import React, { Fragment } from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import { Col, Row, Container, Jumbotron } from 'reactstrap';
import customlogo from '../../images/custom-logo.jpg';
import { Link } from '../../components';
import styled from 'styled-components';

const CustomJumbotron = styled(Jumbotron)`  
  margin-bottom: 20px;
  padding: 6rem 2rem;

  &:before {
    background: url(${customlogo}) no-repeat top center;
    opacity: 1;
  }

  @media (max-width: 576px) {
    top: -10px;
    padding: 4rem 2rem;
  }
`;

const JumbotronText = styled.div`
  color: white;
  font-size: xxx-large;
  font-weight: bolder;
  text-align: center;
`;

const StyledCol = styled(Col)`
  margin-bottom: 20px;

  .inner {
      height: 150px;
      padding: 20px;
      background-color: #6c757d;
  }

  .text {
      height: 66%;
      color: white;
      font-size: 20px;
      font-weight: bold;
  }

  .bottom {
      height: 34%;
  }

  .link {
      margin-top: 6px;
  }

  a {
      padding: 6px 20px;
      color: white;
      background-color: #db011c;
      font-weight: 500;
  }

  input {
      margin-right: 10px;
  }
`;

const Customize = (props) => {
  const { intl } = props;

  return (
    <Fragment>
      <div className='animate-bottom-fade-in content-wrapper'>
        <CustomJumbotron className='has-background-banner-1'>
          <Container data-test-id='customizations-title' className='animate-bottom-fade-in search-container'>
            <JumbotronText><FormattedMessage id='customLogo.title' /></JumbotronText>
          </Container>
        </CustomJumbotron>
        <Container>
          <Row>
            <StyledCol xs={12} className='text-center'>
              <div className='inner'>
                <div className='text'><FormattedMessage id='customLogo.createStart' /></div>
                <div data-test-id='view-or-start-customization' className='bottom link'>
                  <Link to='customizer'><FormattedMessage id='customLogo.startCustomizing' /></Link>
                </div>
              </div>
            </StyledCol>
          </Row>
          <Row>
            <Col sm={{ size: 12, offset: 0 }}>
              <h3><FormattedMessage id='customLogo.customizeProduct' /></h3>
              <p>{intl.formatMessage({ id: 'customLogo.customizeProductDescription' }, {
                customizerFAQLink: (
                  <a
                    data-test-id='customize-faq-link'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={window.location.host.toLowerCase() === 'connect.milwaukeetool.com' ? 'https://custom.milwaukeetool.com/external/customer/FAQ' : 'https://custom-stage.milwaukeetool.com/external/customer/FAQ'}
                  >
                    {intl.formatMessage({
                      id: 'customLogo.faqLinkText',
                    })}
                  </a>
                )
              })}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default injectIntl(Customize);