import queryString from '../helpers/queryString';
import createFetchAction from './createFetchAction';

export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR';

export const DOWNLOADING_INVOICE = 'DOWNLOADING_INVOICE';

export const initialState = {
  invoiceHistory: {
    invoices: [],
    isLoading: false,
    isError: false,
    totalPages: 0,
    totalCount: 0,
    count: 0
  }
};

export const actionCreators = {
  getInvoices: (queryObject) => {
    let query = queryString.objectToQuery(queryObject);
    return createFetchAction({
      url: `/api/v1/invoices?${query}`,
      startAction: GET_INVOICES
    });
  },
  downloadInvoice: (invoiceId, invoiceDate, orderNumber) => {
    return createFetchAction({
      url: `/accountapi/invoice/${invoiceId}/${invoiceDate}/${orderNumber}`,
      startAction: DOWNLOADING_INVOICE,
      isBlob: true,
      ignore404: true
    });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          isLoading: true,
          isError: false
        }
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          invoices: action.payload.data,
          totalPages: action.payload.totalPages || 0,
          totalCount: action.payload.totalCount,
          count: action.payload.count,
          isLoading: false,
          isError: false
        }
      };
    case GET_INVOICES_ERROR:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          isLoading: false,
          isError: true
        }
      };

    default:
      return state;
  }
};