import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import camelcase from 'camelcase';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  Row
} from 'reactstrap';

import { Button, CalendarInput, Checkbox, Link, PageLoader, Text } from '../../components';
import { Dropdown, RadioButtons } from '@met/react-components';
import queryString from '../../helpers/queryString';
import user from '../../helpers/user';
import history from '../../history';
import { warrantyActions, warrantyClaimActions } from '../../store';
import SearchResult from './SearchResult';

class WarrantySearch extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();

    this.handleStatusCheckbox = this.handleStatusCheckbox.bind(this);
    this.handleBatteryClaims = this.handleBatteryClaims.bind(this);
    this.handleShowOnlyBatteryClaims = this.handleShowOnlyBatteryClaims.bind(this);
    this.handleSortDirection = this.handleSortDirection.bind(this);
    this.handleChangeSortDirection = this.handleChangeSortDirection.bind(this);
    this.handleShowOnlyArchivedClaims = this.handleShowOnlyArchivedClaims.bind(this);
    this.userCanSeeArchivedClaims = this.userCanSeeArchivedClaims.bind(this);
  }

  componentDidMount() {
    this.props.getServiceManagers();

    if (window.location.search !== '') {
      this.updateStateFromQuery(() => {
        this.props.searchWarranties(this.state.query, this.props.accountNumber);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;

    if (this.state.queryString !== window.location.search) {
      this.updateStateFromQuery(() => {
        this.props.searchWarranties(this.state.query, this.props.accountNumber);
      });
    }
  }

  initialState = () => {
    return {
      tryDownloadMemo: false,
      showFilters: true,
      advancedSearch: false,
      queryString: '',
      query: {
        statuses: {
          draft: false,
          pendingInitialReview: false,
          approved: false,
          approvedWithChanges: false,
          rejected: false,
          pendingSubsequentReview: false,
          denied: false,
          pendingAutoApproval: false
        },
        serviceManager: '',
        hideBatteryClaims: false,
        showOnlyBatteryClaims: false,
        orderBy: 2,
        sortDirection: false,
        endDate: undefined,
        startDate: undefined,
        claimNumber: '',
        asc: '',
        customerName: '',
        orderNumber: '',
        workOrderNumber: '',
        catalogNumber: '',
        page: 1,
        length: 10,
        showOnlyArchivedClaims: false
      }
    };
  };

  updateStateFromQuery = (callback) => {
    let values = queryString.parse();

    this.setState({
      ...this.state,
      showFilters: window.location.search === undefined || window.location.search === '',
      advancedSearch: (values.clan || values.asc || values.cusn || values.on || values.won || values.catn || values.axon) || false,
      queryString: window.location.search,
      query: {
        statuses: {
          draft: values.dft === 'true' || false,
          pendingInitialReview: values.pir === 'true' || false,
          approved: values.a === 'true' || false,
          approvedWithChanges: values.awc === 'true' || false,
          rejected: values.r === 'true' || false,
          pendingSubsequentReview: values.psr === 'true' || false,
          denied: values.d === 'true' || false,
          pendingAutoApproval: values.paa === 'true' || false
        },
        serviceManager: values.sm || undefined,
        hideBatteryClaims: values.hbc === 'true' || false,
        showOnlyBatteryClaims: values.sobc === 'true' || false,
        sortDirection: values.std === 'true' || false,
        orderBy: 2,
        endDate: this.parseDate(values.ed) || undefined,
        startDate: this.parseDate(values.sd) || undefined,
        claimNumber: values.clan || undefined,
        asc: values.asc || undefined,
        axOrderNumber: values.axon || undefined,
        customerName: values.cusn || undefined,
        orderNumber: values.on || undefined,
        workOrderNumber: values.won || undefined,
        catalogNumber: values.catn || undefined,
        page: values.p ? parseInt(values.p) : 1,
        length: 10,
        showOnlyArchivedClaims: values.soac === 'true' || false,
      }
    }, () => {
      if (callback)
        callback();
    });
  };

  handleServiceManagerChange = (option) => {
    let serviceManager;

    if (option.value === 'All Managers')
      serviceManager = undefined;
    else
      serviceManager = option.value;

    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        serviceManager: serviceManager
      }
    });
  };

  handleAdvancedSearchToggle = () => {
    this.setState({
      ...this.state,
      advancedSearch: !this.state.advancedSearch
    });
  };

  handleBatteryClaims() {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        hideBatteryClaims: !this.state.query.hideBatteryClaims
      }
    });
  }

  handleShowOnlyBatteryClaims() {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        showOnlyBatteryClaims: !this.state.query.showOnlyBatteryClaims
      }
    });
  }

  handleShowOnlyArchivedClaims() {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        showOnlyArchivedClaims: !this.state.query.showOnlyArchivedClaims
      }
    });
  }

  handleSortDirection() {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        sortDirection: !this.state.query.sortDirection
      }
    });
  }

  handleChangeSortDirection(option) {
    this.setState({
      ...this.state,
      showFilters: false,
      query: {
        ...this.state.query,
        page: 1,
        sortDirection: option.value
      }
    }, () => {
      this.filter();
    });
  }

  handleStatusCheckbox(selected, value) {
    let statuses = this.state.query.statuses;

    switch (value) {
      case 0:
        statuses.draft = selected;
        break;
      case 1:
        statuses.pendingInitialReview = selected;
        break;
      case 2:
        statuses.approved = selected;
        break;
      case 3:
        statuses.approvedWithChanges = selected;
        break;
      case 4:
        statuses.rejected = selected;
        break;
      case 5:
        statuses.pendingSubsequentReview = selected;
        break;
      case 6:
        statuses.denied = selected;
        break;
      case 7:
        statuses.pendingAutoApproval = selected;
        break;
      default:
        break;
    }

    if (statuses)

      this.setState({
        ...this.state,
        query: {
          ...this.state.query,
          statuses: statuses
        }
      });
  }

  handleStartDateChange = (selectedDay) => {
    let endDate;
    if (selectedDay > this.state.query.endDate) {
      endDate = undefined
    }
    else {
      endDate = this.state.query.endDate
    }

    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        startDate: selectedDay,
        endDate
      }
    });
  };

  handleEndDateChange = (selectedDay) => {
    let startDate;
    if (selectedDay < this.state.query.startDate) {
      startDate = undefined
    }
    else {
      startDate = this.state.query.startDate
    }

    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        endDate: selectedDay,
        startDate
      }
    });
  };

  handleSearchInputChange = (e) => {
    let name = camelcase(e.target.name);
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        [name]: e.target.value
      }
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        page: pageNumber
      }
    });

    history.push(queryString.update('p', pageNumber));
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  handleSearchButtonPress = () => {
    this.setState({
      ...this.state,
      showFilters: false,
      query: {
        ...this.state.query,
        page: 1
      }
    }, () => {
      this.search();
    });
  };

  handleClearButtonPress = () => {
    let initialState = this.initialState();

    this.setState({
      ...initialState
    });
  };

  handleNewSearchButtonPress = () => {
    let locale = this.props.match.params.locale;
    let subPath = locale ? `/${locale}` : '';
    let route = subPath + '/warranty';

    history.push(route);
  };

  handleRefineSearchButtonPress = () => {
    this.setState({
      ...this.state,
      showFilters: true
    });
  };

  search = () => {
    let locale = this.props.match.params.locale;
    let subPath = locale ? `/${locale}` : '';
    let route = subPath + queryString.update('/warranty' + window.location.search);
    route = queryString.update('dft', this.state.query.statuses.draft, route, false);
    route = queryString.update('pir', this.state.query.statuses.pendingInitialReview, route, false);
    route = queryString.update('a', this.state.query.statuses.approved, route, false);
    route = queryString.update('awc', this.state.query.statuses.approvedWithChanges, route, false);
    route = queryString.update('r', this.state.query.statuses.rejected, route, false);
    route = queryString.update('psr', this.state.query.statuses.pendingSubsequentReview, route, false);
    route = queryString.update('d', this.state.query.statuses.denied, route, false);
    route = queryString.update('paa', this.state.query.statuses.pendingAutoApproval, route, false);
    route = queryString.update('sm', this.state.query.serviceManager, route);
    route = queryString.update('hbc', this.state.query.hideBatteryClaims, route, false);
    route = queryString.update('sobc', this.state.query.showOnlyBatteryClaims, route, false);
    route = queryString.update('std', this.state.query.sortDirection, route, false);
    route = queryString.update('ed', this.state.query.endDate && this.state.query.endDate.toISOString().split('T')[0], route, '');
    route = queryString.update('sd', this.state.query.startDate && this.state.query.startDate.toISOString().split('T')[0], route, '');
    route = queryString.update('clan', this.state.query.claimNumber, route);
    route = queryString.update('asc', this.state.query.asc, route);
    route = queryString.update('axon', this.state.query.axOrderNumber, route);
    route = queryString.update('cusn', this.state.query.customerName, route);
    route = queryString.update('on', this.state.query.orderNumber, route);
    route = queryString.update('won', this.state.query.workOrderNumber, route);
    route = queryString.update('catn', this.state.query.catalogNumber, route);
    route = queryString.update('p', this.state.query.page, route, 1);
    route = queryString.update('soac', this.state.query.showOnlyArchivedClaims, route, false);

    // If no query parameters were appended, append a base 'q' param so the container will re-render and search for claims on update
    if (route.indexOf('?') === -1)
      route = route + '?q=';
    else
      route = queryString.update('q', '', route);

    history.push(route.replace(/\+/g, '%2B'));
  };

  filter = () => {
    let route = queryString.update('/warranty' + window.location.search);
    route = queryString.update('std', this.state.query.sortDirection, route, false);

    if (route.indexOf('?') === -1)
      route = route + '?q=';

    history.push(route.replace(/\+/g, '%2B'));
  };

  parseDate = (input) => {
    if (!input)
      return undefined;

    let parts = input.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
  };

  userCanSeeArchivedClaims = () => {
    return user.isMilwaukeeToolEmployee() && user.canApproveWarrantyClaims();
  }

  render() {
    const { warrantySearch, serviceManagers } = this.props;
    const { locale } = this.props.match.params;

    let results = warrantySearch.warranties && warrantySearch.warranties.map((warranty, index) => {
      return (
        <SearchResult
          key={`warranty-${index}`}
          warranty={warranty}
          accountNumber={this.props.accountNumber}
          getCreditMemo={this.props.getCreditMemo} />
      )
    });

    let blankResults = (
      <Container>
        <Row>
          <Col>
            <h5 className='my-3'>Claim Search Results</h5>
            <ListGroup>
              <ListGroupItem className='text-center warranty-preview'>
                <div className='my-4'>
                  <FontAwesomeIcon icon='search' size='2x' className='mb-2 text-muted' />
                  <h3 data-test-id='no-claims-message'><FormattedMessage id='warranty.noClaimsMessage' /></h3>
                  <p className='text-muted'>We can&apos;t find any warranty claims matching your search.</p>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );

    let resultsStart = warrantySearch.totalCount > 0 ? (this.state.query.page ? this.state.query.page - 1 : 0) * 10 + 1 : 0;
    let resultsEnd = warrantySearch.count < 10 ? warrantySearch.totalCount : (this.state.query.page ? this.state.query.page : 1) * 10;
    let resultsOf = warrantySearch.totalCount;
    let resultsFromToOf = warrantySearch.totalCount > 0 ? 'Showing ' + resultsStart + '-' + resultsEnd + ' of ' + resultsOf : 'No';
    let orderOptions = [{ label: 'Oldest to Newest', value: 'false' }, { label: 'Newest to Oldest', value: 'true' }];
    let direction = (this.state.query.sortDirection || this.state.query.sortDirection === 'true') ? 'true' : 'false';

    let resultsList = warrantySearch.isLoading ? <PageLoader /> : (warrantySearch.totalCount !== undefined ? (warrantySearch.totalCount > 0 ? (
      <Container fluid>
        <Row>
          <Col>
            <h5 className='mb-3'>Claim Search Results</h5>
            <Container fluid>
              <Row className='my-3'>
                <Col>
                  <Text
                    isHidden={false}
                    value={`${resultsFromToOf} results ${queryString.parse().q ? `for "${queryString.parse().q}"` : ''}`}
                  />
                </Col>
                <Col sm={3} className='mt-3 mt-xl-0'>
                  <Dropdown
                    options={orderOptions}
                    onChange={this.handleChangeSortDirection}
                    placeholder='Sort'
                    value={orderOptions.filter(option => option.value === direction)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ListGroup>
                    {results}
                  </ListGroup>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    ) : blankResults) : null);

    let serviceManagerOptions = serviceManagers.serviceManagers && serviceManagers.serviceManagers.map(serviceManager => {
      return { value: serviceManager.serviceRegionCode, label: serviceManager.displayName };
    });

    let serviceManagerOption = this.state.query.serviceManager;

    let serviceManagerMarkup = user.isMilwaukeeToolEmployee() && (
      <FormGroup>
        <Label for='service-manager'>Service Manager</Label>
        <Dropdown
          isLoading={serviceManagers.isLoading}
          onChange={this.handleServiceManagerChange}
          options={serviceManagerOptions}
          value={serviceManagerOptions.filter(option => option.value === serviceManagerOption)}
        />
      </FormGroup>
    );

    let searchResultsMarkup = warrantySearch.warranties && this.state.queryString !== '' && !this.state.showFilters && (
      <Container fluid>
        <Row className='justify-content-end'>
          <Col xs='auto'>
            <Button color='primary' isLoading={this.props.isRefreshing} onClick={this.handleNewSearchButtonPress} className='mr-2'>New Search</Button>
            <Button color='secondary' isLoading={this.props.isRefreshing} onClick={this.handleRefineSearchButtonPress}>Refine Search</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {resultsList}
            {warrantySearch.totalPages > 1 && <Pagination
              activePage={this.state.query.page}
              itemsCountPerPage={10}
              totalItemsCount={warrantySearch.totalCount}
              pageRangeDisplayed={8}
              innerClass='pagination flex-wrap'
              itemClass='page-item'
              linkClass='page-link'
              nextPageText={<FontAwesomeIcon icon='angle-right' />}
              prevPageText={<FontAwesomeIcon icon='angle-left' />}
              lastPageText={<FontAwesomeIcon icon='angle-double-right' />}
              firstPageText={<FontAwesomeIcon icon='angle-double-left' />}
              hideFirstLastPages={false}
              onChange={this.handlePageChange}
            />}
          </Col>
        </Row>
      </Container>
    );

    let searchFieldsMarkup = this.state.showFilters && (
      <Container>
        <Row>
          <Col>
            <Container fluid>
              <Row>
                <Col sm={6}>
                  <Form>
                    {serviceManagerMarkup}
                    <FormGroup tag='fieldset' data-test-id='warranty-search-hide-battery-claims' className='mb-3'>
                      <Checkbox label='Show Only Battery Claims' isChecked={this.state.query.showOnlyBatteryClaims} onToggle={this.handleShowOnlyBatteryClaims} isDisabled={this.state.query.hideBatteryClaims} />
                      <Checkbox label='Hide Battery Claims' isChecked={this.state.query.hideBatteryClaims} onToggle={this.handleBatteryClaims} isDisabled={this.state.query.showOnlyBatteryClaims} />
                      {this.userCanSeeArchivedClaims() && <Checkbox label='Show Only Archived Claims' isChecked={this.state.query.showOnlyArchivedClaims} onToggle={this.handleShowOnlyArchivedClaims} />}
                    </FormGroup>
                    <div data-test-id='warranty-search-sort-order' className='my-3'>
                      <Label for='order'>Sort Order</Label>
                      <RadioButtons
                        onRadioButtonSelect={this.handleSortDirection}
                        name='order'
                        options={orderOptions}
                      />
                    </div>
                    <FormGroup tag='fieldset' data-test-id='warranty-search-claim-status' className='mb-4'>
                      <Label for='claim-status'>Claim Status (Optional)</Label>
                      <Container fluid>
                        <Row>
                          <Col sm={6}>
                            <Checkbox label='Draft' isChecked={this.state.query.statuses.draft} onToggle={this.handleStatusCheckbox} value={0} />
                            <Checkbox label='Pending Initial Review' isChecked={this.state.query.statuses.pendingInitialReview} onToggle={this.handleStatusCheckbox} value={1} />
                            <Checkbox label='Approved' isChecked={this.state.query.statuses.approved} onToggle={this.handleStatusCheckbox} value={2} />
                            <Checkbox label='Approved With Changes' isChecked={this.state.query.statuses.approvedWithChanges} onToggle={this.handleStatusCheckbox} value={3} />
                          </Col>
                          <Col sm={6}>
                            <Checkbox label='Rejected' isChecked={this.state.query.statuses.rejected} onToggle={this.handleStatusCheckbox} value={4} />
                            <Checkbox label='Pending Subsequent Review' isChecked={this.state.query.statuses.pendingSubsequentReview} onToggle={this.handleStatusCheckbox} value={5} />
                            <Checkbox label='Denied' isChecked={this.state.query.statuses.denied} onToggle={this.handleStatusCheckbox} value={6} />
                            {user.canApproveWarrantyClaims() &&
                              <Checkbox label='Pending Auto Approval' isChecked={this.state.query.statuses.pendingAutoApproval} onToggle={this.handleStatusCheckbox} value={7} />
                            }
                          </Col>
                        </Row>
                      </Container>
                    </FormGroup>
                    <FormGroup tag='fieldset' className='mt-2 mb-0'>
                      <Label for='submission-date-range'>Submission Date Range (Optional)</Label>
                      <Container fluid>
                        <Row form>
                          <Col xs='auto'>
                            <FormGroup data-test-id='warranty-search-start-date'>
                              <CalendarInput
                                selectsStart
                                isClearable
                                placeholderText='Start Date'
                                selectedDay={this.state.query.startDate}
                                onSelectedDateChange={this.handleStartDateChange}
                                startDate={this.state.query.startDate}
                                endDate={this.state.query.endDate}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs='auto'>
                            <FormGroup data-test-id='warranty-search-end-date'>
                              <CalendarInput
                                selectsEnd
                                isClearable
                                placeholderText='End Date'
                                minDate={this.state.query.startDate}
                                selectedDay={this.state.query.endDate}
                                onSelectedDateChange={this.handleEndDateChange}
                                startDate={this.state.query.startDate}
                                endDate={this.state.query.endDate}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Container>
                    </FormGroup>
                    <ListGroup>
                      <ListGroupItem>
                        <Container onClick={this.handleAdvancedSearchToggle} className='advanced-search-toggle'>
                          <Row>
                            <Col xs='auto' className='mr-2'>
                              <FontAwesomeIcon icon={this.state.advancedSearch ? 'angle-down' : 'angle-right'} />
                            </Col>
                            <Col>
                              <span data-test-id='warranty-search-advanced-search'>Advanced Search</span>
                            </Col>
                          </Row>
                        </Container>
                        <Collapse isOpen={this.state.advancedSearch}>
                          <hr />
                          <Container fluid className='p-2'>
                            <Row>
                              <Col>
                                <FormGroup data-test-id='warranty-search-claim-number'>
                                  <Label for='claim-number'>EW # (Optional)</Label>
                                  <Input type='text' placeholder='' value={this.state.query.claimNumber} onKeyPress={this.handleKeyPress} onChange={this.handleSearchInputChange} name='claim-number' />
                                </FormGroup>
                                {user.isMilwaukeeToolEmployee() &&
                                  <FormGroup>
                                    <Label for='asc'>ASC (Comma Separated) (Optional)</Label>
                                    <Input type='text' placeholder='' value={this.state.query.asc} onKeyPress={this.handleKeyPress} onChange={this.handleSearchInputChange} name='asc' />
                                  </FormGroup>
                                }
                                <FormGroup data-test-id='warranty-search-customer-name'>
                                  <Label for='customer-name'>Customer/Company Name (Optional)</Label>
                                  <Input type='text' placeholder='' value={this.state.query.customerName} onKeyPress={this.handleKeyPress} onChange={this.handleSearchInputChange} name='customer-name' />
                                </FormGroup>
                                <FormGroup data-test-id='warranty-search-order-number'>
                                  <Label for='order-number'>Customer Order Number (Optional)</Label>
                                  <Input type='text' placeholder='' value={this.state.query.orderNumber} onKeyPress={this.handleKeyPress} onChange={this.handleSearchInputChange} name='order-number' />
                                </FormGroup>
                                <FormGroup data-test-id='warranty-search-work-order'>
                                  <Label for='work-order-number'>Work Order # (Optional)</Label>
                                  <Input type='text' placeholder='' value={this.state.query.workOrderNumber} onKeyPress={this.handleKeyPress} onChange={this.handleSearchInputChange} name='work-order-number' />
                                </FormGroup>
                                <FormGroup data-test-id='warranty-search-catalog-number'>
                                  <Label for='catalog-number'>Catalog # (Optional)</Label>
                                  <Input type='text' placeholder='' value={this.state.query.catalogNumber} onKeyPress={this.handleKeyPress} onChange={this.handleSearchInputChange} name='catalog-number' />
                                </FormGroup>
                                <FormGroup data-test-id='warranty-search-ax-order-number'>
                                  <Label for='ax-order-number'>AX Order # (Optional)</Label>
                                  <Input type='text' placeholder='' value={this.state.query.axOrderNumber} onKeyPress={this.handleKeyPress} onChange={this.handleSearchInputChange} name='ax-order-number' />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Container>
                        </Collapse>
                      </ListGroupItem>
                    </ListGroup>
                  </Form>
                </Col>
              </Row>
              <Row className='justify-content-end'>
                <Col xs='auto'>
                  <Button data-test-id='warranty-search-button' color='primary' isLoading={this.props.isRefreshing} onClick={this.handleSearchButtonPress} className='mr-2'>Search</Button>
                  <Button data-test-id='warranty-search-clear-button' color='secondary' isLoading={this.props.isRefreshing} onClick={this.handleClearButtonPress}>Clear</Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );

    return (
      <div id='warranty-search' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row>
            <Col>
              <Nav>
                <NavItem data-test-id='warranty-claim-search'>
                  <Link to='warranty' locale={locale} isActive={true} className='nav-link'><span>Search</span></Link>
                </NavItem>
                {user.canCreateWarrantyClaims() &&
                  <Fragment>
                    <NavItem data-test-id='warranty-claim-new-claim'>
                      <Link to='warranty/newClaim' locale={locale} isActive={false} className='nav-link'><span>New Claim</span></Link>
                    </NavItem>
                    <NavItem data-test-id='warranty-claim-defective-part-claim'>
                      <Link to='warranty/defectiveClaim' locale={locale} isActive={false} className='nav-link'><span>New Defective Part Claim</span></Link>
                    </NavItem>
                  </Fragment>
                }
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {searchFieldsMarkup}
                  {searchResultsMarkup}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    warrantySearch: state.warranty.warrantySearch,
    serviceManagers: state.warranty.serviceManagers,
    accountNumber: state.account.currentAccount.accountDetails && (state.account.currentAccount.accountDetails.accountNumber || '')
  };
};

const mapDispatchToProps = dispatch => ({
  searchWarranties: (query, accountNumber) => dispatch(warrantyActions.searchWarranties(query, accountNumber)),
  getServiceManagers: () => dispatch(warrantyActions.getServiceManagers()),
  clearWarranties: () => dispatch(warrantyActions.clearWarranties()),
  getCreditMemo: (creditMemoNumber, accountNumber) => dispatch(warrantyClaimActions.getCreditMemo(creditMemoNumber, accountNumber))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WarrantySearch));