import React, {Component} from 'react';
import { ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';

class MenuItem extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      isActive: this.props.isActive || false
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      this.setState({
        ...this.state,
        isActive: this.props.isActive
      });
    }
  }
  
  toggle = () => {
    let isActive = !this.state.isActive;
    this.setState({
      ...this.state,
      isActive
    });
    
    if (this.props.onClick)
      this.props.onClick(this.props.value, isActive);
  };
  
  render() {
    return (
      <ListGroupItem onClick={this.toggle} active={this.state.isActive}>
        {this.props.display || this.props.value}
      </ListGroupItem>
    );
  }
}

MenuItem.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string.isRequired,
  display: PropTypes.string,
  isActive: PropTypes.bool
};

export default MenuItem;