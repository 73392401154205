import { Card, CardBody, CardHeader, Col, Row, RadioButtons } from '@met/react-components';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {FormattedMessage} from "react-intl";
import shipping from '../../../helpers/shippingHelper';

const ShippingSpeed = props => {
  const [shippingSpeed, setShippingSpeed] = useState(shipping.speeds.bestWay);
  
  useEffect(() => {
    setDefaultShippingSpeed();
  }, []);

  const handleShippingSpeedChange = (value) => {
    setShippingSpeed(value);
    
    if (props.onShippingSpeedChange)
      props.onShippingSpeedChange(value);
  };

  const setDefaultShippingSpeed = () => {
    handleShippingSpeedChange(shipping.getDefaultShippingSpeed(props.isEmployee, props.address.state, props.address.city, props.freeTruckShippingEnabled));
  };

  // Determines whether to disable or enable shipping selection
  let customLogoOptions = "NoCustomGear";
  if (props.containsCustomLogo && props.containsNonCustomGear) {
    customLogoOptions = "Mixed";
  }
  if (!props.containsCustomLogo && props.containsNonCustomGear) {
    customLogoOptions = "NoCustomGear";
  }
  if (props.containsCustomLogo && !props.containsNonCustomGear) {
    customLogoOptions = "CustomGear";
  }
  
  return (
    <Card data-test-id='checkout-shipping-speed'>
      <CardHeader>
        <FormattedMessage id='checkout.shippingSpeedTitle'/>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={12} md={5}>
            <RadioButtons
              onRadioButtonSelect={handleShippingSpeedChange}
              selectedValue={shippingSpeed}
              options={[
                // { value: shipping.speeds.oliveBranchWillCall, label: 'Will Call - Olive Branch', hidden: !(props.isEmployee && props.address.state === 'MS' && props.address.city === 'Olive Branch'), disabled: customLogoOptions === "CustomGear"},
                { value: shipping.speeds.jacksonTruck, label: 'Company Truck - Jackson', hidden: !(props.isEmployee && props.address.state === 'MS' && props.address.city === 'Jackson') || !props.freeTruckShippingEnabled, disabled: customLogoOptions === "CustomGear" || !props.freeTruckShippingEnabled },
                { value: shipping.speeds.greenwoodTruck, label: 'Company Truck - Greenwood', hidden: !(props.isEmployee && props.address.state === 'MS' && props.address.city === 'Greenwood') || !props.freeTruckShippingEnabled, disabled: customLogoOptions === "CustomGear" || !props.freeTruckShippingEnabled },
                { value: 'Best Way', label: 'Normal Ground'},
                { value: 'SO FX PRIO', label: 'FedEx Priority Overnight*', hidden: props.countryCode !== 'US', disabled: customLogoOptions === "CustomGear" || !props.expeditedShippingEnabled},
                { value: 'SO FX 2DAY', label: 'FedEx 2nd Day Air*', hidden: props.countryCode !== 'US', disabled: customLogoOptions === "CustomGear" || !props.expeditedShippingEnabled},
                { value: 'SO FX 3PM', label: 'FedEx Standard Overnight*', hidden: props.countryCode !== 'US', disabled: customLogoOptions === "CustomGear" || !props.expeditedShippingEnabled},
                { value: 'SO FX AM', label: 'FedEx First Overnight*', hidden: props.countryCode !== 'US', disabled: customLogoOptions === "CustomGear" || !props.expeditedShippingEnabled},
                { value: 'SO FX 3DAY', label: 'FedEx Express Saver*', hidden: props.countryCode !== 'US', disabled: customLogoOptions === "CustomGear" || !props.expeditedShippingEnabled},
                { value: 'SO INTL P1', label: 'FedEx International Priority*', hidden: props.countryCode === 'US', disabled: customLogoOptions === "CustomGear" || !props.expeditedShippingEnabled}
              ]}/>
          </Col>
          <Col xs={12} md={7}>
            <div className='font-italic font-weight-light mt-2 mt-md-0'>
              {(customLogoOptions === "NoCustomGear" || customLogoOptions === "Mixed") && !props.expeditedShippingEnabled ? <FormattedMessage id='checkout.disabledShippingSpeedMessage' values={{ br: <br /> }} /> : <FormattedMessage id='checkout.shippingSpeedMessage' values={{ br: <br /> }} />}
              {(customLogoOptions === "CustomGear" || customLogoOptions === "Mixed") && <FormattedMessage id='checkout.customLogoShippingSpeedMessage' values={{ br: <br /> }} />}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

ShippingSpeed.propTypes = {
  address: PropTypes.object,
  containsCustomLogo: PropTypes.bool,
  containsNonCustomGear: PropTypes.bool,
  countryCode: PropTypes.string,
  isEmployee: PropTypes.bool,
  onShippingSpeedChange: PropTypes.func,
  expeditedShippingEnabled: PropTypes.bool,
  freeTruckShippingEnabled: PropTypes.bool
};

export default ShippingSpeed;