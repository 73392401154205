
import createFetchAction from './createFetchAction';
import {getProfile} from '../helpers/localStorage';

export const GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS = 'GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS';
export const GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS_SUCCESS = 'GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS_SUCCESS';
export const GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS_ERROR = 'GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS_ERROR';

export const DELETE_WARRANTY_AUTO_APPROVE_FILTER = 'DELETE_WARRANTY_AUTO_APPROVE_FILTER';
export const DELETE_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS = 'DELETE_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS';
export const DELETE_WARRANTY_AUTO_APPROVE_FILTER_ERROR = 'DELETE_WARRANTY_AUTO_APPROVE_FILTER_ERROR';

export const UPDATE_WARRANTY_AUTO_APPROVE_FILTER = 'UPDATE_WARRANTY_AUTO_APPROVE_FILTER';
export const UPDATE_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS = 'UPDATE_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS';
export const UPDATE_WARRANTY_AUTO_APPROVE_FILTER_ERROR = 'UPDATE_WARRANTY_AUTO_APPROVE_FILTER_ERROR';

export const ADD_WARRANTY_AUTO_APPROVE_FILTER = 'ADD_WARRANTY_AUTO_APPROVE_FILTER';
export const ADD_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS = 'ADD_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS';
export const ADD_WARRANTY_AUTO_APPROVE_FILTER_ERROR = 'ADD_WARRANTY_AUTO_APPROVE_FILTER_ERROR';

export const initialState = {
  getWarrantyAutoApproveFilters: {
    filters: [],
    isLoading: false,
    isError: false
  },
  deleteWarrantyAutoApproveFilter: {
    isProcessing: false,
    isError: false
  },
  updateWarrantyAutoApproveFilter: {
    isProcessing: false,
    isError: false
  },
  addWarrantyAutoApproveFilter: {
    isProcessing: false,
    isError: false
  }
};

export const actionCreators = {
  getWarrantyAutoApproveFilters: () => {
    return createFetchAction({
      url: `/warrantyapi/AutoApproveFilter/GetAll`,
      startAction: GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS
    });
  },
  deleteWarrantyAutoApproveFilter: (id) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/warrantyapi/AutoApproveFilter/Delete/${id}`,
      startAction: DELETE_WARRANTY_AUTO_APPROVE_FILTER
    });
  },
  updateWarrantyAutoApproveFilter: (filter) => {
    const userProfile = getProfile();
    return createFetchAction({
      method: 'POST',
      url: `/warrantyapi/AutoApproveFilter/Update`,
      startAction: UPDATE_WARRANTY_AUTO_APPROVE_FILTER,
      body: {
        ...filter,
        modifiedBy: userProfile.email
      }
    })
  },
  addWarrantyAutoApproveFilter: (filter) => {
    const userProfile = getProfile();
    return createFetchAction({
      method: 'POST',
      url: `/warrantyapi/AutoApproveFilter/Add`,
      startAction: ADD_WARRANTY_AUTO_APPROVE_FILTER,
      body: {
        ...filter,
        modifiedBy: userProfile.email
      }
    })
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  
  switch (action.type) {
    case GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS:
      return {
        ...state,
        getWarrantyAutoApproveFilters:{
          isLoading: true,
          isError: false
        }
      };
    case GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS_SUCCESS:
      return {
        ...state,
        getWarrantyAutoApproveFilters: {
          ...state.getWarrantyAutoApproveFilters,
          filters: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case GET_ALL_WARRANTY_AUTO_APPROVE_FILTERS_ERROR:
      return {
        ...state,
        getWarrantyAutoApproveFilters: {
          ...state.getWarrantyAutoApproveFilters,
          isLoading: false,
          isError: true
        }
      };
    case DELETE_WARRANTY_AUTO_APPROVE_FILTER:
      return {
        ...state,
        deleteWarrantyAutoApproveFilter: {
          isError: false,
          isProcessing: true
        }
      };
    case DELETE_WARRANTY_AUTO_APPROVE_FILTER_ERROR:
      return {
        ...state,
        deleteWarrantyAutoApproveFilter: {
          isError: true,
          isProcessing: false
        }
      };
    case DELETE_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS:
      return {
        ...state,
        deleteWarrantyAutoApproveFilter: {
          isError: false,
          isProcessing: false
        }
      };
    case UPDATE_WARRANTY_AUTO_APPROVE_FILTER:
      return {
        ...state,
        updateWarrantyAutoApproveFilter: {
          isError: false,
          isProcessing: true
        }
      };
    case UPDATE_WARRANTY_AUTO_APPROVE_FILTER_ERROR:
      return {
        ...state,
        updateWarrantyAutoApproveFilter: {
          isError: true,
          isProcessing: false
        }
      };
    case UPDATE_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS:
      return {
        ...state,
        updateWarrantyAutoApproveFilter: {
          isError: false,
          isProcessing: false
        }
      };
    case ADD_WARRANTY_AUTO_APPROVE_FILTER:
      return {
        ...state,
        addWarrantyAutoApproveFilter: {
          isError: false,
          isProcessing: true
        }
      };
    case ADD_WARRANTY_AUTO_APPROVE_FILTER_ERROR:
      return {
        ...state,
        addWarrantyAutoApproveFilter: {
          isError: true,
          isProcessing: false
        }
      };
    case ADD_WARRANTY_AUTO_APPROVE_FILTER_SUCCESS:
      return {
        ...state,
        addWarrantyAutoApproveFilter: {
          isError: false,
          isProcessing: false
        }
      };
    default:
      return state;
  }
};