import {
  AddCreditCard,
  Button,
  Card,
  CardBody,
  CardHeader,
  PageLoader,
  Row,
  SelectCreditCard
} from '@met/react-components';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import styled, {keyframes} from 'styled-components';
import TestCardDialog from "../../../components/TestCardDialog";

const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const ButtonRow = styled(Row)`
  & > button {
    animation: ${fadeIn} .35s both ease-in;
    
    @media (max-width: 770px) {
      display: block;
      width: 100%;
    }
    
    &:not(:first-of-type) {
      margin-left: 10px;
      @media (max-width: 770px) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
`;

const PaymentMethod = props => {
  
  let cardBody;
  if (props.pciFeatureEnabled){
    cardBody = props.square.getCreditCards.isLoading || !props.square.creditCards ? <PageLoader/> : (
      <Fragment>
        <SelectCreditCard
          creditCards={props.square.creditCards || []}
          onSetSelectedCard={props.onSetSelectedCard}
          onDeleteCard={props.onDeleteCard}
        />
        <br/>
        <ButtonRow>
          <AddCreditCard
            applicationId={props.square.squareApplicationId}
            onCreditCardAdded={props.onCreditCardAdded}
            canSave={true}
          />
          {
            props.square.allowTestCards && (
              <Button data-test-id='checkout-add-test-card' onClick={props.toggleTestCardDialogHidden}>Add Test Card</Button>
            )
          }
        </ButtonRow>
        {
          props.square.allowTestCards && (
            <TestCardDialog
              hidden={props.testCardDialogHidden}
              toggle={props.toggleTestCardDialogHidden}
              addTestCard={props.onTestCreditCardAdded}
              isLoading={props.square.addCreditCard.isLoading}
            />
          )
        }
      </Fragment>
    )
  }
  else {
    cardBody = (
      <Fragment>
        <div>
          <span className='has-font-size-2 font-weight-bold mr-2'>Invoice Account:</span>
          <span data-test-id='invoice-account-number'>{props.invoiceAccount}</span>
        </div>
        <div>
          <span className='has-font-size-2 font-weight-bold mr-2'>Order Account:</span>
          <span data-test-id='order-account-number'>{(props.branch && props.branch.accountNumber) || props.accountNumber}</span>
        </div>
      </Fragment>
    );
  }
  
  return (
    <Card>
      <CardHeader>
        <FormattedMessage id='checkout.paymentMethodTitle'/>
      </CardHeader>
      <CardBody>
        {cardBody}
      </CardBody>
    </Card>
  );
};

PaymentMethod.propTypes = {
  invoiceAccount: PropTypes.string,
  accountNumber: PropTypes.string,
  branch: PropTypes.object,
  square: PropTypes.object,
  pciFeatureEnabled: PropTypes.bool
};

export default PaymentMethod;