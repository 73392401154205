import React from 'react';

import {Col, Container, Nav, NavItem, NavLink, Row} from 'reactstrap';
import history from '../../history';
import BaseLayout from './BaseLayout';
import user from '../../helpers/user';

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    let currentUrl = window.location.pathname;
    let currentPage = currentUrl.split('/').pop();
    let activeTab;
    switch(currentPage) {
      case 'admin':
        activeTab = 'announcements';
        break;
      case 'products':
        activeTab = 'products';
        break;
      case 'warrantyFilters':
        activeTab = 'warrantyFilters';
        break;
      default:
        activeTab = currentPage;
        break;
    }

    this.state = {
      activeTab
    }
  }

  handleProductTabClick = () => {
    this.setState({
      ...this.state,
      activeTab: 'products'
    });
    history.push('/admin/products');
  };
  
  handleAnnouncementsTabClick = () => {
    this.setState({
      ...this.state,
      activeTab: 'announcements'
    });
    history.push('/admin');
  };

  handleFiltersTabClick = () => {
    this.setState({
      ...this.state,
      activeTab: 'warrantyFilters'
    });
    history.push('/admin/warrantyFilters');
  };

  render() {
    let title = this.props.title && (
      <Row className='my-3'>
        <Col>
          <h2>{this.props.title}</h2>
        </Col>
      </Row>
    );
    
    return (
      <BaseLayout {...this.props}>
        <Container id='admin-container'>
          {title}
          <Row>
            <Col>
              <Nav className='mb-2'>
                {user.isAnnouncementsAdmin() && <NavItem>
                  <NavLink
                    onClick={this.handleAnnouncementsTabClick}
                    active={this.state.activeTab === 'announcements'}
                    href='#'>
                    Announcements
                  </NavLink>
                </NavItem>}
                {(user.isProductAdmin() || user.isAdmin()) && <NavItem>
                  <NavLink
                    onClick={this.handleProductTabClick}
                    active={this.state.activeTab === 'products'}
                    href='#'>
                    Products
                  </NavLink>
                </NavItem>}
                {user.canApproveWarrantyClaims() && 
                <NavItem>
                  <NavLink
                    onClick={this.handleFiltersTabClick}
                    active={this.state.activeTab === 'warrantyFilters'}
                    href='#'>
                    Warranty Filters
                  </NavLink>
                </NavItem>}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </BaseLayout>
    );
  }
}

export default AdminLayout;