import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { Button, Icon, Input, PageLoader, QuantityInput } from '../../components';
import { getProfile } from '../../helpers/localStorage';
import history from '../../history';
import { cartActions } from '../../store';

class QuickAddModal extends Component {
  constructor(props) {
    super(props);

    this.skuValidationRegex = /^[a-zA-Z0-9,.-]*$/;

    this.state = {
      skuOrEachUpc: {
        value: '',
        isValid: undefined,
        validationMessage: undefined,
        validationMessageParams: {}
      },
      quantity: 1,
      isAdding: false,
      trySubmit: false,
      isFillOrKill: getProfile().cachedAccountDetails?.isFillOrKill || false
    };
  }

  validateSkuOrEachUpc = (skuOrEachUpc) => {
    return skuOrEachUpc ? new RegExp(this.skuValidationRegex).test(skuOrEachUpc) : false;
  };

  showErrorToast = (id, params, errorText) => {
    const toastrOptions = {
      timeOut: 5000,
      showCloseButton: false
    };

    toastr.error(
      errorText ? errorText : this.props.intl.formatMessage({ id }, params),
      toastrOptions
    );
  };

  addSkuToCart = () => {
    const { addToCart, getCartStatistics, getCart, accountNumber, isEmployeeAccount, intl } = this.props;

    if (!this.validateSkuOrEachUpc(this.state.skuOrEachUpc.value)) {
      this.setState({
        ...this.state,
        trySubmit: true
      });

      return;
    }

    this.setState({
      ...this.state,
      isAdding: true
    }, () => {
      let quantity = this.state.quantity;
      let sanitizedSkuOrEachUpc = this.state.skuOrEachUpc.value.replace(/[-\\.]/g, '');
      let product = {
        skuOrEachUpc: sanitizedSkuOrEachUpc
      };

      return addToCart(product, quantity, accountNumber, (!this.props.featureToggles?.EmployeeOrdersDisabled && isEmployeeAccount))
        .then((response) => {
          if (response.invalidProduct) {
            this.setState({
              ...this.state,
              skuOrEachUpc: {
                value: product.skuOrEachUpc,
                isValid: false,
                trySubmit: false
              },
              isAdding: false
            }, () => {
              this.showErrorToast('quickAdd.invalidProduct', { skuOrEachUpc: product.skuOrEachUpc });
            });
          } else if (response.invalidQuantity) {
            this.setState({
              ...this.state,
              skuOrEachUpc: {
                value: product.skuOrEachUpc,
                isValid: false,
                trySubmit: false
              },
              isAdding: false
            }, () => {
              let error = this.props.intl.formatMessage({ id: 'quickAdd.invalidQuantity' }, {})
                + (response.nextQuantityAllowed > 0 ? this.props.intl.formatMessage({ id: 'quickAdd.invalidQuantityNextQuantityAllowed' }, { nextQuantityAllowed: response.nextQuantityAllowed }) : '')
                + (response.lowestQuantityAllowed > 0 ? this.props.intl.formatMessage({ id: 'quickAdd.invalidQuantityLowestQuantityAllowed' }, { lowestQuantityAllowed: response.lowestQuantityAllowed }) : '')
              this.showErrorToast(undefined, undefined, error);
            });
          } else {
            this.setState({
              ...this.state,
              skuOrEachUpc: {
                value: '',
                isValid: undefined,
                trySubmit: false
              },
              quantity: 1,
              isAdding: false
            }, () => {
              getCartStatistics(accountNumber);
              getCart(accountNumber, this.state.isFillOrKill)
                .then(() => {
                  let self = this;
                  //This is a hack, maybe there is a better way to reset focus on the box, but this was what worked.
                  setTimeout(function () {
                    if (self.itemInput) {
                      self.itemInput.focusInput();
                    }
                  }, 100);

                })
                .then(() => {
                  const toastrOptions = {
                    timeOut: 2000,
                    showCloseButton: false
                  };

                  const description = response.description || product.skuOrEachUpc;

                  toastr.success(
                    intl.formatMessage({ id: 'cart.addToCartToastTitle' }, { quantity: quantity }),
                    intl.formatMessage({ id: 'cart.addToCartToastMessage' }, { quantity: quantity, description }),
                    toastrOptions
                  );
                })
            });
          }
        })
        .catch(err => {
          const newState = {
            ...this.state,
            skuOrEachUpc: {
              ...this.state.skuOrEachUpc,
              isValid: undefined,
              trySubmit: false,
              validationMessage: undefined,
              validationMessageParams: {}
            },
            isAdding: false
          }

          let setStateCallback = () => this.showErrorToast('quickAdd.errorQuickAddToCart');

          if (err.productSkus) {
            newState.skuOrEachUpc.isValid = false;
            newState.skuOrEachUpc.validationMessage = 'quickAdd.multipleSkusMessage';
            newState.skuOrEachUpc.validationMessageParams = { productSkus: err.productSkus };
            setStateCallback = () => this.showErrorToast('quickAdd.invalidProduct', { skuOrEachUpc: product.skuOrEachUpc });
          }

          this.setState(newState, setStateCallback);
        });
    });
  };

  handleSkuOrEachUpcChange = (obj) => {
    this.setState({
      ...this.state,
      skuOrEachUpc: {
        value: obj.value,
        isValid: obj.isValid
      }
    });
  };

  handleQuantityChange = (quantity) => {
    this.setState({
      ...this.state,
      quantity: quantity
    });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.addSkuToCart();
    }
  };

  handleCartClick = () => {
    this.props.toggle(history.push('/cart'));
  };

  getInputValidationMessage = () => {
    const { validationMessage, validationMessageParams } = this.state.skuOrEachUpc;
    const { formatMessage } = this.props.intl;
    if (validationMessage)
      return formatMessage({ id: validationMessage }, validationMessageParams);

    return formatMessage({ id: 'quickAdd.validationMessage' });
  }

  render() {
    const { pendingOrder, getCart } = this.props.cart;

    let pendingOrderLines = [];
    if (pendingOrder && pendingOrder.orderLines) {
      for (const [, value] of Object.entries(pendingOrder.orderLines)) {
        pendingOrderLines = pendingOrderLines.concat(value);
      }
    }

    let orderLines = pendingOrderLines.map((orderLine, index) => {
      return (
        <tr key={`order-line-${orderLine.sku}`}>
          <td data-test-id={`quick-add-product-number-${orderLine.sku}`}>{orderLine.sku}</td>
          <td data-test-id={`quick-add-quantity-${orderLine.sku}`}>{orderLine.quantity}</td>
        </tr>
      )
    });
    let cartMarkup = getCart.isLoading && !pendingOrder ? <PageLoader marginSize='sm' /> : orderLines.length > 0 ? (
      <Table striped borderless className='mt-3'>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {orderLines}
        </tbody>
      </Table>
    ) : (
      <ListGroup className='mt-3'>
        <ListGroupItem className='text-center'>
          <div data-test-id='quick-add-empty-cart' className='my-2'>
            <h5><FormattedMessage id='cart.emptyCartMessage' /></h5>
          </div>
        </ListGroupItem>
      </ListGroup>
    );

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} centered={true} id='quick-add-modal'>
        <ModalHeader toggle={this.props.toggle}>
          <div className='d-flex flex-row align-items-center'>
            <Icon type='bolt-add' color='dark-gray' width='20' height='20' />
            <div className='ml-1'>{this.props.intl.formatMessage({ id: 'quickAdd.title' })} <span className='has-font-size-1'>(Ctrl + Q)</span></div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col data-test-id='quick-add-product-number-input' xs='12' sm>
                <Input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  type='text'
                  placeholder={this.props.intl.formatMessage({ id: 'quickAdd.productPlaceholder' })}
                  value={this.state.skuOrEachUpc.value}
                  onChange={this.handleSkuOrEachUpcChange}
                  onKeyPress={this.handleKeyPress}
                  validationRegex={this.skuValidationRegex}
                  validationMessage={this.getInputValidationMessage()}
                  isValid={this.state.skuOrEachUpc.isValid}
                  trySubmit={this.state.trySubmit}
                  onRef={ref => (this.itemInput = ref)}
                />
              </Col>
              <Col xs='12' sm='auto'>
                <Container fluid>
                  <Row className='justify-content-center mt-3 mt-sm-0'>
                    <Col xs='auto' className='my-auto'>
                      <QuantityInput
                        quantity={this.state.quantity}
                        onQuantityChange={this.handleQuantityChange}
                        onKeyPress={this.handleKeyPress}
                        hideButtons
                        dataTestId='quick-add'
                      />
                    </Col>
                    <Col xs='auto' className='my-auto'>
                      <Button data-test-id='quick-add-add-to-cart' type='button' size='md' color='primary' onClick={this.addSkuToCart} className='no-wrap' isLoading={this.state.isAdding}>{this.props.intl.formatMessage({ id: 'search.addToCart' })}</Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col>
                {cartMarkup}
              </Col>
            </Row>
            <Row className='justify-content-end mt-2'>
              <Col xs='auto'>
                <Button data-test-id='quick-add-go-to-cart' block color='primary' onClick={this.handleCartClick}>{this.props.intl.formatMessage({ id: 'quickAdd.goToCart' })}</Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    accountNumber: state.account && (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber),
    isEmployeeAccount: state.account && (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.isEmployeeAccount : state.account.currentAccount.accountDetails.isEmployeeAccount),
    cart: state.cart,
    featureToggles: state.configuration.featureToggles
  }
};

const mapDispatchToProps = dispatch => ({
  addToCart: (product, quantity, accountNumber, isEmployeeAccount) => dispatch(cartActions.addToCart(product, quantity, accountNumber, undefined, isEmployeeAccount)),
  getCartStatistics: (accountNumber) => dispatch(cartActions.getCartStatistics(accountNumber)),
  getCart: (accountNumber, isFillOrKill) => dispatch(cartActions.getCart(accountNumber, isFillOrKill))
});

QuickAddModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(QuickAddModal));