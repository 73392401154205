import {getProfile} from '../helpers/localStorage';
import createFetchAction from './createFetchAction';

export const LOADING_FLYERSERVICE = 'LOADING_FLYERSERVICE';
export const LOADING_FLYERSERVICE_SUCCESS = 'LOADING_FLYERSERVICE_SUCCESS';
export const LOADING_FLYERSERVICE_ERROR = 'LOADING_FLYERSERVICE_ERROR';

export const LOADING_PINNACLE = 'LOADING_PINNACLE';
export const LOADING_PINNACLE_SUCCESS = 'LOADING_PINNACLE_SUCCESS';
export const LOADING_PINNACLE_ERROR = 'LOADING_PINNACLE_ERROR';

export const LOADING_SPROUTLOUD = 'LOADING_SPROUTLOUD';
export const LOADING_SPROUTLOUD_SUCCESS = 'LOADING_SPROUTLOUD_SUCCESS';
export const LOADING_SPROUTLOUD_ERROR = 'LOADING_SPROUTLOUD_ERROR';

export const initialState = {
  flyerServiceisLoading: false,
  flyerServiceisError: false,
  flyerServicessoResponse: {},

  pinnacleisLoading: false,
  pinnacleisError: false,
  pinnaclessoResponse: {},

  sproutloudisLoading: false,
  sproutloudisError: false,
  sproutloudssoResponse: {}
};

export const actionCreators = {
  getFlyerService: () => {
    return createFetchAction({
      url: `/api/v1/flyerservice`,
      startAction: LOADING_FLYERSERVICE,
    });
  },
  getPinnacle: () => {
    let user = getProfile();

    return createFetchAction({
      url: `/api/v1/pinnacle?firstname=${user.firstname}&lastname=${user.lastname}`,
      startAction: LOADING_PINNACLE
    });
  },
  getSproutLoud: (targetUrl) => {
    return createFetchAction({
      url: `/api/v1/sproutloud` + (targetUrl && targetUrl.length ? `?targetUrl=${targetUrl}` : ''),
      startAction: LOADING_FLYERSERVICE,
    });
  }
};


export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === LOADING_FLYERSERVICE) {
    return {
      ...state,
      flyerServiceisLoading: true,
      flyerServiceisError: false,
    };
  }

  if (action.type === LOADING_FLYERSERVICE_SUCCESS) {

    return {
      ...state,
      flyerServiceisLoading: false,
      flyerServiceisError: false,
      flyerServicessoResponse: action.payload.data
    };
  }

  if (action.type === LOADING_FLYERSERVICE_ERROR) {
    return {
      ...state,
      flyerServiceisLoading: false,
      flyerServiceisError: true,
    };
  }

  if (action.type === LOADING_PINNACLE) {
    return {
      ...state,
      pinnacleisLoading: true,
      pinnacleisError: false,
    };
  }

  if (action.type === LOADING_PINNACLE_SUCCESS) {

    return {
      ...state,
      pinnacleisLoading: false,
      pinnacleisError: false,
      pinnaclessoResponse: action.payload.data
    };
  }

  if (action.type === LOADING_PINNACLE_ERROR) {
    return {
      ...state,
      pinnacleisLoading: false,
      pinnacleisError: true,
    };
  }

  if (action.type === LOADING_SPROUTLOUD) {
    return {
      ...state,
      sproutloudisLoading: true,
      sproutloudisError: false,
    };
  }

  if (action.type === LOADING_SPROUTLOUD_SUCCESS) {

    return {
      ...state,
      sproutloudisLoading: false,
      sproutloudisError: false,
      sproutloudssoResponse: action.payload.data
    };
  }

  if (action.type === LOADING_SPROUTLOUD_ERROR) {
    return {
      ...state,
      sproutloudisLoading: false,
      sproutloudisError: true,
    };
  }

  return state;
};