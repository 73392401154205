import {Card, CardBody, CardFooter, CardHeader, LoadingDots} from '@met/react-components';
import React, {Fragment} from 'react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import styled from 'styled-components';

const OrderSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .2rem 0;
  width: 100%;
`;

const Label = styled.span`
  font-size: 1.1rem;
  margin-right: 15px;
`;

const Value = styled.span`
  font-size: 1rem;
  font-weight: 700;
  word-break: break-all;
`;

const Disclaimer = styled.span`
  font-size: .8rem;
`;

const PaymentSummary = ({isLoading, transaction}) => {
  if (!isLoading && !transaction)
    return null;
  
  
const totalLabel = transaction && transaction.externalIds.JOURNAL ? (<Label><FormattedMessage id='general.total' />:</Label>) :
  (<Label><FormattedMessage id='general.subtotal' />:</Label>);
  
  return (
    <Card>
      <CardHeader><FormattedMessage id='square.paymentSummary' /></CardHeader>
      <CardBody>
        <OrderSummary>
          <Label><FormattedMessage id='square.referenceNumber' />:</Label>
          <Value>
            {
              isLoading ? <LoadingDots/> : transaction.externalIds
                && (transaction.externalIds.SALESORDER || transaction.externalIds.SERVICEORDER || transaction.externalIds.JOURNAL)
            }
          </Value>
        </OrderSummary>
        <OrderSummary>
          <Label><FormattedMessage id='general.purchaseOrderNumber' />:</Label>
          <Value>
            {
            isLoading ? <LoadingDots/> : transaction.externalIds
              && (transaction.externalIds.PURCHASE_ORDER_NUMBER || '-')
            }
          </Value>
        </OrderSummary>
        <OrderSummary>
          <Label><FormattedMessage id='square.accountNumber'/>:</Label>
          <Value>
            {
              isLoading ? <LoadingDots/> : transaction.accountNumber
            }
          </Value>
        </OrderSummary>
        <OrderSummary>
          <Label><FormattedMessage id='square.email' />:</Label>
          <Value>
            {
              isLoading ? <LoadingDots/> : transaction.emailAddress
            }
          </Value>
        </OrderSummary>
      </CardBody>
      <CardFooter>
        <OrderSummary>
          {isLoading ? <LoadingDots/> :
            (
              <Fragment>
              {totalLabel}
            
              <FormattedNumber
              style='currency'  // eslint-disable-line react/style-prop-object
              currency={transaction ? transaction.currencyCode : 'USD'}
              value={transaction ? (transaction.total / 100) : 0}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              />
              </Fragment>
            )            
          }  
        </OrderSummary>
        {
          isLoading || (transaction && transaction.externalIds.JOURNAL) ? "" : (
            <Disclaimer>Applicable shipping and taxes will be applied at time of invoicing</Disclaimer>
          )
        }
      </CardFooter>
    </Card>
  );
};

export default PaymentSummary;