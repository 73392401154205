import createFetchAction from './createFetchAction';

export const LOADING_INDEX_PRODUCT = 'LOADING_INDEX_PRODUCT';
export const LOADING_INDEX_PRODUCT_SUCCESS = 'LOADING_INDEX_PRODUCT_SUCCESS';
export const LOADING_INDEX_PRODUCT_ERROR = 'LOADING_INDEX_PRODUCT_ERROR';

export const LOADING_INDEX_PRODUCTS = 'LOADING_INDEX_PRODUCTS';
export const LOADING_INDEX_PRODUCTS_SUCCESS = 'LOADING_INDEX_PRODUCTS_SUCCESS';
export const LOADING_INDEX_PRODUCTS_ERROR = 'LOADING_INDEX_PRODUCTS_ERROR';

export const LOADING_FEATURED_PRODUCTS = 'LOADING_FEATURED_PRODUCTS';
export const LOADING_FEATURED_PRODUCTS_SUCCESS = 'LOADING_FEATURED_PRODUCTS_SUCCESS';
export const LOADING_FEATURED_PRODUCTS_ERROR = 'LOADING_FEATURED_PRODUCTS_ERROR';

export const CLEAR_INDEX_PRODUCT = 'CLEAR_INDEX_PRODUCT';
export const CLEAR_INDEX_PRODUCTS = 'CLEAR_INDEX_PRODUCTS';
export const CLEAR_FEATURED_PRODUCTS = 'CLEAR_FEATURED_PRODUCTS';

export const initialState = {
  getIndexProduct: {
    product: {
      categories: [],
      images: [],
      includes: [],
      options: [],
      relatedAccessories: [],
      relatedProducts: [],
      sizes: []
    },
    isLoading: false,
    isError: false
  },
  getIndexProducts: {
    products: [],
    totalPages: 0,
    totalCount: undefined,
    count: 0,
    isLoading: false,
    isError: false
  },
  getFeaturedProducts: {
    products: [],
    isLoading: false,
    isError: false
  }
};

export const actionCreators = {
  getIndexProduct: (sku, locale) => {
    switch (locale) {
      case 'en':
        locale = 'en-US';
        break;
      case 'es':
        locale = 'es-US';
        break;
      case 'fr':
        locale = 'fr-CA';
        break;
      default:
        locale = 'en-US';
        break;
    }

    return createFetchAction({
      url: `/api/v1/productsearch/${sku}?lang=${locale}`,
      startAction: LOADING_INDEX_PRODUCT
    });
  },
  getIndexProducts: (query, locale) => {
    switch (locale) {
      case 'en':
        locale = 'en-US';
        break;
      case 'es':
        locale = 'es-MX';
        break;
      case 'fr':
        locale = 'fr-CA';
        break;
      default:
        locale = 'en-US';
        break;
    }
    
    return createFetchAction({
      url: `/api/v1/productsearch${query || '?'}&lang=${locale}`,
      startAction: LOADING_INDEX_PRODUCTS
    });
  },
  getFeaturedProducts: (locale) => {
    switch (locale) {
      case 'en':
        locale = 'en-US';
        break;
      case 'es':
        locale = 'es-MX';
        break;
      case 'fr':
        locale = 'fr-CA';
        break;
      default:
        locale = 'en-US';
        break;
    }

    return createFetchAction({
      url: `/api/v1/productsearch/pipelineProducts`,
      startAction: LOADING_FEATURED_PRODUCTS
    });
  },
  getIndexProductWarranty: (sku, locale) => {
    switch (locale) {
      case 'en':
        locale = 'en-US';
        break;
      case 'es':
        locale = 'es-MX';
        break;
      case 'fr':
        locale = 'fr-CA';
        break;
      default:
        locale = 'en-US';
        break;
    }
    
    return createFetchAction({
      url: `/api/v1/productsearch/warranty/${sku}?lang=${locale}`,
      startAction: LOADING_INDEX_PRODUCT
    });
  },
  getIndexProductsAdmin: (query) => {
    return createFetchAction({
      url: `/api/v1/productsearch/admin${query || '?'}`,
      startAction: LOADING_INDEX_PRODUCTS
    });
  },
  clearIndexProducts: () => {
    return {type: CLEAR_INDEX_PRODUCTS};
  },
  clearIndexProduct: () => {
    return {type: CLEAR_INDEX_PRODUCT};
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type){
    case LOADING_INDEX_PRODUCT:
      return {
        ...state,
        getIndexProduct: {
          isLoading: true,
          isError: false
        }
      };
    case LOADING_INDEX_PRODUCT_ERROR:
      return {
        ...state,
        getIndexProduct: {
          isLoading: false,
          isError: true
        }
      };
    case LOADING_INDEX_PRODUCT_SUCCESS:
      return {
        ...state,
        getIndexProduct: {
          product: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_INDEX_PRODUCTS:
      return {
        ...state,
        getIndexProducts: {
          isLoading: true,
          isError: false
        }
      };
    case LOADING_INDEX_PRODUCTS_SUCCESS:
      return {
        ...state,
        getIndexProducts: {
          products: action.payload.data,
          totalPages: action.payload.totalPages,
          totalCount: action.payload.totalCount,
          count: action.payload.count,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_INDEX_PRODUCTS_ERROR:
      return {
        ...state,
        getIndexProducts: {
          isLoading: false,
          isError: true
        }
      };
    case LOADING_FEATURED_PRODUCTS:
      return {
        ...state,
        getFeaturedProducts: {
          isLoading: true,
          isError: false
        }
      };
    case LOADING_FEATURED_PRODUCTS_SUCCESS:
      return {
        ...state,
        getFeaturedProducts: {
          products: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        getFeaturedProducts: {
          isLoading: false,
          isError: true
        }
      };
    case CLEAR_INDEX_PRODUCT:
      return {
        ...state,
        getIndexProduct: {
          ...initialState.getIndexProduct
        }
      };
    case CLEAR_INDEX_PRODUCTS:
      return {
        ...state,
        getIndexProducts: {
          ...initialState.getIndexProducts
        }
      };
    case CLEAR_FEATURED_PRODUCTS:
      return {
        ...state,
        getFeaturedProducts: {
          ...initialState.getFeaturedProducts
        }
      };
    default:
      return state;
  }
};