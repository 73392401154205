import {
  Button, Card, CardBody,
  Col,
  Container,
  ListGroup,
  Row
} from 'reactstrap';
import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import MenuItem from "./MenuItem";
import classNames from 'classnames';
import history from '../../history';
import queryString from '../../helpers/queryString';

class FilterMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }
  
  toggle = () => {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen
    });

    // Prevent body from scrolling when Filter menu is open
    let body = document.getElementsByTagName('body')[0];
    if (!this.state.isOpen)
      body.style["overflow-y"] = "hidden";
    else
      body.removeAttribute("style");
  };
  
  handleBatterySystemClick = (system, isActive) => {
    let batterySystems = [...this.props.batterySystems];
    
    if (isActive)
      batterySystems.push(system.toLowerCase());
    else
      batterySystems = batterySystems.filter(e => e !== system.toLowerCase());

    let route = queryString.update('bs', batterySystems.join(','));
    route = queryString.update('p', null, route);
    history.push(route);
  };
  
  handlePowerSourceClick = (powerSource, isActive) => {
    let powerSources = [...this.props.powerSources];

    if (isActive)
      powerSources.push(powerSource.toLowerCase());
    else
      powerSources = powerSources.filter(e => e !== powerSource.toLowerCase());
    
    let route = queryString.update('ps', powerSources.join(','));
    route = queryString.update('p', null, route);
    history.push(route);
  };
  
  handleCategoryClick = (category, isActive) => {
    let categories = [...this.props.categories];
    
    if (isActive)
      categories.push(category.toLowerCase());
    else
      categories = categories.filter(e => e !== category.toLowerCase());
    
    let route = queryString.update('c', categories.join(','));
    route = queryString.update('p', null, route);
    history.push(route);
  };

  handleEmployeeAvailableClick = (category) => {
    let isEmployeeAvailable = !this.props.isEmployeeAvailable;

    let route = queryString.update('ea', isEmployeeAvailable ? isEmployeeAvailable : null);
    route = queryString.update('p', null, route);
    history.push(route);
  };
  
  render() {
    let navClasses = classNames(
      'filter-menu-list',
      this.state.isOpen && 'show'
    );

    let overlay = (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className='filter-menu-overlay' onClick={this.toggle}></div>
    );
    
    return (      
      <div className='filter-menu'>
        <Button className='filter-menu-toggle d-md-none mb-3' color='primary' onClick={this.toggle}>Filter</Button>
        <div className={navClasses}>
          <Container>
            <Row>
              <Col className='text-right'>
                <FontAwesomeIcon className='is-mock-link d-md-none' icon='times' size='lg' onClick={this.toggle}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className='font-weight-bold'>Filter</h5>
                <h6 className='mt-3'>Power Source</h6>
                <Card>
                  <CardBody data-test-id='filter-power-source' className='p-0'>
                    <ListGroup>
                      <MenuItem
                        onClick={this.handlePowerSourceClick}
                        value={'Cordless'}
                        isActive={this.props.powerSources.includes('cordless')}
                      />
                      <MenuItem 
                        onClick={this.handlePowerSourceClick}
                        value={'Corded'}
                        isActive={this.props.powerSources.includes('corded')}
                      />
                    </ListGroup>
                  </CardBody>
                </Card>
                <h6 className='mt-3'>System</h6>
                <Card>
                  <CardBody data-test-id='filter-system' className='p-0'>
                    <ListGroup>
                      <MenuItem
                        onClick={this.handleBatterySystemClick}
                        value={'M4'}
                        isActive={this.props.batterySystems.includes('m4')}
                      />
                      <MenuItem
                        onClick={this.handleBatterySystemClick}
                        value={'M12'}
                        isActive={this.props.batterySystems.includes('m12')}
                      />
                      <MenuItem
                        onClick={this.handleBatterySystemClick}
                        value={'M18'}
                        isActive={this.props.batterySystems.includes('m18')}
                      />
                      <MenuItem
                        onClick={this.handleBatterySystemClick}
                        value={'M28'}
                        isActive={this.props.batterySystems.includes('m28')}
                      />
                      <MenuItem
                        onClick={this.handleBatterySystemClick}
                        value={'MX FUEL'}
                        isActive={this.props.batterySystems.includes('mx fuel')}
                      />
                    </ListGroup>
                  </CardBody>
                </Card>
                <h6 className='mt-3'>Categories</h6>
                <Card>
                  <CardBody data-test-id='filter-categories' className='p-0'>
                    <ListGroup>
                      <MenuItem
                        onClick={this.handleCategoryClick}
                        value={'Combo Kits'}
                        isActive={this.props.categories.includes('combo kits')}
                      />
                      <MenuItem
                        onClick={this.handleCategoryClick}
                        value={'Power Tools'}
                        isActive={this.props.categories.includes('power tools')}
                      />
                      <MenuItem
                        onClick={this.handleCategoryClick}
                        value={'Hand Tools'}
                        isActive={this.props.categories.includes('hand tools')}
                      />
                      <MenuItem
                        onClick={this.handleCategoryClick}
                        value={'Accessories'}
                        isActive={this.props.categories.includes('accessories')}
                      />
                      <MenuItem
                        onClick={this.handleCategoryClick}
                        value={'Outdoor Power Equipment'}
                        isActive={this.props.categories.includes('outdoor power equipment')}
                      />
                      <MenuItem
                        onClick={this.handleCategoryClick}
                        value={'Work Gear'}
                        isActive={this.props.categories.includes('work gear')}
                      />
                      {this.props.featureToggles?.EmployeeOrdersDisabled === false && this.props.isEmployeeAccount &&
                        <MenuItem
                          onClick={this.handleEmployeeAvailableClick}
                          value={'Employee Purchase Available'}
                          isActive={this.props.isEmployeeAvailable}
                        />
                      }
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {overlay}
      </div>
    );
  }
}

export default FilterMenu;