import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import camelcase from 'camelcase';
import React, {Component, Fragment} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {toastr} from 'react-redux-toastr';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {Button, Dropdown, Input} from '../../../components';

let typeOptions = [
  { value: 1, label: 'Sku'},
  { value: 2, label: 'Service Product Line'}
];

class WarrantyAutoApproveRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviewing: false,
      isDeleting: false,
      isEditing: false,
      isNew: this.props.isNew,
      id: this.props.filter ? this.props.filter.id : 0,
      type: this.props.filter ? this.props.filter.type : 0,
      value: this.props.filter ? this.props.filter.value : ''
    }
  }

  filterType = (type) => {
    switch(type) {
      case 1: 
        return 'Sku';
      case 2:
        return 'Service Product Line';
      default:
        return '';
    }
  };

  toggleEdit = () => {
    this.setState({
      ...this.state,
      isEditing: !this.state.isEditing
    });
  };

  handleTypeChange = (option) => {
    this.setState({
      ...this.state,
      type: option.value
    });
  };

  handleInputChange = (e) => {
    let name = camelcase(e.name);
    this.setState({
      ...this.state,
      [name]: e.value
    });
  };

  saveEdits = () => {
    const {intl} = this.props;
    if (this.state.type > 0 && this.state.value) {
      this.props.onSave({
        id: this.state.id,
        type: this.state.type,
        value: this.state.value
      });
    } else {
      toastr.error(intl.formatMessage({id: 'warrantyAutoApproveFilters.errorSavingFilterTitle'}), intl.formatMessage({id: 'warrantyAutoApproveFilters.errorFilterTypeAndValueRequired'}))
    }
  };

  toggleDeletionConfirmation = () => {
    this.setState({
      ...this.state,
      isDeleting: !this.state.isDeleting
    });
  };
  
  handleDelete = () => {
    this.setState({
      ...this.state,
      isDeleting: !this.state.isDeleting
    });
    this.props.onDelete(this.props.filter.id);
  };

  render() {
    const {filter, intl} = this.props;
    let typeMarkup;
    if (this.state.isEditing || this.state.isNew) {
      typeMarkup = (
        <Dropdown 
            onChange={this.handleTypeChange}
            defaultValue={typeOptions.filter(opt => {return opt.value === filter.type})}
            options={typeOptions}/>
      );
    } else {
      typeMarkup = (
      <Fragment>
        {this.filterType(filter.type)}
      </Fragment>
      );
    }

    let valueMarkup;
    if (this.state.isEditing || this.state.isNew) {
      valueMarkup = (
        <Input 
          type='text'
          name='value'
          id='value'
          value={this.state.value}
          onChange={this.handleInputChange}
        />
      );
    } else {
      valueMarkup = (
      <Fragment>
        {filter.value}
      </Fragment>
      );
    }

    let actionMarkup;
    if (this.state.isNew) {
      actionMarkup = (
        <Button color='primary' onClick={this.saveEdits}>Create</Button>
      )
    } else if (this.state.isEditing) {
      actionMarkup = (
        <Fragment>
          <Button color='primary' onClick={this.saveEdits}>Save</Button>{' '}
          <Button onClick={this.toggleEdit}>Cancel</Button>
        </Fragment>
      );
    } else {
      actionMarkup = (
      <Fragment>
        <FontAwesomeIcon className='is-mock-link text-secondary' icon='pencil-alt' onClick={this.toggleEdit} />{' '}
        <FontAwesomeIcon className='is-mock-link text-secondary mx-2' icon='trash-alt' onClick={this.toggleDeletionConfirmation} />
      </Fragment>
      );
    }
    return (
      <tr>
        <td data-label={intl.formatMessage({id: 'warrantyAutoApproveFilters.type'})}>
          {typeMarkup}
        </td>
        <td data-label={intl.formatMessage({id: 'warrantyAutoApproveFilters.value'})}>
          {valueMarkup}
        </td>
        <td data-label={intl.formatMessage({id: 'warrantyAutoApproveFilters.actions'})}>
          {actionMarkup}
        </td>
        <Modal isOpen={this.state.isDeleting} toggle={this.toggleDeletionConfirmation} centered={true}>
          <ModalHeader toggle={this.toggle}>
            <FormattedMessage id='warrantyAutoApproveFilters.confirmDeleteModalTitle'/>
          </ModalHeader>
          <ModalBody>
            <div><FormattedMessage id='warrantyAutoApproveFilters.confirmDeleteModalBody'/></div>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggleDeletionConfirmation}>
              <FormattedMessage id='general.cancel'/>
            </Button>{' '}
            <Button color='primary' onClick={this.handleDelete}>
              <FormattedMessage id='warrantyAutoApproveFilters.confirmDeleteButton'/>
            </Button>
          </ModalFooter>
        </Modal>
      </tr>
    )
  }
}

export default injectIntl(WarrantyAutoApproveRow);