import createFetchAction from './createFetchAction';

export const LOADING_ACTIVE_USER = 'LOADING_ACTIVE_USER';
export const LOADING_ACTIVE_USER_SUCCESS = 'LOADING_ACTIVE_USER_SUCCESS';
export const LOADING_ACTIVE_USER_ERROR = 'LOADING_ACTIVE_USER_ERROR';

export const SENDING_ACTIVATION_REQUEST = 'SENDING_ACTIVATION_REQUEST';
export const SENDING_ACTIVATION_REQUEST_SUCCESS = 'SENDING_ACTIVATION_REQUEST_SUCCESS';
export const SENDING_ACTIVATION_REQUEST_ERROR = 'SENDING_ACTIVATION_REQUEST_ERROR';

export const initialState = {
  user: {
    data: null,
    isLoading: false,
    isError: false
  },
  accountActivation: {
    isProcessing: false,
    isError: false
  }
};

export const actionCreators = {
  getActiveUser: () => {
    return createFetchAction({
      url: `/api/v1/users/_actions/getactiveuser`,
      startAction: LOADING_ACTIVE_USER,
    });
  },
  activateAccount: (accountNumber) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/users/_actions/activateaccount?accountNumber=${accountNumber}`,
      startAction: SENDING_ACTIVATION_REQUEST,
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  
  switch(action.type) {
    case LOADING_ACTIVE_USER:
      return {
        ...state,
        user: {
          data: null,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_ACTIVE_USER_SUCCESS:
      return {
        ...state,
        user: {
          data: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_ACTIVE_USER_ERROR:
      return {
        ...state,
        user: {
          data: null,
          isLoading: false,
          isError: false
        }
      };
      
    case SENDING_ACTIVATION_REQUEST:
      return {
        ...state,
        accountActivation: {
          isProcessing: true,
          isError: false
        }
      };
    case SENDING_ACTIVATION_REQUEST_ERROR:
      return {
        ...state,
        accountActivation: {
          isProcessing: false,
          isError: true
        }
      };
    case SENDING_ACTIVATION_REQUEST_SUCCESS:
      return {
        ...state,
        accountActivation: {
          isProcessing: false,
          isError: false
        }
      };
      
    default:
      return state;
  }
};