import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardBody, CardHeader} from '@met/react-components'
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {ListGroup} from 'reactstrap';
import PageLoader from "../../components/PageLoader";
import AnnouncementItem from './AnnouncementItem';

class Announcements extends Component {
  render() {
    let markup;
    
    if (this.props.isLoading) {
      markup = <PageLoader marginSize='sm'/>;
    }
    else if (this.props.announcements.length === 0) {
      markup = <div>There are no announcements</div>;
    }
    else {
      markup = this.props.announcements.map((announcement, index) => {
        return (
          <AnnouncementItem key={`announcement-${index}`} announcement={announcement}/>
        );
      });
    }

    return (
      <Card className='info-card'>
        <CardHeader className='header-primary d-flex align-items-center'>
          <FontAwesomeIcon icon='bullhorn' className='is-color-dark-gray mr-2'/>
          <FormattedMessage id='home.announcementsCardTitle'/>
        </CardHeader>
        <CardBody>
          <ListGroup flush>
            {markup}
          </ListGroup>
        </CardBody>
      </Card>
    );
  }
}

export default injectIntl(Announcements);