import Enumerable from 'linq';

import createFetchAction from './createFetchAction';

export const LOADING_ADDRESSES = 'LOADING_ADDRESSES';
export const LOADING_ADDRESSES_SUCCESS = 'LOADING_ADDRESSES_SUCCESS';
export const LOADING_ADDRESSES_ERROR = 'LOADING_ADDRESSES_ERROR';

export const UPSERT_ADDRESS = 'UPSERT_ADDRESS';
export const UPSERT_ADDRESS_SUCCESS = 'UPSERT_ADDRESS_SUCCESS';
export const UPSERT_ADDRESS_ERROR = 'UPSERT_ADDRESS_ERROR';

export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_ERROR = 'DELETE_USER_ADDRESS_ERROR';

export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';

export const initialState = {
  userAddresses: null,
  selectedAddress: null,
  getAddresses: {
    isLoading: false,
    isError: false
  },
  upsertAddress: {
    isProcessing: false,
    isError: false
  },
  deleteUserAddress: {
    isProcessing: false,
    isError: false
  }
};

export const actionCreators = {
  getUserAddresses: () => {
    return createFetchAction({
      url: `/api/v1/addresses/_actions/getactiveuseraddresses`,
      startAction: LOADING_ADDRESSES
    });
  },
  upsertAddress: ({addressLine1, addressLine2, city, state, countryCode, postalCode, id}) => {
    return createFetchAction({
      method: 'POST',
      url: '/api/v1/addresses/_actions/upsertuseraddress',
      body: {
        addressLine1,
        addressLine2,
        city,
        state,
        countryCode,
        postalCode,
        addressId: id
      },
      startAction: UPSERT_ADDRESS,
      actionPayload: {editedAddressId: id}
    });
  },
  deleteAddress: (addressId) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/api/v1/addresses/_actions/deleteuseraddress/${addressId}`,
      startAction: DELETE_USER_ADDRESS
    })
  },
  setSelectedAddress: (addressId) => {
    if (!addressId) return {type: 'NOOP'};

    return {
      type: SET_SELECTED_ADDRESS,
      payload: addressId
    }
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case LOADING_ADDRESSES:
      return {
        ...state,
        getAddresses: {
          isLoading: true,
          isError: false
        }
      };
    case LOADING_ADDRESSES_SUCCESS:
      return {
        ...state,
        userAddresses: action.payload.data,
        selectedAddress: action.payload.data.length > 0 ? action.payload.data[0] : null,
        getAddresses: {
          isLoading: false,
          isError: false
        }
      };
    case LOADING_ADDRESSES_ERROR:
      return {
        ...state,
        getAddresses: {
          isLoading: false,
          isError: true
        }
      };
      
    case UPSERT_ADDRESS:
      return {
        ...state,
        upsertAddress: {
          isProcessing: true,
          isError: false
        }
      };
    case UPSERT_ADDRESS_SUCCESS:{
      let userAddresses = [...state.userAddresses];
      
      // if we were editing an address, replace it with the one returned from the upsert
      if (action.editedAddressId) {
        let index = userAddresses.findIndex(x => x.id === action.editedAddressId);
        userAddresses[index] = action.payload.data;
      }
      // otherwise, simply append it to the array
      else {
        userAddresses = [...state.userAddresses, action.payload.data]
      }
      
      return {
        ...state,
        userAddresses,
        selectedAddress: action.payload.data,
        upsertAddress: {
          isProcessing: false,
          isError: false
        }
      };
    }
    case UPSERT_ADDRESS_ERROR:
      return {
        ...state,
        upsertAddress: {
          isProcessing: false,
          isError: true
        }
      };
      
    case DELETE_USER_ADDRESS:
      return {
        ...state,
        deleteUserAddress: {
          isProcessing: true,
          isError: false
        }
      };
    case DELETE_USER_ADDRESS_SUCCESS:{
      let filteredAddresses = state.userAddresses.filter(address => address.id !== action.payload.data);

      return {
        ...state,
        userAddresses: filteredAddresses,
        selectedAddress: state.selectedAddress.id === action.payload.data && filteredAddresses.length > 0 ? filteredAddresses[0] : filteredAddresses.length === 0 ? null : state.selectedAddress,
        deleteUserAddress: {
          isProcessing: false,
          isError: false
        }
      };
    }
    case DELETE_USER_ADDRESS_ERROR:
      return {
        ...state,
        deleteUserAddress: {
          isProcessing: false,
          isError: true
        }
      };
    case SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: Enumerable.from(state.userAddresses).firstOrDefault(x => x.id === action.payload)
      };
      
    default:
      return state;
  }
};