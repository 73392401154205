import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Pagination from 'react-js-pagination';
import {Table} from 'reactstrap';
import {Message} from '../../components';
import {Price} from '../../components';

class PartsListTable extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentPage: 1
    }
  }

  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      currentPage: pageNumber
    });
  };
  
  getPartsValidationError = (id, itemNumber) => {
    switch (id) {
      case 1:
        return this.props.intl.formatMessage({id: 'warranty.partsValidation.notInCatalog'}, {itemNumber: itemNumber});
      case 2:
        return this.props.intl.formatMessage({id: 'warranty.partsValidation.invalidTool'}, {itemNumber: itemNumber});
      case 3:
        return this.props.intl.formatMessage({id: 'warranty.partsValidation.invalidComponent'}, {itemNumber: itemNumber});
      case 4:
        return this.props.intl.formatMessage({id: 'warranty.partsValidation.unauthorizedRepair'}, {itemNumber: itemNumber});
      default:
        return '';
    }
  };
  
  render() {
    const { parts, validationErrors, paginate, linesLimit, intl, showPrices } = this.props;
    
    let rows;
    let validationErrorsMarkup;
    let pagination;
    
    if (parts && parts.length > 0) {
      let partsEnum = Enumerable.from(parts);
      if (linesLimit)
        partsEnum = partsEnum.skip((this.state.currentPage - 1) * linesLimit).take(linesLimit);

      rows = partsEnum.select((part, index) => {
        let listPriceMarkup = part.listPrice !== undefined ? <Price value={part.listPrice} canBeZero /> : <FormattedMessage id={'warranty.pricingMessage'}/>;
        let unitPriceMarkup = part.unitPrice !== undefined ? <Price value={part.unitPrice} canBeZero /> : <FormattedMessage id={'warranty.pricingMessage'}/>;
        let extendedPriceMarkup = part.unitPrice !== undefined ? <Price value={part.unitPrice * part.quantity} canBeZero /> : <FormattedMessage id={'warranty.pricingMessage'}/>;
        return (
          <tr className='parts-list-item' key={`part-${index}`}>
            <td data-label={intl.formatMessage({id: 'general.quantity'})} className='min-width'>
              <span data-test-id={`parts-list-quantity-${part.itemNumber}`} className='text-uppercase no-wrap'>{part.quantity}</span>
            </td>
            <td data-label={intl.formatMessage({id: 'general.itemNumber'})} className='min-width'>
              <span data-test-id={`parts-list-item-number-${part.itemNumber}`} className='text-uppercase no-wrap'>{part.itemNumber}</span>
            </td>
            <td data-label={intl.formatMessage({id: 'general.description'})} className='description-column min-width'>
              <span data-test-id={`parts-list-description-${part.itemNumber}`} className='text-uppercase'>{part.description}</span>
            </td>
            {showPrices && 
              <Fragment>
                <td data-label={intl.formatMessage({id: 'pricing.listPrice'})}>
                  {listPriceMarkup}
                </td>
                <td data-label={intl.formatMessage({id: 'pricing.reimbursementPrice'})}>
                  {unitPriceMarkup}
                </td>
                <td data-label={intl.formatMessage({id: 'pricing.extendedPrice'})}>
                  {extendedPriceMarkup}
                </td>
              </Fragment>
            }
          </tr>
        );
      }).toArray();

      validationErrorsMarkup = validationErrors && validationErrors.map((error, index) => {
        return (
          <Fragment key={`validation-error-${index}`}>
            <Message
              type='warning'
              icon='exclamation-triangle'
              value={this.getPartsValidationError(error.errorCode, error.itemNumber)}
            />
          </Fragment>
        );
      });
      
      pagination = paginate && parts.length > linesLimit && (
        <div className='mb-2'>
          <Pagination
            activePage={this.state.currentPage}
            itemsCountPerPage={linesLimit}
            totalItemsCount={parts.length}
            pageRangeDisplayed={8}
            innerClass='pagination justify-content-center flex-wrap'
            itemClass='page-item'
            linkClass='page-link'
            nextPageText={<FontAwesomeIcon icon='angle-right'/>}
            prevPageText={<FontAwesomeIcon icon='angle-left'/>}
            lastPageText={<FontAwesomeIcon icon='angle-double-right'/>}
            firstPageText={<FontAwesomeIcon icon='angle-double-left'/>}
            hideFirstLastPages={false}
            onChange={this.handlePageChange}
          />
        </div>
      );
    }
    
    return (
      <Fragment>
        {rows && <Table striped borderless responsive className='parts-list-table'>
          <thead>
          <tr>
            <th className='min-width'><FormattedMessage id='general.quantityAbbr'/></th>
            <th className='min-width'><FormattedMessage id='general.itemNumber'/></th>
            <th className='min-width'><FormattedMessage id='general.description'/></th>
            {showPrices &&
              <Fragment>
                <th><FormattedMessage id='pricing.listPrice'/></th>
                <th><FormattedMessage id='pricing.reimbursementPrice'/></th>
                <th><FormattedMessage id='pricing.extendedPriceAbbr'/></th>
              </Fragment>
            }
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
         </Table>
        }
        {validationErrorsMarkup}
        {pagination}
      </Fragment>
    )
  }
}

PartsListTable.propTypes = {
  parts: PropTypes.array,
  validationErrors: PropTypes.array,
  paginate: PropTypes.bool,
  linesLimit: PropTypes.number,
  onQuantityChange: PropTypes.func,
  onPartLineTypeChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
  showPrices: PropTypes.bool
};

export default injectIntl(PartsListTable);