import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, FormGroup, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';

import { Button, Message, PageLoader, SearchInput } from '../../components';
import { productSearchActions, warrantyClaimActions } from '../../store';

class SearchProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sku: this.props.sku || ''
    }
  }

  componentDidMount() {
    if (this.state.sku === '')
      this.props.clearIndexProduct();
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter')
      this.search();
  };

  handleSearchClick = () => {
    this.search();
  };

  handleSearchChange = (e) => {
    this.setState({
      ...this.state,
      sku: e.target.value
    });
  };

  prev = () => {
    this.props.clearIndexProduct();
    this.props.prev();
  };

  clear = () => {
    this.setState({
      ...this.state,
      sku: ''
    }, () => {
      this.props.clearValidation();
      this.props.clearIndexProduct();
    });
  };

  search = () => {
    if (this.state.sku) {
      this.props.clearValidation();
      this.props.getIndexProductWarranty(this.state.sku);
    }
  };

  addItemToClaim = (sku) => {
    this.setState({
      ...this.state,
      sku,
      isAdding: true
    }, () => {
      this.props.validateClaim(this.state.sku);
    });
  };

  render() {
    const { validationErrors } = this.props.validatedClaim;
    const { product, isLoading } = this.props.productSearch;

    let blankResults = (
      <Container>
        <Row>
          <Col>
            <h5 className='my-3'>Product Search Results</h5>
            <ListGroup className='search-list'>
              <ListGroupItem className='text-center search-list-item'>
                <div className='my-4'>
                  <h3>No products found!</h3>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );

    let results = product && (
      <ListGroupItem className='search-list-item'>
        <Container fluid className='p-2'>
          <Row>
            <Col md='auto' xl='2' data-test-id='warranty-claim-product-search-image' className='text-center mx-lg-2 my-lg-auto align-self-center'>
              <img src={`/api/v1/images?url=${product.imageUrl}`} alt={product.description} />
            </Col>
            <Col className='is-color-red my-2 my-lg-auto mr-lg-1 align-self-center wrap-break-words'>
              <Container>
                <Row>
                  <Col>
                    <div data-test-id='warranty-claim-product-search-sku' className='mb-2'>{product.skuAlias}</div>
                    <div data-test-id='warranty-claim-product-search-name' dangerouslySetInnerHTML={{ __html: product.description }}
                      className='has-line-height-2 font-weight-bold' />
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md='auto' className='mb-2 my-lg-auto align-self-center'>
              <Container>
                <Row className='justify-content-center'>
                  <Col xs='auto' className='mt-3 mt-xl-0'>
                    <Button data-test-id='warranty-claim-product-add' outline color='primary' onClick={() => this.addItemToClaim(product.skuAlias)} isLoading={this.state.isAdding}>Add</Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </ListGroupItem>
    );

    let validationMessages = validationErrors && validationErrors.map((error, index) => {
      let errorMessage = '';
      let showEservice = true;
      switch (error.errorCode) {
        case 1:
          errorMessage = this.props.intl.formatMessage({ id: 'warranty.partsValidation.notInCatalog' }, { itemNumber: error.itemNumber });
          break;
        case 2:
          errorMessage = this.props.intl.formatMessage({ id: 'warranty.partsValidation.invalidTool' }, { itemNumber: error.itemNumber });
          break;
        case 3:
          errorMessage = this.props.intl.formatMessage({ id: 'warranty.partsValidation.invalidComponent' }, { itemNumber: error.itemNumber });
          break;
        case 4:
          errorMessage = this.props.intl.formatMessage({ id: 'warranty.partsValidation.unauthorizedRepair' }, { itemNumber: error.itemNumber });
          break;
        case 5:
          errorMessage = this.props.intl.formatMessage({ id: 'warranty.partsValidation.flatRatePriceNotSet' }, { itemNumber: error.itemNumber });
          showEservice = false;
          break;
        default:
          errorMessage = '';
          break;
      }
      return (

        <ListGroupItem key={`error-${index}`}>
          <Container fluid className='p-2'>
            <Row>
              <Col className='is-color-red my-2 my-lg-auto mr-lg-1 align-self-center wrap-break-words'>
                <Container>
                  <Row>
                    <Col>
                      <Message
                        type='warning'
                        icon='exclamation-triangle'
                        value={errorMessage}
                        className='mb-0'
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
              {showEservice &&
                <Col md='auto' className='mb-2 my-lg-auto align-self-center'>
                  <Container>
                    <Row className='justify-content-center'>
                      <Col xs='auto' className='mt-3 mt-xl-0'>
                        <a href='https://www.milwaukeetool.com/eService' target='_blank' className='btn btn-primary' rel='noopener noreferrer'>eService</a>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              }
            </Row>
          </Container>
        </ListGroupItem>
      )
    });

    const resultsList = isLoading ? <PageLoader /> : (product ? (product.sku ? (
      <Container>
        <Row>
          <Col>
            <h5 className='my-3'>Product Search Results</h5>
            <ListGroup className='search-list'>
              {validationMessages}
              {results}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    ) : null) : blankResults);

    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Container fluid>
                <Row>
                  <Col md='4'>
                    <FormGroup className='mr-sm-2 mb-sm-0'>
                      <Label for='product-number'>Search Products</Label>
                      <SearchInput
                        searchInputDataTestId='warranty-claim-search-input'
                        searchButtonDataTestId='warranty-claim-search-button'
                        value={this.state.sku}
                        onClear={this.clear} onKeyPress={this.handleKeyPress}
                        onChange={this.handleSearchChange} onClick={this.handleSearchClick}
                        isSearching={isLoading} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {resultsList}
                  </Col>
                </Row>
                <Row className='justify-content-end mt-4'>
                  <Col xs='auto'>
                    <Button onClick={this.prev}>Back</Button>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

SearchProduct.propTypes = {
  prev: PropTypes.func,
  validateClaim: PropTypes.func,
  match: PropTypes.object,
  sku: PropTypes.string
};

const mapStateToProps = state => {
  return {
    productSearch: state.productSearch.getIndexProduct,
    validatedClaim: state.warrantyClaim.validateClaim
  }
};

const mapDispatchToProps = dispatch => ({
  getIndexProductWarranty: (sku, locale) => dispatch(productSearchActions.getIndexProductWarranty(sku, locale)),
  clearIndexProduct: () => dispatch(productSearchActions.clearIndexProduct()),
  clearValidation: () => dispatch(warrantyClaimActions.clearValidation())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SearchProduct));