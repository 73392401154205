import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Col,
  Dropdown,
  Row,
  TextInput,
  ToggleSwitch,
  useForm,
  ValidationRegex,
} from '@met/react-components';
import Enumerable from 'linq';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {toastr} from 'react-redux-toastr';

export const UserForm = ({user, onSaveClick}) => {
  const firstNameRef = useRef(null);

  // app sate
  const accountDetails = useSelector(state => (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails : state.account.currentAccount.accountDetails));
  
  // local state
  const [saving, setSaving] = useState(false);
  const [accountLoading, setAccountLoading] = useState(true);
  const { form, validateForm, setValue } = useForm({
    firstName: {
      value: (user && user.firstName) || '',
      validationRegex: ValidationRegex.NotEmpty
    },
    lastName: {
      value: (user && user.lastName) || '',
      validationRegex: ValidationRegex.NotEmpty
    },
    email: {
      value: (user && user.email) || '',
      validationRegex: ValidationRegex.Email
    },
    accountNumber: {
      value: user && user.accountNumber
    },
    accountEnabled: {
      value: user ? user.connectUser : true
    },
    canPlaceOrders: {
      value: user ? user.canPlaceOrders : false
    },
    canSubmitWarrantyClaims: {
      value: user ? user.canSubmitWarrantyClaims : false
    },
    canMaintainCompanyAccounts: {
      value: user ? user.canMaintainCompanyAccounts : false
    }
  });

  useEffect(() => {
    if (firstNameRef.current) firstNameRef.current.focus();
  }, [firstNameRef]);
  
  useEffect(() => {
    if (accountDetails.accountNumber) {
      if (!user)
        setValue('accountNumber', accountDetails.accountNumber);
      setAccountLoading(false);
    }
  }, [accountDetails])

  /***
   * handleSaveClick will call the onSaveClick callback function with the current
   * form values in a condensed user object.
   */
  const handleSaveClick = () => {
    if (validateForm()) {
      setSaving(true);
      onSaveClick && onSaveClick({
        accountEnabled: form.accountEnabled.value,
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        email: form.email.value,
        accountNumber: form.accountNumber.value,
        canPlaceOrders: form.canPlaceOrders.value,
        canSubmitWarrantyClaims: form.canSubmitWarrantyClaims.value,
        canMaintainCompanyAccounts: form.canMaintainCompanyAccounts.value,
      }).finally(() => setSaving(false))
    }
    else {
      toastr.error('Please fill in the missing required fields.');
    }
  };

  const accounts = accountDetails.children
    ? Enumerable.from(accountDetails.children)
      .where(account => account.accountNumber !== accountDetails.accountNumber)
      .select(account => account.accountNumber)
      .concat([accountDetails.accountNumber])
      .select(accountNumber => {return {label: accountNumber, value: accountNumber}})
      .orderBy(option => option.value)
      .toArray()
    : [{label: accountDetails.accountNumber, value: accountDetails.accountNumber}];
  
  return (
    <React.Fragment>
      <Card>
        <CardHeader title='User Details'>
          <Col end='sm'>
            <div data-test-id='user-account-enabled'>
              <Checkbox
                checked={form.accountEnabled.value}
                label='Account Enabled'
                onCheck={({checked}) => setValue('accountEnabled', checked)}
              />
            </div>
          </Col>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={12} sm={6}>
              <label htmlFor='firstName'>First Name</label>
              <TextInput
                data-test-id='user-firstname-input'
                id='firstName'
                inputRef={firstNameRef}
                value={form.firstName.value}
                onChange={e => setValue('firstName', e.target.value)}
                invalid={form.firstName.isValid === false}
                errorMessage='First Name is required'
              />
            </Col>
            <Col xs={12} sm={6}>
              <label htmlFor='lastName'>Last Name</label>
              <TextInput
                data-test-id='user-lastname-input'
                id='lastName'
                value={form.lastName.value}
                onChange={e => setValue('lastName', e.target.value)}
                invalid={form.lastName.isValid === false}
                errorMessage='Last Name is required'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <label htmlFor='email'>Email</label>
              <TextInput
                data-test-id='user-email-input'
                id='email'
                value={form.email.value}
                onChange={e => setValue('email', e.target.value)}
                invalid={form.email.isValid === false}
                errorMessage='A valid email is required'
                disabled={user !== undefined && user !== null}
              />
            </Col>
            <Col xs={12} sm={6} data-test-id='user-account-number-input'>
              <label htmlFor='account'>Account #</label>
              <Dropdown
                id='account'
                windowItemSize={35}
                value={form.accountNumber.value}
                options={accounts}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                onChange={option => setValue('accountNumber', option.value)}
                loading={accountLoading}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ToggleSwitch
                id={1}
                checked={form.canPlaceOrders.value}
                label='Can Place Orders'
                labelSide='right'
                onChange={({checked}) => setValue('canPlaceOrders', checked)}
              />
              <ToggleSwitch
                id={2}
                checked={form.canSubmitWarrantyClaims.value}
                label='Can Submit Warranty Claims'
                labelSide='right'
                onChange={({checked}) => setValue('canSubmitWarrantyClaims', checked)}
              />
              <ToggleSwitch
                id={3}
                checked={form.canMaintainCompanyAccounts.value}
                label='Can Maintain Company Accounts'
                labelSide='right'
                onChange={({checked}) => setValue('canMaintainCompanyAccounts', checked)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row className='mb-2'>
        <Col end='xs'>
          <Button data-test-id='user-save' primary loading={saving} onClick={handleSaveClick}>Save</Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}