const checkout = {
  shippingAddressTitle: 'Adresse de Livraison',
  shippingSpeedTitle: 'Vitesse de Livraison',
  shippingSpeedMessage: `
      Les commandes passées pour expédition aux États-Unis peuvent choisir une vitesse d'expédition accélérée.
      N'oubliez pas que tous les frais de transport associés à ce service seront facturés sur votre facture.
      Les "collectes" ou les "facturations à des tiers" ne sont pas disponibles en ligne.
      {br}{br}
      Les commandes d'expédition par avion reçues avant 14h00 (heure centrale) seront expédiées le même jour ouvrable.
      Les week-ends et les jours fériés sont exclus.
      Les envois par voie terrestre seront expédiés sous 1-2 jours ouvrables.,
      {br}{br}
    `,
  disabledShippingSpeedMessage: `
      *Pour le moment, nous ne traitons pas le transport aérien ou accéléré le lendemain. Veuillez contacter votre représentant commercial pour toute question.
      {br}{br}
  `,
  customLogoShippingSpeedMessage: `
      Les envois Custom Logo Gear sont gérés par notre partenaire de personnalisation et des estimations seront envoyées à l'adresse électronique indiquée ci-dessus.
    `,
  paymentMethodTitle: 'Mode de paiement',
  requestedShipDateTitle: 'Date d\'expédition demandée',
  requestedShipDateMessage: 'Les dates d\'expédition sont déterminées par la disponibilité du produit, la distribution, les délais et les exigences d\'expédition.',
  orderSummaryTitle: 'Récapitulatif de la commande',
  errorSubmittingOrder: 'Une erreur s\'est produite lors de l\'envoi de votre commande. Veuillez réessayer plus tard.',
  validationWarningMessage: 'Purchase Order # is a required field',
  paymentMethodRequiredTitle: 'Méthode de paiement requise',
  paymentMethodRequiredMessage: 'Vous devez sélectionner un mode de paiement avant de valider.',
  addressRequiredTitle: 'Adresse de livraison requise',
  addressRequiredMessage: 'Vous devez sélectionner une adresse de livraison avant de valider votre commande.',
  addressRegionLockedTitle: 'Logo personnalisé non disponible',
  addressRegionLockedMessage: 'Vous avez des articles dans votre panier qui ne peuvent pas être expédiés en dehors de l\'Amérique'
};

export default checkout;