import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import auth from '../../auth';
import user from '../../helpers/user';
import LoadingDots from '../LoadingDots';

class ProductPrice extends Component {
  render() {
    const { pricing, sku, type } = this.props;

    let markup = '';
    if (!auth.isAuthenticated()) {
      markup = <FormattedMessage id='pricing.signInForPrice'/>;
    }
    else if (!user.canPlaceOrders()){
      markup = <FormattedMessage id='pricing.accountNeedsAccess'/>;
    }
    else if (sku === '') {
      markup = <FormattedMessage id='pricing.selectOptions'/>;
    }
    else if (pricing.priceMap[sku]) {
      let price = pricing.priceMap[sku][type];
      if (price && price > 0) {
        markup = <FormattedNumber
          style='currency'  // eslint-disable-line react/style-prop-object
          currency='USD'
          value={price}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />;
      }
      else if (pricing.priceMap[sku]?.error) {
        markup = (<span style={{ cursor: 'pointer'}}>
            <span title={pricing.priceMap[sku].error}>
              <FontAwesomeIcon className='mr-3' color='black' icon='exclamation-triangle' />
              <FormattedMessage id='general.pricingError' />
            </span>
          </span>);
      }
      else {
        markup = <FormattedMessage id='products.unavailable'/>;
      }
    }
    else if (pricing.isLoading) {
      markup = <LoadingDots />;
    }
    else {
      markup = <FormattedMessage id='products.unavailable'/>;
    }
    
    return (
      markup
    );
  }
}

ProductPrice.propTypes = {
  pricing: PropTypes.object.isRequired,
  sku: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['unitAmount', 'listAmount', 'msrp']).isRequired
};

export default injectIntl(ProductPrice);