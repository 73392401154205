import {
  library
} from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faCircle as regularCircle,
  faDotCircle,
  faSquare
} from '@fortawesome/free-regular-svg-icons';
import {
  faAddressCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAward,
  faBell,
  faBookReader,
  faBookOpen,
  faBox,
  faBullhorn,
  faCalendarAlt,
  faCertificate,
  faCheck,
  faCheckCircle,
  faCircle,
  faClipboardCheck,
  faCloudDownloadAlt,
  faComments,
  faCreditCard,
  faEllipsisVertical,
  faEnvelopeOpenText,
  faEye,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileAlt,
  faFileDownload,
  faFileExcel,
  faFileInvoice,
  faFileInvoiceDollar,
  faGraduationCap,
  faImage,
  faInfoCircle,
  faList,
  faMinus,
  faMoneyBillAlt,
  faPencilAlt,
  faPlus,
  faQuestionCircle,
  faRunning,
  faSearch,
  faShareAlt,
  faShieldAlt,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faSyncAlt,
  faTachometerAlt,
  faTh,
  faThList,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTruck,
  faTruckMonster,
  faTshirt,
  faUser,
  faUsers,
  faUserCircle,
  faVideo,
  faWrench
} from '@fortawesome/free-solid-svg-icons';

// Add fontawesome icons to our library
library.add(
  faAddressCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAward,
  faBell,
  faBookReader,
  faBookOpen,
  faBox,
  faBullhorn,
  faCalendarAlt,
  faCertificate,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faClipboardCheck,
  faCloudDownloadAlt,
  faComments,
  faCreditCard,
  faDotCircle,
  faEllipsisVertical,
  faEye,
  faEnvelopeOpenText,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileAlt,
  faFileDownload,
  faFileExcel,
  faFileInvoice,
  faFileInvoiceDollar,
  faGraduationCap,
  faImage,
  faInfoCircle,
  faList,
  faMinus,
  faMoneyBillAlt,
  faPencilAlt,
  faPlus,
  faQuestionCircle,
  regularCircle,
  faRunning,
  faSearch,
  faShareAlt,
  faShieldAlt,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faSquare,
  faSyncAlt,
  faTachometerAlt,
  faTh,
  faThList,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTruck,
  faTruckMonster,
  faTshirt,
  faUser,
  faUsers,
  faUserCircle,
  faVideo,
  faWrench
);