import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Enumerable from 'linq';
import React, { Component } from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { Card, CardBody, Col, Container, ListGroup, ListGroupItem, Nav, NavItem, NavLink, Row } from 'reactstrap';
import {
  Button,
  CalendarInput,
  Checkbox,
  PageLoader,
  Pagination,
  PaginationResultsCount,
  SearchInput
} from '../../components';
import {downloadBlob} from '../../helpers/downloadblob';
import {updateSession} from '../../helpers/localStorage';
import queryString from '../../helpers/queryString';
import user from '../../helpers/user';
import history from '../../history';
import {cartActions, ordersActions} from '../../store';
import OrderHistoryPreview from './OrderHistoryPreview';
import PendingOrderPreview from './PendingOrderPreview';
import { configurationActions } from '../../store';

class Orders extends Component {
  constructor(props){
    super(props);
    let values = queryString.parse();
    this.state = {
      query: values.q || '',
      queryString: window.location.search,
      currentPage: values.p ? parseInt(values.p) : 1,
      pageSize: 10,
      endDate: this.parseDate(values.sd),
      startDate: this.parseDate(values.ed),
      ordersFilterSelected: this.filterSelected(values),
      searchAllAccounts: values.aa === 'true'
    };
    updateSession({orderHistoryQuery: window.location.search});
  }

  filterSelected = (values) => {
    if (values.b) {
      return 'backordered';
    } else if (values.c) {
      return 'cancelled';
    } else if (values.o) {
      return 'open';
    } else {
      return 'all';
    }
  }

  getOrderHistoryQuery = (query)=>{
    if (query.indexOf('?') >= 0) {
      return query + '&an=' + this.props.accountNumber;
    } else {
      return query + '?an=' + this.props.accountNumber;
    }
  };

  getOrderHistoryWrapper = (query) => {
    return this.props.getOrderHistory(this.getOrderHistoryQuery(query));
  };

  componentDidMount() {
    this.props.getConfig();
    this.getOrderHistoryWrapper(this.state.queryString)
      .then(payload => {
        // User is attempting to navigate beyond last page; Redirect to last page
        if (this.state.currentPage > (payload.totalPages || 0)) {
          history.replace(queryString.update('p', payload.totalPages));
          this.setState({
            ...this.state,
            currentPage: payload.totalPages
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps === undefined)
      return false;

    if (this.state.queryString !== window.location.search
      || prevProps.accountNumber !== this.props.accountNumber
    ) {
      this.getOrderHistoryWrapper(window.location.search);

      let values = queryString.parse();
      this.setState({
        ...this.state,
        query: values.q || '',
        queryString: window.location.search,
        currentPage: values.p ? parseInt(values.p) : 1,
        endDate: this.parseDate(values.ed),
        startDate: this.parseDate(values.sd),
        ordersFilterSelected: this.filterSelected(values),
        searchAllAccounts: values.aa === 'true'
      });
      updateSession({orderHistoryQuery: window.location.search});
    }
  }
  
  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      currentPage: pageNumber
    });

    history.push(queryString.update('p', pageNumber));
  };

  search = () => {
    let locale = this.props.match.params.locale;
    let subPath = locale ? `/${locale}` : '';
    let route = subPath + queryString.update('q', this.state.query, '/orders' + window.location.search);
    route = queryString.update('sd', this.state.startDate && this.state.startDate.toISOString().split('T')[0], route);
    route = queryString.update('ed', this.state.endDate && this.state.endDate.toISOString().split('T')[0], route);
    route = queryString.update('b', this.state.ordersFilterSelected === 'backordered', route, false);
    route = queryString.update('hc', this.state.ordersFilterSelected === 'cancelled', route, false);
    route = queryString.update('o', this.state.ordersFilterSelected === 'open', route, false);
    route = queryString.update('p', null, route, false);
    route = queryString.update('aa', this.state.searchAllAccounts, route, false);
    history.push(route.replace(/\+/g, '%2B'));
    updateSession({orderHistoryQuery: window.location.search});
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  handleSearchInputChange = (e) => {
    this.setState({
      ...this.state,
      query: e.target.value
    });
  };

  handleStartDateChange = (selectedDay) => {
    let endDate;
    if (selectedDay > this.state.endDate) {
      endDate = undefined
    }
    else {
      endDate = this.state.endDate
    }
    
    this.setState({
      ...this.state,
      startDate: selectedDay,
      endDate
    });
  };

  handleEndDateChange = (selectedDay) => {
    let startDate;
    if (selectedDay < this.state.startDate) {
      startDate = undefined
    }
    else {
      startDate = this.state.startDate
    }
    
    this.setState({
      ...this.state,
      endDate: selectedDay,
      startDate
    });
  };

  handleOrdersFilterClick = (filter) => {
      this.setState({
        ...this.state,
        ordersFilterSelected: filter
      }, () => {
        this.search();
      });
    };

  handleAllAccountsToggle = (isChecked) => {
    this.setState({
      ...this.state,
      searchAllAccounts: isChecked
    }, () => {
      this.search();
    });
  };

  handleReorderClick = (orderId) => {
    return this.props.reorder(orderId)
      .then(() => this.props.getCartStatistics(this.props.accountNumber));
  };
  
  handleExcelDownloadClick = ()=>{
    return this.props.getExcelOrderHistory(this.getOrderHistoryQuery(this.state.queryString)).then((blob) => {
      downloadBlob('OrderHistory.xlsx', blob);
    }).catch(() => {
      toastr.error(this.props.intl.formatMessage({ id: 'orders.excelDownloadErrorTitle' }), this.props.intl.formatMessage({ id: 'orders.excelDownloadErrorBody' }), { timeOut: 0 });
    });
  };

  clear = () => {
    this.setState({
      ...this.state,
      query: ''
    });
  };

  parseDate = (input) => {
    if (!input)
      return undefined;
    
    let parts = input.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
  };

  render() {
    const { orderHistory } = this.props;

    let searchHelpModalContent = (
      <div>
        <p>Search terms can include order numbers, product numbers, product descriptions, purchase order numbers, ship to names, etc.</p>
        <p>By default, searches with spaces will treat separate words as AND searches - Ex. <code>hammer drill</code> will return orders containing items matching the terms <code>hammer</code> AND <code>drill</code>.</p>
        <p>The following modifiers can be used for advanced searching:</p>
        <ul>
          <li><strong>OR operator (<code>|</code>)</strong> - Ex. <code>hammer|drill</code> will return orders containing items matching the terms <code>hammer</code> OR <code>drill</code></li>
          <li><strong>NOT operator (<code>-</code>)</strong> - Ex. <code>hammer -drill</code> will return orders containing items matching the term <code>hammer</code> but NOT <code>drill</code></li>
          <li><strong>Suffix operator (<code>*</code>)</strong> - Ex. <code>impact*</code> will return orders containing items matching the term <code>impact</code></li>
          <li><strong>Phrase operator (<code>&quot;&quot;</code>)</strong> - Ex. <code>&quot;m12 impact&quot;</code> will return orders containing items matching the WHOLE phrase <code>&quot;m12 impact&quot;</code> exactly</li>
        </ul>
      </div>
    );
    
    let submittedOrders = Enumerable.from(orderHistory.orders).where(x => x.orderStatusId === 2).toArray();
    let pendingOrders = Enumerable.from(orderHistory.orders).except(submittedOrders).toArray();

    let orderHistoryPreviewMarkup = submittedOrders.map(order => {
      return (
        <OrderHistoryPreview
          key={`order-history-${order.orderNumber}`}
          order={order}
          isOpen={orderHistory.expandedOrders.indexOf(order.id) !== -1}
          locale={this.props.match.params.locale}
          onReorderClick={this.handleReorderClick}
          onOrderHistoryToggle={this.props.toggleOrderPreview}
        />
      )
    });

    let pendingOrderPreviewMarkup = pendingOrders.map(order => {
      return (
        <PendingOrderPreview
          key={`pending-order-${order.orderNumber}-${order.quoteNumber}`}
          order={order}
          locale={this.props.match.params.locale}
        />
      )
    });

    let orderHistoryOrders = orderHistory.isLoading ? <PageLoader/> : (
      <Row>
        <Col>
          <Row>
            <Col>
              <h5 style={{ marginTop: '5px' }} >Order History</h5>
            </Col>
            {orderHistory.totalCount > 0 && 
            <Col xs="auto">
              <Button isLoading={this.props.excelOrderHistory.isLoading} onClick={this.handleExcelDownloadClick}>
                <FontAwesomeIcon icon='file-excel' className='mr-2' />
                Download Results
              </Button>
            </Col>
            }
          </Row>
          {orderHistory.totalCount > 0 && <Container fluid>
            <Row className='my-3'>
              <Col>
                <PaginationResultsCount
                  count={orderHistory.count}
                  totalCount={orderHistory.totalCount}
                  currentPage={this.state.currentPage}
                  pageSize={this.state.pageSize}
                  isHidden={orderHistory.isLoading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroup className='mb-3'>
                  {orderHistoryPreviewMarkup}
                </ListGroup>
              </Col>
            </Row>
          </Container>}
        </Col>
      </Row>
    );

    let pendingOrdersMarkup = pendingOrders.length > 0 && (
      <Row>
        <Col>
          <h5 className='mb-3'>Pending Orders</h5>
          <ListGroup className='mb-3'>
            {pendingOrderPreviewMarkup}
          </ListGroup>
        </Col>
      </Row>
    );

    let noOrders = !orderHistory.isLoading && orderHistory.orders && orderHistory.orders.length === 0 && (
      <ListGroup className='cart-list'>
        <ListGroupItem className='text-center'>
          <div className='my-4'>
            <h3 data-test-id='no-orders-found' ><FormattedMessage id='orders.noOrdersMessage'/></h3>
          </div>
        </ListGroupItem>
      </ListGroup>
    );

    return (
      <div id='orders' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row>
            <Col>
              <Nav>
                <NavItem data-test-id='orders-all'>
                  <NavLink
                    onClick={() => this.handleOrdersFilterClick('all')}
                    active={this.state.ordersFilterSelected === 'all'}
                    href='#'
                  >
                    <FormattedMessage id='orders.ordersFilter' />
                  </NavLink>
                </NavItem>
                <NavItem data-test-id='orders-open'>
                  <NavLink
                    onClick={() => this.handleOrdersFilterClick('open')}
                    active={this.state.ordersFilterSelected === 'open'}
                    href='#'
                  >
                    <FormattedMessage id='orders.openOrders' />
                  </NavLink>
                </NavItem>
                <NavItem data-test-id='orders-backordered'>
                  <NavLink
                    onClick={() => this.handleOrdersFilterClick('backordered')}
                    active={this.state.ordersFilterSelected === 'backordered'}
                    href='#'
                  >
                    <FormattedMessage id='orders.backordered' />
                  </NavLink>
                </NavItem>
                {this.props.configuration.featureToggles.CancelledTab === true &&
                <NavItem>
                  <NavLink
                    onClick={() => this.handleOrdersFilterClick('cancelled')}
                    active={this.state.ordersFilterSelected === 'cancelled'}
                    href='#'
                  >
                    <FormattedMessage id='orders.cancelled' />
                  </NavLink>
                </NavItem>}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Container fluid>
                    <Row className='align-items-center mb-3'>
                      <Col md={3} data-test-id='orders-start-date' className='mb-3 mb-md-0'>
                        <CalendarInput
                          selectsStart
                          isClearable
                          placeholderText='Start Date'
                          selectedDay={this.state.startDate}
                          onSelectedDateChange={this.handleStartDateChange}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                        />
                      </Col>
                      <Col md={3} data-test-id='orders-end-date' className='mb-3 mb-md-0'>
                        <CalendarInput
                          selectsEnd
                          isClearable
                          placeholderText='End Date'
                          minDate={this.state.startDate}
                          selectedDay={this.state.endDate}
                          onSelectedDateChange={this.handleEndDateChange}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                        />
                      </Col >
                      <Col>
                        <SearchInput
                          searchInputDataTestId='order-search-input'
                          searchButtonDataTestId='order-search-button'
                          placeholder='Search'
                          value={this.state.query}
                          onChange={this.handleSearchInputChange}
                          onKeyPress={this.handleKeyPress}
                          onClick={this.search}
                          isSearching={orderHistory.isLoading}
                          onClear={this.clear}
                          searchHelpModalContent={searchHelpModalContent} />
                      </Col>
                      {user.canViewAllOrders() && <Col sm='auto' data-test-id='orders-search-all-accounts' className='mt-3 mt-sm-0'>
                        <Checkbox label='Search all accounts' isChecked={this.state.searchAllAccounts} onToggle={this.handleAllAccountsToggle} />
                      </Col>}
                    </Row>
                    <Row>
                      <Col>
                        {pendingOrdersMarkup}
                        {orderHistoryOrders}
                        {noOrders}
                        <Pagination
                          totalPages={orderHistory.totalPages}
                          currentPage={this.state.currentPage}
                          pageSize={this.state.pageSize}
                          totalItemsCount={orderHistory.totalCount}
                          onPageChange={this.handlePageChange}
                          isHidden={orderHistory.isLoading}
                        />
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    configuration: state.configuration,
    orderHistory: state.orders.orderHistory,
    accountNumber: state.account && (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber),
    excelOrderHistory: state.orders.excelOrderHistory
  };
};

const mapDispatchToProps = dispatch => ({
  getConfig: () => dispatch(configurationActions.getConfig()),
  getOrderHistory: query => dispatch(ordersActions.getOrderHistory(query)),
  getExcelOrderHistory: query => dispatch(ordersActions.getExcelOrderHistory(query)),
  reorder: orderId => dispatch(cartActions.reorder(orderId)),
  getCartStatistics: (accountNumber) => dispatch(cartActions.getCartStatistics(accountNumber)),
  toggleOrderPreview: orderId => dispatch(ordersActions.toggleOrderPreview(orderId))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Orders));