import {connectRouter, routerMiddleware} from 'connected-react-router';
import {reducer as toastrReducer} from 'react-redux-toastr'
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import * as Account from './Account';
import * as Addresses from './Addresses';
import * as Announcements from './Announcements';
import * as Availability from './Availability';
import * as Brandworks from './Brandworks';
import * as Cart from './Cart';
import * as ChatSchedule from './ChatSchedule';
import * as CompanyAdmin from './CompanyAdmin';
import * as Configuration from './Configuration';
import * as Contact from './Contact';
import * as Countries from './Countries';
import * as Dashboard from './Dashboard';
import * as DefectivePartsClaim from './DefectivePartsClaim';
import * as Invoices from './Invoices';
import * as Locales from './Locales';
import * as Orders from './Orders';
import * as Pricing from './Pricing';
import * as Products from './Products';
import * as ProductSearch from './ProductSearch';
import * as Square from './Square';
import * as States from './States';
import * as Users from './Users';
import * as Warranty from './Warranty';
import * as WarrantyAutoApproveFilters from './WarrantyAutoApproveFilters';
import * as WarrantyClaim from './WarrantyClaim';
import * as Wishlists from './Wishlists';
import * as Publications from './Publications';

export default function configureStore(history, initialState) {
  const reducers = {
    router: connectRouter(history),
    productSearch:  ProductSearch.reducer,
    locales: Locales.reducer,
    pricing: Pricing.reducer,
    availability: Availability.reducer,
    cart: Cart.reducer,
    chatSchedule: ChatSchedule.reducer,
    orders: Orders.reducer,
    products: Products.reducer,
    toastr: toastrReducer,
    warranty: Warranty.reducer,
    brandworks: Brandworks.reducer,
    account: Account.reducer,
    contact: Contact.reducer,
    warrantyClaim: WarrantyClaim.reducer,
    users: Users.reducer,
    announcements: Announcements.reducer,
    invoices: Invoices.reducer,
    dashboard: Dashboard.reducer,
    warrantyAutoApproveFilters: WarrantyAutoApproveFilters.reducer,
    defectivePartsClaim: DefectivePartsClaim.reducer,
    square: Square.reducer,
    countries: Countries.reducer,
    states: States.reducer,
    configuration: Configuration.reducer,
    addresses: Addresses.reducer,
    wishlists: Wishlists.reducer,
    publications: Publications.reducer,
    companyAdmin: CompanyAdmin.reducer,
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
