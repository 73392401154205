import 'lightbox-react/style.css';
import React, { useEffect, useState } from 'react';
import { injectIntl } from "react-intl";
import { Route, Switch } from 'react-router';
import auth from './auth';
import {
  Admin,
  Announcements,
  EditAnnouncement,
  NewAnnouncement,
  ProductAdmin,
  ProductsAdmin,
  WarrantyAutoApprove
} from './containers/Admin';
import Callback from './containers/Callback';
import Cart from './containers/Cart';
import Checkout from './containers/Checkout';
import Confirmation from './containers/Confirmation';
import Contact from './containers/Contact';
import DefectivePartWizard from './containers/DefectivePartClaim';
import FAQ from './containers/FAQ';
import Home from './containers/Home';
import Invoices from './containers/Invoices';
import Customize from './containers/Customize';
import Customizer from './containers/Customizer';
import { AdminLayout, BaseLayout, Layout, ProfileLayout, SearchLayout } from './containers/Layout';
import NotFound from './containers/NotFound';
import OrderDetails from './containers/OrderDetails';
import Orders from './containers/Orders';
import ProductDetails from './containers/ProductDetails';
import { General, ShippingInfo, CreditCards } from './containers/Profile';
import Publications from './containers/Publications';
import Search from './containers/Search';
import Swagger from './containers/Swagger';
import Track from './containers/Track';
import WarrantyClaimWizard from './containers/WarrantyClaim';
import WarrantyClaimDetails from './containers/WarrantyClaimDetails';
import WarrantySearch from './containers/WarrantySearch';
import Wishlist from './containers/Wishlists/Wishlist';
import Wishlists from './containers/Wishlists';
import InviteToPay from './containers/InviteToPay';
import user from './helpers/user';
import history from './history';
import { CompanyAdminHome, CompanyAdminCreateUser, CompanyAdminEditUser } from './containers/CompanyAdmin';

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

const AppRoute = (
  {
    component: Component,
    layout: Layout,
    documentTitle,
    title,
    backgroundColor,
    isAuthenticatedRoute,
    isConnectUser,
    isSuperAdminRoute,
    isAdminRoute,
    isAnnouncementsAdminRoute,
    isProductAdminRoute,
    isWarrantyAdminRoute,
    isCreditCardRoute,
    isCompanyAdminRoute,
    ...rest
  }) => {

  if (isAuthenticatedRoute && auth.isAuthenticated()) {
    auth.renewToken();
  }
  if (isAuthenticatedRoute && !auth.isAuthenticated()) {
    auth.login();
    return null;
  }
  if (isConnectUser && !(user.isConnectUser())) {
    history.replace('/NotFound');
    return null;
  }
  if (isSuperAdminRoute && !(user.isSuperAdmin())) {
    history.replace('/NotFound');
    return null;
  }
  if (isAdminRoute && !(user.isAdmin())) {
    history.replace('/NotFound');
    return null;
  }
  if (isAnnouncementsAdminRoute && !(user.isAnnouncementsAdmin())) {
    history.replace('/NotFound');
    return null;
  }
  if (isProductAdminRoute && !(user.isProductAdmin() || user.isAdmin())) {
    history.replace('/NotFound');
    return null;
  }
  if (isWarrantyAdminRoute && !(user.canApproveWarrantyClaims())) {
    history.replace('/NotFound');
    return null;
  }
  if (isCreditCardRoute && !(user.isCreditCardUser())) {
    history.replace('/NotFound');
    return null;
  }
  if (isCompanyAdminRoute && !(user.isCompanyAdmin())) {
    history.replace('/NotFound');
    return null;
  }

  document.title = documentTitle ? `${documentTitle} | Milwaukee Connect` : title ? `${title} | Milwaukee Connect` : 'Milwaukee Connect';

  return (
    <Route {...rest} render={props => (
      <Layout {...props} title={title} backgroundColor={backgroundColor}>
        <Component {...props} />
      </Layout>
    )} />
  );
};

const App = (props) => {
  const [checkingAuth, setCheckingAuth] = useState(false);
  const [redirectUri, setRedirectUri] = useState('');

  useEffect(() => {
    // check once to see if there is already an existing session
    const handleCheckForAuth = async () => {
      setCheckingAuth(true);
      if (!auth.isAuthenticated()) {
        await auth.checkForExistingSession(() => setCheckingAuth(false));
      } else {
        setCheckingAuth(false);
      }
      
      if (auth.isAuthenticated() && redirectUri) {
        history.replace(redirectUri);
      }
    }
    
    setRedirectUri(window.location.pathname + window.location.search);

    // don't auto check for auth if on the callback route (just logged in)
    if (!(/callback/.test(window.location.pathname))) {
      handleCheckForAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (checkingAuth) return null;
  return (
    <Switch>
      <AppRoute exact path='/:locale(en|es|fr)?' component={Home} layout={BaseLayout} />
      <AppRoute path='/:locale(en|es|fr)?/search' component={Search} layout={SearchLayout} documentTitle='Search' />
      <AppRoute path='/:locale(en|es|fr)?/cart' isAuthenticatedRoute isConnectUser component={Cart} layout={SearchLayout} documentTitle='Cart' />
      <AppRoute exact path='/:locale(en|es|fr)?/orders' isAuthenticatedRoute isConnectUser component={Orders} layout={SearchLayout} title='Your Orders' documentTitle='Orders' />
      <AppRoute path='/:locale(en|es|fr)?/orders/:id' isAuthenticatedRoute isConnectUser component={OrderDetails} layout={SearchLayout} title='Order Details' />
      <AppRoute path='/:locale(en|es|fr)?/checkout' isAuthenticatedRoute isConnectUser component={Checkout} layout={Layout} title='Checkout' />
      <AppRoute path='/:locale(en|es|fr)?/confirmation/:id' isAuthenticatedRoute isConnectUser component={Confirmation} layout={Layout} title='Order Confirmation' />
      <AppRoute path='/:locale(en|es|fr)?/products/:skuAlias' component={ProductDetails} layout={SearchLayout} backgroundColor='white' />
      <AppRoute path='/:locale(en|es|fr)?/track' isAuthenticatedRoute isConnectUser component={Track} layout={SearchLayout} title='Shipment Details' />
      <AppRoute path='/:locale(en|es|fr)?/invoices' isAuthenticatedRoute isConnectUser component={Invoices} layout={SearchLayout} documentTitle='Invoices' />
      <AppRoute path='/:locale(en|es|fr)?/publications' isAuthenticatedRoute isConnectUser component={Publications} layout={SearchLayout} title='Pricing and Publications' />

      <AppRoute exact path='/admin' isAuthenticatedRoute component={Admin} layout={AdminLayout} title='Administrator Dashboard' />
      <AppRoute exact path='/admin/products' isAuthenticatedRoute isProductAdminRoute component={ProductsAdmin} layout={AdminLayout} title='Administrator Dashboard' />
      <AppRoute path='/admin/products/:skuAlias' isAuthenticatedRoute isProductAdminRoute component={ProductAdmin} layout={AdminLayout} title='Administrator Dashboard' />
      <AppRoute exact path='/admin/announcements' isAuthenticatedRoute isAnnouncementsAdminRoute component={Announcements} layout={AdminLayout} title='Administrator Dashboard' />
      <AppRoute exact path='/admin/announcements/new' isAuthenticatedRoute isAnnouncementsAdminRoute component={NewAnnouncement} layout={AdminLayout} title='Administrator Dashboard' />
      <AppRoute exact path='/admin/announcements/:id' isAuthenticatedRoute isAnnouncementsAdminRoute component={EditAnnouncement} layout={AdminLayout} title='Administrator Dashboard' />
      <AppRoute exact path='/admin/warrantyFilters' isAuthenticatedRoute isWarrantyAdminRoute component={WarrantyAutoApprove} layout={AdminLayout} title='Administrator Dashboard' />

      <AppRoute exact path='/companyadmin' isAuthenticatedRoute isCompanyAdminRoute component={CompanyAdminHome} layout={SearchLayout} title='Company Admin' />
      <AppRoute exact path='/companyadmin/:userId/edit' isAuthenticatedRoute isCompanyAdminRoute component={CompanyAdminEditUser} layout={SearchLayout} title='Edit User' />
      <AppRoute exact path='/companyadmin/new' isAuthenticatedRoute isCompanyAdminRoute component={CompanyAdminCreateUser} layout={SearchLayout} title='New User' />

      <AppRoute exact path='/:locale(en|es|fr)?/warranty' isAuthenticatedRoute isConnectUser component={WarrantySearch} layout={Layout} title='Warranty Claims' />
      <AppRoute path='/:locale(en|es|fr)?/warranty/newClaim' isAuthenticatedRoute isConnectUser component={WarrantyClaimWizard} layout={Layout} title='Warranty Claims' />
      <AppRoute path='/:locale(en|es|fr)?/warranty/defectiveClaim' isAuthenticatedRoute isConnectUser component={DefectivePartWizard} layout={Layout} title='Warranty Claims' />
      <AppRoute exact path='/:locale(en|es|fr)?/warranty/:id' isAuthenticatedRoute isConnectUser component={WarrantyClaimDetails} layout={Layout} title='Warranty Claims' />

      <AppRoute exact path='/:locale(en|es|fr)?/shoppingLists' isAuthenticatedRoute isConnectUser component={Wishlists} layout={SearchLayout} title='My Shopping Lists' />
      <AppRoute exact path='/:locale(en|es|fr)?/shoppingLists/:id' isAuthenticatedRoute isConnectUser component={Wishlist} layout={SearchLayout} documentTitle='Shopping List Details' />

      <AppRoute exact path='/:locale(en|es|fr)?/profile' isAuthenticatedRoute isConnectUser component={General} layout={ProfileLayout} title='Profile' />
      <AppRoute exact path='/:locale(en|es|fr)?/profile/shippinginfo' isAuthenticatedRoute isConnectUser component={ShippingInfo} layout={ProfileLayout} title='Profile' />
      <AppRoute exact path='/:locale(en|es|fr)?/profile/creditcards' isAuthenticatedRoute component={CreditCards} layout={ProfileLayout} title='Profile' />

      <AppRoute exact path='/:locale(en|es|fr)?/customize' isAuthenticatedRoute isConnectUser component={Customize} layout={BaseLayout} />
      <AppRoute exact path='/:locale(en|es|fr)?/customizer/:referenceId?/:skuAlias?' isAuthenticatedRoute isConnectUser component={Customizer} layout={BaseLayout} />
      <AppRoute exact path='/:locale(en|es|fr)?/contact' isAuthenticatedRoute isConnectUser component={Contact} layout={SearchLayout} title='Contact Us' />
      <AppRoute exact path='/:locale(en|es|fr)?/faq' isAuthenticatedRoute component={FAQ} isConnectUser layout={Layout} documentTitle='FAQs' />

      <AppRoute exact path='/:locale(en|es|fr)?/invitetopay' component={InviteToPay} layout={Layout} title={props.intl.formatMessage({ id: 'square.inviteToPay' })} />
      <AppRoute exact path='/:locale(en|es|fr)?/api/v1' isAuthenticatedRoute isAdminRoute component={Swagger} layout={Layout} title='CustomerPortal API' />

      <Route path='/callback' render={(props) => {
        handleAuthentication(props);
        return <Callback {...props} />
      }} />
      <AppRoute component={NotFound} layout={BaseLayout} />
    </Switch>
  );
}

export default injectIntl(App);
