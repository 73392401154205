import Enumerable from 'linq';
import createFetchAction from "./createFetchAction";

export const LOADING_ORDER_DASHBOARD = 'LOADING_ORDER_DASHBOARD';
export const LOADING_ORDER_DASHBOARD_ERROR = 'LOADING_ORDER_DASHBOARD_ERROR';
export const LOADING_ORDER_DASHBOARD_SUCCESS = 'LOADING_ORDER_DASHBOARD_SUCCESS';

export const LOADING_CURRENTWARRANTY_DASHBOARD = 'LOADING_CURRENTWARRANTY_DASHBOARD';
export const LOADING_CURRENTWARRANTY_DASHBOARD_ERROR = 'LOADING_CURRENTWARRANTY_DASHBOARD_ERROR';
export const LOADING_CURRENTWARRANTY_DASHBOARD_SUCCESS = 'LOADING_CURRENTWARRANTY_DASHBOARD_SUCCESS';

export const LOADING_TOTALWARRANTY_DASHBOARD = 'LOADING_TOTALWARRANTY_DASHBOARD';
export const LOADING_TOTALWARRANTY_DASHBOARD_ERROR = 'LOADING_TOTALWARRANTY_DASHBOARD_ERROR';
export const LOADING_TOTALWARRANTY_DASHBOARD_SUCCESS = 'LOADING_TOTALWARRANTY_DASHBOARD_SUCCESS';


export const initialState ={
  orders:{
    invoiceCount: 0,
    orderCount: 0,
    isLoading: false,
    isError: false
  },
  currentWarranty:{
    pending:0,
    rejected: 0,
    denied: 0,
    isLoading: false,
    isError: false
  },
  totalWarranty:{
    data: [],
    currentYearCount:0,
    lastYearCount: 0,
    isLoading: false,
    isError: false
  }
};

export const actionCreators = {
  getOrdersDashboard: (accountNumber) => {
    return createFetchAction({
      url: `/api/v1/dashboard?accountNumber=${accountNumber || ''}`,
      startAction: LOADING_ORDER_DASHBOARD
    });
  },
  getCurrentWarrantyDashboard: (accountNumber) => {
    return createFetchAction({
      url: `/warrantyapi/dashboard/current/${accountNumber || ''}`,
      startAction: LOADING_CURRENTWARRANTY_DASHBOARD
    });
  },
  getTotalWarrantyDashboard: (accountNumber) => {
    return createFetchAction({
      url: `/warrantyapi/dashboard/total/${accountNumber || ''}`,
      startAction: LOADING_TOTALWARRANTY_DASHBOARD
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case LOADING_ORDER_DASHBOARD:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_ORDER_DASHBOARD_ERROR:
      return {
        ...state,
        orders: {
          ...state.orders,
          isError: true,
          isLoading: false
        }
      };
    case LOADING_ORDER_DASHBOARD_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isError: false,
          isLoading: false,
          invoiceCount: action.payload.data.invoiceCount,
          orderCount: action.payload.data.orderCount
        }
      };
    case LOADING_CURRENTWARRANTY_DASHBOARD:
      return {
        ...state,
        currentWarranty: {
          ...state.currentWarranty,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_CURRENTWARRANTY_DASHBOARD_ERROR:
      return {
        ...state,
        currentWarranty: {
          ...state.currentWarranty,
          isError: true,
          isLoading: false
        }
      };
    case LOADING_CURRENTWARRANTY_DASHBOARD_SUCCESS:
      return {
        ...state,
        currentWarranty: {
          ...state.currentWarranty,
          isError: false,
          isLoading: false,
          pending: action.payload.data.pending,
          rejected: action.payload.data.rejected,
          denied: action.payload.data.denied
        }
      };
    case LOADING_TOTALWARRANTY_DASHBOARD:
      return {
        ...state,
        totalWarranty: {
          ...state.totalWarranty,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_TOTALWARRANTY_DASHBOARD_ERROR:
      return {
        ...state,
        totalWarranty: {
          ...state.totalWarranty,
          isError: true,
          isLoading: false
        }
      };
    case LOADING_TOTALWARRANTY_DASHBOARD_SUCCESS:{
      let year = new Date().getFullYear();
      
      return {
        ...state,
        totalWarranty: {
          ...state.totalWarranty,
          isError: false,
          isLoading: false,
          data: action.payload.data,
          currentYearCount: Enumerable.from(action.payload.data).firstOrDefault(x=>x.year === year, {count: 0}).count,
          lastYearCount: Enumerable.from(action.payload.data).firstOrDefault(x=>x.year === (year - 1), {count: 0}).count,
        }
      };
    }
    default:
      return state;
  }
};