import React, { Component } from 'react';
import {injectIntl} from 'react-intl';
import {
  Col,
  Input,
  Label,
  Row
} from 'reactstrap';

import {Button, QuantityInput} from '../../components';
class RepairLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.props.index,
      itemNumber: this.props.itemNumber || '',
      quantity: this.props.quantity || 0
    };

    this.handleSkuChange = this.handleSkuChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.removeRepairPartLineItem = this.removeRepairPartLineItem.bind(this);
  }

  handleSkuChange = (e) => {
    this.setState({
      ...this.state,
      itemNumber: e.target.value
    }, () => {
      this.props.update(this.state.index, 
        {
          quantity: this.state.quantity,
          itemNumber: this.state.itemNumber
        })
    });
  }

  handleQuantityChange = (quantity) => {
    this.setState({
      ...this.state,
      quantity
    }, () => {
      this.props.update(this.state.index, 
        {
          quantity: this.state.quantity,
          itemNumber: this.state.itemNumber
        })
    });
  }

  removeRepairPartLineItem = () => {
    this.props.removeRepairPartLineItem(
    {
      quantity: this.state.quantity,
      itemNumber: this.state.itemNumber
    });
  }

  render() {

    return (
    <Row className='align-items-end mb-2'>
      <Col xs='auto'>
        <Label for='part-line-item'>Part #</Label>
         <Input
          data-test-id={`repair-line-item-input-${this.state.itemNumber}`}
          name='part-line-item' 
          value={this.state.itemNumber} 
          onChange={this.handleSkuChange}
        />
      </Col>
      <Col xs='auto'>                      
        <Label for='part-quantity'>Quantity</Label>
        <QuantityInput
          name='part-quantity' 
          value={this.state.quantity} 
          onQuantityChange={this.handleQuantityChange}
          dataTestId={this.props.itemNumber}
        />
      </Col>
      <Col xs='auto'>
          <Button data-test-id={`repair-line-item-remove-button-${this.state.itemNumber}`} onClick={this.removeRepairPartLineItem}>
          Remove
        </Button>
      </Col>
    </Row>
    )
  }
}

export default injectIntl(RepairLineItem);