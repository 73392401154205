import React, {Component} from 'react';

import classNames from 'classnames';
import {Link as ReactLink} from 'react-router-dom';
import PropTypes from 'prop-types';

class Link extends Component {
  render() {
    const { className, locale, to, isHidden, isActive } = this.props;
    
    if (isHidden)
      return null;
    
    const classes = classNames(
      className,
      isActive && 'active'
    );

    let linkTo = `${locale ? '/' + locale : ''}/${to}`;

    return (
      <ReactLink to={linkTo} data-test-id={this.props.linkDataTestId} className={classes}>
        {this.props.children}
      </ReactLink>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  locale: PropTypes.string,
  isHidden: PropTypes.bool,
  isActive: PropTypes.bool,
  linkDataTestId: PropTypes.string
};

export default Link;