import {Col, Row, useToggle} from '@met/react-components';
import React, {Fragment, useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import Enumerable from 'linq';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Alert } from 'reactstrap';
import {Button} from '../../components/Button';
import {PageLoader} from '../../components/PageLoader';
import history from '../../history';
import {useCanCheckOut} from '../../hooks';
import {
  addressActions,
  cartActions,
  configurationActions,
  countriesActions,
  squareActions,
  statesActions
} from '../../store';
import InvalidCartMessage from './components/InvalidCartMessage';
import OrderSummary from './components/OrderSummary';
import PaymentMethod from './components/PaymentMethod';
import PurchaseOrder from './components/PurchaseOrder';
import RequestedShipDate from './components/RequestedShipDate';
import ShippingAddress from './components/ShippingAddress';
import EmailConfirmation from './components/EmailConfirmation';
import ShippingSpeed from './components/ShippingSpeed';
import shipping from '../../helpers/shippingHelper';
import user from '../../helpers/user';
import { getProfile } from '../../helpers/localStorage';
import { getCardExpired } from '../../helpers/creditCardHelper';

const Checkout = (props) => {
  const canCheckOut = useCanCheckOut(props.cart, props.account);
  const [isLoading, setIsLoading] = useState(true);
  const [trySubmit, setTrySubmit] = useState(false);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState({ value: '', isValid: false });
  const [emailConfirmation, setEmailConfirmation] = useState({ value: '', isValid: false });
  const [shippingSpeed, setShippingSpeed] = useState(shipping.speeds.bestWay);
  const [requestedShipDate, setRequestedShipDate] = useState(undefined);
  const [branch, setBranch] = useState({});
  const [formIsValid, setFormIsValid] = useState(false);
  const [testCardDialogHidden, toggleTestCardDialogHidden] = useToggle(true);
  const [hideShippingAddress, setHideShippingAddress] = useState(false);
  const [isFillOrKill] = useState(getProfile().cachedAccountDetails?.isFillOrKill || false);
  const [isDropShippable, setIsDropShippable] = useState(false);

  // get the configuration on initial page load in order to access any feature toggles needed
  useEffect(() => {
      props.configurationActions.getConfig();
    },
    []);

  useEffect(() => {
      setHideShippingAddress(!shipping.requireShippingAddress(shippingSpeed));
    },
    [shippingSpeed]);

  // show the page as loading until certain conditions are met.
  useEffect(() => {
      let cartLoading = !props.cart.pendingOrder || props.cart.getCart.isLoading;
      let addressesLoading = props.isEmployeeAccount &&
        (!props.addresses.userAddresses || props.addresses.getAddresses.isLoading);
      let creditCardsLoading = props.isCreditCardOnlyAccount &&
        (!props.square.creditCards || props.square.getCreditCards.isLoading);
      let configLoading = props.configuration.getConfig.isLoading;
      let pciFeaturesLoading = props.configuration.featureToggles.PCI &&
        (addressesLoading || creditCardsLoading || props.statesLoading || props.countriesLoading);

      let genericUserCheckoutLoading = configLoading || !props.accountLoaded;
      let employeeCheckoutLoading = props.isEmployeeAccount &&
        (configLoading || !props.accountLoaded || cartLoading || pciFeaturesLoading);

      setIsLoading(genericUserCheckoutLoading || employeeCheckoutLoading);
    },
    [
      props.addresses.getAddresses.isLoading, props.square.getCreditCards.isLoading, props.accountLoaded,
      props.configuration.featureToggles,
      props.statesLoading, props.countriesLoading, props.cart.getCart.isLoading,
      props.configuration.getConfig.isLoading,
      props.isEmployeeAccount
    ]);

  useEffect(() => {
    if (props.cart.pendingOrder?.orderLines) {
      setIsDropShippable(props.configuration.featureToggles.DropShippable && allItemsDropShippable());
    }
  }, [props.configuration.featureToggles.DropShippable, props.cart.pendingOrder, props.cart.pendingOrder?.orderLines]);

  // once our configuration is loaded and feature toggles are set, fetch data accordingly
  useEffect(() => {
      // load the user addresses if a milwaukee tool employee only
      if ((props.configuration.featureToggles.PCI && props.isEmployeeAccount) || isDropShippable) {
        props.addressActions.getUserAddresses();
        props.statesActions.getStates();
        props.countriesActions.getCountries();
      }

      // set PO Number to default if milwaukee tool employee
      if (props.configuration.featureToggles.PONumberDefault && props.isEmployeeAccount) setPurchaseOrderNumber({ value: "EMPLOYEE ORDER", isValid: true });

      if (props.configuration.featureToggles.PCI && props.isCreditCardOnlyAccount) {
        //Not specifying a location here, so it uses the default
        //we only need the location for invite to pay requests
        props.squareActions.getSquareConfig()
          .then(() => {
            // load all credit cards for the user
            props.squareActions.getCreditCards()
              .catch(() => toastr.error(props.intl.formatMessage({ id: 'general.errorToastTitle' }),
                props.intl.formatMessage({ id: 'square.getCreditCardsErrorMessage' }),
                { timeOut: 0 }));
          });
      }
    },
    [props.configuration.featureToggles, props.isEmployeeAccount, props.isCreditCardOnlyAccount, isDropShippable]);

  // if the user's cart is expired, don't allow them to checkout
  useEffect(() => {
      if (props.cart.isExpired)
        history.replace('/cart');
    },
    [props.cart.isExpired]);

  // when the active account number changes, get the cart again
  useEffect(() => {
      if (props.accountNumber)
        props.cartActions.getCart(props.accountNumber, isFillOrKill);
    },
    [props.accountNumber]);

  // check form validity
  useEffect(() => {
      validate();
    },
    [
      purchaseOrderNumber.isValid, emailConfirmation.isValid, props.isCreditCardOnlyAccount,
      props.configuration.featureToggles, props.square.selectedCard, props.addresses.selectedAddress
    ]);

  const containsCustomLogo = () => {
    return Enumerable.from(props.cart.pendingOrder.orderLines.inStock).any(x => x.isCustomLogo) ||
      Enumerable.from(props.cart.pendingOrder.orderLines.backordered).any(x => x.isCustomLogo) ||
      Enumerable.from(props.cart.pendingOrder.orderLines.other).any(x => x.isCustomLogo);
  };

  const allItemsDropShippable = () => {
    return Enumerable.from(props.cart.pendingOrder.orderLines.inStock).all(x => x.isDropShippable) &&
      Enumerable.from(props.cart.pendingOrder.orderLines.backordered).all(x => x.isDropShippable) && 
      Enumerable.from(props.cart.pendingOrder.orderLines.other).all(x => x.isDropShippable);
  };

  const containsNonCustomGear = () => {
    return Enumerable.from(props.cart.pendingOrder.orderLines.inStock).any(x => !x.isCustomLogo) ||
      Enumerable.from(props.cart.pendingOrder.orderLines.backordered).any(x => !x.isCustomLogo) ||
      Enumerable.from(props.cart.pendingOrder.orderLines.other).any(x => !x.isCustomLogo);
  };

  const validate = () => {
    let isValid = purchaseOrderNumber.isValid;

    if (props.cart.pendingOrder !== null && containsCustomLogo()) {
      isValid = isValid && emailConfirmation.isValid;
    }

    if (props.configuration.featureToggles.PCI) {
      //CC only accounts must have a selected card
      if (props.isCreditCardOnlyAccount) {
        isValid = isValid && props.square.selectedCard && !getCardExpired(props.square.selectedCard);
      }

      //CC only employees (US employees) must have a selected address
      if (props.isCreditCardOnlyAccount && props.isEmployeeAccount && !hideShippingAddress)
        isValid = isValid && props.addresses.selectedAddress;

      //Non CC employees (international employees) and Distributors must have a valid address gotten from AX
      if (!props.isCreditCardOnlyAccount || !props.isEmployeeAccount)
        isValid = isValid && props.address;
    }
    setFormIsValid(isValid);
  };

  // When a credit card is added, only save it via the Trap API if saveCard is true
  const handleCreditCardAdded = (cardData, nonce, cardholderName, saveCard) => {
    if (saveCard) {
      return props.squareActions.addCreditCard({
        billingPostalCode: cardData.billing_postal_code,
        cardholderName: cardholderName,
        squareCardNonce: nonce
      })
        .catch(response => {
          toastr.error(
            props.intl.formatMessage({ id: 'general.errorToastTitle' }),
            props.intl.formatMessage(
              {
                id: (response.error && response.error.code) ?
                  `square.errorCodes.${response.error.code}` :
                  'square.addCreditCardErrorMessage',
                defaultMessage: props.intl.formatMessage({ id: 'square.addCreditCardErrorMessage' })
              }),
            { timeOut: 0 });
        });
    } else {
      return props.squareActions.addTemporaryCreditCard({
        id: nonce, // still need to provide an id
        squareCardNonce: nonce,
        cardBrand: cardData.card_brand,
        cardLast4: cardData.last_4,
        cardholderName: cardholderName,
        billingPostalCode: cardData.billing_postal_code
      });
    }
  };

  const handleAddTestCard = (nonce, cardholderName) => {
    return props.squareActions.addTemporaryCreditCard({
      id: nonce, // still need to provide an id
      squareCardNonce: nonce,
      cardBrand: 'fake',
      cardLast4: 'fake',
      cardholderName: cardholderName,
      billingPostalCode: '94103'
    }).finally(() => toggleTestCardDialogHidden());
  };

  // When a credit card is deleted, check if there is a card nonce. If so, we know it is just a temporary card.
  const handleDeleteCard = (card) => {
    if (card.squareCardNonce)
      return props.squareActions.deleteTemporaryCreditCard(card.squareCardNonce);

    return props.squareActions.deleteCreditCard(card.id)
      .catch(() => toastr.error(props.intl.formatMessage({ id: 'general.errorToastTitle' }),
        props.intl.formatMessage({ id: 'square.deleteCreditCardErrorMessage' }),
        { timeOut: 0 }));
  };

  const handlePurchaseOrderNumberChange = (value) => {
    setPurchaseOrderNumber(value);
    setTrySubmit(false);
  };

  const handleEmailConfirmationChange = (value) => {
    setEmailConfirmation(value);
    setTrySubmit(false);
  };

  const handleSaveAddressClick = (address) => {
    return props.addressActions.upsertAddress(address);
  };

  const handleDeleteAddressClick = (addressId) => {
    return props.addressActions.deleteAddress(addressId)
      .catch(() => toastr.error(props.intl.formatMessage({ id: 'general.errorToastTitle' }),
        props.intl.formatMessage({ id: 'addressManagement.deleteErrorMessage' }),
        { timeOut: 0 }));
  };

  const canUseSelectedShippingAddress = () => {
    return (props.configuration.featureToggles.PCI && props.isUsEmployeeAccount && !hideShippingAddress) || isDropShippable;
  };

  const handleIfOBOfficeAddressSelected = (address) => {
    const address1 = ["12385 crossroads drive", "12385 crossroads dr", "12385 cross roads drive", "12385 cross roads dr"];
    const isOBAddressSelected = ((address1.indexOf(address.addressLine1.toLowerCase()) >= 0) && address.city.toLowerCase() === "olive branch"
          && address.state === "MS" && address.postalCode.includes("38654"));
    
    return isOBAddressSelected;
  }

  const handleSubmitClick = () => {
    setTrySubmit(true);

    if (!purchaseOrderNumber.isValid) {
      toastr.warning(props.intl.formatMessage({ id: 'general.validationToastTitle' }),
        props.intl.formatMessage({ id: 'checkout.validationWarningMessage' }));
    }

    if (props.configuration.featureToggles.PCI) {
      if (props.isCreditCardOnlyAccount && (!props.square.selectedCard || (props.square.selectedCard && getCardExpired(props.square.selectedCard)))) {
        toastr.warning(props.intl.formatMessage({ id: 'checkout.paymentMethodRequiredTitle' }),
          props.intl.formatMessage({ id: 'checkout.paymentMethodRequiredMessage' })
        );
      }

      if (props.isCreditCardOnlyAccount && props.isEmployeeAccount) {
        if (!props.addresses.selectedAddress) {
          //CC only employees must have an address selected
          toastr.warning(props.intl.formatMessage({ id: 'checkout.addressRequiredTitle' }),
            props.intl.formatMessage({ id: 'checkout.addressRequiredMessage' }));
        } else {
          //CC only employees (US employees) with a selected address must be US (if order contains custom logo)
          if (containsCustomLogo() && props.addresses.selectedAddress.countryCode !== "US") {
            toastr.warning(props.intl.formatMessage({ id: 'checkout.addressRegionLockedTitle' }),
              props.intl.formatMessage({ id: 'checkout.addressRegionLockedMessage' }));
          }
        }
      } else {
        //Non CC employees/Distributors with a AX address must be (if order contains custom logo)
        if (containsCustomLogo() && props.address.country !== "USA") {
          toastr.warning(props.intl.formatMessage({ id: 'checkout.addressRegionLockedTitle' }),
            props.intl.formatMessage({ id: 'checkout.addressRegionLockedMessage' }));
        }
      }
    }

    if (props.configuration.featureToggles.IsOBOfficeAddress) {
      const isShippingAddressEnabled = props.configuration.featureToggles.PCI && props.isCreditCardOnlyAccount && props.isEmployeeAccount;
      const errorMessage = 'Error. You cannot place an employee order with the current shipping address. Please add your shipping address and place the order again.';
      if (isShippingAddressEnabled) {
        if (props.addresses.selectedAddress && handleIfOBOfficeAddressSelected(props.addresses.selectedAddress)) {
          toastr.error(errorMessage);
          return;
        }
      } else {
        if (Object.keys(branch).length > 0 && handleIfOBOfficeAddressSelected(branch.address)) {
          toastr.error(errorMessage);
          return;
        } else {
          if (handleIfOBOfficeAddressSelected(props.cart.pendingOrder.address)) {
            toastr.error(errorMessage);
            return;
          }
        }
      }
    }

    validate();

    if (formIsValid) {
      props.cartActions.submitCart(shippingSpeed,
          requestedShipDate,
          purchaseOrderNumber.value,
          emailConfirmation.value,
          props.accountNumber,
          branch,
          props.square.selectedCard,
        canUseSelectedShippingAddress() ? props.addresses.selectedAddress : {},
          props.currencyCode)
        .then(() => {
          props.cartActions.getCartStatistics(props.accountNumber);
          history.push(`/confirmation/${props.cart.pendingOrder.id}`);
        })
        .catch(response => {
          setTrySubmit(false);

          if (response.error && response.error.code)
            toastr.error(
              props.intl.formatMessage({ id: 'general.errorToastTitle' }),
              props.intl.formatMessage(
                {
                  id: `square.errorCodes.${response.error.code}`,
                  defaultMessage: props.intl.formatMessage({ id: 'square.errorCodes.DEFAULT_ERROR' })
                }),
              { timeOut: 0 });
          else
            toastr.error(props.intl.formatMessage({ id: 'general.errorToastTitle' }),
              props.intl.formatMessage({ id: 'checkout.errorSubmittingOrder' }),
              { timeOut: 0 });
        });
    }
  };

  let shippingAddressMarkup = props.cart.pendingOrder && !hideShippingAddress && (
    <ShippingAddress
      accountChildren={props.accountChildren}
      addDropShipAddressEnabled={isDropShippable}
      address={props.cart.pendingOrder.address}
      onBranchChange={(branch) => setBranch(branch)}
      selectShippingAddressEnabled={props.configuration.featureToggles.PCI && props.isCreditCardOnlyAccount && props.isEmployeeAccount}
      userAddresses={props.addresses.userAddresses}
      states={props.states}
      countries={props.countries}
      selectedAddress={props.addresses.selectedAddress}
      onSaveClick={handleSaveAddressClick}
      onDeleteClick={handleDeleteAddressClick}
      onSetSelectedAddress={props.addressActions.setSelectedAddress}
    />
  );

  let employeeOrdersDisabled = user.isMilwaukeeToolEmployee() && props.configuration.featureToggles.EmployeeOrdersDisabled === true;
  let employeeOrdersDisabledMarkup = employeeOrdersDisabled && (
    <Container>
      <Alert color='danger' className='mt-4 mb-0 font-weight-bold d-print-none'>
        <Container>
          <Row className='my-auto'>
            <Col xs='auto' className='my-auto'>
              <FontAwesomeIcon className='mr-2' color='black' icon='exclamation-triangle' />
            </Col>
            <Col className='py-2'>
              <FormattedMessage id='orders.employeeOrdersDisabled' />
            </Col>
          </Row>
        </Container>
      </Alert>
    </Container>
  );

  return (
    <div id='checkout' className='animate-bottom-fade-in content-wrapper'>
      {employeeOrdersDisabled ? employeeOrdersDisabledMarkup : <Fragment />}
      {employeeOrdersDisabled ? <Fragment /> : !isLoading && props.cart.pendingOrder && !props.cart.getCart.isLoading ? <Fragment>
        <InvalidCartMessage canCheckOut={canCheckOut}/>
        <Row>
          <Col xs={12} md={8}>
            <PurchaseOrder
              trySubmit={trySubmit}
              onPurchaseOrderNumberChange={handlePurchaseOrderNumberChange}
              employeeOrdersDefaultPONumber={props.configuration.featureToggles.PONumberDefault && props.isEmployeeAccount}
            />
            {containsCustomLogo() &&
              <EmailConfirmation
                trySubmit={trySubmit}
                onEmailConfirmationChange={handleEmailConfirmationChange}
            />}
            {shippingAddressMarkup}
            <PaymentMethod
              accountNumber={props.accountNumber}
              invoiceAccount={props.cart.pendingOrder.invoiceAccount}
              branch={branch}
              pciFeatureEnabled={props.configuration.featureToggles.PCI && props.isCreditCardOnlyAccount}
              square={props.square}
              onSetSelectedCard={props.squareActions.setSelectedCard}
              onDeleteCard={handleDeleteCard}
              onCreditCardAdded={handleCreditCardAdded}
              onTestCreditCardAdded={handleAddTestCard}
              toggleTestCardDialogHidden={toggleTestCardDialogHidden}
              testCardDialogHidden={testCardDialogHidden}
            />
            <ShippingSpeed
              countryCode={props.cart.pendingOrder.address.country}
              onShippingSpeedChange={setShippingSpeed}
              isEmployee={props.isEmployeeAccount}
              containsCustomLogo={containsCustomLogo()}
              containsNonCustomGear={containsNonCustomGear()}
              address={props.address}
              expeditedShippingEnabled={props.configuration.featureToggles.ExpeditedShipping}
              freeTruckShippingEnabled={props.configuration.featureToggles.FreeTruckShipping}
            />
            <RequestedShipDate onRequestedShipDateChange={setRequestedShipDate}/>
          </Col>
          <Col xs={12} md={4}>
            <OrderSummary
              cart={props.cart}
              shippingSpeed={shippingSpeed}
            />
            <Row className='justify-content-end mt-3'>
              <Col xs={12} md={6} className='mb-3 mb-sm-0'>
                <Button data-test-id='checkout-cancel' onClick={() => history.push('/cart')} block color='secondary'>
                  <FormattedMessage id='general.cancel'/>
                </Button>
              </Col>
              <Col xs={12} md={6}>
                <Button data-test-id='checkout-submit' onClick={() => handleSubmitClick()} block color='primary' isLoading={props.cart.submitCart.isProcessing} isDisabled={!canCheckOut}>Submit Order</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment> : <PageLoader/>}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    cart: state.cart,
    account: state.account,
    address: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.address
      : state.account.currentAccount.accountDetails.address,
    addresses: state.addresses,
    square: state.square,
    configuration: state.configuration,
    accountNumber: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.accountNumber
      : state.account.currentAccount.accountDetails.accountNumber,
    accountChildren: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.children
      : state.account.currentAccount.accountDetails.children,
    isEmployeeAccount: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.isEmployeeAccount
      : state.account.currentAccount.accountDetails.isEmployeeAccount,
    isUsEmployeeAccount: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.isEmployeeAccount
      : state.account.currentAccount.accountDetails.isEmployeeAccount && state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.currencyCode === "USD"
      : state.account.currentAccount.accountDetails.currencyCode === "USD",
    isCreditCardOnlyAccount: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.isCreditCardOnlyAccount &&
      state.account.impersonatedAccount.accountDetails.currencyCode === "USD"
      : state.account.currentAccount.accountDetails.isCreditCardOnlyAccount &&
      state.account.currentAccount.accountDetails.currencyCode === "USD",
    currencyCode: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.currencyCode
      : state.account.currentAccount.accountDetails.currencyCode,
    accountLoaded: !state.account.currentAccount.isLoading &&
      state.account.currentAccount.accountDetails.accountNumber !== '',
    states: state.states.states,
    statesLoading: state.states.getStates.isLoading,
    countries: state.countries.countries,
    countriesLoading: state.countries.getCountries.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    squareActions: bindActionCreators(squareActions, dispatch),
    configurationActions: bindActionCreators(configurationActions, dispatch),
    addressActions: bindActionCreators(addressActions, dispatch),
    statesActions: bindActionCreators(statesActions, dispatch),
    countriesActions: bindActionCreators(countriesActions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Checkout));