import Enumerable from 'linq';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Col, Container, Row, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { Button, Icon, Link, Message, PageLoader, Price, LoadingDots } from '../../components';
import { OrderStatus } from '../../enums';
import { getPreferences, updatePreferences, getProfile } from '../../helpers/localStorage';
import { useCanCheckOut } from '../../hooks';
import { availabilityActions, cartActions, priceActions, wishlistsActions, configurationActions } from '../../store';
import CartExpiredModal from './components/CartExpiredModal';
import EmptyCart from './components/EmptyCart';
import OrderLineGroup from './components/OrderLineGroup';
import MultiSiteOrderLineGroup from './components/MultiSiteOrderLineGroup';
import SaveForLater from './components/SaveForLater';
import user from '../../helpers/user';
import { theme } from '@met/react-components';
import styled, { css } from 'styled-components';

const isMultisiteGroup = (key) => {
  return key.toLowerCase().indexOf('multisite:') > -1
};

const getOrderLineGroupIndex = (key) => {
  // We want to put things in order of unavailable, backordered, other, then in stock
  switch (key) {
    case 'unavailable':
      return 0;
    case 'backordered':
      return 1;
    case 'other':
      return 2;
    case 'inStock':
      return 3;
    default:
      return isMultisiteGroup(key) ? -1 : 2;
  }
};

const Title = styled.h5`
  ${css`color: ${theme.colors.yellow};`}
`;

const Cart = (props) => {
  const canCheckOut = useCanCheckOut(props.cart, props.account);
  const [useGridView, setUseGridView] = useState(getPreferences().useGridView || false);
  const [isFillOrKill] = useState(getProfile().cachedAccountDetails?.isFillOrKill || false);

  useEffect(() => {
    return () => {
      props.cartActions.resetOnNavigation();
    };
  }, []);

  useEffect(() => {
    props.getConfig();
    props.wishlistsActions.getWishlist();
  }, []);

  useEffect(() => {
    let skus = Enumerable.from(props.saveForLater.wishlistLines).select(x => x.sku).toArray();
    props.priceActions.getPrices(skus, props.accountNumber);
    props.availabilityActions.getAvailability(skus, props.accountNumber);
  }, [props.saveForLater.wishlistLines]);

  useEffect(() => {
    if (props.accountNumber) {
      props.cartActions.getCart(props.accountNumber, isFillOrKill);
    }

    const interval = setInterval(() => {
      if (props.accountNumber) {
        props.cartActions.getCart(props.accountNumber, isFillOrKill);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [props.accountNumber]);

  const handleRemoveFromCartClick = (product) => {
    return props.cartActions.removeFromCart(product.sku, props.accountNumber, product.customLogoRefId)
      .then(() => props.cartActions.getCart(props.accountNumber, isFillOrKill))
      .then(() => props.cartActions.getCartStatistics(props.accountNumber));
  };

  const emptyCart = () => {
    return props.cartActions.removeFromCart(null, props.accountNumber)
      .then(() => props.cartActions.getCart(props.accountNumber, isFillOrKill))
      .then(() => props.cartActions.getCartStatistics(props.accountNumber));
  };

  const handleQuantityChange = (product, quantity) => {
    props.cartActions.updateQuantity(product.sku, quantity, props.accountNumber, product.customLogoRefId)
      .then(() => props.cartActions.getCart(props.accountNumber, isFillOrKill))
      .then(() => props.cartActions.getCartStatistics(props.accountNumber));
  };

  const handleRefreshCartClick = () => {
    return props.cartActions.refreshCart(props.accountNumber);
  };

  const handleToggleView = () => {
    updatePreferences({ useGridView: !useGridView });
    setUseGridView(!useGridView);
  };

  const moveToWishlist = (sku, accountNumber, customLogoRefId, orderLineIds, errorDescription) => {
    return props.cartActions.moveToWishlist(sku, accountNumber, customLogoRefId, orderLineIds)
      .then(() => {
        props.wishlistsActions.getWishlist();
      })
      .catch(() => {
        toastr.error(props.intl.formatMessage({ id: 'general.errorToastTitle' }), props.intl.formatMessage({ id: 'wishlists.errorAddingToWishlist' }, { description: errorDescription }), { timeout: 0 });
      });
  };

  const handleSaveForLaterClick = (orderLine) => {
    return moveToWishlist(orderLine.sku, props.accountNumber, orderLine.customLogoRefId, null, orderLine.description);
  };

  const handleSaveGroupForLaterClick = (orderLineIds) => {
    return moveToWishlist(null, props.accountNumber, null, orderLineIds, 'items');
  };

  const moveToCart = (sku, customLogoRefId, orderLineIds) => {
    return props.wishlistsActions.moveToCart(sku, customLogoRefId, null, orderLineIds)
      .then(() => {
        props.wishlistsActions.getWishlist();
      })
      .catch(() => {
        toastr.error(props.intl.formatMessage({ id: 'general.errorToastTitle' }), props.intl.formatMessage({ id: 'cart.errorAddingToCart' }));
      });
  };

  const handleMoveToCartClick = (orderLine) => {
    return moveToCart(orderLine.sku, orderLine.customLogoRefId, null);
  };

  const handleMoveGroupToCartClick = (orderLineIds) => {
    return moveToCart(null, null, orderLineIds);
  };

  const handleRemoveFromWishlistClick = (wishlistLine) => {
    props.wishlistsActions.removeFromWishlist(wishlistLine.sku, null, wishlistLine.customLogoRefId)
      .then(() => {
        props.wishlistsActions.getWishlist();
        toastr.success(props.intl.formatMessage({ id: 'wishlists.removedFromWishlistTitle' }), props.intl.formatMessage({ id: 'wishlists.removedFromWishlist' }, { description: wishlistLine.description }));
      })
      .catch(() => {
        props.wishlistsActions.getWishlist();
        toastr.error(props.intl.formatMessage({ id: 'general.errorToastTitle' }), props.intl.formatMessage({ id: 'wishlists.errorRemovingFromWishlist' }, { description: wishlistLine.description }));
      });
  };

  const groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  let orderLineGroups;
  if (props.cart.pendingOrder && props.cart.pendingOrder.orderLines) {
    orderLineGroups = new Array(Object.keys(props.cart.pendingOrder.orderLines).length);
    let nextMultisiteIndex = 5;
    for (const [key, value] of Object.entries(props.cart.pendingOrder.orderLines)) {
      let groupIndex = getOrderLineGroupIndex(key);
      orderLineGroups[groupIndex === -1 ? nextMultisiteIndex++ : groupIndex] = (
        isMultisiteGroup(key) ?
        <MultiSiteOrderLineGroup
          key={`lines-${key}`}
          group={key.replace('multisite:', '')}
          orderLines={value}
          useGridView={useGridView}
          onRemoveFromCartClick={handleRemoveFromCartClick}
          onSaveForLaterClick={handleSaveForLaterClick}
          onQuantityChange={handleQuantityChange}
          onSaveGroupForLaterClick={handleSaveGroupForLaterClick}
        /> :
        <OrderLineGroup
          key={`lines-${key}`}
          group={key}
          orderLines={value}
          useGridView={useGridView}
          onRemoveFromCartClick={handleRemoveFromCartClick}
          onSaveForLaterClick={handleSaveForLaterClick}
          onQuantityChange={handleQuantityChange}
        />
      );
    }
  }

  let wishlistMarkup = [];
  if (props.saveForLater && props.saveForLater.wishlistLines && props.saveForLater.wishlistLines.length > 0) {
    if (isFillOrKill) {
      const groupBySite = groupBy('site');
      const defaultSite = "12";
      const wishlistGroups = groupBySite(props.saveForLater.wishlistLines.sort((a, b) => (a.site === defaultSite ? "0" : a.site) < (b.site === defaultSite ? "0" : b.site)));
      let nextGroupIndex = 1;
      for (const [key, value] of Object.entries(wishlistGroups)) {
        wishlistMarkup.push(
          <SaveForLater
            key={`wishlists-${key}`}
            groupName={`Group ${nextGroupIndex++}`}
            wishlist={props.saveForLater}
            saveForLaterLines={value}
            useGridView={useGridView}
            locale={props.locale}
            pricing={props.pricing}
            availability={props.availability}
            isProcessing={props.wishlistUpdating}
            handleDeleteClick={handleRemoveFromWishlistClick}
            handleMoveToCartClick={handleMoveToCartClick}
            addGroupClick={handleMoveGroupToCartClick}
          />
        );
      }
    } else {
      wishlistMarkup.push(
        <SaveForLater
          key={`wishlists`}
          groupName={null}
          wishlist={props.saveForLater}
          saveForLaterLines={props.saveForLater.wishlistLines}
          useGridView={useGridView}
          locale={props.locale}
          pricing={props.pricing}
          availability={props.availability}
          isProcessing={props.wishlistUpdating}
          handleDeleteClick={handleRemoveFromWishlistClick}
          handleMoveToCartClick={handleMoveToCartClick}
          addGroupClick={handleMoveGroupToCartClick}
        />
      )
    }
  }

  let employeeOrdersDisabled = user.isMilwaukeeToolEmployee() && props.configuration.featureToggles.EmployeeOrdersDisabled === true;
  let employeeOrdersDisabledMarkup = employeeOrdersDisabled && (
    <Container>
      <Alert color='danger' className='mt-4 mb-0 font-weight-bold d-print-none'>
        <Container>
          <Row className='my-auto'>
            <Col xs='auto' className='my-auto'>
              <FontAwesomeIcon className='mr-2' color='black' icon='exclamation-triangle' />
            </Col>
            <Col className='py-2'>
              <FormattedMessage id='orders.employeeOrdersDisabled' />
            </Col>
          </Row>
        </Container>
      </Alert>
    </Container>
  );

  let multishipmentWarning = props.cart.pendingOrder != null && Object.keys(props.cart.pendingOrder.orderLines).some(key => isMultisiteGroup(key)) && (
    <Container>
      <Alert color='warning' className='account-selection mt-4 mb-0 d-print-none'>
        <Container>
          <Row className='my-auto'>
            <Col xs='auto' className='my-auto'>
              <FontAwesomeIcon className='mr-2' color='black' icon='exclamation-triangle' />
            </Col>
            <Col className='py-2'>
              <FormattedMessage id='multishipment.multishipmentWarningMessage' values={{
                // eslint-disable-next-line react/display-name
                b: chunks => <b>{chunks}</b>,
                br: <br />
              }} />
            </Col>
          </Row>
        </Container>
      </Alert>
    </Container>
  );

  let multiSiteShipmentMarkup = props.cart.pendingOrder != null && Object.keys(props.cart.pendingOrder.orderLines).some(key => isMultisiteGroup(key)) && (
    <Container className='order-line-preview-grid print-group'>
      <Row className='align-items-center animate-bottom-fade-in'>
        <Col xs='auto' className='mb-3'>
        <Title className='mb-3' >
            <FormattedMessage id='multishipment.multiSiteShipment' />
        </Title>
        </Col>
      </Row>
      {props.cart.getCart.isLoading && !props.cart.pendingOrder ? <PageLoader /> : orderLineGroups?.slice(5)}
    </Container>
    );

  return (
    <div id='cart' className='content-wrapper'>
      {employeeOrdersDisabledMarkup}
      <Container>
        <Row className='my-3 align-items-end'>
          <Col xs='auto' className='mr-3'>
            <h2 data-test-id='text-your-cart' style={{ marginTop: '10px' }}>Your Cart</h2>
          </Col>
          <Col xs sm='auto' className='align-self-center text-right text-md-left'>
            <Button data-test-id='empty-cart' outline color='primary' onClick={emptyCart}>Empty Cart</Button>
          </Col>
          <Col xs='12' sm className='text-right d-print-none align-self-center mt-3 mt-sm-0' data-test-id='cart-image-or-list'>
            <Icon type='image' onClick={handleToggleView} className={useGridView ? 'mx-2 has-pointer-icon fa-dim' : 'mx-2'} isDisabled={!useGridView} color='black' />
            <Icon type='list' onClick={handleToggleView} className={!useGridView && 'has-pointer-icon fa-dim'} isDisabled={useGridView} color='black' />
          </Col>
        </Row>
        <EmptyCart cart={props.cart} />
        <Message
          type='info'
          icon='info-circle'
          value={props.intl.formatMessage({ id: 'cart.accountIssues' })}
          isHidden={!props.cart.pendingOrder || (props.cart.pendingOrder && props.cart.pendingOrder.orderStatusId !== OrderStatus.AccountNotFoundQuoteFailedToProcess)}
        />
        {multiSiteShipmentMarkup}
        
        {props.cart.getCart.isLoading && !props.cart.pendingOrder ? <PageLoader /> : orderLineGroups?.slice(0, 4)}
        {props.cart.pendingOrder && <Row className='justify-content-end'>
          {multishipmentWarning}
          <Col xs='auto' data-test-id='cart-checkout'>
            <div className='d-flex align-items-baseline justify-content-center mt-4'>
              <span className='has-font-size-4 mb-0 mr-2'>Order Subtotal:</span>
              <span className='has-font-size-5 font-weight-bold' data-test-id='cart-subtotal'>
                <Price
                  value={props.cart.pendingOrder.subtotal || 0}
                  isLoading={props.cart.isRefreshing}
                  canBeZero={true}
                />
              </span>
            </div>
            <div className='has-font-size-2 text-muted mb-3'>{props.cart.pendingOrder.totalQuantity || 0} items {props.cart.pendingOrder.totalWeight > 0 && (<span style={{ fontWeight: 'bold', color: 'black', float: 'right' }}>{props.cart.isRefreshing ? <LoadingDots /> : <span>Total Weight: {props.cart.pendingOrder.totalWeight.toFixed(2)}</span>}</span>)}</div>
            <Button block
              color='primary' tag={Link} to='checkout' className='d-print-none'
              isLoading={props.cart.isRefreshing}
              isDisabled={!canCheckOut || employeeOrdersDisabled}>Checkout</Button>
          </Col>
        </Row>}
        {wishlistMarkup}
      </Container>
      {props.cart && props.cart.isExpired && props.cart.pendingOrder?.totalQuantity &&
        <CartExpiredModal
          isOpen={props.cart.isExpired}
          onRefreshClick={handleRefreshCartClick}
          onEmptyCartClick={emptyCart}
        />
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    configuration: state.configuration,
    cart: state.cart,
    account: state.account,
    accountNumber: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.accountNumber
      : state.account.currentAccount.accountDetails.accountNumber,
    saveForLater: state.wishlists.getWishlist && state.wishlists.getWishlist.wishlist,
    wishlistUpdating: state.wishlists.removeFromWishlist && state.wishlists.removeFromWishlist.isProcessing,
    locale: state.locales.locale,
    pricing: state.pricing,
    availability: state.availability
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConfig: () => dispatch(configurationActions.getConfig()),
    cartActions: bindActionCreators(cartActions, dispatch),
    wishlistsActions: bindActionCreators(wishlistsActions, dispatch),
    priceActions: bindActionCreators(priceActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Cart));