import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardBody, CardTitle, Col, Row} from '@met/react-components'
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import history from '../../history';

class OrderInformation extends Component {
  
  goToOrders = ()=>{
    history.push("orders");
  };
  goToInvoices = ()=>{
   history.push("invoices");
  };
  goToCart = ()=>{
    history.push("cart");
  };
  
  
  render() {
    return (
      <Card className='overview-card'>
        <CardBody>
          <CardTitle h5>
            <FormattedMessage id='home.orderInformationCardTitle'/>
          </CardTitle>
          <Row className='overview-sections'>
            <Col data-test-id='home-order-information-orders' className='overview-section' onClick={this.goToOrders}>
              <div>
                <FontAwesomeIcon icon='truck' className='is-color-green'/>
                <span>{this.props.orderCount}</span>
              </div>
              <div><FormattedMessage id='general.orders'/></div>
            </Col>
            {this.props.canViewInvoices && 
              <Col data-test-id='home-order-information-invoices'  className='overview-section' onClick={this.goToInvoices}>
                  <div>
                    <FontAwesomeIcon icon='file-alt' className='is-color-blue'/>              
                    <span>{this.props.invoiceCount}</span>
                  </div>
                  <div> <FormattedMessage id='general.invoices'/></div>
             </Col>
            }
            <Col data-test-id='home-order-information-cart' className='overview-section' onClick={this.goToCart}>
              <div>
                <FontAwesomeIcon icon='shopping-cart' className='is-color-black'/>
                <span>{this.props.cartQuantity}</span>
              </div>
               <div><FormattedMessage id='general.cart'/></div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default injectIntl(OrderInformation);