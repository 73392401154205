import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalHeader} from 'reactstrap';
import Button from '../Button/Button';

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchHelp: false
    };
  }
  
  toggleSearchHelpModal = () => {
    this.setState({
      ...this.state,
      showSearchHelp: !this.state.showSearchHelp
    });
  };
  
  render() {
    let clearButton = this.props.value && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div data-test-id='search-clear' className='clear-button' color='none' onClick={this.props.onClear}>
        <FontAwesomeIcon icon='times'/>
      </div>
    );
    let helpButton = this.props.searchHelpModalContent && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div data-test-id='search-help' className='help-button' color='none' onClick={this.toggleSearchHelpModal}>
        <FontAwesomeIcon icon='question-circle'/>
      </div>
    );
    let searchHelpModal = (
      <Modal isOpen={this.state.showSearchHelp} toggle={this.toggleSearchHelpModal} centered={true}>
        <ModalHeader toggle={this.toggleSearchHelpModal}>Search Tips</ModalHeader>
        <ModalBody>
          {this.props.searchHelpModalContent}
        </ModalBody>
      </Modal>
    );

    return (
      <div>
        <InputGroup className='search-input'>
          <Input type='text' data-test-id={this.props.searchInputDataTestId || 'search-input'} placeholder={this.props.placeholder} value={this.props.value} onKeyPress={this.props.onKeyPress} onChange={this.props.onChange} />
          <InputGroupAddon addonType="append">
            <div className='search-input-icons'>
              {clearButton}
              {helpButton}
            </div>
            <Button data-test-id={this.props.searchButtonDataTestId || 'search-button'} className='search-button' color='none' onClick={this.props.onClick} isLoading={this.props.isSearching}>
              <FontAwesomeIcon icon='search'/>
            </Button>
          </InputGroupAddon>
        </InputGroup>
        {searchHelpModal}
      </div>
    );
  }
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  searchInputDataTestId: PropTypes.string,
  searchButtonDataTestId: PropTypes.string,
  isSearching: PropTypes.bool,
  onClear: PropTypes.func,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  searchHelpModalContent: PropTypes.object
};

export default SearchInput;