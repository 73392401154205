import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Card, CardBody, Col, Container, Nav, NavItem, Row} from 'reactstrap';
import {bindActionCreators} from 'redux';

import {Link, PageLoader} from '../../components';
import Stepper from '../../components/Stepper/Stepper';
import {getProfile} from '../../helpers/localStorage';
import history from '../../history';
import {warrantyClaimActions, 
  configurationActions} from '../../store';
import CustomerDetails from './CustomerDetails';
import Logistics from './Logistics';
import RepairDetails from './RepairDetails';
import ReviewClaim from './ReviewClaim';
import SearchProduct from './SearchProduct';
import AccountSelection from './AccountSelection';

class WarrantyClaimWizard extends Component {
  constructor(props){
    super(props);
    
    this.maxSteps = 7;

    this.state = {
      isLoading: false,
      currentStep: 1,
      newClaim: {
        productSku: undefined
      },
      verifiedClaim: {},
      showBatteryWarning: false,
      isFlatRateClaim: false
    };

    props.configurationActions.getConfig();

    this.next = this.next.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
  }

  next = (data) => {
    let { configuration } = this.props;
    let currentStep = this.state.currentStep;
    if (currentStep < this.maxSteps) {
      currentStep = currentStep + 1;

      let showBatteryWarning = this.state.showBatteryWarning;

      if (this.state.currentStep === 1) {
        let acceptingBatteryClaims = !configuration.getConfig.isLoading && configuration.featureToggles && configuration.featureToggles.AcceptingBatteryClaims;
        let batteryClaimExclusions = !configuration.getConfig.isLoading && configuration.batteryClaimExclusionCountries;
        
        showBatteryWarning = !acceptingBatteryClaims
          && batteryClaimExclusions 
          && !batteryClaimExclusions.filter(e => e === data.accountCountry).length > 0;
      }

      this.setState({
        ...this.state,
        isLoading: false,
        currentStep: currentStep,
        newClaim: {
          ...this.state.newClaim,
          ...data
        },
        showBatteryWarning: showBatteryWarning
      });
    }
  };

  prev = () => {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep = currentStep - 1;
    }

    this.setState({
      ...this.state,
      currentStep: currentStep
    });
  };

  validateClaim = (sku) => {
    this.setState({
      ...this.state,
      isLoading: true,
      newClaim: {
        ...this.state.newClaim,
        productSku: sku
      }
    }, () => {
      this.props.validateClaim({
        Type: 'Customer',
        AccountNumber: this.state.newClaim.accountNumber,
        Skus: [sku]
      }).then(payload => {
        if (payload.isValid) {
          this.setState({
            ...this.state,
            newClaim: {
              ...this.state.newClaim,
              serviceProducLine: payload.serviceProducLine
            },
            isFlatRateClaim: payload.isFlatRateClaim
          }, () => {
            this.next();
          })
        } else {
          this.setState({
            ...this.state,
            isLoading: false
          });
        }
      });
    });
  };

  verifyClaim = (data) => {
    const { jumpToPage } = this;
    const { intl } = this.props;
    this.setState({
      ...this.state,
      isLoading: true,
      newClaim: {
        ...this.state.newClaim,
        ...data
      }
    }, () => {
      let profile = getProfile();
      let formFields = {
        ...this.state.newClaim,
        userFirstName: profile.firstname,
        userLastName: profile.lastname
      };
      delete formFields.receipt;
      delete formFields.photos;
      let data = this.getValues(formFields);
      let files = [];
      if (this.state.newClaim.receipt) {
        files.push({'name': 'receipt', 'file': this.state.newClaim.receipt[0] });
      }
      if (this.state.newClaim.photos) {
        this.state.newClaim.photos.forEach(function (arrayItem, index) {
            files.push({ 'name': 'photos', 'file': arrayItem});
        });
      }
      this.props.verifyCustomerClaim(data, files).then(payload => {
        if (payload.data) {
          this.setState({
            ...this.state,
            isLoading: false,
            verifiedClaim: payload.data
          }, () => {
            this.next();
          });
        } else {
          const {errors, error} = payload;
          let pageToJumpTo = 5;
          if (errors) {
            Object.keys(errors).forEach(function (error) {
              errors[error].forEach(errorMessage => {
                pageToJumpTo = jumpToPage(errorMessage, pageToJumpTo);
                toastr.error(intl.formatMessage({id: 'warranty.submissionError.' + errorMessage}));
              });
            });
          } 
          if (error && error.details) {
            error.details.forEach(errorMessage => {
              if (errorMessage.errorCode) {
                pageToJumpTo = this.mapErrorMessage(errorMessage, pageToJumpTo);
              }
              else {
                pageToJumpTo = jumpToPage(errorMessage.name, pageToJumpTo);
                toastr.error(intl.formatMessage({id: 'warranty.submissionError.' + errorMessage.name}, {...errorMessage.data}));
              }
            });
          }
          this.setState({
            ...this.state,
            currentStep: pageToJumpTo,
            isLoading: false
          });
        }
      });
    });
  };

  mapErrorMessage = (errorMessage, pageToJumpTo) => {
    const { intl } = this.props;
    let message = '';
    switch (errorMessage.errorCode) {
      case 1:
        message = 'warranty.partsValidation.notInCatalog';
        break;
      case 2:
        message = 'warranty.partsValidation.invalidTool';
        break;
      case 3:
        message = 'warranty.partsValidation.invalidComponent';
        break;
      case 4:
        message = 'warranty.partsValidation.unauthorizedRepair';
        break;
      default:
        break;
    }
    toastr.error(intl.formatMessage({id: message}, {itemNumber: errorMessage.itemNumber}));
    return this.jumpToPage(message, pageToJumpTo);
  };

  jumpToPage = (errorMessage, currentPage) => {
    let newPage = 6;
    let message = errorMessage.split(/[. ]+/).pop();
    switch (message) {
      case 'customerIncomplete':
        newPage = 3;
        break;
      case 'notInCatalog':
      case 'invalidTool':
      case 'invalidComponent':
      case 'unauthorizedRepair':
      case 'receiptRequired':
      case 'notCertifiedAccurate':
      case 'notCertifiedSafety':
      case 'serialNumberRequired':
      case 'purchaseDateRequired':
      case 'primaryPartRequired':
      case 'duplicateParts':
      case 'warrantyLaborRequired':
      case 'warrantyLaborOutOfRange':
      case 'warrantyLaborPrecision':
      case 'laborHoursExceededForToolReplacement':
      case 'onlyToolAllowedAsPart':
      case 'noCostWarrantyClaim':
      case 'freightAllowanceGreaterThanDailyAmount':
        newPage = 4;
        break;
      case 'dateReceivedOutOfRange':
      case 'dateRepairedOutOfRange':
      case 'dateReturnedOutOfRange':
      case 'receivedViaRequired':
      case 'returnedViaRequired':
      case 'returnShippingIncomplete':
        newPage = 3;
        break;
      case 'productSkuRequired':
        newPage = 2;
        break;
      case 'notAcceptingBatteryClaims':
        newPage = 5
        break;
      default: 
        newPage = 1;
        break;
    }
    return newPage < currentPage ? newPage : currentPage;
  };

  submit = () => {
    this.setState({
      ...this.state,
      isLoading: true
    }, () => {
      let profile = getProfile();
      let formFields = {
        ...this.state.newClaim,
        userFirstName: profile.firstname,
        userLastName: profile.lastname
      };
      delete formFields.receipt;
      delete formFields.photos;
      let data = this.getValues(formFields);
      let files = [];
      if (this.state.newClaim.receipt) {
        files.push({'name': 'receipt', 'file': this.state.newClaim.receipt[0] });
      }
      if (this.state.newClaim.photos) {
        this.state.newClaim.photos.forEach(function (arrayItem, index) {
            files.push({ 'name': 'photos', 'file': arrayItem});
        });
      }
      this.props.submitCustomerClaim(data, files).then(payload => {
        if (payload.error)
          toastr.error(payload.error.details.data);
        else if (payload.claimId) {
          history.push(`/warranty/${payload.claimId}?newClaim`);
        }
      }).catch(() => {
        this.setState({
          ...this.state,
          isLoading: false
        }, () => {
          toastr.error("Error Verifying Data");
        });
      });
    });
  };

  getValues = (input) => {
    let fields = {};
    let that = this;
    Object.keys(input).forEach(function (item) {
      if (input[item] && input[item] instanceof Date) {
        fields[item] = input[item];
      }
      else if (input[item] && Array.isArray(input[item])) {
        var list = [];
        input[item].forEach(function (arrayItem, index) {
          list.push(that.getValues(arrayItem));
        });
        fields[item] = list;
      }
      else if (input[item] && typeof input[item] === 'object' && Object.prototype.hasOwnProperty.call(input[item], 'isValid')) {
        if (input[item].isValid) {
          fields[item] = input[item].value;
        }
      } else if (input[item] && typeof input[item] === 'object' && !Object.prototype.hasOwnProperty.call(input[item], 'isValid')) {
        fields[item] = that.getValues(input[item]);
      } else {
        fields[item] = input[item];
      }
    });
    return fields;
  };

  render() {
    const { locale } = this.props.match.params;
    const { intl } = this.props;
    return (
      <div id="warranty-claim-wizard" className="content-wrapper animate-bottom-fade-in">
        <Container>
          <Row id="warranty-claim-links">
            <Col>
              <Nav>
                <NavItem data-test-id='warranty-claim-search'>
                  <Link to='warranty' locale={locale} isActive={false} className='nav-link'><span>Search</span></Link>
                </NavItem>
                <NavItem data-test-id='warranty-claim-new-claim'>
                  <Link to='warranty/newClaim' locale={locale} isActive={true} className='nav-link'><span>New Claim</span></Link>
                </NavItem>
                <NavItem data-test-id='warranty-claim-defective-part-claim'>
                  <Link to='warranty/defectiveClaim' locale={locale} isActive={false} className='nav-link'><span>New Defective Part Claim</span></Link>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row id="warranty-claim-stepper">
            <Col>
              <Card>
                <CardBody>
                <Stepper steps={[
                    {label: intl.formatMessage({id: 'warranty.accountSelection'}), icon: 'users', isComplete: this.state.currentStep > 1, isActive: this.state.currentStep === 1},
                    {label: intl.formatMessage({id: 'warranty.eligibility'}), icon: 'check', isComplete: this.state.currentStep > 2, isActive: this.state.currentStep === 2},
                    {label: intl.formatMessage({id: 'warranty.customerDetails'}), icon: 'truck', isComplete: this.state.currentStep > 3, isActive: this.state.currentStep === 3},
                    {label: intl.formatMessage({id: 'warranty.logistics'}), icon: 'wrench', isComplete: this.state.currentStep > 4, isActive: this.state.currentStep === 4},
                    {label: intl.formatMessage({id: 'warranty.repairDetails'}), icon: 'user', isComplete: this.state.currentStep > 5, isActive: this.state.currentStep === 5},
                    {label: intl.formatMessage({id: 'warranty.claimReview'}), icon: 'clipboard-check', isComplete: this.state.currentStep > 6, isActive: this.state.currentStep === 6},
                    {label: intl.formatMessage({id: 'warranty.claimConfirmation'}), icon: 'check', isComplete: this.state.currentStep >= 7, isActive: this.state.currentStep === 7}
                  ]} beforeToRender={1} afterToRender={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.showBatteryWarning &&
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <p>At Milwaukee Tool, the health and safety of our employees continues to be our number one priority.</p>
                    <p>Due to impact of COVID-19, our Battery Warranty Claim online option is temporarily suspended until further notice.</p>
                    <p>Please do not send any batteries in for warranty.</p>
                    <p>We apologize for any inconvenience this causes, and we will update upon resuming battery warranty claims.</p>
                    <p>Please reach out to our Customer Experience Team with any questions or concerns at 1-800-SAWDUST (1-800-729-3878) or local Milwaukee Tool Sales Representative.</p>
                    <p>Thank you for your patience and consideration during these unprecedented times. </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
          {this.state.isLoading && <PageLoader />}
          {!this.state.isLoading && this.state.currentStep === 1 &&
            <AccountSelection
              next={this.next}
              data={this.state.newClaim}
              accountNumber={this.props.accountNumber}
              currentAccount={this.props.currentAccount}
              accountChildren={this.props.accountChildren}
            />
          }
          {!this.state.isLoading && this.state.currentStep === 2 &&
          <SearchProduct
            prev={this.prev}
            validateClaim={this.validateClaim}
            match={this.props.match}
            sku={this.state.newClaim.productSku}
          />
          }
          {!this.state.isLoading && this.state.currentStep === 3 &&
            <CustomerDetails
              next={this.next}
              prev={this.prev}
              data={this.state.newClaim}
            />
          }
          {!this.state.isLoading && this.state.currentStep === 4 &&
            <Logistics
              next={this.next} 
              prev={this.prev}
              data={this.state.newClaim}
              isFlatRate={this.state.isFlatRateClaim}
            />
          }
          {!this.state.isLoading && this.state.currentStep === 5 &&
            <RepairDetails
              verifyClaim={this.verifyClaim}
              prev={this.prev}
              data={this.state.newClaim}
              getClaimsForCustomer={this.props.getClaimsForCustomer}
              claimsForCustomer={this.props.claimsForCustomer}
              isFlatRate={this.state.isFlatRateClaim}
            />
          }
          {!this.state.isLoading && this.state.currentStep === 6 &&
            <ReviewClaim
              claim={this.state.verifiedClaim}
              prev={this.prev}
              submit={this.submit}
              isLoading={this.state.isLoading}
            />
          }
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    accountNumber: state.account.currentAccount.accountDetails.accountNumber,
    currentAccount: state.account.currentAccount,
    accountChildren: state.account.currentAccount.accountDetails.children,
    claimsForCustomer: state.warrantyClaim.getClaims && state.warrantyClaim.getClaims.claims,
    configuration: state.configuration
  }
};

const mapDispatchToProps = dispatch => ({
  validateClaim: (query) => dispatch(warrantyClaimActions.validateClaim(query)),
  verifyCustomerClaim: (fields, files) => dispatch(warrantyClaimActions.verifyCustomerClaim(fields, files)),
  submitCustomerClaim: (fields, files) => dispatch(warrantyClaimActions.submitCustomerClaim(fields, files)),
  getClaimsForCustomer: (customerId, date) => dispatch(warrantyClaimActions.getClaimsForCustomer(customerId, date)),
  configurationActions: bindActionCreators(configurationActions, dispatch)
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WarrantyClaimWizard));