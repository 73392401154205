import { flattenMessages, DEFAULT_LOCALE } from '../locales/utils';
import translation from '../locales/messages';

export const UPDATE_LOCALE = 'UPDATE_LOCALE';

export const initialState = {
  locale: DEFAULT_LOCALE,
  messages: flattenMessages(translation[DEFAULT_LOCALE])
};

export const actionCreators = {
  updateLocale: (locale) => {
    return {type: UPDATE_LOCALE, locale};
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type){
    case UPDATE_LOCALE:
      return {
        ...state,
        locale: action.locale,
        messages: flattenMessages(translation[action.locale])
      };
    default:
      return state;
  }
};
