import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Pagination from 'react-js-pagination';
import {Table} from 'reactstrap';
import {Message} from '../../components';
import PartsListItem from './PartsListItem';
import user from '../../helpers/user';

class PartsListTable extends Component {
  constructor(props) {
    super(props);
    this.isApprover = user.canApproveWarrantyClaims();
    this.state = {
      currentPage: 1
    }
  }

  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      currentPage: pageNumber
    });
  };

  handleQuantityChange = (quantity, index) => {
    this.props.onQuantityChange(quantity, index);
  };

  handlePartLineTypeChange = (selected, index) => {
    this.props.onPartLineTypeChange(selected.value, index);
  };

  handleDeleteClick = (index) => {
    this.props.onDeleteClick(index);
  };

  getPartLineTypeValue = (id) => {
    switch (id) {
      case 1:
        return this.props.intl.formatMessage({id: 'warranty.partLineType.warranty'});
      case 2:
        return this.props.intl.formatMessage({id: 'warranty.partLineType.recall'});
      case 3:
        return this.props.intl.formatMessage({id: 'warranty.partLineType.goodwill'});
      default:
        return '';
    }
  };
  
  getPartsValidationError = (id, itemNumber) => {
    switch (id) {
      case 1:
        return this.props.intl.formatMessage({id: 'warranty.partsValidation.notInCatalog'}, {itemNumber: itemNumber});
      case 2:
        return this.props.intl.formatMessage({id: 'warranty.partsValidation.invalidTool'}, {itemNumber: itemNumber});
      case 3:
        return this.props.intl.formatMessage({id: 'warranty.partsValidation.invalidComponent'}, {itemNumber: itemNumber});
      case 4:
        return this.props.intl.formatMessage({id: 'warranty.partsValidation.unauthorizedRepair'}, {itemNumber: itemNumber});
      default:
        return '';
    }
  };
  
  render() {
    const { parts, validationErrors, paginate, linesLimit, showPrices } = this.props;
    
    let rows;
    let validationErrorsMarkup;
    let pagination;
    
    if (parts && parts.length > 0) {
      let partsEnum = Enumerable.from(parts);
      if (linesLimit)
        partsEnum = partsEnum.skip((this.state.currentPage - 1) * linesLimit).take(linesLimit);

      const partLineTypes = [
        this.getPartLineTypeValue(1),
        this.getPartLineTypeValue(2),
        this.getPartLineTypeValue(3)
      ];

      const partLineTypeOptions = partLineTypes.map((value, index) => {
        return (
          {value: index + 1, label: value}
        );
      });

      rows = partsEnum.select((part, index) => {
        return (
          <tr className='parts-list-item' key={`part-${index}`}>
            <PartsListItem
              index={index}
              quantity={part.quantity}
              listPrice={part.listPrice}
              unitPrice={part.unitPrice}
              itemNumber={part.itemNumber}
              description={part.description}
              partLineType={part.partLineType}
              partLineTypeOptions={partLineTypeOptions}
              onQuantityChange={this.handleQuantityChange}
              onPartLineTypeChange={this.handlePartLineTypeChange}
              onDeleteClick={this.handleDeleteClick}
              canEdit={this.props.canEdit}
              showPrices={showPrices}
            />
          </tr>
        );
      }).toArray();

      validationErrorsMarkup = validationErrors && validationErrors.map((error, index) => {
        return (
          <Fragment key={`validation-error-${index}`}>
            <Message
              type='warning'
              icon='exclamation-triangle'
              value={this.getPartsValidationError(error.errorCode, error.itemNumber)}
            />
          </Fragment>
        );
      });
      
      pagination = paginate && parts.length > linesLimit && (
        <div className='mb-2'>
          <Pagination
            activePage={this.state.currentPage}
            itemsCountPerPage={linesLimit}
            totalItemsCount={parts.length}
            pageRangeDisplayed={8}
            innerClass='pagination justify-content-center flex-wrap'
            itemClass='page-item'
            linkClass='page-link'
            nextPageText={<FontAwesomeIcon icon='angle-right'/>}
            prevPageText={<FontAwesomeIcon icon='angle-left'/>}
            lastPageText={<FontAwesomeIcon icon='angle-double-right'/>}
            firstPageText={<FontAwesomeIcon icon='angle-double-left'/>}
            hideFirstLastPages={false}
            onChange={this.handlePageChange}
          />
        </div>
      );
    }
    
    return (
      <Fragment>
        {rows && <Table striped borderless responsive className='parts-list-table'>
          <thead>
          <tr>
            <th className='min-width'><FormattedMessage id='general.quantityAbbr'/></th>
            <th className='min-width'><FormattedMessage id='general.itemNumber'/></th>
            <th className='min-width'><FormattedMessage id='general.description'/></th>
            {showPrices &&
              <Fragment>
                <th><FormattedMessage id='pricing.listPrice'/></th>
                <th><FormattedMessage id='pricing.reimbursementPrice'/></th>
                <th><FormattedMessage id='pricing.extendedPriceAbbr'/></th>
              </Fragment>
            }
            {this.isApprover &&
            <Fragment>
              <th>&nbsp;</th>
              <th className='min-width'>&nbsp;</th>
            </Fragment>
            }
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
         </Table>
        }
        {validationErrorsMarkup}
        {pagination}
      </Fragment>
    )
  }
}

PartsListTable.propTypes = {
  parts: PropTypes.array,
  validationErrors: PropTypes.array,
  paginate: PropTypes.bool,
  linesLimit: PropTypes.number,
  onQuantityChange: PropTypes.func,
  onPartLineTypeChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
  canEdit: PropTypes.bool,
  showPrices: PropTypes.bool
};

export default injectIntl(PartsListTable);