import createFetchAction from './createFetchAction';
import { getCardExpired } from '../helpers/creditCardHelper';

export const ADD_TEMP_CARD = 'ADD_TEMP_CARD';
export const DELETE_TEMP_CARD = 'DELETE_TEMP_CARD';
export const SET_SELECTED_CARD = 'SET_SELECTED_CARD';

export const GET_INVITE_TO_PAY = 'GET_INVITE_TO_PAY';
export const GET_INVITE_TO_PAY_SUCCESS = 'GET_INVITE_TO_PAY_SUCCESS';
export const GET_INVITE_TO_PAY_ERROR = 'GET_INVITE_TO_PAY_ERROR';

export const GET_CREDIT_CARDS = 'GET_CREDIT_CARDS';
export const GET_CREDIT_CARDS_SUCCESS = 'GET_CREDIT_CARDS_SUCCESS';
export const GET_CREDIT_CARDS_ERROR = 'GET_CREDIT_CARDS_ERROR';

export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD';
export const ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS';
export const ADD_CREDIT_CARD_ERROR = 'ADD_CREDIT_CARD_ERROR';

export const GET_SQUARE_CONFIG = 'GET_SQUARE_CONFIG';
export const GET_SQUARE_CONFIG_SUCCESS = 'GET_SQUARE_CONFIG_SUCCESS';
export const GET_SQUARE_CONFIG_ERROR = 'GET_SQUARE_CONFIG_ERROR';

export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
export const DELETE_CREDIT_CARD_ERROR = 'DELETE_CREDIT_CARD_ERROR';

export const SUBMIT_INVITE_TO_PAY = 'SUBMIT_INVITE_TO_PAY';
export const SUBMIT_INVITE_TO_PAY_SUCCESS = 'SUBMIT_INVITE_TO_PAY_SUCCESS';
export const SUBMIT_INVITE_TO_PAY_ERROR = 'SUBMIT_INVITE_TO_PAY_ERROR';

export const initialState = {
  squareApplicationId: '',
  allowTestCards: false,
  creditCards: null,
  selectedCard: null,
  inviteToPay: {
    transaction: null,
    isLoading: false,
    isError: false
  },
  getCreditCards: {
    isLoading: false,
    isError: false,
  },
  addCreditCard: {
    isLoading: false,
    isError: false
  },
  getSquareConfig: {
    isLoading: false,
    isError: false
  },
  deleteCreditCard: {
    isProcessing: false,
    isError: false
  },
  submitInviteToPay: {
    isProcessing: false,
    isError: false
  }
};

export const actionCreators = {
  getSquareConfig: (locationId) => {
    return createFetchAction({
      url: `/api/v1/square/config?locationId=${locationId ? locationId : ''}`,
      startAction: GET_SQUARE_CONFIG
    });
  },
  getInviteToPay: (inviteToPayId) => {
    return createFetchAction({
      url: `/api/v1/square/invitetopay?p=${inviteToPayId}`,
      startAction: GET_INVITE_TO_PAY
    });
  },
  getCreditCards: (locationId) => {
    return createFetchAction({
      url: `/api/v1/square/creditcards?locationId=${locationId ? locationId : ''}`,
      startAction: GET_CREDIT_CARDS
    })
  },
  addCreditCard: ({billingPostalCode, cardholderName, squareCardNonce, accountNumber, locationId}) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/square/creditcards`,
      body: {
        billingPostalCode,
        cardholderName,
        squareCardNonce,
        accountNumber,
        locationId
      },
      startAction: ADD_CREDIT_CARD
    })
  },
  deleteCreditCard: (creditCardId) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/api/v1/square/creditcards/${creditCardId}`,
      startAction: DELETE_CREDIT_CARD
    });
  },
  addTemporaryCreditCard: ({id, squareCardNonce, cardLast4, cardBrand, cardholderName, billingPostalCode, expMonth, expYear}) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_TEMP_CARD,
        payload: {
          id,
          squareCardNonce,
          cardBrand,
          cardLast4,
          cardholderName,
          billingPostalCode,
          expMonth,
          expYear
        }
      });
      
      resolve();
    });
  },
  deleteTemporaryCreditCard: (squareCardNonce) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DELETE_TEMP_CARD,
        payload: {
          squareCardNonce
        }
      });
      
      resolve();
    });
  },
  setSelectedCard: (card) => {
    if (!card) return {type: 'NOOP'};

    return {
      type: SET_SELECTED_CARD,
      payload: card
    }
  },
  submitInviteToPay: ({inviteToPayId, creditCardId, cardNonce, billingPostalCode, cardholderName, expMonth, expYear}) => {
    return createFetchAction({
      method: 'POST',
      body: {
        inviteToPayId,
        creditCardId,
        cardNonce,
        billingPostalCode,
        cardholderName,
        expMonth,
        expYear
      },
      url: `/api/v1/square/invitetopay`,
      startAction: SUBMIT_INVITE_TO_PAY
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_SQUARE_CONFIG:
      return {
        ...state,
        getSquareConfig: {
          isLoading: true,
          isError: false
        }
      };
    case GET_SQUARE_CONFIG_SUCCESS:
      return {
        ...state,
        squareApplicationId: action.payload.data.squareApplicationId,
        allowTestCards: action.payload.data.allowTestCards,
        getSquareConfig: {
          isLoading: false,
          isError: false
        }
      };
    case GET_SQUARE_CONFIG_ERROR:
      return {
        ...state,
        getSquareConfig: {
          isLoading: false,
          isError: true
        }
      };
    
    case GET_INVITE_TO_PAY:
      return {
        ...state,
        inviteToPay: {
          ...state.inviteToPay,
          isLoading: true,
          isError: false
        }
      };
    case GET_INVITE_TO_PAY_SUCCESS:
      return {
        ...state,
        inviteToPay: {
          transaction: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case GET_INVITE_TO_PAY_ERROR:
      return {
        ...state,
        inviteToPay: {
          ...state.inviteToPay,
          isLoading: false,
          isError: true
        }
      };
      
    case GET_CREDIT_CARDS:
      return {
        ...state,
        getCreditCards: {
          isLoading: true,
          isError: false
        }
      };
    case GET_CREDIT_CARDS_SUCCESS:
      return {
        ...state,
        creditCards: action.payload.data,
        selectedCard: action.payload.data.length > 0 ? action.payload.data.find(x => !getCardExpired(x)) || null : null,
        getCreditCards: {
          isLoading: false,
          isError: false
        }
      };
    case GET_CREDIT_CARDS_ERROR:
      return {
        ...state,
        getCreditCards: {
          isLoading: false,
          isError: true
        }
      };

    case ADD_CREDIT_CARD:
      return {
        ...state,
        addCreditCard: {
          isLoading: true,
          isError: false
        }
      };
    case ADD_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        creditCards: [...state.creditCards || [], action.payload.data],
        selectedCard: action.payload.data,
        addCreditCard: {
          isLoading: false,
          isError: false
        }
      };
    case ADD_CREDIT_CARD_ERROR:
      return {
        ...state,
        addCreditCard: {
          isLoading: false,
          isError: true
        }
      };
      
    case DELETE_CREDIT_CARD:
      return {
        ...state,
        deleteCreditCard: {
          isProcessing: true,
          isError: false
        }
      };
    case DELETE_CREDIT_CARD_SUCCESS:{
      let filteredCards = state.creditCards.filter(card => card.id !== action.payload.data.id);
      
      return {
        ...state,
        creditCards: filteredCards,
        selectedCard: state.selectedCard.id === action.payload.data.id && filteredCards.length > 0 ? filteredCards[0] : filteredCards.length === 0 ? null : state.selectedCard,
        deleteCreditCard: {
          isProcessing: false,
          isError: false
        }
      };
    }
    case DELETE_CREDIT_CARD_ERROR:
      return {
        ...state,
        deleteCreditCard: {
          isProcessing: false,
          isError: true
        }
      };
      
    case SUBMIT_INVITE_TO_PAY:
      return {
        ...state,
        submitInviteToPay: {
          isProcessing: true,
          isError: false
        }
      };
    case SUBMIT_INVITE_TO_PAY_SUCCESS:
      return {
        ...state,
        submitInviteToPay: {
          isProcessing: false,
          isError: false
        }
      };
    case SUBMIT_INVITE_TO_PAY_ERROR:
      return {
        ...state,
        submitInviteToPay: {
          isProcessing: false,
          isError: true
        }
      };
      
    case ADD_TEMP_CARD:
      return {
        ...state,
        creditCards: [...state.creditCards || [], action.payload],
        selectedCard: action.payload
      };
    case DELETE_TEMP_CARD:{
      let updatedCards = state.creditCards.filter(card => card.squareCardNonce !== action.payload.squareCardNonce);
      
      return {
        ...state,
        creditCards: updatedCards,
        selectedCard: state.selectedCard.id === action.payload.id && updatedCards.length > 0 ? updatedCards[0] : updatedCards.length > 0 ? state.selectedCard : null
      };
    }
    case SET_SELECTED_CARD:
      return {
        ...state,
        selectedCard: action.payload
      };
    default:
      return state;
  }
};