import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardBody, CardFooter, CardHeader} from "@met/react-components";
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {UncontrolledTooltip} from 'reactstrap';

import {Price} from '../../../components';
import LoadingDots from '../Checkout';
import shipping from '../../../helpers/shippingHelper';

const OrderSummary = props => {
  const inStockQuantity = props.cart.pendingOrder.orderLines.inStock ? Enumerable.from(props.cart.pendingOrder.orderLines.inStock).sum(x => x.quantity) : 0;
  const customLogoOtherQuantity = props.cart.pendingOrder.orderLines.other ? Enumerable.from(props.cart.pendingOrder.orderLines.other).where(x => x.isCustomLogo).sum(x => x.quantity) : 0;
  const backorderedQuantity = props.cart.pendingOrder.orderLines.backordered ? Enumerable.from(props.cart.pendingOrder.orderLines.backordered).sum(x => x.quantity) : 0;
  const tax = props.cart.isRefreshing ? <LoadingDots/> : (props.cart.pendingOrder.taxExempt ? 'Exempt' : 'Pending');
  const shippingCostMarkup = shipping.getShippingCostMarkup(props.shippingSpeed);
  const shippingTooltipText = shipping.getShippingCostTooltip(props.shippingSpeed);

  const inStockItemsSection = (
    <div className='d-flex justify-content-between'>
      <span className='has-font-size-4 mr-3'><FormattedMessage id='orderSummary.itemsToBeShipped'/>:</span>
      <span data-test-id='order-summary-items-to-be-shipped' className='has-font-size-5 font-weight-bold'>{props.cart.isRefreshing ? <LoadingDots /> : (inStockQuantity + customLogoOtherQuantity)}</span>
    </div>
  );

  const backorderedItemsSection = backorderedQuantity > 0 && (
    <div className='d-flex justify-content-between'>
      <span className='has-font-size-4 mr-3'><FormattedMessage id='orderSummary.itemsOnBackorder'/>: <FontAwesomeIcon icon='question-circle' size='sm' id='backorderTooltip'/></span>
      <span data-test-id='order-summary-items-on-backorder' className='has-font-size-5 font-weight-bold'>{props.cart.isRefreshing ? <LoadingDots/> : backorderedQuantity}</span>
      <UncontrolledTooltip data-test-id='backorder-tool-tip' placement='bottom' target='backorderTooltip'><FormattedMessage id='orderSummary.backorderTooltip'/></UncontrolledTooltip>
    </div>
  );
  
  return (
    <Card>
      <CardHeader className='d-flex justify-content-between'>
        <span><FormattedMessage id='checkout.orderSummaryTitle'/></span>
        <Link data-test-id='order-summary-edit-cart' to='/cart'>Edit Cart</Link>
      </CardHeader>
      <CardBody>
        {inStockItemsSection}
        {backorderedItemsSection}
        <hr/>
        <div className='d-flex justify-content-between'>
          <span className='has-font-size-4 mr-3'><FormattedMessage id='general.subtotal'/>:</span>
          <span data-test-id='order-summary-subtotal' className='has-font-size-5 font-weight-bold'>
            <Price value={props.cart.pendingOrder.subtotal || 0} isLoading={props.cart.isRefreshing}/>
          </span>
        </div>
        <div className='d-flex justify-content-between'>
          <span className='has-font-size-4 mr-3'>
            <FormattedMessage id='general.shipping'/>: <FontAwesomeIcon icon='question-circle' size='sm' id='shippingTooltip'/>
          </span>
          <span data-test-id='order-summary-shipping' className='has-font-size-5'>
            {shippingCostMarkup}
          </span>
          <UncontrolledTooltip data-test-id='shipping-tool-tip' placement='bottom' target='shippingTooltip'>
            {shippingTooltipText}
          </UncontrolledTooltip>
        </div>
        <div className='d-flex justify-content-between'>
          <span className='has-font-size-4 mr-3'><FormattedMessage id='general.salesTax'/>: <FontAwesomeIcon icon='question-circle' size='sm' id='taxTooltip'/></span>
          <span data-test-id='order-summary-sales-tax' className='has-font-size-5'>{tax}</span>
          <UncontrolledTooltip data-test-id='sales-tax-tool-tip' placement='bottom' target='taxTooltip'>
            {props.cart.pendingOrder.taxExempt ? <FormattedMessage id='orderSummary.taxExempt' /> : <FormattedMessage id='orderSummary.taxToBeCalculated' />}
          </UncontrolledTooltip>
        </div>
      </CardBody>
      <CardFooter>
        <div className='d-flex justify-content-between w-100'>
          <span className='has-font-size-4 mr-3'><FormattedMessage id='general.total'/>:</span>
          <span data-test-id='order-confirmation-total' className='has-font-size-5 font-weight-bold'>
            <Price value={props.cart.pendingOrder.total || 0} isLoading={props.cart.isRefreshing}/>
          </span>
        </div>
      </CardFooter>
    </Card>
  );
};

OrderSummary.propTypes = {
  cart: PropTypes.object,
  shippingSpeed: PropTypes.string
};

export default OrderSummary;