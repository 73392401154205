import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@met/react-components';
import React from 'react';
import {Card, Col, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

const SalesPolicies = (props) => {
  const { handleSalesPolicy, salesPolicyFile, handleInternetPolicy, handleTermsOfSalePolicy, internetPolicyFile, handleCoOpPolicy, coOpPolicyFile, termsOfSalePolicyFile, handleIMAPPolicy, IMAPPolicyFile} = props;
  return (
    <Card body>
      <h4><FormattedMessage id='publications.salesPolicies' /></h4>
      <Row>
        <Col md={4} className='d-flex flex-column align-items-stretch'>
          <h5 className='is-color-dark-gray font-weight-light'>
            <FormattedMessage id='publications.sales' />
          </h5>
          <p>
            <Button secondary onClick={handleSalesPolicy}>
              <FontAwesomeIcon icon='file-excel' className='mx-2'/>{salesPolicyFile}
            </Button>
          </p>
        </Col>
        <Col md={4} className='d-flex flex-column align-items-stretch'>
          <h5 className='is-color-dark-gray font-weight-light'>
            <FormattedMessage id='publications.internet' />
          </h5>
          <p>
            <Button secondary onClick={handleInternetPolicy}>
              <FontAwesomeIcon icon='file-excel' className='mx-2'/>{internetPolicyFile}
            </Button>
          </p>
        </Col>
        <Col md={4} className='d-flex flex-column align-items-stretch'>
          <h5 className='is-color-dark-gray font-weight-light'>
            <FormattedMessage id='publications.coOpPolicies' />
          </h5>
          <p>
            <Button secondary onClick={handleCoOpPolicy}>
              <FontAwesomeIcon icon='file-excel' className='mx-2'/>{coOpPolicyFile}
            </Button>
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={4} className='d-flex flex-column align-items-stretch'>
          <h5 className='is-color-dark-gray font-weight-light'>
            <FormattedMessage id='publications.termsOfSale' />
          </h5>
          <p>
            <Button secondary onClick={handleTermsOfSalePolicy}>
              <FontAwesomeIcon icon='file-excel' className='mx-2' />{termsOfSalePolicyFile}
            </Button>
          </p>
        </Col>
        <Col md={4} className='d-flex flex-column align-items-stretch'>
          <h5 className='is-color-dark-gray font-weight-light'>
            <FormattedMessage id='publications.iMapPolicy' />
          </h5>
          <p>
            <Button secondary onClick={handleIMAPPolicy}>
              <FontAwesomeIcon icon='file-excel' className='mx-2' />{IMAPPolicyFile}
            </Button>
          </p>
        </Col>
      </Row>
    </Card>
  );
}

export default SalesPolicies;