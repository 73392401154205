import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactPagination from 'react-js-pagination';

class Pagination extends Component {
  render() {
    const {currentPage, pageSize, totalItemsCount, totalPages, onPageChange, isHidden} = this.props;

    if (isHidden || !(totalPages > 1))
      return null;
    
    return (
      <ReactPagination
        activePage={currentPage}
        itemsCountPerPage={pageSize}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={8}
        innerClass='pagination justify-content-center flex-wrap'
        itemClass='page-item'
        linkClass='page-link'
        nextPageText={<FontAwesomeIcon icon='angle-right' />}
        prevPageText={<FontAwesomeIcon icon='angle-left' />}
        lastPageText={<FontAwesomeIcon icon='angle-double-right'/>}
        firstPageText={<FontAwesomeIcon icon='angle-double-left'/>}
        hideFirstLastPages={false}
        
        onChange={onPageChange}
      />
    );
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalItemsCount: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  isHidden: PropTypes.bool
};

export default Pagination;