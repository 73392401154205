import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {FormFeedback, Input as ReactInput} from 'reactstrap';

class Input extends Component {
  constructor(props) {
    super(props);

    this.focusRef = React.createRef();
    
    this.state = {
      value: props.value || '',
      isValid: this.props.isValid || false,
      isDirty: false,
      trySubmit: this.props.trySubmit || false
    };
  }

  componentDidMount() {
    if (this.props.autoFocus)
      this.focusInput();
    
    if (this.props.onRef){
      this.props.onRef(this);
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef){
      this.props.onRef(null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;
    
    if (!this.props.value && this.props.autoFocus)
      this.focusInput();
    
    if (prevProps.value !== this.props.value || prevProps.isValid !== this.props.isValid || prevProps.trySubmit !== this.props.trySubmit) {
      this.setState({
        ...this.state,
        value: this.props.value,
        isDirty: this.props.value !== '' && this.props.value !== undefined,
        isValid: this.props.isValid,
        trySubmit: this.props.trySubmit
      });
    }
  }

  focusInput = () => {
    this.focusRef.current.focus();
  };
  
  handleValueChange = (e) => {
    let newValue = e.target.value;
    let isValid = this.state.isValid;

    if (this.props.onChange) {
      if (this.props.validationMethod)
        isValid = this.props.validationMethod(newValue);  // Container will handle validation
      else if (this.props.validationRegex)
        isValid = new RegExp(this.props.validationRegex).test(newValue); // Input will handle validation

      let obj = {
        value: newValue,
        isValid,
        name: e.target.name
      };
      
      this.props.onChange(obj);
    }

    this.setState({
      ...this.state,
      value: newValue,
      isValid: isValid,
      isDirty: true
    });
  };
  
  render() {
    const { type, name, validationMessage, placeholder, className, isReadOnly } = this.props;

    return (
      <Fragment>
        <ReactInput
          data-test-id={this.props.inputDataTestId}
          type={type}
          name={name}
          value={this.state.value}
          onChange={this.handleValueChange}
          onKeyPress={this.props.onKeyPress}
          invalid={!this.state.isValid && (this.state.isDirty || this.state.trySubmit)}
          placeholder={placeholder}
          className={className}
          innerRef={this.focusRef}
          readOnly={isReadOnly}
        />
        <FormFeedback>{validationMessage}</FormFeedback>
      </Fragment>
    );
  }
}

Input.defaultProps = {
  type: 'text'
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  validationRegex: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  validationMethod: PropTypes.func,
  validationMessage: PropTypes.string,
  placeholder: PropTypes.string,
  trySubmit: PropTypes.bool,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  onRef: PropTypes.func,
  isReadOnly: PropTypes.bool,
  inputDataTestId: PropTypes.string
};

export default Input;