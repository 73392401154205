import {Col, Placeholder, PlaceholderContent, Row, TextInput} from '@met/react-components';
import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import styled from 'styled-components';

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Loader = () => {
  return (
    <Placeholder>
      <Row>
        <Col xs={4}><PlaceholderContent/></Col>
      </Row>
      <Row>
        <Col><PlaceholderContent big/></Col>
      </Row>
      <Row>
        <Col xs={4}><PlaceholderContent/></Col>
      </Row>
      <Row>
        <Col><PlaceholderContent big/></Col>
      </Row>
    </Placeholder>
  )
};

const ShippingInfo = (props) => {
  return (
    <div className="tab-pane fade show active" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
      <h5>General</h5>
      <hr/>
      {
        props.accountLoaded ? (
          <form>
            <FormGroup>
              <label htmlFor='accountName'>Account Name</label>
              <TextInput data-test-id='general-account-name' id='accountName' value={props.accountName} disabled/>
            </FormGroup>
            <FormGroup>
              <label htmlFor='accountNumber'>Account Number</label>
              <TextInput data-tets-id='general-account-number' id='accountNumber' value={props.accountNumber} disabled/>
            </FormGroup>
          </form>
        ) :
        <Loader />
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    accountNumber: state.account.currentAccount.accountDetails.accountNumber,
    accountName: state.account.currentAccount.accountDetails.name,
    accountAddress: state.account.currentAccount.accountDetails.address,
    accountLoaded: !state.account.currentAccount.isLoading && state.account.currentAccount.accountDetails.accountNumber !== '',
  };
};

export default injectIntl(connect(mapStateToProps, null)(ShippingInfo));