export default{  
  identifyUser: (user)=>{
    try {
      if (window.dtrum) {
        window.dtrum.identifyUser(user);
      }
    }catch (e) {
      console.error("error notifying dynatrace of user: " + e);
    }
  }  
};