import React, {Component} from 'react';
import {FormattedDate, injectIntl} from 'react-intl';
import {ListGroupItem, ListGroupItemHeading, ListGroupItemText} from 'reactstrap';

import {AnnouncementModal} from '../../components';

class AnnouncementItem extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      isPreviewing: false
    }
  }
  
  toggleModal = () => {
    this.setState({
      isPreviewing: !this.state.isPreviewing
    });
  };
  
  render() {
    const {announcement} = this.props;
    
    return (
      <ListGroupItem className='announcement-item' action onClick={this.toggleModal}>
        <div className='has-justified-content'>
          <ListGroupItemHeading>{announcement.heading}</ListGroupItemHeading>
          <small><FormattedDate value={announcement.startDate} /></small>
        </div>
        <ListGroupItemText>
          {announcement.summary}
        </ListGroupItemText>
        <span className='is-mock-link'>Read More...</span>
        <AnnouncementModal announcement={announcement} isOpen={this.state.isPreviewing} toggle={this.toggleModal}/>
      </ListGroupItem>
    );
  }
}

export default injectIntl(AnnouncementItem);