import createFetchAction from './createFetchAction';

export const LOADING_COUNTRIES = 'LOADING_COUNTRIES';
export const LOADING_COUNTRIES_SUCCESS = 'LOADING_COUNTRIES_SUCCESS';
export const LOADING_COUNTRIES_ERROR = 'LOADING_COUNTRIES_ERROR';

export const initialState = {
  countries: [],
  getCountries: {
    isLoading: false,
    isError: false
  }
};

export const actionCreators = {
  getCountries: () => {
    return createFetchAction({
      url: `/api/v1/countries`,
      startAction: LOADING_COUNTRIES
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  
  switch (action.type) {
    case LOADING_COUNTRIES:
      return {
        ...state,
        countries: [],
        getCountries: {
          isLoading: true,
          isError: false
        }
      };
    case LOADING_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload.data,
        getCountries: {
          isLoading: false,
          isError: false
        }
      };
    case LOADING_COUNTRIES_ERROR:
      return {
        ...state,
        getCountries: {
          isLoading: false,
          isError: true
        }
      };
    default:
      return state;
  }
};