export const OrderStatus = {
  Pending: 1,
  Submitted: 2,
  QuoteSentToQueueForRefresh: 3,
  OrderSentToQuoteForSubmission: 4,
  QuoteProcessing: 5,
  OrderProcessing: 6,
  QuoteFailedToProcess: 7,
  OrderFailedToProcess: 8,
  AccountNotFoundQuoteFailedToProcess: 9
};

export const SquareTransactionStatus = {
  Open: 0,
  AwaitingInviteToPay: 1,
  Authorized: 2,
  Captured: 3,
  AwaitingCheckout: 4,
  Cancelled: 5
};