import {Card, CardBody, CardHeader, Col, Row} from '@met/react-components';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {Input} from '../../../components';

const PurchaseOrder = (props) => {
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState({value: '', isValid: false});
  const purchaseOrderNumberValidationRegex = /(.|\s)*\S(.|\s)*/; // Allow anything other than blank

  const handlePurchaseOrderNumberChange = (obj) => {
    setPurchaseOrderNumber({
      value: obj.value,
      isValid: obj.isValid
    });
    
    if (props.onPurchaseOrderNumberChange)
      props.onPurchaseOrderNumberChange(obj);
  };
  
  const validatePurchaseOrderNumber = (value) => {
    return value ? new RegExp(purchaseOrderNumberValidationRegex).test(value) : false;
  };
  
  return (
    <Card>
      <CardHeader>
       <FormattedMessage id='general.purchaseOrderNumber'/>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={4}>
            {props.employeeOrdersDefaultPONumber ?
              <Input
                inputDataTestId='checkout-purchase-order-number'
                type='text'
                value="EMPLOYEE ORDER"
                isValid={true}
                trySubmit={true}
                isReadOnly={true}
              />
            :
              <Input
                inputDataTestId='checkout-purchase-order-number'
                type='text'
                value={purchaseOrderNumber.value}
                isValid={purchaseOrderNumber.isValid}
                onChange={handlePurchaseOrderNumberChange}
                validationMethod={validatePurchaseOrderNumber}
                validationMessage='Purchase Order # is required'
                trySubmit={props.trySubmit}
              />
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

PurchaseOrder.propTypes = {
  onPurchaseOrderNumberChange: PropTypes.func,
  trySubmit: PropTypes.bool
};

export default PurchaseOrder;