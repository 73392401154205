import createFetchAction from './createFetchAction';

export const LOADING_ORDER_HISTORY = 'LOADING_ORDER_HISTORY';
export const LOADING_ORDER_HISTORY_SUCCESS = 'LOADING_ORDER_HISTORY_SUCCESS';
export const LOADING_ORDER_HISTORY_ERROR = 'LOADING_ORDER_HISTORY_ERROR';

export const LOADING_EXCEL_ORDER_HISTORY = 'LOADING_EXCEL_ORDER_HISTORY';
export const LOADING_EXCEL_ORDER_HISTORY_SUCCESS = 'LOADING_EXCEL_ORDER_HISTORY_SUCCESS';
export const LOADING_EXCEL_ORDER_HISTORY_ERROR = 'LOADING_EXCEL_ORDER_HISTORY_ERROR';

export const LOADING_ORDER = 'LOADING_ORDER';
export const LOADING_ORDER_SUCCESS = 'LOADING_ORDER_SUCCESS';
export const LOADING_ORDER_ERROR = 'LOADING_ORDER_ERROR';

export const LOADING_TRACKING_DETAILS = 'LOADING_TRACKING_DETAILS';
export const LOADING_TRACKING_DETAILS_SUCCESS = 'LOADING_TRACKING_DETAILS_SUCCESS';
export const LOADING_TRACKING_DETAILS_ERROR = 'LOADING_TRACKING_DETAILS_ERROR';


export const GET_PACKINGSLIP = 'GET_PACKINGSLIP';
export const GET_PACKINGSLIP_SUCCESS = 'GET_PACKINGSLIP_SUCCESS';
export const GET_PACKINGSLIP_ERROR = 'GET_PACKINGSLIP_ERROR';

export const GET_HASHEDORDERID = 'GET_HASHEDORDERID';
export const GET_HASHEDORDERID_SUCCESS = 'GET_HASHEDORDERID_SUCCESS';
export const GET_HASHEDORDERID_ERROR = 'GET_HASHEDORDERID_ERROR';

export const TOGGLE_ORDER_PREVIEW = 'TOGGLE_ORDER_PREVIEW';

export const initialState = {
  orderHistory: {
    orders: [],
    isLoading: false,
    isError: false,
    expandedOrders: []
  },
  orderDetails: {
    order: null,
    isLoading: false,
    isError: false
  },
  trackingDetails: {
    shipment: null,
    isLoading: false,
    isError: false
  },
  packingSlip: {
    isLoading: false,
    isError: false
  },
  excelOrderHistory:{
    isLoading: false,
    isError: false
  },
  hashedOrderId: {
    orderId: null,
    isLoading: false,
    isError: false,
  }
};

export const actionCreators = {
  getOrderHistory: (query) => {
    return createFetchAction({
      url: `/api/v1/orders${query}`,
      startAction: LOADING_ORDER_HISTORY,
    });
  },
  getOrder: (orderId) => {
    return createFetchAction({
      url: `/api/v1/orders/${orderId}`,
      startAction: LOADING_ORDER
    });
  },
  getTrackingDetails: (orderShipmentId) => {
    return createFetchAction({
      url: `/api/v1/ordershipments/_actions/trackshipment?o=${orderShipmentId}`,
      startAction: LOADING_TRACKING_DETAILS
    })
  },
  getPackingSlip: (packingslip, date, ordernumber, mkeProductId) => {
    return createFetchAction({
      url: `/accountapi/packingslip/${packingslip}/${date.getUTCFullYear()}-${date.getUTCMonth()+1}-${date.getUTCDate()}/${ordernumber}/${mkeProductId}`,
      startAction: GET_PACKINGSLIP,
      isBlob: true,
      ignore404: true
    }, null, true);
  },
  getExcelOrderHistory: (query) => {
    return createFetchAction({
      url: `/api/v1/orders/excel${query}`,
      startAction: LOADING_EXCEL_ORDER_HISTORY,
      isBlob: true
    });
  },
  toggleOrderPreview: (orderId) => {
    return {
      type: TOGGLE_ORDER_PREVIEW,
      payload: orderId
    }
  },
  getOrderId: (orderNumber) => {
    return createFetchAction({
      url: `/api/v1/orders/hashId/${orderNumber}`,
      ignore404: true,
      startAction: GET_HASHEDORDERID
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case LOADING_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          isLoading: true,
          isError: false
        }
      };

    case LOADING_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          orders: action.payload.data,
          totalPages: action.payload.totalPages || 0,
          totalCount: action.payload.totalCount,
          count: action.payload.count,
          isLoading: false,
          isError: false
        }
      };

    case LOADING_ORDER_HISTORY_ERROR:
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          isLoading: false,
          isError: true
        }
      };
      
    case LOADING_EXCEL_ORDER_HISTORY:
      return {
        ...state,
        excelOrderHistory: {
          ...state.excelOrderHistory,
          isLoading: true,
          isError: false
        }
      };

    case LOADING_EXCEL_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        excelOrderHistory: {
          ...state.excelOrderHistory,
          isLoading: false,
          isError: false
        }
      };

    case LOADING_EXCEL_ORDER_HISTORY_ERROR:
      return {
        ...state,
        excelOrderHistory: {
          ...state.excelOrderHistory,
          isLoading: false,
          isError: true
        }
      };

    case LOADING_ORDER:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          order: null,
          isLoading: true,
          isError: false
        }
      };
      
    case LOADING_ORDER_SUCCESS:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          order: action.payload.data || {},
          isLoading: false,
          isError: false
        }
      };
      
    case LOADING_ORDER_ERROR:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: false,
          isError: true
        }
      };
      
    case LOADING_TRACKING_DETAILS:
      return {
        ...state,
        trackingDetails: {
          ...state.trackingDetails,
          shipment: null,
          isLoading: true,
          isError: false
        }
      };
      
    case LOADING_TRACKING_DETAILS_SUCCESS:
      return {
        ...state,
        trackingDetails: {
          ...state.trackingDetails,
          shipment: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
      
    case LOADING_TRACKING_DETAILS_ERROR:
        return {
          ...state,
          trackingDetails: {
            ...state.trackingDetails,
            isLoading: false,
            isError: true
          }
      };

    case GET_PACKINGSLIP:
      return {
        ...state,
        packingSlip: {
          ...state.packingSlip,
          isLoading: true,
          isError: false
        }
      };

    case GET_PACKINGSLIP_SUCCESS:
      return {
        ...state,
        packingSlip: {
          ...state.packingSlip,
          isLoading: false,
          isError: false
        }
      };

    case GET_PACKINGSLIP_ERROR:
      return {
        ...state,
        packingSlip: {
          ...state.packingSlip,
          isLoading: false,
          isError: true
        }
      };

    case GET_HASHEDORDERID:
      return {
         ...state,
          hashedOrderId: {
              ...state.hashedOrderId,
              isLoading:true,
              isError:false
          }
      };
      
    case GET_HASHEDORDERID_SUCCESS:
      return {
         ...state,
          hashedOrderId: {
              ...state.hashedOrderId,
              orderId: action.payload.data,
              isLoading:false,
              isError:false
          }
      };
    case GET_HASHEDORDERID_ERROR:
      return {
         ...state,
          hashedOrderId: {
              ...state.hashedOrderId,
              isLoading:false,
              isError:true
          }
      };
      
    case TOGGLE_ORDER_PREVIEW:{
      let expandedOrders = JSON.parse(JSON.stringify(state.orderHistory.expandedOrders));
      let i = expandedOrders.indexOf(action.payload);
      
      if (i === -1)
        expandedOrders.push(action.payload);
      else
        expandedOrders.splice(i, 1);

      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          expandedOrders
        }
      };
    }
    default:
      return state;
  }
};