import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Col, Container, FormGroup, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import {Button, Icon, Input} from '../../components';

const EditWishlistModal = (props) => {
  const nameValidationRegex = /\S+/; // Require at least one character
  
  const [name, setName] = useState({ value: props.wishlist ? props.wishlist.name : '', isValid: false });
  const [trySubmit, setTrySubmit] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  
  const handleNameChange = (obj) => {
    setName({
      value: obj.value,
      isValid: obj.isValid
    });
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSaveChangesClick();
    }
  };
  
  const validateName = (value) => {
    return value ? new RegExp(nameValidationRegex).test(value) : false;
  };
  
  const handleSaveChangesClick = () => {
    setTrySubmit(true);
    
    let nameIsValid = validateName(name.value);
    
    setName({
      value: name.value,
      isValid: nameIsValid
    });

    if (nameIsValid) {
      props.handleSaveChangesClick(name.value);
    }
  };
  
  let deleteButtonMarkup = confirmDelete ? (
    <Button data-test-id='edit-wishlist-areyousure' onClick={props.handleDeleteClick} block color='secondary'>
      <FormattedMessage id='general.areYouSure'/>
    </Button>
  ) : (
    <Button data-test-id='edit-wishlist-delete' onClick={() => setConfirmDelete(true)} block color='secondary'>
      <FormattedMessage id='general.delete'/>
    </Button>
  );
  
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered id='edit-wishlist-modal'>
      <ModalHeader toggle={props.toggle}>
        <div className='d-flex flex-row align-items-center'>
          <Icon type='pencil-alt' color='dark-gray' size='sm'/>
          <div className='ml-2'><FormattedMessage id='wishlists.editWishlist'/></div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row>
            <Col>
              <FormGroup data-test-id='edit-wishlist-name-input'>
                <Label for='wishlist-name'>Name</Label>
                <Input
                  type='text'
                  value={name.value}
                  isValid={name.isValid}
                  onChange={handleNameChange}
                  onKeyPress={handleKeyPress}
                  validationMethod={validateName}
                  validationMessage='Please enter a valid name'
                  trySubmit={trySubmit}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className='justify-content-end'>
            <Col xs='auto'>
              {deleteButtonMarkup}
            </Col>
            <Col xs='auto'>
              <Button data-test-id='edit-wishlist-save-changes' onClick={handleSaveChangesClick} color='primary' isLoading={props.isLoading}>Save Changes</Button>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default EditWishlistModal;