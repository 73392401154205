import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import notFoundImage from '../../images/404.jpg';

import './NotFound.scss';


class NotFound extends Component {
  render() {
    return (
      <div className='not-found'>
        <img src={notFoundImage} alt='notfound' />
      </div>
    );
  }
}

export default injectIntl(NotFound);