const faq = {
  accounts: {
    howToSeeAccounts: {
      question: '¿Cómo puedo ver todas mis cuentas de Milwaukee Connect?',
      answer: 'Para obtener acceso a todas las cuentas, debe estar configurado en la cuenta principal y designado para ser un Administrador sobre la cuenta. Por favor, contacte CXHelp@milwaukeetool.com para obtener más ayuda.'
    },
    howToLocateAccount: {
      question: '¿Cómo puedo ubicar mi número de cuenta de Milwaukee Connect?',
      answer: 'Para saber cuál es su número de cuenta, comuníquese con el equipo de Experiencia del Cliente al 800-729-3878.'
    },
    multipleContactNumbers: {
      question: '¿Puedo proporcionar múltiples números de contacto?',
      answer: 'Puedes tener dos números de teléfono; se puede agregar un segundo número en la configuración de su cuenta. También puedes seguir estas instrucciones:' +
        '[Haz clic en el ícono de la persona en la esquina superior derecha.||' +
        'Se le mostrará un menú, haga clic en "Cuenta".||' +
        'Será llevado a su perfil de cuenta.||' +
        'Busque la primera sección llamada "Información personal", debe estar abierta y mostrar su información de contacto. Si no está abierto, haga clic en la barra que dice "Información personal" para mostrar la información de contacto||' +
        'Habrá dos campos para ingresar un número de teléfono, uno etiquetado como "Número de teléfono" (este campo ya puede estar completado) y a la derecha de este número hay un campo llamado "Teléfono celular".||' +
        'Puede agregar el segundo número en el campo "Teléfono celular"; No tiene que ser un número de teléfono celular.||' +
        'Una vez terminado, desplácese hasta la parte inferior de la página y haga clic en el botón "Guardar cambios".]'
    },
    howToCreateAccount: {
      question: '¿Cómo creo una cuenta?',
      answer: 'Para crear una cuenta, siga estas instrucciones:' +
        '[En la esquina superior derecha de la página, haga clic en "Iniciar sesión"||' +
        'Se le mostrará una ventana emergente con dos pestañas.||' +
        'Seleccione la pestaña llamada "Registrarse".||' +
        'Rellene el formulario, todos los campos son obligatorios.||' +
        'Se enviará un correo electrónico al equipo de CX o de Cuentas.||' +
        'Activarán su cuenta.||' +
        'Recibirá un correo electrónico de activación.||' +
        'Una vez que se haya recibido un correo electrónico de activación, vaya a Conectar y haga clic en el enlace "Iniciar sesión" en la esquina superior derecha.]'
    },
    howToResetPassword: {
      question: '¿Cómo puedo restablecer mi contraseña?',
      answer: 'Si ha olvidado su contraseña, siga estas instrucciones:' +
        '[En la esquina superior derecha de la página, haga clic en "Iniciar sesión".||' +
        'Se le mostrará una ventana emergente con dos pestañas.||' +
        'En la pestaña que se muestra ("Iniciar sesión"), hay un enlace llamado "¿No recuerda su contraseña?".||' +
        'Haga clic en "¿No recuerda su contraseña?||' +
        'Ingrese su dirección de correo electrónico.||' +
        'Haz clic en el botón "Enviar correo electrónico".||' +
        'Volverá a la pestaña "Iniciar sesión".||' +
        'Vaya a su cliente de correo electrónico y busque un correo electrónico de accounts@milwaukeetool.com]'
    }
  },
  orderingOrders: {
    howToPriceAvailability: {
      question: '¿Cómo puedo encontrar precio y disponibilidad?',
      answer: 'Para obtener el precio y la disponibilidad de un producto:' +
        '[Ingrese un número o nombre de producto en el campo de búsqueda "Buscar un producto"||' +
        'Se lo dirigirá a una página de resultados de búsqueda que le mostrará el producto que buscó o una gama de productos si buscó por un nombre de producto.||' +
        'Encuentre el producto sobre el que desea un precio, el precio y la disponibilidad se mostrarán con la información del producto.||' +
        'Los precios y la disponibilidad también se presentan en las páginas de detalles del producto. Si hace clic en el nombre o número de producto en los resultados de búsqueda, eso lo llevará a la página de detalles del producto.]'
    },
    howToPricingSchedule: {
      question: '¿Cómo puedo ubicar mi programa de precios en Milwaukee Connect 2.0?',
      answer: 'Desafortunadamente, esta función no está disponible en este momento. Comuníquese con Customer Experience al 800-729-3878 para obtener su calendario de precios.'
    },
    applyPCE: {
      question: '¿Puedo aplicar PCE a mi pedido de Milwaukee Connect 2.0?',
      answer: 'Desafortunadamente, esta función no está disponible en este momento. Envíe su pedido de compra por correo electrónico a METOrders@milwaukeetool.com. Los PCE a los que se hace referencia en su orden de compra se aplicarán durante la entrada de la orden, según corresponda.'
    },
    employeePayment: {
      question: '¿Puedo pagar mi pedido en Milwaukee Connect 2.0? (Empleado)',
      answer: 'No en este momento. Comuníquese con Customer Experience al 800-729-3878 dentro de los 5 días hábiles después de realizar su pedido para pagar su pedido.'
    },
    locateTrackingNumber: {
      question: '¿Cómo puedo localizar el número de seguimiento de mi pedido?',
      answer: 'El número de seguimiento se asignará una vez que se haya enviado el pedido. Recibirá un correo electrónico que su pedido ha sido enviado. Una vez que reciba este correo electrónico, puede hacer clic en el enlace del correo electrónico para ver el seguimiento. También puede seguir estas instrucciones (recuerde, no habrá seguimiento hasta que se envíe su pedido):' +
        '[Inicie sesión en Connect.||' +
        'Haga clic en "Pedidos" en la navegación principal; esto lo llevará al Panel de pedidos de ventas.||' +
        'Encuentre el pedido sobre el que desea conocer la información de seguimiento.||' +
        'Haga clic en el "# de pedido" (comenzará con "AX").||' +
        'En la sección "Artículos en este pedido", tendrá un botón para verificar el seguimiento de los artículos enviados.]'
    },
    howToStatusUpdates: {
      question: '¿Cómo puedo ver y recibir actualizaciones de estado para mi pedido?',
      answer: 'Recibirá un correo electrónico para la confirmación del pedido, el pedido enviado y el pedido entregado. Si no ha recibido los correos electrónicos, verifique su carpeta de correo no deseado. Esta información también está disponible en el Panel de órdenes de venta:' +
        '[Inicie sesión en Connect.||' +
        'Haga clic en "Pedidos" en la navegación principal; esto lo llevará al Panel de pedidos de ventas.||' +
        'Encuentra el pedido del que deseas saber el estado.||' +
        'Haga clic en el "# de pedido" (comenzará con "AX").||' +
        'En la sección "Artículos en este pedido", le indicará el estado de los productos en el pedido.]'
    },
    canIPickup: {
      question: '¿Puedo ingresar el pedido y recogerlo en mi sucursal local?',
      answer: 'Lamentablemente, no puede recoger pedidos en su sucursal local. Todos los pedidos se enviarán desde nuestro centro de distribución principal en Olive Branch, MS.'
    },
    canSalesRepsOrder: {
      question: '¿Puede mi representante de ventas ingresar pedidos en mi nombre?',
      answer: 'Su representante de ventas no puede enviar pedidos en su nombre. Sin embargo, su representante de ventas puede proporcionar un presupuesto y verificar el estado de los pedidos por usted.'
    },
    receivedWrongProduct: {
      question: '¿Qué hago si recibí un producto por error? ¿Qué pasa si recibí el producto incorrecto?',
      answer: 'Comuníquese con el equipo de Servicio al Cliente de Milwaukee en CXHelp@milwaukeetool.com con una imagen del producto recibido, número de pedido, PO #. Se iniciará una verificación de contenedor y se comunicará un número de pedido de reemplazo una vez que se haya confirmado que el inventario es exacto.'
    },
    whenWillOrderShip: {
      question: '¿Qué tan pronto se enviará mi pedido?',
      answer: 'Los pedidos se procesan en nuestro centro de distribución por orden de llegada. Consulte su Panel de órdenes de venta para obtener más actualizaciones de entrega. Su pedido llegará dentro de estas estimaciones de entrega en función de su ubicación y la velocidad de envío seleccionada. También recibirá un correo electrónico cuando se envíe su pedido. Para acceder al Panel de órdenes de venta:' +
        '[Inicie sesión en Connect.||' +
        'Haga clic en "Pedidos en la navegación principal.||' +
        'Encuentra el pedido del que deseas saber el estado.||' +
        'Haga clic en el "# de pedido" (comenzará con "AX").||' +
        'Se lo dirigirá a la página Detalles del pedido.||' +
        'Mostrará el estado de los productos en ese orden.]'
    }
  },
  invoicingStatements: {
    howToLocateInvoice: {
      question: '¿Cómo puedo ver (descargar) una factura?',
      answer: 'Para descargar su factura:' +
        '[Inicie sesión en Connect||' +
        'Desde el menú principal, haga clic en "Facturas"||' +
        'Será llevado a la página de facturas.||' +
        'Para encontrar la factura que está buscando, puede usar los filtros de fecha y/o la función de búsqueda para encontrar facturas específicas.||' +
        'Una vez que haya encontrado la factura, haga clic en el icono de descarga y su factura se descargará en su dispositivo.]'
    },
    howToCustomerStatement: {
      question: '¿Cómo puedo descargar una "Declaración del cliente"?',
      answer: 'Para descargar su declaración de cliente:' +
        '[Inicie sesión en Connect.||' +
        'Desde el menú principal, haga clic en "Facturas".||' +
        'Será llevado a la página de facturas.||' +
        'Habrá un botón llamado "Declaraciones de clientes".||' +
        'Haga clic en él y el archivo se descargará en su dispositivo.]'
    },
    howToCreditMemo: {
      question: '¿Cómo puedo recuperar mi nota de crédito?',
      answer: '¿Cómo puedo recuperar mi nota de crédito?' +
        '[Inicie sesión en Connect.||' +
        'Desde el menú principal, haga clic en "Facturas".||' +
        'Será llevado a la página de facturas.||' +
        'Para encontrar la factura que está buscando, puede usar los filtros de fecha y/o la función de búsqueda para encontrar facturas específicas.||' +
        'Encuentre el enlace a "Nota de crédito" en la factura que está buscando.||' +
        'Haga clic en él y el archivo se descargará en su dispositivo.]'
    }
  },
  warranty: {
    noCatalogNumber: {
      question: '¿Qué hago si no se puede ingresar el número de catálogo en un reclamo de garantía?',
      answer: '[[Puede estar ingresando un número restringido de servicio.||' +
        'Los resultados de la búsqueda le permitirán saber si un número de catálogo está cubierto por la garantía. Si un número de catálogo no está cubierto, aún puede repararlo a través de eService.||' +
        'Verifique si está ingresando el número de artículo correcto.]]'
    },
    noPartNumber: {
      question: '¿Qué hago si no se puede ingresar el número de pieza en un reclamo de garantía?',
      answer: '[[Asegúrese de ingresar un número de pieza de servicio; No se pueden ingresar accesorios.||' +
        'Si la parte tiene un número de parte de servicio válido y aún no se acepta, llame a su gerente de garantía.]]'
    },
    rejectedClaim: {
      question: 'Si se rechaza un reclamo, ¿qué debo hacer?',
      answer: 'Cuando se rechaza un reclamo, su gerente de garantía necesita más información antes de que su reclamo pueda ser aprobado. Siga estos pasos para un reclamo rechazado:' +
        '[Inicie sesión en Connect.||' +
        'Haga clic en "Servicio" en la navegación principal.||' +
        'You will be shown a menu - select "Warranty Claims".||' +
        'Busque el reclamo rechazado.||' +
        'Haga clic en el botón "Ver" para el reclamo rechazado.||' +
        'Desplácese hasta la parte inferior de la página y busque la sección de comentarios.||' +
        'Lea los comentarios del gerente de garantía.||' +
        'Answer any questions in the text box and submit them.Responda cualquier pregunta en el cuadro de texto y envíela.||' +
        'El reclamo será revisado nuevamente.]'
    },
    pendingClaimStatus: {
      question: '¿Por qué mi reclamo de garantía de batería todavía está en estado "Pendiente"?',
      answer: 'Llame o envíe un correo electrónico a su gerente de servicio. Deberá proporcionarles el número EW # y el número de seguimiento que muestra que la batería se entregó a Greenwood, MS. Su gerente de garantía responderá con una respuesta al problema.'
    },
    defectiveParts: {
      question: '¿Qué hago si las piezas están defectuosas fuera del embalaje?',
      answer: 'Deberá completar un "Nuevo reclamo de piezas defectuosas". Por favor siga estos pasos:' +
        '[Iniciar sesión en Connect.||' +
        'Haga clic en "Servicio" en la navegación principal.||' +
        'Se le mostrará un menú: seleccione "Reclamaciones de garantía".||' +
        'Seleccione la pestaña "Nuevo reclamo de parte".||' +
        'Rellene el formulario y envíelo.]'
    }
  },
  manualsPartLists: {
    howToLatestDPL: {
      question: '¿Cómo puedo encontrar la última DPL (Lista de precios de distribuidor)?',
      answer: 'The Distributor Price List is located on the Pricing and Publications page. Please follow these steps:' +
        '[Inicie sesión en Connect.||' +
        'Haga clic en "Servicio" en la navegación principal.||' +
        'Se le mostrará un menú: seleccione "Precios/Publicaciones".||' +
        'Se lo dirigirá a la página de precios y publicaciones.||' +
        'En "Piezas y precios", busque "Herramientas y accesorios" y haga clic en el botón debajo de eso.||' +
        'The file will be downloaded to your device.El archivo se descargará a su dispositivo.]'
    },
    howToPartsListManuals: {
      question: '¿Cómo puedo obtener la lista de piezas o un manual para mi herramienta?',
      answer: 'Las listas de piezas y los manuales se encuentran en la página de Precios y Publicaciones. Por favor siga estos pasos:' +
        '[Inicie sesión en Connect.||' +
        'Haga clic en "Servicio" en la navegación principal.||' +
        'Se le mostrará un menú: seleccione "Precios/Publicaciones".||' +
        'Se lo dirigirá a la página de precios y publicaciones.||' +
        'Localice la sección "Publicaciones técnicas".||' +
        'Haga clic en el botón "Buscar publicaciones técnicas" para acceder a la página de Manuales y publicaciones en milwaukeetool.com.]'
    }
  }
};

export default faq;