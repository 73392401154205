import createFetchAction from './createFetchAction';

export const LOADING_STATES = 'LOADING_STATES';
export const LOADING_STATES_SUCCESS = 'LOADING_STATES_SUCCESS';
export const LOADING_STATES_ERROR = 'LOADING_STATES_ERROR';

export const initialState = {
  states: [],
  getStates: {
    isLoading: false,
    isError: false
  }
};

export const actionCreators = {
  getStates: () => {
    return createFetchAction({
      url: `/api/v1/states`,
      startAction: LOADING_STATES
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  
  switch (action.type) {
    case LOADING_STATES:
      return {
        ...state,
        states: [],
        getStates: {
          isLoading: true,
          isError: false
        }
      };
    case LOADING_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload.data,
        getStates: {
          isLoading: false,
          isError: false
        }
      };
    case LOADING_STATES_ERROR:
      return {
        ...state,
        getStates: {
          isLoading: false,
          isError: true
        }
      };
    default:
      return state;
  }
};