import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import className from 'classnames';
import FormFeedback from 'reactstrap/es/FormFeedback';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isDirty: false,
      trySubmit: this.props.trySubmit || false
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;
    
    if (prevProps.trySubmit !== this.props.trySubmit) {
      this.setState({
        ...this.state,
        trySubmit: this.props.trySubmit
      });
    }
  }
  
  handleChange = (option) => {
    this.setState({
      ...this.state,
      isDirty: true
    }, () => {
      this.props.onChange(option);
    });
  };
  
  render() {
    const {isLoading, options, defaultValue, isValid} = this.props;
    
    const containerClasses = className(
      'react-select-container form-control',
      isLoading && 'is-loading',
      (!isValid && (this.state.isDirty || this.state.trySubmit)) && 'is-invalid'
    );
    
    return (
      <Fragment>
        <Select
          className={containerClasses}
          classNamePrefix='react-select'
          onChange={this.handleChange}
          options={options}
          defaultValue={defaultValue}
        />
        <FormFeedback>{this.props.validationMessage}</FormFeedback>
      </Fragment>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  isValid: PropTypes.bool,
  trySubmit: PropTypes.bool,
  validationMessage: PropTypes.string
};

export default Dropdown;