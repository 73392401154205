import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import {Button} from '../../components';

class AccountSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        accountNumber: '',
        accountCountry: ''
      },
      accounts: [],
      accountOptions: [],
      validation: this.getDefaultValidation()
    };
  }
  
  getDefaultValidation = () => {
    return {
      accountSelectionIsValid: false
    };
  };
  
  componentDidMount() {
    this.refreshState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;

    if (prevProps.accountNumber !== this.props.accountNumber || prevProps.accountChildren !== this.props.accountChildren || prevProps.currentAccount !== this.props.currentAccount)
      this.refreshState();
  }
  
  refreshState = () => {
    let accountNumber = this.props.data.accountNumber || this.props.accountNumber;
    let accounts = this.getAccounts();
    let accountOptions = accounts.map(a => this.mapAccountToOption(a));
    let currentAccountOption = Enumerable.from(accountOptions).firstOrDefault(x => x.value === accountNumber);
    let currentAccount = Enumerable.from(accounts).firstOrDefault(x => x.accountNumber === accountNumber);
    
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        accountNumber: accountNumber,
        accountCountry: currentAccountOption ? currentAccount.address.country : ''
      },
      validation: {
        ...this.state.validation,
        accountSelectionIsValid: currentAccountOption && this.validateAccountNumber(accountNumber)
      },
      accountOptions: accountOptions,
      accounts: accounts
    });
  };
  
  handleAscChange = (accountNumber) => {
    let currentAccount = Enumerable.from(this.state.accounts).firstOrDefault(x => x.accountNumber === accountNumber);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        accountNumber: accountNumber,
        accountCountry: currentAccount.address.country
      },
      validation: {
        ...this.state.validation,
        accountSelectionIsValid: this.validateAccountNumber(accountNumber)
      }
    });
  };
  
  validateAccountNumber = (accountNumber) => {
    return accountNumber && accountNumber !== '' && accountNumber > 0;
  };

  getAccounts = () => {
    let accounts = [];
    let currentAccount = this.props.currentAccount.accountDetails;
    let ascAccountChildren = Enumerable.from(this.props.accountChildren).where(x => x.hasAsc).toArray();
    
    for (let i = 0; i < ascAccountChildren.length; i++) {
      accounts.push(ascAccountChildren[i]);
    }
    
    if (accounts.length === 0) {
      if (currentAccount.hasAsc && currentAccount.address) {
        accounts.push(currentAccount);
      }
    }

    return accounts;
  };

  mapAccountToOption = (account) => {
    if (account.address) {
      return {
        value: account.accountNumber,
        label: `${account.accountNumber} - ${account.name} - ${account.address.addressLine1} ${account.address.city}, ${account.address.state} ${account.address.postalCode}`
      };
    }
  };
  
  next = () => {
    if (this.validateAccountNumber(this.state.data.accountNumber))
      this.props.next(this.state.data);
  };
  
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h5 className='my-3'>Account Selection</h5>
                <Container fluid>
                  <Row>
                    <Col data-test-id='account-selection-dropdown' md='6'>
                      <Select
                        className='react-select-container'
                        classNamePrefix='react-select'
                        value={this.state.accountOptions.filter(option => option.value === this.state.data.accountNumber)}
                        options={this.state.accountOptions}
                        onChange={option => this.handleAscChange(option.value)}
                        isLoading={this.props.currentAccount.isLoading}
                      />
                    </Col>
                  </Row>
                  <Row className='justify-content-end mt-4'>
                    <Col xs='auto'>
                      <Button data-test-id='account-selection-next' block color='primary' onClick={this.next} isDisabled={!this.state.validation.accountSelectionIsValid}>Next</Button>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>        
      </Container>
    );
  }
}

AccountSelection.propTypes = {
  next: PropTypes.func,
  data: PropTypes.object,
  accountNumber: PropTypes.string,
  currentAccount: PropTypes.object,
  accountChildren: PropTypes.array
};

export default AccountSelection;