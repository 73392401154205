import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@met/react-components';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { actions as toastrActions, toastr } from 'react-redux-toastr';
import { Card, Col, Container, Row, Table } from 'reactstrap';
import { bindActionCreators } from 'redux';
import dayjs from "dayjs";

import { downloadBlob } from '../../helpers/downloadblob';
import { downloadUrl } from '../../helpers/downloadblob';
import { mediaActions, publicationActions } from '../../store';
import {
  SearchInput
} from '../../components';
import PublicationRow from './PublicationRow';
import user from '../../helpers/user';
import SalesPolicies from './salesPolicies';
import gtm from "../../helpers/googleTagManager";

class Publications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      canPlaceOrders: user.canPlaceOrders(),
    }
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  handleSearchInputChange = (e) => {
    this.setState({
      ...this.state,
      query: e.target.value
    })
  }

  clearSearch = () => {
    this.setState({
      ...this.state,
      query: ''
    })
  }

  search = () => {
    if (this.state.query) {
      gtm.event('Pricing and Publications', 'MXF and One-Key Doc Search', this.state.query);
      this.props.publicationActions.getPublications(this.state.query)
        .then(response => {
          if (!response.data.length) {
            toastr.warning(this.props.intl.formatMessage({ id: 'general.noSearchResults' }));
          }
        });
    }
  }

  trackFileDownload = (filename) => {
    gtm.event('Pricing and Publications', 'Download', filename);
  }

  handlePriceFileDownload = (filename) => {
    this.showDownloadToast(filename);
    this.trackFileDownload(filename);
    this.props.mediaActions.downloadPriceFile(filename)
      .then(blob => {
        downloadBlob(filename, blob);
        toastr.remove(filename);
      });
  };

  handleDPLFileDownload = (filename) => {
    //  The blob renames the file based on current year
    this.trackFileDownload(filename);
    this.props.mediaActions.downloadDplUsaFile()
      .then(blob => {
        downloadBlob(filename, blob);
      });
  };

  handleServiceFileDownload = (filename) => {
    this.showDownloadToast(filename);
    this.trackFileDownload(filename);
    this.props.mediaActions.downloadServiceFile(filename)
      .then(blob => {
        downloadBlob(filename, blob);
        toastr.remove(filename);
      });
  };

  handleSalesPoliciesFileDownload = (filename) => {
    this.showDownloadToast(filename);
    this.trackFileDownload(filename);
    this.props.mediaActions.downloadSalesPoliciesFile(filename)
      .then(blob => {
        downloadBlob(filename, blob);
        toastr.remove(filename);
      });
  };

  showDownloadToast = (filename) => {
    this.props.toastrActions.add({
      id: filename,
      type: 'info',
      title: this.props.intl.formatMessage({ id: 'publications.downloadingFile' }, { filename: filename }),
      position: 'bottom-right',
      message: '',
      options: {
        className: 'toastr-download',
        removeOnHover: false,
        showCloseButton: false,
        closeOnToastrClick: false,
        timeOut: 0,
        icon: <FontAwesomeIcon icon='spinner' size='2x' spin />
      }
    });
  };

  getDplUsaFileName = () => {
    var year = dayjs(new Date()).format('YY');
    return 'DPLUSA' + year + '.xlsx';
  };

  getPolicyFileName = (file) => {
    return file + '.pdf';
  };

  getDplGeneratedDate = () => {
    return dayjs().format('MM/DD/YYYY');
  };

  handlePublicationFileDownload = (filename) => {
    this.showDownloadToast(filename);
    this.trackFileDownload(filename);
    this.props.publicationActions.getPublicationFile(filename)
      .then(url => {
        downloadUrl(filename, url.data);
        toastr.remove(filename);
      });
  }

  render() {
    const { publications, configuration, topLevelAccountNumber, accountHasOneKey } = this.props;
    let showSupportDocumentSection = accountHasOneKey || user.isSuperAdmin();
    const showPricingPubs = configuration && topLevelAccountNumber && !configuration?.distributorAccountRestrictions?.restrictedPricing?.includes(topLevelAccountNumber);
    let supportDocumentSearchSection = showSupportDocumentSection && (
      <Row>
        <Col>
          <Card body>
            <h4><FormattedMessage id='publications.documentSearchTitle' /></h4>
            <FormattedMessage id='publications.documentSearchDescription' />
            <Row>
              <Col md={4}>
                <SearchInput
                  searchInputDataTestId='publications-search-input'
                  searchButtonDataTestId='publications-search-button'
                  placeholder='Search'
                  value={this.state.query}
                  onChange={this.handleSearchInputChange}
                  onKeyPress={this.handleKeyPress}
                  onClick={this.search}
                  onClear={this.clearSearch}
                />
              </Col>
            </Row>
            {publications.data.length > 0 &&
              <Table striped borderless responsive className='publication-lines-table'>
                <thead>
                  <tr>
                    <th><FormattedMessage id='publications.documentTableName' /></th>
                    <th><FormattedMessage id='publications.documentTableDate' /></th>
                    <th><FormattedMessage id='publications.documentTableDownload' /></th>
                  </tr>
                </thead>
                <tbody>
                  {publications.data.map((publication, index) =>
                    <PublicationRow
                      publication={publication} key={`publication-${index}`}
                      handleDownload={this.handlePublicationFileDownload}
                    />
                  )}
                </tbody>
              </Table>
            }
          </Card>
        </Col>
      </Row>
    );
    return (
      <div id='publications' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          {supportDocumentSearchSection}
          <Row>
            {this.state.canPlaceOrders &&
              <Col md={4} className='d-flex flex-column align-items-stretch'>
                <Card body>
                  <h4><FormattedMessage id='publications.partsPricingTitle' /></h4>
                  <h5 className='is-color-dark-gray'>USA</h5>
                  {showPricingPubs &&
                    <>
                      <h5 className='is-color-dark-gray font-weight-light'>
                        <FormattedMessage id='publications.toolAndAccessoryPrices' />
                        &nbsp;(effective <FormattedDate value={this.getDplGeneratedDate()} />)
                      </h5>
                      <p>
                        <Button secondary onClick={() => this.handleDPLFileDownload(this.getDplUsaFileName())}>
                          <FontAwesomeIcon icon='file-excel' className='mx-2' />{this.getDplUsaFileName()}
                        </Button>
                      </p>
                      <h5 className='is-color-dark-gray font-weight-light'>
                        <FormattedMessage id='publications.servicePartsPrices' />
                      </h5>
                      <p>
                        <Button secondary onClick={() => this.handlePriceFileDownload('SPPL.xlsx')}>
                          <FontAwesomeIcon icon='file-excel' className='mx-2' />SPPL.xlsx
                        </Button>
                      </p>
                    </>
                  }
                  <h5 className='is-color-dark-gray font-weight-light'>
                    <FormattedMessage id='publications.iMap' />
                  </h5>
                  <p>
                    <Button secondary onClick={() => this.handlePriceFileDownload('IMAP.xlsx')}>
                      <FontAwesomeIcon icon='file-excel' className='mx-2' />IMAP.xlsx
                    </Button>
                  </p>
                  <br />
                  {showPricingPubs &&
                    <>
                      <h5 className='is-color-dark-gray'>Canada</h5>
                      <h5 className='is-color-dark-gray font-weight-light'>
                        <FormattedMessage id='publications.toolAndAccessoryPrices' />
                      </h5>
                      <p>
                        <Button secondary onClick={() => this.handlePriceFileDownload('DPLC.xlsx')}>
                          <FontAwesomeIcon icon='file-excel' className='mx-2' />DPLC.xlsx
                        </Button>
                      </p>
                      <h5 className='is-color-dark-gray font-weight-light'>
                        <FormattedMessage id='publications.servicePartsPrices' />
                      </h5>
                      <p>
                        <Button secondary onClick={() => this.handlePriceFileDownload('SPPLC.xlsx')}>
                          <FontAwesomeIcon icon='file-excel' className='mx-2' />SPPLC.xlsx
                        </Button>
                      </p>
                    </>
                  }
                </Card>
              </Col>
            }
            <Col md={4} className='d-flex flex-column align-items-stretch'>
              <Card body>
                <h4><FormattedMessage id='publications.servicePublicationsTitle' /></h4>
                <h5 className='is-color-dark-gray font-weight-light'>
                  <FormattedMessage id='publications.lightningMaxRepairPriceSheetTitle' />
                </h5>
                <p><FormattedMessage id='publications.lightningMaxRepairPriceSheetDescription' /></p>
                <p>
                  <Button secondary onClick={() => this.handleServiceFileDownload('LMR.xlsx')}>
                    <FontAwesomeIcon icon='file-download' className='mx-2' />LMR.xlsx
                  </Button>
                </p>
                <h5 className='is-color-dark-gray font-weight-light'>
                  <FormattedMessage id='publications.thirtyDayExclusionListTitle' />
                </h5>
                <p><FormattedMessage id='publications.thirtyDayExclusionListDescription' /></p>
                <p>
                  <Button secondary onClick={() => this.handleServiceFileDownload('30DaySatisfactionExclusionList.pdf')}>
                    <FontAwesomeIcon icon='file-download' className='mx-2' />Exclusion List.pdf
                  </Button>
                </p>
              </Card>
            </Col>
            <Col md={4} className='d-flex flex-column align-items-stretch'>
              <Card body>
                <h4><FormattedMessage id='publications.technicalPublicationsTitle' /></h4>
                <p><FormattedMessage id='publications.technicalPublicationsDescription' /></p>
                <Button secondary onClick={() => window.open('https://www.milwaukeetool.com/support/manuals-and-downloads?IsMC=1', '_blank')}>
                  <FontAwesomeIcon icon='external-link-alt' className='mx-2' /><FormattedMessage id='publications.technicalPublicationsButton' />
                </Button>
              </Card>
            </Col>
          </Row>
          <SalesPolicies
            handleSalesPolicy={() => this.handleSalesPoliciesFileDownload(this.getPolicyFileName('Sales Policy'))}
            handleInternetPolicy={() => this.handleSalesPoliciesFileDownload(this.getPolicyFileName('Internet Policy'))}
            handleCoOpPolicy={() => this.handleSalesPoliciesFileDownload(this.getPolicyFileName('Co-Op Policy'))}
            handleTermsOfSalePolicy={() => this.handleSalesPoliciesFileDownload(this.getPolicyFileName('Terms of Sale Policy'))}
            handleIMAPPolicy={() => this.handleSalesPoliciesFileDownload(this.getPolicyFileName('IMAP Policy'))}
            salesPolicyFile={this.getPolicyFileName('Sales Policy')}
            internetPolicyFile={this.getPolicyFileName('Internet Policy')}
            coOpPolicyFile={this.getPolicyFileName('Co-Op Policy')}
            termsOfSalePolicyFile={this.getPolicyFileName('Terms of Sale Policy')}
            IMAPPolicyFile={this.getPolicyFileName('IMAP Policy')}
          />
          <Row>
            <Col>
              <Card body>
                <h4><FormattedMessage id='publications.disclaimerTitle' /></h4>
                <p><FormattedMessage id='publications.disclaimerDescription' /></p>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    publications: state.publications.publications,
    accountHasOneKey: state.account.currentAccount.accountDetails.hasOneKey,
    topLevelAccountNumber: state.account.currentAccount.accountDetails.topLevelAccount,
    configuration: state.configuration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mediaActions: bindActionCreators(mediaActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
    publicationActions: bindActionCreators(publicationActions, dispatch)
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Publications));