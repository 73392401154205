import React from 'react';
import DOMPurify from 'dompurify';
import {FormattedMessage} from 'react-intl';
import {Col, Container, Row} from 'reactstrap';
import {Button, Link, ProductAvailability, ProductPrice} from '../../../components';
import user from '../../../helpers/user';

const SaveForLaterLinePreviewGrid = (props) => {
  const { sku, skuAlias, description } = props.saveForLaterLine;

  const handleDeleteClick = () => {
    return props.handleDeleteClick(props.saveForLaterLine);
  };
  
  const handleMoveToCartClick = () => {
    return props.handleMoveToCartClick(props.saveForLaterLine);
  };
    
  const getSkuAliasMarkup = (line) =>{
    return line.skuAlias !== line.description && (
      <div data-test-id={`save-for-later-grid-product-sku-${line.sku}`} className='mr-lg-3'>{line.skuAlias}</div>  
    )
  }
  
  const getListPriceMarkup = (line) => {
   return user && !user.isMilwaukeeToolEmployee() && (
     <div>
      <span className='has-font-size-1'>
        <FormattedMessage id='pricing.listPrice'/>&nbsp;
      </span>
       <span data-test-id={`save-for-later-grid-list-price-${line.sku}`} className='has-font-size-1'>
        <del>
          <ProductPrice
            pricing={props.pricing}
            sku={line.sku}
            type='listAmount'
          />
        </del>
      </span>
     </div>
   );
  }
  
  const getYourPriceMarkup = (line) =>(
    <div>
      <div className='has-font-size-2'>
        <FormattedMessage id='pricing.yourPrice'/>
      </div>
      <div data-test-id={`save-for-later-grid-your-price-${line.sku}`} className='has-font-size-4'>
        <ProductPrice
          pricing={props.pricing}
          sku={line.sku}
          type='unitAmount'
        />
      </div>
    </div>
  )
  
  const getAvailabilityMarkup = (line) =>(
    <div data-test-id={`save-for-later-grid-availability-${line.sku}`} className='has-font-size-2 font-weight-bold'>
      <ProductAvailability
        availability={props.availability}
        sku={line.sku}
      />
    </div>
  );
  
  return (
    <Container className='order-line-preview-grid'>
      <Row className='align-items-center animate-bottom-fade-in print-group'>
        <Col xs='12' md='9'>
          <Container>
            <Row className='mb-0 ml-3 ml-md-2 mt-3 my-md-2'>
              <Col xs='12' md='2' className='text-left align-self-center font-weight-bold product-data'>
                <Link to={`products/${skuAlias}`} locale={props.locale} className='no-underline'>
                  {getSkuAliasMarkup(props.saveForLaterLine)}
                </Link>
              </Col>
              <Col xs='12' md='7' data-test-id={`save-for-later-grid-product-name-${sku}`} className='is-color-red text-left align-self-center wrap-break-words'>
                <Link to={`products/${skuAlias}`} locale={props.locale} className='no-underline'>
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}}/>
                </Link>
              </Col>
              <Col xs='3' md className='my-1 text-left align-self-center'>
                {getListPriceMarkup(props.saveForLaterLine)}
                {getYourPriceMarkup(props.saveForLaterLine)}
                {getAvailabilityMarkup(props.saveForLaterLine)}
              </Col>              
            </Row>
            {props.saveForLaterLine.children?.length ? (
              <React.Fragment>
                {props.saveForLaterLine.children.map(c=>(
                  <Row key={c.id} className='mb-0 ml-3 ml-md-2 mt-1 my-md-2'>
                    <Col md={{size: 7, offset: 2}} xs='12' className='text-left align-self-center wrap-break-words'>
                      <div className='has-font-size-2'>
                        {getSkuAliasMarkup(c)}
                      </div>
                      <div className='has-font-size-2'>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(c.description)}}/>
                      </div>
                    </Col>
                    <Col xs='3' md className='my-1 text-left align-self-center has-font-size-2'>
                      {getListPriceMarkup(c)}
                      {getYourPriceMarkup(c)}
                    </Col>
                  </Row>                  
                ))}
              </React.Fragment>
              ): ''}
          </Container>
        </Col>
        <Col xs='12' md='3' className='mb-2 my-lg-auto align-self-center'>
          <Container>
            <Row className='justify-content-center'>
              <Col xs='auto' className='mt-1 mt-lg-3 mt-xl-0'>
                <Button data-test-id='save-for-later-grid-delete' outline color='secondary' onClick={handleDeleteClick}>Delete</Button>
                <Button data-test-id='save-for-later-grid-move-to-cart' outline color='primary' onClick={handleMoveToCartClick} isLoading={props.isProcessing} className='ml-2'>Move to Cart</Button>
              </Col>
            </Row>
          </Container>          
        </Col>
      </Row>
    </Container>
  );
};

export default SaveForLaterLinePreviewGrid;