const common = {
  tryAgainLater: 'Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878).'
};

const square = {
  inviteToPay: 'Inviter à Payer',
  paymentMethod: 'Mode de Paiement',
  paymentSummary: 'Résumé de Paiement',
  submitPayment: 'Soumettre le Paiement',
  referenceNumber: 'Numéro de Réference',
  accountNumber: 'Numéro de Compte',
  email: 'Email',
  transactionNotValid: 'La transaction n\'est plus valide',
  transactionNotValidMessage: 'Cette transaction n\'est plus valide.',
  transactionComplete: 'Transaction Terminée',
  transactionCompleteMessage: 'Cette transaction est déjà terminée.',
  inviteToPayErrorMessage: `Une erreur s'est produite lors de la récupération des détails de l'invitation à payer. ${common.tryAgainLater}`,
  getCreditCardsErrorMessage: `Une erreur est survenue lors de la récupération de vos cartes de crédit. ${common.tryAgainLater}`,
  submitPaymentErrorMessage: `Une erreur est survenue lors de l'envoi de votre paiement. ${common.tryAgainLater}`,
  addCreditCardErrorMessage: `Une erreur s'est produite lors de l'ajout de votre carte de crédit. ${common.tryAgainLater}`,
  deleteCreditCardErrorMessage: `Une erreur s'est produite lors de la suppression de votre carte de crédit. ${common.tryAgainLater}`,
  paymentSubmittedErrorMessage: `Une erreur est survenue lors de l'envoi de votre paiement. ${common.tryAgainLater}`,
  creditCardSelectionRequired: 'Veuillez sélectionner une carte de crédit pour effectuer le paiement.',
  errorCodes:{
    DEFAULT_ERROR: `Une erreur s'est produite lors du traitement de votre paiement. ${common.tryAgainLater}`,
    CARD_DENIED: 'Votre méthode de paiement a été refusé. Veuillez ajouter ou sélectionner un autre mode de paiement.',
    INVALID_CARD_ERROR: 'Le mode de paiement est invalide. Veuillez ajouter un nouveau mode de paiement.',
    UNSUPPORTED_CARD_ERROR: 'Votre mode de paiement n\'est pas pris en charge. Seules les cartes VISA, MasterCard, American Express, Discover, JSC et UnionPay sont acceptées.'
  }
};

export default square;