import Enumerable from 'linq';
import React, {Fragment, useEffect, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {bindActionCreators} from 'redux';

import auth from '../../auth';
import {Button, Icon, Link, PageLoader, Price} from '../../components';
import user from '../../helpers/user';
import {wishlistLineGrouper} from "../../helpers/wishlistLineGrouper";
import history from '../../history';
import {availabilityActions, cartActions, priceActions, wishlistsActions} from '../../store';
import EditWishlistModal from './EditWishlistModal';
import WishlistLine from './WishlistLine';

const Wishlist = (props) => {
  const shareUrl = window.location.href;
  const userId = user.getUserId();
  const [wishlistLines, updateWishlistLines] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [wishlistTotal, updateWishlistTotal] = useState(0);
  const [quantityTotal, updateQuantityTotal] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
    
  useEffect(() => {
    getWishlist();
  }, []);
  
  useEffect(() => {
    if (props.wishlist.wishlistLines) {
      updateWishlistLines(wishlistLineGrouper(props.wishlist.wishlistLines));
    }
  }, [props.wishlist.wishlistLines]);
  
  useEffect(() => {
    let skus = Enumerable.from(wishlistLines).select(x => x.sku).toArray();
    let childSkus = Enumerable.from(wishlistLines).selectMany(x=>x.children || []).select(x=>x.sku).toArray();
    if (auth.isAuthenticated()) {
      getPricing([...skus, ...childSkus]);
      getAvailability(skus);
    }

    recalculateQuantityTotal();
    recalculateWishlistTotal();
  }, [wishlistLines]);
  
  useEffect(() => {
    if (props.wishlist.userId && props.wishlist.userId.length) {
      setCanEdit(userId === props.wishlist.userId);
    } 
  }, [props.wishlist.userId]);
  
  useEffect(() => {
    recalculateWishlistTotal();
  }, [props.pricing.priceMap]);
  
  const getWishlist = () => {
    props.wishlistsActions.getWishlist(props.match.params.id);
  };
  
  const getPricing = (skus) => {
    if (skus && skus.length > 0) {
      props.priceActions.getPrices(skus, props.accountNumber);
    }
  };
  
  const recalculateWishlistTotal = (sku = '', quantity = 0) => {
    let totalPrice = 0;
    
    if (wishlistLines.length > 0) {
      wishlistLines.forEach((wishlistLine) => {
        let linePrice = (props.pricing.priceMap && props.pricing.priceMap[wishlistLine.sku] && props.pricing.priceMap[wishlistLine.sku].unitAmount) || 0;
        let lineQuantity = wishlistLine.sku === sku ? quantity : wishlistLine.quantity;
        let lineTotal = parseFloat(linePrice) * lineQuantity;
        
        totalPrice += lineTotal;
        
        if(wishlistLine.children?.length){
          wishlistLine.children.forEach(c=>{
            let linePrice = (props.pricing.priceMap && props.pricing.priceMap[c.sku] && props.pricing.priceMap[c.sku].unitAmount) || 0;
            let lineQuantity = c.quantity;
            let lineTotal = parseFloat(linePrice) * lineQuantity;
            
            totalPrice += lineTotal;
          })
        }
      });
    }
    
    updateWishlistTotal(totalPrice);
  };
  
  const recalculateQuantityTotal = (sku = '', quantity = 0) => {
    let totalQuantity = 0;

    if (wishlistLines.length > 0) {
      if (sku.length && quantity > 0) {
        totalQuantity = Enumerable.from(wishlistLines).where(x => x.sku !== sku).select(x => x.quantity).sum();
        totalQuantity += quantity;
      }
      else {
        totalQuantity = Enumerable.from(wishlistLines).select(x => x.quantity).sum();
      }
    }

    updateQuantityTotal(totalQuantity);
  };
  
  const getAvailability = (skus) => {
    if (skus && skus.length > 0)
      props.availabilityActions.getAvailability(skus, props.accountNumber);
  };
  
  const handleRemoveWishlistLineClick = (wishlistLine) => {
    props.wishlistsActions.removeFromWishlist(wishlistLine.sku, props.wishlist.id, wishlistLine.customLogoRefId)
      .then(() => {
        getWishlist();
        toastr.success(props.intl.formatMessage({id: 'wishlists.removedFromWishlistTitle'}), props.intl.formatMessage({id: 'wishlists.removedFromWishlist'}, {description: wishlistLine.description}));
      })
      .catch(() => {
        getWishlist();
        toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'wishlists.errorRemovingFromWishlist'}, {description: wishlistLine.description}));
      });
  };
  
  const handleAddWishlistLineToCartClick = (wishlistLine) => {
    props.wishlistsActions.moveToCart(wishlistLine.sku, wishlistLine.customLogoRefId, props.wishlist.id)
      .then(() => {
        props.cartActions.getCartStatistics();
        toastr.success(props.intl.formatMessage({id: 'wishlists.wishlistLineAddedToCartTitle'}), props.intl.formatMessage({id: 'wishlists.wishlistLineAddedToCart'}, {description: wishlistLine.description}));
      })
      .catch(() => {
        toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'wishlists.errorAddingWishlistLineToCart'}), {timeout: 0});
      });
  };

  const handleSaveChangesClick = (name) => {
    props.wishlistsActions.updateWishlist(props.wishlist.id, name)
      .then(() => {
        toastr.success(props.intl.formatMessage({id: 'wishlists.wishlistUpdatedTitle'}), props.intl.formatMessage({id: 'wishlists.wishlistUpdated'}));
      })
      .then(() => {
        toggleEditModal();
        getWishlist();
      })
      .catch((error) => {
        if (error.status === 409)
          toastr.error(props.intl.formatMessage({id: 'wishlists.wishlistAlreadyExistsTitle'}), props.intl.formatMessage({id: 'wishlists.wishlistAlreadyExists'}), {timeout: 0});
        else
          toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'wishlists.errorUpdatingWishlist'}), {timeout: 0});
      });
  };
  
  const handleDeleteClick = () => {
    props.wishlistsActions.deleteWishlist(props.wishlist.id)
      .then(() => {
        history.push('/shoppingLists');
        toastr.success(props.intl.formatMessage({id: 'wishlists.wishlistDeletedTitle'}), props.intl.formatMessage({id: 'wishlists.wishlistDeleted'}));
      })
      .catch(() => {
        toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'wishlists.errorDeletingWishlist'}), {timeout: 0});
      });
  };
  
  const handleAddToCartClick = () => {
    props.cartActions.addBatchToCart(wishlistLines, props.accountNumber)
      .then(() => {
        props.cartActions.getCartStatistics();
        toastr.success(props.intl.formatMessage({id: 'wishlists.wishlistAddedToCartTitle'}), props.intl.formatMessage({id: 'wishlists.wishlistAddedToCart'}));
      })
      .catch(() => {
        toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'wishlists.errorAddingWishlistToCart'}), {timeout: 0});
      });
  };
  
  const handleShareClick = () => {
    toastr.success(props.intl.formatMessage({id: 'general.linkCopiedToastTitle'}), props.intl.formatMessage({id: 'general.linkCopied'}));
  };
  
  const handleQuantityChange = (sku, quantity, customLogRefId) => {
    props.wishlistsActions.updateWishlistQuantity(props.wishlist.id, sku, quantity, customLogRefId)
      .then(() => {
        let wishlistLine = Enumerable.from(wishlistLines).firstOrDefault(x => x.sku === sku && x.customLogoRefId === customLogRefId);
        wishlistLine.quantity = quantity;
        
        recalculateQuantityTotal(sku, quantity);
        recalculateWishlistTotal(sku, quantity);
      })
      .catch(() => {
        getWishlist();
        toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'wishlists.errorUpdatingQuantity'}), {timeout: 0});
      });
  };
  
  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };
  
  let shoppingListsBreadcrumbMarkup = user.canPlaceOrders() && canEdit && (
    <BreadcrumbItem data-test-id='your-shopping-lists'>
      <Link to={`shoppingLists`} locale={props.locale}><FormattedMessage id='wishlists.yourWishlists'/></Link>
    </BreadcrumbItem>
  );

  let wishlistLineRows = wishlistLines && wishlistLines.map((wishlistLine, index) => {
    return (
      <ListGroupItem key={`wishlist-line-${index}`} className='wishlist-list-item'>
        <WishlistLine
          locale={props.locale}
          wishlistLine={wishlistLine}
          pricing={props.pricing}
          availability={props.availability}
          canEdit={canEdit}
          removingFromWishlist={props.removingFromWishlist}
          addingToCart={props.cartProcessing}
          quantityUpdating={props.quantityIsUpdating}
          handleRemoveClick={handleRemoveWishlistLineClick}
          handleQuantityChange={handleQuantityChange}
          handleAddToCartClick={handleAddWishlistLineToCartClick}
        />
      </ListGroupItem>
    );
  });
  
  let wishlistLinesMarkup = wishlistLines && wishlistLines.length > 0 ? wishlistLineRows : (
    <ListGroupItem className='text-center'>
      <div className='my-4'>
        <h3><FormattedMessage id='wishlists.emptyWishlist'/></h3>
      </div>
    </ListGroupItem>
  );
  
  let editWishlistModalMarkup = showEditModal && (
    <EditWishlistModal
      isOpen={showEditModal}
      toggle={toggleEditModal}
      wishlist={props.wishlist}
      handleSaveChangesClick={handleSaveChangesClick}
      handleDeleteClick={handleDeleteClick}
    />
  );
  
  let shareButtonMarkup = (
    <CopyToClipboard text={shareUrl} onCopy={handleShareClick}>
      <div data-test-id='wishlist-share' className='is-mock-link mr-4'>
        <span className='mr-2'><FormattedMessage id='general.share'/></span>
        <Icon type='share-alt' size='md' color='red'/>
      </div>
    </CopyToClipboard>
  );
  
  let editButtonMarkup = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div data-test-id='wishlist-edit' onClick={() => toggleEditModal()} className='is-mock-link d-print-none'>
      <span className='mr-2'><FormattedMessage id='general.edit'/></span>
      <Icon type='pencil-alt' size='md' color='red'/>
    </div>
  );
  
  let adminButtonsMarkup = canEdit && (
    <Col className='align-self-center'>
      <Container fluid>
        <Row className='justify-content-end'>
          <Col xs='auto'>
            <div className='d-flex flex-row align-items-center'>
              {shareButtonMarkup}
              {editButtonMarkup}
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
  );
  
  let addToCartButtonMarkup = auth.isAuthenticated && user.canPlaceOrders() && (
    <Row className='justify-content-end'>
      <Col>
        <Button
          data-test-id='wishlist-addtocart'
          block
          color='primary'
          onClick={() => handleAddToCartClick()}
          className='d-print-none'
          isLoading={props.cartProcessing}
          isDisabled={wishlistLines && wishlistLines.length <= 0}
        >
          <FormattedMessage id='search.addToCart'/>
        </Button>
      </Col>
    </Row>
  );
  
  let subtotalMarkup = auth.isAuthenticated && user.canPlaceOrders() && (
    <Fragment>
      <div className='d-flex align-items-baseline justify-content-center mt-4'>
        <span className='has-font-size-4 mb-0 mr-2'><FormattedMessage id='wishlists.wishlistSubtotal'/>:</span>
        <span data-test-id='wishlist-subtotal' className='has-font-size-5 font-weight-bold'>
          <Price
            value={wishlistTotal}
            canBeZero={true}
            isLoading={props.pricingIsLoading || props.quantityIsUpdating}
          />
        </span>
      </div>
      <div data-test-id='wishlist-items-quantity' className='has-font-size-2 text-muted mb-3'>{quantityTotal} items</div>
    </Fragment>
  );

  return props.isLoading ? <PageLoader/> : (
    <Fragment>
      <Container id='wishlist' className='content-wrapper animate-bottom-fade-in'>
        <Row className='my-3 align-items-end'>
          <Col xs='auto' className='mr-3'>
            <h2 data-test-id='wishlist-name-title'>{props.wishlist.name}</h2>
          </Col>
          {adminButtonsMarkup}
        </Row>
        <Row>
          <Col>
            <Breadcrumb>
              {shoppingListsBreadcrumbMarkup}
              <BreadcrumbItem active>
                <FormattedMessage id='wishlists.wishlistDetails'/>
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody className='p-0'>
                <ListGroup className='wishlist-list'>
                  {wishlistLinesMarkup}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='justify-content-end'>
          <Col xs='auto'>
            {subtotalMarkup}
            {addToCartButtonMarkup}
          </Col>
        </Row>
      </Container>
      {editWishlistModalMarkup}
    </Fragment>
  )
};

const mapStateToProps = state => {
  return {
    isLoading: state.wishlists.getWishlist.isLoading,
    isError: state.wishlists.getWishlist.isError,
    wishlist: state.wishlists.getWishlist.wishlist,
    account: state.account,
    accountNumber: state.account && (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber),
    cartProcessing: state.cart.addToCart.isProcessing,
    removingFromWishlist: state.wishlists.removeFromWishlist && state.wishlists.removeFromWishlist.isProcessing,
    locale: state.locales.locale,
    pricing: state.pricing,
    pricingIsLoading: state.pricing.isLoading,
    availability: state.availability,
    quantityIsUpdating: state.wishlists.updateWishlistQuantity.isProcessing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    wishlistsActions: bindActionCreators(wishlistsActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
    priceActions: bindActionCreators(priceActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch)
  }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Wishlist));