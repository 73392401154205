import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@met/react-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '../../../components/Icon';

const CartExpiredModal = (props) => {
  const [clickedRefresh, setClickedRefresh] = useState(false);
  const [clickedEmpty, setClickedEmpty] = useState(false);

  const handleRefreshClick = () => {
    setClickedRefresh(true);
    props.onRefreshClick();
  }

  const handleOnEmptyCartClick = () => {
    setClickedEmpty(true);
    props.onEmptyCartClick();
  }

  return (
    <Modal hidden={!props.isOpen} centered>
      <ModalContent>
        <ModalHeader>
          <div className='d-flex flex-row align-items-center'>
            <Icon type='exclamation-triangle' color='red' size='sm' />
            <div className='ml-1'>
              <FormattedMessage id='cart.cartExpiredTitle' />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id='cart.cartExpiredMessage' />
        </ModalBody>
        <ModalFooter>
          <Button data-test-id='empty-cart' outline primary onClick={handleOnEmptyCartClick} loading={clickedEmpty}>Empty Cart</Button>
          <Button primary onClick={handleRefreshClick} loading={clickedRefresh}>
            <FormattedMessage id='general.refresh' />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CartExpiredModal.propTypes = {
  isOpen: PropTypes.bool,
  onRefreshClick: PropTypes.func,
  onEmptyCartClick: PropTypes.func
};

export default CartExpiredModal;