const common = {
  tryAgainLater: 'Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).'
};

const square = {
  inviteToPay: 'Invite To Pay',
  paymentMethod: 'Payment Method',
  paymentSummary: 'Payment Summary',
  submitPayment: 'Submit Payment',
  referenceNumber: 'Reference #',
  accountNumber: 'Account #',
  email: 'Email',
  transactionNotValid: 'Transaction No Longer Valid',
  transactionNotValidMessage: 'This transaction is no longer valid.',
  transactionComplete: 'Transaction Complete',
  transactionCompleteMessage: 'This transaction has already been completed.',
  inviteToPayErrorMessage: `An error occurred while retrieving the Invite To Pay details. ${common.tryAgainLater}`,
  getCreditCardsErrorMessage: `An error occurred while retrieving your credit cards. ${common.tryAgainLater}`,
  submitPaymentErrorMessage: `An error occurred while submitting your payment. ${common.tryAgainLater}`,
  addCreditCardErrorMessage: `An error occurred while adding your credit card. ${common.tryAgainLater}`,
  deleteCreditCardErrorMessage: `An error occurred while deleting your credit card. ${common.tryAgainLater}`,
  creditCardSelectionRequired: 'Please select a credit card to provide payment',
  errorCodes:{
    DEFAULT_ERROR: `An error occurred while processing your payment. ${common.tryAgainLater}`,
    CARD_DENIED : 'Your payment method was declined. Please add or select an alternative payment method.',
    INVALID_CARD_ERROR: 'The payment method is invalid. Please add a new payment method.',
    UNSUPPORTED_CARD_ERROR: 'Your payment method is not supported. Only VISA, MasterCard, America Express, Discover, JSC, and UnionPay are accepted.',
  }
};

export default square;