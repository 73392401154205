import React from 'react';
import {injectIntl} from 'react-intl';

import {PageLoader} from '../../components';

const Callback = props => (
  <div className='d-flex justify-content-center align-items-center'>
    <PageLoader/>
  </div>
);

export default injectIntl(Callback);
