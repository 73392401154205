import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Card, CardBody, ListGroup, ListGroupItem} from 'reactstrap';
import PropTypes from 'prop-types';

const EmptyCartMessage = (props) => {
  if ((!props.cart || !props.cart.pendingOrder) || (props.cart.pendingOrder && Object.keys(props.cart.pendingOrder.orderLines).length > 0)) {
    return null;
  }
  
  return (
    <Card>
      <CardBody className='p-0'>
        <ListGroup className='cart-list'>
          <ListGroupItem data-test-id='your-cart-is-empty' className='text-center cart-list-item'>
            <div className='my-4'>
              <h3><FormattedMessage id='cart.emptyCartMessage'/></h3>
            </div>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};

EmptyCartMessage.propTypes = {
  cart: PropTypes.object
};

export default EmptyCartMessage;