import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactSVG from 'react-svg';

import boltIcon from './Icons/bolt_add.svg';
import mtLogo from './Icons/mt_logo.svg';

class MilwaukeeToolIcon extends Component {
  render() {
    const { icon, className, color, width, height } = this.props;
    const defaultColor = 'white';
    
    let style = {
      fill: color || defaultColor,
      width: width + 'px',
      height: height + 'px'
    };
    
    switch (icon) {
      case 'bolt-add':
        return (
          <ReactSVG
            src={boltIcon}
            svgClassName={className}
            svgStyle={style}
            fallback={<img src={boltIcon} alt='Bolt'/>}
          />
        );
      case 'mt-logo':
        return (
          <ReactSVG
            src={mtLogo}
            svgClassName={className}
            svgStyle={style}
          />
        );
      default:
        return '';
    }
  }
}

MilwaukeeToolIcon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string
};

export default MilwaukeeToolIcon;