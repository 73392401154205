import PropTypes from "prop-types";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from 'react-intl';

import {Link} from 'react-router-dom';

import {Col, Container, Row} from 'reactstrap';
import queryString from '../../helpers/queryString';
import ca from '../../images/ca.svg';
import mx from '../../images/mx.svg';
import us from '../../images/us.svg';
import './Footer.scss';

class Footer extends Component {
  render() {
    let currentRoute = window.location.pathname + window.location.search;
    let parts = currentRoute.split('/');
    let filtered = parts.filter(function (el) { return el !== undefined && el != null && el !== '';});
    const isProduction = (window.location.hostname === 'connect.milwaukeetool.com');
    if (['en', 'fr', 'es'].includes(filtered[0])) {
      filtered = filtered.slice(1);
    }
    
    let newRoute = filtered.join('/');
    newRoute = queryString.update('p', null, newRoute || '/');
    
    if (newRoute === '/')
      newRoute = '';

    return (
      <footer className='footer'>
        <Container>
          <Row>
            <Col className='text-center text-sm-left mb-2 mb-xm-0'>
              <Container>
                <Row>
                  <Col>
                    <FormattedMessage id='footer.copyright' values={{ year: new Date().getFullYear() }}/>
                  </Col>
                </Row>
                <div className='mt-2'>
                  <a
                    href='https://privacyportal-cdn.onetrust.com/dsarwebform/0bcd3d0d-df9d-4291-9bd5-f276c7dae8aa/c5ed10b1-7ea6-4af4-839e-b31008e11e5c.html'
                    target='_blank'
                    rel="noopener noreferrer"
                    className='is-color-white is-static-link'
                  >
                    <FormattedMessage id='footer.ccpa'/>
                  </a>
                  <a
                    href={isProduction ? 'https://milwaukeetool.com/Footer/Privacy' : 'https://staging.milwaukeetool.com/Footer/Privacy'}
                    className='is-color-white is-static-link footer-menu-link'
                  >
                    <FormattedMessage id='footer.privacy'/>
                  </a>
                </div>
              </Container>
            </Col>
            {(this.props.showCountries ? 
             (
            <Col sm='auto' className='text-center text-sm-left'>
              <ul className='countries'>
                <li>
                  <Link to={`/en/${newRoute}`}><img src={us} width={20} alt='english'/></Link>
                </li>
                <li>
                  <Link to={`/es/${newRoute}`}><img src={mx} width={20} alt='spanish'/></Link>
                </li>
                <li>
                  <Link to={`/fr/${newRoute}`}><img src={ca} width={20} alt='french'/></Link>
                </li>
              </ul>
            </Col>
             ) : "")}
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  showCountries: PropTypes.bool
};

export default injectIntl(Footer);