import {success} from '../utils';
import {GET_USER, GET_USERS} from './types';

export let initialState = {
  users: null,
  user: null,
};

const reducer = (state, action) => {
  state = state || initialState;
  
  switch(action.type) {
    case success(GET_USERS):
      return {
        ...state,
        users: action.payload.data
      }
    case success(GET_USER):
      return {
        ...state,
        user: action.payload.data
      }
    default:
      return state;
  }
}

export default reducer;