import { Card, CardBody, CardHeader, Grid } from '@met/react-components';
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import SwaggerUI from 'swagger-ui-react'; 

import './Swagger.scss'; 

const Swagger = (props) => {
  return (
    <Grid fluid>
      <Card>
        <CardHeader>Endpoints</CardHeader>
        <CardBody>
          <SwaggerUI
            url='/swagger/v1/swagger.json'
          />
        </CardBody>
      </Card>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default injectIntl(connect(null, mapDispatchToProps)(Swagger));