const checkout = {
  shippingAddressTitle: 'Shipping Address',
  shippingSpeedTitle: 'Shipping Speed',
  shippingSpeedMessage: `
    Orders placed for shipment within the United States can choose to select an expedited Shipping Speed.
    Please remember that you will be billed for all freight charges associated with this service on your invoice.
    "Collect" or "third party billings" are not available on-line.
    {br}{br}
    Air Shipments are processed in the order in which they are received, and are not guaranteed to ship the same day.
    Most air shipments will ship within 1 business day. Regular ground shipments will ship within 1-2 business days.
    "Weekends and holidays are excluded."
    {br}{br}
  `,
  disabledShippingSpeedMessage: `
    *At this time, we are not processing next day air or expedited shipping. Please contact your sales rep with any questions.
    {br}{br}
  `,
  customLogoShippingSpeedMessage: `
    Custom Logo Gear shipments are handled by our customization partner and estimations will be sent to the email provided above.
  `,
  paymentMethodTitle: 'Payment Method',
  requestedShipDateTitle: 'Requested Ship Date (Optional)',
  requestedShipDateMessage: 'Ship Dates are determined by product availability, distribution, lead time, and shipping requirements.',
  orderSummaryTitle: 'Order Summary',
  errorSubmittingOrder: 'There was an error submitting your order. Please try again later.',
  validationWarningMessage: 'Purchase Order # is a required field',
  paymentMethodRequiredTitle: 'Payment Method Required',
  paymentMethodRequiredMessage: 'You must select a payment method before checking out.',
  addressRequiredTitle: 'Shipping Address Required', 
  addressRequiredMessage: 'You must select a shipping address before checking out.',
  addressRegionLockedTitle: 'Custom Logo Unavailable',
  addressRegionLockedMessage: 'You have items in your cart that cannot be shipped outside the US'
};

export default checkout;