export const calculateNewQuantity = (newQuantity, minQuantity, maxQuantity, incrementQuantity) => {
  if ((newQuantity - minQuantity) % incrementQuantity !== 0) {
    if (newQuantity > 0)
      newQuantity = Math.ceil(newQuantity / incrementQuantity.toFixed(1)) * incrementQuantity;
    else
      newQuantity = incrementQuantity;
  }

  if (!newQuantity)
    newQuantity = incrementQuantity;

  if (newQuantity < minQuantity) {
    newQuantity = minQuantity;
  }

  if (maxQuantity > 0 && newQuantity > maxQuantity) {
    newQuantity = maxQuantity;
  }
  else if (newQuantity > 5000) {
    newQuantity = 5000;
  }

  if (!newQuantity)
    newQuantity = incrementQuantity;

  return newQuantity;
}