import React from 'react';
import DOMPurify from 'dompurify';
import {FormattedMessage} from 'react-intl';
import {Col, Container, Row} from 'reactstrap';
import auth from '../../auth';
import {Button, Link, ProductAvailability, ProductPrice, QuantityInput} from '../../components';
import LoadingDots from '../../components/LoadingDots';
import user from '../../helpers/user';

const WishlistLine = (props) => {
  const { sku, skuAlias, description, quantity, nextValidQuantityAllowed, lowestQuantityAllowed, highestQuantityAllowed, customLogoRefId } = props.wishlistLine;
  
  const handleQuantityChange = (quantity) => {
    props.handleQuantityChange(sku, quantity, customLogoRefId);
  };
  
  const handleRemoveClick = () => {
    props.handleRemoveClick(props.wishlistLine);
  };
  
  const handleAddToCartClick = () => {
    props.handleAddToCartClick(props.wishlistLine);
  };
  
  const getSkuAliasMarkup = (line) =>{
    return line.skuAlias !== line.description && (
      <div data-test-id={`wishlist-product-sku-${sku}`} className='mb-2 mr-lg-3'>{line.skuAlias}</div>  
    );
  }
  
  const getListPriceMarkup = (line) =>{
    return user && !user.isMilwaukeeToolEmployee() && user.canPlaceOrders() && (
      <div>
      <span className='has-font-size-1'>
        <FormattedMessage id='pricing.listPrice'/>&nbsp;
      </span>
        <span data-test-id={`wishlist-product-list-price-${sku}`} className='has-font-size-1'>
        <del>
          <ProductPrice
            pricing={props.pricing}
            sku={line.sku}
            type='listAmount'
          />
        </del>
      </span>
      </div>
    );    
  }
  
  const getYourPriceMarkup = (line) =>(
    <div>
      <div className='has-font-size-2'>
        <FormattedMessage id='pricing.yourPrice'/>
      </div>
      <div data-test-id={`wishlist-product-your-price-${sku}`} className='has-font-size-4'>
        <ProductPrice
          pricing={props.pricing}
          sku={line.sku}
          type='unitAmount'
        />
      </div>
    </div>
  )
  
  const getAvailabilityMarkup = (line) =>{
    return line.sku && (
      <div data-test-id={`wishlist-product-availability-${sku}`} className='has-font-size-2 font-weight-bold'>
        <ProductAvailability
          availability={props.availability}
          sku={line.sku}
        />
      </div>
    )
  }
  
  let deleteButtonMarkup = props.canEdit && (props.removingFromWishlist ? (
    <LoadingDots/>
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div data-test-id={`wishlist-product-remove-${sku}`} className='is-mock-link' onClick={() => handleRemoveClick()}>Remove</div>
  ));
  
  let deleteButtonRowMarkup = props.canEdit && (
    <Row className='mt-2'>
      <Col className='text-center'>
        {deleteButtonMarkup}
      </Col>
    </Row>
  );
  
  let addToCartButtonMarkup = auth.isAuthenticated() && (
    <Col xs='auto' className='mt-3 mt-xl-0'>
      <Button data-test-id={`wishlist-product-addtocart-${sku}`} outline color='primary' onClick={() => handleAddToCartClick()} isLoading={props.addingToCart || props.quantityUpdating}>
        <FormattedMessage id='search.addToCart' />
      </Button>
    </Col>
  );
  
  let quantityMarkup = (
    <QuantityInput
      quantity={quantity}
      incrementQuantity={nextValidQuantityAllowed}
      minQuantity={lowestQuantityAllowed}
      maxQuantity={highestQuantityAllowed}
      onQuantityChange={(quantity) => handleQuantityChange(quantity)}
      hideButtons={!props.canEdit}
      isDisabled={!props.canEdit}
      dataTestId={sku}
    />
  );
  
  let nextValidQuantityMarkup = nextValidQuantityAllowed > 0 && (
    <Row className='mt-2'>
      <Col>
        <div className='font-weight-light text-secondary has-font-size-2 text-center'>
          <FormattedMessage id='products.quantityMultiple' values={{nextValidQuantityAllowed: nextValidQuantityAllowed}}/>
        </div>
      </Col>
    </Row>
  );

  let minQuantityMessage = lowestQuantityAllowed > 0 && (
    <Row>
      <Col>
        <div className='font-weight-light text-secondary has-font-size-2 text-center'>
          <FormattedMessage id='products.quantityMin' values={{ lowestQuantityAllowed: lowestQuantityAllowed }} />
        </div>
      </Col>
    </Row>
  );

  let maxQuantityMessage = highestQuantityAllowed > 0 && (
    <Row>
      <Col>
        <div className='font-weight-light text-secondary has-font-size-2 text-center'>
          <FormattedMessage id='products.quantityMax' values={{ highestQuantityAllowed: highestQuantityAllowed }} />
        </div>
      </Col>
    </Row>
  );
  
  return (
    <Container data-test-id={`wishlist-product-details-${sku}`} className='wishlist-line'>
      <Row className='align-items-center animate-bottom-fade-in'>
        <Col>
          <Container>
            <Row className='mb-2 my-lg-2'>
              <Col md='auto' data-test-id={`wishlist-product-image-${sku}`} className='text-center mx-lg-2 my-lg-auto align-self-center product-image'>
                <img src={`/api/v1/images/?sku=${skuAlias}`} alt={description} />
              </Col>
              <Col className='align-self-center'>
                <Container>
                  <Row>
                    <Col lg='8' data-test-id={`wishlist-product-name-${sku}`} className='is-color-red my-2 my-lg-auto align-self-center wrap-break-words'>
                      <Link to={`products/${skuAlias}`} locale={props.locale} className='no-underline'>
                        {getSkuAliasMarkup(props.wishlistLine)}
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} className='has-line-height-2 font-weight-bold' />
                      </Link>
                    </Col>
                    <Col lg='4' xs='6' className='my-2 my-lg-0 align-self-center'>
                      {getListPriceMarkup(props.wishlistLine)}
                      {getYourPriceMarkup(props.wishlistLine)}
                      {getAvailabilityMarkup(props.wishlistLine)}
                    </Col>
                  </Row>
                  {props.wishlistLine.children?.length ? (
                    <React.Fragment>
                      {props.wishlistLine.children.map(c=>(
                        <Row key={c.id} className='mt-1'>
                          <Col lg='8' className='my-lg-auto align-self-center wrap-break-words'>
                            {getSkuAliasMarkup(c)}
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(c.description) }} className='has-line-height-2 font-weight-bold' />
                          </Col>
                          <Col lg='4' xs='6' className='my-2 my-lg-0 align-self-center'>
                            {getListPriceMarkup(c)}
                            {getYourPriceMarkup(c)}
                          </Col>
                        </Row>
                      ))}
                    </React.Fragment>
                  ): ''}
                </Container>
              </Col>
              <Col xl='auto' className='mb-2 my-lg-auto align-self-center'>
                <Container>
                  <Row className='justify-content-center'>
                    <Col xs='auto' className='mt-3 mt-xl-0'>
                      {quantityMarkup}
                    </Col>
                    {addToCartButtonMarkup}
                  </Row>
                  {deleteButtonRowMarkup}
                  {nextValidQuantityMarkup}
                  {minQuantityMessage}
                  {maxQuantityMessage}
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default WishlistLine;