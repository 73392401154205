import camelcase from 'camelcase';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {toastr} from 'react-redux-toastr';
import {Card, CardBody, Col, Container, FormGroup, Label, Row} from 'reactstrap';

import {Button, Input} from '../../components';
import RepairLineItem from './RepairLineItem';

class DefectivePartClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parts: this.props.claim.parts || [
        {
          itemNumber: '',
          quantity: 1
        }
      ],
      workOrderNumber: this.props.claim.workOrderNumber || '',
      comments: this.props.claim.comments || ''
    }
  }

  addRepairPartLineItem = () => {
    let newPart = {
      itemNumber: '',
      quantity: 1
    };
    this.setState({
      ...this.state,
      parts: [
        ...this.state.parts,
        newPart
      ]
    });
  };
  

  updateRepairPartLineItem = (index, newPart) => {
    let parts = [...this.state.parts];
    
    parts[index] = newPart;

    this.setState({
      ...this.state,
      parts
    });
  };

  removeRepairPartLineItem = (removePart) => {
    let parts = [...this.state.parts];

    parts.splice(parts.indexOf(removePart), 1);
    
    this.setState({
      ...this.state,
      parts
    });
  };
  

  handleInputChange = (e) => {
    let name = camelcase(e.name);
    this.setState({
      ...this.state,
        [name]: e.value
    });
  };

  next  = () => {
    let {
      parts,
      comments,
      workOrderNumber
    } = this.state;

    parts = parts.filter((part) => {
      return part.itemNumber.length > 0;
    });

    if (parts.length > 0) {

      this.props.next({
        comments: comments,
        parts: parts,
        workOrderNumber: workOrderNumber
      });
    } else {
      toastr.error('Please enter at least one part.');
    }
  }

  render() {
    let partsMarkup = this.state.parts 
    && this.state.parts.map((part, index) => {
      return (
        <RepairLineItem
          key={index}
          index={index}
          itemNumber={part.itemNumber}
          quantity={part.quantity}
          removeRepairPartLineItem={this.removeRepairPartLineItem}
          update={this.updateRepairPartLineItem}
        />
      );
    });
    return (
      <Container>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col sm={6}>
                  <h5 className='my-3'>Parts</h5>
                    <FormGroup tag='fieldset' data-test-id='defective-part-parts' className='mt-2 mb-0'>
                      {partsMarkup}
                    </FormGroup>
                    <FormGroup tag='fieldset' className='mt-2 mb-0'>
                      <Button data-test-id='defective-part-add-part-button' onClick={this.addRepairPartLineItem}>Add Part</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <h5 className='my-3'>Repair Notes</h5>
                    <FormGroup tag='fieldset' data-test-id='defective-part-work-order' className='mt-2 mb-0'>
                    <Label for='work-order-number'>Work Order</Label>
                      <Input 
                        type='text'
                        name='work-order-number'
                        id='work-order-number'
                        value={this.state.workOrderNumber}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='defective-part-comments' className='mt-2 mb-0'>
                      <Label for='comments'>Comments</Label>
                      <Input 
                        type='textarea'
                        name='comments'
                        id='comments'
                        value={this.state.comments}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            </Col>
            </Row>

            <Row className='justify-content-end'>
          <Col xs='auto'>
            <Button data-test-id='defective-part-next' block color='primary' onClick={this.next}>Next</Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

DefectivePartClaim.propTypes = {
  claim: PropTypes.object.isRequired,
  next: PropTypes.func
};

export default DefectivePartClaim;