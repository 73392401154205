import PropTypes from 'prop-types';
import React from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';

const RecoveryDate = ({orderLine}) => {
  if (orderLine.recoveryDate && new Date(orderLine.recoveryDate.toString()) >= Date.now()){
    return <div><FormattedDate value={orderLine.recoveryDate}/></div>;
  }
  
  return null;
};

const OrderLineAvailability = (props) => {
  if (props.group === 'backordered') {
      return (
        <div className='font-weight-bold has-font-size-2 is-color-yellow'>
          <FormattedMessage id='products.backordered'/>
          <RecoveryDate orderLine={props.orderLine} />
        </div>
      );
  }
  else if (props.group === 'other') {
    return (
      <div className='has-font-size-2 font-weight-bold is-color-blue'>
        <FormattedMessage id={`products.${props.orderLine.customAvailabilityMessage}`}/>
        {props.orderLine.customAvailabilityMessage !== "PreSale" && <RecoveryDate orderLine={props.orderLine} />}
      </div>
    );
  }
  
  return null;
};

OrderLineAvailability.propTypes = {
  group: PropTypes.string,
  orderLine: PropTypes.object
};

export default OrderLineAvailability;