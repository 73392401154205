import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Button } from '../../../components';
import history from '../../../history';

const CustomizeButton = styled(Button)`
  width: 100%;
  background-color: white;
  margin-top: 1em;
  :hover {
    background-color: #db011c;
  }
`;

const CustomizeItem = (props) => {
  
  const handleGoToCustomizerClick = () => {
    history.push(`/customizer?sku=${props.sku}`);
  };

  return props.isCustomLogo === true && (
    <div className={props.className} style={{ backgroundColor: '#e5e5e5' }}>
      <div style={{ padding: '1em' }}>
        <h5 style={{ fontSize: '1.3rem', fontWeight: '700' }}><FormattedMessage id='customLogo.customize' /></h5>
        <span style={{ fontWeight: '500' }}><FormattedMessage id='customLogo.customizeMessage' /></span>
        <div className='text-center'>
          <CustomizeButton data-test-id='go-to-customizer' outline color='primary' onClick={handleGoToCustomizerClick}>
            <FontAwesomeIcon icon='edit' style={{ paddingRight: '5px' }} />
            <FormattedMessage id='customLogo.goToCustomizer' />
          </CustomizeButton>
        </div>
      </div >
    </div>
  );
}

export default injectIntl(CustomizeItem);