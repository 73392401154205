import addressManagement from './addressManagement/fr';
import checkout from './checkout/fr';
import faq from './faq/fr';
import square from './square/fr';

export default {
  account: {
    accountNotFound: 'Compte non trouvé',
    accountLoadingError: 'Des problèmes sont survenus lors de la récupération des détails de votre compte. Vous pouvez rencontrer des difficultés lors de l’utilisation de Connect. Si le problème persiste, veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878).',
    accountImpersonationLoadingError: 'Des problèmes sont survenus lors de l\'extraction des détails du compte à imiter.'
  },
  addressManagement: addressManagement,
  announcements: {
    heading: 'Titre',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    priority: 'Priorité',
    actions: 'Actes',
    dateRange: 'Plage de dates',
    audience: 'Public',
    isBanner: 'Alerte de la page d\'accueil',
    noAnnouncementsMessage: 'Aucune annonce trouvée',
    currentAnnouncements: 'Annonces en cours',
    addButton: '+ Ajouter',
    confirmDeleteModalTitle: 'Êtes-vous sûr?',
    confirmDeleteModalBody: 'Etes-vous sûr de vouloir supprimer cette annonce? Cette action ne peut pas être annulée.',
    confirmDeleteButton: 'Je suis sûr - Supprimer',
    newAnnouncementTitle: 'Nouvelle annonce',
    updateAnnouncementTitle: 'Annonce de mise à jour',
    errorDeletingTitle: 'Erreur lors de la suppression d\'une annonce',
    errorDeletingMessage: 'Un problème est survenu lors de la suppression de l\'annonce. Veuillez réessayer plus tard.',
    errorSavingTitle: 'Erreur lors de l\'enregistrement de l\'annonce',
    errorSavingMessage: 'Un problème est survenu lors de l\'enregistrement de l\'annonce. Veuillez réessayer plus tard.',
    headingPlaceholder: 'En-tête d\'annonce',
    headingValidationMessage: 'Un en-tête d\'annonce est requis',
    summaryPlaceholder: 'Un bref résumé de l\'annonce',
    summaryValidationMessage: 'Un résumé doit comporter entre 1 et 280 caractères.',
    editorValidationMessage: 'Vous devez fournir les détails de l\'annonce',
    editorTitle: 'Détails'
  },
  brandworks: {
    sproutloudFailureTitle: 'Échec de la connexion à Sproutloud',
    pinnacleFailureTitle: 'Échec de la connexion SWAG à Milwaukee',
    flyerserviceFailureTitle: 'Echec de la connexion au service Flyer',
    ssoFailure: 'Un problème est survenu lors de l\'accès au fournisseur tiers. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878).'
  },
  breadcrumbs: {
    dashboard: 'Tableau de Bord',
    yourOrders: 'Vos Commandes',
    orderDetails: 'Détails de la Commande',
    trackPackage: 'Suivre un Colis'
  },
  cart: {
    addedToCart: 'Ajouté au panier',
    errorAddingToCart: 'Une erreur s\'est produite lors de l\'ajout de vos articles à votre panier',
    emptyCartMessage: 'Votre panier est vide!',
    addToCartToastTitle: '{quantity, plural, one {Produit} other {Produits}} ajouté au panier',
    addToCartToastMessage: '({quantity, number}) "{description}" a été ajouté à votre panier.',
    notAddedToCartToastTitle: '{quantity, plural, one {Produit} other {Produits}} non ajouté au panier',
    notAddedToCartToastMessage: 'Un problème est survenu lors de l\'ajout {quantity, plural, one {du produit} other {des produits}} à votre panier - veuillez réessayer ultérieurement.',
    errorRetreivingStatuses: 'Un problème est survenu lors de l\'extraction de la disponibilité des articles dans le panier.',
    cartExpiredTitle: 'Les prix sont valables pour 30 jours.',
    cartExpiredMessage: 'Certains/Tous les articles dans votre panier sont là depuis plus de 30 jours. Les montants seront actualisés pour refléter les prix actuels.',
    accountIssues: 'Il existe un problème avec votre compte qui vous empêche de passer à la caisse. Veuillez contacter le service client au 800-729-3878 pour résoudre ce problème.'
  },
  checkout: checkout,
  confirmation: {
    thankYou: 'Merci d\'avoir acheté chez nous',
    explanations: {
      separateShipments: 'Nous vous enverrons une confirmation avec les informations de suivi une fois que votre commande sera expédiée. Si vous avez commandé plusieurs articles, ceux-ci peuvent être expédiés séparément.',
      backordered: 'Si votre commande contient des articles en rupture de stock, vous recevrez un e-mail de confirmation d\'expédition dès que le produit sera disponible.'
    },
    needHelp: {
      title: 'Avez-vous besoin d\'aide avec votre commande?',
      howToTrack: 'Comment suivre ma commande?',
      canICancel: 'Puis-je annuler ou modifier ma commande?',
      orderArrive: 'Quand ma commande va-t-elle arriver?'
    },
    talkToSomeone: 'Parler à quelqu\'un'
  },
  customLogo: {
    title: 'Personnalisations',
    customizeProduct: 'Personnalisez votre produit',
    customizeProductDescription: `Milwaukee Tool safety vests, hard hats, and helmets can be customized with images, logos, and text. Learn more about placing a custom product order {customizerFAQLink}.`,
    faqLinkText: 'ici',
    startCustomizing: 'Commencer la personnalisation',
    customize: 'Personnaliser',
    goToCustomizer: 'Aller au personnalisateur',
    customizeMessage: 'Add your logo, name, or other artwork to item.',
    createStart: 'To view or create a new custom product design, start here',
  },
  faq: faq,
  footer: {
    copyright: '© {year} par Milwaukee Tool - Tous droits réservés',
    ccpa: 'Do Not Sell My Personal Information',
    privacy: 'Privacy'
  },
  general: {
    activate: 'Activer',
    actions: 'Actes',
    amount: 'Montante',
    areYouSure: 'Êtes-vous sûr?',
    cart: 'Chariot',
    catalogNumber: 'Catalogue #',
    comments: 'Commentaires',
    cancel: 'Annuler',
    customer: 'Client',
    date: 'Rendez-vous amoureux',
    delete: 'Effacer',
    description: 'La description',
    edit: 'Modifier',
    email: 'Email',
    endDate: 'Date de fin',
    errorToastTitle: 'Quelque chose s\'est mal passé',
    exempt: 'Exempter',
    free: 'Free',
    id: 'ID',
    invoices: 'Factures',
    item: 'Article',
    itemNumber: 'Numéro d\'article',
    linkCopiedToastTitle: 'Lien copié',
    linkCopied: 'Lien copié dans le presse-papier',
    orders: 'Ordres',
    orderDate: 'Date de commande',
    orderDetails: 'Détails de la commande',
    orderNumber: 'Numéro de commande',
    orderSummary: 'Récapitulatif de la commande',
    pending: 'En attendant',
    phone: 'Téléphone',
    purchaseDate: 'Purchase Date',
    purchaseOrderNumber: 'Numéro de commande',
    purchaseOrderNumberAbbrev: 'PO #',
    emailConfirmation: 'Confirmez votre e-mail',
    quantity: 'Quantité',
    quantityAbbr: 'Qty',
    receipt: 'Le reçu',
    refresh: 'Rafraîchir',
    total: 'Totale',
    salesTax: 'Taxe de vente',
    serialNumber: 'Serial #',
    share: 'Partager',
    shipping: 'Livraison',
    shippingCost: 'Frais de livraison',
    shippingSpeed: 'Vitesse de livraison',
    shipTo: 'Envoyez à',
    startDate: 'Date de début',
    sku: 'SKU',
    status: 'Statut',
    subtotal: 'Totale',
    successToastTitle: 'Succès!',
    unknown: 'Inconnu',
    unauthorized: 'Non autorisé',
    validationToastTitle: 'Veuillez corriger les champs obligatoires',
    viewOrder: 'Voir l\'ordre',
    customerStatementErrorTitle: 'Erreur lors de l\'obtention du relevé client',
    customerStatementErrorBody: 'Un problème est survenu lors de l\'accès à votre relevé client. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878).'
  },
  home: {
    title: 'Trouver un produit',
    orderInformationCardTitle: 'Informations sur la commande',
    whatsNewCardTitle: 'Quoi de neuf',
    announcementsCardTitle: 'Annonces',
    notificationsCardTitle: 'Les notifications',
    brandworksCardTitle: 'Marketing Quick Links',
    enterAccountNumber: 'Ingrese el número de cuenta',
    accountActivationPending: 'Activación de cuenta pendiente',
    activateAccountDescription: 'Entrez votre numéro de compte ici pour une expérience complète de Connect.',
    accountActivationRequestSent: 'Votre demande d\'activation de compte a été envoyée au support client. Vous recevrez un email une fois votre compte activé.',
    activationErrorTitle: 'Échec de l\'activation du compte',
    activationErrorDescription: 'Un problème est survenu lors de la tentative d\'activation de votre compte. Si ce problème persiste, veuillez contacter le service client.',
    autoAccountActivationTitle: 'Activation du compte terminée',
    autoAccountActivationDescription: 'L\'activation de votre compte est terminée. Dans 5 secondes, le navigateur se rafraîchira pour mettre à jour votre session.',
    autoAccountActivationFeedbackTitle: 'Activation du compte terminée',
    autoAccountActivationFeedbackDescription: 'Votre demande d\'activation de compte a été traitée. Veuillez vous déconnecter et vous reconnecter dans quelques minutes pour que les modifications prennent effet.'
  },
  invoices: {
    loadingInvoicesErrorMessage: 'Une erreur s\'est produite lors de la récupération de vos factures. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878).',
    noInvoicesMessage: 'Aucune facture trouvée',
    downloadingInvoice: 'Facture de téléchargement {invoiceId}...',
    downloadingInvoiceErrorMessage: 'Un problème est survenu lors de l\'accès à votre facture. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878).'
  },
  orders: {
    noOrdersMessage: 'Aucune commande trouvée',
    ordersFilter: 'Tout',
    openOrders: 'Open',
    backordered: 'Remis en ordre',
    cancelled: 'Annulé',
    invoicedFilter: 'Facturé',
    orderDateLabel: 'Date de ordre',
    purchaseOrderLabel: 'Bon de ordre',
    quoteNumberLabel: 'Cite un nombre',
    orderNumberLabel: 'Ordre #',
    shippingStatusLabel: 'Statut d\'expédition',
    orderProcessingMessage: 'La commande est en cours de traitement. Vous recevrez un numéro de commande et une notification par courrier électronique une fois le traitement terminé.',
    skuColumn: 'Sku',
    quantityColumn: 'Qty',
    shipTo: 'Envoyez à',
    trackPackage: 'Suivre un colis',
    packingSlip: 'Bordereau d\'emballage',
    downloadingPackingSlip: 'Téléchargement du bon de livraison...',
    packingSlipDownloadErrorBody: 'Il y a eu un problème pour obtenir votre bordereau de marchandises. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878).',
    excelDownloadErrorTitle: 'Problème de téléchargement excel',
    excelDownloadErrorBody: 'Un problème est survenu lors du téléchargement de l\'historique des commandes Excel. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878).',
    reorder: 'Réorganiser',
    reorderToastTitle: 'Produits de la commande {orderNumber} ajoutés au panier',
    reorderErrorToastTitle: 'Un problème est survenu lors de l’ajout des produits de order {orderNumber} à votre panier. Veuillez réessayer ultérieurement.',
    downloadingWarrantyClaims: 'Downloading warranty claims...',
    warrantyClaimsExcelDownloadError: 'There was a problem downloading the order history excel. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    employeeOrdersDisabled: 'En raison d\'une demande sans précédent, nous n\'acceptons pas les commandes des employés pour le moment.',
    orderLoadingError: 'Une erreur s\'est produite lors de l\'accès à la commande. Veuillez réessayer plus tard.',
    unauthorizedToView: 'Vous n\'êtes pas autorisé à consulter cette commande.'
  },
  orderSummary: {
    backorderTooltip: 'Vous ne serez pas facturé pour ces articles jusqu\'à ce qu\'ils arrivent en stock et soient expédiés',
    itemsShipped: 'Articles expédiés',
    itemsToBeShipped: 'Articles à expédier',
    itemsOnBackorder: 'Articles en rupture de stock',
    shippingToBeCalculated: 'L\'expédition est calculée lorsque les articles ont été expédiés',
    freeShipping: 'This order qualifies for free shipping',
    taxExempt: 'Cette commande est éligible au statut d\'exonération fiscale',
    taxToBeCalculated: 'La taxe sera calculée après la commande est passée'
  },
  pricing: {
    signInForPrice: 'Connectez-vous pour voir votre prix',
    netUnitPrice: 'Prix ​​Unitaire Net:',
    listPrice: 'Liste des prix:',
    unitPrice: 'Prix unitaire',
    yourPrice: 'Ton prix:',
    quantity: 'Quantité',
    extendedPrice: 'Prix ​​Prolongée:',
    extendedPriceAbbr: 'Ext Price',
    reimbursementPrice: 'Prix de remboursement',
    accountNeedsAccess: 'Votre compte n\'est pas autorisé à passer des commandes',
    selectOptions: 'Select options'
  },
  products: {
    eachWeight: 'Poids',
    totalWeight: 'Poids total',
    backordered: 'En rupture de stock',
    unavailable: 'Indisponible',
    discontinuedInStock: 'Discontinué en Stock',
    discontinuedOutOfStock: 'Discontinué en Rupture de Stock',
    inStock: 'En Stock',
    other: 'Autre',
    quantityMultiple: 'Doit être commandé en multiples de {nextValidQuantityAllowed}',
    quantityMin: 'Doit avoir un minimum de commande de {lowestQuantityAllowed}',
    quantityMax: 'Doit avoir un ordre maximum de {highestQuantityAllowed}',
    LeadTime7Days: 'Délai de 7 jours',
    LeadTime7To14Days: 'Délai de 7 à 14 jours',
    PreSale: 'Pré-vente'
  },
  productDetails: {
    keyFeatures: 'Principales Caractéristiques',
    includes: 'Comprend',
    youMightAlsoLike: 'Vous Pourriez Aussi Aimer'
  },
  publications: {
    downloadingFile: 'Téléchargement {filename}...',
    effectiveDate: 'efficace {date}',
    partsPricingTitle: 'Prix des pièces',
    partsPricingDescription: 'Les fichiers d’outils et d’accessoires contiennent le numéro de catalogue, la description, la liste et les prix nets, la quantité de paquet maître, le code UPC, les dimensions du produit (LxWxH) et le poids. Les fichiers de pièces de rechange contiennent le numéro de pièce, la description et le prix catalogue.',
    toolAndAccessoryPrices: 'Prix des outils et accessoires',
    servicePartsPrices: 'Prix des pièces de rechange',
    iMap: 'IMAP',
    iMapPolicy: 'IMAP Policy',
    servicePublicationsTitle: 'Publications de service',
    technicalPublicationsTitle: 'Publications techniques',
    technicalPublicationsDescription: 'Les bulletins de la liste des pièces de rechange, les instructions de câblage et les manuels de l’opérateur peuvent généralement être obtenus ici.',
    technicalPublicationsButton: 'Recherche de publications techniques',
    lightningMaxRepairPriceSheetTitle: 'Grille tarifaire des Lightning Max Repair',
    lightningMaxRepairPriceSheetDescription: 'Liste de prix du programme de Lightning Max Repair pour l’étalonnage, la maintenance et les commandes de service non couvertes par la garantie.',
    thirtyDayExclusionListTitle: 'Liste d’exclusion de 30 jours',
    thirtyDayExclusionListDescription: 'Liste des articles exclus de notre politique de retour de satisfaction sous 30 jours.',
    disclaimerTitle: 'Avertissement',
    disclaimerDescription: 'Les prix et les informations contenus dans ces fichiers sont sujets à changement sans préavis. Veuillez contacter le service clientèle mondial au 1-800-SAWDUST (1-800-729-3878) pour toute question relative aux pièces de rechange. Nous apprécions votre activité et votre partenariat continu avec nous pour répondre aux besoins de nos clients.',
    documentSearchTitle: 'ONE KEY and MX FUEL Service Parts Publications and Videos',
    documentSearchDescription: 'Enter model number to search for SPLs and training material in the field below',
    documentTableName: 'Name',
    documentTableDate: 'Date Uploaded',
    documentTableDownload: 'Download',
    sales: 'Sales Policy',
    internet: 'Internet Distributor Policy',
    coOpPolicies: 'Co-Op Advertising Allowance Policy',
    termsOfSale: 'Terms of Sale',
    salesPolicies: 'Milwaukee Sales Policies'
  },
  search: {
    addToCart: 'Ajouter au Panier',
    removeFromCart: 'Retirer',
    searchPlaceholder: 'Nom du produit ou #',
    sizes: 'Tailles',
    colors: 'Couleurs',
    selectAColor: 'S\'il vous plaît sélectionner une couleur d\'abord'
  },
  square: square,
  track: {
    carrier: 'Transporteur',
    trackingNumber: 'Numéro de Suivi',
    eta: 'Date de Livraison Estimée',
    preTransit: 'Pré-Transit',
    inTransit: 'En Transit',
    outForDelivery: 'En Cours de Livraison',
    delivered: 'Livré',
    trackingNotAvailable: 'Le suivi n\'est actuellement pas disponible pour cet envoi',
    shipped: 'Expédié'
  },
  warranty: {
    activeWarrantyClaims: 'Réclamations de garantie active',
    totalWarrantyClaims: 'Réclamations totales de garantie',
    autoApprovalViolation: {
      valueThreshold: 'La valeur de la pièce dépasse le seuil',
      laborHoursThreshold: 'Les heures de travail dépassent le seuil',
      obsoleteItem: 'Contient un article obsolète',
      repairDurationThreshold: 'La durée de réparation dépasse le seuil',
      partQuantityThreshold: 'La quantité de pièce dépasse le seuil',
      freightAllowanceThreshold: 'L\'indemnité de fret dépasse le seuil',
      reviewSkuPrefix: 'Toujours vérifier le préfixe de SKU',
      reviewDefectivePartClaim: 'Toujours examiner la réclamation de pièce défectueuse',
      initialViolation: 'Initial Auto Approve Violations',
      discontinuedPart: '{itemNumber} is discontinued',
      replacementPart: '{itemNumber} has been replaced with {replacementSku}',
      backOrderedPart: '{itemNumber} is backordered',
      backOrderedPartWithDate: '{itemNumber} is backordered til {date}'
    },
    claimStatus: {
      draft: 'Brouillon',
      pending: 'en attendant',
      pendingInitialReview: 'En attente de l\'examen initial',
      approved: 'Approuvée',
      approvedWithChanges: 'Approuvé avec modifications',
      rejected: 'Rejetée',
      pendingSubsequentReview: 'Examen subséquent en attente',
      denied: 'Refusée',
      pendingAutoApproval: 'En attente d\'approbation automatique'
    },
    partLineType: {
      warranty: 'Garantie',
      recall: 'Rappel',
      goodwill: 'Bonne volonté'
    },
    shippedType: {
      notAvailable: 'Indisponible',
      customerDropOffOrPickup: 'Dépose/ramassage du client',
      companyTruck: 'Camion d\'entreprise',
      carrierFreight: 'Transporteur commun/fret'
    },
    partsValidation: {
      notInCatalog: '{itemNumber} n\'est pas dans notre catalogue en ligne',
      invalidTool: '{itemNumber} n\'est pas valide en tant qu\'outil sur une demande de garantie',
      invalidComponent: '{itemNumber} n\'est pas valide en tant que composant d\'une demande de garantie',
      unauthorizedRepair: 'Service Center n\'est pas autorisé à réparer {itemNumber}',
      flatRatePriceNotSet: 'There is a problem with the model number you entered. Please confirm the model number matches the tool you are repairing. Should you need additional assistance please email warrantyclaims@milwaukeetool.com include the model number you are trying to enter in your email.'
    },
    noClaimsMessage: 'Aucune demande de garantie trouvée',
    accountSelection: 'Sélection du compte',
    eligibility: 'Admissibilité',
    logistics: 'Logistique',
    repairDetails: 'Détails de réparation',
    customerDetails: 'Détails du client',
    claimReview: 'Examen de réclamation',
    claimConfirmation: 'Confirmation de réclamation',
    commentsPlaceholder: 'Ajouter des commentaires supplémentaires',
    pricingMessage: 'Enregistrer les modifications pour obtenir les prix',
    claimInformation: 'Claim Information',
    expenseSummary: 'Expense Summary',
    repairNotes: 'Repair Notes',
    ascInformation: 'ASC Information',
    customerInformation: 'Customer Information',
    repairInformation: 'Repair Info',
    photos: 'Photos',
    productInformation: 'Product Information',
    reviewInformation: 'Review Information',
    shipping: {
      information: 'Shipping Information',
      batteryNotice: 'NOTICE: Any claims for Batteries require shipping the Batteries back to Milwaukee Tool for inspection.',
      batteryPrint: 'Please print out the documents below and follow the instructions to ship the Batteries to Milwaukee Tool.',
      labelPrint: 'Print Shipping Label and Instructions'
    },
    review: {
      errorTitle: 'Erreur de soumission de la demande',
      requireComments: 'Veuillez fournir des commentaires expliquant vos modifications',
      invalidStatusChange: 'Le statut ne peut pas changer de {oldStatus} à {newStatus}'
    },
    newComment: {
      errorTitle: 'Une erreur est survenue',
      errorMessage: 'Une erreur est survenue lors de l\'envoi de vos commentaires. Veuillez réessayer ultérieurement.'
    },
    submissionError: {
      dateReceivedOutOfRange: 'La date de réception doit être dans moins d\'un an',
      dateRepairedOutOfRange: 'La date de réception doit être dans moins d\'un an',
      dateReturnedOutOfRange: 'La date de réception doit être dans moins d\'un an',
      freightAllowanceRequired: 'Indemnité de fret requise',
      primaryPartRequired: 'Partie primaire requise',
      productSkuRequired: 'Numéro de catalogue requis',
      receivedViaRequired: 'Reçu via obligatoire',
      returnedViaRequired: 'Retourné via requis',
      warrantyLaborRequired: 'Travail sous garantie requis',
      warrantyLaborOutOfRange: 'Le travail sous garantie doit être compris entre 0 et 100',
      notCertifiedAccurate: 'Veuillez certifier que les données sont exactes',
      notCertifiedSafety: 'Veuillez certifier que tous les tests et toutes les vérifications ont été effectués',
      returnShippingIncomplete: 'S\'il vous plaît inclure le fournisseur d\'expédition de retour et le numéro de suivi',
      serialNumberRequired: 'Le numéro de série est requis si lisible',
      duplicateParts: 'Il y a des pièces en double sur votre demande, veuillez retirer les copies pour continuer',
      warrantyLaborPrecision: 'La main-d\'œuvre sous garantie doit être arrondie au dixième près',
      laborHoursExceededForToolReplacement: 'Le nombre d’heures de travail dépasse le temps alloué pour le remplacement des outils. Seulement 0.3 est autorisé pour les remplacements d\'outils.',
      onlyToolAllowedAsPart: 'Les demandes de remplacement d’outil ne peuvent avoir l’outil répertorié en tant que pièce',
      receiptRequired: 'Un reçu est requis pour les demandes portant des numéros de série illisibles.',
      purchaseDateRequired: 'La date d\'achat est requise pour les demandes portant des numéros de série illisibles.',
      noCostWarrantyClaim: 'La réclamation au titre de la garantie doit porter sur un montant en dollars supérieur à zéro. Veuillez vérifier et ajuster vos pièces et valeurs.',
      freightAllowanceGreaterThanDailyAmount: 'Maximum de {amountRemaining} restant pour les livraisons de camions de la société à ce client le jour choisi.',
      customerIncomplete: 'Les informations client sont incomplètes',
      notAcceptingBatteryClaims: 'Battery Claims not allowed at this time',
      skuDoesNotExist: 'Sku {itemNumber} does not exist',
      skuIsNotEligible: 'Sku {itemNumber} n\'est pas éligible pour une réclamation de pièce défectueuse',
      partNotInCatalog: 'Certaines pièces ne sont pas au catalogue',
      toolNotValidClaim: 'La revendication d\'outil n\'est pas valide',
      componentNotValidClaim: 'Le composant de revendication n\'est pas valide',
      accountNotAuthorized: 'Gamme de produits non autorisée pour ce compte',
      flatRatePriceNotSet: 'Le prix forfaitaire n\'est pas fixé pour cet article'
    },
    button: {
      saveChanges: 'Save Changes',
      addPart: 'Add Part',
      newClaim: 'New Claim',
      addComment: 'Add Comment',
      resubmit: 'Resubmit',
      deny: 'Deny',
      reject: 'Reject',
      approve: 'Approve',
      download: 'Download',
      confirm: 'Confirmer'
    },
    archive: {
      archiveModalTitle: 'Archiver la demande de remplacement',
      archiveModalBody: 'Voulez-vous vraiment archiver cette demande de remplacement?',
      unarchiveModalTitle: 'Désarchiver la demande de remplacement',
      unarchiveModalBody: 'Voulez-vous vraiment désarchiver cette demande de remplacement?',
      archiveSuccessToastTitle: 'Demande de remplacement Archivée',
      archiveSuccessToastMessage: 'Demande de remplacement # {claimNumber} a été archivée',
      unarchiveSuccessToastTitle: 'Demande de remplacement Désarchivée',
      unarchiveSuccessToastMessage: 'Demande de remplacement # {claimNumber} a été désarchivée',
      errorToastTitle: 'Une erreur est survenue',
      archiveErrorToastMessage: 'Une erreur s\'est produite lors de l\'archivage de cette demande de remplacement, veuillez réessayer plus tard',
      unarchiveErrorToastMessage: 'Une erreur s\'est produite lors du désarchivage de cette demande de remplacement, veuillez réessayer plus tard',
    }
  },
  warrantyAutoApproveFilters: {
    currentFilters: 'Filtres d’approbation automatique actifs',
    errorAddingFilterTitle: 'Erreur lors de l\'ajout du filtre',
    errorAddingMessage: 'Un problème est survenu lors de l\'ajout du filtre. Veuillez réessayer plus tard.',
    errorSavingFilterTitle: 'Erreur d\'enregistrement des modifications',
    errorSavingMessage: 'Un problème est survenu lors de la sauvegarde du filtre. Veuillez réessayer plus tard.',
    errorDeletingFilterTitle: 'Erreur lors de la suppression du filtre',
    errorDeletingMessage: 'Un problème est survenu lors de la suppression du filtre. Veuillez réessayer plus tard.',
    errorFilterTypeAndValueRequired: 'Les champs de type et de valeur sont obligatoires pour enregistrer un filtre',
    confirmDeleteModalTitle: 'Êtes-vous sûr?',
    confirmDeleteModalBody: 'Êtes-vous sûr de vouloir supprimer ce filtre? Cette action ne peut pas être annulée.',
    confirmDeleteButton: 'Je suis sûr - Supprimer',
    actions: 'Actes',
    type: 'Type',
    value: 'Valeur'
  },
  contact: {
    title: 'Contactez Nous',
    yourInfo: 'Vos Informations',
    yourCompanyInfo: 'Renseignements Sur Votre Entreprise',
    custService: 'Service Clients',
    generalPhone: 'Téléphone général',
    fax: 'Fax',
    address: 'Adresse',
    emailForm: 'Formulaire email',
    subject: 'Assujettir',
    selectSubject: 'Sélectionnez un Sujet',
    comments: 'Commentaires',
    userInstructions1: 'Un représentant de Milwaukee Tool répondra à votre demande le jour ouvrable suivant.',
    userInstructions2: 'Lorsque vous commandez du matériel publicitaire ou de la littérature (catalogues, DPL, etc.), veuillez spécifier la quantité et les articles que vous souhaitez recevoir. Les matériaux seront expédiés sur le compte auquel vous êtes actuellement connecté. Modifiez-les au besoin. Cela ne changera pas les informations dans notre système, juste le nom et l\'emplacement de cette demande.',
    sentReqItemsTo: 'Envoyer les articles demandés à',
    custName: 'Nom du client',
    companyName: 'Nom de la compagnie',
    zipCode: 'Code postal',
    city: 'Ville',
    state: 'état ou province',
    phone: 'Téléphone',
    email: 'Email',
    optional: 'Optionnel',
    changeShippingInfo: 'Modifier les informations de livraison',
    thanksForYourRequst: 'Merci pour votre requête.',
    repWillRespond: 'Un représentant de Milwaukee Tool répondra à votre demande d\'ici le prochain jour ouvrable.',
    subjectInventory: 'Inventaire',
    subjectOrderDpl: 'Commande Dpl',
    subjectPricing: 'Prix',
    subjectOrderStatus: 'Statut de la commande',
    subjectInvoice: 'Facture d\'achat',
    subjectAccountBalance: 'Solde du compte',
    subjectProdTech: 'Produit/Technique',
    subjectProductService: 'Service de produit',
    subjectAdvertLitReq: 'Demandes de documentation publicitaire',
    subjectWarrantyClaims: 'Réclamations de garantie',
    errorSubmittingForm: 'Erreur de soumission du formulaire.'
  },
  quickAdd: {
    title: 'Ajout rapide',
    invalidProduct: '{skuOrEachUpc} est introuvable ou n\'est pas valide',
    invalidQuantity: 'Quantité saisie invalide',
    invalidQuantityNextQuantityAllowed: '. Doit être commandé par multiples de {nextQuantityAllowed}',
    invalidQuantityLowestQuantityAllowed: '. Doit avoir une commande minimum de {lowestQuantityAllowed}.',
    validationMessage: 'Veuillez entrer un SKU ou Each UPC valide',
    productPlaceholder: 'SKU ou Each UPC',
    goToCart: 'Aller au panier',
    multipleSkusMessage: 'Plusieurs SKU trouvés pour ce produit: {productSkus}',
    errorQuickAddToCart: 'Une erreur s\'est produite lors de l\'ajout d\'un nouveau produit au panier'
  },
  wishlists: {
    noWishlists: 'Vous n\'avez pas de liste de courses!',
    emptyWishlist: 'Votre liste d\'achats est vide!',
    addedToWishlistTitle: 'Ajouté à la liste d\'achats',
    addedToWishlist: '"{description}" a été ajouté à votre liste d\'achats',
    errorAddingToWishlist: 'Une erreur s\'est produite lors de l\'ajout de {description} à votre liste d\'achats.',
    removedFromWishlistTitle: 'Liste de courses mise à jour',
    wishlistLineAddedToCartTitle: 'Ajouté au panier',
    wishlistLineAddedToCart: '{description} a été ajouté à votre panier!',
    errorAddingWishlistLineToCart: 'Une erreur s\'est produite lors de l\'ajout de {description} à votre panier.',
    removedFromWishlist: '{description} a été retiré de votre liste d\'achats!',
    errorRemovingFromWishlist: 'Une erreur s\'est produite lors de la suppression de {description} de votre liste d\'achats.',
    wishlistUpdatedTitle: 'Liste de courses mise à jour',
    wishlistUpdated: 'Votre liste de courses a été mise à jour!',
    wishlistAlreadyExistsTitle: 'La liste d\'achats existe déjà',
    wishlistAlreadyExists: 'Une liste de courses avec ce nom existe déjà',
    errorUpdatingWishlist: 'Une erreur est survenue lors de la mise à jour de votre liste de courses',
    wishlistDeletedTitle: 'Liste de courses supprimée',
    wishlistDeleted: 'Votre liste de courses a été supprimée!',
    errorDeletingWishlist: 'Une erreur s\'est produite lors de la suppression de votre liste de courses',
    wishlistCreatedTitle: 'Liste de courses créée',
    wishlistCreated: 'Votre liste de courses a été créée!',
    errorCreatingWishlist: 'Une erreur est survenue lors de la création de votre liste de courses',
    wishlistAddedToCartTitle: 'Ajouté au panier',
    wishlistAddedToCart: 'Vos articles de liste de courses ont été ajoutés à votre panier!',
    errorAddingWishlistToCart: 'Une erreur s\'est produite lors de l\'ajout de votre liste de courses à votre panier.',
    wishlistSubtotal: 'Sous-total de la liste d\'achats',
    yourWishlists: 'Vos listes de courses',
    wishlistDetails: 'Détails de la liste d\'achat',
    editWishlist: 'Modifier la liste d\'achat',
    createWishlist: 'Créer un nouveau',
    errorUpdatingQuantity: 'Une erreur s\'est produite lors de la mise à jour de la quantité',
    errorRemovingFromSavedForLater: 'Une erreur est survenue lors de la suppression de {description} de votre nom enregistré pour plus tard.'
  },
  restrictedItems: {
    warningForDeletingRestrictedItemsFromCart: 'Vous aviez des articles restreints dans votre panier qui ont été supprimés.Veuillez revoir votre panier avant de soumettre.'
  },
  creditCard: {
    confirmDeleteModalTitle: 'Supprimer la carte',
    confirmDeleteModalBody: 'Êtes-vous sûr de vouloir supprimer votre carte se terminant par {cardLast4}?',
    confirmDeleteButton: 'Supprimer la carte',
  }
}
