const faq = {
  accounts: {
    howToSeeAccounts: {
      question: 'Comment puis-je voir tous mes comptes Milwaukee Connect?',
      answer: 'Pour pouvoir accéder à tous les comptes, vous devez être configuré sous le compte parent et désigné comme administrateur sur le compte. Veuillez contacter CXHelp@milwaukeetool.com pour obtenir de l\'aide.'
    },
    howToLocateAccount: {
      question: 'Comment trouver mon numéro de compte Milwaukee Connect?',
      answer: 'Pour connaître votre numéro de compte, veuillez contacter l\'équipe Expérience client au 800-729-3878.'
    },
    multipleContactNumbers: {
      question: 'Puis-je fournir plusieurs numéros de contact?',
      answer: 'Vous pouvez avoir deux numéros de téléphone. un deuxième numéro peut être ajouté dans les paramètres de votre compte. Vous pouvez également suivre ces instructions:' +
        '[Cliquez sur l\'icône de la personne dans le coin supérieur droit.||' +
        'Vous verrez un menu, cliquez sur "Compte".||' +
        'Vous serez dirigé vers votre profil de compte.||' +
        'Recherchez la première section intitulée "Informations personnelles". Elle devrait être ouverte et indiquer vos informations de contact. Si ce n\'est pas ouvert, cliquez sur la barre qui dit "informations personnelles" pour afficher les informations de contact||' +
        'Il y aura deux champs pour entrer un numéro de téléphone, l\'un intitulé "Numéro de téléphone" (ce champ peut déjà être renseigné) et à la droite de ce numéro, un champ appelé "Téléphone portable".||' +
        'Vous pouvez ajouter le deuxième numéro dans le champ "Téléphone portable"; il n\'est pas nécessaire que ce soit un numéro de téléphone cellulaire.||' +
        'Une fois terminé, faites défiler vers le bas de la page et cliquez sur le bouton "Enregistrer les modifications".]'
    },
    howToCreateAccount: {
      question: 'Comment créer un compte?',
      answer: 'Pour créer un compte, veuillez suivre les instructions suivantes:' +
        '[Dans le coin supérieur droit de la page, cliquez sur "Connexion".||' +
        'Une fenêtre s’ouvrira avec deux onglets.||' +
        'Sélectionnez l\'onglet nommé "Inscription".||' +
        'Remplissez le formulaire, tous les champs sont obligatoires.||' +
        'Un email sera envoyé à l\'équipe CX ou à la comptabilité.||' +
        'Ils vont activer votre compte.||' +
        'Vous recevrez un email d\'activation.||' +
        'Une fois qu\'un e-mail d\'activation a été reçu, allez à Connexion et cliquez sur le lien "Connexion" en haut à droite.]'
    },
    howToResetPassword: {
      question: 'Comment réinitialiser mon mot de passe?',
      answer: 'Si vous avez oublié votre mot de passe, suivez ces instructions:' +
        '[Dans le coin supérieur droit de la page, cliquez sur "Connexion".||' +
        'Une fenêtre s’ouvrira avec deux onglets.||' +
        'Dans l\'onglet affiché ("Connexion"), il y a un lien nommé "Vous ne vous souvenez pas de votre mot de passe?".||' +
        'Cliquez sur "Vous ne vous souvenez pas de votre mot de passe?"||' +
        'Entrez votre adresse email.||' +
        'Cliquez sur le bouton "Envoyer un email".||' +
        'Vous serez ramené à l\'onglet "Connexion".||' +
        'Accédez à votre client de messagerie et recherchez un courrier électronique à accounts@milwaukeetool.com.]'
    }
  },
  orderingOrders: {
    howToPriceAvailability: {
      question: 'Accédez à votre client de messagerie et recherchez un courrier électronique à accounts@milwaukeetool.com.',
      answer: 'Pour obtenir le prix et la disponibilité d\'un produit:' +
        '[Entrez un numéro de produit ou un nom dans le champ de recherche "Rechercher un produit"||' +
        'Vous serez dirigé vers une page de résultats de recherche indiquant le produit que vous avez recherché ou une gamme de produits si vous avez recherché un nom de produit.||' +
        'Trouvez le produit pour lequel vous souhaitez un prix, le prix et la disponibilité seront affichés avec les informations sur le produit.||' +
        'Les prix et la disponibilité sont également présentés sur les pages de détail du produit. Si vous cliquez sur le nom ou le numéro du produit dans les résultats de la recherche, vous accédez à la page de détails du produit.]'
    },
    howToPricingSchedule: {
      question: 'Comment localiser mon barème de prix sur Milwaukee Connect 2.0?',
      answer: 'Malheureusement, cette fonctionnalité n\'est pas disponible pour le moment. Veuillez contacter le service client au 800-729-3878 pour obtenir votre barème de prix.'
    },
    applyPCE: {
      question: 'Puis-je appliquer les PCE à ma commande Milwaukee Connect 2.0?',
      answer: 'Malheureusement, cette fonctionnalité n\'est pas disponible pour le moment. Veuillez envoyer votre commande par courrier électronique à METOrders@milwaukeetool.com. Le PCE référencé sur votre bon de commande sera appliqué lors de la saisie de la commande, le cas échéant.'
    },
    employeePayment: {
      question: 'Puis-je payer ma commande sur Milwaukee Connect 2.0? (Employé)',
      answer: 'Pas en ce moment. Veuillez contacter Customer Experience au 800-729-3878 dans les 5 jours ouvrables suivant votre commande pour payer votre commande.'
    },
    locateTrackingNumber: {
      question: 'Comment puis-je localiser le numéro de suivi de ma commande?',
      answer: 'Un numéro de suivi sera attribué une fois la commande expédiée. Vous recevrez un email indiquant que votre commande a été expédiée. Une fois que vous avez reçu cet email, vous pouvez cliquer sur le lien dans l\'email pour afficher le suivi. Vous pouvez également suivre ces instructions (rappelez-vous, il n\'y aura pas de suivi jusqu\'à ce que votre commande soit expédiée):' +
        '[Connectez-vous à Connect.||' +
        'Cliquez sur "Commandes" dans la navigation principale pour accéder au tableau de bord des commandes clients.||' +
        'Recherchez la commande sur laquelle vous souhaitez connaître les informations de suivi.||' +
        'Cliquez sur le "numéro de commande" (il commencera par "AX").||' +
        'Dans la section "Articles de cette commande", un bouton permettra de vérifier le suivi des articles expédiés.]'
    },
    howToStatusUpdates: {
      question: 'Comment puis-je voir et recevoir les mises à jour de statut pour ma commande?',
      answer: 'Vous recevrez un courrier électronique pour la confirmation de commande, la commande expédiée et la commande livrée. Si vous n\'avez pas reçu les emails, vérifiez votre dossier spam. Ces informations sont également disponibles sur le tableau de bord des commandes client:' +
        '[Connectez-vous à Connect.||' +
        'Cliquez sur "Commandes" dans la navigation principale pour accéder au tableau de bord des commandes clients.||' +
        'Recherchez la commande dont vous souhaitez connaître le statut.||' +
        'Cliquez sur le "numéro de commande" (il commencera par "AX").||' +
        'Dans la section "Articles de cette commande", il vous indiquera le statut des produits de la commande.]'
    },
    canIPickup: {
      question: 'Puis-je entrer la commande et aller la chercher à ma succursale locale?',
      answer: 'Malheureusement, vous ne pouvez pas récupérer les commandes dans votre agence locale. Toutes les commandes seront expédiées à partir de notre principal centre de distribution situé à Olive Branch, MS.'
    },
    canSalesRepsOrder: {
      question: 'Mon représentant peut-il entrer des commandes en mon nom?',
      answer: 'Votre représentant commercial ne peut pas passer de commande en votre nom. Toutefois, votre représentant peut vous fournir un devis et vérifier l’état des commandes pour vous.'
    },
    receivedWrongProduct: {
      question: 'Que dois-je faire si j\'ai reçu un produit en erreur? Et si j\'ai reçu le mauvais produit?',
      answer: 'Veuillez contacter le service clientèle de Milwaukee à l’adresse CXHelp@milwaukeetool.com avec une photo du produit reçu, numéro de commande, numéro de commande. Une vérification de la corbeille sera lancée et un numéro de commande de remplacement sera communiqué une fois que l\'inventaire aura été confirmé exact.'
    },
    whenWillOrderShip: {
      question: 'Quand ma commande sera-t-elle expédiée?',
      answer: 'Les commandes sont traitées dans notre centre de distribution selon la méthode du premier entré, premier sorti. Veuillez vous reporter à votre tableau de bord des commandes client pour d\'autres mises à jour de livraison. Votre commande arrivera dans les limites de ces estimations de livraison en fonction de votre emplacement et de la vitesse d\'expédition sélectionnée. Vous recevrez également un e-mail lorsque votre commande sera expédiée. Pour accéder au tableau de bord des commandes client:' +
        '[Connectez-vous à Connect.||' +
        'Cliquez sur "Commandes dans la navigation principale."||' +
        'Recherchez la commande dont vous souhaitez connaître le statut.||' +
        'Cliquez sur le "numéro de commande" (il commencera par "AX").||' +
        'Vous serez dirigé vers la page Détails de la commande.||' +
        'Il montrera le statut des produits dans cet ordre.]'
    }
  },
  invoicingStatements: {
    howToLocateInvoice: {
      question: 'Comment visualiser (télécharger) une facture?',
      answer: 'Pour télécharger votre facture:' +
        '[Connectez-vous à Connect||' +
        'Dans le menu principal, cliquez sur "Factures"||' +
        'Vous serez dirigé vers la page des factures.||' +
        'Pour trouver la facture que vous recherchez, vous pouvez utiliser les filtres de date et / ou la fonction de recherche pour trouver des factures spécifiques.||' +
        'Une fois que vous avez trouvé la facture, cliquez sur l\'icône de téléchargement et votre facture sera téléchargée sur votre appareil.]'
    },
    howToCustomerStatement: {
      question: 'Comment puis-je télécharger un "relevé client"?',
      answer: 'Pour télécharger votre relevé client:' +
        '[Connectez-vous à Connect.||' +
        'Dans le menu principal, cliquez sur "Factures".||' +
        'Vous serez dirigé vers la page des factures.||' +
        'Il y aura un bouton appelé "Déclarations client".||' +
        'Cliquez dessus et le fichier sera téléchargé sur votre appareil.]'
    },
    howToCreditMemo: {
      question: 'How can I retrieve my credit memo?',
      answer: 'To download your credit memo:' +
        '[Connectez-vous à Connect.||' +
        'Dans le menu principal, cliquez sur "Factures".||' +
        'Vous serez dirigé vers la page des factures.||' +
        'Pour trouver la facture que vous recherchez, vous pouvez utiliser les filtres de date et / ou la fonction de recherche pour trouver des factures spécifiques.||' +
        'Trouvez le lien vers "Avoir" dans la facture que vous recherchez.||' +
        'Cliquez dessus et le fichier sera téléchargé sur votre appareil.]'
    }
  },
  warranty: {
    noCatalogNumber: {
      question: 'Que faire si le numéro de catalogue ne peut pas être saisi sur une demande de garantie?',
      answer: '[[Vous entrez peut-être un numéro de service restreint.||' +
        'Les résultats de la recherche vous indiqueront si un numéro de catalogue est couvert par la garantie. Si un numéro de catalogue n\'est pas couvert, vous pouvez toujours le faire réparer via eService.||' +
        'Veuillez vérifier si vous entrez le bon numéro d\'article.]]'
    },
    noPartNumber: {
      question: 'Que faire si le numéro de pièce ne peut pas être entré dans une réclamation de garantie?',
      answer: '[[Veuillez vous assurer que vous entrez un numéro de pièce de service; les accessoires ne peuvent pas être entrés.||' +
        'Si la pièce a un numéro de pièce de service valide et qu\'elle n\'est toujours pas acceptée, veuillez contacter votre responsable de la garantie.]]'
    },
    rejectedClaim: {
      question: 'Si une demande est rejetée que dois-je faire?',
      answer: 'Lorsqu\'une réclamation est rejetée, votre responsable de la garantie a besoin de plus d\'informations avant que votre réclamation puisse être approuvée. Veuillez suivre ces étapes pour une demande rejetée:' +
        '[Connectez-vous à Connect.||' +
        'Cliquez sur "Service" dans la navigation principale.||' +
        'Un menu apparaîtra - sélectionnez "Demandes de garantie".||' +
        'Recherchez la demande rejetée.||' +
        'Cliquez sur le bouton "Afficher" pour la demande rejetée.||' +
        'Faites défiler vers le bas de la page et recherchez la section des commentaires.||' +
        'Lisez les commentaires du responsable de la garantie.||' +
        'Répondez à toutes les questions dans la zone de texte et soumettez-les.||' +
        'La réclamation sera ensuite réexaminée.]'
    },
    pendingClaimStatus: {
      question: 'Pourquoi ma demande de garantie de batterie est-elle toujours en attente?',
      answer: 'Veuillez appeler ou envoyer un courriel à votre responsable de service. Vous devrez leur fournir le numéro EW et le numéro de suivi indiquant que la batterie a été livrée à Greenwood, MS. Votre responsable de la garantie répondra avec une réponse au problème.'
    },
    defectiveParts: {
      question: 'Que dois-je faire si des pièces sont défectueuses en dehors de l\'emballage?',
      answer: 'Vous devrez remplir une "Réclamation de nouvelle pièce défectueuse". Veuillez suivre ces étapes:' +
        '[Connectez-vous à Connect.||' +
        'Cliquez sur "Service" dans la navigation principale.||' +
        'Un menu apparaîtra - sélectionnez "Demandes de garantie".||' +
        'Sélectionnez l\'onglet "Nouvelle réclamation de pièce".||' +
        'Remplissez le formulaire et envoyez-le.]'
    }
  },
  manualsPartLists: {
    howToLatestDPL: {
      question: 'Comment trouver le dernier DPL (liste de prix des distributeurs)?',
      answer: 'La liste de prix des distributeurs se trouve sur la page Tarification et publications. Veuillez suivre ces étapes:' +
        '[Connectez-vous à Connect..||' +
        'Cliquez sur "Service" dans la navigation principale.||' +
        'Un menu apparaîtra - sélectionnez "Tarification/Publications".||' +
        'Vous serez dirigé vers la page Tarification et publications.||' +
        'Sous "Pièces et prix", trouvez "Outils et accessoires" et cliquez sur le bouton ci-dessous.||' +
        'Le fichier sera téléchargé sur votre appareil.]'
    },
    howToPartsListManuals: {
      question: 'Comment puis-je obtenir la liste de pièces ou un manuel pour mon outil?',
      answer: 'Les listes de pièces et les manuels se trouvent sur la page Tarification et publications. Veuillez suivre ces étapes:' +
        '[Connectez-vous à Connect.||' +
        'Cliquez sur "Service" dans la navigation principale.||' +
        'Un menu apparaîtra - sélectionnez "Tarification/Publications".||' +
        'Vous serez dirigé vers la page Tarification et publications.||' +
        'Localisez la section "Publications techniques".||' +
        'Cliquez sur le bouton "Rechercher des publications techniques" pour accéder à la page Manuels et publications de milwaukeetool.com.]'
    }
  }
};

export default faq;