import React from 'react';
import {FormattedDate} from 'react-intl';
import Icon from '../../components/Icon';

const PublicationRow = (props) => {
  const {publication, handleDownload} = props;

  const onClick = () => {
    handleDownload(publication.fileName);
  }

  let icon;
  const filetype = publication.fileName.split('.')[1];
  switch(filetype) {
    case 'mp4':
      icon = <Icon type='video' className='download-file' size='sm' color='black' onClick={onClick} />;
      break;
    default:
      icon = <Icon type='file-download' className='download-file' size='sm' color='black' onClick={onClick} />;
      break;
  }

  return (
    <tr>
      <td>{publication.fileName}</td>
      <td><FormattedDate value={new Date(publication.lastModifiedDate)}/></td>
      <td>{icon}</td>
    </tr>
  )
}

export default PublicationRow;