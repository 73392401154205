import {Breadcrumb, BreadcrumbItem} from '@met/react-components';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { Fade } from 'react-awesome-reveal';
import {Link} from 'react-router-dom';
import {Container} from 'reactstrap';

import {TilesLoader} from '../../../components';
import {companyAdminActions} from '../../../store';
import {UserForm} from '../components';

const CompanyAdminEditUser = (props) => {
  const {userId} = props.match.params;
  const dispatch = useDispatch();
  
  // app state
  const users = useSelector(state => state.companyAdmin.users);
  const user = useSelector(state => state.companyAdmin.user);
  
  // local state
  const [userLoading, setUserLoading] = useState(!users);

  /***
   * We only need to fetch the user if we haven't already loaded it in our list of users from the previous page
   */
  useEffect(() => {
    if (!users) {
      dispatch(companyAdminActions.getUser(userId))
        .catch(() => toastr.error('Something went wrong', 'Unable to retrieve user details at this time. Please try again later.'))
        .finally(() => setUserLoading(false));
    }
  }, []);
  
  /***
   * handleSaveClick will update the user in auth0
   * @param user: the user to edit
   */
  const handleSaveClick = (user) => {
    return dispatch(companyAdminActions.updateUser(userId, user))
      .then(() => toastr.success('User Updated', 'The user details have been updated.'))
      .catch(() => toastr.error('Something went wrong', 'Unable to save user details at this time. Please try again later.'));
  }

  return (
    <Container>
      <Fade>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to='/companyadmin'>Company Admin Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            Edit User
          </BreadcrumbItem>
        </Breadcrumb>
      </Fade>
      {
        !userLoading && (
          <Fade>
            <UserForm
              user={users ? users.find(u => u.userId === userId) : user}
              onSaveClick={handleSaveClick}
            />
          </Fade>
        )
      }
      {userLoading && <TilesLoader />}
    </Container>
  )
};

export default CompanyAdminEditUser;