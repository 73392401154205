import PropTypes from 'prop-types';
import React, {Component} from 'react';
import DOMPurify from 'dompurify';
import {FormattedMessage, injectIntl} from 'react-intl';
import {toastr} from 'react-redux-toastr';
import {Col, Container, Row} from 'reactstrap';

import {Button, Link, Price, QuantityInput} from '../../../components';
import user from '../../../helpers/user';
import OrderLineAvailability from './OrderLineAvailability';

class OrderLinePreview extends Component {
  constructor(props){
    super(props);

    this.state = {
      quantity: this.props.orderLine.quantity,
      isRemoving: false
    };
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;
    
    if (prevProps.orderLine.quantity !== this.props.orderLine.quantity) {
      this.setState({
        ...this.state,
        quantity: this.props.orderLine.quantity
      });
    }
  }

  handleQuantityChange = (quantity) => {
    this.props.onQuantityChange(this.props.orderLine, quantity);

    this.setState({
      ...this.state,
      quantity
    });
  };

  handleRemoveFromCartClick = () => {
    this.setState({
      ...this.state,
      isRemoving: true
    }, () => {
      this.props.onRemoveFromCartClick(this.props.orderLine)
        .then(() => {
          this.setState({
            ...this.state,
            isAdding: false
          });
        });
    });
  };

  handleAddToSaveForLaterClick = () => {
    this.props.onSaveForLaterClick(this.props.orderLine)
      .then(() => {
        toastr.success('Item Saved', 'Item was saved for later!');
      });
  };

  render() {
    const { orderLine } = this.props;   

    const renderListPrice = (line) => {
      return user && !user.isMilwaukeeToolEmployee() && (
        <div>
          <span className='has-font-size-1'>
            <FormattedMessage id='pricing.listPrice' />&nbsp;
            <del data-test-id={`cart-list-price-${orderLine.sku}`}>
              <Price
                value={line.listPrice}
                isLoading={line.listPrice === null}
              />
            </del>
          </span>
        </div>
      );
    }

    const renderYourPrice = (line) => {
      return (
        <div>
          <div className='has-font-size-2'>
            <FormattedMessage id='pricing.yourPrice' />
          </div>
          <div data-test-id={`cart-your-price-${orderLine.sku}`} className='has-font-size-4'>
            <Price
              value={line.unitPrice}
              isLoading={line.unitPrice === null}
            />
          </div>
        </div>
      );
    }

    let extendedPrice = (
      <div>
        <div className='has-font-size-2'>
          <FormattedMessage id='pricing.extendedPrice' />
        </div>
        <div data-test-id={`cart-extended-price-${orderLine.sku}`} className='has-font-size-4'>
          <Price
            value={orderLine.unitPrice * this.state.quantity}
            isLoading={orderLine.unitPrice === null}
          />
        </div>
      </div>
    );

    let eachWeight = this.props.group === "inStock" && (
      <div>
        <div className='has-font-size-2'>
          <FormattedMessage id='products.eachWeight' />
        </div>
        <div data-test-id={`cart-weight-${orderLine.sku}`} className='has-font-size-4'>
          {orderLine.weight ? orderLine.weight.toFixed(2) : null}
        </div>
      </div>
    );

    let totalWeight = this.props.group === "inStock" && (
      <div>
        <div className='has-font-size-2'>
          <FormattedMessage id='products.totalWeight' />
        </div>
        <div data-test-id={`cart-total-weight-${orderLine.sku}`} className='has-font-size-4'>
          {orderLine.weight ? (orderLine.weight * this.state.quantity).toFixed(2) : null}
        </div>
      </div>
    );

    let quantityInput = user.canPlaceOrders() && (
      <QuantityInput 
        quantity={this.state.quantity} 
        minQuantity={orderLine.lowestQuantityAllowed} 
        maxQuantity={orderLine.highestQuantityAllowed} 
        incrementQuantity={orderLine.nextValidQuantityAllowed} 
        onQuantityChange={this.handleQuantityChange}
        dataTestId={this.props.orderLine.sku}
      />
    );
    
    const getSkuAliasMarkup = (line) =>{
      return line.skuAlias !== line.description && (
        <div data-test-id={`cart-product-sku-${orderLine.sku}`} className='mb-2 mr-lg-3'>{line.skuAlias}</div>
      );
    }
    
    const calcTotalPrice = () =>{
      let total = orderLine.unitPrice * this.state.quantity;
      
      orderLine.children.forEach(c=>{
        total += c.unitPrice * c.quantity
      })
      
      return total;      
    }

    return (
      <Container data-test-id={`cart-product-details-${orderLine.sku}`} className='order-line-preview'>
        <Row className='align-items-center animate-bottom-fade-in'>
          <Col>
            <Container>
              <Row className='mb-2 my-lg-2'>
                <Col md='auto' data-test-id={`cart-product-image-${orderLine.sku}`} className='text-center mx-lg-2 my-lg-auto align-self-center product-image'>
                  <img src={`/api/v1/images/?sku=${orderLine.skuAlias}`} alt={orderLine.description}/>
                </Col>
                <Col className='align-self-start'>
                  <Container>
                    <Row>
                      <Col lg='6' className='is-color-red my-2 my-lg-0 align-self-start wrap-break-words'>
                        <Link to={`products/${orderLine.skuAlias}`} locale={this.props.locale} className='no-underline'>
                          {getSkuAliasMarkup(orderLine)}
                          <div data-test-id={`cart-product-name-${orderLine.sku}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(orderLine.description)}} className='has-line-height-2 font-weight-bold'/>
                        </Link>
                      </Col>
                      <Col lg='3' xs='6' className='my-2 my-lg-0 align-self-center'>
                        {renderListPrice(orderLine) && (<Row>
                          {renderListPrice(orderLine)}
                        </Row>)}
                        <Row>
                          {renderYourPrice(orderLine)}
                        </Row>
                        <Row>
                          {eachWeight}
                        </Row>
                          <OrderLineAvailability group={this.props.group} orderLine={orderLine} />
                      </Col>
                      <Col lg='3' xs='6' className='my-2 my-lg-0 align-self-center'>
                        {renderListPrice(orderLine) && (<Row>
                          <span>
                            &nbsp;
                          </span>
                        </Row>)}
                        <Row>
                          {extendedPrice}
                        </Row>
                        <Row>
                          {totalWeight}
                        </Row>
                      </Col>
                    </Row>
                    {orderLine.children?.length ? (
                      <React.Fragment>
                        {orderLine.children.map(c=>(
                          <Row key={c.id} className='mt-3'>
                            <Col lg='6' className='my-2 my-lg-0 align-self-start wrap-break-words'>
                              {getSkuAliasMarkup(c)}
                              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(c.description)}} className='has-line-height-1 font-weight-bold'/>
                              {c.customLogoRefId?.length > 0 && (
                                <div data-test-id={`cart-configurator-id-${c.customLogoRefId}`} className='has-line-height-1 my-2'>
                                  {`Configurator ID: ${c.customLogoRefId}`}
                                </div>
                              )}
                            </Col>
                            <Col lg='3' xs='6' className='my-2 my-lg-0 align-self-center'>
                              {renderListPrice(c) && (<Row>
                                {renderListPrice(c)}
                              </Row>)}
                              <Row>
                                {renderYourPrice(c)}
                              </Row>
                            </Col>
                            <Col lg='3' xs='6' className='my-2 my-lg-0 align-self-center'>
                              {renderListPrice(c) && (<Row>
                                <span>
                                  &nbsp;
                                </span>
                              </Row>)}
                              <Row>
                                <span className='has-font-size-2'>
                                  <FormattedMessage id='pricing.extendedPrice'/>
                                </span>
                                </Row>
                              <Row>
                                <span data-test-id={`cart-extended-price-${orderLine.sku}`} className='has-font-size-4'>
                                <Price
                                  value={c.unitPrice * this.state.quantity}
                                  isLoading={c.unitPrice === null}
                                />
                                </span>
                            </Row>
                            </Col>
                          </Row>
                          ))}
                        <Row className='mt-3'>
                          <Col lg='9' xs='6' className='my-2 my-lg-auto align-self-center wrap-break-words'>
                            Total
                          </Col>
                          <Col lg='3' xs='6' className='my-2 my-lg-0 align-self-center'>                            
                            <span data-test-id={`cart-total-price-${orderLine.sku}`}  className='has-font-size-4'>
                                <Price
                                  value={calcTotalPrice()}
                                  isLoading={orderLine.unitPrice === null}
                                />
                              </span>
                          </Col>
                        </Row>
                      </React.Fragment>
                      ): ''}
                  </Container>
                </Col>
                <Col xl='auto' className='mb-2 my-lg-auto align-self-center'>
                  <Container>
                    <Row className='justify-content-center'>
                      <Col xs='auto' className='mt-3 mt-xl-0'>
                        {quantityInput}
                      </Col>
                      <Col xs='auto' data-test-id={`cart-remove-${orderLine.sku}`} className='mt-3 mt-xl-0'>
                        <Button outline color='primary' onClick={this.handleRemoveFromCartClick} isLoading={this.state.isRemoving}>
                          <FormattedMessage id='search.removeFromCart'/>
                        </Button>
                      </Col>
                    </Row>
                    <Row className='mt-2'>
                      <Col data-test-id={`cart-save-${orderLine.sku}`} className='text-center'>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div className='is-mock-link' onClick={this.handleAddToSaveForLaterClick}>Save For Later</div>
                      </Col>
                    </Row>
                    <Row className='mt-3 mt-sm-1'>
                      <Col>
                        {orderLine.nextValidQuantityAllowed > 0 && (<div className='font-weight-light text-secondary has-font-size-2 text-center'>
                          <FormattedMessage id='products.quantityMultiple' values={{ nextValidQuantityAllowed: orderLine.nextValidQuantityAllowed }} />
                        </div>)}
                        {orderLine.lowestQuantityAllowed > 0 && (<div className='font-weight-light text-secondary has-font-size-2 text-center'>
                          <FormattedMessage id='products.quantityMin' values={{ lowestQuantityAllowed: orderLine.lowestQuantityAllowed }} />
                        </div>)}
                        {orderLine.highestQuantityAllowed > 0 && (<div className='font-weight-light text-secondary has-font-size-2 text-center'>
                          <FormattedMessage id='products.quantityMax' values={{ highestQuantityAllowed: orderLine.highestQuantityAllowed }} />
                        </div>)}
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>        
      </Container>
    );
  }
}

OrderLinePreview.propTypes = {
  orderLine: PropTypes.object.isRequired,
  availability: PropTypes.object,
  onRemoveFromCartClick: PropTypes.func,
  onSaveForLaterClick: PropTypes.func,
  onQuantityChange: PropTypes.func,
  locale: PropTypes.string,
  group: PropTypes.string
};

export default injectIntl(OrderLinePreview);