import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import  styled  from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Button,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@met/react-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import { getCardExpired } from '../../../helpers/creditCardHelper';

const LabelIcon = styled(Icon)`
  margin-right: .25em;
  vertical-align: -0.3em
`;

const Message = styled.div`
  text-align: center;
`;

const CardExp = styled.span`
  color: #db011c !important;
`;

const cardBrandsMap = {
  VISA: {
    icon: 'cc-visa',
    color: '#122d98'
  },
  MASTERCARD: {
    icon: 'cc-mastercard',
    color: '#ff9900'
  },
  AMERICANEXPRESS: {
    icon: 'cc-amex',
    color: '#006fcf'
  },
  DISCOVER: {
    icon: 'cc-discover',
    color: '#ff6000'
  },
  DISCOVERDINERS: {
    icon: 'cc-diners-club',
    color: '#3c8db5'
  },
  JCB: {
    icon: 'cc-jcb',
    color: '#333399'
  },
  UNIONPAY: {
    icon: 'credit-card'
  },
  DEFAULT: {
    icon: 'credit-card'
  }
};

const getCardBrandConfig = (cardBrand) => (cardBrandsMap[cardBrand] || cardBrandsMap.DEFAULT);

const CreditCard = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { card } = props;

  const handleDelete = () => {
    props.deleteCard(card.id);
  }

  const toggleDeletionConfirmation = () => {
    setIsDeleting(!isDeleting);
  }

  const getCardExpiredDisplay = (card) => {
    return getCardExpired(card) ? 
      ` Expired ${String(card.cardExpMonth).padStart(2, '0')}/${String(card.cardExpYear).slice(-2)}` : '';
  };

  return (
    <Fragment>
      <Row style={{'alignItems': 'center' }}>
        <Col xs={2} >
          <LabelIcon sm type={getCardBrandConfig(card.cardBrand).icon} />
        </Col>
        <Col xs={8}>
          <span>
            {`${card.cardholderName}'s card ending in ${card.cardLast4}`}
            <CardExp>
              {getCardExpiredDisplay(card)}
            </CardExp>
          </span>
        </Col>
        <Col xs={2}>
          <FontAwesomeIcon className='is-mock-link text-secondary mx-2' icon='trash-alt' onClick={toggleDeletionConfirmation} />
        </Col>
      </Row>
      <Modal hidden={!isDeleting} toggle={toggleDeletionConfirmation} centered>
        <ModalContent>
          <ModalHeader>
            <FontAwesomeIcon color='#F8A522' icon='triangle-exclamation' size='sm' />
            <FormattedMessage id='creditCard.confirmDeleteModalTitle'/>
          </ModalHeader>
          <ModalBody>
            <Message>
              <FormattedMessage id='creditCard.confirmDeleteModalBody' values={{ cardLast4: card.cardLast4 }} />
            </Message>
          </ModalBody>
          <ModalFooter>
            <Button primary outline onClick={toggleDeletionConfirmation}>
              <FormattedMessage id='general.cancel'/>
            </Button>
            <Button primary onClick={handleDelete}>
              <FormattedMessage id='creditCard.confirmDeleteButton'/>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}

CreditCard.propTypes = {
  card: PropTypes.object,
  deleteCard: PropTypes.func
};

export default injectIntl(CreditCard);