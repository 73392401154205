import React from 'react';
import { injectIntl } from 'react-intl';
import { Card, CardBody, ListGroup, ListGroupItem, Container, Row, Col } from 'reactstrap';
import { wishlistLineGrouper } from "../../../helpers/wishlistLineGrouper";
import SaveForLaterLinePreview from './SaveForLaterLinePreview';
import SaveForLaterLinePreviewGrid from './SaveForLaterLinePreviewGrid';
import { Button } from '../../../components';

const SaveForLater = (props) => {
  let saveForLaterRows = [];
  
  if (props.saveForLaterLines) {
    let lines = wishlistLineGrouper(props.saveForLaterLines);
    
    saveForLaterRows = lines.map((saveForLaterLine, index) => {
      let previewMarkup = (
        <SaveForLaterLinePreview
          saveForLaterLine={saveForLaterLine}
          useGridView={props.useGridView}
          locale={props.locale}
          isProcessing={props.isProcessing}
          pricing={props.pricing}
          availability={props.availability}
          handleDeleteClick={() => props.handleDeleteClick(saveForLaterLine)}
          handleMoveToCartClick={() => props.handleMoveToCartClick(saveForLaterLine)}
        />
      );

      let previewGridMarkup = (
        <SaveForLaterLinePreviewGrid
          saveForLaterLine={saveForLaterLine}
          useGridView={props.useGridView}
          locale={props.locale}
          isProcessing={props.isProcessing}
          pricing={props.pricing}
          availability={props.availability}
          handleDeleteClick={() => props.handleDeleteClick(saveForLaterLine)}
          handleMoveToCartClick={() => props.handleMoveToCartClick(saveForLaterLine)}
        />
      );

      return (
        <ListGroupItem key={`save-for-later-line-${index}`} className={props.useGridView ? 'cart-list-item-grid' : 'cart-list-item'}>
          {props.useGridView ? previewGridMarkup : previewMarkup}
        </ListGroupItem>
      );
    });
  }
  
  return (
    <Container>
      <Row className='mb-3 mt-3 align-items-end'>
        <Col xs='auto'>
          <h5 style={{ marginTop: '8px' }}>
            {`Saved For Later${(props.groupName ? ` - ${props.groupName}` : '')}`}
          </h5>
        </Col>
        <Col xs sm='auto' className='align-self-center text-right text-md-left'>
          <Button data-test-id='move-all-to-cart' outline color='primary' onClick={(e) => props.addGroupClick(props.saveForLaterLines?.map(l => l.id).join(','))}>{`Move ${(props.groupName ? 'Group' : 'All')} To Cart`}</Button>
        </Col>
      </Row>
      <Card>
        <CardBody className='p-0'>
          <ListGroup className='cart-list'>
            {saveForLaterRows}
          </ListGroup>
        </CardBody>
      </Card>
    </Container>
  );
};

export default injectIntl(SaveForLater);