import auth0 from 'auth0-js';
import appInsights from '../helpers/appInsights';
import dynatrace from '../helpers/dynatrace'
import { getValueAndToLower } from '../helpers/keyHelpers';
import { clearAll, getSession, updateProfile, updateSession } from '../helpers/localStorage';
import tealium from '../helpers/tealium';
import oneTrust from '../helpers/oneTrust';
import history from '../history';

const soapNamespace = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims';
const microsoftNamespace = 'http://schemas.microsoft.com/ws/2008/06/identity/claims';
const milwaukeetoolNamespace = 'https://milwaukeetool.com:milwaukeetool';

export default class Auth {
  auth;
  tokenRenewalTimeout;

  constructor() {
    this.scheduleRenewal();
  }

  getAndSetAuthConfig = async () => {
    window.localStorage.setItem('auth0:callback', window.location.pathname + window.location.search);
    return await fetch('/api/v1/configuration')
      .then(async response => {
        return response.json()
      })
      .then(async config => {
        let session = {
          auth0: {
            domain: config.data.auth0.domain,
            clientId: config.data.auth0.clientId,
            callbackUrl: (window.location.origin ? window.location.origin + '/callback' : config.data.auth0.callbackUrl),
            audience: config.data.auth0.audience
          },
          digitalLibrary: {
            url: config.data.digitalLibrary.url
          },
          heavyDutyUniversity: {
            url: config.data.heavyDutyUniversity.url
          },
          accountsUrl: {
            url: config.data.accountsUrl.url
          },
          quickLinkUrl: {
            url: config.data.quickLinkUrl.url
          },
          catalogUrl: {
            url: config.data.catalogUrl.url
          }
        };
        updateSession(session);

        return session;
      });
  }

  login() {
    this.getAndSetAuthConfig()
      .then((session) => {
        this.auth = new auth0.WebAuth({
          domain: session.auth0.domain,
          clientID: session.auth0.clientId,
          redirectUri: session.auth0.callbackUrl,
          audience: session.auth0.audience,
          responseType: 'token',
          scope: 'openid profile connect email'
        });

        this.auth.authorize();
      });
  }

  handleAuthResult = async (authResult, skipRedirect = false) => {
    this.setSession(authResult);

    await this.auth.client.userInfo(authResult.accessToken, async (err, user) => {
      if (oneTrust.canLogAI()) {
        appInsights.setAuthenticatedUserContext(
          user[`${milwaukeetoolNamespace}:user_id`],
          user[`${milwaukeetoolNamespace}:customer-account-number`]
        );
      }

      await fetch('/api/v1/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authResult.accessToken}`
        },
        body: JSON.stringify({
          phone: user[`${soapNamespace}/homephone`]
        })
      }).then(async response => {
        if (!response.ok) {
          history.replace('/'); // TODO - send to some 'we are currently having difficulties' page
        }

        tealium.logEvent({
          event_action: 'login',
          event_category: 'authentication',
          event_name: 'milwaukee connect',
          user_email: user[`email`],
          user_account_number: user[`${milwaukeetoolNamespace}:customer-account-number`]
        });

        dynatrace.identifyUser(user[`name`]);

        const getNickname = () => {
          if (!!user[`${soapNamespace}/givenname`] && !!user[`${soapNamespace}/surname`]) {
            return user[`${soapNamespace}/givenname`] + ' ' + user[`${soapNamespace}/surname`]
          }
          if (user['nickname']) {
            return user['nickname'];
          }
          return undefined;
        }

        let profile = {
          email: user[`name`],
          nickname: getNickname(),
          firstname: user[`${soapNamespace}/givenname`],
          lastname: user[`${soapNamespace}/surname`],
          roles: user[`${microsoftNamespace}/role`],
          accountNumber: user[`${milwaukeetoolNamespace}:customer-account-number`],
          userId: user[`${milwaukeetoolNamespace}:user_id`],
          permissions: {
            canPlaceOrders: getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:can-place-orders`) === 'true',
            canImpersonateUsers: getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:can-impersonate-users`) === 'true',
            canQuoteOtherAccounts: getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:quote-other-accounts-claims`) === 'all'
              || getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:quote-other-accounts-claims`) === 'assigned',
            canApproveWarrantyClaims: getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:warranty-claims`) === 'approve',
            canViewWarrantyClaims: getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:warranty-claims`) === 'view'
              || getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:warranty-claims`) === 'create'
              || getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:warranty-claims`) === 'approve',
            canCreateWarrantyClaims: getValueAndToLower(user, `${milwaukeetoolNamespace}:connect:warranty-claims`) === 'create'
          }
        };
        updateProfile(profile);

        let callback = window.localStorage.getItem('auth0:callback');

        if (!skipRedirect) {
          if (callback) {
            history.replace(callback);
            window.localStorage.removeItem('auth0:callback');
          } else
            history.replace('/');
        }
      });
    });
  }

  handleAuthentication = () => {
    let session = getSession();

    this.auth = new auth0.WebAuth({
      domain: session.auth0.domain,
      clientID: session.auth0.clientId,
      redirectUri: session.auth0.callbackUrl,
      audience: session.auth0.audience,
      responseType: 'token',
      scope: 'openid profile connect email'
    });

    this.auth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.handleAuthResult(authResult);
      } else if (err) {
        history.replace('/');
      }
    });
  };

  setSession = (authResult) => {
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    updateSession({ accessToken: authResult.accessToken, expiresAt: expiresAt });

    // schedule a token renewal
    this.scheduleRenewal();
  };

  logout = () => {
    // Clear Access Token and ID Token from local storage
    let session = getSession();
    clearAll();

    // don't renew the token anymore
    clearTimeout(this.tokenRenewalTimeout);

    // navigate to the home route
    if (session?.auth0) {
      window.location = `https://${session.auth0.domain}/v2/logout?returnTo=${window.location.origin}&client_id=${session.auth0.clientId}`;
    }
  };

  isAuthenticated = () => {
    // Check whether the current time is past the 
    // Access Token's expiry time
    let session = getSession();
    let expiresAt = session.expiresAt;
    return new Date().getTime() < expiresAt;
  };

  renewToken = () => {
    let session = getSession();

    if (session?.auth0) {
      this.auth = new auth0.WebAuth({
        domain: session.auth0.domain,
        clientID: session.auth0.clientId,
        redirectUri: session.auth0.callbackUrl,
        audience: session.auth0.audience,
        responseType: 'token',
        scope: 'openid profile connect email'
      });

      this.auth.checkSession({}, (err, result) => {
        if (err) {
          this.logout();
        } else {
          this.setSession(result);
        }
      });
    }
  };

  checkForExistingSession = async (onDoneChecking) => {
    return await this.getAndSetAuthConfig()
      .then(async session => {
        this.auth = new auth0.WebAuth({
          domain: session.auth0.domain,
          clientID: session.auth0.clientId,
          responseType: 'token',
          redirectUri: window.location.origin ? window.location.origin + '/callback' : session.auth0.callbackUrl,
        });

        await this.auth.checkSession({ audience: session.auth0.audience, scope: 'openid profile connect email' }, async (err, authResult) => {
          if (authResult && authResult.accessToken) {
            await this.handleAuthResult(authResult, true);
          }
          onDoneChecking();
        });
      });
  };

  scheduleRenewal = () => {
    let session = getSession();
    let expiresAt = session.expiresAt;

    if (expiresAt) {
      const delay = expiresAt - Date.now();
      if (delay > 0) {
        this.tokenRenewalTimeout = setTimeout(() => {
          this.renewToken();
        }, delay);
      }
    }
  };

  getProfile = (callback) => {
    let session = getSession();

    this.auth = new auth0.WebAuth({
      domain: session.auth0.domain,
      clientID: session.auth0.clientId,
      redirectUri: session.auth0.callbackUrl,
      audience: session.auth0.audience,
      responseType: 'token',
      scope: 'openid profile'
    });

    this.auth.client.userInfo(session.accessToken, (err, profile) => {
      let userProfile = {
        nickname: profile[`${soapNamespace}/givenname`] + ' ' + profile[`${soapNamespace}/surname`],
        firstname: profile[`${soapNamespace}/givenname`],
        lastname: profile[`${soapNamespace}/surname`]
      };

      updateProfile(userProfile);
      callback(err, userProfile);
    });
  };
}