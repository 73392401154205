import { Button, Card, CardBody, CardHeader, Col, RadioButtons, Row, useToggle } from '@met/react-components';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AsyncSelect from 'react-select/lib/Async';

import { Address } from '../../../components/Address';
import { AddressFormModal } from '../../../components/AddressForm';

import NoShippingAddresses from "./NoShippingAddresses";
import ShippingAddressRadioOption from './ShippingAddressRadioOption';

const ShippingAddress = (props) => {
  const [accountAddress, setAccountAddress] = useState(props.address);
  const [addressModalHidden, addressToggle] = useToggle(true);
  const [dropShipAddress, setDropShipAddress] = useState(null);
  const [dropShipAddressModalHidden, dropShipToggle] = useToggle(true);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const filterBranches = filter => {
    return new Promise(resolve => {
      let arr = Enumerable.from(props.accountChildren).where(x => `${x.name} - ${x.address.addressLine1} ${x.address.city}, ${x.address.state} ${x.address.postalCode}`.toLowerCase().includes(filter.toLowerCase())).toArray();
      resolve(arr);
    });
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value);
    setAccountAddress({ ...value.address, shipToName: value.name });
    setDropShipAddress(null);

    if (props.onBranchChange) {
      props.onBranchChange(value);
    }
  };

  const handleSaveDropShipAddressClick = (address) => {
    let returnValue = props.onSaveClick(address);
    setSelectedBranch(null);
    setDropShipAddress(address);

    if (props.onBranchChange) {
      props.onBranchChange({});
    }
    return returnValue;
  };

  let addressMarkup = null;

  // Employees get to choose their shipping address
  if (props.selectShippingAddressEnabled) {
    let addressOptions;

    // if we have addresses, render a radio button list
    if (props.userAddresses && props.userAddresses.length > 0) {
      let radioOptions = props.userAddresses.map(value => {
        return {
          value: value.id,
          label: (
            <ShippingAddressRadioOption
              shipToName={accountAddress.shipToName}
              address={value}
              states={props.states}
              countries={props.countries}
              onSaveClick={props.onSaveClick}
              onDeleteClick={props.onDeleteClick}
            />
          )
        };
      });

      addressOptions = (
        <Fragment>
          <RadioButtons
            selectedValue={props.selectedAddress.id}
            onRadioButtonSelect={props.onSetSelectedAddress}
            name='shipping-address'
            options={radioOptions}
          />
        </Fragment>
      );
    }
    else {
      addressOptions = <NoShippingAddresses />;
    }

    addressMarkup = (
      <Fragment>
        <Row>
          <Col>
            {addressOptions}
          </Col>
        </Row>
        <Row>
          <Col xs='auto'>
            <Button data-test-id='checkout-add-address' secondary onClick={addressToggle}>Add Address</Button>
          </Col>
        </Row>
        <AddressFormModal
          states={props.states}
          countries={props.countries}
          hidden={addressModalHidden}
          toggle={addressToggle}
          onSaveClick={props.onSaveClick}
        />
      </Fragment>
    );
  }
  else {
    addressMarkup = (
      <Fragment>
        {props.accountChildren && props.accountChildren.length > 0 && (
          <Row data-test-id='ship-to-branch' className='mb-3'>
            <Col>
              <div className='has-font-size-2 font-weight-bold mr-2'>SHIP TO BRANCH</div>
              <AsyncSelect
                className='react-select-container'
                classNamePrefix='react-select'
                cacheOptions
                defaultOptions
                value={selectedBranch}
                loadOptions={filterBranches}
                isSearchable={true}
                getOptionValue={(option) => option.accountNumber}
                getOptionLabel={(option) => `${option.name} - ${option.address.addressLine1} ${option.address.city}, ${option.address.state} ${option.address.postalCode}`}
                onChange={handleBranchChange}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col data-test-id='drop-ship-address'>
            <div className='font-weight-bold'>{dropShipAddress ? 'Drop Ship Address' : accountAddress.shipToName}</div>
            <Address
              line1={(dropShipAddress || accountAddress).addressLine1}
              line2={(dropShipAddress || accountAddress).addressLine2}
              city={(dropShipAddress || accountAddress).city}
              state={(dropShipAddress || accountAddress).state}
              postalCode={(dropShipAddress || accountAddress).postalCode}
              countryCode={(dropShipAddress || accountAddress).country}
            />
          </Col>
          {props.addDropShipAddressEnabled && (<Col xs='auto'>
            <Button secondary data-test-id='add-drop-ship-address' onClick={dropShipToggle}>Add Drop Ship Address</Button>
          </Col>)}
        </Row>
        <AddressFormModal
          states={props.states}
          countries={props.countries}
          hidden={dropShipAddressModalHidden}
          toggle={dropShipToggle}
          onSaveClick={handleSaveDropShipAddressClick}
          title={'Add Drop Ship Address'}
          addAttentionTo={true}
        />
      </Fragment>
    );
  }

  return (
    <Card>
      <CardHeader>
        <FormattedMessage id='checkout.shippingAddressTitle' />
      </CardHeader>
      <CardBody>
        {addressMarkup}
      </CardBody>
    </Card>
  );
};

ShippingAddress.propTypes = {
  address: PropTypes.object,
  accountChildren: PropTypes.array,
  onBranchChange: PropTypes.func,
  userAddresses: PropTypes.array,
  selectShippingAddressEnabled: PropTypes.bool,
  states: PropTypes.arrayOf(Object),
  countries: PropTypes.arrayOf(Object),
  onSaveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onSetSelectedAddress: PropTypes.func,
  addDropShipAddressEnabled: PropTypes.bool
};

export default ShippingAddress;