import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Card, Col, Container, Form, FormGroup, Label, Row} from 'reactstrap';
import { Button, RadioButtons } from '@met/react-components';
import { CalendarInput, Input, Link, RichTextEditor } from '../../../components';
import user from '../../../helpers/user';

class AnnouncementEditor extends Component {
  constructor(props){
    super(props);

    this.headingValidationRegex = /.+/;
    this.summaryValidationRegex = /^.{1,280}$/s;
    this.detailsValidationRegex = /.+/;

    this.state = {
      heading: {
        value: this.props.heading || '',
        isValid: (this.props.heading && new RegExp(this.headingValidationRegex).test(this.props.heading)) || undefined
      },
      summary: {
        value: this.props.summary || '',
        isValid: (this.props.summary && new RegExp(this.summaryValidationRegex).test(this.props.summary)) || undefined
      },
      details: {
        value: this.props.details || '',
        isValid: (this.props.details && new RegExp(this.detailsValidationRegex).test(this.props.details)) || undefined
      },
      theme: 'snow',
      priority: this.props.priority || 'low',
      audience: this.props.audience || 'all',
      isBanner: this.props.isBanner?.toString() || 'false',
      endDate: this.props.endDate || new Date(),
      startDate: this.props.startDate || new Date(),
      isSaving: false
    };
  }

  handleDetailsChange = (value, isValid) => {
    this.setState({
      ...this.state,
      details: {
        value,
        isValid
      }
    });
  };

  handleHeadingChange = (obj) => {
    this.setState({
      ...this.state,
      heading: {
        value: obj.value,
        isValid: obj.isValid
      }
    });
  };

  handleSummaryChange = (obj) => {
    this.setState({
      ...this.state,
      summary: {
        value: obj.value,
        isValid: obj.isValid
      }
    });
  };

  handlePrioritySelect = (value) => {
    this.setState({
      ...this.state,
      priority: value
    });
  };

  handleAudienceSelect = (value) => {
    this.setState({
      ...this.state,
      audience: value
    });
  };

  handleIsBannerSelect = (value) => {
    this.setState({
      ...this.state,
      isBanner: value
    });
  };

  handleStartDateChange = (selectedDay) => {
    if (!selectedDay)
      selectedDay = new Date();

    let endDate;
    if (selectedDay > this.state.endDate) {
      endDate = selectedDay
    }
    else {
      endDate = this.state.endDate
    }

    this.setState({
      ...this.state,
      startDate: selectedDay,
      endDate
    });
  };

  handleEndDateChange = (selectedDay) => {
    if (!selectedDay)
      selectedDay = new Date();

    let startDate;
    if (selectedDay < this.state.startDate) {
      startDate = selectedDay
    }
    else {
      startDate = this.state.startDate
    }

    this.setState({
      ...this.state,
      endDate: selectedDay,
      startDate
    });
  };

  validateForm = () => {
    return this.state.heading.isValid && this.state.summary.isValid && this.state.details.isValid;
  };

  handleSaveButtonClick = () => {
    let announcement = {
      announcementId: this.props.announcementId,
      heading: this.state.heading.value,
      summary: this.state.summary.value,
      details: this.state.details.value,
      priority: this.state.priority,
      audience: this.state.audience,
      isBanner: this.state.isBanner,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    if (this.props.onSaveClick) {
      this.setState({
        ...this.state,
        isSaving: true
      }, () => {
        this.props.onSaveClick(announcement);
      });
    }
  };
  
  render() {
    const {intl} = this.props;
    return (
      <Container>
        <Row>
          <Col>
            <Card body>
              <Container>
                <Row>
                  <Col>
                    <h4>{this.props.title}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <FormGroup>
                        <Label for='heading' className='font-weight-bold'>
                          <FormattedMessage id='announcements.heading' />
                        </Label>
                        <Input
                          id='heading'
                          placeholder={intl.formatMessage({id: 'announcements.headingPlaceholder'})}
                          value={this.state.heading.value}
                          validationRegex={this.headingValidationRegex}
                          validationMessage={intl.formatMessage({id: 'announcements.headingValidationMessage'})}
                          isValid={this.state.heading.isValid}
                          onChange={this.handleHeadingChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for='summary'><span className='font-weight-bold'>Summary</span> <span className='has-font-size-1'>({280-this.state.summary.value.length}/280)</span></Label>
                        <Input
                          type='textarea'
                          name='text'
                          id='summary'
                          placeholder={intl.formatMessage({id: 'announcements.summaryPlaceholder'})}
                          value={this.state.summary.value}
                          validationRegex={this.summaryValidationRegex}
                          validationMessage={intl.formatMessage({id: 'announcements.summaryValidationMessage'})}
                          isValid={this.state.summary.isValid}
                          onChange={this.handleSummaryChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for='exampleText' className='font-weight-bold'>
                          <FormattedMessage id='announcements.editorTitle' />
                        </Label>
                        <RichTextEditor
                          onChange={this.handleDetailsChange}
                          html={this.state.details.value}
                          validationRegex={this.state.isBanner ? null : /.+/}
                          validationMessage={intl.formatMessage({id: 'announcements.editorValidationMessage'})}
                        />
                      </FormGroup>
                      <Container>
                        <Row>
                          <Col>
                            <FormGroup>
                              <div className='font-weight-bold'>
                                <FormattedMessage id='announcements.priority' />
                              </div>
                              <RadioButtons
                                name='priority-options'
                                onRadioButtonSelect={this.handlePrioritySelect}
                                selectedValue={this.state.priority}
                                options={[
                                  {value: 'low', label: 'Low'},
                                  {value: 'medium', label: 'Medium'},
                                  {value: 'high', label: 'High'},
                                  {value: 'critical', label: 'Critical'}
                                ]}/>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <div className='font-weight-bold'>
                                <FormattedMessage id='announcements.audience' />
                              </div>
                              <RadioButtons
                                name='audience-options'
                                onRadioButtonSelect={this.handleAudienceSelect}
                                selectedValue={this.state.audience}
                                options={[
                                  {value: 'all', label: 'All'},
                                  {value: 'asc', label: 'Authorized Service Centers'}
                                ]}/>
                            </FormGroup>
                          </Col>
                          <Col>
                            {user.isAnnouncementsAdmin() && user.isAdmin() && (<FormGroup>
                              <div className='font-weight-bold'>
                                <FormattedMessage id='announcements.isBanner' />
                              </div>
                              <RadioButtons
                                name='isbanner-options'
                                onRadioButtonSelect={this.handleIsBannerSelect}
                                selectedValue={this.state.isBanner}
                                options={[
                                  { value: 'true', label: 'Yes' },
                                  { value: 'false', label: 'No' }
                                ]} />
                            </FormGroup>)}
                          </Col>
                        </Row>
                      </Container>
                      <FormGroup>
                        <Container>
                          <div className='font-weight-bold'>
                            <FormattedMessage id='announcements.dateRange' />
                          </div>
                          <Row>
                            <Col md={3} className='mb-3 mb-md-0'>
                              <CalendarInput
                                selectsStart
                                placeholderText={intl.formatMessage({id: 'announcements.startDate'})}
                                minDate={new Date()}
                                selectedDay={this.state.startDate}
                                onSelectedDateChange={this.handleStartDateChange}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                              />
                            </Col>
                            <Col md={3} className='mb-3 mb-md-0'>
                              <CalendarInput
                                selectsEnd
                                placeholderText={intl.formatMessage({id: 'announcements.endDate'})}
                                minDate={this.state.startDate}
                                selectedDay={this.state.endDate}
                                onSelectedDateChange={this.handleEndDateChange}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className='float-right'
                      color='primary'
                      isDisabled={!this.validateForm() || this.state.isSaving}
                      onClick={this.handleSaveButtonClick}
                      isLoading={this.state.isSaving}
                    >
                      Save
                    </Button>
                    <Link className='btn btn-secondary float-right mr-2' to='admin'>
                      <FormattedMessage id='general.cancel' />
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

AnnouncementEditor.propTypes = {
  title: PropTypes.string
};

export default injectIntl(AnnouncementEditor);