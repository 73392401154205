import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, CardTitle, Col, Row} from 'reactstrap';
import history from '../../history';

class TotalWarrantyInfo extends Component {
  goToLastYear = () => {
    let lastYear = new Date().getFullYear() - 1;
    history.push(`warranty?sd=${lastYear}-01-01&ed=${lastYear}-12-31`);
  };
  
  goToCurrentYear = () => {
    let thisYear = new Date().getFullYear();
    history.push(`warranty?sd=${thisYear}-01-01&ed=${thisYear}-12-31`);
  };
  
  render() {
    let year = new Date().getFullYear();
    
    return (
      <Card className='overview-card' body>
        <CardTitle className='text-uppercase'>
          <FormattedMessage id='warranty.totalWarrantyClaims'/>
        </CardTitle>
        <Row className='overview-sections'>
          <Col data-test-id='total-warranty-lastyear' className='overview-section' onClick={this.goToLastYear}>
            <div>
              <FontAwesomeIcon icon='shield-alt' className='is-color-gray'/>
              <span>{this.props.lastYearCount}</span>
            </div>
            <span>{(year-1)}</span>
          </Col>
          <Col data-test-id='total-warranty-currentyear' className='overview-section' onClick={this.goToCurrentYear}>
            <div>
              <FontAwesomeIcon icon='shield-alt' className='is-color-black'/>              
              <span>{this.props.currentYearCount}</span>
            </div>
            <span>{year}</span>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default injectIntl(TotalWarrantyInfo);