import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Price} from '../components/Price';

export const speeds = {
  bestWay: 'Best Way',
  oliveBranchWillCall: 'OBEMPWC',
  jacksonTruck: 'JKSN Truck',
  greenwoodTruck: 'GW Truck'
};

export const isFreeShipping = (shippingSpeed, isEmployeeOrder) => {
  return shippingSpeed === speeds.oliveBranchWillCall || shippingSpeed === speeds.jacksonTruck || shippingSpeed === speeds.greenwoodTruck || (isEmployeeOrder && shippingSpeed === speeds.bestWay);
};

export const orderIsEmployeeOrder = (order) => order.purchaseOrderNumber === "EMPLOYEE ORDER";

export const getOrderShippedQuantity = (order) => {
  return order?.orderShipments?.map(x=>x.orderShipmentLines ?? []).flat().reduce((acc, orderShipmentLine) => acc + orderShipmentLine.quantityShipped, 0) ?? 0;
}

export default {
  speeds: speeds,
  isFreeShipping: (shippingSpeed) => {
    return isFreeShipping(shippingSpeed);
  },
  getDefaultShippingSpeed: (isEmployee, state, city, freeTruckEnabled) => {
    let defaultShippingSpeed = speeds.bestWay;
    
    if (isEmployee && state && state === 'MS' && freeTruckEnabled) {
      // if (city && city === 'Olive Branch')
      //   defaultShippingSpeed = speeds.oliveBranchWillCall;
      if (city && city === 'Jackson')
        defaultShippingSpeed = speeds.jacksonTruck;
      if (city && city === 'Greenwood')
        defaultShippingSpeed = speeds.greenwoodTruck;
    }
    
    return defaultShippingSpeed;
  },
  requireShippingAddress: (shippingSpeed) => {
    if (shippingSpeed === speeds.oliveBranchWillCall || shippingSpeed === speeds.jacksonTruck || shippingSpeed === speeds.greenwoodTruck)
      return false;
    else
      return true;
  },
  getShippingCostMarkup: (order) => {
    const {freight: shippingCost, shippingSpeed} = order;
    const isEmployeeOrder = orderIsEmployeeOrder(order);
    const shippedQuantity = getOrderShippedQuantity(order);
    
    if (isFreeShipping(shippingSpeed, isEmployeeOrder)) {
      return <FormattedMessage id='general.free'/>;
    }
    if (shippingCost || shippingCost === 0){
      return <Price value={shippingCost} canBeZero={shippedQuantity && shippedQuantity > 0} unavailableMessage='general.pending' />;
    }
    return <FormattedMessage id='general.pending'/>;
  },
  getShippingCostTooltip: (order) => {
    const {shippingSpeed} = order;
    const isEmployeeOrder = orderIsEmployeeOrder(order);
    if (isFreeShipping(shippingSpeed, isEmployeeOrder))
      return <FormattedMessage id='orderSummary.freeShipping'/>;
    else
      return <FormattedMessage id='orderSummary.shippingToBeCalculated'/>;
  }
}