import {
  AddCreditCard,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Icon,
  PageLoader,
  Row,
  SelectCreditCard, useToggle
} from '@met/react-components';
import React, {Fragment, useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {bindActionCreators} from 'redux';

import auth from '../../auth';
import TestCardDialog from "../../components/TestCardDialog";
import {SquareTransactionStatus} from '../../enums';
import queryString from '../../helpers/queryString';
import {squareActions} from '../../store';
import PaymentConfirmationModal from './components/PaymentConfirmationModal';
import PaymentSummary from './components/PaymentSummary';
import { keyframes } from 'styled-components';
import  styled  from 'styled-components';
import { getCardExpired } from '../../helpers/creditCardHelper';

const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const ButtonRow = styled(Row)`
  & > button {
    animation: ${fadeIn} .35s both ease-in;
    
    @media (max-width: 770px) {
      display: block;
      width: 100%;
    }
    
    &:not(:first-of-type) {
      margin-left: 10px;
      @media (max-width: 770px) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
`;

const InviteToPay = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [testCardDialogHidden, toggleTestCardDialogHidden] = useToggle(true);
  
  // On initial mount, load up the square config and then the credit cards for the user and transaction details
  useEffect(() => {
    let values = queryString.parse();
    
    props.squareActions.getInviteToPay(values.p).then((response)=>{      
      props.squareActions.getSquareConfig(response.data.locationId)
        .then(() => {
          if (auth.isAuthenticated()) {
            // load all credit cards for the user
            props.squareActions.getCreditCards(response.data.locationId)
              .catch(() => toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'square.getCreditCardsErrorMessage'}), {timeOut: 0}));

          }
        });      
    });    
  }, []);
  
  // Have the whole state loading controlled by both fetch actions being in progress
  useEffect(() => {
    if (props.inviteToPay.transaction && !props.getCreditCards.isLoading && !props.inviteToPay.isLoading && props.squareApplicationId) {
      setIsLoading(false);
    }
    else
      setIsLoading(true);
  }, [props.getCreditCards.isLoading, props.inviteToPay.isLoading, props.inviteToPay.transaction, props.squareApplicationId]);

  // When a credit card is added, only save it via the Trap API if saveCard is true
  const handleCreditCardAdded = (cardData, nonce, cardholderName, saveCard) => {
    if (saveCard) {
      return props.squareActions.addCreditCard({
        billingPostalCode: cardData.billing_postal_code,
        cardholderName: cardholderName,
        squareCardNonce: nonce,
        accountNumber: props.inviteToPay.transaction.accountNumber,
        locationId: props.inviteToPay.locationId
      })
        .catch(response => {
          toastr.error(
            props.intl.formatMessage({ id: 'general.errorToastTitle' }),
            props.intl.formatMessage(
              {
                id: (response.error && response.error.code) ?
                  `square.errorCodes.${response.error.code}` :
                  'square.addCreditCardErrorMessage',
                defaultMessage: props.intl.formatMessage({ id: 'square.addCreditCardErrorMessage' })
              }),
            { timeOut: 0 });
        });
    }
    else {
      return props.squareActions.addTemporaryCreditCard({
        id: nonce, // still need to provide an id
        squareCardNonce: nonce,
        cardBrand: cardData.card_brand,
        cardLast4: cardData.last_4,
        cardholderName: cardholderName,
        billingPostalCode: cardData.billing_postal_code,
        expMonth: cardData.exp_month,
        expYear: cardData.exp_year
      })
    }
  };

  const handleAddTestCard = (nonce, cardholderName) =>{
    return props.squareActions.addTemporaryCreditCard({
      id: nonce, // still need to provide an id
      squareCardNonce: nonce,
      cardBrand: 'fake',
      cardLast4: 'fake',
      cardholderName: cardholderName,
      billingPostalCode: '94103'
    }).finally(()=>toggleTestCardDialogHidden());    
  };
  
  // When a credit card is deleted, check if there is a card nonce. If so, we know it is just a temporary card.
  const handleDeleteCard = (card) => {
    if (card.squareCardNonce)
      return props.squareActions.deleteTemporaryCreditCard(card.squareCardNonce);

    return props.squareActions.deleteCreditCard(card.id)
      .catch(() => toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'square.deleteCreditCardErrorMessage'}), {timeOut: 0}));
  };
  
  const handleSubmitPayment = () => {
    
    if(props.selectedCard && !getCardExpired(props.selectedCard) && props.selectedCard.id) {

      props.squareActions.submitInviteToPay({
        inviteToPayId: props.inviteToPay.transaction.inviteToPayId,
        creditCardId: props.selectedCard.id,
        cardNonce: props.selectedCard.squareCardNonce,
        billingPostalCode: props.selectedCard.billingPostalCode,
        cardholderName: props.selectedCard.cardholderName,
        expMonth: props.selectedCard.expMonth,
        expYear: props.selectedCard.expYear
      }).then(() => setIsConfirmationHidden(false))
        .catch(response => {
          if (response.error && response.error.code)
            toastr.error(
              props.intl.formatMessage({id: 'general.errorToastTitle'}),
              props.intl.formatMessage(
                {
                  id: `square.errorCodes.${response.error.code}`,
                  defaultMessage: props.intl.formatMessage({id: 'square.errorCodes.DEFAULT_ERROR'})
                }), {timeOut: 0});
          else
            toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'square.submitPaymentErrorMessage'}), {timeOut: 0});
        });
    }else{
      if (props.selectedCard && getCardExpired(props.selectedCard)) {
        toastr.warning(props.intl.formatMessage({ id: 'checkout.paymentMethodRequiredTitle' }),
          props.intl.formatMessage({ id: 'checkout.paymentMethodRequiredMessage' })
        );
      } else {
        toastr.error(props.intl.formatMessage({id: 'general.validationToastTitle'}), props.intl.formatMessage({id: 'square.creditCardSelectionRequired'}), {timeOut: 6000});
      }
    }
  };
  
  const transactionAlreadyCompleted = (
    <div className='text-center'>
      <div className='text-muted'><Icon type='info-circle' sm /></div>
      <h3><FormattedMessage id='square.transactionComplete' /></h3>
      <div className='text-muted'><FormattedMessage id='square.transactionCompleteMessage' /></div>
    </div>
  );

  const transactionNoLongerValid = (
    <div className='text-center'>
      <div className='text-muted'><Icon type='info-circle' sm /></div>
      <h3><FormattedMessage id='square.transactionNotValid' /></h3>
      <div className='text-muted'><FormattedMessage id='square.transactionNotValidMessage' /></div>
    </div>
  );
  
  let paymentMethod = null;
  if (isLoading)
    paymentMethod = <PageLoader/>;
  else if (props.inviteToPay.transaction.isDeleted)
    paymentMethod = transactionNoLongerValid;
  else if (props.inviteToPay.transaction.status !== SquareTransactionStatus.AwaitingInviteToPay)
    paymentMethod = transactionAlreadyCompleted;
  else
    paymentMethod = (
      <Fragment>
        <SelectCreditCard
          creditCards={props.creditCards || []}
          onSetSelectedCard={props.squareActions.setSelectedCard}
          onDeleteCard={handleDeleteCard}
          noPaymentMessage={`To pay as a guest click the "Enter Card Info" button and enter your card information. If you would like to store your credit card number for future use click sign-in, if you don't have an account click sign-in then click the "Sign Up" tab to create an account. Make sure to check the "Save" box to save your card information.`}
        />
        <br/>
        {props.squareApplicationId ? (<ButtonRow>
          <AddCreditCard
            applicationId={props.squareApplicationId}
            onCreditCardAdded={handleCreditCardAdded}
            canSave={true}
            buttonMessage='Enter Card Info'
          />

          {
            props.allowTestCards && (
              <Button onClick={toggleTestCardDialogHidden}>Add Test Card</Button>
            )
          }
        </ButtonRow>) :''}        
      </Fragment>
    );

  return (
    <Row className='content-wrapper animate-bottom-fade-in'>
      <Col xs={12} md={7}>
        <Card>
          <CardHeader>
            <FormattedMessage id='square.paymentMethod' />
          </CardHeader>
          <CardBody>
            {paymentMethod}
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} md={5}>
        <PaymentSummary
          isLoading={isLoading}
          transaction={props.inviteToPay.transaction}
        />
        <Row>
          <Col style={{textAlign: 'right'}}>
            <Button
              primary
              disabled={isLoading || props.inviteToPay.transaction.status !== SquareTransactionStatus.AwaitingInviteToPay || props.inviteToPay.transaction.isDeleted}
              loading={props.submitInviteToPay.isProcessing}
              onClick={handleSubmitPayment}
            >
              <FormattedMessage id='square.submitPayment' />
            </Button>
          </Col>
        </Row>
      </Col>
      {!isConfirmationHidden &&
        <PaymentConfirmationModal />
      }
      {
        props.allowTestCards && (
          <TestCardDialog
            hidden={testCardDialogHidden}
            toggle={toggleTestCardDialogHidden}
            addTestCard={handleAddTestCard}
            isLoading={props.addCreditCard.isLoading}
          />
        )
      }
    </Row>
  );
};

const mapStateToProps = state => {
  return {
    ...state.square
  }
};

const mapDispatchToProps = dispatch => {
  return {
    squareActions: bindActionCreators(squareActions, dispatch)
  }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InviteToPay));
