import createFetchAction from './createFetchAction';

export const LOADING_PUBLICATIONS = 'LOADING_PUBLICATIONS';
export const LOADING_PUBLICATIONS_SUCCESS = 'LOADING_PUBLICATIONS_SUCCESS';
export const LOADING_PUBLICATIONS_ERROR = 'LOADING_PUBLICATIONS_ERROR';

export const DOWNLOAD_PUBLICATION_FILE = 'DOWNLOAD_PUBLICATION_FILE';

export const initialState = {
  publications: {
    data: [],
    isLoading: false,
    isError: false
  }
};

export const actionCreators = {
  getPublications: (filter) => {    
    return createFetchAction({
      url: `/api/v1/publications/${filter}`, 
      startAction: LOADING_PUBLICATIONS,
    });
  },
  getPublicationFile: (filename) => {
    return createFetchAction({
      url: `/api/v1/media/support/${filename}`,      
      ignore404: true,
      startAction: 'DOWNLOAD_PUBLICATION_FILE'
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  
  switch (action.type) {
    case LOADING_PUBLICATIONS:
      return {
        ...state,
        publications: {
          ...state.publications,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_PUBLICATIONS_SUCCESS:      
      return {
        ...state,
        publications: {
          data: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_PUBLICATIONS_ERROR:
      return {
        ...state,
        publications: {
          ...state.publications,
          isLoading: false,
          isError: true
        }
      };
    default:
      return state;
  }
}