const productAdminReason = {
    isNotHidden: '<p>An item is Hidden when manually set to hidden (checkbox) in the Connect Administrator Dashboard for the given product.</p>',
    featured: '<p>An item is Featured when manually set to featured (checkbox) in the Connect Administrator Dashboard for the given product.</p>',
    isNotRestricted: `
        <dl>
            <dd> A product is restricted when it has one or more of the following (AX) Item Statuses:</dd>
            <dd>
                <ul>
                    <li>NO - Not Ready To Accept Orders</li>
                    <li>NP - "Un-Released" New Purchased Tool or Accessory</li>
                    <li>RN - Reference Item, No Longer Sold/Used by MKE</li>
                    <li>RS - Reserved Item - Requires ECN  Release</li>
                    <li>RT - Reference Item - MKE Design Control</li>
                    <li>MB - Merchandiser BOM</li>
                </ul>
            </dd>
            <dd>Item Status is defined in AX, MET > Product information management > Common > Released products under the General section for a given product.</dd>
        </dl>`,
    hasValidCommercialization: `
        <dl>
            <dd>A product has invalid commercialization when it has one of the following (AX) Commercialization Codes:</dd>
            <dd>
                <ul>
                    <li>MCH - Merchandisers 49-22-Xxxx'S</li>
                    <li>PKG - Packng,Carton,Kit Card,Label</li>
                    <li>DSP - Displays-Racks-Planograms</li>
                    <li>DWG - Drawings,Artwork,Decals,Bom</li>
                    <li>LIT - Literature, Manuals</li>
                    <li>COM - Commodity Items For Shipping</li>
                </ul>
            </dd>
            <dd>Commercialization Code is defined in AX, MET > Product information management > Common > Released products under the Sell section for a given product.</dd>
        </dl>`,
    hasValidPackingGroup: `
        <dl>
            <dd>A product has invalid packing group when it has an (AX) Packing group of "QP - Quarter Pallet".</dd>
            <dd>Packing group is defined in AX, MET > Product information management > Common > Released products under the Manage Inventory section for a given product.</dd>
        </dl>`,
    hasPricing: `
        <dl>
            <dd>A product has pricing when the Flintfox TPM Start Date is before (earlier than) the current date..</dd>
            <dd>Flintfox TPM Start Date is defined in AX, MET > Product information management > Common > Released products under the General section for a given product.</dd>
        </dl>`,
    hasValidItemType: `
        <dl>
            <dd>A product has a valid item type when it has an (AX) Item type of "Item".</dd>
            <dd>Item type is defined in AX, MET > Product information management > Common > Released products for a given product.</dd>
        </dl>`,
    hasValidItemGroup: `
        <dl>
            <dd>A product has invalid item group when it has one of the following (AX) Item groups:</dd>
            <dd>
                <ul>
                    <li>PHANTOM</li>
                    <li>PURCH SVC</li>
                    <li>REBATE</li>
                    <li>REF ONLY</li>
                </ul>
            </dd>
            <dd>Packing group is defined in AX, MET > Product information management > Common > Released products under the Manage Costs section for a given product.</dd>
        </dl>`,
    isNotStopped: `
        <dl>
            <dd>A product is stopped when it has the (AX) Stopped field checked.</dd>
            <dd>Stopped (checkbox) is defined in AX, MET > Product information management > Common > Released products for a given product under Order Settings > Site specific order settings OR Default order settings. Site specific will take precedence.</dd>
        </dl>`,
    isNotAnOemTool: `
        <dl>
            <dd>A product is an OEM tool when it has one of the following (AX) PL2_Product_Level_2 values:</dd>
            <dd>
                <ul>
                    <li>HND_OTAPE</li>
                    <li>ACC_OOSC</li>
                </ul>
            </dd>
            <dd>PL2_Product_Level_2 is defined in AX, MET > Product information management > Common > Released products under Financial dimensions for a given product.</dd>
        </dl>`,
    isPartIsListedInTheDpl: `
        <dl>
            <dd>A product is a part when the (AX) Commercialization Code is "PRT" or "HST".</dd>
            <dd>Type is defined in AX, MET > Product information management > Common > Released products for a given product under the General section for a given product.</dd>
            <dd>A product is listed in the DPL when the (AX) Product category has "DPL-USA" listed.</dd>
            <dd>Product categories are defined in AX, MET > Product information management > Common > Released products under Set-up > Product categories for the given product.</dd>
        </dl>`,
    isFeatured: '<p>An item is Featured when manually set to featured (checkbox) in the Connect Administrator Dashboard for the given product.</p>',
    isPreSale: `
        <dl>
            <dd>A product is presale when it has an (AX) Item Status of AE - Accept Order Early, Not Ready to Ship.</dd>
            <dd> Item Status is defined in AX, MET > Product information management > Common > Released products under the General section for a given product.</dd>
        </dl>`,
    isDiscontinued: `
        <p>A product is discontinued if any of the following criteria is met:</p>
        <dl>
            <dd>The product is not one of the following (AX) Commercialization Codes:</dd>
            <dd>
                <ul>
                    <li>ACC</li>
                    <li>EMP</li>
                    <li>HND</li>
                    <li>OPE</li>
                    <li>PRT</li>
                    <li>SFT</li>
                    <li>STL</li>
                    <li>TOL</li>
                    <li>XXX</li>
                </ul>
            </dd>
            <dd>Type is defined in AX, MET > Product information management > Common > Released products for a given product under the General section for a given product.</dd>
            <dd>The product has one of the following Item Statuses:</dd>
            <dd>
                <ul>
                    <li>DA - To be Disc. after Prod. End-of-Life Analy Compltd</li>
                    <li>TD - Temporarily Disc'd - See Item Master Text Notes</li>
                    <li>DS - Disc-Use Up Stock-Check for Replaced by Number</li>
                    <li>OB - Obsolete, Do Not Stock Part</li>
                </ul>
            </dd>
            <dd>Item Status is defined in AX, MET > Product information management > Common > Released products for a given product under the General section for a given product.</dd>
            <dd>The product has a Valid From date that is less than the current date, and a Valid To date that is greater than the current date.</dd>
            <dd>Valid From and Valid To dates are defined in AX, MET > Product information management > Common > Released &nbspproducts for a given product under the Retail section for a given product.</dd>
            <dd>The product has a Flintfox TPM End Date that is less than the current date.</dd>
            <dd>Flintfox TPM End Date is defined in AX, MET > Product information management > Common > Released products under the General section for a given product.</dd>
            <dd>The product is not listed in the DPL.</dd>
            <dd>Product categories (such as "DPL-USA") are defined in AX, MET > Product information management > Common > Released products under Set-up > Product categories for the given product.</dd>
            <dd>The product has an Alternative Product - When to use of "Always".</dd>
            <dd>Alternative Product When to use is defined in AX, MET > Product information management > Common > Released products under the Sell section for a given product.</dd>
        </dl>`
}

export default productAdminReason;
