import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Dropdown } from '@met/react-components';
import {statesActions} from '../../store';

const StatesDropdown = (props) => {
  const validationMessage = 'Please select a state';
  
  useEffect(() => {
    props.statesActions.getStates();
  }, []);

  const handleDropdownChange = (option) => {
    props.onChange && props.onChange(option);
  };

  return (
    <Dropdown
      options={props.states || []}
      getOptionLabel={(option) => (option.name)}
      getOptionValue={(option) => (option.abbreviation)}
      onChange={handleDropdownChange}
      invalidMsg={validationMessage}
      invalid={props.invalid}
      value={props.states.filter(option => option.abbreviation === props.value)}
      placeholder='Select State...'
    />
  );
};

StatesDropdown.propTypes = {
  onChange: PropTypes.func,
  trySubmit: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    states: state.states.states
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    statesActions: bindActionCreators(statesActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatesDropdown);