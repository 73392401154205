import React from 'react';
import { HourglassOutlined } from '@ant-design/icons';
import { CustomizationViewer } from "@milwaukeetool/customization-react";
import { Result, Layout } from 'antd'
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { useQueryParams, StringParam } from "use-query-params";
import { cartActions } from "../../store";

const Customizer = (props) => {
  const dispatch = useDispatch();
  const { intl } = props;
  const accountNumber = useSelector(state=>state.account && (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber));
  const accountInfoLoading = useSelector(state => state.account && (state.account.isImpersonating ? state.account.impersonatedAccount.isLoading : state.account.currentAccount.isLoading));
  const isEmployeeAccount = useSelector(state => state.account && (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.isEmployeeAccount : state.account.currentAccount.accountDetails.isEmployeeAccount));
  const configLoading = useSelector(state=>state.configuration.getConfig.isLoading);
  const threeKitConfig = useSelector(state=>state.configuration.threeKitConfig);
  const useVisualizer = useSelector(state=>state.configuration.featureToggles.CustomLogoVisualizer);
  const [queryParams] = useQueryParams({
    sku: StringParam,
    referenceId: StringParam
  })

  if(configLoading || !threeKitConfig)
    return "";
  
  if(!useVisualizer){
    return (
      <div className='ant-layout'>
        <Result
          icon={<HourglassOutlined />}
          title="Please contact a safety specialist to place a customized order."
        />,
      </div>
    );
  }else{
    const sessionSavedAsync = (session) => {
      if (isEmployeeAccount === true) {
        return;
      }

      let lines = [];
      session.configurations.forEach(config => {
        let splitId = config.id.split('.');
        lines.push({
          sku: config.sku,
          skuAlias: config.sku,
          quantity: config.quantity,
          remainingQuantity: config.quantity,
          customLogoRefId: config.id,
          pceCode: config.physicalSkuPce,
          nextValidQuantityAllowed: config.multiple || 0,
          lowestQuantityAllowed: splitId.length > 1 && session.configurations.filter(x => x.id.split('.')[0] == splitId[0]).length > 1 ? 0 : (config.minimumOrderQuantity || 0),
          isCustomLogo: true
        });
        if (config.additionalSkus) {
          config.additionalSkus.forEach(additionalSku => {
            lines.push({
              sku: additionalSku,
              skuAlias: additionalSku,
              quantity: config.quantity,
              remainingQuantity: config.quantity,
              customLogoRefId: config.id,
              pceCode: config.decorationSkuPce,
              nextValidQuantityAllowed: config.multiple || 0,
              lowestQuantityAllowed: splitId.length > 1 && session.configurations.filter(x => x.id.split('.')[0] == splitId[0]).length > 1 ? 0 : (config.minimumOrderQuantity || 0),
              isCustomLogo: true
            });
          });
        }
      });

      return dispatch(cartActions.addBatchToCart(lines, accountNumber))
        .then(() => {
          dispatch(cartActions.getCartStatistics(accountNumber))
            .then(() => {
              toastr.success(intl.formatMessage({ id: 'cart.addedToCart' }), intl.formatMessage({ id: 'cart.addedToCart' }));
            })
            .catch(() => {
              toastr.error(intl.formatMessage({ id: 'general.errorToastTitle' }), intl.formatMessage({ id: 'cart.errorAddingToCart' }), { timeout: 0 });
            })
        });
    }

    return accountInfoLoading === false && (
      <Layout.Content style={{ position: 'relative' }}>
        <CustomizationViewer
          sku={queryParams.sku}
          configurationId={queryParams.referenceId}
          orgId={threeKitConfig.organizationId}
          authId={threeKitConfig.publicToken}
          environment={threeKitConfig.environment}
          apiEnvironment={threeKitConfig.apiEnvironment}
          saveFlow={isEmployeeAccount === true ? 'email' : 'cart'}
          enableTrace={true}
          onSessionSavedAsync={sessionSavedAsync}
          constrainDialogs={false}
          theme={
            {
              layout: {
                showHeader: false
              }
            }
          }
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            overflowY: 'scroll'
          }}
        />
      </Layout.Content>
    );
  }  
}

export default injectIntl(Customizer);