import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Text} from '../../components';
import queryString from '../../helpers/queryString';

class PaginationResultsCount extends Component {
  render() {
    const {currentPage, totalCount, pageSize, isHidden, count} = this.props;

    if (totalCount === 0)
      return null;
    
    let resultsStart = totalCount > 0 ? (currentPage ? currentPage - 1 : 0) * pageSize + 1 : 0;
    let resultsEnd = count < pageSize ? totalCount : (currentPage ? currentPage : 1) * pageSize;
    let resultsFromToOf = totalCount > 0 ? 'Showing ' + resultsStart + '-' + resultsEnd + ' of ' + totalCount : 'No';
    
    return (
      <Text
        isHidden={isHidden}
        value={`${resultsFromToOf} results ${queryString.parse().q ? `for "${queryString.parse().q}"` : ''}`}
      />
    );
  }
}

PaginationResultsCount.propTypes = {
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  isHidden: PropTypes.bool,
  count: PropTypes.number
};

export default PaginationResultsCount;