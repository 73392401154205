import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from "react-redux-toastr";

import history from '../../../history';
import {PageLoader} from '../../../components';
import {announcementsActions} from '../../../store';
import AnnouncementEditor from './AnnouncementEditor';

class EditAnnouncement extends Component {
  componentDidMount() {
    const {id} = this.props.match.params;
    
    this.props.getAnnouncement(id);
  }

  handleSaveClick = (announcement) => {
    const {intl} = this.props;
    
    return this.props.updateAnnouncement(announcement)
      .then(() => {
        history.push('/admin');
      })
      .catch(() => {
        toastr.error(intl.formatMessage({id: 'announcements.errorSavingTitle'}), intl.formatMessage({id: 'announcements.errorSavingMessage'}))
      });
  };

  render() {
    const {isLoading, intl, announcement} = this.props;
    
    if (isLoading || !announcement)
      return <PageLoader/>;
    
    return (
      <div id='announcements' className='animate-bottom-fade-in content-wrapper'>
        <AnnouncementEditor
          title={intl.formatMessage({id: 'announcements.updateAnnouncementTitle'})}
          announcementId={announcement.id}
          heading={announcement.heading}
          summary={announcement.summary}
          details={announcement.details}
          priority={announcement.priority}
          audience={announcement.audience}
          isBanner={announcement.isBanner}
          startDate={new Date(announcement.startDate)}
          endDate={new Date(announcement.endDate)}
          onSaveClick={this.handleSaveClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAnnouncement: (id) => dispatch(announcementsActions.getAnnouncement(id)),
  updateAnnouncement: (announcement) => dispatch(announcementsActions.updateAnnouncement(announcement))
});

const mapStateToProps = state => {
  return {
    isLoading: state.announcements.getAnnouncement.isLoading,
    announcement: state.announcements.getAnnouncement.announcement,
    isUpdating: state.announcements.updateAnnouncement.isProcessing
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditAnnouncement));