import { Button, Col, Dropdown, Row, TextInput } from '@met/react-components';
import React, { useEffect, useState } from 'react';

const AddressForm = ({ address, disabled, toggle, onSaveClick, states, countries, addAttentionTo }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(null);

  const initialAddressState = {
    attentionTo: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: 'US'
  };
  const [addressFields, setAddressFields] = useState(address || initialAddressState);

  useEffect(() => {
    setAddressFields(address || initialAddressState);
  }, [address]);

  const handleCancelClick = () => {
    toggle();
    setErrors(null);
    setAddressFields(address || initialAddressState);
  };

  const handleSaveClick = () => {
    setIsSaving(true);
    let addressFieldsToSave = addressFields;
    if (addAttentionTo && addressFieldsToSave.attentionTo?.trim().length > 0) {
      addressFieldsToSave = { ...addressFieldsToSave, addressLine1: `Attn: ${addressFieldsToSave.attentionTo}\n${addressFieldsToSave.addressLine1}` }
    }
    return onSaveClick(addressFieldsToSave)
      .then(() => setErrors(null))
      .catch(response => {
        if (response.error && response.error.details) {
          const messagesList = response.error.details.map((detail, index) => {
            return <li key={`error-${index}`}>{detail}</li>
          });
          setErrors(<ul className='font-italic'>{messagesList}</ul>);
        }
        else {
          setErrors(<span>An Unknown Error Occurred - Please Try Again</span>);
        }
      })
      .finally(() => setIsSaving(false));
  };

  const handleAttentionToChange = (e) => setAddressFields({ ...addressFields, attentionTo: e.target.value });
  const handleAddressLine1Change = (e) => setAddressFields({ ...addressFields, addressLine1: e.target.value });
  const handleAddressLine2Change = (e) => setAddressFields({ ...addressFields, addressLine2: e.target.value });
  const handleCityChange = (e) => setAddressFields({ ...addressFields, city: e.target.value });
  const handleStateDropdownChange = (state) => setAddressFields({ ...addressFields, state: state.abbreviation });
  const handleStateTextInputChange = (e) => setAddressFields({ ...addressFields, state: e.target.value });
  const handleCountryChange = (country) => setAddressFields({ ...addressFields, countryCode: country.iso });
  const handlePostalCodeChange = (e) => setAddressFields({ ...addressFields, postalCode: e.target.value });

  const stateInput = addressFields.countryCode === 'US' ? (
    <Dropdown
      id='stateProvince'
      options={states}
      getOptionValue={(option) => (option.abbreviation)}
      getOptionLabel={(option) => (option.name)}
      placeholder='Select...'
      onChange={handleStateDropdownChange}
      value={states.filter(state => state.abbreviation === addressFields.state)}
    />
  ) : <TextInput value={addressFields.state} onChange={handleStateTextInputChange} disabled={disabled} />;

  return (
    <Col>
      <Row>
        <Col>
          <label htmlFor='country'>Country</label>
          {
            disabled ? <TextInput id='country' value={addressFields.countryCode || addressFields.country} disabled /> : (
              <Dropdown
                id='country'
                options={countries}
                getOptionValue={(option) => (option.iso)}
                getOptionLabel={(option) => (option.name)}
                placeholder='Select...'
                onChange={handleCountryChange}
                value={countries.filter(country => country.iso === addressFields.countryCode)}
              />
            )
          }
        </Col>
      </Row>
      {addAttentionTo && (<Row>
        <Col>
          <label htmlFor='attentionTo'>Attention To</label>
          <TextInput id='attentionTo' placeholder='John Doe' value={addressFields.attentionTo} onChange={handleAttentionToChange} disabled={disabled} />
        </Col>
      </Row>)}
      <Row>
        <Col>
          <label htmlFor='address'>Address</label>
          <TextInput id='address' placeholder='1234 Main St' value={addressFields.addressLine1} onChange={handleAddressLine1Change} disabled={disabled} />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor='address2'>Address 2</label>
          <TextInput id='address2' placeholder='Apartment, studio, or floor' value={addressFields.addressLine2} onChange={handleAddressLine2Change} disabled={disabled} />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor='city'>City</label>
          <TextInput id='city' value={addressFields.city} onChange={handleCityChange} disabled={disabled} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <label htmlFor='stateProvince'>State/Province</label>
          <div data-test-id='stateProvince'>
            {
              disabled ? <TextInput id='stateProvince' value={addressFields.state} disabled /> : stateInput
            }
          </div>
        </Col>
        <Col xs={12} md={6}>
          <label htmlFor='zipPostalCode'>Zip/Postal Code</label>
          <TextInput id='zipPostalCode' value={addressFields.postalCode} onChange={handlePostalCodeChange} disabled={disabled} />
        </Col>
      </Row>
      {
        errors && (
          <Row className='is-color-red'>
            <Col>
              {errors}
            </Col>
          </Row>
        )
      }
      <Row className='justify-content-end align-items-center'>
        {toggle && <Button primary outline onClick={handleCancelClick}>Cancel</Button>}
        {!disabled && <Button data-test-id='checkout-address-save' primary style={{ marginLeft: '10px' }} onClick={handleSaveClick} loading={isSaving}>Save</Button>}
      </Row>
    </Col>
  );
};

export default AddressForm;