import React, { Fragment } from 'react';
import { Message } from '../components';
import { Col, Row } from 'reactstrap';
import history from '../history';

const getReplacementSkuMarkup = (product) => {
  return product?.replacementSku ? (
    <Message
      type='info'
      icon='info-circle'
      value={<Fragment>
        <span>Item has been replaced by </span>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span onClick={() => {
          history.push(`/products/${product.replacementSku}`)
        }} className='has-pointer-icon font-weight-bold'>{product.replacementSku}</span><br />
      </Fragment>}
      className='mb-0 p-0 border-0' />
  ) : undefined;
}

const getSimilarProductsMarkup = (product) => {
  return product?.similarProducts ? (
    <Message
      type='info'
      icon='info-circle'
      value={<Fragment>
        <span>See similar items: </span>
        {product.similarProducts.map((similarProduct, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <span key={index} onClick={() => {
            history.push(`/products/${similarProduct}`)
          }} className='has-pointer-icon font-weight-bold'>{(index ? ', ' : '') + similarProduct}</span>
        ))}
      </Fragment>}
      className='mb-0 p-0 border-0' />
  ) : undefined;
}

const getMessageMarkup = (product) => {
  return (product?.replacementSku || product?.similarProducts) ? (
    <Row className='mt-3'>
      <Col>
        <Message
          type='info'
          value={[getReplacementSkuMarkup(product), getSimilarProductsMarkup(product)]}
          className='mb-0'
        />
      </Col>
    </Row>
  ) : undefined;
}

export default {
  getMessageMarkup
}