import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { Col, Container, Row } from 'reactstrap';

import { Button, Link, Price, QuantityInput } from '../../../components';
import user from '../../../helpers/user';
import OrderLineAvailability from './OrderLineAvailability';

class OrderLinePreviewGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: this.props.orderLine.quantity,
      isRemoving: false
    };
  }

  handleQuantityChange = (quantity) => {
    this.props.onQuantityChange(this.props.orderLine, quantity);

    this.setState({
      ...this.state,
      quantity
    });
  };

  handleRemoveFromCartClick = () => {
    this.setState({
      ...this.state,
      isRemoving: true
    }, () => {
      this.props.onRemoveFromCartClick(this.props.orderLine)
        .then(() => {
          this.setState({
            ...this.state,
            isAdding: false
          });
        });
    });
  };

  handleAddToSaveForLaterClick = () => {
    this.props.onSaveForLaterClick(this.props.orderLine)
      .then(() => {
        toastr.success('Item Saved', 'Item was saved for later!');
      });
  };

  render() {
    const {orderLine} = this.props;
    
    const renderListPrice = (line) => {
      return user && !user.isMilwaukeeToolEmployee() && (
        <div>
        <span className='has-font-size-1'>
          <FormattedMessage id='pricing.listPrice'/>&nbsp;
            <del data-test-id={`cart-grid-list-price-${orderLine.sku}`}>
            <Price
              value={line.listPrice}
              isLoading={line.listPrice === null}
            />
          </del>
        </span>
        </div>
      );
    }
    const renderYourPrice = (line) => {
      return (<div>
        <div className='has-font-size-2'>
          <FormattedMessage id='pricing.yourPrice'/>
        </div>
        <div data-test-id={`cart-grid-your-price-${orderLine.sku}`} className='has-font-size-4'>
          <Price
            value={line.unitPrice}
            isLoading={line.unitPrice === null}
          />
        </div>
      </div>);
    }

    let quantityInput = user.canPlaceOrders() && (
      <QuantityInput 
        quantity={this.state.quantity} 
        minQuantity={orderLine.lowestQuantityAllowed} 
        maxQuantity={orderLine.highestQuantityAllowed} 
        incrementQuantity={orderLine.nextValidQuantityAllowed} 
        onQuantityChange={this.handleQuantityChange} 
        dataTestId={this.props.orderLine.sku}
      />
    );
    
    const getSkuAliasMarkup = (line) =>{
      return line.skuAlias !== line.description && (
        <div className='mr-lg-3'>{line.skuAlias}</div>
      );
    }

    const calcTotalPrice = () =>{
      let total = orderLine.unitPrice * this.state.quantity;

      orderLine.children.forEach(c=>{
        total += c.unitPrice * c.quantity
      })

      return total;
    }

    return (
      <Container data-test-id={`cart-grid-product-details-${orderLine.sku}`} className='order-line-preview-grid print-group'>
        <Row className='align-items-center animate-bottom-fade-in'>
          <Col xs='12' md='9'>
            <Container>
              <Row className='mb-0 ml-3 ml-md-2 mt-3 my-md-2'>
                <Col xs='12' md='2' data-test-id={`cart-grid-product-sku-${orderLine.sku}`} className='text-left align-self-center font-weight-bold product-data'>
                  <Link to={`products/${orderLine.skuAlias}`} locale={this.props.locale} className='no-underline'>
                    {getSkuAliasMarkup(orderLine)}
                  </Link>
                </Col>
                <Col xs='12' md='6' data-test-id={`cart-grid-product-name-${orderLine.sku}`} className='is-color-red text-left align-self-center wrap-break-words'>
                  <Link to={`products/${orderLine.skuAlias}`} locale={this.props.locale} className='no-underline'>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(orderLine.description)}}/>
                  </Link>
                </Col>
                <Col xs='6' md='2' className='my-1 text-left align-self-center'>
                  {renderListPrice(orderLine) && (<Row>
                    {renderListPrice(orderLine)}
                  </Row>)}
                  <Row>
                    {renderYourPrice(orderLine)}
                  </Row>
                  <OrderLineAvailability group={this.props.group} orderLine={orderLine}/>
                </Col>
                <Col xs='6' md='2' className='my-1 text-left align-self-center'>
                  {renderListPrice(orderLine) && (<Row>
                    <span>
                      &nbsp;
                    </span>
                  </Row>)}
                  <Row>
                  <div className='has-font-size-2'>
                    <FormattedMessage id='pricing.extendedPrice'/>
                  </div>
                    <div data-test-id={`cart-grid-extended-price-${orderLine.sku}`} className='has-font-size-4'>
                    <Price
                      value={orderLine.unitPrice * this.state.quantity}
                      isLoading={orderLine.unitPrice === null}
                    />
                    </div>
                    </Row>
                </Col>               
              </Row>
              {orderLine.children?.length ? (
                <React.Fragment>
                  {orderLine.children.map(c=>(
                    <Row key={c.id} className='mb-0 ml-3 ml-md-2 mt-3 my-md-2 has-font-size-2'>                      
                      <Col xs='12' md={{size: 6, offset:2}} className='text-left align-self-center wrap-break-words'>
                        <div className='has-font-size-2'>
                          {getSkuAliasMarkup(c)}
                        </div>
                        <div className='has-font-size-2'>
                          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(c.description)}}/>
                        </div>
                        {c.customLogoRefId?.length > 0 && (
                          <div data-test-id={`cart-grid-configurator-id-${c.customLogoRefId}`} className='has-font-size-2'>
                            {`Configurator ID: ${c.customLogoRefId}`}
                          </div>
                        )}
                      </Col>
                      <Col xs='6' md='2' className='my-1 text-left align-self-center'>
                        {renderListPrice(c) && (<Row>
                          {renderListPrice(c)}
                        </Row>)}
                        <Row>
                          {renderYourPrice(c)}
                        </Row>
                      </Col>
                      <Col xs='6' md='2' className='my-1 text-left align-self-center'>
                        {renderListPrice(c) && (<Row>
                          <span>
                            &nbsp;
                          </span>
                        </Row>)}
                        <Row>
                          <div className='has-font-size-2'>
                            <FormattedMessage id='pricing.extendedPrice'/>
                          </div>
                        </Row>
                        <Row>
                          <div data-test-id={`cart-grid-extended-price-${orderLine.sku}`} className='has-font-size-4'>
                          <Price
                            value={c.unitPrice * c.quantity}
                            isLoading={c.unitPrice === null}
                          />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    ))}
                    <Row className='mb-0 ml-3 ml-md-2 mt-3 my-md-2 has-font-size-2'>                      
                      <Col xs='6' md={{size: 8, offset:2}} className='text-left align-self-center font-weight-bold product-data'>
                        Total
                      </Col>
                      <Col xs='6' md='2' className='my-1 text-left align-self-center'>                        
                        <div data-test-id='cart-grid-total-price' className='has-font-size-4'>
                          <Price
                            value={calcTotalPrice()}
                            isLoading={orderLine.unitPrice === null}
                          />
                        </div>
                      </Col>
                    </Row>
                </React.Fragment>
                ): ''}
            </Container>
          </Col>          
          <Col xs='12' md='3' className='mb-2 my-lg-auto align-self-center'>            
            <Container>
              <Row className='justify-content-center'>
                <Col xs='auto' className='mt-1 mt-lg-3 mt-xl-0'>
                  {quantityInput}
                </Col>
                <Col xs='auto' data-test-id={`cart-grid-remove-${orderLine.sku}`} className='mt-1 mt-lg-3 mt-xl-0 d-print-none'>
                  <Button outline color='primary' onClick={this.handleRemoveFromCartClick} isLoading={this.state.isRemoving}>
                    <FormattedMessage id='search.removeFromCart'/>
                  </Button>
                </Col>
              </Row>
              <Row className={this.props.isMultiShipment?'d-none':'mt-2 d-print-none'} >
                <Col className='text-center'>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div data-test-id={`cart-grid-save-${orderLine.sku}`} className='is-mock-link' onClick={this.handleAddToSaveForLaterClick}>Save For Later</div>
                </Col>
              </Row>
              <Row className='mt-1 mt-sm-1 d-print-none'>
                <Col>
                  {orderLine.nextValidQuantityAllowed > 0 && (<div className='font-weight-light text-secondary has-font-size-2 text-center'>
                    <FormattedMessage id='products.quantityMultiple' values={{ nextValidQuantityAllowed: orderLine.nextValidQuantityAllowed }} />
                  </div>)}
                  {orderLine.lowestQuantityAllowed > 0 && (<div className='font-weight-light text-secondary has-font-size-2 text-center'>
                    <FormattedMessage id='products.quantityMin' values={{ lowestQuantityAllowed: orderLine.lowestQuantityAllowed }} />
                  </div>)}
                  {orderLine.highestQuantityAllowed > 0 && (<div className='font-weight-light text-secondary has-font-size-2 text-center'>
                    <FormattedMessage id='products.quantityMax' values={{ highestQuantityAllowed: orderLine.highestQuantityAllowed }} />
                  </div>)}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

OrderLinePreviewGrid.propTypes = {
  orderLine: PropTypes.object.isRequired,
  availability: PropTypes.object,
  onRemoveFromCartClick: PropTypes.func,
  onSaveForLaterClick: PropTypes.func,
  onQuantityChange: PropTypes.func,
  locale: PropTypes.string,
  isMultiShipment: PropTypes.bool
};

export default injectIntl(OrderLinePreviewGrid);