import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {actions as toastrActions, toastr} from 'react-redux-toastr';
import { Fade } from 'react-awesome-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Col, Container, Row, Input, InputGroup, Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {AccountSelection, SearchInput} from '../../components';
import queryString from '../../helpers/queryString';
import user from '../../helpers/user';
import history from '../../history';
import {accountActions, ordersActions} from '../../store';
import BaseLayout from './BaseLayout';

class SearchLayout extends React.Component {
  constructor(props){
    super(props);

    const values = queryString.parse();

    this.state = {
      query: values.q || '',
      showOrderIdModal: false,
      showErrorMessage: false,
      orderInputValue: ''
    }
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      query: e.target.value
    });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };
  
  handleClick = () => {
    this.search();
  };

  search = () => {
    let locale = this.props.match.params.locale;
    let subPath = locale ? `/${locale}` : '';
    let route = subPath + queryString.update('q', this.state.query, '/search' + window.location.search);
    route = queryString.update('p', null, route);
    history.push(route.replace(/\+/g, '%2B'));
  };

  clear = () => {
    this.setState({
      ...this.state,
      query: ''
    });
  };

  loadImpersonatedAccount = (accountNumber) => {
    this.props.loadImpersonatedAccount(accountNumber)
      .catch(() => {
        toastr.error(this.props.intl.formatMessage({ id: 'general.errorToastTitle' }), this.props.intl.formatMessage({ id: 'account.accountImpersonationLoadingError' }));
      });
  };

  clearImpersonatedAccount = () => {
    this.props.clearImpersonatedAccount();
  };

  navigateToOrderDetails = () => {
    if (this.state.orderInputValue != ''){
        this.props.getOrderId(this.state.orderInputValue)
        .then(() => {
            this.displayInvalidAxError(false)
            this.toggleOrderIdModal()
            history.push(`/orders/${this.props.hashedOrderId}`)
          }).catch((error) => {
            this.displayInvalidAxError(true)
        });
    }
  };

  displayInvalidAxError = (flag) => {
    this.setState({
      ...this.state,
      showErrorMessage: flag
    });
  }

  toggleOrderIdModal = () => {
    let showOrderId = !this.state.showOrderIdModal;
    this.setState({
      ...this.state,
      showOrderIdModal: showOrderId,
      showErrorMessage: false
    });
  };

  setOrderInputValue = (e) => {
    this.setState({
      ...this.state,
      orderInputValue: e.target.value
    });
  }

  render() {
    let orderIdHasher = (
      <InputGroup type='search'>
        <Input type='search' onChange = {this.setOrderInputValue}/>
          <Button onClick={this.navigateToOrderDetails} color='danger'>
           Order Details
          </Button>
      </InputGroup>
    );

    let orderIdModal =(
      <Modal isOpen={this.state.showOrderIdModal} toggle={this.toggleOrderIdModal} centered={true}>
        <ModalHeader toggle={this.toggleOrderIdModal}>Get Order Details</ModalHeader>
        <ModalBody>
          {orderIdHasher}
          {this.state.showErrorMessage &&(<span style={{color:'red'}}>Invalid Order Number</span>)}
        </ModalBody>
      </Modal>
    );

    let orderHelpButton = this.props.featureToggles.IdHasherEnabled && this.props.title == 'Your Orders' && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <span className='help-button' color='none' onClick={this.toggleOrderIdModal}>
        <FontAwesomeIcon icon='question-circle' style={{'fontSize':'medium','verticalAlign':'inherit'}}/>
      </span>
    );

    let title = this.props.title && (
      <Row className='my-3'>
        <Col>
          <h2>
            {this.props.title}
            {orderHelpButton}
          </h2>
        </Col>
      </Row>
    );

    let searchPlaceholder = this.props.intl.formatMessage({id: 'search.searchPlaceholder'});
    let searchHelpModalContent = (
      <div>
        <p>By default, searches with spaces will treat separate words as AND searches - Ex. <code>hammer drill</code> will return products containing the terms <code>hammer</code> AND <code>drill</code>.</p>
        <p>The following modifiers can be used for advanced searching:</p>
        <ul>
          <li><strong>OR operator (<code>|</code>)</strong> - Ex. <code>hammer|drill</code> will return products containing the terms <code>hammer</code> OR <code>drill</code></li>
          <li><strong>NOT operator (<code>-</code>)</strong> - Ex. <code>hammer -drill</code> will return products containing the term <code>hammer</code> but NOT <code>drill</code></li>
          <li><strong>Suffix operator (<code>*</code>)</strong> - Ex. <code>impact*</code> will return products starting with the term <code>impact</code></li>
          <li><strong>Phrase operator (<code>&quot;&quot;</code>)</strong> - Ex. <code>&quot;m12 impact&quot;</code> will return products containing the WHOLE phrase <code>&quot;m12 impact&quot;</code> exactly</li>
        </ul>
      </div>
    );
    let accountSelectionMarkup = '';

    if (((user.isMilwaukeeToolEmployee() && user.canQuoteOtherAccounts()) || (this.props.accountChildren && this.props.accountChildren.length > 0)) && !this.props.accountIsError) {
      accountSelectionMarkup = (
        <Container>
          <AccountSelection
            IsLoading={!this.props.accountLoaded}
            accountNumber={this.props.accountNumber}
            accountName={this.props.accountName}
            accountChildren={this.props.accountChildren}
            loadImpersonatedAccount={this.loadImpersonatedAccount}
            clearImpersonatedAccount={this.clearImpersonatedAccount}
            impersonatedAccountNumber={this.props.impersonatedAccountNumber}
            impersonatedAccountName={this.props.impersonatedAccountName}
          />
        </Container>
      );
    }

    return (
      <BaseLayout {...this.props}>
        <Container className='has-background-light-gray' fluid>
          <Container className='search-container d-print-none'>
            <Row className='my-4'>
              <Col>
                <SearchInput
                  searchInputDataTestId='product-search-input'
                  searchButtonDataTestId='product-search-button'
                  value={this.state.query}
                  placeholder={searchPlaceholder}
                  isSearching={this.props.search.getIndexProducts.isLoading}
                  onClear={this.clear}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleChange}
                  onClick={this.handleClick}
                  searchHelpModalContent={searchHelpModalContent}
                />
              </Col>
            </Row>
          </Container>
        </Container>
        {accountSelectionMarkup}
        <Container fluid className='d-flex flex-column flex-grow-1'>
          <Container>
            <Fade>
              {title}
            </Fade>
            {orderIdModal}
          </Container>
          {this.props.children}
        </Container>
      </BaseLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    search: state.productSearch,
    accountLoaded: (!state.account.currentAccount.isLoading && state.account.currentAccount.accountDetails.accountNumber !== '') || (!state.account.impersonatedAccount.isLoading && state.account.impersonatedAccount.accountDetails.accountNumber !== ''),
    accountNumber: state.account.currentAccount.accountDetails.accountNumber,
    accountName: state.account.currentAccount.accountDetails.name,
    accountChildren: state.account.currentAccount.accountDetails.children,
    accountIsError: state.account.currentAccount.isError,
    impersonatedAccountNumber: state.account.impersonatedAccount.accountDetails.accountNumber,
    impersonatedAccountName: state.account.impersonatedAccount.accountDetails.name,
    hashedOrderId: state.orders.hashedOrderId.orderId,
    featureToggles: state.configuration.featureToggles
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderId: (orderNumber) => dispatch(ordersActions.getOrderId(orderNumber)),
    loadImpersonatedAccount: (accountNumber) => dispatch(accountActions.loadImpersonatedAccount(accountNumber)),
    clearImpersonatedAccount: () => dispatch(accountActions.clearImpersonatedAccount()),
    showToast: (toastConfig) => dispatch(toastrActions.add(toastConfig))
  }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SearchLayout));