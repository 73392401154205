import createFetchAction from './createFetchAction';

export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const LOADING_PRODUCT_SUCCESS = 'LOADING_PRODUCT_SUCCESS';
export const LOADING_PRODUCT_ERROR = 'LOADING_PRODUCT_ERROR';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export const initialState = {
  getProduct: {
    product: null,
    isLoading: false,
    isError: false
  },
  updateProduct: {
    isProcessing: false,
    isError: false
  }
};

export const actionCreators = {
  getProduct: (sku) => {
    return createFetchAction({
      url: `/api/v1/products/${sku}`,
      startAction: LOADING_PRODUCT
    });
  },
  updateProduct: (sku, overrides, isHidden, isFeatured, featuredOrder,isMarketingOnly) => {
    return createFetchAction({
      method: 'PATCH',
      url: `/api/v1/products`,
      body: {sku, overrides, isHidden, isFeatured, featuredOrder, isMarketingOnly},
      startAction: UPDATE_PRODUCT
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  
  switch(action.type){
    case LOADING_PRODUCT:
      return {
        ...state,
        getProduct: {
          product: null,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_PRODUCT_ERROR:
      return {
        ...state,
        getProduct: {
          isLoading: false,
          isError: true
        }
      };
    case LOADING_PRODUCT_SUCCESS:
      return {
        ...state,
        getProduct: {
          product: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        updateProduct: {
          isProcessing: true,
          isError: false
        }
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        updateProduct: {
          isProcessing: false,
          isError: false
        }
      };
    case UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        updateProduct: {
          isProcessing: false,
          isError: true
        }
      };
    default:
      return state;
  }
};