import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { actions as toastrActions, toastr } from 'react-redux-toastr';
import { Col, Container, Jumbotron, Row } from 'reactstrap';
import auth0 from 'auth0-js';

import auth from '../../auth';
import { AccountSelection, SearchInput } from '../../components';
import { getProfile, getSession } from '../../helpers/localStorage';
import user from '../../helpers/user';
import history from '../../history';
import oneKey from '../../images/one-key.png';
import {
  accountActions,
  announcementsActions,
  brandworksActions,
  dashboardActions,
  productSearchActions,
  usersActions
} from '../../store';
import ActivateAccount from './ActivateAccount';
import ActiveWarrantyInfo from './ActiveWarrantyInfo';
import Announcements from './Announcements';
import AnnouncementBanner from './AnnouncementBanner';
import Brandworks from './Brandworks';
import EmployeePurchase from './EmployeePurchase';
import OrderInformation from './OrderInformation';
import TotalWarrantyInfo from "./TotalWarrantyInfo";
import WhatsNew from './WhatsNew';
import pipelineBanner from '../../images/pipeline-banner.png';
import tealium from '../../helpers/tealium';
import gtm from "../../helpers/googleTagManager";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      readMore: false,
      isActivating: false
    };
  }

  componentDidMount() {
    let locale = this.props.match.params.locale;
    this.props.getFeaturedProducts(locale);

    let profile = getProfile();
    if (auth.isAuthenticated()) {
      // we only need to fetch the user if they don't have an account loaded in order to figure out
      // whether or not they have sent an activation request yet
      if (!profile.accountNumber) {
        this.props.getActiveUser();
      } else {
        this.loadAnnouncements();
        this.loadDashboards();
      }


    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.accountNumber !== this.props.accountNumber && this.props.accountNumber && auth.isAuthenticated()) {
      this.loadAnnouncements();
      this.loadDashboards();
    }
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      query: e.target.value
    });
  };

  handleActivateAccountClick = (accountNumber) => {
    this.setState({ ...this.state, isActivating: true });
    this.props.activateAccount(accountNumber)
      .then(response => {
        if (!response.autoActivationCompleted) {
          this.props.getActiveUser()
        } else {
          toastr.success(
            this.props.intl.formatMessage({ id: 'home.autoAccountActivationTitle' }),
            this.props.intl.formatMessage({ id: 'home.autoAccountActivationDescription' }),
            { timeOut: 5000 }
          );

          let session = getSession();

          const auth0Options = {
            domain: session.auth0.domain,
            clientID: session.auth0.clientId,
            redirectUri: session.auth0.callbackUrl,
            audience: session.auth0.audience,
            responseType: 'token',
            scope: 'openid profile connect email'
          };

          this.auth = new auth0.WebAuth(auth0Options);

          // refresh current session
          this.auth.renewAuth(auth0Options, () => {
            // refresh the  browser to apply session changes
            setTimeout(() => window.location.reload(), 3500);
          });
        }

        this.setState({ ...this.state, isActivating: false });
      })
      .catch(() => {
        this.setState({ ...this.state, isActivating: false });
        toastr.error(
          this.props.intl.formatMessage({ id: 'home.activationErrorTitle' }),
          this.props.intl.formatMessage({ id: 'home.activationErrorDescription' }),
          { timeOut: 5000 }
        )
      });
  };

  handleEmployeePurchaseClick = () => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.query = "&ea=true";
    this.search();
  };

  loadAnnouncements = () => {
    if (user.canCreateWarrantyClaims() || user.canApproveWarrantyClaims() || user.isSuperAdmin() || this.props.accountHasAsc) {
      this.props.getAnnouncements({ d: new Date().toISOString().split('T')[0] })
    }
    else {
      this.props.getAnnouncements({ d: new Date().toISOString().split('T')[0], a: 'all' })
    }
  };

  loadDashboards = () => {
    if (user.isConnectUser()) {
      this.props.getOrdersDashboard(this.props.accountNumber);
    }

    if (user.canCreateWarrantyClaims() || (user.canViewWarrantyClaims() && this.props.accountHasAsc)) {
      this.props.getCurrentWarrantyDashboard(this.props.accountNumber);
      this.props.getTotalWarrantyDashboard(this.props.accountNumber);
    }
  };

  search = () => {
    let locale = this.props.match.params.locale;
    let subPath = locale ? `/${locale}` : '';
    let route = `${subPath}/search?q=${this.state.query}`;
    history.push(route.replace(/\+/g, '%2B'));
  };

  clear = () => {
    this.setState({
      ...this.state,
      query: ''
    });
  };

  loadImpersonatedAccount = (accountNumber) => {
    this.props.loadImpersonatedAccount(accountNumber)
      .catch(() => {
        toastr.error(this.props.intl.formatMessage({ id: 'general.errorToastTitle' }), this.props.intl.formatMessage({ id: 'account.accountImpersonationLoadingError' }));
      });
  };

  clearImpersonatedAccount = () => {
    this.props.clearImpersonatedAccount();
  };

  handleEventTracking = () => {
    // google events
    gtm.event('Connect Banner', 'Click', 'Pipeline Registration');
    // Tealium events
    tealium.logEvent({
      event_name: 'Connect Pipeline Registration'
    });
  }

  render() {
    const { products, isLoading } = this.props.featuredProducts;
    const isPipelineEvent = this.props.configuration.featureToggles.PipelineBanner;

    let searchPlaceholder = this.props.intl.formatMessage({ id: 'search.searchPlaceholder' });
    let searchHelpModalContent = (
      <div>
        <p>By default, searches with spaces will treat separate words as AND searches - Ex. <code>hammer drill</code> will return products containing the terms <code>hammer</code> AND <code>drill</code>.</p>
        <p>The following modifiers can be used for advanced searching:</p>
        <ul>
          <li><strong>OR operator (<code>|</code>)</strong> - Ex. <code>hammer|drill</code> will return products containing the terms <code>hammer</code> OR <code>drill</code></li>
          <li><strong>NOT operator (<code>-</code>)</strong> - Ex. <code>hammer -drill</code> will return products containing the term <code>hammer</code> but NOT <code>drill</code></li>
          <li><strong>Suffix operator (<code>*</code>)</strong> - Ex. <code>impact*</code> will return products starting with the term <code>impact</code></li>
          <li><strong>Phrase operator (<code>&quot;&quot;</code>)</strong> - Ex. <code>&quot;m12 impact&quot;</code> will return products containing the WHOLE phrase <code>&quot;m12 impact&quot;</code> exactly</li>
        </ul>
      </div>
    );

    let accountSelectionMarkup = '';

    if (((user.isMilwaukeeToolEmployee() && user.canQuoteOtherAccounts()) || (this.props.accountChildren && this.props.accountChildren.length > 0)) && !this.props.accountIsError) {
      accountSelectionMarkup = (
        <Container>
          <AccountSelection
            IsLoading={!this.props.accountLoaded}
            accountNumber={this.props.accountNumber}
            accountName={this.props.accountName}
            accountChildren={this.props.accountChildren}
            loadImpersonatedAccount={this.loadImpersonatedAccount}
            clearImpersonatedAccount={this.clearImpersonatedAccount}
            impersonatedAccountNumber={this.props.impersonatedAccountNumber}
            impersonatedAccountName={this.props.impersonatedAccountName}
          />
        </Container>
      );
    }

    let annoucementBanner = this.props.announcements.filter(x => x.isBanner === true).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).splice(0, 1).map((announcement, index) => {
      return (
        <AnnouncementBanner key={`announcement-${index}`} announcement={announcement} />
      );
    });

    const isAuthenticatedAndHasAsc = auth.isAuthenticated() && this.props.accountHasAsc;
    const isProd = window.location.hostname === 'connect.milwaukeetool.com';
    const canViewInvoices = (this.props.topLevelAccount == this.props.accountNumber || user.isMilwaukeeToolEmployee()) ? true : !(this.props.configuration?.distributorAccountRestrictions?.restrictedInvoices?.includes(this.props.topLevelAccount))
    const pipelineEventBanner = (
      <Col>
        <a id='pipeline-virtual-event-banner' href={isProd ? "https://www.milwaukeetool.com/Pipeline" : "http://staging.milwaukeetool.com/pipeline"} onClick={this.handleEventTracking}>
          <img src={pipelineBanner} alt='Pipeline' className='advertisement' />
        </a>
      </Col>
    );

    return (
      <div id='home' className='content-wrapper'>
        <Jumbotron className='has-background-banner-1'>
          <Container className='animate-bottom-fade-in search-container'>
            <h1 data-test-id='find-a-product' className='display-4'><FormattedMessage id='home.title' /></h1>
            <SearchInput
              searchInputDataTestId='product-search-input'
              searchButtonDataTestId='product-search-button'
              placeholder={searchPlaceholder}
              value={this.state.query}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              onClick={this.search}
              onClear={this.clear}
              searchHelpModalContent={searchHelpModalContent} />
          </Container>
        </Jumbotron>
        {annoucementBanner}
        {accountSelectionMarkup}
        <Container className='mt-3'>
          <Row>
            <Col md={5} lg={4}>
              <Container>
                <Row>
                  <Col>
                    <ActivateAccount
                      user={this.props.users.user}
                      onActivateAccountClick={this.handleActivateAccountClick}
                      isLoading={this.state.isActivating}
                    />
                  </Col>
                </Row>
                {user.isConnectUser() &&
                  <Row>
                    <Col>
                      <OrderInformation cartQuantity={this.props.cartQuantity} invoiceCount={this.props.invoiceCount} orderCount={this.props.orderCount} canViewInvoices={canViewInvoices} />
                    </Col>
                  </Row>
                }
                {user.isConnectUser() && (user.canCreateWarrantyClaims() || (user.canViewWarrantyClaims() && this.props.accountHasAsc)) &&
                  <Fragment>
                    <Row>
                      <Col>
                        <ActiveWarrantyInfo rejected={this.props.warrantyRejected} denied={this.props.warrantyDenied} pending={this.props.warrantyPending} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TotalWarrantyInfo currentYearCount={this.props.warrantyTotalCurrentYear} lastYearCount={this.props.warrantyTotalLastYear} />
                      </Col>
                    </Row>
                  </Fragment>
                }
                {this.props.configuration.featureToggles?.EmployeeOrdersDisabled === false && this.props.isEmployeeAccount &&
                  <Fragment>
                    <Row>
                      <Col>
                        <EmployeePurchase onClick={this.handleEmployeePurchaseClick} />
                      </Col>
                    </Row>
                  </Fragment>
                }
                {auth.isAuthenticated() &&
                  <Fragment>
                    <Row>
                      <Col>
                        <Announcements announcements={this.props.announcements.filter(x => x.isBanner === false)} isLoading={this.props.announcementsLoading} />
                      </Col>
                    </Row>
                  </Fragment>
                }
                <Row className='d-none d-md-block'>
                  {isPipelineEvent ?
                    <>{pipelineEventBanner}</>
                    :
                    <Col>
                      <img src={oneKey} alt='one key' className='advertisement' />
                    </Col>
                  }
                </Row>
              </Container>
            </Col>
            <Col md={7} lg={8}>
              <Container>
                <Row>
                  <Col>
                    <WhatsNew products={products} isLoading={isLoading} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Brandworks
                      getFlyerService={this.props.getFlyerService}
                      getPinnacle={this.props.getPinnacle}
                      getSproutLoud={this.props.getSproutLoud}
                      isEmployeeAccount={this.props.isEmployeeAccount}
                      isAuthenticatedAndHasAsc={isAuthenticatedAndHasAsc}
                    />
                  </Col>
                </Row>
                <Row className='d-block d-md-none'>
                  {isPipelineEvent ?
                    <>{pipelineEventBanner}</>
                    :
                    <Col>
                      <img src={oneKey} alt='one key' className='advertisement' />
                    </Col>
                  }
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getFlyerService: () => dispatch(brandworksActions.getFlyerService()),
  getPinnacle: () => dispatch(brandworksActions.getPinnacle()),
  getSproutLoud: (targetUrl) => dispatch(brandworksActions.getSproutLoud(targetUrl)),
  getFeaturedProducts: (locale) => dispatch(productSearchActions.getFeaturedProducts(locale)),
  getActiveUser: () => dispatch(usersActions.getActiveUser()),
  activateAccount: (accountNumber) => dispatch(usersActions.activateAccount(accountNumber)),
  loadImpersonatedAccount: (accountNumber) => dispatch(accountActions.loadImpersonatedAccount(accountNumber)),
  clearImpersonatedAccount: () => dispatch(accountActions.clearImpersonatedAccount()),
  getAnnouncements: (query) => dispatch(announcementsActions.getAnnouncements(query)),
  getOrdersDashboard: (accountNumber) => dispatch(dashboardActions.getOrdersDashboard(accountNumber)),
  getCurrentWarrantyDashboard: (accountNumber) => dispatch(dashboardActions.getCurrentWarrantyDashboard(accountNumber)),
  getTotalWarrantyDashboard: (accountNumber) => dispatch(dashboardActions.getTotalWarrantyDashboard(accountNumber)),
  showToast: (toastConfig) => dispatch(toastrActions.add(toastConfig))
});


const mapStateToProps = state => {
  return {
    configuration: state.configuration,
    cartQuantity: state.cart.totalQuantity || 0,
    featuredProducts: state.productSearch.getFeaturedProducts,
    locale: state.locales.locale,
    search: state.productSearch,
    users: state.users,
    accountLoaded: (!state.account.currentAccount.isLoading && state.account.currentAccount.accountDetails.accountNumber !== '') || (!state.account.impersonatedAccount.isLoading && state.account.impersonatedAccount.accountDetails.accountNumber !== ''),
    accountNumber: state.account.currentAccount.accountDetails.accountNumber,
    accountName: state.account.currentAccount.accountDetails.name,
    accountHasAsc: state.account.currentAccount.accountDetails.hasAsc,
    accountHasOneKey: state.account.currentAccount.accountDetails.hasOneKey,
    accountChildren: state.account.currentAccount.accountDetails.children,
    accountIsError: state.account.currentAccount.isError,
    impersonatedAccountNumber: state.account.impersonatedAccount.accountDetails.accountNumber,
    impersonatedAccountName: state.account.impersonatedAccount.accountDetails.name,
    isEmployeeAccount: state.account.isImpersonating
      ? state.account.impersonatedAccount.accountDetails.isEmployeeAccount
      : state.account.currentAccount.accountDetails.isEmployeeAccount,
    announcements: state.announcements.getAnnouncements.announcements,
    announcementsLoading: state.announcements.getAnnouncements.isLoading,
    invoiceCount: state.dashboard.orders.invoiceCount || 0,
    orderCount: state.dashboard.orders.orderCount || 0,
    warrantyRejected: state.dashboard.currentWarranty.rejected || 0,
    warrantyDenied: state.dashboard.currentWarranty.denied || 0,
    warrantyPending: state.dashboard.currentWarranty.pending || 0,
    warrantyTotalCurrentYear: state.dashboard.totalWarranty.currentYearCount || 0,
    warrantyTotalLastYear: state.dashboard.totalWarranty.lastYearCount || 0,
    topLevelAccount: state.account.currentAccount.accountDetails.topLevelAccount,
  }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Home));