import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import Pagination from 'react-js-pagination';
import {connect} from 'react-redux';
import {Card, Col, Container, ListGroup, ListGroupItem, Row, Table} from 'reactstrap';
import {toastr} from 'react-redux-toastr';

import {Link, PageLoader} from '../../../components';
import queryString from '../../../helpers/queryString';
import history from '../../../history';
import {announcementsActions} from '../../../store';
import AnnouncementRow from './AnnouncementRow';

class Announcements extends Component {
  constructor(props) {
    super(props);

    let values = queryString.parse();
    this.state = {
      currentPage: values.p ? parseInt(values.p) : 1,
      pageSize: values.ps ? parseInt(values.ps) : 10,
      queryString: window.location.search
    }
  }
  
  componentDidMount() {
    this.props.getAnnouncements(queryString.parse());
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps === undefined)
      return false;
    
    if (this.state.queryString !== window.location.search){
      this.props.getAnnouncements(queryString.parse());

      let values = queryString.parse();
      this.setState({
        ...this.state,
        queryString: window.location.search,
        currentPage: values.p ? parseInt(values.p) : 1,
        pageSize: values.ps ? parseInt(values.ps) : 10
      });
    }
  }

  handleDeleteClick = (id) => {
    const {intl} = this.props;
    this.props.deleteAnnouncement(id)
      .then(() => {
        this.props.getAnnouncements(window.location.search);
      })
      .catch(() => {
        toastr.error(intl.formatMessage({id: 'announcements.errorDeletingTitle'}), intl.formatMessage({id: 'announcements.errorDeletingMessage'}))
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      currentPage: pageNumber
    });

    history.push(queryString.update('p', pageNumber));
  };

  render() {
    const {isLoading, announcements, totalPages, totalCount} = this.props;
    
    let markup;
    if (isLoading) {
      markup = <PageLoader/>;
    }
    else if (!isLoading && announcements.length > 0) {
      const rows = announcements.map((announcement, index) => {
        return (
          <AnnouncementRow
            key={`announcement-${index}`}
            announcement={announcement}
            onDelete={this.handleDeleteClick}
          />
        );
      });

      markup = (
        <Table striped borderless responsive className='order-lines-table'>
          <thead>
          <tr>
            <th><FormattedMessage id='announcements.heading' /></th>
            <th><FormattedMessage id='announcements.startDate' /></th>
            <th><FormattedMessage id='announcements.endDate' /></th>
            <th><FormattedMessage id='announcements.priority' /></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      );
    }
    else {
      markup = (
        <ListGroup className='mt-2'>
          <ListGroupItem className='text-center'>
            <div className='my-4'>
              <h3><FormattedMessage id='announcements.noAnnouncementsMessage' /></h3>
            </div>
          </ListGroupItem>
        </ListGroup>
      );
    }

    return (
      <div id='announcements' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row>
            <Col>
              <Card body>
                <Container>
                  <Row>
                    <Col>
                      <h4><FormattedMessage id='announcements.currentAnnouncements' /></h4>
                    </Col>
                    <Col xs='auto'>
                      <Link to='admin/announcements/new' className='btn btn-primary mb-2 mb-md-0'><FormattedMessage id='announcements.addButton' /></Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {markup}
                      {totalPages > 1 && <Pagination
                        activePage={this.state.currentPage}
                        itemsCountPerPage={this.state.pageSize}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={8}
                        innerClass='pagination justify-content-center flex-wrap'
                        itemClass='page-item'
                        linkClass='page-link'
                        nextPageText={<FontAwesomeIcon icon='angle-right' />}
                        prevPageText={<FontAwesomeIcon icon='angle-left' />}
                        lastPageText={<FontAwesomeIcon icon='angle-double-right'/>}
                        firstPageText={<FontAwesomeIcon icon='angle-double-left'/>}
                        hideFirstLastPages={false}
                        onChange={this.handlePageChange}
                      />}
                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAnnouncements: (query) => dispatch(announcementsActions.getAnnouncements(query)),
  deleteAnnouncement: (id) => dispatch(announcementsActions.deleteAnnouncement(id))
});

const mapStateToProps = state => {
  return {
    isLoading: state.announcements.getAnnouncements.isLoading,
    announcements: state.announcements.getAnnouncements.announcements,
    totalPages: state.announcements.getAnnouncements.totalPages,
    totalCount: state.announcements.getAnnouncements.totalCount
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Announcements));