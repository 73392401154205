import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import React, { Component } from 'react';
import { Icon } from '../../../components/Icon';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SearchableFlagRow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSearchHelp: false
    };
  }

  toggleSearchHelpModal = () => {
    this.setState({
      ...this.state,
      showSearchHelp: !this.state.showSearchHelp
    });

  };
  render() {
    let icon = this.props.valid ? (
      <Icon type='check-circle' color='green' size='sm' />
    ) : (
      <Icon type='times-circle' color='red' size='sm' />
    );

    let helpButton = this.props.searchHelpModalContent && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className='help-button' color='none' onClick={this.toggleSearchHelpModal}>
        <FontAwesomeIcon icon='question-circle' />
      </div>
    );

    let searchHelpModal = (
      <Modal isOpen={this.state.showSearchHelp} toggle={this.toggleSearchHelpModal} centered={true}>
        <ModalHeader toggle={this.toggleSearchHelpModal}>{this.props.label}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.searchHelpModalContent) }} />
        </ModalBody>
      </Modal>
    );

    return (
      <div className='d-flex flex-row'>
        {icon}
        <span className='ml-2'>{this.props.label}</span>
        {helpButton}
        {searchHelpModal}
      </div>
    );
  }
}

SearchableFlagRow.propTypes = {
  valid: PropTypes.bool,
  label: PropTypes.string
};

export default SearchableFlagRow;