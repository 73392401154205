import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInput, Button, Col, Dropdown, InputGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Row, TextInput } from '@met/react-components';

const options = [
  { label: 'Good Card', value: 'cnon:card-nonce-ok'},
  { label: 'CVV incorrect', value: 'cnon:card-nonce-rejected-cvv'},
  { label: 'Postal Code incorrect', value: 'cnon:card-nonce-rejected-postalcode'},
  { label: 'Card Expiration incorrect', value: 'cnon:card-nonce-rejected-expiration'},
  { label: 'Card Declined', value: 'cnon:card-nonce-declined'},
];

const TestCardDialog = ({ addTestCard, hidden, toggle, isLoading }) =>{
  const cardholderName = useInput('');  
  const [cardNonce, setCardNonce] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cardholderNameErrorClass, setCardholderNameErrorClass] = useState(null);

  useEffect(() => {
    if (cardholderName.hasValue) {
      setCardholderNameErrorClass(null);
    }
  }, [cardholderName.hasValue]);

  const handleAddClicked = () => {
    if (!cardholderName.hasValue || !cardNonce.value) {
      setErrorMessage('Please fill in all required fields');
      setCardholderNameErrorClass(!cardholderName.hasValue ? 'sq-input--error' : null);
    } else {
      addTestCard(cardNonce.value, cardholderName.value);
    }
  };

  return (
    <Modal centered hidden={hidden} toggle={toggle} >
      <ModalContent>
        <ModalHeader toggle={toggle}>
          Add Test Credit Card
        </ModalHeader>
        <ModalBody style={{'minHeight': '325px'}}>
          <Row>
            <Col>
              <InputGroup>
                <TextInput
                  data-test-id='test-card-input'
                  id='cardholder-name'
                  type='text'
                  placeholder='Cardholder Name'
                  {...cardholderName.bindToInput}
                  className={cardholderNameErrorClass}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup data-test-id='test-card-dropdown'>
                <Dropdown
                  value={cardNonce}
                  options={options}
                  placeholder={'Select Credit Card...'}
                  getOptionValue={(option) => (option.value)}
                  getOptionLabel={(option) => (option.label)}
                  onChange={(option) => setCardNonce(option)}
                />
              </InputGroup>
            </Col>
          </Row>
          {
            errorMessage && (
              <p id='square-error'>
                <FontAwesomeIcon icon='exclamation-circle' /> {errorMessage}
              </p>
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button data-test-id='checkout-test-card-dialog-cancel' onClick={toggle}>Cancel</Button>
          <Button data-test-id='checkout-test-card-dialog-save' primary onClick={handleAddClicked} loading={isLoading}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

TestCardDialog.propTypes = {
  addTestCard: PropTypes.func,
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  toggle: PropTypes.any
};

export default TestCardDialog;