import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { Alert, Col, Container, Input, Row } from 'reactstrap';
import { Button, LoadingDots } from '../../components';

class AccountSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountNumber: this.props.impersonatedAccountNumber || '',
      selectedAccountNumber: this.props.impersonatedAccountNumber || '',
      selectedAccountName: this.props.impersonatedAccountName || ''
    }
  }

  loadImpersonatedAccount = () => {
    this.props.loadImpersonatedAccount(this.state.selectedAccountNumber);
  };

  clearImpersonatedAccount = () => {
    this.props.clearImpersonatedAccount();
  };

  handleAccountTextChange = (e) => {
    this.setState({
      ...this.state,
      selectedAccountNumber: e.target.value
    });
  };

  handleAccountDropdownChange = (value) => {
    this.setState({
      ...this.state,
      accountNumber: value ? value.accountNumber : '',
      selectedAccountNumber: value ? value.accountNumber : '',
      selectedAccountName: value ? value.name : ''
    }, () => {
      this.loadImpersonatedAccount();
    });

    return value;
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.loadImpersonatedAccount();
    }
  };

  reset = () => {
    this.setState({
      ...this.state,
      accountNumber: '',
      selectedAccountNumber: '',
      selectedAccountName: ''
    }, () => {
      this.clearImpersonatedAccount('');
    });
  };

  filterAccountChildren = filter => {
    const accountChildren = this.props.accountChildren;

    return new Promise(resolve => {
      let arr = Enumerable.from(accountChildren).where(x => (x.name.toLowerCase().includes(filter.toLowerCase()) || x.accountNumber.includes(filter))).toArray();
      resolve(arr);
    });
  };

  render() {
    const { accountName, accountNumber, isLoading, accountChildren, impersonatedAccountNumber, impersonatedAccountName } = this.props;
    const value = this.state.selectedAccountNumber !== '' ? { accountNumber: this.state.selectedAccountNumber, name: this.state.selectedAccountName } : null;

    let accountNameMarkup = isLoading
      ? <LoadingDots />
      : (
        <div>
          <span data-test-id='logged-in-as-number' className='font-weight-bold'>{accountNumber}</span>
          <span> - </span>
          <span data-test-id='logged-in-as-name'>{accountName}</span>
        </div>
      );

    let impersonatedAccountNameMarkup = accountNameMarkup;
    if (impersonatedAccountNumber !== '') {
      impersonatedAccountNameMarkup = isLoading
        ? <LoadingDots />
        : (
          <div>
            <span data-test-id='viewing-account-number' className='font-weight-bold'>{impersonatedAccountNumber}</span>
            <span> - </span>
            <span data-test-id='viewing-account-name'>{impersonatedAccountName}</span>
          </div>
        );
    }

    let accountSelector = accountChildren && accountChildren.length > 0 ? (
      <AsyncSelect
        className='react-select-container'
        classNamePrefix='react-select'
        cacheOptions
        defaultOptions
        isSearchable
        value={value}
        loadOptions={this.filterAccountChildren}
        getOptionValue={(option) => option.accountNumber}
        getOptionLabel={(option) => `${option.name} - ${option.accountNumber}`}
        onChange={this.handleAccountDropdownChange}
      />
    ) : (
      <Input type='text' value={this.state.selectedAccountNumber} onChange={this.handleAccountTextChange} onKeyPress={this.handleKeyPress} />
    );

    let buttonsMarkup = accountChildren && accountChildren.length > 0 ? (
      <Fragment>
        <Button data-test-id='account-selection-reset' type='button' size='sm' className='' onClick={this.reset} isDisabled={isLoading}>Reset</Button>
      </Fragment>
    ) : (
      <Fragment>
        <Button data-test-id='account-selection-select' type='button' size='sm' className='mr-2' color='primary' onClick={this.loadImpersonatedAccount} isDisabled={isLoading}>Select</Button>
        <Button data-test-id='account-selection-reset' type='button' size='sm' className='' onClick={this.reset} isDisabled={isLoading}>Reset</Button>
      </Fragment>
    );

    return (
      <Alert color='warning' className='account-selection mt-4 mb-0 d-print-none'>
        <Container>
          <Row className='my-auto'>
            <Col xs='auto' className='my-auto'>
              <FontAwesomeIcon className='mr-2' color='black' icon='exclamation-triangle' />
            </Col>
            <Col className='py-2'>
              <Container fluid>
                <Row className='my-auto'>
                  <Col lg='3' className='mb-3 my-lg-auto'>
                    <div>Logged In As:</div>
                    <div>{accountNameMarkup}</div>
                  </Col>
                  <Col lg='3' className='mb-3 my-lg-auto'>
                    <div>Viewing Account:</div>
                    <div>{impersonatedAccountNameMarkup}</div>
                  </Col>
                  <Col data-test-id='account-selection-dropdown-menu' className='mb-3 my-lg-auto'>
                    {accountSelector}
                  </Col>
                  <Col lg='auto' className='my-auto text-right'>
                    {buttonsMarkup}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Alert>
    );
  }
}

AccountSelection.propTypes = {
  isLoading: PropTypes.bool,
  accountNumber: PropTypes.string,
  accountName: PropTypes.string,
  accountChildren: PropTypes.array,
  impersonatedAccountNumber: PropTypes.string,
  impersonatedAccountName: PropTypes.string
};

export default AccountSelection;