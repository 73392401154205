import createFetchAction from './createFetchAction';

export const DOWNLOAD_PRICE_FILE = 'DOWNLOAD_PRICE_FILE';
export const DOWNLOAD_SERVICE_FILE = 'DOWNLOAD_SERVICE_FILE';
export const DOWNLOAD_SALES_POLICY_FILE = 'DOWNLOAD_SALES_POLICY_FILE';
export const DOWNLOAD_DPLUSA_FILE = 'DOWNLOAD_DPLUSA_FILE';

export const actionCreators = {
  downloadPriceFile: (filename) => {
    return createFetchAction({
      url: `/api/v1/media/pricing/${filename}`,
      isBlob: true,
      ignore404: true,
      startAction: 'DOWNLOAD_PRICE_FILE'
    });
  },
  downloadServiceFile: (filename) => {
    return createFetchAction({
      url: `/api/v1/media/service/${filename}`,
      isBlob: true,
      ignore404: true,
      startAction: 'DOWNLOAD_SERVICE_FILE'
    });
  },
  downloadSalesPoliciesFile: (filename) => {
    return createFetchAction({
      url: `/api/v1/media/salesPolicies/${filename}`,
      isBlob: true,
      ignore404: true,
      startAction: 'DOWNLOAD_SALES_POLICY_FILE'
    });
  },
  downloadDplUsaFile: () => {
    return createFetchAction({
      url: '/api/v1/media/dplusa',
      isBlob: true,
      ignore404: true,
      startAction: 'DOWNLOAD_DPLUSA_FILE'
    });
  }
};
