import React, { Fragment, useState } from 'react';
import { Tooltip } from 'reactstrap';

const Circle = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  let id = `circleComponent${props.id}`;

  let tooltipMarkup = (props.toolTipText && (typeof props.toolTipText == 'object' || props.toolTipText.length > 0) &&
    <Tooltip placement="top" isOpen={tooltipOpen} target={id} toggle={toggle} delay={{ show: 0, hide: props.delay || 0 }} className={props.tooltipClass} innerClassName={props.tooltipClass}>
      {props.toolTipText}
    </Tooltip>
  );
  let strokeWidth = 1;

  return (
    <Fragment>
      <svg id={id} style={{width: `${(props.radius * 2) + (strokeWidth * 2)}px`, height: `${(props.radius * 2) + (strokeWidth * 2)}px`}}>
        <circle cx={props.radius + strokeWidth} cy={props.radius + strokeWidth} r={props.radius} fill={props.color} stroke="black" strokeWidth={strokeWidth} />
      </svg>
      {tooltipMarkup}
    </Fragment>
  );
};

export default Circle;