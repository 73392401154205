import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedDate, FormattedNumber, injectIntl} from 'react-intl';

class InvoiceRow extends Component {
  handleDownloadClick = () => {
    this.props.onDownloadClick(this.props.invoice);
  };
  
  render(){
    const {invoice, intl} = this.props;
    
    return (
      <tr>
        <td data-test-id={`invoices-date-${invoice.invoiceId}`} data-label={intl.formatMessage({id: 'general.date'})}><FormattedDate value={new Date(invoice.invoiceDate)}/></td>
        <td data-test-id={`invoices-id-${invoice.invoiceId}`} data-label={intl.formatMessage({id: 'general.id'})}>{invoice.invoiceId}</td>
        <td data-test-id={`invoices-purchase-order-${invoice.invoiceId}`} data-label={intl.formatMessage({id: 'general.purchaseOrderNumberAbbrev'})}>{invoice.purchaseOrderNumber}</td>
        <td data-test-id={`invoices-order-number-${invoice.invoiceId}`} data-label={intl.formatMessage({id: 'general.orderNumber'})}>{invoice.orderNumber}</td>
        <td data-test-id={`invoices-customer-${invoice.invoiceId}`} data-label={intl.formatMessage({id: 'general.customer'})}>{invoice.customerName}</td>
        <td data-test-id={`invoices-amount-${invoice.invoiceId}`} data-label={intl.formatMessage({id: 'general.amount'})}>
          <FormattedNumber
            style='currency'  // eslint-disable-line react/style-prop-object
            currency='USD'
            value={invoice.invoiceAmount}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        </td>
        <td data-test-id={`invoices-actions-${invoice.invoiceId}`} data-label={intl.formatMessage({id: 'general.actions'})}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div onClick={this.handleDownloadClick}>
            <FontAwesomeIcon icon='file-download' className='is-mock-link text-secondary'/>
          </div>
        </td>
      </tr>
    );
  }
}

InvoiceRow.propTypes = {
  invoice: PropTypes.object.isRequired,
  onDownloadClick: PropTypes.func.isRequired
};

export default injectIntl(InvoiceRow);