import {Breadcrumb, BreadcrumbItem} from '@met/react-components';
import React from 'react';
import {useDispatch} from 'react-redux';
import {toastr} from "react-redux-toastr";
import {Link} from 'react-router-dom';
import {Container} from 'reactstrap';

import {companyAdminActions} from '../../../store';
import {UserForm} from '../components';
import history from "../../../history";

const CompanyAdminCreateUser = () => {
  const dispatch = useDispatch();

  /***
   * handleSaveClick creates a new user in auth0
   * @param user: the user to create
   * @returns {Q.Promise<any>}
   */
  const handleSaveClick = (user) => {
    return dispatch(companyAdminActions.createUser(user))
      .then(() => toastr.success('User created', 'The user has been created.'))
      .then(() => history.push('/companyadmin'))
      .catch(response => {
        if (response.status === 409)
          toastr.warning('Cannot Create User', 'This email is already associated with a Milwaukee Account.')
        else
          toastr.error('Something went wrong', 'Unable to create user at this time. Please try again later.')
      });
  }
  
  return (
    <Container>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to='/companyadmin'>Company Admin Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          New User
        </BreadcrumbItem>
      </Breadcrumb>
      <UserForm onSaveClick={handleSaveClick} />
    </Container>
  )
};

export default CompanyAdminCreateUser;