import React, {Component} from 'react';

class LoadingDots extends Component {
  render() {
    return (
      <div className='loading-dots'>
        <div className='bounce1'/>
        <div className='bounce2'/>
        <div className='bounce3'/>
      </div>
    );
  }
}

export default LoadingDots;