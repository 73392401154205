import createFetchAction from './createFetchAction';

export const NEW_WISHLIST = 'NEW_WISHLIST';
export const NEW_WISHLIST_SUCCESS = 'NEW_WISHLIST_SUCCESS';
export const NEW_WISHLIST_ERROR = 'NEW_WISHLIST_ERROR';

export const GET_WISHLISTS = 'GET_WISHLISTS';
export const GET_WISHLISTS_SUCCESS = 'GET_WISHLISTS_SUCCESS';
export const GET_WISHLISTS_ERROR = 'GET_WISHLISTS_ERROR';

export const GET_WISHLIST = 'GET_WISHLIST';
export const GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS';
export const GET_WISHLIST_ERROR = 'GET_WISHLIST_ERROR';

export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';
export const ADD_TO_WISHLIST_ERROR = 'ADD_TO_WISHLIST_ERROR';

export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const REMOVE_FROM_WISHLIST_SUCCESS = 'REMOVE_FROM_WISHLIST_SUCCESS';

export const UPDATE_WISHLIST = 'UPDATE_WISHLIST';
export const UPDATE_WISHLIST_SUCCESS = 'UPDATE_WISHLIST_SUCCESS';
export const UPDATE_WISHLIST_ERROR = 'UPDATE_WISHLIST_ERROR';

export const DELETE_WISHLIST = 'DELETE_WISHLIST';
export const DELETE_WISHLIST_SUCCESS = 'DELETE_WISHLIST_SUCCESS';
export const DELETE_WISHLIST_ERROR = 'DELETE_WISHLIST_ERROR';

export const UPDATE_WISHLIST_QUANTITY = 'UPDATE_WISHLIST_QUANTITY';
export const UPDATE_WISHLIST_QUANTITY_SUCCESS = 'UPDATE_WISHLIST_QUANTITY_SUCCESS';
export const UPDATE_WISHLIST_QUANTITY_ERROR = 'UPDATE_WISHLIST_QUANTITY_ERROR';

export const MOVE_WISHLIST_TO_CART = 'MOVE_WISHLIST_TO_CART';
export const MOVE_WISHLIST_TO_CART_SUCCESS = 'MOVE_WISHLIST_TO_CART_SUCCESS';
export const MOVE_WISHLIST_TO_CART_ERROR = 'MOVE_WISHLIST_TO_CART_ERROR';

export const initialState = {
  newWishlist: {
    isProcessing: false,
    isError: false
  },
  getWishlists: {
    wishlists: [],
    isLoading: false,
    isError: false
  },
  getWishlist: {
    wishlist: {
      id: 0,
      wishlistLines: []
    },
    isLoading: false,
    isError: false
  },
  addToWishlist: {
    isProcessing: false,
    isError: false
  },
  removeFromWishlist: {
    isProcessing: false
  },
  updateWishlist: {
    isProcessing: false,
    isError: false
  },
  deleteWishlist: {
    isProcessing: false,
    isError: false
  },
  updateWishlistQuantity: {
    isProcessing: false,
    isError: false
  },
  moveWishlistToCart: {
    isProcessing: false,
    isError: false
  },
};

export const actionCreators = {
  newWishlist: (name) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/wishlists`,
      body: { name },
      startAction: NEW_WISHLIST
    });
  },
  getWishlists: () => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/wishlists`,
      startAction: GET_WISHLISTS
    });
  },
  getWishlist: (wishlistId) => {
    return createFetchAction({
      method: 'GET',
      url: wishlistId ? `/api/v1/wishlists/${wishlistId}` : `/api/v1/wishlists/later`,
      startAction: GET_WISHLIST
    });
  },
  addToWishlist: (wishlistId, sku, skuAlias, description, quantity, nextValidQuantityAllowed, lowestQuantityAllowed, highestQuantityAllowed, isCustomLogo) => {
    return createFetchAction({
      method: 'PATCH',
      url: `/api/v1/wishlists/add`,
      body: {
        wishlistId,
        sku,
        skuAlias,
        description,
        quantity,
        nextValidQuantityAllowed,
        lowestQuantityAllowed,
        highestQuantityAllowed,
        isCustomLogo
      },
      startAction: ADD_TO_WISHLIST
    });
  },
  removeFromWishlist: (sku, wishlistId, customLogoRefId) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/api/v1/wishlists`,
      body: {
        sku,
        wishlistId,
        customLogoRefId
      },
      startAction: REMOVE_FROM_WISHLIST
    });
  },
  updateWishlist: (wishlistId, name) => {
    return createFetchAction({
      method: 'PATCH',
      url: `/api/v1/wishlists/${wishlistId}`,
      body: {
        name
      },
      startAction: UPDATE_WISHLIST
    });
  },
  deleteWishlist: (wishlistId) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/api/v1/wishlists/${wishlistId}`,
      startAction: DELETE_WISHLIST
    });
  },
  updateWishlistQuantity: (wishlistId, sku, quantity, customLogoRefId) => {
    return createFetchAction({
      method: 'PATCH',
      url: `/api/v1/wishlists/updateQuantity`,
      body: {
        wishlistId,
        sku,
        quantity,
        customLogoRefId
      },
      startAction: UPDATE_WISHLIST_QUANTITY
    });
  },
  moveToCart: (sku, customLogoRefId, wishlistId, orderLineIds) =>{
    return createFetchAction({
      method: 'PUT',
      url: `/api/v1/wishlists/moveToCart`,
      body:{
        wishlistId,
        sku,
        customLogoRefId,
        orderLineIds
      },
      startAction: MOVE_WISHLIST_TO_CART
    })
  },
};

export const reducer = (state, action) => {
  state = state || initialState;
  
  switch (action.type) {
    case NEW_WISHLIST:
      return {
        ...state,
        newWishlist: {
          isProcessing: true,
          isError: false
        }
      };
    case NEW_WISHLIST_SUCCESS:
      return {
        ...state,
        newWishlist: {
          isProcessing: false,
          isError: false
        }
      };
    case NEW_WISHLIST_ERROR:
      return {
        ...state,
        newWishlist: {
          isProcessing: false,
          isError: true
        }
      };
    case GET_WISHLISTS:
      return {
        ...state,
        getWishlists: {
          wishlists: initialState.getWishlists.wishlists,
          isLoading: true,
          isError: false
        }
      };
    case GET_WISHLISTS_SUCCESS:
      return {
        ...state,
        getWishlists: {
          wishlists: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case GET_WISHLISTS_ERROR:
      return {
        ...state,
        getWishlists: {
          isLoading: false,
          isError: true
        }
      };
    case GET_WISHLIST:
      return {
        ...state,
        getWishlist: {
          ...state.getWishlist,
          isLoading: true,
          isError: false
        }
      };
    case GET_WISHLIST_SUCCESS:
      return {
        ...state,
        getWishlist: {
          wishlist: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case GET_WISHLIST_ERROR:
      return {
        ...state,
        getWishlist: {
          ...state.getWishlist,
          isLoading: false,
          isError: true
        }
      };
    case ADD_TO_WISHLIST:
      return {
        ...state,
        addToWishlist: {
          isProcessing: true,
          isError: false
        }
      };
    case ADD_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        addToWishlist: {
          isProcessing: false,
          isError: false
        }
      };
    case ADD_TO_WISHLIST_ERROR:
      return {
        ...state,
        addToWishlist: {
          isProcessing: false,
          isError: true
        }
      };
    case REMOVE_FROM_WISHLIST:
      return {
        ...state,
        removeFromWishlist: {
          isProcessing: true
        }
      };
    case REMOVE_FROM_WISHLIST_SUCCESS:
      return {
        ...state,
        removeFromWishlist: {
          isProcessing: false
        }
      };
    case UPDATE_WISHLIST:
      return {
        ...state,
        updateWishlist: {
          isProcessing: true,
          isError: false
        }
      };
    case UPDATE_WISHLIST_SUCCESS:
      return {
        ...state,
        updateWishlist: {
          isProcessing: false,
          isError: false
        }
      };
    case UPDATE_WISHLIST_ERROR:
      return {
        ...state,
        updateWishlist: {
          isProcessing: false,
          isError: true
        }
      };
    case DELETE_WISHLIST:
      return {
        ...state,
        deleteWishlist: {
          isProcessing: true,
          isError: false
        }
      };
    case DELETE_WISHLIST_SUCCESS:
      return {
        ...state,
        deleteWishlist: {
          isProcessing: false,
          isError: false
        }
      };
    case DELETE_WISHLIST_ERROR:
      return {
        ...state,
        deleteWishlist: {
          isProcessing: false,
          isError: true
        }
      };
    case UPDATE_WISHLIST_QUANTITY:
      return {
        ...state,
        updateWishlistQuantity: {
          isProcessing: true,
          isError: false
        }
      };
    case UPDATE_WISHLIST_QUANTITY_SUCCESS:
      return {
        ...state,
        updateWishlistQuantity: {
          isProcessing: false,
          isError: false
        }
      };
    case UPDATE_WISHLIST_QUANTITY_ERROR:
      return {
        ...state,
        updateWishlistQuantity: {
          isProcessing: false,
          isError: true
        }
      };
    case MOVE_WISHLIST_TO_CART:
      return {
        ...state,
        moveWishlistToCart: {
          isProcessing: true,
          isError: false
        }
      };
    case MOVE_WISHLIST_TO_CART_SUCCESS:
      return {
        ...state,
        moveWishlistToCart: {
          isProcessing: false,
          isError: false
        }
      };
    case MOVE_WISHLIST_TO_CART_ERROR:
      return {
        ...state,
        moveWishlistToCart: {
          isProcessing: false,
          isError: true
        }
      };
    default:
      break;
  }
  
  return state;
};