import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, { Component } from "react";
import {FormattedDate, injectIntl} from 'react-intl';
import {
  Col,
  Container,
  ListGroupItem,
  Row
} from 'reactstrap';
import {Button, Link} from '../../components';
import {downloadBlob} from '../../helpers/downloadblob';

class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tryDownloadMemo: false
    };
  }

  getCreditMemo = () => {
    this.setState({
      ...this.state,
      tryDownloadMemo: true
    }, () => {
      this.props.getCreditMemo(this.props.warranty.id, this.props.accountNumber)
      .then((blob) => {
        downloadBlob('Credit Memo.pdf', blob);
        this.setState({
          ...this.state,
          tryDownloadMemo: false
        });
      });
    });
  }


  render() {
    let {warranty} = this.props;
    return (
    <ListGroupItem className='warranty-preview'>
      <Container fluid className='p-2'>
        <Row>
          <Col>
            <div className='warranty-info label'>Date</div>
              <div data-test-id={`warranty-claim-search-result-date-${warranty.id}`} className='warranty-info value'>
              <FormattedDate
                value={warranty.enteredDate}
                year='numeric'
                month='short'
                day='2-digit'
                weekday='short'/>
            </div>
          </Col>
          <Col>
            <div className='warranty-info label'>ASC</div>
            <div data-test-id={`warranty-claim-search-result-asc-${warranty.id}`} className='warranty-info value'>{warranty.account ? warranty.account.number : ""}</div>
          </Col>
          <Col>
            <div className='warranty-info label'>Name</div>
            <div data-test-id={`warranty-claim-search-result-name-${warranty.id}`} className='warranty-info value'>{warranty.customer ? warranty.customer.name : ""}</div>
          </Col>
          <Col>
            <div className='warranty-info label'>WO/Invoice #</div>
            <div data-test-id={`warranty-claim-search-result-invoice-number-${warranty.id}`} className='warranty-info value'>{warranty.workReferenceNumber}</div>
          </Col>
          <Col>
            <div className='warranty-info label'>Claim #</div>
            <div data-test-id={`warranty-claim-search-result-claim-number-${warranty.id}`} className='warranty-info value'>{"EW-" + warranty.id}</div>
          </Col>
          <Col>
            <div className='warranty-info label'>Status</div>
            <div data-test-id={`warranty-claim-search-result-status-${warranty.id}`} className='warranty-info value'>{warranty.status}</div>
          </Col>
          <Col md='auto' className='mb-2 my-lg-auto align-self-center'>
            <Container>
              <Row className='justify-content-center'>
                <Col xs='auto' className='mt-3 mt-xl-0'>
                    <Button data-test-id={`warranty-claim-search-result-view-${warranty.id}`} outline color='primary' tag={Link} to={`warranty/${warranty.id}`}>View</Button>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs='auto' className='mb-2 my-lg-autoalign-self-center'>
            <div>
              {warranty.creditMemo && warranty.creditMemo.memoNumber ? 
                <FontAwesomeIcon icon='file-download' className='is-mock-link text-secondary' onClick={this.getCreditMemo} />
                : <span>&nbsp;&nbsp;&nbsp;</span>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </ListGroupItem>)
  }
}

export default injectIntl(SearchResult);