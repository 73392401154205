export default {
  logEvent: (utagData, forceLog) => {
    try {
      if (isProd() || forceLog || forceLogOverride)
        window.utag.link(utagData);
    }catch (e) {
      console.error("error logging tealium event: " + e);
    }
  },  
  logVisit: (utagData, forceLog) => {
    try {
      if (isProd() || forceLog || forceLogOverride)
        window.utag.view(utagData);
    }catch (e) {
      console.error("error logging tealium visit: " + e);
    }
  }
}

const isProd = () => {
  return window.location.hostname.toLowerCase() === 'connect.milwaukeetool.com' || window.location.hostname.toLowerCase() === 'customerportal.milwaukeetool.com';
};
const forceLogOverride = false;