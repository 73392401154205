import React, {Component} from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

class PageLoader extends Component {
  render() {
    const pageLoaderClasses = className(
      'page-loader',
      this.props.marginSize
    );
    
    return (
      <div className={pageLoaderClasses}>
        <div className='double-bounce1'/>
        <div className='double-bounce2'/>
      </div>
    );
  }
}

PageLoader.propTypes = {
  style: PropTypes.object,
  marginSize: PropTypes.oneOf(['sm', 'md', 'lg'])
};

export default PageLoader;