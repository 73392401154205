import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Card, CardBody, Col, Grid, Row} from '@met/react-components';
import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {ListGroup, ListGroupItem} from 'reactstrap';
import {bindActionCreators} from 'redux';

import {
  configurationActions
} from '../../store';
import history from '../../history';
import BaseLayout from './BaseLayout';
import user from '../../helpers/user';

const ProfileLayout = (props) => {
  // get the configuration on initial page load in order to access any feature toggles needed
  useEffect(() => {
    props.configurationActions.getConfig();
  }, []);

  const handleGeneralTabClick = () => {
    history.push('/profile')
  };
  
  const handleShippingInfoTabClick = () => {
    history.push('/profile/shippinginfo');
  };

  const handleCreditCardTabClick = () => {
    history.push('/profile/creditcards');
  };

  let title = props.title && (
    <Row className='my-3'>
      <Col>
        <h2>{props.title}</h2>
      </Col>
    </Row>
  );

  let hasAccountNumber = props.account.currentAccount 
    && props.account.currentAccount.accountDetails 
    && !props.account.currentAccount.isLoading 
    && !!props.account.currentAccount.accountDetails.accountNumber
    
  let canShowCreditCards = props.configuration.featureToggles.PCI && (user.isCreditCardUser() || !hasAccountNumber);

  return (
    <BaseLayout {...props}>
      <Grid id='profile-container'>
        {title}
        <div className="content-wrapper animate-bottom-fade-in">
          <Card className="mt-0">
            <Row>
              <Col xs={12} sm={4} className="pr-sm-0 border-right">
                {user.isConnectUser() &&
                <ListGroup flush>
                  <ListGroupItem data-test-id='profile-general-tab' active={props.location.pathname === '/profile'} action onClick={handleGeneralTabClick}>
                    <FontAwesomeIcon icon='info-circle' /> General
                  </ListGroupItem>
                </ListGroup>
                }
                {user.isConnectUser() &&
                <ListGroup flush>
                  <ListGroupItem data-test-id='profile-shipping-tab' active={props.location.pathname.indexOf('/profile/shippinginfo') === 0} action onClick={handleShippingInfoTabClick}>
                    <FontAwesomeIcon icon='truck' /> Shipping Info
                  </ListGroupItem>
                </ListGroup>
                }
                {canShowCreditCards &&
                  <ListGroup flush>
                    <ListGroupItem data-test-id='profile-card-tab' active={props.location.pathname.indexOf('/profile/creditcard') === 0} action onClick={handleCreditCardTabClick}>
                      <FontAwesomeIcon icon='credit-card' /> Credit Cards
                    </ListGroupItem>
                  </ListGroup>
                }
              </Col>
              <Col xs={12} sm={8}>
                <CardBody>
                  {props.children}
                </CardBody>
              </Col>
            </Row>
          </Card>
        </div>
      </Grid>
    </BaseLayout>
  );
};

const mapStateToProps = state => {
  return {
    configuration: state.configuration,
    account: state.account
  }
}

const mapDispatchToProps = dispatch => {
  return {
    configurationActions: bindActionCreators(configurationActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileLayout));