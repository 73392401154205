import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardHeader } from '@met/react-components'
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Button from '../../components/Button';

class EmployeePurchase extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card className='info-card'>
        <CardHeader className='header-primary d-flex align-items-center'>
          <FontAwesomeIcon icon='bolt' className='is-color-dark-gray mr-2'/>
          <FormattedMessage id='home.employeePurchaseCardTitle'/>
        </CardHeader>
        <CardBody>
          <Button data-test-id='search-now-button' type='button' size='lg' block className='' color='primary' onClick={this.props.onClick}>Search Now!</Button>
        </CardBody>
      </Card>
    );
  }
}

export default injectIntl(EmployeePurchase);