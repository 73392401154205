const checkout = {
  shippingAddressTitle: 'Dirección de Envío',
  shippingSpeedTitle: 'Velocidad de Envio',
  shippingSpeedMessage: `
      Los pedidos realizados para envío dentro de los Estados Unidos pueden elegir seleccionar una Velocidad de envío acelerada.
      Recuerde que se le facturarán todos los cargos de flete asociados con este servicio en su factura.
      "Cobro" o "facturación de terceros" no están disponibles en línea.
      {br}{br}
      Los pedidos de envío aéreo recibidos antes de las 2:00 pm, hora central, se enviarán el mismo día hábil.
      Los fines de semana y festivos están excluidos.
      Los envíos regulares por tierra se enviarán dentro de 1-2 días hábiles.
      {br}{br}
    `,
  disabledShippingSpeedMessage: `
      *En este momento, no estamos procesando el envío aéreo o urgente al día siguiente. Comuníquese con su representante de ventas si tiene alguna pregunta.
      {br}{br}
  `,
  customLogoShippingSpeedMessage: `
      Nuestro socio de personalización maneja los envíos de logotipo personalizado y las estimaciones se enviarán al correo electrónico proporcionado anteriormente.
    `,
  paymentMethodTitle: 'Método de Pago',
  requestedShipDateTitle: 'Fecha de Envío Solicitada',
  requestedShipDateMessage: 'Las fechas de envío están determinadas por la disponibilidad del producto, la distribución, el tiempo de entrega y los requisitos de envío.',
  orderSummaryTitle: 'Resumen del pedido',
  errorSubmittingOrder: 'Hubo un error al enviar su pedido. Por favor, inténtelo de nuevo más tarde.',
  validationWarningMessage: 'Purchase Order # is a required field',
  paymentMethodRequiredTitle: 'Método de pago requerido',
  paymentMethodRequiredMessage: 'Debe seleccionar un método de pago antes de la salida.',
  addressRequiredTitle: 'Dirección de envío requerida',
  addressRequiredMessage: 'Debe seleccionar una dirección de envío antes de la salida.',
  addressRegionLockedTitle: 'Logotipo personalizado no disponible',
  addressRegionLockedMessage: 'Tiene artículos en su carrito que no pueden enviarse fuera de Estados Unidos.'
};

export default checkout;