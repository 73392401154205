import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Dropdown } from '@met/react-components';
import {countriesActions} from '../../store';

const CountriesDropdown = (props) => {
  const validationMessage = 'Please select a Country';
  
  useEffect(() => {
    props.countriesActions.getCountries();
  }, []);

  const handleDropdownChange = (option) => {
    props.onChange && props.onChange(option);
  };

  return (
    <Dropdown
      options={props.countries || []}
      getOptionLabel={(option) => (option.name)}
      getOptionValue={(option) => (option.nameNormalized)}
      onChange={handleDropdownChange}
      invalidMsg={validationMessage}
      invalid={props.invalid}
      value={props.countries.filter(option => option.nameNormalized === props.value)}
      placeholder='Select Country...'
    />
  );
};

CountriesDropdown.propTypes = {
  onChange: PropTypes.func,
  trySubmit: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    countries: state.countries.countries
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countriesActions: bindActionCreators(countriesActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountriesDropdown);