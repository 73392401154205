import {Modal, ModalBody, ModalContent} from '@met/react-components';
import React, {useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';

const heartBeat  = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
`;

const animateLineTip = keyframes`
  0% {
    background-color: #fff;
    top: 1.1875em;
    left: .0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    background-color: #28a745;
    top: 2.8125em;
    left: .875em;
    width: 1.5625em;
  }
`;

const animateLineLong = keyframes`
  0% {
    background-color: #fff;
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    background-color: #28a745;
    top: 2.375em;
    right: .5em;
    width: 2.9375em;
  }
`;

const animateCircularLine = keyframes`
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
`;

const MessageHeader = styled.div`
  text-align: center;
  font-size: 1.55rem;
  font-weight: 600;
  margin-bottom: .35rem;
`;

const Message = styled.div`
  text-align: center;
  font-size: 1.3rem;

  & > div {
    display: block;
    position: relative;

    &.heartBeat {
      animation-name: ${heartBeat};
      animation-duration: 1.3s;
      animation-timing-function: ease-in-out;
    }
  }
`;

const SuccessIconContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: .75em auto 1.15em;
  zoom: normal;
  border: .25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  user-select: none;
  display: flex;
  border-color: #28a745;

  :before {
    display: flex;
    align-items: center;
    height: 92%;
    font-size: 3.75em;
  }
`;

const SuccessIconCircleLineLeft = styled.div`
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
  background-color: rgb(255, 255, 255);
`;

const SuccessIconCircleLineRight = styled.div`
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
  background-color: rgb(255, 255, 255);
  animation-name: ${animateCircularLine};
  animation-timing-function: ease-in;  
  animation-duration: 4.25s;  
  animation-delay: .2s;
`;

const SuccessIconFix = styled.div`
  position: absolute;
  z-index: 1;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg);
  background-color: rgb(255, 255, 255);
`;

const SuccessIconLineTip = styled.span`
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #fff;
  top: 2.875em;
  left: .875em;
  width: 1.5625em;
  transform: rotate(45deg);
  animation-name: ${animateLineTip};
  animation-duration: .75s;  
  animation-delay: .2s;
  animation-fill-mode: forwards;
`;

const SuccessIconLineLong = styled.span`
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #fff;
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg);
  animation-name: ${animateLineLong};
  animation-duration: .75s;  
  animation-delay: .2s;
  animation-fill-mode: forwards;
`;

const SuccessIconRing = styled.div`
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: .25em solid rgba(40, 167, 69, .35);
  border-radius: 50%;
`;

const AnimatedSuccessIcon = () => (
  <SuccessIconContainer>
    <SuccessIconCircleLineLeft />
    <SuccessIconLineTip />
    <SuccessIconLineLong />
    <SuccessIconRing />
    <SuccessIconFix />
    <SuccessIconCircleLineRight />
  </SuccessIconContainer>
);

const PaymentConfirmationModal = () => {
  const [animationClass, setAnimationClass] = useState(null);

  useEffect(() => {
    if (animationClass === 'heartBeat') {
      setTimeout(() => setAnimationClass(null), 1300);
    }
  }, [animationClass]);

  return (
    <Modal centered={true} size={'md'}>
      <ModalContent>
        <ModalBody>
          <AnimatedSuccessIcon />
          <MessageHeader>Transaction Complete!</MessageHeader>
          <Message>
            <div className={animationClass}>
              Please close the browser window/tab
            </div>
          </Message>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentConfirmationModal;