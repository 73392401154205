import { Tabs, Tab } from "@met/react-components";
import Enumerable from 'linq';
import React, { Component, Fragment } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, Checkbox, Link, PageLoader } from '../../../components/';
import user from '../../../helpers/user';
import { productsActions, productSearchActions } from '../../../store';
import SearchableFlagRow from './SearchableFlagRow';
import { injectIntl } from "react-intl";

class ProductAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sku: '',
      isHidden: false,
      isMarketingOnly: false,
      sdkProduct: {},
      rawProduct: {},
      marketingProductData: {},
      axProductData: {},
      oracleProductData: {},
      highJumpProductData: {},
      automaticOverrides: {},
      overrides: {},
      isFeatured: false,
      featuredOrder: undefined
    };
  }

  componentDidMount() {
    this.getProduct();
    this.getIndexProduct();
  }

  mergeOverrides = (overrides, data) => {
    let overrideKeys = Object.keys(overrides || {});
    let dataKeys = Object.keys(data);

    let updatedData = { ...data };

    for (let key in overrideKeys) {
      if (dataKeys.indexOf(overrideKeys[key]) >= 0) {
        updatedData[overrideKeys[key]] = overrides[overrideKeys[key]];
      }
    }

    return updatedData;
  };

  getProduct = () => {
    this.props.getProduct(this.props.match.params.skuAlias)
      .then(payload => {
        //make sure there is valid data (just in case of key discrepancies)
        let validData = payload.data !== null && payload.data.rawProduct !== null;
        let obj = (validData && payload.data.rawProduct.manualOverrides) || {};
        Object.keys(obj).forEach(key => (obj[key] === undefined || obj[key] === null) && delete obj[key]); // Clear out any null props from server
        let sortedOracleProductsData = payload.data.rawProduct?.oracleProducts?.filter(oracleProduct => oracleProduct?.organizationCode == "MT")
          .concat(payload.data.rawProduct?.oracleProducts?.filter(oracleProduct => oracleProduct?.organizationCode == "2120"))
          .concat(payload.data.rawProduct?.oracleProducts?.filter(oracleProduct => oracleProduct?.organizationCode !== "MT" && oracleProduct?.organizationCode !== "2120")
            .sort((a, b) => a.organizationCode > b.organizationCode ? 1 : -1)
          );

        this.setState({
          ...this.state,
          sku: payload.data.sku,
          isFeatured: payload.data.isFeatured,
          featuredOrder: payload.data.featuredOrder,
          isMarketingOnly: payload.data.isMarketingOnly,
          isHidden: payload.data.rawProduct && payload.data.rawProduct.manualOverrides && payload.data.rawProduct.manualOverrides.IsHidden,
          sdkProduct: payload.data.sdkProduct || {},
          rawProduct: payload.data.rawProduct,
          marketingProductData: this.mergeOverrides(obj, (payload.data.rawProduct ? payload.data.rawProduct.marketingProductData : {}) || {}),
          axProductData: this.mergeOverrides(obj, (payload.data.rawProduct ? payload.data.rawProduct.axProductData : {}) || {}),
          oracleProductData: this.mergeOverrides(obj, (payload.data.rawProduct ? sortedOracleProductsData : {}) || {}),
          highJumpProductData: this.mergeOverrides(obj, (payload.data.rawProduct ? payload.data.rawProduct.highJumpProductData : {}) || {}),
          automaticOverrides: (payload.data.rawProduct ? payload.data.rawProduct.automaticOverrides : {}) || {},
          overrides: obj
        });
      });
  };

  getIndexProduct = () => {
    this.props.getIndexProduct(this.props.match.params.skuAlias);
  };

  handleIsFeaturedChange = () => {
    this.setState({
      ...this.state,
      isFeatured: !this.state.isFeatured
    });
  };

  handleHiddenChange = () => {
    this.setState({
      ...this.state,
      isHidden: !this.state.isHidden
    });
  };

  handleOverridesJsonEdit = ({ updated_src, name, namespace, new_value, existing_value }) => {
    let overrides = this.state.overrides;

    if (updated_src[name] !== null && updated_src[name] !== undefined) {
      overrides[name] = updated_src[name];
    } else {
      delete overrides[name];
    }

    let newState = {
      ...this.state,
      marketingProductData: this.mergeOverrides(overrides, this.state.marketingProductData),
      axProductData: this.mergeOverrides(overrides, this.state.axProductData),
      oracleProductData: this.mergeOverrides(overrides, this.state.oracleProductData),
      highJumpProductData: this.mergeOverrides(overrides, this.state.highJumpProductData),
      overrides: overrides
    }

    this.setState(newState);
  }

  handleJsonEdit = (stateName) => ({ updated_src, name, namespace, new_value, existing_value }) => {
    let sdkProduct = this.state[stateName];
    let overrides = this.state.overrides;

    if (namespace !== undefined && namespace.length > 0) {
      let noMoOverrides = Enumerable.from(updated_src[namespace[0]]).except(sdkProduct[namespace[0]]).toArray().length === 0;
      if (noMoOverrides) {
        overrides[namespace[0]] = null;
        delete overrides[namespace[0]];
      }
      else
        overrides[namespace[0]] = updated_src[namespace[0]];
    }
    else if (name !== undefined && name.length > 0) {
      let noMoOverrides = sdkProduct[name] === updated_src[name];
      if (noMoOverrides) {
        overrides[name] = null;
        delete overrides[name];
      }
      else
        overrides[name] = updated_src[name];
    }

    let newState = {
      ...this.state,
      overrides: overrides
    }

    newState[stateName] = this.mergeOverrides(overrides, newState[stateName]);

    this.setState(newState);
  };

  handleSaveChangesClick = () => {
    this.updateProduct();
  };

  updateProduct = () => {
    this.props.updateProduct(
      this.state.sku,
      this.state.overrides,
      this.state.isHidden,
      this.state.isFeatured,
      this.state.featuredOrder,
      this.state.isMarketingOnly
    )
      .then(() => {
        toastr.success('Product was successfully updated!');
        this.getProduct();
        this.getIndexProduct();
      })
      .catch(() => {
        toastr.error('An error occurred saving Product');
      });
  };

  render() {
    const { product, isLoading } = this.props.product.getProduct;
    const { isProcessing } = this.props.product.updateProduct;
    const indexProduct = this.props.productSearch.getIndexProduct.product;

    if (isLoading && !product) {
      return <PageLoader />;
    }

    if (!isLoading && !product) {
      return (
        <div>Nothing to see here!</div>
      );
    }

    let productSearchMarkup;
    let warrantySearchMarkup;

    if (product && product.sdkProduct && indexProduct) {
      let isHidden = product.isHidden;
      let isRestricted = product.sdkProduct.isRestricted;
      let isValidCommercialization = product.sdkProduct.isValidCommercialization;
      let isValidPackingGroup = product.sdkProduct.isValidPackingGroup;
      let hasPricing = product.sdkProduct.hasPricing;
      let isValidItemType = product.sdkProduct.isValidItemType;
      let isValidItemGroup = product.sdkProduct.isValidItemGroup;
      let isStopped = product.sdkProduct.isStopped;
      let isOemTool = product.sdkProduct.isOemTool;
      let isInDpl = product.sdkProduct.isInDpl;
      let isPart = product.sdkProduct.isPart;
      let isSitecoreOnlyProduct = product.sdkProduct.validSearchProfiles.indexOf("Marketing") !== -1;

      let productSearchValidRows = (
        <Fragment>
          {isHidden !== null && !isHidden && <SearchableFlagRow valid={true} label='Is not hidden' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotHidden' })} />}
          {isRestricted !== null && !isRestricted && <SearchableFlagRow valid={true} label='Is not restricted' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotRestricted' })} />}
          {isValidCommercialization !== null && isValidCommercialization && <SearchableFlagRow valid={true} label='Has valid commercialization' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidCommercialization' })} />}
          {isValidPackingGroup !== null && isValidPackingGroup && <SearchableFlagRow valid={true} label='Has a valid packing group' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidPackingGroup' })} />}
          {hasPricing !== null && hasPricing && <SearchableFlagRow valid={true} label='Has pricing' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasPricing' })} />}
          {isValidItemType !== null && isValidItemType && <SearchableFlagRow valid={true} label='Has a valid item type' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidItemType' })} />}
          {isValidItemGroup !== null && isValidItemGroup && <SearchableFlagRow valid={true} label='Has a valid item group' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidItemGroup' })} />}
          {isStopped !== null && !isStopped && <SearchableFlagRow valid={true} label='Is not stopped' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotStopped' })} />}
          {isOemTool !== null && !isOemTool && <SearchableFlagRow valid={true} label='Is not an OEM tool' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotAnOemTool' })} />}
          {((isInDpl !== null && isInDpl) || isPart) && <SearchableFlagRow valid={true} label='Is a part OR is listed in the DPL' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isPartIsListedInTheDpl' })} />}
        </Fragment>
      );

      let productSearchInvalidRows = (
        <Fragment>
          {isHidden !== null && isHidden && <SearchableFlagRow valid={false} label='Is hidden' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotHidden' })} />}
          {isRestricted !== null && isRestricted && <SearchableFlagRow valid={false} label='Is restricted' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotRestricted' })} />}
          {isValidCommercialization !== null && !isValidCommercialization && <SearchableFlagRow label='Does not have commercialization' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidCommercialization' })} />}
          {isValidPackingGroup !== null && !isValidPackingGroup && <SearchableFlagRow valid={false} label='Does not have a valid packing group' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.HasValidPackingGroup' })} />}
          {hasPricing !== null && !hasPricing && <SearchableFlagRow valid={false} label='Does not have pricing' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasPricing' })} />}
          {isValidItemType !== null && !isValidItemType && <SearchableFlagRow valid={false} label='Does not have a valid item type' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidItemType' })} />}
          {isValidItemGroup !== null && !isValidItemGroup && <SearchableFlagRow valid={false} label='Does not have a valid item group' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidItemGroup' })} />}
          {isStopped !== null && isStopped && <SearchableFlagRow valid={false} label='Is stopped' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotStopped' })} />}
          {isOemTool !== null && isOemTool && <SearchableFlagRow valid={false} label='Is an OEM Tool' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotAnOemTool' })} />}
          {isInDpl !== null && (!isInDpl && !isPart) && <SearchableFlagRow valid={false} label='Is not a part AND is not listed in the DPL' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isPartIsListedInTheDpl' })} />}
        </Fragment>
      );

      let productSearchShow = indexProduct.showInProductSearch && (
        <span><span className='is-color-green'>WILL SHOW</span> in search results because it...</span>
      );

      let productSearchNoShowMarkup = !indexProduct.showInProductSearch && (
        <Fragment>
          <div className='mt-2'>BUT, this product <span className='is-color-red'>WILL NOT SHOW</span> in search results because it...</div>
          {productSearchInvalidRows}
        </Fragment>
      );

      productSearchMarkup = (
        <FormGroup className='mb-4 mb-md-0'>
          <div>This product {productSearchShow}...</div>
          {productSearchValidRows}
          {productSearchNoShowMarkup}
        </FormGroup>
      );

      let warrantySearchValidRows = (
        <Fragment>
          {!isSitecoreOnlyProduct && <SearchableFlagRow valid={true} label='Is in AX' />}
          {isRestricted !== null && !isRestricted && <SearchableFlagRow valid={true} label='Is not restricted' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotRestricted' })} />}
          {isValidCommercialization !== null && isValidCommercialization && <SearchableFlagRow valid={true} label='Has valid commercialization' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidCommercialization' })} />}
          {isValidPackingGroup !== null && isValidPackingGroup && <SearchableFlagRow valid={true} label='Has a valid packing group' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidPackingGroup' })} />}
          {hasPricing !== null && hasPricing && <SearchableFlagRow valid={true} label='Has pricing' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasPricing' })} />}
          {isValidItemType !== null && isValidItemType && <SearchableFlagRow valid={true} label='Has a valid item type' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidItemType' })} />}
          {isValidItemGroup !== null && isValidItemGroup && <SearchableFlagRow valid={true} label='Has a valid item group' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidItemGroup' })} />}
          {isOemTool !== null && !isOemTool && <SearchableFlagRow valid={true} label='Is not an OEM tool' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotAnOemTool' })} />}
        </Fragment>
      );

      let warrantySearchInvalidRows = (
        <Fragment>
          {isSitecoreOnlyProduct && <SearchableFlagRow valid={false} label='Is not in AX' />}
          {isRestricted !== null && isRestricted && <SearchableFlagRow valid={false} label='Is restricted' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotRestricted' })} />}
          {isValidCommercialization !== null && !isValidCommercialization && <SearchableFlagRow valid={false} label='Does not have valid commercialization' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidCommercialization' })} />}
          {isValidPackingGroup !== null && !isValidPackingGroup && <SearchableFlagRow valid={false} label='Does not have a valid packing group' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidPackingGroup' })} />}
          {hasPricing !== null && !hasPricing && <SearchableFlagRow valid={false} label='Does not have valid pricing' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasPricing' })} />}
          {isValidItemType !== null && !isValidItemType && <SearchableFlagRow valid={false} label='Does not have a valid item type' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidItemType' })} />}
          {isValidItemGroup !== null && !isValidItemGroup && <SearchableFlagRow valid={false} label='Does not have a valid item group' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.hasValidItemGroup' })} />}
          {isOemTool !== null && isOemTool && <SearchableFlagRow valid={false} label='Is an OEM tool' searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotAnOemTool' })} />}
        </Fragment>
      );

      let warrantySearchShow = indexProduct.showInWarrantySearch && (
        <span><span className='is-color-green'>WILL SHOW</span> in warranty search results because it...</span>
      );

      let warrantySearchNoShowMarkup = !indexProduct.showInWarrantySearch && (
        <Fragment>
          <div className='mt-2'>BUT, this product <span className='is-color-red'>WILL NOT SHOW</span> in warranty search results because it...</div>
          {warrantySearchInvalidRows}
        </Fragment>
      );

      warrantySearchMarkup = (
        <FormGroup className='mb-4 mb-md-0'>
          <div>This product {warrantySearchShow}...</div>
          {warrantySearchValidRows}
          {warrantySearchNoShowMarkup}
        </FormGroup>
      );
    }

    let sdkProductMarkup = this.state.isMarketingOnly || !this.state.sku ? (
      <Row>
        <Col>
          <Tabs defaultTab='sdkProduct'>
            <Tab id='sdkProduct' title='Search'>
              <ReactJson src={this.state.sdkProduct} name={null} theme='monokai' iconStyle='circle' />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    ) : (
      <Row>
        <Col>
          <Tabs defaultTab='sdkProduct'>
            <Tab id='sdkProduct' title='Search'>
              <ReactJson src={this.state.sdkProduct} name={null} theme='monokai' iconStyle='circle' />
            </Tab>
            <Tab id='marketing' title='Marketing'>
              <ReactJson src={this.state.marketingProductData} name={null} theme='monokai' iconStyle='circle'
                onEdit={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('marketingProductData')}
                onAdd={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('marketingProductData')}
                onDelete={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('marketingProductData')}
              />
            </Tab>
            <Tab id='ax' title='AX'>
              <ReactJson src={this.state.axProductData} name={null} theme='monokai' iconStyle='circle'
                onEdit={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('axProductData')}
                onAdd={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('axProductData')}
                onDelete={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('axProductData')}
              />
            </Tab>
            <Tab id='oracle' title='Oracle'>
              <ReactJson src={this.state.oracleProductData} name={null} theme='monokai' iconStyle='circle'
                onEdit={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('oracleProductData')}
                onAdd={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('oracleProductData')}
                onDelete={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('oracleProductData')}
              />
            </Tab>
            <Tab id='highjump' title='High Jump'>
              <ReactJson src={this.state.highJumpProductData} name={null} theme='monokai' iconStyle='circle'
                onEdit={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('highJumpProductData')}
                onAdd={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('highJumpProductData')}
                onDelete={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleJsonEdit('highJumpProductData')}
              />
            </Tab>
            {this.state.sdkProduct.warrantyMetadata && (
                <Tab id="warranty" title="Warranty">
                  <ReactJson
                    src={this.state.sdkProduct.warrantyMetadata}
                    name={null}
                    theme="monokai"
                    iconStyle="circle"
                  />
                </Tab>
              )}
            {this.state.sdkProduct.pricing && (
              <Tab id="pricing" title="Pricing">
                <ReactJson
                  src={this.state.sdkProduct.pricing}
                  name={null}
                  theme="monokai"
                  iconStyle="circle"
                />
              </Tab>
            )}
            <Tab id='auto' title='Auto'>
              <ReactJson src={this.state.automaticOverrides} name={null} theme='monokai' iconStyle='circle' />
            </Tab>
            <Tab id='manual' title='Manual'>
              <ReactJson src={this.state.overrides} name={null} theme='monokai' iconStyle='circle'
                onEdit={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleOverridesJsonEdit}
                onAdd={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleOverridesJsonEdit}
                onDelete={!user.isSuperAdmin() || this.state.isMarketingOnly ? undefined : this.handleOverridesJsonEdit}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    );

    let buttonMarkup = user.isSuperAdmin() && (
      <Row className='justify-content-end mt-2'>
        <Col xs='auto'>
          <Button block color='primary' isLoading={isProcessing} onClick={this.handleSaveChangesClick}>Save Changes</Button>
        </Col>
      </Row>
    );

    return (
      <div id='product-admin' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row className='my-2'>
            <Col>
              <Breadcrumb>
                <BreadcrumbItem><Link to={`admin/products`}>Products Admin</Link></BreadcrumbItem>
                <BreadcrumbItem active>{product.sku}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col sm='4'>
                        <div className='mb-1 font-weight-bold'>SKU</div>
                        <div>{product.sku}</div>
                      </Col>
                      <Col sm='4'>
                        <div className='mb-1 font-weight-bold'>Added</div>
                        <div>
                          <FormattedDate value={product.createdAt + 'Z'} />&nbsp;<FormattedTime value={product.createdAt + 'Z'} />
                        </div>
                      </Col>
                      <Col sm='4'>
                        <div className='mb-1 font-weight-bold'>Last Updated</div>
                        <div>
                          <FormattedDate value={product.updatedAt + 'Z'} />&nbsp;<FormattedTime value={product.updatedAt + 'Z'} />
                        </div>
                      </Col>
                    </Row>
                    <Row className='my-3'>
                      <Col xs='auto'>
                        <Checkbox label='Featured' isChecked={this.state.isFeatured} onToggle={this.handleIsFeaturedChange} isDisabled={!user.isSuperAdmin()} searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isFeatured' })} />
                        <Checkbox label='Hidden' isChecked={this.state.isHidden} onToggle={this.handleHiddenChange} isDisabled={!user.isSuperAdmin() || this.state.isMarketingOnly} searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotHidden' })} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='4'>
                        <FormGroup>
                          <Label for='searchProfile' className='font-weight-bold'>Search Profile</Label>
                          <Input type='text' placeholder='' value={this.state.sdkProduct.validSearchProfiles} name='searchProfile' disabled={true} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className='mb-3'>
                      <Col lg='6'>
                        <div className='mb-1 font-weight-bold'>Product Searching</div>
                        {productSearchMarkup}
                      </Col>
                      <Col lg='6' className='mt-3 mt-lg-0'>
                        <div className='mb-1 font-weight-bold'>Warranty Product Searching</div>
                        {warrantySearchMarkup}
                      </Col>
                    </Row>
                    {sdkProductMarkup}
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {buttonMarkup}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    product: state.products,
    productSearch: state.productSearch
  };
};

const mapDispatchToProps = dispatch => ({
  getProduct: (sku) => dispatch(productsActions.getProduct(sku)),
  getIndexProduct: (sku, locale) => dispatch(productSearchActions.getIndexProduct(sku, locale)),
  updateProduct: (sku, overrides, isHidden, isFeatured, featuredOrder, isMarketingOnly) => dispatch(productsActions.updateProduct(sku, overrides, isHidden, isFeatured, featuredOrder, isMarketingOnly))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductAdmin));
