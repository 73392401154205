import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import {
  Card,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import history from '../../history';

class ActiveWarrantyInfo extends Component {

  goToRejected = ()=>{
    history.push("warranty?r=true");
  };
  goToDenied = ()=>{
    history.push("warranty?d=true");
  };
  goToPending = ()=>{
    history.push("warranty?pir=true");
  };


  render() {
    return (
      <Card className='overview-card' body>
        <CardTitle className='text-uppercase'>
          <FormattedMessage id='warranty.activeWarrantyClaims'/>
        </CardTitle>
        <Row className='overview-sections'>
          <Col data-test-id='warranty-rejected' className='overview-section' onClick={this.goToRejected}>
            <div>
              <FontAwesomeIcon icon='shield-alt' className='is-color-red'/>
              <span>{this.props.rejected}</span>
            </div>
            <FormattedMessage id='warranty.claimStatus.rejected'/>
          </Col>
          <Col data-test-id='warranty-denied' className='overview-section' onClick={this.goToDenied}>
            <div>
              <FontAwesomeIcon icon='shield-alt' className='is-color-yellow'/>
              <span>{this.props.denied}</span>
            </div>
            <FormattedMessage id='warranty.claimStatus.denied'/>
          </Col>
          <Col data-test-id='warranty-pending' className='overview-section' onClick={this.goToPending}>
            <div>
              <FontAwesomeIcon icon='shield-alt' className='is-color-green'/>
              <span>{this.props.pending}</span>
            </div>
            <FormattedMessage id='warranty.claimStatus.pending'/>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default injectIntl(ActiveWarrantyInfo);