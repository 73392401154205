import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { chatScheduleActions } from '../../store';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

const Chat = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(chatScheduleActions.getChatSchedule());
  }, [dispatch]);

  const isChatOpenToday = useSelector((state) => state.chatSchedule.chatSchedule.isChatOpenToday);
  const chatOpenTimeCST = useSelector((state) => state.chatSchedule.chatSchedule.chatOpenTimeCST);
  const chatCloseTimeCST = useSelector((state) => state.chatSchedule.chatSchedule.chatCloseTimeCST);
  const offlineMessage = useSelector((state) => state.chatSchedule.chatSchedule.offlineMessage);
  const impersonatedAccount = useSelector((state) => state.account?.impersonatedAccount?.accountDetails?.accountNumber);

  const userInfo = window.localStorage.getItem('customerportal:profile');

  let name = '';
  let accountNumber = '';

  if (userInfo) {
    const parsedUserInfo = JSON.parse(userInfo);
    name = parsedUserInfo.nickname ? parsedUserInfo.nickname : '';
    const topLevelAccount = parsedUserInfo?.cachedAccountDetails?.topLevelAccount ? parsedUserInfo?.cachedAccountDetails?.topLevelAccount : '';
    accountNumber = impersonatedAccount ? impersonatedAccount : topLevelAccount;
  }

  const configValues = {
    name,
    accountNumber
  };

  const format = 'HH:mm:ss';
  const currentTimeInCST = dayjs().tz('America/Chicago').format(format);
  const isTimeOkay = chatOpenTimeCST && chatCloseTimeCST && dayjs(currentTimeInCST, format).isBetween(dayjs(chatOpenTimeCST, format), dayjs(chatCloseTimeCST, format));

  return (
    <Fragment>
      <div style={{ position: 'fixed', right: '55px', bottom: '20px' }} id={window.chatButtonId}>
        <FontAwesomeIcon icon='comments' size='2x' style={{ color: '#db011c', cursor: 'hand' }} title="Chat with a Milwaukee Tool representative"
          onClick={() => {
            window.chatOfflineMessage = offlineMessage;
            window.isChatOpen = isChatOpenToday && isTimeOkay;
            window.customChatPlugin.command('WebChat.open', window.getChatConfig(configValues));
          }} />
      </div>
    </Fragment>
  );
}

export default Chat;