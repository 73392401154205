import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import Pagination from 'react-js-pagination';
import {Table} from 'reactstrap';

import {Link} from '../../components'

class OrderLinesTable extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentPage: 1
    }
  }
  
  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      currentPage: pageNumber
    });
  };
  
  render() {
    const { intl, orderLines, linesLimit, useRemainingQuantity, paginate, isCustomLogo } = this.props;

    // TODO: Handle rendering null orderLines message

    let customLogoTableHeaderMarkup = isCustomLogo && (
        <th><FormattedMessage id='general.status' /></th>
    );

    let orderLinesEnum = orderLines && Enumerable.from(orderLines);
    if (linesLimit)
      orderLinesEnum = orderLinesEnum.skip((this.state.currentPage - 1) * linesLimit).take(linesLimit);
    
    let rows = orderLinesEnum.select(orderLine => {
      let total = orderLine.unitPrice ? orderLine.unitPrice * (useRemainingQuantity ? orderLine.remainingQuantity : orderLine.quantity) : 0;
      let recoveryDate = new Date(orderLine.recoveryDate);
      const isRecoveryDateAvailable = new Date() < recoveryDate;
      let recoveryDateMarkup = orderLine.isBackordered && (
        <div className='text-warning'>
          {isRecoveryDateAvailable ? <FormattedDate value={recoveryDate} /> : 'In Stock Date TBD'}
        </div>
      );
      let customLogoTableMarkup;
      if (isCustomLogo) {
        if (orderLine.customLogoOrderUrl) {
          customLogoTableMarkup = (
            <td>
              <a href={orderLine.customLogoOrderUrl} target='blank'>{orderLine.customLogoOrderStatus}</a>
            </td>
          );
        } else {
          customLogoTableMarkup = (
            <td>
              {orderLine.customLogoOrderStatus}
            </td>
          );
        }
      }

      return (
        <tr key={`order-lines-table-${orderLine.id}`}>
          <th scope='row' data-test-id={`order-history-sku-${orderLine.sku}`} className='text-nowrap text-primary' data-label={intl.formatMessage({id: 'general.sku'})}>
            <Link to={`products/${orderLine.skuAlias}`} locale={this.props.locale}>{orderLine.skuAlias}</Link>
          </th>
          <td data-test-id={`order-history-description-${orderLine.sku}`} data-label={intl.formatMessage({id: 'general.description'})}>
            {orderLine.description}
            {recoveryDateMarkup}
          </td>
          {customLogoTableMarkup}
          <td data-test-id={`order-history-quantity-${orderLine.sku}`} data-label={intl.formatMessage({id: 'general.quantity'})}>{useRemainingQuantity ? orderLine.remainingQuantity : orderLine.quantity}</td>
          <td data-test-id={`order-history-total-${orderLine.sku}`} data-label={intl.formatMessage({id: 'general.total'})}>
            <FormattedNumber
              style='currency'  // eslint-disable-line react/style-prop-object
              currency='USD'
              value={total}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </td>
        </tr>
      );
    }).toArray();
    
    let pagination = paginate && orderLines.length > linesLimit && (
      <div className='mb-2'>
        <Pagination
          activePage={this.state.currentPage}
          itemsCountPerPage={linesLimit}
          totalItemsCount={orderLines.length}
          pageRangeDisplayed={8}
          innerClass='pagination justify-content-center flex-wrap'
          itemClass='page-item'
          linkClass='page-link'
          nextPageText={<FontAwesomeIcon icon='angle-right' />}
          prevPageText={<FontAwesomeIcon icon='angle-left' />}
          lastPageText={<FontAwesomeIcon icon='angle-double-right'/>}
          firstPageText={<FontAwesomeIcon icon='angle-double-left'/>}
          hideFirstLastPages={false}
          onChange={this.handlePageChange}
        />
      </div>
    );    

    return (
      <div>
        <Table striped borderless responsive className='order-lines-table'>
          <thead>
            <tr>
              <th><FormattedMessage id='general.sku'/></th>
              <th><FormattedMessage id='general.description'/></th>
              {customLogoTableHeaderMarkup}
              <th><FormattedMessage id='general.quantity'/></th>
              <th><FormattedMessage id='general.total'/></th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
        {pagination}
      </div>
    );
  }
}

OrderLinesTable.propTypes = {
  linesLimit: PropTypes.number,
  useRemainingQuantity: PropTypes.bool,
  orderLines: PropTypes.array,
  paginate: PropTypes.bool,
  locale: PropTypes.string
};

export default injectIntl(OrderLinesTable);