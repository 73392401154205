import React, {Component} from 'react';
import {FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import LoadingDots from '../LoadingDots';

class Price extends Component {
  render() {
    const { currency, value, isLoading, canBeZero, unavailableMessage} = this.props;

    let markup = '';
    if (isLoading) {
      markup = <LoadingDots/>;
    }
    else if ((value && value > 0) || canBeZero) {
      markup = <FormattedNumber
        style='currency'  // eslint-disable-line react/style-prop-object
        currency={currency}
        value={value}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />;
    }
    else {
      markup = <FormattedMessage id={unavailableMessage}/>;
    }
    
    return (
      markup
    );
  }
}

Price.propTypes = {
  currency: PropTypes.oneOf(['USD']),
  value: PropTypes.number,
  isLoading: PropTypes.bool,
  canBeZero: PropTypes.bool,
  unavailableMessage: PropTypes.string
};

Price.defaultProps = {
  currency: 'USD',
  canBeZero: false,
  unavailableMessage: 'products.unavailable'
};

export default injectIntl(Price);