import camelcase from 'camelcase';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {injectIntl} from 'react-intl';
import {
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row
} from 'reactstrap';

import {Button, CalendarInput, Checkbox} from '../../components';
import QuantityInput from '../../components/QuantityInput/QuantityInput';
import RepairLineItem from './RepairLineItem';

class RepairDetails extends Component {
  constructor(props) {
    super(props);
    
    this.maxCredit = 5.0;
    
    this.state = {
      data: {
        serialNumber: this.props.data.serialNumber || '',
        purchaseDate: this.props.data.purchaseDate || undefined,
        illegibleSerial: this.props.data.illegibleSerial || false,
        receipt: this.props.data.receipt || [],
        primaryPart: this.props.data.primaryPart || {
          itemNumber: '',
          quantity: 1
        },
        additionalParts: this.props.data.additionalParts || [
          {
            itemNumber: '',
            quantity: 1
          }
        ],
        repairNotes: this.props.data.repairNotes || '',
        photos: this.props.data.photos || [],
        warrantyLabor: this.props.data.warrantyLabor || '0.0',
        freightAllowance: this.props.data.freightAllowance || '0.00',
        certifySafety: this.props.data.certifySafety || false,
        certifyAccurate: this.props.data.certifyAccurate || false
      },
      validation: this.getDefaultValidation(),
      remainingFreightCredit: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  getDefaultValidation = () => {
    const defaultValidation = {
      serialNumberIsValid: true,
      illegibleSerialIsValid: true,
      primaryPartIsValid: true,
      warrantyLaborIsValid: true,
      freightAllowanceIsValid: true,
      certifySafety: true,
      certifyAccurate: true,
      repairNotesIsValid: true
    };
    return defaultValidation;
  };

  handleInputChange = (e) => {
    let name = camelcase(e.target.name);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [name]: e.target.value
      }
    });
  };

  handlePurchaseDateChange = (selectedDay) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        purchaseDate: selectedDay
      }
    });
  };
  
  handleIllegibleSerial = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        illegibleSerial: !this.state.data.illegibleSerial
      }
    });
  };
  
  handleCertifySafety = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        certifySafety: !this.state.data.certifySafety
      }
    });
  };
  
  handleCertifyAccurate = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        certifyAccurate: !this.state.data.certifyAccurate
      }
    });
  };

  handleUploadReceipt = (file) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        receipt: file
      }
    }); 
  };

  handleUploadPhotos = (files) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        photos: files
      }
    }); 
        
  };

  handlePrimaryPartSkuChange = (e) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        primaryPart: {
          ...this.state.data.primaryPart,
          itemNumber: e.target.value
        }
      }
    });
  };

  handlePrimaryPartQuantityChange = (quantity) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        primaryPart: {
          ...this.state.data.primaryPart,
          quantity
        }
      }
    });
  };
  
  hideFreightAllowance = () => {
    let { receivedVia, returnedVia } = this.props.data;
    const customerDropoffOption = '2';
    return (!receivedVia && !returnedVia) || (receivedVia === customerDropoffOption && returnedVia === customerDropoffOption);
  };

  addRepairPartLineItem = () => {
    let newPart = {
      itemNumber: '',
      quantity: 1
    };
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        additionalParts: [
          ...this.state.data.additionalParts,
          newPart
        ]
      }
    });
  };

  updateRepairPartLineItem = (index, newPart) => {
    let parts = [...this.state.data.additionalParts];
    
    parts[index] = newPart;

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        additionalParts: parts
      }
    });
  };

  removeRepairPartLineItem = (removePart) => {
    let parts = [...this.state.data.additionalParts];

    parts.splice(parts.indexOf(removePart), 1);
    
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        additionalParts: parts
      }
    });
  };

  formatWarrantyLabor = (e) => {
    var warrantyLabor = e.target.value;
    warrantyLabor = (Math.floor(warrantyLabor * 100) / 100).toFixed(1);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        warrantyLabor
      }
    });
  };

  formatFreightAllowanceChange = (e) => {
    var freightAllowance = e.target.value;
    freightAllowance = (Math.floor(freightAllowance * 100) / 100).toFixed(2);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        freightAllowance
      }
    });
  };

  prev = () => {
    this.props.prev();
  };

  validateSerialNumber = (validation, illegibleSerial, serialNumber, purchaseDate, receipt) => {
    if (illegibleSerial){
      if (!purchaseDate || receipt.length < 1) {
        validation = {
          ...validation,
          illegibleSerialIsValid: false
        };
      }
    } else {
      if (!serialNumber) {
        validation = {
          ...validation,
          serialNumberIsValid: false
        };
      }
    }
    return validation;
  };
  
  validateFreightAllowance = (validation, freightAllowance) => {
    let dateReturned = this.props.data.dateReturned;
    let date = dateReturned.getFullYear() + '-' + (dateReturned.getMonth() + 1) + '-' + dateReturned.getDate();
    
    // Need to figure out total freight claims for return date
    return this.props.getClaimsForCustomer(this.props.data.customer.id, date)
      .then(() => {
        let truckTotal = Enumerable.from(this.props.claimsForCustomer).sum(x => x.freightAllowance).toFixed(2);
        let remainingCredit = this.maxCredit - truckTotal;
        let isValid = parseFloat(remainingCredit).toFixed(2) >= parseFloat(freightAllowance).toFixed(2) && parseFloat(freightAllowance).toFixed(2) <= this.maxCredit;

        this.setState({
          ...this.state,
          remainingFreightCredit: remainingCredit
        });
        
        if (freightAllowance && freightAllowance >= 0 && !this.hideFreightAllowance() && !isValid) {
          return {
            ...validation,
            freightAllowanceIsValid: false
          }
        } else
          return validation;
      });
  };
  
  validate = (validation) => {
    // if valid, save data to the wizard, go to next page
    let isValid = Object.keys(validation).every(function (k) {
      return validation[k] === true
    });
    if (isValid) {
      this.props.verifyClaim(this.state.data);
    }
    // else, show invalid data
    this.setState({
      ...this.state,
      validation
    });
  };

  next = () => {
    // validate data
    let validation = this.getDefaultValidation();
    let { 
      serialNumber, 
      illegibleSerial, 
      purchaseDate, 
      receipt, 
      primaryPart, 
      warrantyLabor, 
      freightAllowance,
      certifyAccurate,
      certifySafety,
      repairNotes
    } = this.state.data;
    // Serial Number is required unless the No Serial Number is true and there's a purchase date and receipt
    validation = this.validateSerialNumber(validation, illegibleSerial, serialNumber, purchaseDate, receipt);
    // Primary part is required
    validation = {
      ...validation,
      primaryPartIsValid: (primaryPart && primaryPart.itemNumber && primaryPart.quantity > 0)
    };
    //RepairNotes is required
    validation = {
      ...validation,
      repairNotesIsValid: (repairNotes.trim() !== '')
    };
    // Warranty labor is required and should be a number that only uses one sig fig
    if (!this.props.isFlatRate) {
      validation = {
        ...validation,
        warrantyLaborIsValid: warrantyLabor && warrantyLabor >= 0
      };
    }
    // Both Certify checkboxes need to be clicked
    validation = {
      ...validation,
      certifyAccurate: certifyAccurate
    };
    validation = {
      ...validation,
      certifySafety: certifySafety
    };

    if (this.props.data.receivedVia === '3' || this.props.data.returnedVia === '3') {
      // Freight allowance is required and should be a number that only uses two sig fig
      this.validateFreightAllowance(validation, freightAllowance)
        .then((updatedValidation) => {
          validation = updatedValidation;

          this.validate(validation);
        });
    }
    else {
      this.validate(validation);
    }
  };

  render() {
    let { productSku } = this.props.data;
    let moreParts = this.state.data.additionalParts 
      && this.state.data.additionalParts.map((part, index) => {
        return (
          <RepairLineItem
            key={index}
            index={index}
            itemNumber={part.itemNumber}
            quantity={part.quantity}
            removeRepairPartLineItem={this.removeRepairPartLineItem}
            update={this.updateRepairPartLineItem}
          />
        );
      });
    
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <h5 className='my-3'>Product</h5>
                    <FormGroup tag='fieldset' data-test-id='repair-details-part-sku' className='mt-2 mb-0'>
                      <Label for='part-item-number'>Part SKU*</Label>
                      <Input 
                        type='text'
                        name='part-item-number'
                        id='part-item-number'
                        readOnly={true} 
                        value={productSku}
                      />
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='repair-details-serial-number' className='mt-2 mb-0'>
                      <Label for='serial-number'>Serial # (Optional if box below checked)</Label>
                      <Input 
                        type='text'
                        name='serial-number'
                        id='serial-number'
                        value={this.state.data.serialNumber}
                        onChange={this.handleInputChange}
                        invalid={!this.state.validation.serialNumberIsValid}
                      />
                      <FormFeedback>Serial Number is Required</FormFeedback>
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='repair-details-serial-number-illegible-checkbox' className='mt-2 mb-0'>
                      <Checkbox 
                        label='Serial Number is illegible.' 
                        value={this.state.data.illegibleSerial}
                        isChecked={this.state.data.illegibleSerial} 
                        onToggle={this.handleIllegibleSerial} />
                      <p>If you check this box, you will need to enter a purchase date and upload a receipt. A serial number and/or purchase date is required to determine warranty eligibility.</p>
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='repair-details-purchase-date' className='mt-2 mb-0'>
                      <Label for='purchase-date'>Purchase Date (Required if no serial number available)</Label>
                      <CalendarInput
                        isClearable
                        placeholderText='Purchase Date'
                        selectedDay={this.state.data.purchaseDate}
                        onSelectedDateChange={this.handlePurchaseDateChange}
                        minDate={new Date(1900, 1, 1)}
                        maxDate={new Date()}
                        error={!this.state.validation.illegibleSerialIsValid ? 'Purchase Date is required for illegible serial numbers' : ''}
                      />
                    </FormGroup>
                    <FormGroup tag='fieldset' className='mt-2 mb-0'>
                      <Label for='receipt'>Upload Receipt (Required if no serial number available)</Label>
                      <Dropzone
                        onDrop={this.handleUploadReceipt.bind(this)}
                        multiple={false}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div {...getRootProps()}>
                            <input className={this.state.validation.illegibleSerialIsValid ? 'form-control' : 'form-control is-invalid'} {...getInputProps()} />
                            <Button data-test-id='repair-details-upload-receipt' >Upload</Button>
                            <FormFeedback>Receipt is required for illegible serial numbers</FormFeedback>
                          </div>
                        )}
                      </Dropzone>
                      <ul>
                        { this.state.data.receipt.map(f => <li key={f.name}>{f.name}</li>)}
                      </ul>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <h5 className='my-3'>Parts</h5>
                    <ul>
                      <li>If claim involves more than one part then enter the part that caused the failure as the &quot;Primary Part&quot;.</li>
                      <li>If no parts are required for the claim, please enter 45-00-0000 into the &quot;Primary Part&quot; field.</li>
                      <li>Primary Part can not be the same as additional Part</li>
                    </ul>
                    <h5 className='my-3'>Primary Part</h5>
                    <FormGroup tag='fieldset' className='mt-2 mb-0'>
                      <Row>
                        <Col xs='auto' className='mt-3 mt-lg-0'>
                          <Label for='primary-part-number'>Part #*</Label>
                          <Input 
                            data-test-id='repair-details-part-number-input'
                            name='primary-part-number'
                            value={this.state.data.primaryPart.itemNumber}
                            onChange={this.handlePrimaryPartSkuChange}
                            invalid={!this.state.validation.primaryPartIsValid}
                            />
                            <FormFeedback>Primary Part is Required</FormFeedback>
                        </Col>
                        <Col xs='auto' data-test-id='repair-details-quantity' className='mt-3 mt-lg-0'>                      
                          <Label for='primary-part-number'>Quantity</Label>
                          <QuantityInput 
                            value={this.state.data.primaryPart.quantity} 
                            onQuantityChange={this.handlePrimaryPartQuantityChange}
                            dataTestId='primary'
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <h5 className='my-3'>More Parts</h5>
                    <FormGroup tag='fieldset' className='mt-2 mb-0'>
                      {moreParts}
                    </FormGroup>
                    <FormGroup tag='fieldset' className='mt-2 mb-0'>
                      <Button data-test-id='repair-details-add-part' onClick={this.addRepairPartLineItem}>Add Part</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <h5 className='my-3'>Repair Notes *</h5>
                    <FormGroup tag='fieldset' data-test-id='repair-details-repair-notes' className='mt-2 mb-0'>
                    <Label for='repair-notes'>Please use this area to explain replacement parts or the labor charged.</Label>
                      <Input
                        type='textarea' 
                        name='repair-notes' 
                        value={this.state.data.repairNotes}
                        onChange={this.handleInputChange}
                        invalid={!this.state.validation.repairNotesIsValid}
                      />
                      <FormFeedback>Repair Notes are Required</FormFeedback>
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='repair-details-photos' className='mt-2 mb-0'>
                      <Label for='photos'>Photos (Optional)</Label>
                      <Dropzone
                        onDrop={this.handleUploadPhotos.bind(this)}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button>Upload</Button>
                          </div>
                        )}
                      </Dropzone>
                      <ul>
                        { this.state.data.photos.map(f => <li key={f.name}>{f.name}</li>)}
                      </ul>
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='repair-details-warranty-labor' className='mt-2 mb-0' hidden={this.props.isFlatRate}>
                      <Label for='warranty-labor'>Warranty Labor (0.1 increments)</Label>
                      <InputGroup>
                        <Input 
                          type='number'
                          name='warranty-labor'
                          value={this.state.data.warrantyLabor}
                          onChange={this.handleInputChange}
                          onBlur={this.formatWarrantyLabor}
                          step='.1'
                          invalid={!this.state.validation.warrantyLaborIsValid}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>Hours</InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback>Warranty Labor is Required</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card hidden={this.hideFreightAllowance()}>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <h5 className='my-3'>Freight Allowance</h5>
                    <FormGroup tag='fieldset' data-test-id='repair-details-freight-allowance' className='mt-2 mb-0'>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          type='number'
                          name='freight-allowance'
                          step='.01'
                          value={this.state.data.freightAllowance}
                          onChange={this.handleInputChange}
                          onBlur={this.formatFreightAllowanceChange}
                          invalid={!this.state.validation.freightAllowanceIsValid}
                        /> 
                        <InputGroupAddon addonType="append">
                          <InputGroupText>USD</InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback>Maximum of ${this.state.remainingFreightCredit.toFixed(2)} remaining for Company Truck deliveries to this customer today</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <h5 className='my-3'>Repair Certifications</h5>
                    <FormGroup tag='fieldset' data-test-id='repair-details-certify-safety-checkbox' className='mt-2 mb-0'>
                      <Checkbox
                        label='I certify:'
                        name='certify-safety'
                        isChecked={this.state.data.certifySafety} 
                        error={!this.state.validation.certifySafety ? 'You must certify!' : ''}
                        onToggle={this.handleCertifySafety}
                      />
                      <ol type='1'>
                        <li>Electrical and/or mechanical safety of the repaired product serviced will be completed.</li>
                        <li>Safety warning labels are in place.</li>
                        <li>All safety guards are in place and functioning.</li>
                      </ol>
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='repair-details-certify-accurate-checkbox' className='mt-2 mb-0'>
                      <Checkbox
                        label='I certify:'
                        name='certify-accurate'
                        isChecked={this.state.data.certifyAccurate}
                        error={!this.state.validation.certifyAccurate ? 'You must certify!' : ''}
                        onToggle={this.handleCertifyAccurate} />
                      <ol type='1'>
                        <li>All information herein is accurate.</li>	
                        <li>Upon repair, the product will meet all safety requirements</li>	
                        <li>No modifications will be made to alter the product</li>	
                        <li>Services being performed (parts and labor) were within the Milwaukee warranty at no cost to the customer</li>
                      </ol>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='justify-content-end'>
          <Col xs='auto'>
            <Button data-test-id='repair-details-back' onClick={this.prev}>Back</Button>
          </Col>
          <Col xs='auto'>
            <Button data-test-id='repair-details-next' block color='primary' onClick={this.next}>Next</Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

RepairDetails.propTypes = {
  verifyClaim: PropTypes.func,
  prev: PropTypes.func,
  data: PropTypes.object,
  getClaimsForCustomer: PropTypes.func,
  claimsForCustomer: PropTypes.array,
  isFlatRate: PropTypes.bool
};

export default injectIntl(RepairDetails);