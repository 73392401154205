import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {NavItem, NavLink} from 'reactstrap';

import {Icon, Link} from '../../components';

export default class MobileNavMenu extends Component {
  render() {
    let adminNavItem = this.props.showAdminMenu && (
      <NavItem>
        <NavLink tag={Link} to='admin'>Admin</NavLink>
      </NavItem>
    );
    
    let downloadStatementMenuItem = this.props.showDownloadStatementMenuItem && (
      <NavItem>
        <NavLink onClick={this.props.getCustomerStatement}>
          <div className='d-flex flex-row align-items-center'>
            Download Statement
            <Icon type='file-alt' color='red' size='sm' className='ml-2'/>
          </div>
        </NavLink>
      </NavItem>
    );

    let wishlistsMenuItem = this.props.showWishlistsMenuItem && (
      <NavItem>
        <NavLink tag={Link} to='shoppingLists'>Shopping Lists</NavLink>
      </NavItem>
    );
    
    return (
      this.props.isAuthenticated ? (
        <Fragment>
          <NavItem>
            <NavLink>
              <div className='d-flex flex-row align-items-center'>
                <Icon type='user-circle' color='red'/>
                <div className='ml-2'>{this.props.nickname}</div>
              </div>
            </NavLink>
          </NavItem>
          {this.props.isConnectUser &&
            <NavItem>
              <NavLink tag='a' href={this.props.accountsUrl} target='_blank'>
                <div className='d-flex flex-row align-items-center'>
                  <div>
                    <div>Account</div>
                    <div className='has-font-size-1'>{this.props.accountNumber}</div>
                  </div>
                  <Icon type='external-link-alt' color='dark-gray' size='sm' className='ml-2'/>
                </div>
              </NavLink>
            </NavItem>
          }
          <NavItem>
            {this.props.isConnectUser ?
              <NavLink tag={Link} to='profile'>Preferences</NavLink>
              : <NavLink tag={Link} to='profile/creditcards'>Credit Cards</NavLink>
            }
          </NavItem>
          {wishlistsMenuItem}
          {adminNavItem}
          {downloadStatementMenuItem}
          <NavItem>
            <NavLink onClick={this.props.logout}>Sign Out</NavLink>
          </NavItem>
        </Fragment>
      ) : (
        <NavItem>
          <NavLink onClick={this.props.login} className='has-pointer-icon'>Sign In</NavLink>
        </NavItem>
      )
    );
  }
}

MobileNavMenu.propTypes = {
  isAuthenticated: PropTypes.bool,
  nickname: PropTypes.string,
  accountNumber: PropTypes.string,
  accountsUrl: PropTypes.string,
  login: PropTypes.func,
  showAdminMenu: PropTypes.bool,
  showDownloadStatementMenuItem: PropTypes.bool,
  isConnectUser: PropTypes.bool,
  getCustomerStatement: PropTypes.func,
  showWishlistsMenuItem: PropTypes.bool,
  logout: PropTypes.func
};