import React, {Component, Fragment} from 'react';
import {FormattedDate, injectIntl} from 'react-intl';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  Row
} from 'reactstrap';
import {Button, CardBodyField, Price} from '../../components';
import PartsListTable from './PartsListTable';

class ReviewClaim extends Component {
  prev = () => {
    this.props.prev();
  };
  
  submit = () => {
    this.props.submit();
  };
  
  getShippedType = (id) => {
    switch (id) {
    case 1:
      return this.props.intl.formatMessage({ id: 'warranty.shippedType.notAvailable' });
    case 2:
      return this.props.intl.formatMessage({ id: 'warranty.shippedType.customerDropOffOrPickup' });
    case 3:
      return this.props.intl.formatMessage({ id: 'warranty.shippedType.companyTruck' });
    case 4:
      return this.props.intl.formatMessage({ id: 'warranty.shippedType.carrierFreight' });
    default:
      return '';
    }
  };

  getShippingProvider = (id) => {
    switch (id) {
      case 0: 
        return 'Other';
      case 1:
        return 'FedEx';
      case 2:
        return 'UPS';
      case 3:
        return 'USPS';
      case 4:
        return 'Purolator';
      case 5:
        return 'UPS Freight';
      case 6:
        return 'SAIA';
      case 7:
        return 'USF Holland';
      case 8:
        return 'YRC';
      case 9:
        return 'Southeastern';
      default:
        return '';
    }
  };
  
  hideFreightAllowance = () => {
    let { receivedVia, returnedVia } = this.props.claim;
    const customerDropoffOption = 2;
    return receivedVia === customerDropoffOption && returnedVia === customerDropoffOption;
  };
  
  render() {
    let {claim} = this.props;
    const customerCountry = claim.customer.country.toUpperCase();
    
    let laborLabel = claim.laborRate && claim.laborHours &&
      `Labor (${claim.laborHours} @ ${Number(claim.laborRate).toFixed(2)})`;
    
    let ascAddressMarkup = (
      <div>
        <div>{claim.ascAccount.address1}</div>
        <div>{claim.ascAccount.address2}</div>
        <div>{claim.ascAccount.city + ', ' + claim.ascAccount.stateProvince + ' ' + claim.ascAccount.postalCode}</div>
      </div>
    );
    
    let customerAddressMarkup = (
      <div>
        <div>{claim.customer.address1}</div>
        <div>{claim.customer.address2}</div>
        <div>{claim.customer.city + ', ' + claim.customer.stateProvince + ' ' + claim.customer.postalCode}</div>
      </div>
    );

    let partsListMarkup = claim.parts && (
      <Fragment>
        <Row>
          <Col>
            <ListGroup className='mt-3'>
              <PartsListTable
                parts={claim.parts}
                showPrices={!(claim.pricingStructure === 0)}
              />
            </ListGroup>
          </Col>
        </Row>
      </Fragment>
    );
    
    return (
      <div id='warranty-claim-details' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row id="review-header">
            <Col>
              <Card>
                <CardBody>
                  <div className='font-weight-bold'>Review Claim</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {claim.showBatteryWarning && <Row>
            <Col>
              <Card>
                <CardBody>
                <h4 className='text-center'>Attention</h4>
                <p>In an effort to make submitting battery claims quick and easy, we will provide <strong>Pre-Paid FedEx</strong> shipping labels for returning the batteries to Milwaukee</p>
                <p>Once you submit the claim you will be prompted to print out a pre-paid FedEx label to return the battery to Milwaukee Battery Analysis Dept.</p>
                <p>Please contact your warranty manager with questions.</p>
                </CardBody>
              </Card>
            </Col>
          </Row>}
          <Row>
            <Col lg='8'>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>Claim Information</span>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col md='8'>
                        <CardBodyField label='Credit Memo #:' value={claim.creditMemoNumber}/>
                        <CardBodyField label='Work Order #:' value={claim.workOrder}/> 
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
              <Card className='expense-summary'>
                <CardHeader>
                  <span className='text-uppercase'>Expense Summary</span>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col md='8'>
                        {!(claim.pricingStructure === 0) && 
                          <Fragment>
                            {!this.hideFreightAllowance() &&
                              <CardBodyField label='Freight Allowance:' value={claim.freightAllowance&& <Price value={claim.freightAllowance} canBeZero/> }/>
                            }
                            <CardBodyField label={laborLabel} value={claim.laborTotal && <Price value={claim.laborTotal} canBeZero/>}/>
                            <CardBodyField label='Parts:' value={claim.partTotal && <Price value={claim.partTotal} canBeZero/>}/>
                            {(customerCountry !== "CANADA" && claim.taxValue > 0) &&
                              <CardBodyField label='Tax:' value={claim.taxValue && <Price value={claim.taxValue} canBeZero/>}/>}
                            {(customerCountry === "CANADA") &&
                              <>
                                <CardBodyField label='GST/HST:' value={claim.taxValue && <Price value={claim.taxValue} canBeZero/>} />
                                <CardBodyField label='PST/QST:' value={claim.pstTaxValue && <Price value={claim.pstTaxValue} canBeZero/>} />
                              </>
                            }
                            <hr/>
                            <CardBodyField claimDataTestId='claim-review-warranty-total' label='Warranty Total:' value={claim.totalPrice && <Price value={claim.totalPrice} canBeZero/>}/>
                          </Fragment>
                        }
                        {claim.pricingStructure === 0 &&
                          <Fragment>
                            <CardBodyField claimDataTestId='claim-review-labor' label='Labor:' value={<Price value={claim.flatRatePrice} canBeZero/>}/>
                            {(customerCountry !== "CANADA" && claim.taxValue > 0) &&
                              <CardBodyField label='Tax:' value={claim.taxValue && <Price value={claim.taxValue} canBeZero/>}/>}
                            {(customerCountry === "CANADA") &&
                              <>
                                <CardBodyField label='GST/HST:' value={claim.taxValue && <Price value={claim.taxValue} canBeZero/>}/>
                                <CardBodyField label='PST/QST:' value={claim.pstTaxValue && <Price value={claim.pstTaxValue} canBeZero/>}/>
                              </>
                            }
                            <hr/>
                            <CardBodyField label='Warranty Total:' value={<Price value={claim.flatRatePrice + claim.taxValue + (claim.pstTaxValue ? claim.pstTaxValue : 0)} canBeZero/>}/>
                          </Fragment>
                        }
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>ASC Information</span>
                </CardHeader>
                <CardBody>
                  <CardBodyField claimDataTestId='claim-review-asc-number' label='ASC #:' value={claim.ascAccount.number}/>
                  <CardBodyField claimDataTestId='claim-review-asc-name' label='ASC Name:' value={claim.ascAccount.name}/>
                  <CardBodyField claimDataTestId='claim-review-asc-address' label='Address:' value={ascAddressMarkup}/>
                  <CardBodyField claimDataTestId='claim-review-asc-phone' label='Phone:' value={claim.ascAccount.phone}/>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>Customer Information</span>
                </CardHeader>
                <CardBody>
                  <CardBodyField claimDataTestId='claim-review-company-name' label='Company Name:' value={claim.customer.name}/>
                  <CardBodyField claimDataTestId='claim-review-customer-name' label='Customer Name:' value={claim.customer.firstName + ' ' + claim.customer.lastName}/>
                  <CardBodyField claimDataTestId='claim-review-customer-order-number' label='Customer Order #:' value={claim.customerOrder}/>
                  <CardBodyField claimDataTestId='claim-review-customer-address' label='Address:' value={customerAddressMarkup}/>
                  <CardBodyField claimDataTestId='claim-review-customer-phone' label='Phone:' value={claim.customer.phone}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className='mt-0'>
                <CardHeader>
                  <span className='text-uppercase'>Repair Info</span>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Row>
                      {!(claim.pricingStructure === 0) && 
                        <Fragment>
                          <Col xs='12' md='6' lg='4' className='mb-3 mb-lg-0'>
                            <CardBodyField label='Date Received:' value={<FormattedDate value={claim.dateReceived}/>}/>
                            <CardBodyField label='Received Via:' value={this.getShippedType(claim.receivedVia)}/>
                            <CardBodyField label='Received By:' value={claim.receivedBy}/>
                          </Col>
                          <Col xs='12' md='6' lg='4' className='mb-3 mb-lg-0'>
                            <CardBodyField label='Date Repaired:' value={<FormattedDate value={claim.dateRepaired}/>}/>
                            <CardBodyField label='Repaired By:' value={claim.repairedBy}/>
                          </Col>
                          <Col xs='12' md='6' lg='4'>
                            <CardBodyField label='Date Returned:' value={<FormattedDate value={claim.dateReturned}/>}/>
                            <CardBodyField label='Returned Via:' value={this.getShippedType(claim.returnedVia)}/>
                            {claim.shippingProvider && 
                            <Fragment>
                              <CardBodyField label='Shipping Provider:' value={this.getShippingProvider(claim.shippingProvider)}/>
                              <CardBodyField label='Tracking #:' value={claim.trackingNumber}/>
                            </Fragment>}
                            <CardBodyField label='Work Order #:' value={claim.workOrder}/>
                          </Col>
                        </Fragment>
                      }
                      {claim.pricingStructure === 0 &&
                        <Fragment>
                          <Col xs='12' md='6' lg='6' className='mb-3 mb-lg-0'>
                            <CardBodyField label='Date Received:' value={<FormattedDate value={claim.dateReceived}/>}/>
                            <CardBodyField label='Received By:' value={claim.receivedBy}/>
                          </Col>
                          <Col xs='12' md='6' lg='6'>
                            <CardBodyField label='Work Order #:' value={claim.workOrder}/>
                          </Col>
                        </Fragment>
                      }
                    </Row>
                  </Container>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>Product Information</span>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col md='6'>
                        <CardBodyField claimDataTestId='claim-review-catalog-number' label={this.props.intl.formatMessage({id: 'general.catalogNumber'}) + ':'} value={claim.itemNumber}/>
                        <CardBodyField label={this.props.intl.formatMessage({id: 'general.description'}) + ':'} value={claim.toolDescription}/>
                        <CardBodyField label={this.props.intl.formatMessage({id: 'general.serialNumber'}) + ':'} value={claim.serialNumber}/>
                        <CardBodyField label={this.props.intl.formatMessage({id: 'general.purchaseDate'}) + ':'} value={claim.purchaseDate && <FormattedDate value={claim.purchaseDate}/>}/>
                      </Col>
                    </Row>
                    {partsListMarkup}
                    <Row>
                      <Col>
                        <Container fluid>
                          <Row className='my-1'>
                            <Col md='6'>
                              <span className='has-font-size-2 font-weight-bold mr-2'>Repair Notes:</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {claim.repairNotes}
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row id="action-buttons" className='justify-content-end'>
                      <Col xs='auto'>
                        <Button data-test-id='claim-review-back' onClick={this.prev}>Back</Button>
                      </Col>
                      <Col xs='auto'>
                        <Button data-test-id='claim-review-submit' block color='primary' onClick={this.submit} isLoading={this.props.isLoading}>Submit Warranty Claim</Button>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default injectIntl(ReviewClaim);