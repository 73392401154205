
import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toastr} from 'react-redux-toastr';

import {squareActions} from '../../store';
import {
  AddCreditCard,
  Row,
  Col
} from '@met/react-components';
import CreditCard from './components/CreditCard';
import CreditCardLoader from './components/CreditCardLoader';
import { getProfile } from '../../helpers/localStorage';


const CreditCards = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  const deleteCard = (cardId) => {
    props.squareActions.deleteCreditCard(cardId)
      .then(() => setIsLoading(true));
  }

  const handleCreditCardAdded = (cardData, nonce, cardholderName) => {
    const accountNumber = getProfile().accountNumber;
    return props.squareActions.addCreditCard({
      billingPostalCode: cardData.billing_postal_code,
      cardholderName: cardholderName,
      squareCardNonce: nonce,
      accountNumber: accountNumber
    })
      .then(() => setIsLoading(true))
      .catch(response => {
        toastr.error(
          props.intl.formatMessage({ id: 'general.errorToastTitle' }),
          props.intl.formatMessage(
            {
              id: (response.error && response.error.code) ?
                `square.errorCodes.${response.error.code}` :
                'square.addCreditCardErrorMessage',
              defaultMessage: props.intl.formatMessage({ id: 'square.addCreditCardErrorMessage' })
            }),
          { timeOut: 0 });
      });
  };

  const cardMarkup = props.creditCards && props.creditCards.filter(c => c.visibleToUser).map((card) => {
    return (
      <CreditCard card={card} deleteCard={deleteCard} key={card.id} />
    )
  });

  useEffect(() => {
    if (isLoading) {
      //Not specifying a location here, so it uses the default
      //we only need the location for invite to pay requests
      //if someone gets here after logging in with invite to pay and cant find
      //their credit card they saved, they will just have to deal
      //since we have to use two different square accounts
      //should only happen if someone does an invite to pay to pickup a tool at
      //a canadian branch location, logs in and saves their card
      props.squareActions.getSquareConfig()
        .then(() => {
          // load all credit cards for the user
          props.squareActions.getCreditCards()
            .then(() => setIsLoading(false))
            .catch(() => toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'square.getCreditCardsErrorMessage'}), {timeOut: 0}));
        });
    }
  }, [isLoading]);

  return (
    <div style={{minHeight: '310px'}}>
      <Row>
        <h5>Credit Cards</h5>
        <hr/>
      </Row>
      {isLoading ? <CreditCardLoader/> : cardMarkup}
      <Row className='mt-4'>
        <Col className='text-right'>
          {getProfile().accountNumber && 
            <AddCreditCard
              applicationId={props.squareApplicationId}
              onCreditCardAdded={handleCreditCardAdded}
              canSave={false} // Don't give them the option to not save here, as we're always saving from this page
            />
          }
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.square
  }
};

const mapDispatchToProps = dispatch => {
  return {
    squareActions: bindActionCreators(squareActions, dispatch)
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreditCards));