import PropTypes from 'prop-types';
import React from 'react';

import {Message} from '../../../components';

const InvalidCartMessage = props => {
  return (
    <Message
      type='info'
      icon='info-circle'
      value='Your cart has one or more issues that need addressing - please return to you cart to fix them before submitting your order.'
      isHidden={props.canCheckOut}
    />
  );
};

InvalidCartMessage.propTypes = {
  canCheckOut: PropTypes.bool
};

export default InvalidCartMessage