import {
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  Icon,
  InputGroup,
  InputGroupAppend,
  InputGroupPrepend,
  InputGroupText,
  Table,
  TextInput
} from '@met/react-components';
import Enumerable from 'linq';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { Fade } from 'react-awesome-reveal';
import {Link} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';

import {TilesLoader} from '../../../components';
import history from '../../../history';
import {companyAdminActions} from '../../../store';

const CompanyAdminHome = () => {
  const dispatch = useDispatch();
  
  // app state
  const users = useSelector(state => state.companyAdmin.users, shallowEqual);
  const accountDetails = useSelector(state => (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails : state.account.currentAccount.accountDetails));
  
  // local state
  const [usersLoading, setUsersLoading] = useState(true);
  const [accountLoading, setAccountLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [accountNumber, setAccountNumber] = useState(null);

  /***
   * Update the account number once the current account is loaded
   */
  useEffect(() => {
    if (accountDetails.accountNumber) {
      setAccountNumber(accountDetails.accountNumber);
      setAccountLoading(false);
    }
  }, [accountDetails])

  /***
   * Load the users for the current account number
   */
  useEffect(() => {
    if (accountNumber) {
      setUsersLoading(true);
      dispatch(companyAdminActions.getUsers(accountNumber))
        .catch(() => toastr.error('Something went wrong', 'Unable to load accounts at this time. Please try again later.'))
        .finally(() => setUsersLoading(false));
    }
  }, [accountNumber])

  /***
   * Once we have users loaded, update our filtered users
   */
  useEffect(() => {
    if (users) {
      setFilteredUsers(users);
    }
  }, [users]);

  /***
   * filter users by email address as the user types in the input field
   * @param e
   */
  const handleFilterUsers = (e) => {
    setFilteredUsers(users.filter(x => x.email.toLowerCase().includes(e.target.value.toLowerCase())));
  };
  
  const columns = {
    lastName: {
      label: 'Last',
      content: (user) => user.lastName || '-'
    },
    firstName: {
      label: 'First',
      content: (user) => user.firstName || '-'
    },
    email: {
      label: 'Email'
    },
    accountNumber: {
      label: 'Account #'
    },
    actions: {
      label: '',
      // eslint-disable-next-line react/display-name
      content: (user) => <Link to={`/companyadmin/${user.userId}/edit`}><Icon type='pencil' /></Link>
    }
  }
  
  const accounts = accountDetails.children
    ? Enumerable.from(accountDetails.children)
      .where(account => account.accountNumber !== accountDetails.accountNumber)
      .select(account => account.accountNumber)
      .concat([accountDetails.accountNumber])
      .select(accountNumber => {return {label: accountNumber, value: accountNumber}})
      .orderBy(option => option.value)
      .toArray()
    : [{label: accountDetails.accountNumber, value: accountDetails.accountNumber}];

  return (
    <Container>
      {!accountLoading && (
        <Fade>
          <Row>
            <Col>
              <Card>
                <CardHeader title='Users' >
                  <div style={{marginLeft: 'auto'}} id='table-actions' />
                </CardHeader>
                <CardBody>
                  <Row style={{marginBottom: '1rem'}}>
                    <Col xs={12} md={6}>
                      <InputGroup data-test-id='company-admin-account-number'>
                        <InputGroupPrepend>
                          <InputGroupText>
                            Account #
                          </InputGroupText>
                        </InputGroupPrepend>
                        <Dropdown
                          windowItemSize={35}
                          value={accountNumber}
                          options={accounts}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          onChange={option => setAccountNumber(option.value)}
                          loading={accountLoading}
                        />
                      </InputGroup>
                    </Col>
                    <Col xs={12} md={6} end='xs'>
                      <InputGroup>
                        <TextInput data-test-id='company-admin-email-search' onChange={handleFilterUsers} placeholder='Search by Email' />
                        <InputGroupAppend>
                          <InputGroupText>
                            <Icon type='search' />
                          </InputGroupText>
                        </InputGroupAppend>
                      </InputGroup>
                    </Col>
                  </Row>
                  {
                    !usersLoading ? (
                      <Table
                        striped
                        borderless
                        uniqueKeyId='userId'
                        columns={columns}
                        data={filteredUsers}
                        actionsToolbar={{
                          targetElementId: 'table-actions',
                          customActions: [
                            {
                              label: 'New User',
                              icon: <Icon type='plus-circle' />,
                              onClick: () => history.push('/companyadmin/new'),
                              disabled: false
                            }
                          ]
                        }}
                      />
                    ) : <TilesLoader />
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Fade>
      )}
      {accountLoading && <TilesLoader />}
    </Container>
  )
};

export default CompanyAdminHome;