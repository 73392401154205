import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import user from '../../helpers/user';
import LoadingDots from '../LoadingDots';
import ProductAvailabilityMarkup from '../ProductAvailabilityMarkup';

class ProductAvailability extends Component {
  render() {
    const { availability, sku, onlyShowBackordered } = this.props;
    
    if (!user.canPlaceOrders())
      return null;
    if (availability && availability.isLoading && !availability.availabilityMap[sku]) {
      return (
        <LoadingDots/>
      );
    }
    if (sku === '') {
      return null;
    }
    else if (availability && availability.availabilityMap[sku]) {
      let availabilityStatus = availability.availabilityMap[sku].availability;
      let recoveryDate = availability.availabilityMap[sku].recoveryDate;
      let customMessage = availability.availabilityMap[sku].customMessage;
      return (
        <ProductAvailabilityMarkup availabilityStatus={availabilityStatus} recoveryDate={recoveryDate} onlyShowBackordered={onlyShowBackordered} customMessage={customMessage}/>
      );
    }
    else {
      return (
        <ProductAvailabilityMarkup availabilityStatus={2} onlyShowBackordered={onlyShowBackordered}/>
      );
    }
  }
}

ProductAvailability.propTypes = {
  availability: PropTypes.object.isRequired,
  sku: PropTypes.string.isRequired,
  onlyShowBackordered: PropTypes.bool
};

ProductAvailability.defaultProps = {
  onlyShowBackordered: false
};

export default injectIntl(ProductAvailability);