import googleTagManager from '@analytics/google-tag-manager';
import Analytics from 'analytics';
import oneTrust from './oneTrust';

let isEnabled = false;
const googleTagManagerId = 'GTM-PN9DW2S';

const analytics = Analytics({
  plugins: [
    googleTagManager({
      containerId: googleTagManagerId,
      enabled: false
    })
  ]
})

let init = function () {
  if (oneTrust.canLogGTM() && !isEnabled) {
    analytics.plugins.enable(['google-tag-manager']);
    isEnabled = true;
  }
}

let disable = function () {
  analytics.plugins.disable(['google-tag-manager']);
  isEnabled = false;
}

const gtm = {
  init: init,
  disable: disable,
  pageView: function (path) {
    if (oneTrust.canLogGTM()) {
      if (!isEnabled) {
        init();
      }
      analytics.page(path);
    }
  },
  event: function(category, action, label) {
    if (oneTrust.canLogGTM()) {
      if (!isEnabled) {
        init();
      }
      analytics.track('content-view', {
        category: category,
        action: action,
        label: label
      });
    }
  }
}

export default gtm;