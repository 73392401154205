import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component, Fragment} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {actions as toastrActions, toastr} from 'react-redux-toastr'
import {Card, Col, Container, ListGroup, ListGroupItem, Row, Table, Label, Input, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {CalendarInput, Checkbox, PageLoader, Pagination, PaginationResultsCount, SearchInput} from '../../components';
import {Button} from "../../components";
import queryString from '../../helpers/queryString';
import user from '../../helpers/user';
import history from '../../history';
import {accountActions, invoicesActions} from '../../store';
import InvoiceRow from './InvoiceRow';
import {downloadBlob} from '../../helpers/downloadblob';

class Invoices extends Component {
  constructor(props){
    super(props);

    let values = queryString.parse();
    this.state = {
      query: values.q || '',
      queryString: window.location.search,
      currentPage: values.p ? parseInt(values.p) : 1,
      pageSize: 24,
      endDate: this.parseDate(values.e),
      startDate: this.parseDate(values.s),
      searchAllAccounts: values.aa === 'true',
      showInvoiceIdModal: false,
      invoiceInputValue: { invoiceId: '', invoiceDate: new Date(), orderNumber: '' }
    };
  }
  
  componentDidMount(){
    this.getInvoices();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps === undefined)
      return false;

    if (this.state.queryString !== window.location.search || (prevProps.accountNumber !== this.props.accountNumber)) {
      this.getInvoices();
      
      let values = queryString.parse();
      this.setState({
        ...this.state,
        query: values.q || '',
        queryString: window.location.search,
        currentPage: values.p ? parseInt(values.p) : 1,
        endDate: this.parseDate(values.e),
        startDate: this.parseDate(values.s),
        searchAllAccounts: values.aa === 'true'
      });
    }
  }
  
  getInvoices = () => {
    let queryObject = queryString.parse();

    if (this.props.accountNumber)
      queryObject['an'] = this.props.accountNumber;

    this.props.getInvoices(queryObject)
      .catch(() => toastr.error(this.props.intl.formatMessage({id: 'general.errorToastTitle'}), this.props.intl.formatMessage({id: 'invoices.loadingInvoicesErrorMessage'})))
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      currentPage: pageNumber
    });

    history.push(queryString.update('p', pageNumber));
  };

  search = () => {
    let locale = this.props.match.params.locale;
    let subPath = locale ? `/${locale}` : '';
    let route = subPath + queryString.update('q', this.state.query, '/invoices' + window.location.search);
    route = queryString.update('s', this.state.startDate && this.state.startDate.toISOString().split('T')[0], route);
    route = queryString.update('e', this.state.endDate && this.state.endDate.toISOString().split('T')[0], route);
    route = queryString.update('p', null, route, false);
    route = queryString.update('aa', this.state.searchAllAccounts, route, false);
    history.push(route.replace(/\+/g, '%2B'));
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };
  
  handleSearchInputChange = (e) => {
    this.setState({
      ...this.state,
      query: e.target.value
    });
  };

  handleStartDateChange = (selectedDay) => {
    let endDate;
    if (selectedDay > this.state.endDate) {
      endDate = undefined
    }
    else {
      endDate = this.state.endDate
    }

    this.setState({
      ...this.state,
      startDate: selectedDay,
      endDate
    });
  };

  handleEndDateChange = (selectedDay) => {
    let startDate;
    if (selectedDay < this.state.startDate) {
      startDate = undefined
    }
    else {
      startDate = this.state.startDate
    }

    this.setState({
      ...this.state,
      endDate: selectedDay,
      startDate
    });
  };

  handleAllAccountsToggle = (isChecked) => {
    this.setState({
      ...this.state,
      searchAllAccounts: isChecked
    }, () => {
      this.search();
    });
  };

  handleDownloadClick = (invoice) => {
    if (invoice?.invoiceId && invoice?.invoiceDate && !isNaN(Date.parse(invoice?.invoiceDate))) {
      this.props.showToast({
        id: invoice.invoiceId,
        type: 'info',
        title: this.props.intl.formatMessage({ id: 'invoices.downloadingInvoice' }, { invoiceId: invoice.invoiceId }),
        position: 'bottom-right',
        message: '',
        options: {
          className: 'toastr-download',
          removeOnHover: false,
          showCloseButton: false,
          closeOnToastrClick: false,
          timeOut: 0,
          icon: <FontAwesomeIcon icon='spinner' size='2x' spin/>
        }
      });

      this.props.downloadInvoice(invoice.invoiceId, new Date(invoice.invoiceDate).toISOString().split('T')[0], invoice.orderNumber)
        .then((blob) => {
          downloadBlob(`Invoice_${invoice.invoiceId}.pdf`, blob);
          toastr.remove(invoice.invoiceId);
        })
        .catch(() => {
          toastr.remove(invoice.invoiceId);
          toastr.error(this.props.intl.formatMessage({ id: 'general.errorToastTitle' }), this.props.intl.formatMessage({ id: 'invoices.downloadingInvoiceErrorMessage' }), { timeOut: 0 });
        });
    } else {
      toastr.error(this.props.intl.formatMessage({ id: 'general.errorToastTitle' }), this.props.intl.formatMessage({ id: 'invoices.invalidInvoiceData' }), { timeOut: 0 });
    }
  };

  clear = () => {
    this.setState({
      ...this.state,
      query: ''
    });
  };

  parseDate = (input) => {
    if (!input)
      return undefined;

    let parts = input.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
  };
  
  getSearchHelpModalContent = () => {
    return (
      <div>
        <p>Search terms can include invoice IDs, purchase order numbers, order numbers, customer names, and account numbers.</p>
        <p>By default, searches with spaces will treat separate words as AND searches - Ex. <code>ruecker champlin</code> will return invoices matching the terms <code>ruecker</code> AND <code>champlin</code>.</p>
        <p>The following modifiers can be used for advanced searching:</p>
        <ul>
          <li><strong>OR operator (<code>|</code>)</strong> - Ex. <code>ruecker|champlin</code> will return invoices matching the terms <code>ruecker</code> OR <code>champlin</code></li>
          <li><strong>NOT operator (<code>-</code>)</strong> - Ex. <code>ruecker -champlin</code> will return invoices matching the term <code>ruecker</code> but NOT <code>champlin</code></li>
          <li><strong>Suffix operator (<code>*</code>)</strong> - Ex. <code>january_order_*</code> will return invoices matching the term <code>january_order_</code> and any additional characters. Useful for matching partial words.</li>
          <li><strong>Phrase operator (<code>&quot;&quot;</code>)</strong> - Ex. <code>&quot;My Purchase Order Number&quot;</code> will return invoices matching the WHOLE phrase <code>&quot;My Purchase Order Number&quot;</code> exactly</li>
        </ul>
      </div>
    );
  };

  getCustStatement = () => {
    toastr.info(
      'Customer Statement Downloading...',
      '',
      {
        className: 'toastr-download',
        position: 'bottom-right',
        removeOnHover: false,
        showCloseButton: false,
        closeOnToastrClick: false,
        timeOut: 0,
        icon: <FontAwesomeIcon icon='spinner' size='2x' spin />
      }
    );

    if (this.props.isImpersonating) {
      this.props.getCustomerStatementForImpersonatedAccount(this.props.accountNumber)
        .then((blob) => {
          downloadBlob('Customer Statement.pdf', blob);
          toastr.removeByType('info');
        }).catch(() => {
        toastr.removeByType('info');
        toastr.error(this.props.intl.formatMessage({ id: 'general.customerStatementErrorTitle' }), this.props.intl.formatMessage({ id: 'general.customerStatementErrorBody' }), { timeOut: 0 });
      });
    }
    else {
      this.props.getCustomerStatementForAccount(this.props.accountNumber)
        .then((blob) => {
          downloadBlob('Customer Statement.pdf', blob);
          toastr.removeByType('info');
        }).catch(() => {
        toastr.removeByType('info');
        toastr.error(this.props.intl.formatMessage({ id: 'general.customerStatementErrorTitle' }), this.props.intl.formatMessage({ id: 'general.customerStatementErrorBody' }), { timeOut: 0 });
      });
    }
  };

  toggleInvoiceIdModal = () => {
    let showInvoiceId = !this.state.showInvoiceIdModal;
    this.setState({
      ...this.state,
      showInvoiceIdModal: showInvoiceId,
      showErrorMessage: false
    });
  };

  setInvoiceInputValue = (e) => {
    this.setState({
      ...this.state,
      invoiceInputValue: e.target.value
    });
  }

  render(){
    const {invoiceHistory, intl} = this.props;

    let invoiceIdHasher = (
      <div type='search'>
        <Label>AX Order Number</Label>
        <Input type='text' value={this.state.invoiceInputValue.orderNumber} onChange={(e) => {
          this.setState({
            ...this.state,
            invoiceInputValue: {
              ...this.state.invoiceInputValue,
              orderNumber: e.target.value
            }
          });
        }} />
        <Label>Invoice Id</Label>
        <Input type='text' value={this.state.invoiceInputValue.invoiceId} onChange={(e) => {
          this.setState({
            ...this.state,
            invoiceInputValue: {
              ...this.state.invoiceInputValue,
              invoiceId: e.target.value
            }
          });
        }} />
        <Label>Invoice Date</Label>
        <CalendarInput
          selectsStart
          isClearable
          placeholderText={'Invoice Date'}
          selectedDay={this.state.invoiceInputValue.invoiceDate}
          onSelectedDateChange={(selectedDay) => {
            this.setState({
              ...this.state,
              invoiceInputValue: {
                ...this.state.invoiceInputValue,
                invoiceDate: selectedDay
              }
            });
          }}
        />
        <Button style={{marginTop: '10px'}} onClick={() => {
          this.handleDownloadClick(this.state.invoiceInputValue);
          this.toggleInvoiceIdModal();
        }} color='danger' isDisabled={!this.state.invoiceInputValue?.invoiceId || !this.state.invoiceInputValue?.invoiceDate || !this.state.invoiceInputValue?.orderNumber}>
          Download Invoice
        </Button>
      </div>
    );

    let invoiceIdModal = (
      <Modal isOpen={this.state.showInvoiceIdModal} toggle={this.toggleInvoiceIdModal} centered={true}>
        <ModalHeader toggle={this.toggleInvoiceIdModal}>Download Invoice</ModalHeader>
        <ModalBody>
          {invoiceIdHasher}
          {this.state.showErrorMessage && (<span style={{ color: 'red' }}>Invalid Invoice Id</span>)}
        </ModalBody>
      </Modal>
    );

    let invoiceHelpButton = this.props.featureToggles.IdHasherEnabled && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <span className='help-button' color='none' onClick={this.toggleInvoiceIdModal}>
        <FontAwesomeIcon icon='question-circle' style={{ 'fontSize': 'medium', 'verticalAlign': 'inherit' }} />
      </span>
    );

    let markup;
    if (invoiceHistory.isLoading)
    {
      markup = <PageLoader/>;
    }
    else if (!invoiceHistory.isLoading && invoiceHistory.invoices.length > 0){ 
      let invoiceRows = invoiceHistory.invoices.map((invoice, index) => {
        return (
          <InvoiceRow invoice={invoice} onDownloadClick={this.handleDownloadClick} key={`invoice-${index}`}/>
        );
      });
      
      markup = (
        <Table striped borderless responsive className='order-lines-table'>
          <thead>
          <tr>
            <th><FormattedMessage id='general.date' /></th>
            <th><FormattedMessage id='general.id' /></th>
            <th><FormattedMessage id='general.purchaseOrderNumber' /></th>
            <th><FormattedMessage id='general.orderNumber' /></th>
            <th><FormattedMessage id='general.customer' /></th>
            <th><FormattedMessage id='general.amount' /></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {invoiceRows}
          </tbody>
        </Table>
      )
    }
    else {
      markup = (
        <ListGroup>
          <ListGroupItem className='text-center'>
            <div className='my-4'>
              <h3 data-test-id='no-invoices-found' ><FormattedMessage id='invoices.noInvoicesMessage'/></h3>
            </div>
          </ListGroupItem>
        </ListGroup>
      )
    }

    return(
      <Fragment>
        <Container>
          <Row className='my-3'>
            <Col>
              <h2 style={{display:'inline-block'}}>Your Invoices</h2>
              {invoiceHelpButton}
            </Col>
            {this.props.canAccessCustomerStatement && 
            <Col md={'auto'}>
              <Button data-test-id='customer-statement' onClick={this.getCustStatement} isLoading={this.props.customerStatementIsLoading} className='float-right float-md-none'>
                <FontAwesomeIcon icon='file-download' className={'mr-2'}/>
                Customer Statement
              </Button>
            </Col>
            }
          </Row>
        </Container>
        <div id='invoices' className='animate-bottom-fade-in content-wrapper'>
          <Container>
            <Row>
              <Col>
                <Card body>
                  <Container fluid>
                    <Row className='align-items-center mb-3'>
                      <Col md={3} data-test-id='invoices-start-date' className='mb-3 mb-md-0'>
                        <CalendarInput
                          selectsStart
                          isClearable
                          placeholderText={intl.formatMessage({id: 'general.startDate'})}
                          selectedDay={this.state.startDate}
                          onSelectedDateChange={this.handleStartDateChange}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                        />
                      </Col>
                      <Col md={3} data-test-id='invoices-end-date' className='mb-3 mb-md-0'>
                        <CalendarInput
                          selectsEnd
                          isClearable
                          placeholderText={intl.formatMessage({id: 'general.endDate'})}
                          minDate={this.state.startDate}
                          selectedDay={this.state.endDate}
                          onSelectedDateChange={this.handleEndDateChange}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                        />
                      </Col>
                      <Col>
                        <SearchInput
                          searchInputDataTestId='invoice-search-input'
                          searchButtonDataTestId='invoice-search-button'
                          placeholder='Search'
                          value={this.state.query}
                          onChange={this.handleSearchInputChange}
                          onKeyPress={this.handleKeyPress}
                          onClick={this.search} 
                          isSearching={invoiceHistory.isLoading}
                          onClear={this.clear}
                          searchHelpModalContent={this.getSearchHelpModalContent()}
                        />
                      </Col>
                      {user.canViewAllOrders() && <Col sm='auto' data-test-id='invoices-search-all-accounts' className='mt-3 mt-sm-0'>
                        <Checkbox label='Search all accounts' isChecked={this.state.searchAllAccounts} onToggle={this.handleAllAccountsToggle} />
                      </Col>}
                    </Row>
                    <Row className='my-3'>
                      <Col>
                        <PaginationResultsCount
                          count={invoiceHistory.count}
                          totalCount={invoiceHistory.totalCount}
                          currentPage={this.state.currentPage}
                          pageSize={this.state.pageSize}
                          isHidden={invoiceHistory.isLoading}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {markup}
                        <Pagination
                          totalPages={invoiceHistory.totalPages}
                          currentPage={this.state.currentPage}
                          pageSize={this.state.pageSize}
                          totalItemsCount={invoiceHistory.totalCount}
                          onPageChange={this.handlePageChange}
                          isHidden={invoiceHistory.isLoading}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {invoiceIdModal}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    invoiceHistory: state.invoices.invoiceHistory,
    accountNumber: state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber,
    isImpersonating: state.account.isImpersonating,
    canAccessCustomerStatement: state.account.isImpersonating ? state.account.impersonatedAccount.canAccessCustomerStatement : state.account.currentAccount.canAccessCustomerStatement,
    customerStatementIsLoading: state.account.isImpersonating ? state.account.impersonatedAccount.customerStatement.isLoading : state.account.currentAccount.customerStatement.isLoading,
    featureToggles: state.configuration.featureToggles
  };
};

const mapDispatchToProps = dispatch => ({
  getInvoiceId: (invoiceId) => dispatch(invoicesActions.getInvoiceId(invoiceId)),
  getInvoices: queryObject => dispatch(invoicesActions.getInvoices(queryObject)),
  downloadInvoice: (invoiceId, invoiceDate, orderNumber) => dispatch(invoicesActions.downloadInvoice(invoiceId, invoiceDate, orderNumber || '')),
  showToast: (toastConfig) => dispatch(toastrActions.add(toastConfig)),
  getCustomerStatementForAccount: (account) => dispatch(accountActions.getCustomerStatementForAccount(account)),
  getCustomerStatementForImpersonatedAccount: (account) => dispatch(accountActions.getCustomerStatementForImpersonatedAccount(account)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Invoices));

