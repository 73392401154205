import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

class AnnouncementModal extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      isOpen: this.props.isOpen || false
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps === undefined)
      return false;
    
    if (prevProps.isOpen !== this.props.isOpen){
      this.setState({
        ...this.state,
        isOpen: this.props.isOpen
      });
    }
  }

  toggle = () => {
    if (this.props.toggle)
      this.props.toggle();

    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen
    });
  };
  
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.toggle} centered={true} size='lg'>
        <ModalHeader toggle={this.toggle}>{this.props.announcement.heading}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.announcement.details)}} />
        </ModalBody>
      </Modal>
    );
  }
}

AnnouncementModal.propTypes = {
  announcement: PropTypes.object.isRequired,
  toggle: PropTypes.func
};

export default injectIntl(AnnouncementModal);