import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import {Price, QuantityInput} from '../../components';

class PartsListItem extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      quantity: this.props.quantity || 0,
      partLineType: this.getPartLineType(this.props.partLineType)
    };
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;
    
    if (prevProps.quantity !== this.props.quantity || prevProps.partLineType !== this.props.partLineType) {
      this.setState({
        ...this.state,
        quantity: this.props.quantity,
        partLineType: this.getPartLineType(this.props.partLineType)
      });
    }
  }
  
  getPartLineType = (id) => {
    return this.props.partLineTypeOptions ? Enumerable
      .from(this.props.partLineTypeOptions)
      .where(x => x.value === id)
      .firstOrDefault() : null;
  };
  
  handleQuantityChange = (quantity) => {    
    this.setState({
      ...this.state,
      quantity
    }, () => {
      this.props.onQuantityChange(quantity, this.props.index);
    });
  };
  
  handlePartLineTypeChange = (selected) => {
    this.setState({
      ...this.state,
      partLineType: selected
    }, () => {
      this.props.onPartLineTypeChange(selected.value, this.props.index);
    });
  };
  
  handleDeleteClick = () => {
    this.props.onDeleteClick(this.props.index);
  };
  
  render() {
    const { itemNumber, description, listPrice, unitPrice, partLineTypeOptions, intl, showPrices } = this.props;
    
    let listPriceMarkup = listPrice !== undefined ? <Price value={listPrice}/> : <FormattedMessage id={'warranty.pricingMessage'}/>;
    let unitPriceMarkup = unitPrice !== undefined ? <Price value={unitPrice}/> : <FormattedMessage id={'warranty.pricingMessage'}/>;
    let extendedPriceMarkup = unitPrice !== undefined ? <Price value={unitPrice * this.state.quantity}/> : <FormattedMessage id={'warranty.pricingMessage'}/>;
    
    let quantityInput = this.props.canEdit ? 
      (<QuantityInput 
          quantity={this.state.quantity} 
          onQuantityChange={this.handleQuantityChange} 
          hideButtons
          dataTestId={this.state.itemNumber}
        />)
      : (<Fragment>{this.state.quantity}</Fragment>);

    return (
      <Fragment>
        <td data-label={intl.formatMessage({id: 'general.quantity'})} className='min-width'>
          <div>
            {quantityInput}
          </div>
        </td>
        <td data-label={intl.formatMessage({id: 'general.itemNumber'})} className='min-width'>
          <span className='text-uppercase no-wrap'>{itemNumber}</span>
        </td>
        <td data-label={intl.formatMessage({id: 'general.description'})} className='description-column min-width'>
          <span className='text-uppercase'>{description}</span>
        </td>
        {showPrices &&
          <Fragment>
            <td data-label={intl.formatMessage({id: 'pricing.listPrice'})}>
              {listPriceMarkup}
            </td>
            <td data-label={intl.formatMessage({id: 'pricing.reimbursementPrice'})}>
              {unitPriceMarkup}
            </td>
            <td data-label={intl.formatMessage({id: 'pricing.extendedPrice'})}>
              {extendedPriceMarkup}
            </td>
          </Fragment>
        }
        {this.props.canEdit &&
        <Fragment>
          <td className='select-column'>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              value={this.state.partLineType}
              options={partLineTypeOptions}
              onChange={this.handlePartLineTypeChange}
              menuPosition='fixed'
            />
          </td>
          <td className='d-print-none min-width text-right'>
            <FontAwesomeIcon className='is-mock-link text-secondary mx-2' icon='trash-alt' onClick={this.handleDeleteClick} />
          </td>
        </Fragment>
        }
      </Fragment>
    );
  }
}

PartsListItem.propTypes = {
  index: PropTypes.number,
  quantity: PropTypes.number,
  listPrice: PropTypes.number,
  unitPrice: PropTypes.number,
  itemNumber: PropTypes.string,
  description: PropTypes.string,
  partLineType: PropTypes.number,
  partLineTypeOptions: PropTypes.array,
  onQuantityChange: PropTypes.func,
  onPartLineTypeChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
  canEdit: PropTypes.bool,
  showPrices: PropTypes.bool
};

export default injectIntl(PartsListItem);