import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import {toastr} from "react-redux-toastr";
import { Col, Collapse, Container, ListGroupItem, Row } from 'reactstrap';
import {Button, Circle} from '../../components';
import OrderLinesTable from '../../components/OrderLinesTable/OrderLinesTable';
import history from '../../history';
import shippingStatus from '../../helpers/shippingStatusHelper';

class OrderHistoryPreview extends Component {
  previewAmount = 10;

  constructor(props) {
    super(props);

    if (this.props.order) {
      let statusIndicatorColor = '';
      let toolTipText = '';
      let cancelledOrBackordered = false;
      this.props.order.orderLines.forEach(line => {
        if (line.isCanceled || line.isBackordered) {
          cancelledOrBackordered = true;
        }
      });

      if (cancelledOrBackordered) {
        statusIndicatorColor = 'yellow';
        toolTipText = 'One or more lines are cancelled or backordered';
      } 

      this.state = {
        statusIndicatorColor: statusIndicatorColor,
        toolTipText: toolTipText
      };
    }

    this.state = {
      ...this.state,
      isReorderInProcess: false
    };
  }

  toggle = () => {
    this.props.onOrderHistoryToggle(this.props.order.id);
  };

  handleOrderDetailsClick = () => {
    history.push(`/orders/${this.props.order.id}`);
  };

  handleReorderClick = () => {
    const {intl, order} = this.props;
    
    this.setState({
      ...this.state,
      isReorderInProcess: true
    }, () => {
      this.props.onReorderClick(this.props.order.id)
        .then(() => {
          this.setState({
            ...this.state,
            isReorderInProcess: false
          });
        })
        .then(() => toastr.success(intl.formatMessage({id: 'orders.reorderToastTitle'}, {orderNumber: order.orderNumber}), null, {timeOut: 0}))
        .catch(() => toastr.error(intl.formatMessage({id: 'orders.reorderErrorToastTitle'}, {orderNumber: order.orderNumber}), null, {timeOut: 0}))
    });
  };

  render() {
    const { order } = this.props;

    let orderLinesTable = <OrderLinesTable locale={this.props.locale} orderLines={order.orderLines} linesLimit={this.previewAmount}/>;
    let moreCount = order && order.orderLines && order.orderLines.length ? order.orderLines.length - this.previewAmount : 0;
    let moreMarkup = moreCount > 0 && (
      <Row className='my-2'>
        <Col data-test-id='order-history-more' className='text-center'>
          <Button onClick={this.handleOrderDetailsClick}>+ {moreCount} More</Button>
        </Col>
      </Row>
    );

    let shippingStatusMarkup = shippingStatus.getShippingStatus(order);
    let indicatorMarkup = this.state.statusIndicatorColor.length > 0
      && shippingStatusMarkup.props.children.length > 0
      && !shippingStatusMarkup.props.children[0].includes('Delivered:') && (
      <span style={{ position: 'absolute', paddingLeft: '35px', paddingTop: '10px' }} >
        <Circle radius={11} color={this.state.statusIndicatorColor} id={order.orderNumber} toolTipText={this.state.toolTipText} />
      </span>
    );

    return (
      <ListGroupItem className='order-preview'>
        <Container onClick={this.toggle} fluid className='order-preview-header p-2'>
          <Row>
            <Col xs='auto' className='mr-2 d-flex align-items-md-center'>
              <span className='toggle'>
                <FontAwesomeIcon icon={this.props.isOpen ? 'angle-down' : 'angle-right'} />
              </span>
            </Col>
            <Col>
              <Container fluid>
                <Row>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='orders.purchaseOrderLabel'/></div>
                    <div data-test-id={`order-history-purchase-order-${order.orderNumber}`} className="order-info value">{order.purchaseOrderNumber || 'N/A'}</div>
                  </Col>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='orders.orderDateLabel'/></div>
                    <div data-test-id={`order-history-order-date-${order.orderNumber}`} className="order-info value">
                      <FormattedDate value={new Date(order.orderDate)} />
                    </div>
                  </Col>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='general.total'/></div>
                    <div data-test-id={`order-history-total-${order.orderNumber}`} className="order-info value">
                      <FormattedNumber
                        style='currency'  // eslint-disable-line react/style-prop-object
                        currency='USD'
                        value={order.total}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </div>
                  </Col>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='orders.shipTo'/></div>
                    <div data-test-id={`order-history-ship-to-${order.orderNumber}`} className="order-info value">{order.shipToName}</div>
                  </Col>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='orders.orderNumberLabel'/></div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div data-test-id={`order-history-order-number-${order.orderNumber}`} className="order-info value"><span onClick={this.handleOrderDetailsClick} className='is-mock-link'>{order.orderNumber}</span></div>
                  </Col>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='orders.shippingStatusLabel' />
                      {indicatorMarkup}
                    </div>
                    <div data-test-id={`order-history-shipping-status-${order.orderNumber}`} className="order-info value">{shippingStatusMarkup}</div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Collapse isOpen={this.props.isOpen}>
          <hr/>
          <Container fluid className='p-2'>
            <Row className='justify-content-center'>
              <Col lg='9'>
                <Container fluid>
                  <Row>
                    <Col>
                      <h5 className='order-info status mb-3'>Items In This Order</h5>
                      {orderLinesTable}
                    </Col>
                  </Row>
                  {moreMarkup}
                </Container>
              </Col>
              <Col lg='3' xs='8' className='mt-4 mt-lg-0'>
                <Container fluid className='pl-3'>
                  <Row>
                    <Col xs='12' data-test-id={`order-history-order-details-${order.purchaseOrderNumber}`} className='mb-3'>
                      <Button block onClick={this.handleOrderDetailsClick} color='primary'><FormattedMessage id='general.orderDetails'/></Button>
                    </Col>
                    <Col xs='12' data-test-id={`order-history-reorder-${order.purchaseOrderNumber}`} className='mb-3'>
                      <Button block color='secondary' onClick={this.handleReorderClick} isLoading={this.state.isReorderInProcess}><FormattedMessage id='orders.reorder'/></Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Collapse>
      </ListGroupItem>
    );
  }
}

OrderHistoryPreview.propTypes = {
  locale: PropTypes.string,
  isOpen: PropTypes.bool,
  onReorderClick: PropTypes.func,
  onOrderHistoryToggle: PropTypes.func
};

export default injectIntl(OrderHistoryPreview);
