import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Card, CardTitle, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { connect } from 'react-redux';
import user from '../../helpers/user';

import { Button } from '../../components';

class ActivateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountNumber: ''
    };
  }

  handleActivateAccountClick = () => {
    this.props.onActivateAccountClick(this.state.accountNumber);
  };

  handleAccountNumberChange = (e) => {
    let value = e.target.value;
    this.setState({
      ...this.state,
      accountNumber: value
    });
  };

  getActivationCardText = () => {
    const { data } = this.props.user;

    if (!data.accountActivationRequested) {
      return {
        title: 'home.accountActivationPending',
        description: 'home.activateAccountDescription'
      };
    }

    if (data.accountActivationRequested && !data.autoActivatedAt) {
      return {
        title: 'home.accountActivationPending',
        description: 'home.accountActivationRequestSent'
      };
    }

    return {
      title: 'home.autoAccountActivationFeedbackTitle',
      description: 'home.autoAccountActivationFeedbackDescription'
    };
  }

  render() {
    const { data } = this.props.user;
    const { accountNumber } = this.props;

    if (!data || data.accountNumber || accountNumber || user.isConnectUser()) {
      return null;
    }

    const { title, description } = this.getActivationCardText();

    return (
      <Card className='overview-card' body>
        <CardTitle className='text-uppercase'>
          <FormattedMessage id={title} />
        </CardTitle>
        {!data.accountActivationRequested && (
          <Row>
            <Col>
              <InputGroup>
                <Input value={this.state.accountNumber} onChange={this.handleAccountNumberChange} />
                <InputGroupAddon addonType="append">
                  <Button
                    color='primary'
                    isDisabled={!this.state.accountNumber}
                    isLoading={this.props.isLoading}
                    onClick={this.handleActivateAccountClick}>
                    <FormattedMessage id={'general.activate'} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        )}
        <Row className='mt-2'>
          <Col>
            <FormattedMessage id={description} />
          </Col>
        </Row>
      </Card>
    );
  }
}

ActivateAccount.propTypes = {
  user: PropTypes.object,
  onActivateAccountClick: PropTypes.func
};

const mapStateToProps = state => ({
  accountNumber: state.account.currentAccount.accountDetails.accountNumber
});

export default injectIntl(connect(mapStateToProps)(ActivateAccount));