const faq = {
  accounts: {
    howToSeeAccounts: {
      question: 'How can I see all of my Milwaukee Connect accounts?',
      answer: 'In order to obtain access to all accounts, you must be setup under the parent account and designated to be an Administrator over the account. Please contact CXHelp@milwaukeetool.com for further assistance.'
    },
    howToLocateAccount: {
      question: 'How can I locate my Milwaukee Connect account number?',
      answer: 'To find out what your account number is, please contact Customer Experience team at 800-729-3878.'
    },
    multipleContactNumbers: {
      question: 'Can I provide multiple contact numbers?',
      answer: 'You can have two phone numbers; a second number may be added in your account settings. You can also follow these instructions:' +
        '[Click the icon of the person in the upper right corner.||' +
        'You will be shown a menu, click on "Account".||' +
        'You will be taken to your account profile.||' +
        'Find the first section called "Personal Information", it should be open and showing your contact information. If it is not open, click on the bar that says "Personal Information" to show the contact information||' +
        'There will be two fields to enter a phone number, one labeled "Phone Number" (this field may already be filled in) and to the right of this number there is a field called “Cell Phone”.||' +
        'You can add the second number in the “Cell Phone” field; it does not have to be a cell phone number.||' +
        'Once finished, scroll to the bottom of the page and click the "Save Changes" button.]'
    },
    howToCreateAccount: {
      question: 'How do I create an account?',
      answer: 
        '[Go to milwaukeetool.com||' +
        'Click “SIGN UP / LOG IN” in the upper-right corner.||' +
        'Click “Sign Up” on the pop-up.||' +
        'Enter your email address and password.||' +
        'Optional: Check the box if you want to receive Milwaukee email marketing & complete the fields.||' +
        'Click the Sign-Up button.]'
    },
    howToResetPassword: {
      question: 'How do I reset my password?',
      answer: 'If you\'ve forgotten your password, follow these instructions:' +
        '[In the upper right corner of the page click “Sign In".||' +
        'You will be shown a pop-up window with two tabs.||' +
        'In the displayed tab ("Log In"), there is a link named "Don\'t remember your password?".||' +
        'Click "Don\'t remember your password?"||' +
        'Enter your email address.||' +
        'Click the "Send Email" button.||' +
        'You will be taken back to the "Log In" tab.||' +
        'Go to your email client and check for an email from accounts@milwaukeetool.com]'
    }
  },
  orderingOrders: {
    howToPriceAvailability: {
      question: 'How can I find price and availability?',
      answer: 'To obtain the price and availability of a product:' +
        '[Enter a product number or name into the "Find a Product" search field||' +
        'You will be taken to a search results page showing you the product you searched for or a range of products if you searched by a product name.||' +
        'Find the product you\'d like a price on, the price and availability will be shown with the product information.||' +
        'Pricing and availability are also presented on the product detail pages. If you click on the name or product number in the search results, that will take you to the product details page.]'
    },
    howToPricingSchedule: {
      question: 'How can I locate my pricing schedule on Milwaukee Connect 2.0?',
      answer: 'Unfortunately, this feature is unavailable at this time. Please contact Customer Experience at 800-729-3878 to obtain your pricing schedule.'
    },
    applyPCE: {
      question: 'Can I apply PCE\'s to my Milwaukee Connect 2.0 order?',
      answer: 'Unfortunately, this feature is unavailable at this time. Please email your purchase order to METOrders@milwaukeetool.com. The PCE(s) referenced on your purchase order will be applied during order entry as applicable.'
    },
    employeePayment: {
      question: 'Can I pay for my order on Milwaukee Connect 2.0? (Employee)',
      answer: 'Yes, you can pay for your order on Milwaukee Connect. Your credit card number may be added to your account preferences. You can also follow the below instructions:'+
          '[Click on the person icon in the upper-right corner to access your account menu.||' +
          'Click "Preferences" in the menu to access your account profile.||' +
          'Click "Credit Cards" the "Add Credit Card".||' +
          'Enter your credit card information and click "Add Card".]'
    },
    locateTrackingNumber: {
      question: 'How can I locate the tracking number for my order?',
      answer: 'Tracking number will be assigned once the order has shipped. You will receive an email that your order has shipped. Once you receive this email you can click on the link in the email to view tracking. You can also follow these instructions (remember, there will be no tracking until your order ships):' +
        '[Log into Connect.||' +
        'Click on "Orders" in the main navigation - this will take you to the Sales Order Dashboard.||' +
        'Find the order you want to know the tracking information on.||' +
        'Click on the "Order #" (it will start with "AX").||' +
        'In the "Items in this Order" section, it will have a button to check the tracking on shipped items.]'
    },
    howToStatusUpdates: {
      question: 'How can I see and receive status updates for my order?',
      answer: 'You will receive an email for order confirmation, order shipped, and order delivered. If you haven\'t received the emails, check your spam folder.  This information is also available on the Sales Order Dashboard:' +
        '[Log into Connect.||' +
        'Click on "Orders" in the main navigation - this will take you to the Sales Order Dashboard.||' +
        'Find the order you want to know the status of.||' +
        'Click on the "Order #" (it will start with "AX").||' +
        'In the "Items in this Order" section, it will tell you the status of the products in the order.]'
    },
    canIPickup: {
      question: 'Can I enter the order and pick up at my local Branch?',
      answer: 'Unfortunately, you cannot pick up orders at your local branch. All orders will ship from our main distribution center in Olive Branch, MS.'
    },
    canSalesRepsOrder: {
      question: 'Can my sales representative enter orders on my behalf?',
      answer: 'Your Sales Rep is not able to submit orders on your behalf. However, your Sales Rep can provide a quote and check the status of orders for you.'
    },
    receivedWrongProduct: {
      question: 'What do I do if I received a product in error? What if I received the wrong product?',
      answer: 'Please contact Milwaukee’s Customer Service team at CXHelp@milwaukeetool.com with a picture of the product received, order #, PO#.  A bin check will be initiated, and a replacement order number will be communicated once the inventory has been confirmed as accurate.'
    },
    whenWillOrderShip: {
      question: 'How soon will my order ship?',
      answer: 'Orders are processed and shipped in the order they are received.  You will be provided a delivery estimate based on your location and selected shipping speed.  You will be notified by email when your order ships.  You can access full order details at any time on the Sales Order Dashboard.' +
        '<[Log into Connect.||' +
        'Click on "ORDERS" in the main navigation to access Your Orders.||' +
        'Find the order you are looking for in the Order History results.||' +
        '[[Use the filters to narrow down results by Date, Purchase Order, or Order #]]||' +
        'There are two ways to access the Order Details page.||' +
        '[[Option 1: Click the row containing the Purchase Order or Order Number to view the Items In This Order, then click "Order Details".]]||' +
        '[[Option 2: Click on the number in the "ORDER #" column (it will start with "AX").]]]>'
    },
    howCancelItemFromOrder: {
      question: 'How can I cancel an item from my order?',
      answer: 'Items cannot be canceled in Milwaukee Connect. To request a cancellation, please contact our Customer Experience Support Center at 1.800.729.378 or email CXHelp@milwaukeetool.com.'
    },
    howToCreateACustomizedProduct: {
      question: 'How can I place a custom product order?',
      answer:
        'To place a custom product order, navigate to the \'Custom PPE\' tab of Connect, search for a product you want to customize, or reach out to your Milwaukee Tool safety specialist. Find more details around custom orders {customizerFAQLink}.'
    }
  },
  invoicingStatements: {
    howToLocateInvoice: {
      question: 'How can I view (download) an invoice?',
      answer: 'To download your invoice(s):' +
        '[Log into Connect||' +
        'From the main menu, click on "Invoices"||' +
        'You will be taken to the invoices page.||' +
        'To find the invoice you are looking for, you can use the date filters and/or the search function to find specific invoices.||' +
        'Once you have found the invoice, click on the download icon and your invoice will be downloaded to your device.]'
    },
    howToCustomerStatement: {
      question: 'How can I download a "Customer Statement"?',
      answer: 'To download your customer statement:' +
        '[Log into Connect.||' +
        'From the main menu, click on "Invoices".||' +
        'You will be taken to the invoices page.||' +
        'There will be a button called "Customer Statements".||' +
        'Click it and the file will be downloaded to your device.]'
    },
    howToCreditMemo: {
      question: 'How can I retrieve my credit memo?',
      answer:
        '<[Log into Connect.||' +
        'Click on "INVOICES" in the main navigation to access your Invoices.||' +
        'Find the invoice you are looking for in the Your Invoices results.||' +
        '[[Use the filters to narrow down results by Date, Purchase Order, or Order #]]||' +
        'The credit amount will be in parenthesis.||' +
        'Click the credit amount and the file will be downloaded to your device.]>'
    }
  },
  warranty: {
    noCatalogNumber: {
      question: 'What do I do if the catalog number is not able to be entered on a warranty claim?',
      answer: '[[You may be entering a service restricted number.||' +
        'The search results will let you know if a catalog number is covered under warranty. If a catalog number is not covered, you can still get it repaired through eService.||' +
        'Please check if you are entering the correct item number.]]'
    },
    noPartNumber: {
      question: 'What do I do if the part number is not able to be entered on a warranty claim?',
      answer: '[[Please make sure you are entering a Service Part Number; accessories cannot be entered.||' +
              'If the part has a valid Service Part Number and is still not accepted, please call your warranty manager.]]'
    },
    rejectedClaim: {
      question: 'If a claim is rejected what should I do?',
      answer: 'When a claim is rejected, your warranty manager needs more information before your claim can be approved. Please follow these steps for a rejected claim:' +
        '[Log into Connect.||' +
        'Click on "Service" in the main navigation.||' +
        'You will be shown a menu - select "Warranty Claims".||' +
        'Search for the rejected claim.||' +
        'Click on the "View" button for the rejected claim.||' +
        'Scroll to the bottom of the page and look for the comments section.||' +
        'Read the comments from the warranty manager.||' +
        'Answer any questions in the text box and submit them.||' +
        'The claim will then be re-reviewed.]'
    },
    pendingClaimStatus: {
      question: 'Why is my battery warranty claim still on "Pending" status?',
      answer: 'Please call or email your service manager. You will need to supply them with the EW# and tracking number that shows the battery was delivered to Greenwood, MS. Your warranty manager will respond with an answer to the issue.'
    },
    defectiveParts: {
      question: 'What do I do if parts are defective out of the packaging?',
      answer: 'You will need to fill out a “New Defective Part Claim”. Please follow these steps:' +
        '[Log into Connect.||' +
        'Click on "Service" in the main navigation.||' +
        'You will be shown a menu - select "Warranty Claims".||' +
        'Select the "New Part Claim" tab.||' +
        'Fill out the form and submit it.]'
    }
  },
  manualsPartLists: {
    howToLatestDPL: {
      question: 'How can I locate the latest DPL (Distributor Price List)?',
      answer: 'The Distributor Price List is located on the Pricing and Publications page. Please follow these steps:' +
        '[Log into Connect.||' +
        'Click on "Service" in the main navigation.||' +
        'You will be shown a menu - select "Pricing/Publications".||' +
        'You will be taken to the Pricing and Publications page.||' +
        'Under "Parts and Pricing", find "Tool & Accessory" and click the button below that.||' +
        'The file will be downloaded to your device.]'
    },
    howToPartsListManuals: {
      question: 'How can I get the parts list or a manual for my tool?',
      answer: 'The parts lists and manuals are located on the Pricing and Publications page. Please follow these steps:' +
        '[Log into Connect.||' +
        'Click on "SUPPORT" in the main navigation.||' +
        'Click "Pricing/Publications" in the menu to access the Pricing and Publications page.||' +
        'Locate the "Technical Publications" section.||' +
        'Click the "Search for Technical Publications" button to be taken to the Manuals and Publications page on milwaukeetool.com.]'
    }
  }
};

export default faq;