import createFetchAction from './createFetchAction';

export const SUBMIT_CONTACT_US = 'SUBMIT_CONTACT_US';

export const initialState = {
  submitContactForm: {
    isProcessing: false,
    isError: false,
  }
};

export const actionCreators = {
  submitContactForm: (subjectTypeCode, subjectType, emailBody) => {
    return createFetchAction({
      method: 'POST',
      url: '/api/v1/users/_actions/submitcontactus',
      body: { subjectTypeCode, subjectType, emailBody }, 
      startAction: SUBMIT_CONTACT_US
    })
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case SUBMIT_CONTACT_US:
      return {
        ...state,
        submitIsSuccess: true
      };
    default:
      break;
  }
  return state;
};