import createFetchAction from './createFetchAction';

export const LOADING_CONFIGURATION = 'LOADING_CONFIGURATION';
export const LOADING_CONFIGURATION_SUCCESS = 'LOADING_CONFIGURATION_SUCCESS';
export const LOADING_CONFIGURATION_ERROR = 'LOADING_CONFIGURATION_ERROR';

export const initialState = {
  featureToggles: {},
  batteryClaimExclusionCountries: [],
  flatRateAccountNumbers: [],
  distributorAccountRestrictions: {},
  getConfig: {
    isLoading: false,
    isError: false
  }
};

export const actionCreators = {
  getConfig: () => {
    return createFetchAction({
      url: `/api/v1/configuration`,
      startAction: LOADING_CONFIGURATION
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case LOADING_CONFIGURATION:
      return {
        ...state,
        getConfig: {
          isLoading: true,
          isError: false
        }
      };
    case LOADING_CONFIGURATION_SUCCESS:
      return {
        ...state,
        featureToggles: action.payload.data.featureToggles,
        batteryClaimExclusionCountries: action.payload.data.batteryClaimExclusionCountries,
        flatRateAccountNumbers: action.payload.data.flatRateAccountNumbers,
        threeKitConfig: action.payload.data.threeKitConfig,
        distributorAccountRestrictions: action.payload.data.distributorAccountRestrictions,
        getConfig: {
          isLoading: false,
          isError: false
        }
      };
    case LOADING_CONFIGURATION_ERROR:
      return {
        ...state,
        getConfig: {
          isLoading: false,
          isError: true
        }
      };
    default:
      return state;
  }
};