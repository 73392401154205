import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, Col, Container, Row} from 'reactstrap';

import OrderSummary from '../../components/OrderSummary/OrderSummary';
import PageLoader from '../../components/PageLoader/PageLoader';
import {getProfile} from '../../helpers/localStorage';
import history from '../../history';
import {actionCreators as orderActions} from '../../store/Orders';

class Confirmation extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getOrder(id);
  }

  handleViewOrderButtonClick = () => {
    history.push(`/orders/${this.props.orderDetails.order.id}`);
  };
  
  render() {
    const { order, isLoading } = this.props.orderDetails;

    let userProfile = getProfile();
    
    if (isLoading && !order) {
      return (
        <PageLoader/>
      );
    }
    else if (!order) {
      return (
        <PageLoader/>
      );
    }
    
    return (
      <div id='confirmation' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row>
            <Col>
              <div data-test-id='thank-you-username' className='has-font-size-3 font-weight-bold mb-3'><FormattedMessage id='confirmation.thankYou'/>, {userProfile.nickname}!</div>
              <p><FormattedMessage id='confirmation.explanations.separateShipments'/></p>
              <p><FormattedMessage id='confirmation.explanations.backordered'/></p>
            </Col>
          </Row>
          <Row>
            <Col>
              <OrderSummary
                order={order}
                hideReorder={true}
              />
              <Container>
                <Row data-test-id='view-order' className='justify-content-end mt-4 mb-5'>
                  <Col lg='2' md='4'>
                    <Button onClick={this.handleViewOrderButtonClick} block color='primary'><FormattedMessage id='general.viewOrder'/></Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='has-font-size-4 font-weight-bold mb-3'><FormattedMessage id='confirmation.needHelp.title'/></div>
              <Container>
                <Row>
                  <Col lg='4' md='6'>
                    <div className='has-font-size-3 font-weight-bold mb-2'>FAQ</div>
                    <ul className='list-unstyled'>
                      <li><Link to='/faq#orderingOrders'><FormattedMessage id='faq.orderingOrders.howToPriceAvailability.question'/></Link></li>
                      <li><Link to='/faq#orderingOrders'><FormattedMessage id='faq.orderingOrders.howToStatusUpdates.question'/></Link></li>
                      <li><Link to='/faq#orderingOrders'><FormattedMessage id='faq.orderingOrders.locateTrackingNumber.question'/></Link></li>
                    </ul>
                  </Col>
                  <Col lg='4' md='6'>
                    <div data-test-id='confirmation-contact-us' className='has-font-size-3 font-weight-bold mb-2'><Link to='/contact' className='btn btn-outline-primary'>Contact Us</Link></div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderDetails: state.orders.orderDetails
  };
};

const mapDispatchToProps = dispatch => ({
  getOrder: orderId => dispatch(orderActions.getOrder(orderId))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Confirmation));
