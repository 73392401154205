import createFetchAction from './createFetchAction';

export const NEW_ANNOUNCEMENT = 'NEW_ANNOUNCEMENT';
export const NEW_ANNOUNCEMENT_SUCCESS = 'NEW_ANNOUNCEMENT_SUCCESS';
export const NEW_ANNOUNCEMENT_ERROR = 'NEW_ANNOUNCEMENT_ERROR';

export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_ANNOUNCEMENT_ERROR = 'UPDATE_ANNOUNCEMENT_ERROR';

export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const GET_ANNOUNCEMENTS_ERROR = 'GET_ANNOUNCEMENTS_ERROR';

export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
export const GET_ANNOUNCEMENT_SUCCESS = 'GET_ANNOUNCEMENT_SUCCESS';
export const GET_ANNOUNCEMENT_ERROR = 'GET_ANNOUNCEMENT_ERROR';

export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';
export const DELETE_ANNOUNCEMENT_ERROR = 'DELETE_ANNOUNCEMENT_ERROR';

export const initialState = {
  newAnnouncement: {
    isProcessing: false,
    isError: false
  },
  updateAnnouncement: {
    isProcessing: false,
    isError: false
  },
  getAnnouncements: {
    announcements: [],
    isLoading: false,
    isError: false
  },
  getAnnouncement: {
    announcement: null,
    isLoading: false,
    isError: false
  },
  deleteAnnouncement: {
    isProcessing: false,
    isError: false
  }
};

export const actionCreators = {
  newAnnouncement: (announcement) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/announcements`,
      body: {...announcement},
      startAction: NEW_ANNOUNCEMENT,
    });
  },
  updateAnnouncement: (announcement) => {
    return createFetchAction({
      method: 'PUT',
      url: `/api/v1/announcements`,
      body: {...announcement},
      startAction: UPDATE_ANNOUNCEMENT,
    });
  },
  getAnnouncements: (query) => {
    let queryString = Object
      .keys(query)
      .filter(key => query[key] !== null || query[key] !== undefined)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
      .join('&');

    return createFetchAction({
      url: `/api/v1/announcements?${queryString}`,
      startAction: GET_ANNOUNCEMENTS
    });
  },
  deleteAnnouncement: (id) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/api/v1/announcements/${id}`,
      startAction: DELETE_ANNOUNCEMENT
    });
  },
  getAnnouncement: (id) => {
    return createFetchAction({
      url: `/api/v1/announcements/${id}`,
      startAction: GET_ANNOUNCEMENT
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  
  switch (action.type) {
    case NEW_ANNOUNCEMENT:
      return {
        ...state,
        newAnnouncement: {
          isProcessing: true,
          isError: false
        }
      };
    case NEW_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        newAnnouncement: {
          isProcessing: false,
          isError: false
        }
      };
    case NEW_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        newAnnouncement: {
          isProcessing: false,
          isError: true
        }
      };
      
    case UPDATE_ANNOUNCEMENT:
      return {
        ...state,
        updateAnnouncement: {
          isProcessing: true,
          isError: false
        }
      };
    case UPDATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        updateAnnouncement: {
          isProcessing: false,
          isError: false
        }
      };
    case UPDATE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        updateAnnouncement: {
          isProcessing: false,
          isError: true
        }
      };
      
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        getAnnouncements: {
          ...state.getAnnouncements,
          isLoading: true,
          isError: false
        }
      };
    case GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        getAnnouncements: {
          announcements: action.payload.data,
          totalPages: action.payload.totalPages || 0,
          totalCount: action.payload.totalCount,
          count: action.payload.count,
          isLoading: false,
          isError: false
        }
      };
    case GET_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        getAnnouncements: {
          announcements: [],
          isLoading: false,
          isError: true
        }
      };

    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        deleteAnnouncement: {
          isProcessing: true,
          isError: false
        }
      };
    case DELETE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        deleteAnnouncement: {
          isProcessing: false,
          isError: false
        }
      };
    case DELETE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        deleteAnnouncement: {
          isProcessing: false,
          isError: true
        }
      };
      
    case GET_ANNOUNCEMENT:
      return {
        ...state,
        getAnnouncement: {
          ...state.getAnnouncement,
          isLoading: true,
          isError: false
        }
      };
    case GET_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        getAnnouncement: {
          ...state.getAnnouncement,
          announcement: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case GET_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        getAnnouncement: {
          ...state.getAnnouncement,
          isLoading: false,
          isError: true
        }
      };
    
    default:
      return state;
  }
};