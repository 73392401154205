import {
  Placeholder, 
  PlaceholderContent, 
  Row,
  Col,
  Icon
} from '@met/react-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import  styled  from 'styled-components';
import React from 'react';


const LabelIcon = styled(Icon)`
  margin-right: .25em;
  vertical-align: -0.3em
`;

const CreditCardLoader = () => {
  
  return (
    <Placeholder>
      <Row style={{'align-items': 'center' }}>
        <Col xs={2}>
          <LabelIcon sm type='credit-card' />
        </Col>
        <Col xs={8}>
          <PlaceholderContent />
        </Col>
        <Col xs={2}>
          <FontAwesomeIcon className='is-mock-link text-secondary mx-2' icon='trash-alt' />
        </Col>
      </Row>
    </Placeholder>
  )
}

export default CreditCardLoader;