import React, {Component, Fragment} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import auth from '../../auth';
import classNames from 'classnames';

class ProductAvailabilityMarkup extends Component {
  render() {
    const { availabilityStatus, recoveryDate, onlyShowBackordered, customMessage } = this.props;
    
    if (!auth.isAuthenticated()) {
      return null
    }
    else if (availabilityStatus) {
      let availabilityClasses = classNames(
        availabilityStatus === 1 && 'is-color-green',
        availabilityStatus === 2 && '',        
        availabilityStatus === 3 && 'is-color-yellow',
        availabilityStatus === 4 && 'is-color-blue',
        availabilityStatus === 5 && 'is-color-green',
        availabilityStatus === 6 && ''
      );
      let recoveryDateClasses = classNames(
        'has-font-size-2',
        availabilityClasses
      );
      let recoveryMessageMarkup = (
        <Fragment>
          <br/>
          <span className={recoveryDateClasses}>
            {customMessage}
          </span>
        </Fragment>
      ); 
      let recoveryDateMarkup = recoveryDate != null && new Date(recoveryDate.toString()) >= Date.now() && (
        <Fragment>
          <br/>
          <span className={recoveryDateClasses}>
            {customMessage !== 'PreSale' ? <FormattedDate value={recoveryDate}/> : ""}
          </span>
        </Fragment>
      );

      switch(availabilityStatus) {
        case 1:
          return !onlyShowBackordered && (<span className={availabilityClasses}><FormattedMessage id='products.inStock'/></span>);
        case 2:
          return !onlyShowBackordered && (<span className={availabilityClasses}><FormattedMessage id='products.unavailable'/></span>);
        case 3: {
          let recoveryMarkup = customMessage ? recoveryMessageMarkup : recoveryDateMarkup;
          return (
            <Fragment>
              <span className={availabilityClasses}><FormattedMessage id='products.backordered'/></span>
              {recoveryMarkup}
            </Fragment>
          );
        }
        case 4: {
          let messageKey = 'products.' + customMessage;
          return (
            <Fragment>
              <span className={availabilityClasses}><FormattedMessage id={messageKey}/></span>
              {recoveryDateMarkup}
            </Fragment>
          );
        }
        case 5:
          return !onlyShowBackordered && (<span className={availabilityClasses}><FormattedMessage id='products.discontinuedInStock' /></span>);
        case 6:
          return !onlyShowBackordered && (<span className={availabilityClasses}><FormattedMessage id='products.discontinuedOutOfStock' /></span>);
        default:
          return !onlyShowBackordered && (<span className={availabilityClasses}><FormattedMessage id='products.unavailable'/></span>);
      }
    }
    else {
      return !onlyShowBackordered && (<FormattedMessage id='products.unavailable'/>);
    }
  }
}

ProductAvailabilityMarkup.propTypes = {
  availabilityStatus: PropTypes.number.isRequired,
  recoveryDate: PropTypes.string,
  onlyShowBackordered: PropTypes.bool
};

ProductAvailabilityMarkup.defaultProps = {
  onlyShowBackordered: false
};

export default injectIntl(ProductAvailabilityMarkup);