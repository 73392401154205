import React from 'react';
import { FormattedDate } from 'react-intl';

export const getShippingStatus = (order) => {
  let totalOrderLines = order.orderLines.length;
  let cancelledLines = order.orderLines.filter(orderLine => orderLine.isCanceled);

  // Cancelled Order Lines
  if (cancelledLines && cancelledLines.length === totalOrderLines) {
    return (<div>Cancelled</div>);
  }
  else {
    // Shipped Order Lines
    let matchedOrderShipmentArrays = order.orderShipments.map(shipment => {
      return order.orderLines.map(orderLine => {
        return shipment.orderShipmentLines.find(orderShipmentLine => orderLine.id === orderShipmentLine.orderLineId);
      }).filter(x => x !== undefined);
    });

    var matchedOrderShipments = [].concat(...matchedOrderShipmentArrays);

    if (cancelledLines && cancelledLines.length > 0) {
      totalOrderLines = totalOrderLines - cancelledLines.length;
    }

    if (matchedOrderShipments && matchedOrderShipments.length === totalOrderLines) {
      let delivered = order.orderShipments.filter(shipment => shipment.statusDetails === 'Delivered');
      if (delivered.length === order.orderShipments.length) {
        let latestDeliveryDate = new Date(Math.max.apply(null, delivered.map(function (e) {
          return new Date(e.shipment.tracking_status.status_date);
        })));
        return (<div>Delivered: <br /> <FormattedDate value={new Date(latestDeliveryDate)} /></div>);
      } else if (delivered.length > 0) {
        return (<div>Partially Delivered</div>);
      }

      let latestShipmentDate = new Date(Math.max.apply(null, order.orderShipments.map(function (e) {
        return new Date(e.shippedDate);
      })));
      return (<div>Shipped: <br /> <FormattedDate value={new Date(latestShipmentDate)} /></div>);
    }

    if (matchedOrderShipments && matchedOrderShipments.length > 0) {
      return (<div>Partially Shipped</div>);
    }
  }

  // Back Order Lines
  let backorderedOrderLines = order.orderLines
    .filter(orderLine => orderLine.isBackordered
      && orderLine.isCustomLogo === false
      && orderLine.remainingQuantity > 0);

  if (backorderedOrderLines && backorderedOrderLines.length === totalOrderLines) {
    return (<div>Backordered</div>);
  }

  // Default to Processing
  return (<div>Processing</div>);
}

export default {
  getShippingStatus: (order) => {
    return getShippingStatus(order);
  }
}