import PropTypes from 'prop-types';
import React, {Fragment} from 'react';

import {Message} from '../../../components';
import history from '../../../history';

const OrderLineMessage = ({group, orderLine, className}) => {
  if (group !== 'unavailable')
    return null;

  let invalidMessage = null;
  if (orderLine.replacementSku)
    invalidMessage = (
      <Fragment>
        <span>Item has been replaced by </span>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span onClick={() => { history.push(`/products/${orderLine.replacementSku}`) }} className='has-pointer-icon font-weight-bold'>{orderLine.replacementSku}</span>
        <span> - please replace and/or remove to proceed to Checkout</span>
      </Fragment>
    );
  else if ((orderLine.nextValidQuantityAllowed > 0 && orderLine.quantity % orderLine.nextValidQuantityAllowed !== 0) || orderLine.lowestQuantityAllowed > orderLine.quantity || (orderLine.highestQuantityAllowed > 0 && orderLine.highestQuantityAllowed < orderLine.quantity))
    //this message occurs if an item is added through the quick add, and the quantity selected is invalid (and thus is put in the "unavailable" group.)
    //the quantity input contains more details on what exactly are valid inputs
    invalidMessage = 'Selected quantity is not allowed for this item - please update quantity to proceed to Checkout';
  else
    invalidMessage = 'Item is currently unavailable - please remove from cart to proceed to Checkout';

  return (
    <Message
      type='info'
      icon='info-circle'
      value={invalidMessage}
      className={className}
    />
  );
};

OrderLineMessage.propTypes = {
  group: PropTypes.string,
  orderLine: PropTypes.object,
  className: PropTypes.string
};

export default OrderLineMessage;