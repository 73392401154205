import { theme } from '@met/react-components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Card, CardBody, ListGroup, ListGroupItem, Col, Container, Row, } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Button } from '../../../components';
import OrderLineMessage from './OrderLineMessage';
import OrderLinePreview from './OrderLinePreview';
import OrderLinePreviewGrid from './OrderLinePreviewGrid';

const color = props => {
  return css`color: ${theme.colors.yellow};`;
};

const Title = styled.h5`
  ${(props) => color(props)}
`;

const MultiSiteOrderLineGroup = (props) => {
  let orderLineMarkup = props.orderLines && props.orderLines.map(orderLine => {
    return (
      <ListGroupItem key={`${props.group}-${orderLine.sku}`} className={props.useGridView ? 'cart-list-item-grid' : 'cart-list-item'}>
        {props.useGridView ? (
          <Fragment>
            <OrderLinePreviewGrid
              orderLine={orderLine}
              group={props.group}
              onQuantityChange={props.onQuantityChange}
              onRemoveFromCartClick={props.onRemoveFromCartClick}
              onSaveForLaterClick={props.onSaveForLaterClick}
              isMultiShipment={true}
            />
            <OrderLineMessage group={props.group} orderLine={orderLine} className='m-0' />
          </Fragment>
        ) : (
          <Fragment>
            <OrderLinePreview
              orderLine={orderLine}
              group={props.group}
              onQuantityChange={props.onQuantityChange}
              onRemoveFromCartClick={props.onRemoveFromCartClick}
              onSaveForLaterClick={props.onSaveForLaterClick}
            />
            <OrderLineMessage group={props.group} orderLine={orderLine} className='my-1' />
          </Fragment>
        )}

      </ListGroupItem>
    )
  });

  return (
    <div>
      <Container>
        <Row className='mb-3'>
          <Col xs='auto'>
            <Title group={props.group} style={{ marginTop: '8px' }}>
              {props.group}
            </Title>
          </Col>
          <Col xs sm='auto' className='align-self-center text-right text-md-left'>
            <Button outline color='primary' onClick={(e) => props.onSaveGroupForLaterClick(props.orderLines?.map(l => l.id).join(','))}>Save Group For Later</Button>
          </Col>
        </Row>
      </Container>
      <Card>
        <CardBody className='p-0'>
          <ListGroup className='cart-list'>
            {orderLineMarkup}
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  )
};

MultiSiteOrderLineGroup.propTypes = {
  orderLines: PropTypes.arrayOf(Object),
  group: PropTypes.string,
  useGridView: PropTypes.bool,
  onQuantityChange: PropTypes.func,
  onRemoveFromCartClick: PropTypes.func,
  onSaveForLaterClick: PropTypes.func
};

export default MultiSiteOrderLineGroup;