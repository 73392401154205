import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CardBody, Col, Row, useToggle} from '@met/react-components';
import React, {Fragment, useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from "react-redux-toastr";
import {bindActionCreators} from 'redux';
import styled from 'styled-components';

import {AddressForm, AddressFormModal} from '../../components';
import {addressActions, countriesActions, statesActions} from '../../store';
import AddressCard from './components/AddressCard';
import ShippingInfoLoader from './components/ShippingInfoLoader';
import UserAddress from './components/UserAddress';

const AddAddressLabel = styled.span`
  font-weight: 700;
  font-size: 21px;
  line-height: 1.3;
  color: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ShippingInfo = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [addressModalHidden, toggle] = useToggle(true);
  
  // load the user addresses for milwaukee tool employees only
  useEffect(() => {
    if (props.isEmployeeAccount && props.isCreditCardOnlyAccount){
      props.addressActions.getUserAddresses();
      props.statesActions.getStates();
      props.countriesActions.getCountries();
    }
    
  }, [props.isEmployeeAccount && props.isCreditCardOnlyAccount]);
  
  // show the page as loading until both addresses are loaded and the user account is loaded
  useEffect(() => {
    const addressesLoading = !props.addresses.userAddresses && props.addresses.getAddresses.isLoading;

    if (!props.accountLoaded || (props.isEmployeeAccount && (addressesLoading || props.statesLoading || props.countriesLoading)))
      setIsLoading(true);
    else
      setTimeout(() => setIsLoading(false), 600);
    
  }, [props.addresses.getAddresses.isLoading, props.accountLoaded, props.statesLoading, props.countriesLoading]);

  const handleSaveAddressClick = (address) => {
    return props.addressActions.upsertAddress(address);
  };
  
  const handleDeleteAddressClick = (addressId) => {
    return props.addressActions.deleteAddress(addressId)
      .catch(() => toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'addressManagement.deleteErrorMessage'}), {timeOut: 0}));
  };
  
  let addressMarkup = null;
  // Show address cards for milwaukee tool employees who use credit cards only
  if (props.isEmployeeAccount && props.isCreditCardOnlyAccount){
    addressMarkup = props.addresses.userAddresses && props.states && props.countries && props.addresses.userAddresses.map((address, index) => {
      return (
        <UserAddress
          key={`address-${index}`}
          address={address}
          states={props.states}
          countries={props.countries}
          accountName={props.accountName}
          onSaveClick={handleSaveAddressClick}
          onDeleteClick={handleDeleteAddressClick}
        />
      )
    });
  }
  // otherwise just show a disabled address form
  else if (props.accountLoaded && props.accountAddress) {
    addressMarkup = <AddressForm address={props.accountAddress} states={props.states} countries={props.countries} disabled/>;
  }
  
  return (
    <div style={{minHeight: '310px'}}>
      <Row>
        <h5>Shipping Info</h5>
        <hr/>
      </Row>
      <Row>
        {
          props.isEmployeeAccount && props.isCreditCardOnlyAccount && (
            <Col xs={12} sm={6} className='d-flex'>
              <AddressCard onClick={toggle}>
                <CardBody className='d-flex align-items-center justify-content-center'>
                  <AddAddressLabel>
                    <FontAwesomeIcon icon='plus'/>
                    <span>Add New Address</span>
                  </AddAddressLabel>
                </CardBody>
              </AddressCard>
            </Col>
          )
        }
        {isLoading ? <ShippingInfoLoader/> : (
          <Fragment>
            {addressMarkup}
            <AddressFormModal
              states={props.states}
              countries={props.countries}
              hidden={addressModalHidden}
              toggle={toggle}
              onSaveClick={handleSaveAddressClick}
            />
          </Fragment>
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    accountNumber: state.account.currentAccount.accountDetails.accountNumber,
    accountName: state.account.currentAccount.accountDetails.name,
    accountAddress: state.account.currentAccount.accountDetails.address,
    accountLoaded: !state.account.currentAccount.isLoading && state.account.currentAccount.accountDetails.accountNumber !== '',
    addresses: state.addresses,
    states: state.states.states,
    statesLoading: state.states.getStates.isLoading,
    countries: state.countries.countries,
    countriesLoading: state.countries.getCountries.isLoading,
    isEmployeeAccount: state.account.currentAccount.accountDetails.isEmployeeAccount,
    isCreditCardOnlyAccount: state.account.currentAccount.accountDetails.isCreditCardOnlyAccount && state.account.currentAccount.accountDetails.currencyCode === "USD"
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addressActions: bindActionCreators(addressActions, dispatch),
    statesActions: bindActionCreators(statesActions, dispatch),
    countriesActions: bindActionCreators(countriesActions, dispatch)
  }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShippingInfo));