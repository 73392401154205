import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert} from 'reactstrap';
import PropTypes from "prop-types";

class Message extends Component {
  render() {
    const { icon, isHidden, type, value, className } = this.props;
    
    if (isHidden)
      return null;
    
    const iconMarkup = icon && <FontAwesomeIcon icon={icon} className='mr-3' />;
    
    return (
      <Alert color={type} className={className}>
        {iconMarkup}
        {value}
        {this.props.children}
      </Alert>
    );
  }
}

Message.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']).isRequired,
  icon: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isHidden: PropTypes.bool,
  className: PropTypes.string
};

Message.defaultProps = {
  type: 'info'
};

export default Message;