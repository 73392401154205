import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, FormFeedback } from 'reactstrap';


const Checkbox = (props) => {
  const [isChecked, setIsChecked] = useState(props.isChecked || false);
  const [showSearchHelp, setshowSearchHelp] = useState(false);

  useEffect(() => {
    setIsChecked(props.isChecked);
  }, [props.isChecked]);

  const toggle = () => {
    if (!props.isDisabled) {
      let checked = !isChecked;
      setIsChecked(checked);

      if (props.onToggle)
        props.onToggle(checked, props.value);
    }
  };

  const toggleSearchHelpModal = () => {
    let showSearch = !showSearchHelp;
    setshowSearchHelp(showSearch);
  };

  let checkboxClasses = classNames(
    'checkbox',
    props.error && 'is-invalid'
  );

  let iconClasses = classNames(
    props.isDisabled && 'is-color-gray'
  );

  let iconMarkup = (
    <FontAwesomeIcon icon={['far', isChecked ? 'check-square' : 'square']} className={iconClasses} />
  );

  let helpButton = props.searchHelpModalContent && (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span className='help-button' color='none' onClick={toggleSearchHelpModal}>
      <FontAwesomeIcon icon='question-circle' />
    </span>
  );

  let searchHelpModal = props.searchHelpModalContent && (
    <Modal isOpen={showSearchHelp} toggle={toggleSearchHelpModal} centered={true} style={{"margin-top":"100px"}}>
      <ModalHeader toggle={toggleSearchHelpModal}>{props.label}</ModalHeader>
      <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.searchHelpModalContent) }} />
      </ModalBody>
    </Modal>
  );
  return (
    <div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span className={checkboxClasses} onClick={toggle}>
            {iconMarkup}
            <span className='px-2'>{props.label}</span>
            {props.error && <FormFeedback>{props.error}</FormFeedback>}
        </span>
        {helpButton}
        {searchHelpModal}
    </div>
  
  );
};

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  onToggle: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  searchHelpModalContent: PropTypes.string
};

export default Checkbox;