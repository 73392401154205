import React, {Component} from 'react';

import {
  InputGroup, InputGroupAddon, InputGroupText, FormFeedback
} from 'reactstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePicker from 'react-datepicker';

class CalendarInput extends Component {
  constructor(props){
    super(props);

    let today = new Date();
    this.defaultMaxDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    this.defaultMinDate = new Date(2017, 0, 1);

    this.state = {
      selectedDay: props.selectedDay || undefined,
      maxDate: props.maxDate || this.defaultMaxDate,
      minDate: props.minDate || this.defaultMinDate
    };
  }

  handleChange = (selectedDay) => {
    if (selectedDay && selectedDay < this.state.minDate)
      selectedDay = this.state.minDate;
    else if (selectedDay && selectedDay > this.state.maxDate)
      selectedDay = this.state.maxDate;

    this.setState({
      ...this.state,
      selectedDay: selectedDay
    });
    
    this.props.onSelectedDateChange(selectedDay);
  };
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedDay !== prevProps.selectedDay) {
      this.setState({
        ...this.state,
        selectedDay: this.props.selectedDay,
        minDate: this.props.minDate || this.defaultMinDate,
        maxDate: this.props.maxDate || this.defaultMaxDate
      });
    }
  }
  
  render() {
    let { error } = this.props;
    let errorClass = (error ? ' is-invalid' : '');
    let className = 'form-control' + errorClass;

    return (
      <InputGroup className={errorClass}>
        <InputGroupAddon addonType='prepend'>
          <InputGroupText className='bg-white'>
            <FontAwesomeIcon icon='calendar-alt' />
          </InputGroupText>
        </InputGroupAddon>
        <DatePicker
          className={className}
          dateFormat='yyyy-MM-dd'
          placeholderText={this.props.placeholderText}
          minDate={this.state.minDate}
          maxDate={this.state.maxDate}
          selected={this.state.selectedDay}
          onChange={this.handleChange}
          selectsStart={this.props.selectsStart}
          selectsEnd={this.props.selectsEnd}
          endDate={this.props.endDate}
          startDate={this.props.startDate}
          isClearable={this.props.isClearable}
        />
        {error && <FormFeedback>{error}</FormFeedback>}
      </InputGroup>
    );
  }
}

CalendarInput.propTypes = {
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  selectedDay: PropTypes.object,
  selectsEnd: PropTypes.bool,
  selectsStart: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onSelectedDateChange: PropTypes.func,
  placeholderText: PropTypes.string,
  isClearable: PropTypes.bool
};

CalendarInput.defaultProps = {
  placeholderText: 'YYYY-MM-DD',
  isClearable: false
};

export default CalendarInput;