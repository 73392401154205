import createFetchAction from './createFetchAction';

export const LOADING_CLAIM = 'LOADING_CLAIM';
export const LOADING_CLAIM_SUCCESS = 'LOADING_CLAIM_SUCCESS';
export const LOADING_CLAIM_ERROR = 'LOADING_CLAIM_ERROR';

export const LOADING_CLAIMS = 'LOADING_CLAIMS';
export const LOADING_CLAIMS_SUCCESS = 'LOADING_CLAIMS_SUCCESS';
export const LOADING_CLAIMS_ERROR = 'LOADING_CLAIMS_ERROR';

export const VALIDATING_PRODUCT_ELIGIBILITY = 'VALIDATING_PRODUCT_ELIGIBILITY';
export const VALIDATING_PRODUCT_ELIGIBILITY_SUCCESS = 'VALIDATING_PRODUCT_ELIGIBILITY_SUCCESS';
export const VALIDATING_PRODUCT_ELIGIBILITY_ERROR = 'VALIDATING_PRODUCT_ELIGIBILITY_ERROR';

export const UPDATE_PARTS = 'UPDATE_PARTS';
export const UPDATE_PARTS_SUCCESS = 'UPDATE_PARTS_SUCCESS';
export const UPDATE_PARTS_ERROR = 'UPDATE_PARTS_ERROR';

export const LOADING_ACCOUNT_CUSTOMERS = 'LOADING_ACCOUNT_CUSTOMERS';
export const LOADING_ACCOUNT_CUSTOMERS_SUCCESS = 'LOADING_ACCOUNT_CUSTOMERS_SUCCESS';
export const LOADING_ACCOUNT_CUSTOMERS_ERROR = 'LOADING_ACCOUNT_CUSTOMERS_ERROR';

export const REVIEW_CLAIM = 'REVIEW_CLAIM';

export const ADD_COMMENT = 'ADD_COMMENT';

export const GET_CREDIT_MEMO = 'GET_CREDIT_MEMO';

export const GET_FILE = 'GET_FILE';

export const GET_SALES_ORDER_EXCEL = 'GET_SALES_ORDER_EXCEL';

export const VERIFY_CUSTOMER_CLAIM = 'VERIFY_CUSTOMER_CLAIM';
export const VERIFY_CUSTOMER_CLAIM_SUCCESS = 'VERIFY_CUSTOMER_CLAIM_SUCCESS';
export const VERIFY_CUSTOMER_CLAIM_ERROR = 'VERIFY_CUSTOMER_CLAIM_ERROR';

export const SUBMIT_CUSTOMER_CLAIM = 'SUBMIT_CUSTOMER_CLAIM';
export const SUBMIT_CUSTOMER_CLAIM_SUCCESS = 'SUBMIT_CUSTOMER_CLAIM_SUCCESS';
export const SUBMIT_CUSTOMER_CLAIM_ERROR = 'SUBMIT_CUSTOMER_CLAIM_ERROR';

export const CLEAR_VALIDATION = 'CLEAR_VALIDATION';

export const TOGGLE_CLAIM_DELETED = 'TOGGLE_CLAIM_DELETED';

export const initialState = {
  getClaim: {
    claim: null,
    isLoading: false,
    isError: false
  },
  getClaims: {
    claims: [],
    isLoading: false,
    isError: false
  },
  validateClaim: {
    isValid: false,
    validationErrors: [],
    isLoading: false,
    isError: false
  },
  updateParts: {
    hasErrors: false,
    errorMessages: [],
    parts: [],
    partsTotal: 0,
    laborRate: 0,
    taxValue: 0,
    pstTaxValue: 0
  },
  customers: {
    customers: [],
    isLoading: false,
    isError: false
  }
};

export const actionCreators = {
  getClaim: (id, accountNumber) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/${id}?viewingAccountNumber=${accountNumber}`,
      startAction: LOADING_CLAIM
    });
  },
  getClaims: (accountNumber, date) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/account/${accountNumber}?date=${date}`,
      startAction: LOADING_CLAIMS
    });
  },
  getClaimsForCustomer: (customerId, date) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/customer/${customerId}?date=${date}`,
      startAction: LOADING_CLAIMS
    });
  },
  validateClaim: (query) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/ValidateClaim`,
      method: 'POST',
      startAction: VALIDATING_PRODUCT_ELIGIBILITY,
      body: query
    });
  },
  updateParts: (claimId, parts, accountNumber, comment) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/UpdateParts`,
      method: 'POST',
      startAction: UPDATE_PARTS,
      body: {
        claimId,
        parts,
        accountNumber,
        comment
      }
    });
  },
  clearValidation: () => {
    return { type: CLEAR_VALIDATION };
  },
  getCustomersForAccount: (accountNumber, filter) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/GetCustomerCompany/`,
      method: 'POST',
      startAction: LOADING_ACCOUNT_CUSTOMERS,
      body: {
        accountNumber,
        searchTerm: filter
      }
    })
  },
  reviewWarrantyClaim: (data, files) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/ReviewClaim`,
      method: 'PATCH',
      startAction: REVIEW_CLAIM,
      body: data,
      files: files
    });
  },
  verifyCustomerClaim: (data, files) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/VerifyCustomerClaim`,
      method: 'POST',
      startAction: VERIFY_CUSTOMER_CLAIM,
      body: data,
      files: files
    });
  },
  submitCustomerClaim: (data, files) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/Customer`,
      method: 'POST',
      startAction: SUBMIT_CUSTOMER_CLAIM,
      body: data,
      files: files
    });
  },
  addComment: (claimId, commentText, accountNumber) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/AddComment`,
      method: 'PATCH',
      startAction: ADD_COMMENT,
      body: {
        claimId,
        comment: commentText,
        accountNumber
      }
    });
  },
  getCreditMemo: (creditMemoNumber, accountNumber) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/CreditMemo?id=${creditMemoNumber}&viewingAccountNumber=${accountNumber}`,
      startAction: GET_CREDIT_MEMO,
      isBlob: true
    });
  },
  getFile: (fileId, warrantyId) => {
    return createFetchAction({
      url: `/warrantyapi/file/?warrantyClaimId=${warrantyId}&hashedFile=${fileId}`,
      startAction: GET_FILE,
      isBlob: true
    })
  },
  getSalesOrderClaimsExcel: (salesOrderId) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/SalesOrderClaimsExcel/${salesOrderId}`,
      startAction: GET_SALES_ORDER_EXCEL,
      isBlob: true
    });
  },
  toggleDeleted: (claimId) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/ToggleClaimDeleted/${claimId}`,
      method: 'PATCH',
      startAction: TOGGLE_CLAIM_DELETED
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case LOADING_CLAIM:
      return {
        ...state,
        getClaim: {
          claim: null,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_CLAIM_SUCCESS:
      return {
        ...state,
        getClaim: {
          claim: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_CLAIM_ERROR:
      return {
        ...state,
        getClaim: {
          isLoading: false,
          isError: true
        }
      };
    case LOADING_CLAIMS:
      return {
        ...state,
        getClaims: {
          claims: [],
          isLoading: true,
          isError: false
        }
      };
    case LOADING_CLAIMS_SUCCESS:
      return {
        ...state,
        getClaims: {
          claims: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_CLAIMS_ERROR:
      return {
        ...state,
        getClaims: {
          isLoading: false,
          isError: true
        }
      };
    case VALIDATING_PRODUCT_ELIGIBILITY:
      return {
        ...state,
        validateClaim: {
          isLoading: true
        }
      };
    case VALIDATING_PRODUCT_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        validateClaim: {
          isValid: action.payload.isValid,
          validationErrors: action.payload.validationErrors,
          isLoading: false
        }
      };
    case VALIDATING_PRODUCT_ELIGIBILITY_ERROR:
      return {
        ...state,
        validateClaim: {
          isError: true
        }
      };
    case UPDATE_PARTS:
      return {
        ...state,
        updateParts: {
          isLoading: true
        }
      };
    case UPDATE_PARTS_SUCCESS:
      return {
        ...state,
        updateParts: {
          isLoading: false,
          isError: false,
          hasErrors: action.payload.hasErrors,
          errorMessages: action.payload.errorMessages,
          parts: action.payload.parts,
          partsTotal: action.payload.partsTotal,
          laborRate: action.payload.laborRate,
          taxValue: action.payload.taxValue,
          pstTaxValue: action.payload.pstTaxValue
        }
      };
    case UPDATE_PARTS_ERROR:
      return {
        ...state,
        updateParts: {
          isLoading: false,
          isError: true
        }
      };
    case CLEAR_VALIDATION:
      return {
        ...initialState
      };
    case LOADING_ACCOUNT_CUSTOMERS:
      return {
        ...state,
        customers: {
          customers: [],
          isLoading: true,
          isError: false
        }
      };
    case LOADING_ACCOUNT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: {
          customers: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_ACCOUNT_CUSTOMERS_ERROR:
      return {
        ...state,
        customers: {
          isLoading: false,
          isError: true
        }
      };
    default:
      return state;
  }
};