import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Row, useToggle} from '@met/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {Address} from '../../../components/Address';
import {AddressFormModal, DeleteAddressModal} from "../../../components/AddressForm";

const AddressButton = styled.span`
  cursor: pointer;
  color: #db011c !important;
  font-weight: 600 !important;
  font-size: 1.045em;
`;

const ShippingAddressRadioOption = ({shipToName, address, states, countries, onSaveClick, onDeleteClick}) => {
  const [addressModalHidden, toggleAddressModal] = useToggle(true);
  const [deleteModalHidden, toggleDeleteModal] = useToggle(true);

  return (
    <Row middle='xs'>
      <Col xs={12} sm={6}>
        <div className='font-weight-bold'>{shipToName}</div>
        <Address
          addressDataTestId='checkout-shipping-address'
          line1={address.addressLine1}
          line2={address.addressLine2}
          city={address.city}
          state={address.state}
          postalCode={address.postalCode}
          countryCode={address.countryCode}
        />
      </Col>
      <Col className='flex-row'>
        <AddressButton onClick={toggleAddressModal}>
          <FontAwesomeIcon icon='pencil-alt' className='has-font-size-4' /> Edit
        </AddressButton>
        <AddressButton className='ml-4' onClick={toggleDeleteModal}>
          <FontAwesomeIcon icon='times' className='has-font-size-4' /> Delete
        </AddressButton>
      </Col>
      <DeleteAddressModal
        address={address}
        hidden={deleteModalHidden}
        toggle={toggleDeleteModal}
        onDelete={onDeleteClick}
      />
      <AddressFormModal
        address={address}
        states={states}
        countries={countries}
        hidden={addressModalHidden}
        toggle={toggleAddressModal}
        onSaveClick={onSaveClick}
      />
    </Row>
  );
};

ShippingAddressRadioOption.propTypes = {
  address: PropTypes.object,
  countries: PropTypes.arrayOf(Object),
  onSaveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  shipToName: PropTypes.string,
  states: PropTypes.arrayOf(Object)
};

export default ShippingAddressRadioOption;