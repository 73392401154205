import React, { Fragment, useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { injectIntl } from 'react-intl';
import { Col, Container, Row, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AnnouncementBanner = ({ announcement }) => {
  const [mainContent, setMainContent] = useState(null);
  const [extraContent, setExtraContent] = useState(null);
  const [readMore, setReadMore] = useState(false);
  const [linkName, setLinkName] = useState(null);
  const [color, setColor] = useState();
  const [icon, setIcon] = useState();

  useEffect(() => {
    let summary = announcement?.summary?.trim().replace('\n', '<br/>');
    let details = announcement?.details?.trim();

    if (summary?.length > 0 && details?.replace('<p>', '').replace('</p>', '').replace('<br>', '')?.length > 0) {
      setMainContent(summary);
      setExtraContent((<Fragment><br /><br /><span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details) }} /></Fragment>));
    } else {
      setMainContent(summary);
    }
    let priority = announcement?.priority?.toLowerCase().trim();
    setColor(priority === 'high' || priority == 'critical' ? 'danger' : priority === 'medium' ? 'warning' : 'info');
    setIcon(priority === 'high' || priority == 'critical' ? 'exclamation-triangle' : priority === 'medium' ? 'exclamation' : 'exclamation-circle');
  }, []);

  useEffect(() => {
    setLinkName(extraContent ? readMore ? 'Hide Details <<' : 'Show Details >>' : null);
  }, [extraContent, readMore]);

  return (
    <Container>
      <Alert color={color} className='mt-4 mb-0 font-weight-bold d-print-none'>
        <Container>
          <Row className='my-auto'>
            <Col xs='auto' className='my-auto'>
              <FontAwesomeIcon className='mr-2' color='black' icon={icon} />
            </Col>
            <Col className='py-2'>
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mainContent)}} />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
              {linkName && (<a style={{ marginLeft: "5px", color: "blueviolet", textDecoration: "underline", cursor: "pointer" }} onClick={() => { setReadMore(!readMore) }}>{linkName}</a>)}
              {readMore && extraContent}
            </Col>
          </Row>
        </Container>
      </Alert>
    </Container>
  );
}

export default injectIntl(AnnouncementBanner);