import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavItem} from 'reactstrap';
import auth from '../../auth';
import {Icon, Link} from '../../components';
import {getProfile, getSession} from '../../helpers/localStorage';
import user from '../../helpers/user';
import DesktopNavMenu from './DesktopNavMenu';
import MobileNavMenu from './MobileNavMenu';
import QuickAddModal from './QuickAddModal';

export default class NavMenu extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isOpen: false,
      isProfileDropdownOpen: false,
      isSupportDropdownOpen: false,
      isQuickAddModalOpen: false
    };
  }

  eService = () => {
    window.open("https://service.milwaukeetool.com/")
  };
 
  componentDidMount() {
    document.addEventListener('keydown', this.handleQuickAddShortcut, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleQuickAddShortcut, false);
  }
  
  handleQuickAddShortcut = (e) => {
    if (user.canPlaceOrders() && (e.ctrlKey && e.key === 'q') && window.location.pathname !== '/checkout') {
      this.toggleQuickAddModal();
    }
  };

  login = () => {
    auth.login();
  };

  logout = () => {
    auth.logout();
  };

  toggle = () => {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen
    });
  };

  toggleSupportDropdown = () => {
    this.setState({
      ...this.state,
      isSupportDropdownOpen: !this.state.isSupportDropdownOpen
    });
  };
  
  toggleQuickAddModal = (callback) => {
    if (user.canPlaceOrders() && window.location.pathname !== '/checkout') {
      this.setState({
        ...this.state,
        isQuickAddModalOpen: !this.state.isQuickAddModalOpen
      }, () => {
        if (this.state.isQuickAddModalOpen && !this.props.cart.pendingOrder)
          this.props.getCart();
        
        if (typeof callback === 'function')
          callback();
      });
    }
  };

  toggleProfileDropdown = () => {
    this.setState({
      ...this.state,
      isProfileDropdownOpen: !this.state.isProfileDropdownOpen
    });
  };

  closeAllMenus = () => {
    this.setState({
      ...this.state,
      isOpen: false,
      isProfileDropdownOpen: false,
      isSupportDropdownOpen: false
    });
  };

  render() {
    let hduUrl = getSession().heavyDutyUniversity ? getSession().heavyDutyUniversity.url : 'https://go.bluevolt.com/milwaukee/s/';
    let accountsUrl = getSession().accountsUrl ? getSession().accountsUrl.url : 'https://accounts.milwaukeetool.com';
    let quickLinkUrl = getSession().quickLinkUrl ? getSession().quickLinkUrl.url : 'https://quicklink.milwaukeetool.com';
    let accountNumber = getProfile().accountNumber;
    let showWarrantyMenuItem = user.canViewWarrantyClaims()
        || user.canCreateWarrantyClaims()
        || user.canApproveWarrantyClaims()
        || user.isSuperAdmin();
    let showOneKeySupportMenuItem = this.props.accountHasOneKey || user.canApproveWarrantyClaims() || user.isAdmin();
    let showAdminMenu = user.isAdmin() || user.isAnnouncementsAdmin() || user.isProductAdmin() || user.canApproveWarrantyClaims();
    let showDownloadStatementMenuItem = this.props.canAccessCustomerStatement || user.isSuperAdmin();
    let showWishlists = user.canPlaceOrders();

    let navClasses = classNames(
      'navbar-collapse',
      this.state.isOpen && 'show'
    );
    let hamburgerClasses = classNames(
      'hamburger hamburger--spring nav-link has-pointer-icon',
      this.state.isOpen && 'is-active'
    );
    let ordersMenuItem = user.canPlaceOrders() && (
      <NavItem>
        <NavLink to='/orders' locale={this.props.locale} onClick={this.closeAllMenus} data-test-id='nav-orders' className='nav-link'>Orders</NavLink>
      </NavItem>
    );
    let invoicesMenuItem = user.canPlaceOrders() && this.props.canViewInvoices &&(
      <NavItem>
        <NavLink to='/invoices' locale={this.props.locale} onClick={this.closeAllMenus} data-test-id='nav-invoices' className='nav-link'>Invoices</NavLink>
      </NavItem>
    );
    let customLogoMenuItem = auth.isAuthenticated() && user.isConnectUser() && this.props.featureToggles.CustomLogo === true && (
      <NavItem>
        <NavLink to='/customize' locale={this.props.locale} onClick={this.closeAllMenus} data-test-id='nav-custom' className='nav-link'>Custom PPE</NavLink>
      </NavItem>
    );
    let warrantyMenuItem = showWarrantyMenuItem && (
      <DropdownItem tag={Link} to='warranty' locale={this.props.locale}>Warranty Claims</DropdownItem>
    );
    let pricingAndPublications = user.isConnectUser() && (
      <DropdownItem tag={Link} to='publications' locale={this.props.locale} onClick={this.closeAllMenus}>Pricing/Publications</DropdownItem>
    );
    let heavyDutyUniversityMenuItem = this.props.accountHasAsc && (
      <DropdownItem tag='a' href={hduUrl} target='_blank'>
        <div className='d-flex flex-row align-items-center'>
          Heavy Duty University
          <Icon type='external-link-alt' color='dark-gray' size='sm' className='ml-2'/>
        </div>
      </DropdownItem>
    );
    let oneKeySupportMenuItem = showOneKeySupportMenuItem && (
      <DropdownItem tag='a' href={quickLinkUrl} target='_blank'>Milwaukee® QuickLink</DropdownItem>
    );
    let eServiceMenuItem = <DropdownItem onClick={this.eService} >eService</DropdownItem>;
    let supportMenuItems = auth.isAuthenticated() ? (
      <Fragment>
        {warrantyMenuItem}
        {pricingAndPublications}
        {heavyDutyUniversityMenuItem}
        <DropdownItem tag={Link} to='faq' locale={this.props.locale} onClick={this.closeAllMenus}>FAQs</DropdownItem>
        {oneKeySupportMenuItem}
        {eServiceMenuItem}
      </Fragment>  
    ) : heavyDutyUniversityMenuItem;
    let contactUsMenuItem = auth.isAuthenticated() && user.isConnectUser() && (
      <NavItem>
        <NavLink to='/contact' locale={this.props.locale} onClick={this.closeAllMenus} data-test-id='nav-contact' className='nav-link'>Contact Us</NavLink>
      </NavItem>
    );
    let quickAddMenuItem = auth.isAuthenticated() && user.canPlaceOrders() && (
      <NavItem>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={this.toggleQuickAddModal} data-test-id='nav-quick-add' className='nav-link text-center d-flex flex-column has-pointer-icon'> 
          <Icon type='bolt-add' className='nav-link-icon' width='26' height='26'/>
          <div className='has-font-size-0 nav-link-label'>Quick Add</div>
        </div>
      </NavItem>
    );
    let cartMenuItem = auth.isAuthenticated() && user.canPlaceOrders() && (
      <NavItem>
        <NavLink to='/cart' data-test-id='nav-cart' className='nav-link text-center d-flex flex-column'>
          <Icon type='shopping-cart' badgeValue={this.props.cartQuantity} className='nav-link-icon'/>
          <div className='has-font-size-0 nav-link-label'>Cart</div>
        </NavLink>
      </NavItem>
    );
    
    return (
      <Fragment>
        <Navbar id='navbar' fixed='top' className='navbar-fixed-top navbar-dark' expand='md' style={{ zIndex: '1005' }}>
          <Link to='' locale={this.props.locale} linkDataTestId='nav-logo' className='navbar-brand'>
            <Icon type='mt-logo' width='124' height='67'/>
          </Link>
          {/* Main nav items (shared between Desktop/Mobile) */}
          <div className={navClasses}>
            <Nav navbar>
              {ordersMenuItem}
              {invoicesMenuItem}
              {customLogoMenuItem}
              {user.isConnectUser() &&
                <Dropdown data-test-id='nav-support' nav isOpen={this.state.isSupportDropdownOpen} toggle={this.toggleSupportDropdown}>
                  <DropdownToggle nav caret>Support</DropdownToggle>
                <DropdownMenu>
                  {supportMenuItems}
                </DropdownMenu>
              </Dropdown>
              }
              {contactUsMenuItem}
              <div className='d-md-none'>
                <hr/>
                <MobileNavMenu
                  isAuthenticated={auth.isAuthenticated()}
                  nickname={this.props.profile.nickname}
                  accountNumber={accountNumber}
                  accountsUrl={accountsUrl}
                  login={this.login}
                  showAdminMenu={showAdminMenu}
                  showDownloadCustomerStatementMenuItem={showDownloadStatementMenuItem}
                  isConnectUser={user.isConnectUser()}
                  getCustomerStatement={this.props.getCustomerStatement}
                  showWishlistsMenuItem={showWishlists}
                  logout={this.logout}
                />
              </div>
            </Nav>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className='navbar-overlay' onClick={this.toggle}>&nbsp;</div>
          {/* End of main nav items */}
          {/* Right nav items */}
          <Nav navbar className='ml-auto flex-row nav-right'>
            {quickAddMenuItem}
            {cartMenuItem}
            <div data-test-id='nav-profile' className='d-none d-md-flex align-items-center ml-md-3'>
              <DesktopNavMenu
                isAuthenticated={auth.isAuthenticated()}
                isProfileDropdownOpen={this.state.isProfileDropdownOpen}
                toggleProfileDropdown={this.toggleProfileDropdown}
                accountsUrl={accountsUrl}
                accountNumber={accountNumber}
                showDownloadStatementMenuItem={showDownloadStatementMenuItem}
                isConnectUser={user.isConnectUser()}
                getCustomerStatement={this.props.getCustomerStatement}
                showAdminMenu={showAdminMenu}
                showWishlistsMenuItem={showWishlists}
                logout={this.logout}
                login={this.login}
              />
            </div>
            <div className='d-md-none d-flex align-items-center ml-1'>
              <NavItem>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={this.toggle} className={hamburgerClasses}>
                  <span className='hamburger-box ml-2'>
                    <span className='hamburger-inner'>&nbsp;</span>
                  </span>
                </div>
              </NavItem>
            </div>
          </Nav>
          {/* End of right nav items */}
        </Navbar>
        <QuickAddModal
          isOpen={this.state.isQuickAddModalOpen}
          toggle={this.toggleQuickAddModal}
        />
      </Fragment>
    );
  }
}

NavMenu.propTypes = {
  profile: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  locale: PropTypes.string,
  canAccessCustomerStatement: PropTypes.bool,
  accountHasAsc: PropTypes.bool,
  accountHasOnekey: PropTypes.bool,
  cart: PropTypes.object,
  getCustomerStatement: PropTypes.func,
  getCart: PropTypes.func
};