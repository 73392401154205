import createFetchAction from './createFetchAction';

export const LOADING_AVAILABILITY = 'LOADING_AVAILABILITY';
export const LOADING_AVAILABILITY_SUCCESS = 'LOADING_AVAILABILITY_SUCCESS';
export const LOADING_AVAILABILITY_ERROR = 'LOADING_AVAILABILITY_ERROR';

export const initialState = {
  availabilityMap: {},
  isLoading: false,
  isError: false
};

export const actionCreators = {
  getAvailability: (skus, accountNumber) => {
    if (!skus || skus.length === 0) return {type: 'NOOP'};
    let itemData = [];
    skus.forEach(sku => itemData.push({ sku: sku }));

    return createFetchAction({
      method: 'POST',
      url: `/api/v1/availability`,
      body: { accountNumber: accountNumber, itemData: itemData },
      startAction: LOADING_AVAILABILITY,
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === LOADING_AVAILABILITY) {
    return {
      ...state,
      isLoading: true,
      isError: false
    };
  }

  if (action.type === LOADING_AVAILABILITY_SUCCESS) {
    let availabilityMap = action.payload.data.reduce(function(map, obj) {
      map[obj.sku] = obj;
      return map;
    }, {});

    let updatedMap = {...state.availabilityMap, ...availabilityMap};

    return {
      ...state,
      availabilityMap: updatedMap,
      isLoading: false,
      isError: false
    };
  }

  if (action.type === LOADING_AVAILABILITY_ERROR) {
    return {
      ...state,
      isLoading: false,
      isError: true
    };
  }

  return state;
};