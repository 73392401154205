import createFetchAction from '../createFetchAction';
import {CREATE_USER, GET_USER, GET_USERS, UPDATE_USER} from './types';

export default {
  createUser: (user) => {
    return createFetchAction({
      url: 'api/v1/userMaintenance',
      method: 'POST',
      body: user,
      startAction: CREATE_USER
    })
  },
  getUser: (auth0Id) => {
    return createFetchAction({
      url: `api/v1/userMaintenance/${auth0Id}`,
      startAction: GET_USER
    })
  },
  getUsers: (accountNumber) => {
    return createFetchAction({
      url: `api/v1/userMaintenance/byAccount?accountNumber=${accountNumber}`,
      startAction: GET_USERS
    })
  },
  updateUser: (userId, user) => {
    return createFetchAction({
      url: `api/v1/userMaintenance/${userId}`,
      method: 'PATCH',
      body: user,
      startAction: UPDATE_USER
    })
  }
}