import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Enumerable from 'linq';
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {FormattedDate, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {actions as toastrActions, toastr} from 'react-redux-toastr'
import {Breadcrumb, BreadcrumbItem, Card, CardHeader, Col, Container, ListGroup, ListGroupItem, Row} from 'reactstrap';

import {Button, Link, OrderLinesTable, OrderSummary, PageLoader} from '../../components';
import {getSession} from '../../helpers/localStorage';
import {cartActions, ordersActions, warrantyClaimActions, configurationActions} from '../../store';
import {downloadBlob} from '../../helpers/downloadblob';
import history from '../../history';

export class OrderDetails extends Component {
  constructor(props) {
    super(props);
    
    props.configurationActions.getConfig();

    this.state = {
      collapse: true
    };
  }

  componentDidMount() {
    const {id} = this.props.match.params;
    this.props.getOrder(id).catch(error => {
      if (error && error.status === 401) {
        toastr.error(this.props.intl.formatMessage({ id: 'general.unauthorized' }), this.props.intl.formatMessage({ id: 'orders.unauthorizedToView' }), { timeOut: 0 });
      } else {
        toastr.error(this.props.intl.formatMessage({ id: 'general.errorToastTitle' }), this.props.intl.formatMessage({ id: 'orders.orderLoadingError' }), { timeOut: 0 });
      }
    });
  }

  getOrderHistoryQuery = () => {
    const {order} = this.props.orderDetails;
    return '?an=' + this.props.accountNumber + '&oi=' + order.id;
  };

  getBackorderedOrderLines = () => {
    const {order} = this.props.orderDetails;

    if (order.orderLines) {
      return Enumerable.from(order.orderLines)
        .where(orderLine => orderLine.isBackordered && !orderLine.isCustomLogo && orderLine.remainingQuantity > 0)
        .toArray();
    }

    return [];
  };

  getBackorderedOrderLineIds = () => {
    let backorderedOrderLines = this.getBackorderedOrderLines();

    if (backorderedOrderLines.length > 0) {
      return Enumerable.from(backorderedOrderLines)
        .select(orderLine => orderLine.id)
        .toArray();
    }

    return [];
  };

  getOrderShipments = () => {
    const {order} = this.props.orderDetails;

    if (order && order.orderShipments) {
      return Enumerable.from(order.orderShipments)
        .toArray();
    }

    return [];
  };

  getShippedOrderLines = (shipment) => {
    const {order} = this.props.orderDetails;

    if (shipment && shipment.orderShipmentLines && order && order.orderLines) {
      let shippedOrderLines = Enumerable.from(shipment.orderShipmentLines)
        .select(orderShipmentLine => {
          let orderLine = Enumerable.from(order.orderLines).firstOrDefault(orderLine => orderLine.id === orderShipmentLine.orderLineId);
          if (orderLine) {
            return { ...orderLine, quantity: orderShipmentLine.quantityShipped }
          }
        })
        .toArray();

      return shippedOrderLines;
    }

    return [];
  };

  getNotShippedOrderLines = () => {
    const {order} = this.props.orderDetails;

    if (order && order.orderLines) {
      let backorderedOrderLineIds = this.getBackorderedOrderLineIds();      
      let notShippedOrderLines = Enumerable.from(order.orderLines)
        .where(orderLine => (
          !Enumerable.from(backorderedOrderLineIds).contains(orderLine.id) && 
          !orderLine.isCustomLogo &&
          orderLine.remainingQuantity > 0
        ))
        .toArray();

      return notShippedOrderLines;
    }

    return [];
  };

  getCustomLogoOrderLines = () => {
    const {order} = this.props.orderDetails;

    if (order.orderLines) {
      return Enumerable.from(order.orderLines)
        .where(orderLine => orderLine.isCustomLogo && !orderLine.isCanceled)
        .toArray();
    }

    return [];
  };  

  getCustomLogoOrderLineIds = () => {
    let customLogoOrderLines = this.getCustomLogoOrderLines();

    if (customLogoOrderLines.length > 0) {
      return Enumerable.from(customLogoOrderLines)
        .select(orderLine => orderLine.id)
        .toArray();
    }

    return [];
  };

  getCanceledOrderLines = () => {
    const {order} = this.props.orderDetails;
    if (order && order.orderLines) {
      let notShippedOrderLines = Enumerable.from(order.orderLines)
        .where(orderLine => orderLine.isCanceled)
        .toArray();

      return notShippedOrderLines;
    }

    return [];
  };

  getPackingSlip = (shipment, order, mkeProductId) => {
    this.props.showToast({
      id: shipment.packingSlip,
      type: 'info',
      title: this.props.intl.formatMessage({id: 'orders.downloadingPackingSlip'}),
      message: '',
      options: {
        className: 'toastr-download',
        removeOnHover: false,
        showCloseButton: false,
        closeOnToastrClick: false,
        timeOut: 0,
        icon: <FontAwesomeIcon icon='spinner' size='2x' spin/>
      }
    });

    this.props.getPackingSlip(shipment.packingSlip, new Date(shipment.shippedDate), order.orderNumber, mkeProductId)
      .then((blob) => {
        downloadBlob('Packing Slip_' + shipment.packingSlip + '.pdf', blob);
        toastr.remove(shipment.packingSlip);
      }).catch(() => {
      toastr.remove(shipment.packingSlip);
      toastr.error(this.props.intl.formatMessage({id: 'general.errorToastTitle'}), this.props.intl.formatMessage({id: 'orders.packingSlipDownloadErrorBody'}), {timeOut: 0});
    });
  };

  handleExcelDownloadClick = () => {
    return this.props.getExcelOrderHistory(this.getOrderHistoryQuery()).then((blob) => {
      downloadBlob('OrderDetails.xlsx', blob);
    }).catch(() => {
      toastr.error(this.props.intl.formatMessage({ id: 'orders.excelDownloadErrorTitle' }), this.props.intl.formatMessage({ id: 'orders.excelDownloadErrorBody' }), { timeOut: 0 });
    });
  };

  getSalesOrderClaimsExcel = (salesOrderId) => {
    this.props.showToast({
      id: salesOrderId,
      type: 'info',
      title: this.props.intl.formatMessage({id: 'orders.downloadingWarrantyClaims'}),
      message: '',
      options: {
        className: 'toastr-download',
        removeOnHover: false,
        showCloseButton: false,
        closeOnToastrClick: false,
        timeOut: 0,
        icon: <FontAwesomeIcon icon='spinner' size='2x' spin/>
      }
    });

    this.props.getSalesOrderClaimsExcel(salesOrderId)
      .then((blob) => {
        downloadBlob(salesOrderId + ' Warranty Claims.xlsx', blob);
        toastr.remove(salesOrderId);
      }).catch(() => {
      toastr.remove(salesOrderId);
      toastr.error(this.props.intl.formatMessage({id: 'general.errorToastTitle'}), this.props.intl.formatMessage({id: 'orders.warrantyClaimsExcelDownloadError'}), {timeOut: 0});
    });
  };

  handleReorderClick = (orderId) => {
    return this.props.reorder(orderId)
      .then(() => this.props.getCartStatistics(this.props.accountNumber));
  };

  handleInvoiceClick = (orderNumber) => {
    history.push(`/invoices?q=${orderNumber}&&aa=true`);
  };

  render() {
    const {configuration} = this.props;
    const {order, isLoading} = this.props.orderDetails;
    const flatRateAccountNumbers = !!configuration && !configuration.getConfig.isLoading && configuration.flatRateAccountNumbers;
    const {locale} = this.props.match.params;
    let IsInvoiced;
    if (order) {
      IsInvoiced = Enumerable.from(order.orderLines).all(x => x.isInvoiced);
    }

    if (isLoading || !order) {
      return (
        <PageLoader/>
      );
    }

    let backorderedOrderLines = this.getBackorderedOrderLines();
    let backorderedMarkup = backorderedOrderLines.length > 0 && (
      <Card data-test-id='order-details-backordered' id='backordered-items'>
        <CardHeader className="text-warning"><h5>Backordered</h5></CardHeader>
        <ListGroup className='order-details-list'>
          <ListGroupItem className='order-details-list-item'>
            <Container fluid>
              <Row>
                <Col>
                  <OrderLinesTable locale={locale} orderLines={backorderedOrderLines} linesLimit={5}
                                   useRemainingQuantity paginate />
                </Col>
              </Row>
            </Container>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );

    let customLogoOrderLines = this.getCustomLogoOrderLines();
    let customLogoMarkup = customLogoOrderLines.length > 0 && (
      <Card id='custom-logo-items'>
        <CardHeader><h5>Custom Logo Items</h5></CardHeader>
        <ListGroup className='order-details-list'>
          <ListGroupItem className='order-details-list-item'>
            <Container fluid>
              <Row>
                <Col>
                  <OrderLinesTable locale={locale} orderLines={customLogoOrderLines} linesLimit={5} paginate isCustomLogo={true}/>
                </Col>
              </Row>
            </Container>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );

    let orderShipments = this.getOrderShipments();
    let shippedListMarkup = orderShipments && orderShipments.length > 0 && Enumerable.from(orderShipments).select(orderShipment => 
    {
      let shippedOrderLines = this.getShippedOrderLines(orderShipment);
      let trackingMarkup;
      let packingSlipMarkup;

      if (shippedOrderLines[0].isCustomLogo) {
          trackingMarkup = (<a href={shippedOrderLines[0].customLogoOrderUrl} target='blank' className='btn btn-primary btn-block'>Track Package</a>);
          packingSlipMarkup = (
            <Button block color='secondary' onClick={() => this.getPackingSlip(orderShipment, order, shippedOrderLines[0].mkeProductLevel3)}
                isLoading={this.props.isPackingSlipLoading}>Packing Slip</Button>
          );
      }
      else {
          trackingMarkup = (<Link to={`track?o=${orderShipment.id}`} locale={locale} className='btn btn-primary btn-block'>Track Package</Link>);
          packingSlipMarkup = (
            <Button block color='secondary' onClick={() => this.getPackingSlip(orderShipment, order, null)}
                isLoading={this.props.isPackingSlipLoading}>Packing Slip</Button>
          );
      }

      return (
        <ListGroupItem key={`shipment-${orderShipment.id}`} className='order-details-list-item'>
          <Container>
            <Row className='justify-content-center'>
              <Col lg='9'>
                <Container>
                  <Row className='mb-3'>
                    <Col>
                      <Row className='has-font-size-4 font-weight-bold'>
                        <Col xs='2'>{orderShipment.status || this.props.intl.formatMessage({id: 'track.shipped'})}</Col>
                        <Col><FormattedDate value={orderShipment.shippedDate} /></Col>
                      </Row>
                      <div className='text-secondary'>{orderShipment.statusDetails}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <OrderLinesTable locale={locale} orderLines={shippedOrderLines} linesLimit={5} paginate/>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col lg='3' xs='8'>
                <Container>
                  <Row className='mt-2'>
                    <Col xs='12' className='mb-3'>
                        {trackingMarkup}
                        <small className='text-center'>Please allow up to 48 hours for transit activity</small>
                    </Col>
                    <Col xs='12' className='mb-3'>
                     {packingSlipMarkup}
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </ListGroupItem>
      );
    }).toArray();
    let shippedMarkup = orderShipments.length > 0 && (
      <Card id='shipped-items'>
        <CardHeader><h5>Shipments</h5></CardHeader>
        <ListGroup className='order-details-list'>
          {shippedListMarkup}
        </ListGroup>
      </Card>
    );

    let notShippedOrderLines = this.getNotShippedOrderLines();
    let notShippedMarkup = notShippedOrderLines.length > 0 && (
      <Card data-test-id='order-details-shipment-pending' id='not-shipped-items'>
        <CardHeader><h5>Shipment Pending</h5></CardHeader>
        <ListGroup className='order-details-list'>
          <ListGroupItem className='order-details-list-item'>
            <Container fluid>
              <Row>
                <Col>
                  <OrderLinesTable locale={locale} orderLines={notShippedOrderLines} linesLimit={5} useRemainingQuantity
                                   paginate/>
                </Col>
              </Row>
            </Container>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );

    let canceledLines = this.getCanceledOrderLines();
    let canceledMarkup = (canceledLines && canceledLines.length > 0) && (
      <Card id='not-shipped-items'>
        <CardHeader><h5>Canceled</h5></CardHeader>
        <ListGroup className='order-details-list'>
          <ListGroupItem className='order-details-list-item'>
            <Container fluid>
              <Row>
                <Col>
                  <OrderLinesTable locale={locale} orderLines={canceledLines} linesLimit={5} paginate/>
                </Col>
              </Row>
            </Container>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );

    let warrantyClaimsButtonStyle = {
      'margin-right': '5px'
    };

    return (
      <Container id='order-details' className='animate-bottom-fade-in content-wrapper'>
        <Breadcrumb>
          <BreadcrumbItem><Link to={`orders${getSession().orderHistoryQuery || ''}`}
                                locale={this.props.match.params.locale}>Your Orders</Link></BreadcrumbItem>
          <BreadcrumbItem active>Order Details</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col>
            <OrderSummary order={order} onReorderClick={this.handleReorderClick}/>
          </Col>
        </Row>
        <Row className='align-items-end'>
          <Col>
            <h5 className='my-3'>Items In This Order</h5>
          </Col>
          <Col className='text-right d-print-none'>
            {IsInvoiced && 
              <Button onClick={() => this.handleInvoiceClick(order.orderNumber)} className='mr-2'>
                Invoice
              </Button>
            }
            {(flatRateAccountNumbers || []).indexOf(order.invoiceAccount) > -1 &&
              <Button onClick={() => this.getSalesOrderClaimsExcel(order.orderNumber)} style={warrantyClaimsButtonStyle}>
                <FontAwesomeIcon icon='file-excel' className='mr-2'/>
                Download Warranty Claims
              </Button>
            }
            <Button data-test-id='download-details' onClick={this.handleExcelDownloadClick}>
              {/*<Button isLoading={this.props.excelOrderHistory.isLoading} onClick={this.handleExcelDownloadClick}>*/}
              <FontAwesomeIcon icon='file-excel' className='mr-2'/>
              Download Details
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {canceledMarkup}
            {backorderedMarkup}
            {customLogoMarkup}
            {shippedMarkup}
            {notShippedMarkup}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderDetails: state.orders.orderDetails,
    isPackingSlipLoading: state.orders.packingSlip.isLoading,
    accountNumber: state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber,
    configuration: state.configuration
  };
};

const mapDispatchToProps = dispatch => ({
  getOrder: orderId => dispatch(ordersActions.getOrder(orderId)),
  getPackingSlip: (packingslip, date, ordernumber, mkeProductId) => dispatch(ordersActions.getPackingSlip(packingslip, date, ordernumber, mkeProductId)),
  getExcelOrderHistory: query => dispatch(ordersActions.getExcelOrderHistory(query)),
  getSalesOrderClaimsExcel: salesOrderId => dispatch(warrantyClaimActions.getSalesOrderClaimsExcel(salesOrderId)),
  reorder: (orderId, accountNumber) => dispatch(cartActions.reorder(orderId, accountNumber)),
  getCartStatistics: (accountNumber) => dispatch(cartActions.getCartStatistics(accountNumber)),
  showToast: (toastConfig) => dispatch(toastrActions.add(toastConfig)),
  configurationActions: bindActionCreators(configurationActions, dispatch)
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderDetails));
