import { Card, CardBody, CardHeader, Col, Row } from '@met/react-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Input } from '../../../components';

const EmailConfirmation = (props) => {
  const [emailConfirmation, setEmailConfirmation] = useState({ value: '', isValid: false });
  // eslint-disable-next-line no-useless-escape
  const emailConfirmationValidationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Allow only email

  const handleEmailConfirmationChange = (obj) => {
    setEmailConfirmation({
      value: obj.value,
      isValid: obj.isValid
    });

    if (props.onEmailConfirmationChange)
      props.onEmailConfirmationChange(obj);
  };

  const validateEmailConfirmation = (value) => {
    return value ? new RegExp(emailConfirmationValidationRegex).test(value) : false;
  };

  return (
    <Card data-test-id='checkout-contact-email'>
      <CardHeader>
        <FormattedMessage id='general.emailConfirmation' />
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={10}>
            <Input
              type='email'
              value={emailConfirmation.value}
              isValid={emailConfirmation.isValid}
              onChange={handleEmailConfirmationChange}
              validationMethod={validateEmailConfirmation}
              validationMessage='Enter valid email'
              trySubmit={props.trySubmit}
              placeholder='Artwork Approver Email'
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

EmailConfirmation.propTypes = {
  onEmailConfirmationChange: PropTypes.func,
  trySubmit: PropTypes.bool
};

export default EmailConfirmation;