import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {toastr} from "react-redux-toastr";
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Row, UncontrolledTooltip} from 'reactstrap';

import {Address, Button, Price} from '../../components';
import shipping from '../../helpers/shippingHelper';

class OrderSummary extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      isReorderInProcess: false
    };
  }
  
  handleReorderClick = () => {
    const {intl, order} = this.props;

    this.setState({
      ...this.state,
      isReorderInProcess: true
    }, () => {
      this.props.onReorderClick(this.props.order.id)
        .then(() => {
          this.setState({
            ...this.state,
            isReorderInProcess: false
          });
        })
        .then(() => toastr.success(intl.formatMessage({id: 'orders.reorderToastTitle'}, {orderNumber: order.orderNumber}), null, {timeOut: 0}))
        .catch(() => toastr.error(intl.formatMessage({id: 'orders.reorderErrorToastTitle'}, {orderNumber: order.orderNumber}), null, {timeOut: 0}))
    });
  };
  
  
  render() {
    const { order } = this.props;
    const shippingCostMarkup = shipping.getShippingCostMarkup(order);
    const shippingTooltipText = shipping.getShippingCostTooltip(order);
    
    let orderNumber = order.orderNumber || <FormattedMessage id='general.pending'/>;
    let hasTaxAndFreight = (order.tax && (order.freight || (order.freight === 0 && order.purchaseOrderNumber === 'EMPLOYEE ORDER')));
    let tax = order.taxExempt ? <FormattedMessage id='general.exempt' /> : hasTaxAndFreight ? <Price value={order.tax} canBeZero={true}/> : <FormattedMessage id='general.pending' />;
    let taxTooltip = order.taxExempt ? <FormattedMessage id='orderSummary.taxExempt' /> : <FormattedMessage id='orderSummary.taxToBeCalculated'/>;
    let backorderedSection = order.backorderedQuantity > 0 && (
      <div className='d-flex justify-content-between'>
        <span data-test-id='order-details-backorder-tool-tip' className='has-font-size-4 mr-3'><FormattedMessage id='orderSummary.itemsOnBackorder'/> <FontAwesomeIcon icon='question-circle' size='sm' id='backorderTooltip'/></span>
        <span data-test-id='order-details-backordered-quantity' className='has-font-size-5 font-weight-bold'>{order.backorderedQuantity}</span>
        <UncontrolledTooltip placement='bottom' target='backorderTooltip'><FormattedMessage id='orderSummary.backorderTooltip'/></UncontrolledTooltip>
      </div>
    );
    let shippedSection = order.shippedQuantity > 0 && (
      <div className='d-flex justify-content-between'>
        <span className='has-font-size-4 mr-3'><FormattedMessage id='orderSummary.itemsShipped'/></span>
        <span data-test-id='order-details-items-shipped' className='has-font-size-5 font-weight-bold'>{3}</span>
      </div>
    );
    let inStockSection = order.inStockQuantity > 0 && (
      <div className='d-flex justify-content-between'>
        <span className='has-font-size-4 mr-3'><FormattedMessage id='orderSummary.itemsToBeShipped'/></span>
        <span data-test-id='order-details-items-to-be-shipped' className='has-font-size-5 font-weight-bold'>{order.inStockQuantity}</span>
      </div>
    );
    let address = (
      <Address
        line1={order.addressLine1}
        line2={order.addressLine2}
        city={order.city}
        state={order.state}
        postalCode={order.postalCode}
        countryCode={order.countryCode}
      />
    );
    
    return (
      <Container fluid>
        <Row>
          <Col lg='8'>
            <Card>
              <CardHeader>
                <Container>
                  <Row className='my-2'>
                    <Col sm='4'>
                      <div className='mb-1'><FormattedMessage id='general.orderDate'/></div>
                      <div data-test-id='order-date' className='font-weight-bold'><FormattedDate value={order.orderDate}/></div>
                    </Col>
                    <Col sm='4' className='mt-3 mt-sm-0'>
                      <div className='mb-1'><FormattedMessage id='general.orderNumber'/></div>
                      <div data-test-id='order-number' className='font-weight-bold'>{orderNumber}</div>
                    </Col>
                    <Col sm='4' className='mt-3 mt-sm-0'>
                      <div className='mb-1'><FormattedMessage id='general.purchaseOrderNumber'/></div>
                      <div data-test-id='purchase-order-number' className='font-weight-bold'>{order.purchaseOrderNumber}</div>
                    </Col>
                  </Row>
                </Container>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md='8'>
                      <div className='mb-1'><FormattedMessage id='general.shipTo'/></div>
                      <div data-test-id='order-details-ship-to' className='font-weight-bold mb-3'>
                        {order.addressLine1?.indexOf('Attn:') === -1 && <div>{order.shipToName}</div>}
                        {address}
                      </div>
                      <div className='mb-1'><FormattedMessage id='general.shippingSpeed'/></div>
                      <div data-test-id='order-details-shipping-speed' className='font-weight-bold'>{order.shippingSpeed}</div>
                    </Col>
                    <Col md='4' className='mt-3 mt-md-0 d-flex flex-column'>
                      <div className='mb-1'>Invoice Account</div>
                      <div data-test-id='order-details-invoice-account' className='font-weight-bold mb-3'>{order.invoiceAccount}</div>
                      <div className='mb-1'>Account Number</div>
                      <div data-test-id='order-details-account-number' className='font-weight-bold'>{order.accountNumber}</div>
                      {!this.props.hideReorder &&
                      <Button data-test-id='reorder' block color='secondary' className='mt-3 mt-md-auto' onClick={this.handleReorderClick}
                              isLoading={this.state.isReorderInProcess}>
                        <FormattedMessage id='orders.reorder'/>
                      </Button>
                      }
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Col>
          <Col lg='4' className='d-flex'>
            <Card className='w-100'>
              <CardBody>
                {shippedSection}
                {inStockSection}
                {backorderedSection}
                <hr/>
                <div className='d-flex justify-content-between'>
                  <span className='has-font-size-4 mr-3'><FormattedMessage id='general.subtotal'/>:</span>
                  <span data-test-id='order-details-subtotal' className='has-font-size-5 font-weight-bold'><Price value={order.subtotal} canBeZero={true}/></span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='has-font-size-4 mr-3'>
                    <FormattedMessage id='general.shipping'/>: <FontAwesomeIcon icon='question-circle' size='sm' id='shippingTooltip'/>
                  </span>
                  <span data-test-id='order-details-shipping' className='has-font-size-5'>
                    {shippingCostMarkup}
                  </span>
                  <UncontrolledTooltip placement='bottom' target='shippingTooltip'>
                    {shippingTooltipText}
                  </UncontrolledTooltip>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='has-font-size-4 mr-3'><FormattedMessage id='general.salesTax'/>: {!hasTaxAndFreight && <FontAwesomeIcon icon='question-circle' size='sm' id='taxTooltip'/>}</span>
                  <span data-test-id='order-details-tax' className='has-font-size-5'>{tax}</span>
                  {!hasTaxAndFreight && <UncontrolledTooltip placement='bottom' target='taxTooltip'>{taxTooltip}</UncontrolledTooltip>}
                </div>
              </CardBody>
              <CardFooter>
                <div className='d-flex justify-content-between'>
                  <span className='has-font-size-4 mr-3'><FormattedMessage id='general.total'/>:</span>
                  <span data-test-id='order-details-total' className='has-font-size-5 font-weight-bold'><Price value={order.total} canBeZero={true}/></span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

OrderSummary.propTypes = {
  order: PropTypes.object,
  hideReorder: PropTypes.bool
};

export default injectIntl(OrderSummary);