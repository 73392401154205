import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, FormGroup, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';

import { Button, Checkbox, Link, PageLoader, SearchInput, Text } from '../../../components'
import queryString from '../../../helpers/queryString';
import history from '../../../history';
import { productSearchActions } from '../../../store';
import { injectIntl } from "react-intl";

class ProductsAdmin extends Component {
  constructor(props) {
    super(props);

    this.perPage = 12;
    this.pageRange = 8;

    const values = queryString.parse();

    this.state = {
      query: values.q || '',
      queryString: window.location.search,
      currentPage: values.p ? parseInt(values.p) : 1,
      hiddenOnly: values.h || false,
      featuredOnly: values.f || false,
      preSaleOnly: values.pre || false,
      restrictedOnly: values.r || false,
      discontinuedOnly: values.d || false
    };
  }

  componentDidMount() {
    this.props.clearIndexProducts();

    if (this.state.query !== '' || this.state.hiddenOnly || this.state.featuredOnly || this.state.preSaleOnly || this.state.restrictedOnly || this.state.discontinuedOnly) {
      this.props.getIndexProductsAdmin(window.location.search);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;

    if (this.state.queryString !== window.location.search) {
      this.props.getIndexProductsAdmin(window.location.search);

      let values = queryString.parse();

      this.setState({
        ...this.state,
        query: values.q || '',
        queryString: window.location.search,
        currentPage: values.p ? parseInt(values.p) : 1,
        hiddenOnly: values.h || false,
        featuredOnly: values.f || false,
        preSaleOnly: values.pre || false,
        restrictedOnly: values.r || false,
        discontinuedOnly: values.d || false
      });
    }
  }

  handlePageChange = (pageNumber) => {
    this.setState({
      ...this.state,
      currentPage: pageNumber
    }, () => {
      history.push(queryString.update('p', pageNumber));
      window.scrollTo(0, 0);
    });
  };

  handleSearchSkuChange = (e) => {
    this.setState({
      ...this.state,
      query: e.target.value
    });
  };

  handleSearchSkuButtonPress = () => {
    this.search();
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  handleHiddenOnlyToggle = () => {
    this.setState({
      ...this.state,
      currentPage: 1,
      hiddenOnly: !this.state.hiddenOnly
    }, () => {
      this.search();
    });
  };

  handleFeaturedOnlyToggle = () => {
    this.setState({
      ...this.state,
      currentPage: 1,
      featuredOnly: !this.state.featuredOnly
    }, () => {
      this.search();
    });
  };

  handlePreSaleOnlyToggle = () => {
    this.setState({
      ...this.state,
      currentPage: 1,
      preSaleOnly: !this.state.preSaleOnly
    }, () => {
      this.search();
    });
  };

  handleRestrictedOnlyToggle = () => {
    this.setState({
      ...this.state,
      currentPage: 1,
      restrictedOnly: !this.state.restrictedOnly
    }, () => {
      this.search();
    });
  };

  handleDiscontinuedOnlyToggle = () => {
    this.setState({
      ...this.state,
      currentPage: 1,
      discontinuedOnly: !this.state.discontinuedOnly
    }, () => {
      this.search();
    });
  };

  search = () => {
    let locale = this.props.match.params.locale;
    let subPath = locale ? `/${locale}` : '';
    let route = subPath + queryString.update('q', this.state.query, '/admin/products' + window.location.search);
    route = queryString.update('h', this.state.hiddenOnly, route, false);
    route = queryString.update('f', this.state.featuredOnly, route, false);
    route = queryString.update('pre', this.state.preSaleOnly, route, false);
    route = queryString.update('r', this.state.restrictedOnly, route, false);
    route = queryString.update('d', this.state.discontinuedOnly, route, false);
    history.push(route.replace(/\+/g, '%2B'));
  };

  clear = () => {
    this.setState({
      ...this.state,
      query: ''
    }, () => {
      this.props.clearIndexProducts();
    });
  };

  render() {
    const { isLoading, products, totalCount, count } = this.props.productSearch;

    let results = products && products.map((product, index) => {
      return (
        <ListGroupItem key={`product-${index}`} className='search-list-item'>
          <Container fluid className='p-2'>
            <Row>
              <Col md='auto' xl='2' className='text-center mx-lg-2 my-lg-auto align-self-center'>
                <img src={`/api/v1/images?url=${product.imageUrl}`} alt={product.description} />
              </Col>
              <Col className='is-color-red my-2 my-lg-auto mr-lg-1 align-self-center wrap-break-words'>
                <Container>
                  <Row>
                    <Col>
                      <div className='mb-2'>{product.skuAlias}</div>
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description) }}
                        className='has-line-height-2 font-weight-bold' />
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md='auto' className='mb-2 my-lg-auto align-self-center'>
                <Container>
                  <Row className='justify-content-center'>
                    <Col xs='auto' className='mt-3 mt-xl-0'>
                      <Button outline color='primary' tag={Link} to={`admin/products/${product.sku}`}>Edit</Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </ListGroupItem>
      );
    });

    let blankResults = (
      <Container>
        <Row>
          <Col>
            <h5 className='my-3'>Product Search Results</h5>
            <ListGroup className='search-list'>
              <ListGroupItem className='text-center search-list-item'>
                <div className='my-4'>
                  <h3>No products found!</h3>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );

    let resultsStart = totalCount > 0 ? (this.state.currentPage ? this.state.currentPage - 1 : 0) * this.perPage + 1 : 0;
    let resultsEnd = count < this.perPage ? totalCount : (this.state.currentPage ? this.state.currentPage : 1) * this.perPage;
    let resultsFromToOf = totalCount > 0 ? 'Showing ' + resultsStart + '-' + resultsEnd + ' of ' + totalCount : 'No';

    const resultsList = isLoading ? <PageLoader /> : (totalCount !== undefined ? (totalCount > 0 ? (
      <Container>
        <Row>
          <Col>
            <h5 className='mt-3'>Product Search Results</h5>
            <div className='my-3'>
              <Text
                isHidden={isLoading}
                value={`${resultsFromToOf} results for "${queryString.parse().q || ''}"`}
              />
            </div>
            <ListGroup className='search-list'>
              {results}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    ) : blankResults) : null);

    return (
      <div id='products-admin' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col md='4'>
                        <FormGroup className='mr-sm-2 mb-sm-0'>
                          <Label for='search-sku'>Search Products</Label>
                          <SearchInput
                            searchInputDataTestId='product-search-input'
                            searchButtonDataTestId='product-search-button'
                            value={this.state.query}
                            onChange={this.handleSearchSkuChange}
                            onKeyPress={this.handleKeyPress}
                            onClick={this.handleSearchSkuButtonPress}
                            onClear={this.clear} />
                        </FormGroup>
                        <div className='my-3'>
                          <Checkbox label='Hidden products' isChecked={this.state.hiddenOnly} onToggle={this.handleHiddenOnlyToggle} searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotHidden' })} />
                          <Checkbox label='Featured products' isChecked={this.state.featuredOnly} onToggle={this.handleFeaturedOnlyToggle} searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.featured' })} />
                          <Checkbox label='Pre-sale products' isChecked={this.state.preSaleOnly} onToggle={this.handlePreSaleOnlyToggle} searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isPreSale' })} />
                          <Checkbox label='Restricted products' isChecked={this.state.restrictedOnly} onToggle={this.handleRestrictedOnlyToggle} searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isNotRestricted' })} />
                          <Checkbox label='Discontinued products' isCheck={this.state.discontinuedOnly} onToggle={this.handleDiscontinuedOnlyToggle} searchHelpModalContent={this.props.intl.formatMessage({ id: 'productAdminReason.isDiscontinued' })} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {resultsList}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {!isLoading && totalCount > this.perPage && <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={this.perPage}
                          totalItemsCount={totalCount > this.maxResults ? this.maxResults : totalCount}
                          pageRangeDisplayed={this.pageRange}
                          innerClass='pagination flex-wrap'
                          itemClass='page-item'
                          linkClass='page-link'
                          nextPageText={<FontAwesomeIcon icon='angle-right' />}
                          prevPageText={<FontAwesomeIcon icon='angle-left' />}
                          lastPageText={<FontAwesomeIcon icon='angle-double-right' />}
                          firstPageText={<FontAwesomeIcon icon='angle-double-left' />}
                          hideFirstLastPages={false}
                          onChange={this.handlePageChange}
                        />}
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    productSearch: state.productSearch.getIndexProducts,
    locale: state.locales.locale
  };
};

const mapDispatchToProps = dispatch => ({
  getIndexProductsAdmin: (query) => dispatch(productSearchActions.getIndexProductsAdmin(query)),
  clearIndexProducts: () => dispatch(productSearchActions.clearIndexProducts())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductsAdmin));