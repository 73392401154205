import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { debounce } from 'throttle-debounce';
import classNames from 'classnames';
import { Button } from '../../components';
import { calculateNewQuantity } from "../../helpers/quantityHelper";

class QuantityInput extends Component {
  constructor(props) {
    super(props);

    this.quantityRegex = /^[1-9]+([0-9]*)?$/;
    let incrementQuantity = props.incrementQuantity > 0 ? props.incrementQuantity : 1;
    let minQuantity = props.minQuantity > 0 ? props.minQuantity : 0;
    let maxQuantity = props.maxQuantity > 0 ? props.maxQuantity : 0;
    this.state = {
      quantity: props.quantity || incrementQuantity,
      incrementQuantity: incrementQuantity,
      minQuantity: minQuantity,
      maxQuantity: maxQuantity
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined || prevProps === null)
      return false;

    if (prevProps.quantity !== this.props.quantity
      || prevProps.incrementQuantity !== this.props.incrementQuantity
      || prevProps.minQuantity !== this.props.minQuantity
      || prevProps.maxQuantity !== this.props.maxQuantity) {
      this.setState({
        quantity: this.props.quantity,
        incrementQuantity: this.props.incrementQuantity || 1,
        minQuantity: this.props.minQuantity || 0,
        maxQuantity: this.props.maxQuantity || 0
      });
      return true;
    }
    return false;
  }

  handleIncrementQuantity = () => {
    let newQuantity = this.state.quantity + this.state.incrementQuantity;
    if (this.state.maxQuantity > 0 && newQuantity > this.state.maxQuantity)
      newQuantity = this.state.maxQuantity;

    if (newQuantity === this.state.quantity)
      return;

    this.changeQuantity(newQuantity);

    this.setState({
      ...this.state,
      quantity: newQuantity
    });
  };

  handleDecrementQuantity = () => {
    let newQuantity = this.state.quantity - this.state.incrementQuantity;
    if (newQuantity < this.state.incrementQuantity)
      newQuantity = this.state.incrementQuantity;

    if (newQuantity < this.state.minQuantity)
      newQuantity = this.state.minQuantity;

    if (newQuantity === this.state.quantity)
      return;

    this.changeQuantity(newQuantity);

    this.setState({
      ...this.state,
      quantity: newQuantity
    });
  };

  handleQuantityTextChange = (e) => {
    let newQuantity = new RegExp(this.quantityRegex).test(e.target.value) ? Number(e.target.value) : this.state.quantity;
    this.changeQuantity(newQuantity);

    this.setState({
      ...this.state,
      quantity: newQuantity
    });
  };

  changeQuantity = debounce(500, newQuantity => {
    newQuantity = calculateNewQuantity(newQuantity, this.state.minQuantity, this.state.maxQuantity, this.state.incrementQuantity);

    this.props.onQuantityChange(newQuantity);

    this.setState({
      ...this.state,
      quantity: newQuantity
    });
  });

  render() {
    let classes = classNames(
      'quantity-input',
      this.props.className
    );

    let decrementButton = !this.props.hideButtons && (
      <InputGroupAddon addonType='prepend' className='d-print-none'>
        <Button data-test-id={`quantity-minus-${this.props.dataTestId}`} onClick={this.handleDecrementQuantity}><FontAwesomeIcon icon='minus' /></Button>
      </InputGroupAddon>
    );

    let incrementButton = !this.props.hideButtons && (
      <InputGroupAddon addonType='append' className='d-print-none'>
        <Button data-test-id={`quantity-plus-${this.props.dataTestId}`} onClick={this.handleIncrementQuantity}><FontAwesomeIcon icon='plus' /></Button>
      </InputGroupAddon>
    );

    return (
      <InputGroup className={classes}>
        {decrementButton}
        <Input type='number' data-test-id={`quantity-input-${this.props.dataTestId}`} value={this.state.quantity} onChange={this.handleQuantityTextChange} onKeyPress={this.props.onKeyPress} readOnly={this.props.isDisabled} />
        {incrementButton}
      </InputGroup>
    );
  }
}

QuantityInput.defaultProps = {
  hideButtons: false
};

QuantityInput.propTypes = {
  onQuantityChange: PropTypes.func,
  quantity: PropTypes.number,
  hideButtons: PropTypes.bool,
  incrementQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
  minQuantity: PropTypes.number,
  className: PropTypes.string,
  onKeyPress: PropTypes.func,
  isDisabled: PropTypes.bool,
  dataTestId: PropTypes.string
};

export default QuantityInput;