import {Col} from 'reactstrap';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

const QuantityMessage = (props) => {
    const {caseQuantity,palletQuantity, center} = props

    let caseQuantityMessage = caseQuantity && (
          <div className='font-weight-light text-secondary has-font-size-2'>
            <FormattedMessage id='products.caseQuantity' values={{caseQuantity: Math.round(caseQuantity)}} />
          </div>
    );
  
    let palletQuantityMessage = palletQuantity && (
        <div className='font-weight-light text-secondary has-font-size-2'>
            <FormattedMessage id='products.palletQuantity' values={{palletQuantity: Math.round(palletQuantity)}} />
          </div>
    );
    
    return (
        <Col xs='12' className='order-2 order-lg-1 order-xl-2' style={center ? {marginTop:'0.1rem',textAlign:'center'} : null}>
             {caseQuantityMessage}
             {palletQuantityMessage} 
        </Col>
    );
}

export default injectIntl(QuantityMessage)