import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from "react-redux-toastr";

import history from '../../../history';
import {announcementsActions} from '../../../store';
import AnnouncementEditor from './AnnouncementEditor';

class NewAnnouncement extends Component {
  handleSaveClick = (announcement) => {
    const {intl} = this.props;
    
    return this.props.newAnnouncement(announcement)
      .then(() => {
        history.push('/admin');
      })
      .catch(() => {
        toastr.error(intl.formatMessage({id: 'announcements.errorSavingTitle'}), intl.formatMessage({id: 'announcements.errorSavingMessage'}))
      });
  };

  render() {
    return (
      <div id='announcements' className='animate-bottom-fade-in content-wrapper'>
        <AnnouncementEditor
          title={this.props.intl.formatMessage({id: 'announcements.newAnnouncementTitle'})}
          onSaveClick={this.handleSaveClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  newAnnouncement: (announcement) => dispatch(announcementsActions.newAnnouncement(announcement))
});

const mapStateToProps = state => {
  return {
    isProcessing: state.announcements.newAnnouncement.isProcessing,
    isError: state.announcements.newAnnouncement.isError
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewAnnouncement));