import camelcase from 'camelcase';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Card, CardBody, Col, Container, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';

import {Button, CalendarInput} from '../../components';

const INVALID_DROPDOWN_OPTION = '-1';

class Logistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        dateReceived: this.props.data.dateReceived || undefined,
        dateRepaired: this.props.data.dateRepaired || undefined,
        dateReturned: this.props.data.dateReturned || undefined,
        receivedVia: this.props.data.receivedVia || '',
        returnedVia: this.props.data.returnedVia || '',
        shippingProvider: this.props.data.shippingProvider || '',
        trackingNumber: this.props.data.trackingNumber || '',
        workOrder: this.props.data.workOrder || '',
        customerOrder: this.props.data.customerOrder || '',
        receivedBy: this.props.data.receivedBy || '',
        repairedBy: this.props.data.repairedBy || '',
        providerName: this.props.data.providerName || '',
        productSku: props.data.productSku || ''
      },
      validation: this.getDefaultValidation()
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getDefaultValidation = () => {
    const defaultValidation = {
      dateReceivedIsValid: true,
      dateRepairedIsValid: true,
      dateReturnedIsValid: true,
      receivedViaIsValid: true,
      returnedViaIsValid: true,
      shippingProviderIsRequired: false,
      trackingNumberIsRequired: false,
      providerNameIsRequired: false
    };
    return defaultValidation;
  };

  handleReceivedDateChange = (selectedDay) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        dateReceived: selectedDay
      }
    });
  };

  handleRepairedDateChange = (selectedDay) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        dateRepaired: selectedDay
      }
    });
  };

  handleReturnedDateChange = (selectedDay) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        dateReturned: selectedDay
      }
    });
  };

  handleInputChange = (e) => {
    let name = camelcase(e.target.name);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [name]: e.target.value
      }
    });
  };

  prev = () => {
    this.props.prev();
  };

  next = () => {
    // validate data 
    let isValid = true;
    let validation = this.getDefaultValidation();
    if (!this.state.data.dateReceived 
      || (!this.props.isFlatRate && this.state.data.dateReceived > this.state.data.dateRepaired)) {
      isValid = false;
      validation = {
        ...validation,
        dateReceivedIsValid: false
      };
    }
    if (!this.props.isFlatRate && (!this.state.data.dateRepaired 
      || this.state.data.dateRepaired > this.state.data.dateReturned)) {
      isValid = false;
      validation = {
        ...validation,
        dateRepairedIsValid: false,
      };
    }
    if (!this.props.isFlatRate && !this.state.data.dateReturned) {
      isValid = false;
      validation = {
        ...validation,
        dateReturnedIsValid: false,
      };
    }
    if (!this.props.isFlatRate && (!this.state.data.receivedVia || this.state.data.receivedVia === INVALID_DROPDOWN_OPTION)) {
      isValid = false;
      validation = {
        ...validation,
        receivedViaIsValid: false,
      };
    }
    if (!this.props.isFlatRate && (!this.state.data.returnedVia || this.state.data.returnedVia === INVALID_DROPDOWN_OPTION)) {
      isValid = false;
      validation = {
        ...validation,
        returnedViaIsValid: false
      };
    }
    if (this.state.data.returnedVia === '4' 
    && (!this.state.data.shippingProvider || this.state.data.shippingProvider === INVALID_DROPDOWN_OPTION)) {
      isValid = false;
      validation = {
        ...validation,
        shippingProviderIsRequired: true
      };
    }
    if (this.state.data.returnedVia === '4' && !this.state.data.trackingNumber) {
      isValid = false;
      validation = {
        ...validation,
        trackingNumberIsRequired: true
      };
    }
    if (this.state.data.returnedVia === '4' 
    && (this.state.data.shippingProvider && this.state.data.shippingProvider === '0')
    && !this.state.data.providerName) {
      isValid = false;
      validation = {
        ...validation,
        providerNameIsRequired: true
      };
    }
    // if valid, save data to the wizard, go to next page
    if (isValid) {
      this.props.next(this.state.data);
    }
    // else, show invalid data
    this.setState({
      ...this.state,
      validation
    });
  };

  render() {
    let maxDate = new Date();
    let minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 1);
    
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h5 className='my-3'>Repair Logistics</h5>
                <Row>
                  <Col sm={6}>
                    <FormGroup tag='fieldset' data-test-id='logistics-date-received' className='mt-2 mb-0'>
                      <Label for='date-received'>Date Received*</Label>
                      <CalendarInput
                        isClearable
                        placeholderText='Received Date'
                        selectedDay={this.state.data.dateReceived}
                        onSelectedDateChange={this.handleReceivedDateChange}
                        minDate={minDate}
                        maxDate={this.state.data.dateRepaired || maxDate}
                        error={!this.state.validation.dateRepairedIsValid ? 'Received date must be on or before repaired date' : ''}
                      />
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='logistics-date-repaired' className='mt-2 mb-0' hidden={this.props.isFlatRate}>
                      <Label for='date-repaired'>Date Repaired*</Label>
                      <CalendarInput
                        isClearable
                        placeholderText='Repaired Date'
                        selectedDay={this.state.data.dateRepaired}
                        onSelectedDateChange={this.handleRepairedDateChange}
                        minDate={this.state.data.dateReceived || minDate}
                        maxDate={this.state.data.dateReturned || maxDate}
                        error={!this.state.validation.dateRepairedIsValid ? 'Repaired date must be on or after received date' : ''}
                      />
                    </FormGroup>
                    <FormGroup tag='fieldset' data-test-id='logistics-date-returned' className='mt-2 mb-0' hidden={this.props.isFlatRate}>
                      <Label for='date-returned'>Date Returned*</Label>
                      <CalendarInput
                        isClearable
                        placeholderText='Returned Date'
                        selectedDay={this.state.data.dateReturned}
                        onSelectedDateChange={this.handleReturnedDateChange}
                        minDate={this.state.data.dateRepaired || minDate}
                        maxDate={maxDate}
                        error={!this.state.validation.dateReturnedIsValid ? 'Returned date must be on or after repaired date' : ''}
                      />
                    </FormGroup>
                    <FormGroup hidden={this.props.isFlatRate}>
                      <Label for='received-via'>Received Via</Label>
                      <Input 
                        type='select' 
                        name='received-via' 
                        id='received-via' 
                        value={this.state.data.receivedVia} 
                        onChange={this.handleInputChange}
                        invalid={!this.state.validation.receivedViaIsValid}
                      >
                        <option value={INVALID_DROPDOWN_OPTION}>Select Received Via</option>
                        <option value={2}>Customer Drop-Off/Pickup</option>
                        <option value={3} hidden={this.state.data.productSku.includes('MXF')}>Company Truck</option>
                        <option value={4} hidden={this.state.data.productSku.includes('MXF')}>Common Carrier/Freight</option>
                      </Input>
                      <FormFeedback>Received Via is Required</FormFeedback>
                    </FormGroup>
                    <FormGroup hidden={this.props.isFlatRate}>
                      <Label for='returned-via'>Returned Via</Label>
                      <Input 
                        type='select' 
                        name='returned-via' 
                        id='returned-via' 
                        value={this.state.data.returnedVia} 
                        onChange={this.handleInputChange}
                        invalid={!this.state.validation.returnedViaIsValid}
                      >
                        <option value={INVALID_DROPDOWN_OPTION}>Select Return Via</option>
                        <option value={2}>Customer Drop-Off/Pickup</option>
                        <option value={3} hidden={this.state.data.productSku.includes('MXF')}>Company Truck</option>
                        <option value={4} hidden={this.state.data.productSku.includes('MXF')}>Common Carrier/Freight</option>
                      </Input>
                      <FormFeedback>Received Via is Required</FormFeedback>
                    </FormGroup>
                    <FormGroup hidden={this.state.data.returnedVia !== '4'}>
                      <Label for='shipping-provider'>Shipping Provider*</Label>
                      <Input 
                        type='select' 
                        name='shipping-provider' 
                        id='shipping-provider' 
                        value={this.state.data.shippingProvider} 
                        onChange={this.handleInputChange} 
                        invalid={this.state.validation.shippingProviderIsRequired}
                      >
                        <option value={INVALID_DROPDOWN_OPTION}></option>
                        <option value={1}>FedEx</option>
                        <option value={2}>UPS</option>
                        <option value={3}>USPS</option>
                        <option value={4}>Purolator</option>
                        <option value={5}>UPSFreight</option>
                        <option value={6}>SAIA</option>
                        <option value={7}>USFHolland</option>
                        <option value={8}>YRC</option>
                        <option value={9}>Southeastern</option>
                        <option value={0}>Other</option>
                      </Input>
                      <FormFeedback>Shipping Provider is Required</FormFeedback>
                    </FormGroup>
                    <FormGroup hidden={this.state.data.shippingProvider !== '0'}>
                      <Label for='provider-name'>Provider Name</Label>
                      <Input 
                        type='text' 
                        name='provider-name' 
                        id='provider-name' 
                        value={this.state.data.providerName} 
                        onChange={this.handleInputChange} 
                        invalid={this.state.validation.providerNameIsRequired}
                      />
                      <FormFeedback>Provider Name is Required</FormFeedback>
                    </FormGroup>
                    <FormGroup hidden={this.state.data.returnedVia !== '4'}>
                      <Label for='tracking-number'>Tracking #*</Label>
                      <Input 
                        type='text' 
                        name='tracking-number' 
                        id='tracking-number' 
                        value={this.state.data.trackingNumber} 
                        onChange={this.handleInputChange} 
                        invalid={this.state.validation.trackingNumberIsRequired}
                      />
                      <FormFeedback>Tracking # is Required</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h5 className='my-3'>Repair Notes</h5>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for='work-order'>Work Order # (Optional)</Label>
                      <Input type='text' data-test-id='logistics-work-order' name='work-order' id='work-order' value={this.state.data.workOrder} onChange={this.handleInputChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for='customer-order'>Customer Order # (Optional)</Label>
                      <Input type='text' data-test-id='logistics-customer-order' name='customer-order' id='customer-order' value={this.state.data.customerOrder} onChange={this.handleInputChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for='received-by'>Received By (Optional)</Label>
                      <Input type='text' data-test-id='logistics-received-by' name='received-by' id='received-by' value={this.state.data.receivedBy} onChange={this.handleInputChange} />
                    </FormGroup>
                    <FormGroup hidden={this.props.isFlatRate}>
                      <Label for='repaired-by'>Repaired By (Optional)</Label>
                      <Input type='text' data-test-id='logistics-repaired-by' name='repaired-by' id='repaired-by' value={this.state.data.repairedBy} onChange={this.handleInputChange} />
                    </FormGroup>
                 </Col>
                </Row>
                <Row className='justify-content-end'>
                  <Col xs='auto'>
                    <Button data-test-id='logistics-back' onClick={this.prev}>Back</Button>
                  </Col>
                  <Col xs='auto'>
                    <Button data-test-id='logisitcs-next' block color='primary' onClick={this.next}>Next</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

Logistics.propTypes = {
  next: PropTypes.func,
  prev: PropTypes.func,
  data: PropTypes.object,
  isFlatRate: PropTypes.bool
};

export default injectIntl((Logistics));