import Enumerable from 'linq';
import {useEffect, useState} from 'react';

import {OrderStatus} from '../enums';
import {getProfile} from '../helpers/localStorage';
import user from '../helpers/user';

// This hook expects the whole cart and whole account objects from app state
const useCanCheckOut = (cart, account) => {
  const [canCheckout, setCanCheckout] = useState(false);
  const [accountCanCheckOut, setAccountCanCheckOut] = useState(false);

  useEffect(() => {
    let userProfile = getProfile();
    let accountNumber = account.isImpersonating ? account.impersonatedAccount.accountDetails.accountNumber : account.currentAccount.accountDetails.accountNumber;
    let accountChildren = account.currentAccount.accountDetails.children;
    let accountNumbers = Enumerable.from(accountChildren).select(x => x.accountNumber);
    setAccountCanCheckOut((userProfile.accountNumber === accountNumber || accountNumbers.contains(accountNumber)) || user.isSuperAdmin())
  }, [account]);

  useEffect(() => {
    if (cart.isRefreshing)
      setCanCheckout(false);
  }, [cart.isRefreshing]);
  
  useEffect(() => {
    if (cart.pendingOrder && Object.keys(cart.pendingOrder.orderLines).length > 0) {
      if (cart.pendingOrder.orderLines.unavailable || cart.pendingOrder.orderStatusId !== OrderStatus.Pending || Object.keys(cart.pendingOrder.orderLines).some(key => key.toLowerCase().indexOf('multisite:') > -1)) {
        setCanCheckout(false);
      }
      else
        setCanCheckout(true);
    }
    else {
      setCanCheckout(false);
    }
  }, [cart.pendingOrder]);
  
  return canCheckout && accountCanCheckOut;
};

export default useCanCheckOut;