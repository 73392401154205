import React, {Component, Fragment} from 'react';
import {Card, CardBody, CardHeader, Col, Container, ListGroup, Row} from 'reactstrap';
import {Button, CardBodyField, Price} from '../../components';
import PartsListTable from '../WarrantyClaim/PartsListTable';

class ReviewClaim extends Component {
  back = () => {
    this.props.back();
  };

  submit = () => {
    this.props.submit();
  };

  render() {
    let {claim} = this.props;

    let partsListMarkup = claim.parts && (
      <Fragment>
        <Row>
          <Col>
            <ListGroup className='mt-3'>
              <PartsListTable
                parts={claim.parts}
                showPrices={!(claim.pricingStructure === 0)}
              />
            </ListGroup>
          </Col>
        </Row>
      </Fragment>
    );
    return (
      
      <div id='defective-claim-details' className='animate-bottom-fade-in content-wrapper'>
        <Container>
          <Row id="review-header">
            <Col>
              <Card>
                <CardBody>
                  <div className='font-weight-bold'>Review Claim</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>Parts</span>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    {partsListMarkup}
                    {!(claim.pricingStructure === 0) &&
                      <Row className='justify-content-end'>
                        <Col xs='auto'>
                          <CardBodyField claimDataTestId='defective-claim-review-warranty-total' label='Warranty Total:' value={claim.partsTotal && <Price value={claim.partsTotal} />}/> 
                        </Col>
                      </Row>
                    }
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <span className='text-uppercase'>Repair Notes</span>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col md='8'>
                        <CardBodyField claimDataTestId='defective-claim-review-work-order-number' label='Work Order #:' value={claim.workOrder}/> 
                      </Col>
                    </Row>
                    <Row className='my-1'>
                      <Col md='6'>
                        <span className='has-font-size-2 font-weight-bold mr-2'>Comments:</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {claim.repairNotes}
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row id="action-buttons" className='justify-content-end'>
            <Col xs='auto'>
              <Button data-test-id='defective-claim-review-back' onClick={this.back}>Back</Button>
            </Col>
            <Col xs='auto'>
              <Button data-test-id='defective-claim-review-submit' block color='primary' onClick={this.submit}>Submit Warranty Claim</Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default ReviewClaim;