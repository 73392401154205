import Bowser from 'bowser/bundled';

export default {
  isSupportedBrowser: () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    const browserVersion = browser.getBrowserVersion().split('.');
    
    let isInternetExplorer = browserName.toLowerCase() === 'internet explorer';
    let browserVersionMajor = browserVersion ? parseInt(browserVersion[0]) : 0;
    
    return isInternetExplorer ? browserVersionMajor >= 11 : true;
  },
  setUserAgent: (userAgent) => {
    Object.defineProperty(window.navigator, 'userAgent', {value: userAgent, configurable: true});
  }
};