import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {AnnouncementModal, Button, Link} from '../../../components';

class AnnouncementRow extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isPreviewing: false,
      isDeleting: false
    }
  }
  
  toggleModal = () => {
    this.setState({
      ...this.state,
      isPreviewing: !this.state.isPreviewing
    });
  };
  
  toggleDeletionConfirmation = () => {
    this.setState({
      ...this.state,
      isDeleting: !this.state.isDeleting
    });
  };
  
  handleDelete = () => {
    this.setState({
      ...this.state,
      isDeleting: !this.state.isDeleting
    });
    this.props.onDelete(this.props.announcement.id);
  };
  
  render() {
    const {announcement, intl} = this.props;
    
    return (
      <tr>
        <td data-label={intl.formatMessage({id: 'announcements.heading'})}>{announcement.heading}</td>
        <td data-label={intl.formatMessage({id: 'announcements.startDate'})}><FormattedDate value={announcement.startDate} /></td>
        <td data-label={intl.formatMessage({id: 'announcements.endDate'})}><FormattedDate value={announcement.endDate} /></td>
        <td data-label={intl.formatMessage({id: 'announcements.priority'})}>{announcement.priority}</td>
        <td data-label={intl.formatMessage({id: 'announcements.actions'})}>
          <FontAwesomeIcon className='is-mock-link text-secondary mx-2' icon='eye' onClick={this.toggleModal} />
          <Link to={`admin/announcements/${announcement.id}`} className='mx-2'>
            <FontAwesomeIcon className='is-mock-link text-secondary' icon='pencil-alt' />
          </Link>
          <FontAwesomeIcon className='is-mock-link text-secondary mx-2' icon='trash-alt' onClick={this.toggleDeletionConfirmation} />
        </td>
        <AnnouncementModal announcement={announcement} isOpen={this.state.isPreviewing} toggle={this.toggleModal}/>
        <Modal isOpen={this.state.isDeleting} toggle={this.toggleDeletionConfirmation} centered={true}>
          <ModalHeader toggle={this.toggle}>
            <FormattedMessage id='announcements.confirmDeleteModalTitle'/>
          </ModalHeader>
          <ModalBody>
            <div><FormattedMessage id='announcements.confirmDeleteModalBody'/></div>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggleDeletionConfirmation}>
              <FormattedMessage id='general.cancel'/>
            </Button>{' '}
            <Button color='primary' onClick={this.handleDelete}>
              <FormattedMessage id='announcements.confirmDeleteButton'/>
            </Button>
          </ModalFooter>
        </Modal>
      </tr>
    )
  }
}

export default injectIntl(AnnouncementRow);