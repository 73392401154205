import React from 'react';
import DOMPurify from 'dompurify';
import {FormattedMessage} from 'react-intl';
import {Col, Container, Row} from 'reactstrap';
import {Button, Link, ProductAvailability, ProductPrice} from '../../../components';
import user from '../../../helpers/user';

const SaveForLaterLinePreview = (props) => {
  const { sku, skuAlias, description } = props.saveForLaterLine;

  const handleDeleteClick = () => {
    return props.handleDeleteClick(props.saveForLaterLine);
  };
  
  const handleMoveToCartClick = () => {
    return props.handleMoveToCartClick(props.saveForLaterLine);
  };
  
  const getSkuAliasMarkup = (line) =>{
    return  line.skuAlias !== line.description && (
      <div data-test-id={`save-for-later-product-sku-${line.sku}`} className='mb-2 mr-lg-3'>{line.sku}</div>
    );
  }
  
  const getListPriceMarkup = (line) =>{
    return user && !user.isMilwaukeeToolEmployee() && (
      <div>
      <span className='has-font-size-1'>
        <FormattedMessage id='pricing.listPrice'/>&nbsp;
      </span>
        <span data-test-id={`save-for-later-list-price-${line.sku}`} className='has-font-size-1'>
        <del>
          <ProductPrice
            pricing={props.pricing}
            sku={line.sku}
            type='listAmount'
          />
        </del>
      </span>
      </div>
    );
  }
  
  const getYourPriceMarkup = (line) =>{
    return (
      <div>
        <div className='has-font-size-2'>
          <FormattedMessage id='pricing.yourPrice'/>
        </div>
        <div data-test-id={`save-for-later-your-price-${line.sku}`} className='has-font-size-4'>
          <ProductPrice
            pricing={props.pricing}
            sku={line.sku}
            type='unitAmount'
          />
        </div>
      </div>
    )
  }
  
  const getAvailabilityMarkup = (line) =>{
    return (<div data-test-id={`save-for-later-availability-${line.sku}`} className='has-font-size-2 font-weight-bold'>
      <ProductAvailability
        availability={props.availability}
        sku={line.sku}
      />
    </div>);
  } 
  
  return (
    <Container className='order-line-preview'>
      <Row className='align-items-center animate-bottom-fade-in'>
        <Col md='auto' data-test-id={`save-for-later-product-image-${sku}`} className='text-center mx-lg-2 my-lg-auto align-self-center product-image'>
          <img src={`/api/v1/images/?sku=${skuAlias}`} alt={description}/>
        </Col>
        <Col className='align-self-center'>
          <Container>
            <Row>
              <Col lg='6' className='is-color-red my-2 my-lg-auto align-self-center wrap-break-words'>
                <Link to={`products/${skuAlias}`} locale={props.locale} className='no-underline'>
                  {getSkuAliasMarkup(props.saveForLaterLine)}
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} data-test-id={`save-for-later-product-name-${sku}`} className='has-line-height-2 font-weight-bold'/>
                </Link>
              </Col>
              <Col lg='3' xs='6' className='my-2 my-lg-0 align-self-center'>
                {getListPriceMarkup(props.saveForLaterLine)}
                {getYourPriceMarkup(props.saveForLaterLine)}
                {getAvailabilityMarkup(props.saveForLaterLine)}
              </Col>
            </Row>
            {props.saveForLaterLine.children?.length ? (
              <React.Fragment>
                {props.saveForLaterLine.children.map(c=>(
                  <Row key={c.id} className='mt-2'>
                    <Col lg='6' className='my-2 my-lg-auto align-self-center wrap-break-words'>
                      {getSkuAliasMarkup(c)}
                      <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(c.description)}} className='has-line-height-2 font-weight-bold'/>
                    </Col>
                    <Col lg='3' xs='6' className='my-2 my-lg-0 align-self-center'>
                      {getListPriceMarkup(c)}
                      {getYourPriceMarkup(c)}
                    </Col>
                  </Row>
                ))}               
              </React.Fragment>
            ): ''}
          </Container>
        </Col>
        <Col xl='auto' className='mb-2 my-lg-auto align-self-center'>
          <Container>
            <Row className='justify-content-center'>
              <Col xs='auto' className='mt-3 mt-xl-0'>
                <Button data-test-id={`save-for-later-delete-${sku}`} outline color='secondary' onClick={handleDeleteClick}>Delete</Button>
                <Button data-test-id={`save-for-later-move-to-cart-${sku}`} outline color='primary' onClick={handleMoveToCartClick} isLoading={props.isProcessing} className='ml-2'>Move to Cart</Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default SaveForLaterLinePreview;