import React from 'react';
import styled from 'styled-components';
import { theme, Icon } from '@met/react-components';

const NoShippingAddressesWrapper = styled.div`
  text-align: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
`;

const MutedText = styled.div`
  color: ${theme.colors.muted};
  > div {
    vertical-align: middle;
  }
`;

const NoShippingAddressesHeader = styled.h3`
  font-size: 1.75rem;
  font-weight: 600;
  margin: .2rem;
`;

const NoShippingAddresses = () => (
  <NoShippingAddressesWrapper>
    <MutedText>
      <Icon sm type='info-circle' />
    </MutedText>
    <NoShippingAddressesHeader>No Addresses</NoShippingAddressesHeader>
    <MutedText>Add an address with the &apos;Add Address&apos; button below.</MutedText>
  </NoShippingAddressesWrapper>
);

export default NoShippingAddresses;