import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {Component} from 'react';

import MilwaukeeToolIcon from './MilwaukeeToolIcon';

class Icon extends Component {
  render() {
    const { type, badgeValue, color, className, size, onClick, isDisabled, width, height } = this.props;

    let onClickAction = (isDisabled == null || isDisabled === false) ? onClick : null;

    let wrapperClasses = classNames(
      'icon',
      className
    );
    let iconClasses = classNames(
      size != null ? `fa-${size}` : 'fa-2lg',
      color != null ? `is-color-${color}` : 'is-color-white'
    );

    let iconMarkup = '';

    switch (type) {
      case 'bolt-add':
      case 'mt-logo':
        iconMarkup = <MilwaukeeToolIcon icon={type} className={iconClasses} color={color} width={width} height={height} />;
        break;
      default:
        iconMarkup = <FontAwesomeIcon icon={type} className={iconClasses}/>;
    }

    let badge = badgeValue != null ? <span className='icon-badge' data-count={badgeValue}></span> : <span></span>;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <span className={wrapperClasses} onClick={onClickAction}>
        {iconMarkup}
        {badge}
      </span>
    );
  }
}

export default Icon;