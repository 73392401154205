import camelcase from 'camelcase';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import Enumerable from 'linq';

import {Address, Button, Checkbox, CountriesDropdown, Input, StatesDropdown} from '../../components';
import { warrantyClaimActions, countriesActions, statesActions } from '../../store';

const blankCustomer = {
  //Any adjustments to IsValid must also be changed in CustomerViewModel.cs in CustomerPortal.WarrantyClaims
  id: 0,
  firstName: {
    value: '',
    isValid: false
  },
  lastName: {
    value: '',
    isValid: false
  },
  name: {
    value: '',
    isValid: true
  },
  address1: {
    value: '',
    isValid: false
  },
  address2: {
    value: '',
    isValid: true
  },
  city: {
    value: '',
    isValid: false
  },
  country: {
    value: '',
    isValid: false
  },
  postalCode: {
    value: '',
    isValid: false
  },
  stateProvince: {
    value: '',
    isValid: false
  },
  email: {
    value: '',
    isValid: true
  },
  fax: {
    value: '',
    isValid: true
  },
  phone: {
    value: '',
    isValid: true
  },
  legacyAddressId: {
    value: null,
    isValid: true
  },
  showInAddressBook: {
    value: false,
    isValid: true
  }
};

let notBlankRegex = /.+/;
class CustomerDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        customer: this.setupCustomer(this.props.data.customer)
      },
      showFields: !!this.props.data.customer && this.props.data.customer.id === 0,
      showInvalidDataMessage: false,
      trySubmit: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.getCustomersForAccount(this.props.data.accountNumber);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
    
    if (prevProps.data.accountNumber !== this.props.data.accountNumber) {
      this.props.getCustomersForAccount(this.props.data.accountNumber);
    }
  }

  setupCustomer = (customerProps) => {
    let customer = { ...blankCustomer };
    if (customerProps) {
      Object.keys(customerProps).forEach(function (item) {
        if (customerProps[item] && typeof customer[item] === typeof customerProps[item]) {
          customer[item] = customerProps[item];
        } else if (Object.prototype.hasOwnProperty.call(customer, item)) {
          customer[item] = {
            value: customerProps[item] ? customerProps[item] : '',
            isValid: !customer[item].isValid && customerProps[item] === null ? false : true
          };
        }
      });
    }
    return customer;
  };

  toggleFields = (countryStateIsValid, value) => {
    //this throws a console error, but otherwise seems to work fine
    let customer = this.setupCustomer(value);

    var alphanumericRegex = /^([0-9]|[a-z])+([0-9a-z]+)$/i;
    let lastFirstIsValid = !(customer.firstName.value === '' || !customer.firstName.value.match(alphanumericRegex)) && !(customer.lastName.value === '' || !customer.lastName.value.match(alphanumericRegex));

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customer: {
          ...customer,
          country: {
            value: countryStateIsValid ? customer.country.value : '',
            isValid: countryStateIsValid
          },
          stateProvince: {
            value: countryStateIsValid ? customer.stateProvince.value : '',
            isValid: countryStateIsValid
          }
        }
      },
      showFields: !countryStateIsValid || !lastFirstIsValid,
      showInvalidDataMessage: !countryStateIsValid || !lastFirstIsValid
    });
  }

  handleCustomerSelectChange = (value, action) => {
    switch(action.action) {
      case 'select-option': {
        let self = this;
        let countryStateIsValid = false;
        let customer = this.setupCustomer(value);
        this.props.getCountries()
          .then((countries) => {
            countryStateIsValid = Enumerable.from(countries.data).any(x => x.nameNormalized === customer.country.value);
            if (countryStateIsValid && customer.country.value === 'UNITED STATES') {
              this.props.getStates()
                .then((states) => {
                  countryStateIsValid =
                    Enumerable.from(states.data).any(x => x.abbreviation === customer.stateProvince.value);
                  self.toggleFields(countryStateIsValid, value);
                });
            } else {
              self.toggleFields(countryStateIsValid, value);
            }
          }); 
        break;
      }
      case 'clear':
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            customer: blankCustomer
          },
          showFields: false,
          showInvalidDataMessage: false
        });
        break;
      case 'create-option':
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            customer: blankCustomer
          },
          showFields: true,
          showInvalidDataMessage: false
        });
        break;
      default:
        break;
    }
  };

  filterCustomers = (inputValue) => {
    let mappedCustomers = this.props.customers && this.props.customers.map(c => {
      return {
        ...c,
        label: `${c.name} - ${c.firstName} ${c.lastName}`,
        value: c.id
      };
    });

    if (inputValue)
      return (mappedCustomers || []).filter(c => c.label.toLowerCase().includes(inputValue.toLowerCase()));
    else
      return (mappedCustomers || []);
  };

  promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterCustomers(inputValue));
      }, 1000);
    });

  handleInputChange = (e) => {
    let name = camelcase(e.name);
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customer: {
          ...this.state.data.customer,
          [name]: {
            value: e.value,
            isValid: e.isValid
          }
        }
      }
    });
  };

  handleShowInAddressBook = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customer: {
          ...this.state.data.customer,
          showInAddressBook: {
            ...this.state.data.customer.showInAddressBook,
            value: !this.state.data.customer.showInAddressBook.value
          }
        }
      }
    });
  };

  prev = () => {
    this.props.prev();
  };

  next = () => {
    this.setState({
      ...this.state,
      trySubmit: true
    }, () => {
      let customer = this.state.data.customer;
        let isValid = true;
        //validate if edit or new
        if (customer.id < 1 || this.state.showInvalidDataMessage) {
        Object.keys(customer).forEach(function (item) {
          if (Object.prototype.hasOwnProperty.call(customer[item], 'isValid')) {
            isValid = customer[item].isValid ? isValid : false;
          }
        });
      }
      if (isValid) {
        this.props.next(this.state.data);
      }
    });
  };
  
  reset = () => {
    this.setState( {
      data: {
        customer: this.setupCustomer(this.props.data.customer)
      },
      showFields: false,
      showInvalidDataMessage: false,
      trySubmit: false
    });
  };
  
  handleCountryDropdownChange = (selectedCountry) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customer: {
          ...this.state.data.customer,
          country: {
            value: selectedCountry.nameNormalized,
            isValid: selectedCountry.nameNormalized !== ''
          }
        }
      }
    });
  };
  
  handleStateDropdownChange = (selectedState) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        customer: {
          ...this.state.data.customer,
          stateProvince: {
            value: selectedState.abbreviation,
            isValid: selectedState.abbreviation !== ''
          }
        }
      }
    });
  };

  render() {
    let stateInputMarkup = this.state.data.customer.country.value !== '' && (this.state.data.customer.country.value === 'UNITED STATES' ? (
      <FormGroup tag='fieldset' data-test-id='customer-details-state-dropdown' className='mt-2 mb-0'>
        <Label for='state-province'>State/Province</Label>
        <StatesDropdown
          onChange={this.handleStateDropdownChange}
          value={this.state.data.customer.stateProvince.value}
          invalid={!this.state.data.customer.stateProvince.isValid}
        />
      </FormGroup>
    ) : (
      <FormGroup tag='fieldset' data-test-id='customer-details-state' className='mt-2 mb-0'>
        <Label for='state-province'>State/Province</Label>
        <Input
          type='text'
          name='state-province'
          id='state-province'
          value={this.state.data.customer.stateProvince.value}
          onChange={this.handleInputChange}
          isValid={this.state.data.customer.stateProvince.isValid}
          validationRegex={notBlankRegex}
          trySubmit={this.state.trySubmit}
          validationMessage='State/Province is required'
        />
      </FormGroup>
    ));
    
    let customerDropdownMarkup = this.props.data.accountNumber && (
      <AsyncCreatableSelect
        className="customer"
        classNamePrefix="select"
        cacheOptions
        defaultOptions
        isClearable
        loadOptions={this.promiseOptions}
        onChange={this.handleCustomerSelectChange}
      />
    );
    
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <h5 className='my-3'>Customer Details</h5>
                    {!this.state.showFields &&
                      <FormGroup tag='fieldset' data-test-id='customer-details-dropdown' className='mt-2 mb-0'>
                        <Label for='customer'>Find Customer / Company</Label>
                        {customerDropdownMarkup}
                      </FormGroup>
                    }
                    {!this.state.showFields && this.state.data.customer.id > 0 &&
                      <Address
                      addressDataTestId='customer-details-address'
                        line1={this.state.data.customer.address1.value}
                        line2={this.state.data.customer.address2.value}
                        city={this.state.data.customer.city.value}
                        state={this.state.data.customer.stateProvince.value}
                        postalCode={this.state.data.customer.postalCode.value}
                        countryCode={this.state.data.customer.country.value}
                      />
                    }
                    {this.state.showFields && this.state.showInvalidDataMessage &&
                      <h5 className='is-color-red my-3'>The previous data for this customer was invalid. Please edit.</h5>
                    }
                    {this.state.showFields && 
                      <Fragment>
                        <FormGroup tag='fieldset' data-test-id='customer-details-first-name' className='mt-2 mb-0'>
                          <Label for='first-name'>First Name</Label>
                          <Input
                            type='text'
                            name='first-name'
                            id='first-name'
                            value={this.state.data.customer.firstName.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.firstName.isValid}
                            validationRegex={notBlankRegex}
                            trySubmit={this.state.trySubmit}
                            validationMessage='First Name is required'
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-last-name' className='mt-2 mb-0'>
                          <Label for='last-name'>Last Name</Label>
                          <Input 
                            type='text'
                            name='last-name'
                            id='last-name'
                            value={this.state.data.customer.lastName.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.lastName.isValid}
                            validationRegex={notBlankRegex}
                            trySubmit={this.state.trySubmit}
                            validationMessage='Last Name is required'
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-company-name' className='mt-2 mb-0'>
                          <Label for='name'>Company Name</Label>
                          <Input 
                            type='text'
                            name='name'
                            id='name'
                            value={this.state.data.customer.name.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.name.isValid}
                            trySubmit={this.state.trySubmit}
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-address' className='mt-2 mb-0'>
                          <Label for='address1'>Address</Label>
                          <Input 
                            type='text'
                            name='address1'
                            id='address1'
                            value={this.state.data.customer.address1.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.address1.isValid}
                            validationRegex={notBlankRegex}
                            trySubmit={this.state.trySubmit}
                            validationMessage='Address is required'
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-address2' className='mt-2 mb-0'>
                          <Input 
                            type='text'
                            name='address2'
                            id='address2'
                            value={this.state.data.customer.address2.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.address2.isValid}
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-country' className='mt-2 mb-0'>
                          <Label for='country'>Country</Label>
                          <CountriesDropdown
                          onChange={this.handleCountryDropdownChange}
                          value={this.state.data.customer.country.value}
                          invalid={!this.state.data.customer.country.isValid}
                          />
                        </FormGroup>
                        {stateInputMarkup}
                        <FormGroup tag='fieldset' data-test-id='customer-details-city' className='mt-2 mb-0'>
                          <Label for='city'>City</Label>
                          <Input
                            type='text'
                            name='city'
                            id='city'
                            value={this.state.data.customer.city.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.city.isValid}
                            validationRegex={notBlankRegex}
                            trySubmit={this.state.trySubmit}
                            validationMessage='City is required'
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-zip-code' className='mt-2 mb-0'>
                          <Label for='postal-code'>Zip/Postal Code</Label>
                          <Input
                            type='text'
                            name='postal-code'
                            id='postal-code'
                            value={this.state.data.customer.postalCode.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.postalCode.isValid}
                            validationRegex={notBlankRegex}
                            trySubmit={this.state.trySubmit}
                            validationMessage='Zip/Postal Code is required'
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-phone' className='mt-2 mb-0'>
                          <Label for='phone'>Phone</Label>
                          <Input 
                            type='text'
                            name='phone'
                            id='phone'
                            value={this.state.data.customer.phone.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.phone.isValid}
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-email' className='mt-2 mb-0'>
                          <Label for='email'>Email</Label>
                          <Input 
                            type='text'
                            name='email'
                            id='email'
                            value={this.state.data.customer.email.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.email.isValid}
                          />
                        </FormGroup>
                        <FormGroup tag='fieldset' data-test-id='customer-details-fax' className='mt-2 mb-0'>
                          <Label for='fax'>Fax</Label>
                          <Input 
                            type='text'
                            name='fax'
                            id='fax'
                            value={this.state.data.customer.fax.value}
                            onChange={this.handleInputChange}
                            isValid={this.state.data.customer.fax.isValid}
                          />
                        </FormGroup>
                        <FormGroup data-test-id='customer-details-save-customer-checkbox' className='mt-2'>
                          <Checkbox 
                            label='Save customer/company to address book' 
                            value={this.state.data.customer.showInAddressBook.value}
                            isChecked={this.state.data.customer.showInAddressBook.value} 
                            onToggle={this.handleShowInAddressBook} />
                        </FormGroup>
                      </Fragment>
                    }
                  </Col>
                </Row>
                {this.state.showFields &&
                  <Row className='justify-content-end'>
                    <Col xs='auto'>
                      <Button data-test-id='customer-details-reset' onClick={this.reset}>Reset</Button>
                    </Col>
                  </Row>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='justify-content-end'>
          <Col xs='auto'>
            <Button data-test-id='customer-details-back' onClick={this.prev}>Back</Button>
          </Col>
          <Col xs='auto'>
            <Button data-test-id='customer-details-next' block color='primary' onClick={this.next}>Next</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

CustomerDetails.propTypes = {
  verifyClaim: PropTypes.func,
  prev: PropTypes.func,
  data: PropTypes.object
};

const mapStateToProps = state => {
  return {
    customers: state.warrantyClaim.customers && state.warrantyClaim.customers.customers    
  };
};

const mapDispatchToProps = dispatch => ({
  getCustomersForAccount: (accountNumber, filter) => dispatch(warrantyClaimActions.getCustomersForAccount(accountNumber, filter)),
  getCountries: () => dispatch(countriesActions.getCountries()),
  getStates: () => dispatch(statesActions.getStates())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerDetails));