import auth from '../auth';
import { getProfile } from './localStorage';

const milwaukeeToolEmployee = 'Milwaukee Tool Employee';

export default {
  canPlaceOrders: () => {
    let profile = getProfile();
    let isEmployee = profile && profile.roles && profile.roles.includes(milwaukeeToolEmployee);
    let isConnectUser = profile && profile.roles && profile.roles.includes('Milwaukee Connect User');
    return auth.isAuthenticated() && profile && (isEmployee || isConnectUser) && profile.permissions && profile.permissions.canPlaceOrders && profile.accountNumber && profile.accountNumber.length > 0;
  },
  canViewAllOrders: () => {
    let profile = getProfile();
    return auth.isAuthenticated() && profile && ((profile.roles && profile.roles.includes('Administrator')) || (profile.permissions && profile.permissions.canImpersonateUsers));
  },
  isAdmin: () => {
    let profile = getProfile();
    let isAdministrator = auth.isAuthenticated() && profile && profile.roles && profile.roles.includes('Administrator') && profile.roles.includes(milwaukeeToolEmployee);
    return isAdministrator;
  },
  isCompanyAdmin: () => {
    let profile = getProfile();
    let isCompanyAdmin = auth.isAuthenticated() && profile && (profile.roles && profile.roles.includes('Milwaukee Connect Company Admin'));
    return isCompanyAdmin;
  },
  isConnectUser: () => {
    let profile = getProfile();
    let isEmployee = profile && profile.roles && profile.roles.includes(milwaukeeToolEmployee);
    let isConnectUser = profile && profile.roles && profile.roles.includes('Milwaukee Connect User');
    return auth.isAuthenticated() && profile && profile.accountNumber && (isEmployee || isConnectUser);
  },
  isSuperAdmin: () => {
    let profile = getProfile();
    let isAdministrator = auth.isAuthenticated() && profile && (profile.roles && profile.roles.includes('Administrator'));

    return isAdministrator &&
    (
        (profile.email
        && (profile.email.toLowerCase() === "benjiman.williams@milwaukeetool.com"
        || profile.email.toLowerCase() === "daniel.justus@milwaukeetool.com"
        || profile.email.toLowerCase() === "caleb.cameron@milwaukeetool.com"
        || profile.email.toLowerCase() === "evan.zittel@milwaukeetool.com"))
      || (profile.lastname
          && (profile.lastname.toLowerCase() === "williams"
          || profile.lastname.toLowerCase() === "justus"
          || profile.lastname.toLowerCase() === "cameron"
          || profile.lastname.toLowerCase() === "zittel"))
    ); //yeah this is hacky, but meh
  },
  isAnnouncementsAdmin: () => {
    let profile = getProfile();
    let isAdministrator = auth.isAuthenticated() && profile && profile.roles && profile.roles.includes('Announcements Admin') && profile.roles.includes(milwaukeeToolEmployee);
    return isAdministrator;
  },
  isProductAdmin: () => {
    let profile = getProfile();
    let isAdministrator = auth.isAuthenticated() && profile && profile.roles && profile.roles.includes('Product Admin') && profile.roles.includes(milwaukeeToolEmployee);
    return isAdministrator;
  },
  isMilwaukeeToolEmployee: () => {
    let profile = getProfile();
    return auth.isAuthenticated() && profile && profile.roles && profile.roles.includes(milwaukeeToolEmployee);
  },
  canApproveWarrantyClaims: () => {
    let profile = getProfile();
    return auth.isAuthenticated() && profile && profile.permissions && profile.permissions.canApproveWarrantyClaims;
  },
  canQuoteOtherAccounts: () => {
    let profile = getProfile();
    return auth.isAuthenticated() && profile.permissions && profile.permissions.canQuoteOtherAccounts;
  },
  canViewWarrantyClaims: () => {
    let profile = getProfile();
    return auth.isAuthenticated() && profile && profile.permissions && profile.permissions.canViewWarrantyClaims;
  },
  canCreateWarrantyClaims: () => {
    let profile = getProfile();
    return auth.isAuthenticated() && profile && profile.permissions && profile.permissions.canCreateWarrantyClaims;
  },
  canEditAnnouncements: () => {
    let profile = getProfile();
    return auth.isAuthenticated() && profile && ((profile.roles && (profile.roles.includes('AnnouncementsAdmin') || profile.roles.includes('Administrator'))));
  },
  isCreditCardUser: () => {
    let profile = getProfile();
    return auth.isAuthenticated() &&
      profile &&
      profile.cachedAccountDetails &&
      profile.cachedAccountDetails.isCreditCardOnlyAccount &&
      profile.cachedAccountDetails.currencyCode === "USD";
  },
  getUserId: () => {
    let profile = getProfile();
    return auth.isAuthenticated() && profile && profile.userId;
  },
  isDPLUS: () => {
    let profile = getProfile();
    return profile &&
      profile.cachedAccountDetails &&
      profile.cachedAccountDetails.address &&
      profile.cachedAccountDetails.address.country === "USA";
  },
  isUS: () => {
    let profile = getProfile();
    return profile &&
      profile.cachedAccountDetails &&
      profile.cachedAccountDetails.address &&
      profile.cachedAccountDetails.address.country === "USA";
  }
}