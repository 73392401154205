import {Card, CardBody, CardHeader, Col, Row} from '@met/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {CalendarInput} from '../../../components';

const RequestedShipDate = props => {
  const handleRequestedShipDateChange = (selectedDay) => {
    if (props.onRequestedShipDateChange)
      props.onRequestedShipDateChange(selectedDay);
  };
  
  return (
    <Card data-test-id='requested-ship-date'>
      <CardHeader>
        <FormattedMessage id='checkout.requestedShipDateTitle'/>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={12} md={4}>
            <CalendarInput
              isClearable
              minDate={new Date()}
              onSelectedDateChange={handleRequestedShipDateChange}
            />
          </Col>
          <Col xs={12} md={8}>
            <div className='font-italic font-weight-light mt-2 mt-md-0 ml-md-5'>
              <FormattedMessage id='checkout.requestedShipDateMessage' />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

RequestedShipDate.propTypes = {
  onRequestedShipDateChange: PropTypes.func
};

export default RequestedShipDate;