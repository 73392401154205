import Enumerable from 'linq';
import React from 'react';
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';

import {Link, PageLoader, Stepper} from '../../components';
import queryString from '../../helpers/queryString';
import {ordersActions} from '../../store';

class Track extends React.Component {
  componentDidMount(){
    let values = queryString.parse();
    this.props.getTrackingDetails(values.o);
  }

  render() {
    const { shipment, isLoading } = this.props.trackingDetails;
    const { intl } = this.props;
    
    if (isLoading && !shipment){
      return <PageLoader />
    }
    else if (!isLoading && !shipment){
      return null;
    }
    
    let trackingStatuses = Enumerable.from(shipment.history)
      .orderByDescending(x => new Date(x.statusDate))
      .groupBy(x => new Date(x.statusDate).getDate())
      .select(x => {return {statusDate: new Date(x.first().statusDate), events: x.toArray()};})
      .toArray();

    let trackingHistory = [];
    if(trackingStatuses) {
      for (let i = 0; i < trackingStatuses.length; i++) {
        let events = trackingStatuses[i].events.map((event, index) => {
          let location = event.location && event.location.city && (
            <div className="text-uppercase">{event.location.city}, {event.location.state} {event.location.zip}</div>);

          return (
            <Row key={`history-event-${i}-${index}`} className="my-2">
              <Col xs={4} md={2}><h5><FormattedTime value={new Date(event.statusDate)}/></h5></Col>
              <Col xs={8} md={10}>
                <div className="font-weight-bold">{event.statusDetails}</div>
                {location}
              </Col>
            </Row>
          );
        });

        trackingHistory.push(
          <Card key={`tracking-history-${i}`}>
            <CardHeader>
              <FormattedDate
                value={trackingStatuses[i].statusDate}
                year='numeric'
                month='long'
                day='2-digit'
              />
            </CardHeader>
            <CardBody>
              <Container>
                {events}
              </Container>
            </CardBody>
          </Card>
        );
      }
    }
    
    return (
      <Container id='track' className='animate-bottom-fade-in content-wrapper'>
        <Breadcrumb>
          <BreadcrumbItem><Link to={`orders`} locale={this.props.match.params.locale}><FormattedMessage id='breadcrumbs.yourOrders' /></Link></BreadcrumbItem>
          <BreadcrumbItem><Link to={`orders/${shipment.orderId}`} locale={this.props.match.params.locale}><FormattedMessage id='breadcrumbs.orderDetails' /></Link></BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id='breadcrumbs.trackPackage' /></BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col xs='md'>
            <Card body className='d-flex flex-column align-items-center'>
              <h4>{shipment.carrier}</h4>
              <span className='text-muted text-uppercase'>
                <FormattedMessage id='track.carrier' />
              </span>
            </Card>
          </Col>
          <Col xs='md'>
            <Card body className='d-flex flex-column align-items-center'>
              <h4>{shipment.trackingNumber}</h4>
              <span className='text-muted text-uppercase'>
                <FormattedMessage id='track.trackingNumber' />
              </span>
            </Card>
          </Col>
          <Col xs='md'>
            <Card body className='d-flex flex-column align-items-center'>
              <h4>
                {shipment.eta ? <FormattedDate
                  value={shipment.eta}
                  year='numeric'
                  month='long'
                  day='2-digit'
                  timeZone='UTC'
                /> : 'Unknown'}
              </h4>
              <span className='text-muted text-uppercase'>
                <FormattedMessage id='track.eta' />
              </span>
            </Card>
          </Col>
        </Row>
        <Card>
          <Stepper beforeToRender={3} afterToRender={3} steps={[
            {label: intl.formatMessage({id: 'track.preTransit'}), icon: 'box', isActive: shipment.preTransit, isComplete: shipment.inTransit || shipment.ourForDelivery || shipment.delivered},
            {label: intl.formatMessage({id: 'track.inTransit'}), icon: 'truck', isActive: shipment.inTransit && !shipment.outForDelivery, isComplete: shipment.delivered || shipment.outForDelivery},
            {label: intl.formatMessage({id: 'track.outForDelivery'}), icon: 'running', isActive: shipment.outForDelivery, isComplete: shipment.delivered},
            {label: intl.formatMessage({id: 'track.delivered'}), icon: 'check', isActive: shipment.delivered},
          ]} />
        </Card>
        {trackingHistory}
        {!shipment.history && <Card body>
          <div className='my-4 text-center'>
            <h3><FormattedMessage id='track.trackingNotAvailable'/></h3>
          </div>
        </Card>}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    trackingDetails: state.orders.trackingDetails
  };
};

const mapDispatchToProps = dispatch => ({
  getTrackingDetails: orderShipmentId => dispatch(ordersActions.getTrackingDetails(orderShipmentId))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Track));