import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import {Dropdown, LoadingDots} from '@met/react-components';
import Enumerable from 'linq';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import Slider from 'react-slick';
import {Col, Container, InputGroup, Row} from 'reactstrap';
import auth from '../../auth';
import {Button, CreateWishlistModal, Link, PageLoader, ProductAvailability, ProductPrice, QuantityInput} from '../../components';
import ProductOptions from "../../components/ProductOptions";
import {calculateNewQuantity} from "../../helpers/quantityHelper";
import queryString from '../../helpers/queryString';
import user from '../../helpers/user';
import productHelper from '../../helpers/productHelper';
import {availabilityActions, cartActions, priceActions, productSearchActions, wishlistsActions} from '../../store';
import QuantityMessage from './QuantityMessage';
import CustomizeItem from '../Customize/components/CustomizeItem';

export class ProductDetails extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      quantity: 1,
      userChangedQuantity: false,
      isAdding: false,
      images: [],
      selectedColor: '',
      selectedSize: '',
      selectedCompliance: '',
      sku: '',
      skuAlias: '',
      showCreateWishlistModal: false,
      nextValidQuantityAllowed: 0,
      lowestQuantityAllowed: 0,
      highestQuantityAllowed: 0,
    };
  }
  
  componentDidMount() {
    this.getIndexProduct();
    if (user.canPlaceOrders())
      this.props.getWishlists();
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;
    
    if (prevProps.accountNumber !== this.props.accountNumber) {
      this.getIndexProduct();
    }
    
    if (prevProps.match.params.skuAlias !== this.props.match.params.skuAlias || prevProps.locale !== this.props.locale) {
      this.getIndexProduct();
    }
  }

  handleQuantityChange = (quantity) => {
    this.setState({
      ...this.state,
      userChangedQuantity: true,
      quantity
    });
  };

  handleAddToCart = () => {
    this.setState({
      ...this.state,
      isAdding: true
    }, () => {
      const { addToCart, getCartStatistics, accountNumber, isEmployeeAccount, productDetails, intl } = this.props;
      const quantity = this.state.quantity;

      let product = {
        ...productDetails.product,
        sku: this.state.sku,
        skuAlias: this.state.skuAlias
      };
      return addToCart(product, quantity, accountNumber, (!this.props.featureToggles?.EmployeeOrdersDisabled && isEmployeeAccount))
        .then(() => {
          getCartStatistics(accountNumber)
        })
        .then(() => {
          toastr.success(intl.formatMessage({id: 'cart.addToCartToastTitle'}, {quantity}), intl.formatMessage({id: 'cart.addToCartToastMessage'}, {
            quantity,
            description: product.description
          }));
        })
        .catch(() => {
          toastr.error(intl.formatMessage({id: 'cart.notAddedToCartToastTitle'}, {quantity}), intl.formatMessage({id: 'cart.notAddedToCartToastMessage'}, {quantity}), {timeOut: 0})
        })
        .finally(() => {
          this.setState({
            ...this.state,
            isAdding: false
          });
        });
    });
  };
  
  handleAddToWishlist = (option) => {
    if (option.value === '')
      this.toggleCreateWishlistModal();
    else {
      let product = this.props.productDetails.product;
      this.props.addToWishlist(option.value,
        this.state.sku,
        this.state.skuAlias,
        product.description,
        this.state.quantity,
        this.state.nextValidQuantityAllowed,
        this.state.lowestQuantityAllowed,
        this.state.highestQuantityAllowed,
        product.isCustomLogo)
        .then(() => {
          toastr.success(this.props.intl.formatMessage({id: 'wishlists.addedToWishlistTitle'}), this.props.intl.formatMessage({id: 'wishlists.addedToWishlist'}, {description: product.description}));
        })
        .catch(() => {
          toastr.error(this.props.intl.formatMessage({id: 'general.errorToastTitle'}), this.props.intl.formatMessage({id: 'wishlists.errorAddingToWishlist'}, {description: product.description}), {timeout: 0});
        });
    }
  };
  
  getIndexProduct = () => {
    const skuAlias = this.props.match.params.skuAlias;
    
    this.props.getIndexProduct(skuAlias, this.props.locale)
      .then(payload => {
        if (payload.data) {
          document.title = `${payload.data.description || 'Product Details'} | Milwaukee Connect`;

          this.setState({
            ...this.state,
            nextValidQuantityAllowed: payload.data.nextValidQuantityAllowed,
            lowestQuantityAllowed: payload.data.lowestQuantityAllowed,
            highestQuantityAllowed: payload.data.highestQuantityAllowed,
            quantity: payload.data.lowestQuantityAllowed || payload.data.nextValidQuantityAllowed || 1,
          });
        }
      });
  };
  
  getKeyFeaturesSection = () => {
    const { product } = this.props.productDetails;
    
    if (!product.featuresHtml)
      return null;
    
    return (
      <div className='mt-3' data-test-id='key-features'>
        <h4><FormattedMessage id='productDetails.keyFeatures'/></h4>
        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(product.featuresHtml)}}  />
      </div>
    );
  };
  
  getIncludesSection = () => {
    const { product } = this.props.productDetails;
    
    if (!product.includes || product.includes.length === 0 || !product.validSearchProfiles.includes("ProductsOnly")) // hide if product is MarketingOnly.
      return null;
    
    const includedWithProduct = product.includes.map((item, index) => {
      let skuLink = item.skuAlias && (
        <Link to={`products/${item.skuAlias || item.sku}`} locale={this.props.locale} className='product-details-link'>({item.skuAlias || item.sku})</Link>
      );
      
      return (
        <div key={`child-${index}`}>({item.quantity}) {item.description} {skuLink}</div>
      );
    });
    
    return (
      <div>
        <h4><FormattedMessage id='productDetails.includes'/></h4>
        {includedWithProduct}
      </div>
    );
  };
  
  getRelatedProducts = () => {
    const { product } = this.props.productDetails;
    const { locale } = this.props.match.params;
    
    if (!product.relatedProducts || product.relatedProducts.length === 0)
      return null;

    let relatedProductsSliderSettings = {
      dots: false,
      infinite: product.relatedProducts.length > 3,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    const sliderItems = product.relatedProducts.map((related, index) => {
      return (
        <Link key={`related-${index}`} to={`products/${related.skuAlias||related.sku}`} locale={locale}>
          <div className='has-background-white p-3 has-border has-border-gray'>
            <img src={`/api/v1/images?url=https://www.milwaukeetool.com${related.image || ''}`} alt={related.description}/>
            <h6 className='text-primary'>{related.description}</h6>
          </div>
        </Link>
      );
    });
    
    return (
      <Container className='has-background-light-gray related-products' fluid>
        <Container className='text-center'>
          <h4 className='text-primary mt-4'><FormattedMessage id='productDetails.youMightAlsoLike'/></h4>
          <Slider {...relatedProductsSliderSettings}>
            {sliderItems}
          </Slider>
        </Container>
      </Container>
    );
  };
  
  getSliderMarkup = () => {
    const { product } = this.props.productDetails;
    
    let images = Enumerable.from(this.state.images).take(6).toArray();  // NOTE: If you change the .take(...) here, you'll need to update the width percentage in ProductDetails.scss
    
    const settings = {
      customPaging: function(i) {
        return (
          <div className='d-flex align-items-center justify-content-center'>
            <img src={`/api/v1/images?url=https:${images[i]}`} alt={`${product.description}-${i}`} />
          </div>
        );
      },
      dots: true,
      arrows: false,
      dotsClass: 'slick-dots slick-thumb',
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    let sliderImages;
    if (images.length === 0) {
      sliderImages = (
        <div>
          <img src={`/api/v1/images`} alt={product.sku} />
        </div>
      );
    }
    else {
      sliderImages = images.map((image, index) => {
        image = queryString.update('w', '400', image);
        return (
          <div key={`slider-${index}`}>
            <img src={`/api/v1/images?url=https:${image}`} alt={product.sku} />
          </div>
        );
      });
    }
    
    return (
      <Slider {...settings} className='product-thumbs'>
        {sliderImages}
      </Slider>
    );
  };
  
  handleCreateWishlistClick = (name) => {
    this.props.newWishlist(name) 
      .then((newWishlist) => {
        toastr.success(this.props.intl.formatMessage({id: 'wishlists.wishlistCreatedTitle'}), this.props.intl.formatMessage({id: 'wishlists.wishlistCreated'}));
        this.toggleCreateWishlistModal();
        this.props.getWishlists();

        this.handleAddToWishlist({ label: name, value: newWishlist.data.id });
      })
      .catch((error) => {
        if (error.status === 409)
          toastr.error(this.props.intl.formatMessage({id: 'wishlists.wishlistAlreadyExistsTitle'}), this.props.intl.formatMessage({id: 'wishlists.wishlistAlreadyExists'}), {timeout: 0});
        else
          toastr.error(this.props.intl.formatMessage({id: 'general.errorToastTitle'}), this.props.intl.formatMessage({id: 'wishlists.errorCreatingWishlist'}), {timeout: 0});
      });
  };
  
  toggleCreateWishlistModal = () => {
    this.setState({
      ...this.state,
      showCreateWishlistModal: !this.state.showCreateWishlistModal
    });
  };

  handleSelectSku = (option) => {
    if(option) {
      // If the user has changed the quantity, try to keep it the same otherwise default to 1 to avoid issues when skus have min quantities that are multiples of each other
      const currentQuantity = this.state.userChangedQuantity || !option.lowestQuantityAllowed ? this.state.quantity : option.lowestQuantityAllowed;
      const newQuantity = calculateNewQuantity(currentQuantity, option.lowestQuantityAllowed, option.highestQuantityAllowed, option.nextValidQuantityAllowed || 1);
      this.setState({
        ...this.state,
        quantity: newQuantity,
        sku: option.sku,
        skuAlias: option.skuAlias,
        images: option.images,
        nextValidQuantityAllowed: option.nextValidQuantityAllowed,
        lowestQuantityAllowed: option.lowestQuantityAllowed,
        highestQuantityAllowed: option.highestQuantityAllowed,
      }, ()=>{
        if (auth.isAuthenticated() && option.sku) {
          this.props.getPrices(option.sku, this.props.accountNumber);
          this.props.getAvailability([option.sku], this.props.accountNumber);
        }
      });
    }
  };

  render() {
    const { product, isLoading } = this.props.productDetails;
    const wishlists = this.props.wishlists;
    const wishlistsLoading = this.props.wishlistsLoading;
    const isEmployeeAccount = this.props.isEmployeeAccount;

    if (isLoading && !product) {
      return <PageLoader/>;
    }
    const allCustomLogo = product?.isCustomLogo || (product?.options.length && product?.options.every(o => o.isCustomLogo));
    const someCustomLogoOrConfigurable = product?.isCustomLogo || product?.options.some(o => o.isCustomLogo) || product?.configurable || product?.options.some(o => o.configurable);
    const regionLocked = allCustomLogo && !user.isDPLUS();
    const isEmployeeAvailable = product?.isEmployeeAvailable && user.isMilwaukeeToolEmployee();
  
    if (!isLoading && !product) {
      return (
        <Container className='d-flex flex-column flex-grow-1 content-wrapper'>
          <Row className='my-4'>
            <Col className='text-center'>
              <h3>Details are not available for this product.</h3>
            </Col>
          </Row>
        </Container>
      );
    }
    
    let editButton = user && (user.isAdmin() || user.isProductAdmin()) && (
      <Col xs='auto' className="mt-3 mt-lg-0">
        <Button outline block color='primary' tag={Link} to={`admin/products/${product.sku}`}><FontAwesomeIcon icon='pencil-alt'/></Button>
      </Col>
    );

    let listPrice = user && !user.isMilwaukeeToolEmployee() && auth.isAuthenticated() && user.canPlaceOrders() && !allCustomLogo && (
      <div>
        <span className='has-font-size-1'>
          <FormattedMessage id='pricing.listPrice'/>&nbsp;
        </span>
        <span className='has-font-size-1' data-test-id={`product-details-list-price-${product.sku}`}>
          <del>
            <ProductPrice
              pricing={this.props.pricing}
              sku={this.state.sku}
              type='listAmount'
            />
          </del>
        </span>
      </div>
    );
    
    let yourPrice = !allCustomLogo && (
      <div>
        <div className='has-font-size-2'>
          <FormattedMessage id='pricing.yourPrice'/>
        </div>
        <div data-test-id={`product-details-your-price-${product.sku}`} className='has-font-size-4'>
          <ProductPrice
            pricing={this.props.pricing}
            sku={this.state.sku}
            type='unitAmount'
          />
        </div>
      </div>
    );
    
    let availabilityMarkup = !allCustomLogo && (
      <ProductAvailability
        availability={this.props.availability}
        sku={this.state.sku}
      />
    );

    let customLogoMarkup = this.props.featureToggles.CustomLogo && (
      <CustomizeItem className='my-3' isCustomLogo={someCustomLogoOrConfigurable} sku={this.state.sku} />
    );

    let restrictedQuantityMessage = this.state.nextValidQuantityAllowed > 0 && this.state.sku !== '' && (
      <Col xs='12' className='order-2 order-lg-1 order-xl-2 mt-2'>
        <div className='font-weight-light text-secondary has-font-size-2'>
          <FormattedMessage id='products.quantityMultiple' values={{nextValidQuantityAllowed: this.state.nextValidQuantityAllowed}} />
        </div>
      </Col>
    );

    let minQuantityMessage = this.state.lowestQuantityAllowed > 0 && this.state.sku !== '' && (
      <Col xs='12' className='order-2 order-lg-1 order-xl-2'>
        <div className='font-weight-light text-secondary has-font-size-2'>
          <FormattedMessage id='products.quantityMin' values={{lowestQuantityAllowed: this.state.lowestQuantityAllowed}} />
        </div>
      </Col>
    );

    let maxQuantityMessage = this.state.highestQuantityAllowed > 0 && this.state.sku !== '' && (
      <Col xs='12' className='order-2 order-lg-1 order-xl-2'>
        <div className='font-weight-light text-secondary has-font-size-2'>
          <FormattedMessage id='products.quantityMax' values={{highestQuantityAllowed: this.state.highestQuantityAllowed}} />
        </div>
      </Col>
    );

    let wishlistOptions = wishlists && wishlists.length > 0 ? wishlists.map(x => { return { label: x.name, value: x.id } }) : [];
    wishlistOptions.push({ label: this.props.intl.formatMessage({ id: 'wishlists.createWishlist' }), value: '' });

    if (regionLocked) {
      wishlistOptions = [];
    }
    
    let wishlistsMarkup = user.canPlaceOrders() && this.state.sku !== '' && !allCustomLogo && (wishlistsLoading ? <LoadingDots/> : (
      <Container fluid>
        <Row data-test-id='product-details-wishlist-dropdown' className='my-3'>
          <Col sm='6' md='12' lg='6'>
            <InputGroup>
              <Dropdown
                options={wishlistOptions}
                placeholder='Add to Shopping List'
                getOptionValue={(option) => (option.value)}
                getOptionLabel={(option) => (option.label)}
                onChange={(option) => this.handleAddToWishlist(option)}
                disabled={this.state.sku === '' || regionLocked}
                />
            </InputGroup>
          </Col>
        </Row>
      </Container>
    ));

    const addToCartDisabled = this.state.sku === '' || regionLocked || (!this.props.featureToggles?.EmployeeOrdersDisabled && isEmployeeAccount && !isEmployeeAvailable);

    let addToCartMarkup = user.canPlaceOrders() && !allCustomLogo && (
      <Container fluid>
        <Row className='my-2'>
          <Col xs='auto' className='mt-3 mt-lg-0'>
            <QuantityInput
              quantity={this.state.quantity}  
              incrementQuantity={this.state.nextValidQuantityAllowed}
              minQuantity={this.state.lowestQuantityAllowed}
              maxQuantity={this.state.highestQuantityAllowed}
              onQuantityChange={this.handleQuantityChange}
              dataTestId={this.state.sku}
            />
          </Col>
          {restrictedQuantityMessage}
          {minQuantityMessage}
          {maxQuantityMessage}
          <QuantityMessage caseQuantity={product?.caseMetadata?.conversionFactor} palletQuantity={product?.palletMetadata?.conversionFactor} />
          <Col xs='auto' className='mt-3 mt-lg-0'>
            <Button data-test-id='add-to-cart' outline color='primary' onClick={this.handleAddToCart} isLoading={this.state.isAdding} isDisabled={addToCartDisabled}>
              <FormattedMessage id='search.addToCart'/>
            </Button>
          </Col>
          {editButton}
        </Row>
      </Container>
    );
    let eachUPC = product?.eachUnitMetadata?.upc
    let skuAliasMarkup = product && product.skuAlias !== product.description && (!product.options || product?.options.length === 0) && (
      <div data-test-id={`product-details-sku-${product.sku}`} className='mb-2 mr-lg-3'>
        <b>SKU:</b> {product.skuAlias}
        &emsp;
        {eachUPC && (
          <>
            <b>Each UPC:</b> {eachUPC}
          </>
        )}
      </div>
    );

    let messageMarkup = productHelper.getMessageMarkup(product);

    let createWishlistModalMarkup = this.state.showCreateWishlistModal && (
      <CreateWishlistModal
        isOpen={this.state.showCreateWishlistModal}
        toggle={this.toggleCreateWishlistModal}
        handleCreateClick={this.handleCreateWishlistClick}
        isProcessing={this.props.createWishlistIsProcessing}
      />
    );
    
    return (
      <div id='product-details' className='animate-bottom-fade-in d-flex flex-column flex-grow-1'>
        <Container className='d-flex flex-column flex-grow-1 content-wrapper'>
          <Row>
            <Col xs={12} md={6}>
              {this.getSliderMarkup()}
            </Col>
            <Col>
              {skuAliasMarkup}
              <h2 data-test-id={`product-details-name-${product.sku}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(product.description)}}/>
              <ProductOptions 
                product={product} 
                setSku={this.handleSelectSku} 
                currentSku={this.state?.sku ? this.state?.sku : null} 
                initialSkuAlias={this.props.match.params.skuAlias} 
                isProductDetail 
                allowEmployeePurchase={!this.props.featureToggles?.EmployeeOrdersDisabled && isEmployeeAccount && isEmployeeAvailable}
              />
              <div className='my-3'>
                {customLogoMarkup}
                {listPrice}
                {yourPrice}
                <div data-test-id={`product-details-availability-${product.sku}`} className='has-font-size-2 font-weight-bold'>
                  {availabilityMarkup}
                </div>
              </div>
              {addToCartMarkup}
              {wishlistsMarkup}
              {messageMarkup}
              {this.getKeyFeaturesSection()}
              {this.getIncludesSection()}
            </Col>
          </Row>
        </Container>
        {this.getRelatedProducts()}
        {createWishlistModalMarkup}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    productDetails: state.productSearch.getIndexProduct,
    pricing: state.pricing,
    availability: state.availability,
    locale: state.locales.locale,
    accountNumber: state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber,
    isEmployeeAccount: state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.isEmployeeAccount : state.account.currentAccount.accountDetails.isEmployeeAccount,
    wishlistProcessing: state.wishlists.addToWishlist ? state.wishlists.addToWishlist.isProcessing : false,
    wishlists: state.wishlists.getWishlists.wishlists,
    wishlistsLoading: state.wishlists.getWishlists.isLoading,
    addToWishlistIsProcessing: state.wishlists.addToWishlist.isProcessing,
    createWishlistIsProcessing: state.wishlists.newWishlist.isProcessing,
    featureToggles: state.configuration.featureToggles
  };
};

const mapDispatchToProps = dispatch => ({
  getIndexProduct: (sku, locale) => dispatch(productSearchActions.getIndexProduct(sku, locale)),
  getPrices: (skus,accountNumber) => dispatch(priceActions.getPrices(skus,accountNumber)),
  getAvailability: (skus,accountNumber) => dispatch(availabilityActions.getAvailability(skus,accountNumber)),
  addToCart: (product, quantity, accountNumber, isEmployeeAccount) => dispatch(cartActions.addToCart(product, quantity, accountNumber, undefined, isEmployeeAccount)),
  getCartStatistics: (accountNumber) => dispatch(cartActions.getCartStatistics(accountNumber)),
  getWishlists: () => dispatch(wishlistsActions.getWishlists()),
  addToWishlist: (wishlistId, sku, skuAlias, description, quantity, nextValidQuantityAllowed, lowestQuantityAllowed, highestQuantityAllowed, isCustomLogo) => dispatch(wishlistsActions.addToWishlist(wishlistId, sku, skuAlias, description, quantity, nextValidQuantityAllowed, lowestQuantityAllowed, highestQuantityAllowed, isCustomLogo)),
  newWishlist: (name) => dispatch(wishlistsActions.newWishlist(name))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductDetails));
