import addressManagement from './addressManagement/en';
import productAdminReason from './productAdminReason/en';
import checkout from './checkout/en';
import faq from './faq/en';
import square from './square/en';

export default {
  account: {
    accountNotFound: 'Account Not Found',
    accountLoadingError: 'There were issues retrieving your account details. You may experience some difficulties while using Connect. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    accountImpersonationLoadingError: 'There were issues retrieving details for the account to be impersonated.'
  },
  addressManagement: addressManagement,
  announcements: {
    heading: 'Heading',
    startDate: 'Start Date',
    endDate: 'End Date',
    priority: 'Priority',
    actions: 'Actions',
    dateRange: 'Date Range',
    audience: 'Audience',
    isBanner: 'Homepage Alert',
    noAnnouncementsMessage: 'No announcements found',
    currentAnnouncements: 'Current Announcements',
    addButton: '+ Add',
    confirmDeleteModalTitle: 'Are you sure?',
    confirmDeleteModalBody: 'Are you sure you want to delete this announcement? This action can\'t be undone.',
    confirmDeleteButton: 'I\'m Sure - Delete',
    newAnnouncementTitle: 'New Announcement',
    updateAnnouncementTitle: 'Update Announcement',
    errorDeletingTitle: 'Error while deleting announcement',
    errorDeletingMessage: 'There was a problem while deleting the announcement. Please try again later.',
    errorSavingTitle: 'Error while saving announcement',
    errorSavingMessage: 'There was a problem while saving the announcement. Please try again later.',
    headingPlaceholder: 'Announcement Heading',
    headingValidationMessage: 'An announcement heading is required',
    summaryPlaceholder: 'A brief summary of the announcement',
    summaryValidationMessage: 'A summary must be between 1 and 280 characters',
    editorValidationMessage: 'You must provide announcement details',
    editorTitle: 'Details'
  },
  brandworks: {
    sproutloudFailureTitle: 'Sprout Loud Login Failure',
    pinnacleFailureTitle: 'Milwuakee SWAG Login Failure',
    flyerserviceFailureTitle: 'Flyer Service Login Failure',
    ssoFailure: 'There was a problem accessing the third-party provider. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).'
  },
  breadcrumbs: {
    dashboard: 'Dashboard',
    yourOrders: 'Your Orders',
    orderDetails: 'Order Details',
    trackPackage: 'Track Package'
  },
  cart: {
    addedToCart: 'Added to Cart',
    errorAddingToCart: 'An error occurred adding your items to your cart',
    emptyCartMessage: 'Your cart is empty!',
    addToCartToastTitle: '{quantity, plural, one {Product} other {Products}} Added To Cart',
    addToCartToastMessage: '({quantity, number}) "{description}" {quantity, plural, one {has} other {have}} been added to your cart.',
    notAddedToCartToastTitle: '{quantity, plural, one {Product} other {Products}} Not Added To Cart',
    notAddedToCartToastMessage: 'There was an issue adding the {quantity, plural, one {Product} other {Products}} to your cart - please try again later.',
    errorRetreivingStatuses: 'There was an issue retrieving availability for items in the Cart.',
    cartExpiredTitle: 'Cart prices are good for 30 days.',
    cartExpiredMessage: 'Some/All items in your cart have been there for over 30 days. The amount(s) will be refreshed to reflect the current pricing.',
    accountIssues: 'There is an issue with your account that is preventing you from checking out. Please contact Customer Experience at 800-729-3878 to resolve this issue.'
  },
  checkout: checkout,
  confirmation: {
    thankYou: 'Thanks for shopping with us',
    explanations: {
      separateShipments: 'We\'ll send you a confirmation with tracking information once your order ships. If you ordered multiple items, they may come in separate shipments.',
      backordered: 'If your order contains backordered items, once the product becomes available you will receive a shipping confirmation email.'
    },
    needHelp: {
      title: 'Need help with your order?',
      howToTrack: 'How do I track my order?',
      canICancel: 'Can I cancel or change my order?',
      orderArrive: 'When will my order arrive?'
    },
    talkToSomeone: 'Talk to someone'
  },
  customLogo: {
    title: 'Customizations',
    customizeProduct: 'Customize Your Product',
    customizeProductDescription: `Milwaukee Tool safety vests, hard hats, and helmets can be customized with images, logos, and text. Learn more about placing a custom product order {customizerFAQLink}.`,
    faqLinkText: 'here',
    startCustomizing: 'View or Start Customization',
    customize: 'Customize',
    goToCustomizer: 'Go To Customizer',
    customizeMessage: 'Add your logo, name, or other artwork to item.',
    createStart: 'To view or create a new custom product design, start here',
  },
  faq: faq,
  footer: {
    copyright: '© {year} by Milwaukee Tool - All Rights Reserved',
    ccpa: 'Do Not Sell My Personal Information',
    privacy: 'Privacy'
  },
  general: {
    activate: 'Activate',
    actions: 'Actions',
    amount: 'Amount',
    areYouSure: 'Are You Sure?',
    cancel: 'Cancel',
    cart: 'Cart',
    catalogNumber: 'Catalog #',
    close: 'Close',
    comments: 'Comments',
    customer: 'Customer',
    date: 'Date',
    delete: 'Delete',
    description: 'Description',
    edit: 'Edit',
    email: 'Email',
    endDate: 'End Date',
    errorToastTitle: 'Something Went Wrong',
    exempt: 'Exempt',
    free: 'Free',
    id: 'ID',
    invoices: 'Invoices',
    item: 'Item',
    itemNumber: 'Item Number',
    linkCopiedToastTitle: 'Link Copied',
    linkCopied: 'Link copied to clipboard',
    orders: 'Orders',
    orderDate: 'Order Date',
    orderDetails: 'Order Details',
    orderNumber: 'Order #',
    orderSummary: 'Order Summary',
    pending: 'Pending',
    phone: 'Phone',
    purchaseDate: 'Purchase Date',
    purchaseOrderNumber: 'Purchase Order #',
    purchaseOrderNumberAbbrev: 'PO #',
    emailConfirmation: 'Contact Email',
    quantity: 'Quantity',
    quantityAbbr: 'Qty',
    receipt: 'Receipt',
    refresh: 'Refresh',
    total: 'Total',
    salesTax: 'Sales Tax',
    pricingError: 'Error',
    serialNumber: 'Serial #',
    share: 'Share',
    shipping: 'Shipping',
    shippingCost: 'Shipping Cost',
    shippingSpeed: 'Shipping Speed',
    shipTo: 'Ship To',
    sku: 'SKU',
    startDate: 'Start Date',
    status: 'Status',
    subtotal: 'Subtotal',
    successToastTitle: 'Success!',
    unknown: 'Unknown',
    unauthorized: 'Unauthorized',
    validationToastTitle: 'Please fix required fields',
    viewOrder: 'View Order',
    customerStatementErrorTitle: 'Error getting the customer statement',
    customerStatementErrorBody: 'There was a problem accessing your customer statement. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    noSearchResults: 'Your search returned no results',
  },
  home: {
    title: 'Find a Product',
    orderInformationCardTitle: 'Order Information',
    whatsNewCardTitle: 'What\'s New',
    announcementsCardTitle: 'Announcements',
    employeePurchaseCardTitle: 'Employee Purchase',
    notificationsCardTitle: 'Notifications',
    brandworksCardTitle: 'Marketing Quick Links',
    enterAccountNumber: 'Enter Account Number',
    accountActivationPending: 'Account Activation Pending',
    activateAccountDescription: 'Enter your account number here for the full Connect experience.',
    accountActivationRequestSent: 'Your account activation request has been sent to Customer Support. You will receive an email when your account has been activated.',
    activationErrorTitle: 'Account Activation Failed',
    activationErrorDescription: 'There was an issue while trying to activate your account. If this issue persists, please contact the Customer Support.',
    autoAccountActivationTitle: 'Account Activation Completed',
    autoAccountActivationDescription: 'Your account activation has been completed. In 5 seconds, the browser will refresh to update your session.',
    autoAccountActivationFeedbackTitle: 'Account Activation Completed',
    autoAccountActivationFeedbackDescription: 'Your account activation request has been processed. Please sign out and sign in again in a few minutes for changes to take effect.'
  },
  invoices: {
    loadingInvoicesErrorMessage: 'An error occurred while retrieving your invoices. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    noInvoicesMessage: 'No invoices found',
    downloadingInvoice: 'Downloading invoice {invoiceId}...',
    downloadingInvoiceErrorMessage: 'There was a problem accessing your invoice. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    invalidInvoiceData: 'Invalid invoice data, unable to download.',
  },
  orders: {
    noOrdersMessage: 'No orders found',
    ordersFilter: 'All',
    openOrders: 'Open',
    backordered: 'Backordered',
    cancelled: 'Cancelled',
    invoicedFilter: 'Invoiced',
    orderDateLabel: 'Order Date',
    purchaseOrderLabel: 'Purchase Order',
    quoteNumberLabel: 'Quote Number',
    orderNumberLabel: 'Order #',
    shippingStatusLabel: 'Shipping Status',
    orderProcessingMessage: 'Order is currently being processed. You will receive an order number and email notification once processing has been completed.',
    skuColumn: 'Sku',
    quantityColumn: 'Qty',
    shipTo: 'Ship To',
    trackPackage: 'Track Package',
    packingSlip: 'Packing Slip',
    downloadingPackingSlip: 'Downloading packing slip...',
    packingSlipDownloadErrorBody: 'There was a problem getting your Packing Slip. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    excelDownloadErrorTitle: 'Problem downloading excel',
    excelDownloadErrorBody: 'There was a problem downloading the order history excel. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    reorder: 'Reorder',
    reorderToastTitle: 'Products from order {orderNumber} added to cart',
    reorderErrorToastTitle: 'There was an issue adding the products from order {orderNumber} to your cart - please try again later.',
    downloadingWarrantyClaims: 'Downloading warranty claims...',
    warrantyClaimsExcelDownloadError: 'There was a problem downloading the order history excel. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    employeeOrdersDisabled: 'Due to unprecedented demand, we are not accepting employee orders at this time.',
    orderLoadingError: 'Error occurred accessing order. Please try again later.',
    unauthorizedToView: 'You are not authorized to view this order.'
  },
  orderSummary: {
    backorderTooltip: 'You will not be charged for these items until they arrive in stock and are shipped',
    itemsShipped: 'Items Shipped',
    itemsToBeShipped: 'Items to be Shipped',
    itemsOnBackorder: 'Items on Backorder',
    shippingToBeCalculated: 'Shipping is calculated when items have shipped',
    freeShipping: 'This order qualifies for free shipping',
    taxExempt: 'This order qualifies for tax exempt status',
    taxToBeCalculated: 'Tax will be calculated after order is processed'
  },
  pricing: {
    signInForPrice: 'Sign in to see your price',
    netUnitPrice: 'Net Unit Price',
    listPrice: 'List Price',
    unitPrice: 'Unit Price',
    yourPrice: 'Your Price',
    quantity: 'Quantity',
    extendedPrice: 'Extended Price',
    extendedPriceAbbr: 'Ext Price',
    reimbursementPrice: 'Reimbursement Price',
    accountNeedsAccess: 'Your account is not allowed to place orders',
    selectOptions: 'Select options'
  },
  products: {
    eachWeight: 'Weight',
    totalWeight: 'Total Weight',
    backordered: 'Backordered',
    unavailable: 'Unavailable',
    discontinuedInStock: 'Discontinued - In Stock',
    discontinuedOutOfStock: 'Discontinued - Out of Stock',
    inStock: 'In Stock',
    other: 'Other',
    quantityMultiple: 'Must be ordered in multiples of {nextValidQuantityAllowed}',
    quantityMin: 'Must have minimum order of {lowestQuantityAllowed}',
    quantityMax: 'Must have maximum order of {highestQuantityAllowed}',
    LeadTime7Days: '7 Day Lead Time',
    LeadTime7To14Days: '14 to 21 Day Lead Time',
    PreSale: 'Pre-Sale',
    caseQuantity: 'Master Pack Quantity for this product is {caseQuantity}',
    palletQuantity: 'Pallet Quantity for this product is {palletQuantity}'
  },
  productAdminReason: productAdminReason,
  productDetails: {
    keyFeatures: 'Key Features',
    includes: 'Includes',
    youMightAlsoLike: 'You Might Also Like'
  },
  publications: {
    downloadingFile: 'Downloading {filename}...',
    effectiveDate: 'effective {date}',
    partsPricingTitle: 'Distribution Pricing',
    toolAndAccessoryPrices: 'Tool & Accessory Prices',
    servicePartsPrices: 'Service Parts Prices',
    iMap: 'IMAP',
    iMapPolicy: 'IMAP Policy',
    servicePublicationsTitle: 'Service Publications',
    technicalPublicationsTitle: 'Technical Publications',
    technicalPublicationsDescription: 'Service parts list bulletins, wiring instructions, and operators manuals can generally be obtained here.',
    technicalPublicationsButton: 'Search for Technical Publications',
    lightningMaxRepairPriceSheetTitle: 'Lightning Max Repair Price Sheet',
    lightningMaxRepairPriceSheetDescription: 'Price list for the Lightning Max Repair program for calibration, maintenance, and non-warrantable service orders.',
    thirtyDayExclusionListTitle: '30 Day Exclusion List',
    thirtyDayExclusionListDescription: 'List of items that are excluded from our 30 Day Satisfaction return policy.',
    disclaimerTitle: 'Disclaimer',
    disclaimerDescription: 'The prices and information contained in these files are subject to change without notice. Please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878) with any questions pertaining to service parts. We appreciate your business and your continued partnership with us in servicing the needs of our customers.',
    documentSearchTitle: 'ONE KEY and MX FUEL Service Parts Publications and Videos',
    documentSearchDescription: 'Enter model number to search for SPLs and training material in the field below',
    documentTableName: 'Name',
    documentTableDate: 'Date Uploaded',
    documentTableDownload: 'Download',
    sales: 'Sales Policy',
    internet: 'Internet Distributor Policy',
    coOpPolicies: 'Co-Op Advertising Allowance Policy',
    termsOfSale: 'Terms of Sale',
    salesPolicies: 'Milwaukee Sales Policies'
  },
  search: {
    addToCart: 'Add To Cart',
    removeFromCart: 'Remove',
    searchPlaceholder: 'Product Name or #',
    sizes: 'Sizes',
    colors: 'Colors',
    sku: 'Sku',
    PackQuantity: 'Pack Quantity',
    LensColor: 'Lens Color',
    compliance: 'Compliance',
    selectAColor: 'Please select a color first',
    selectCompliance: 'Please select compliance first',
    noSizesFound: 'No sizes found for selected options.',
    options: 'Options'
  },
  square: square,
  track: {
    carrier: 'Carrier',
    trackingNumber: 'Tracking Number',
    eta: 'Est. Delivery Date',
    preTransit: 'Pre-Transit',
    inTransit: 'In Transit',
    outForDelivery: 'Out for Delivery',
    delivered: 'Delivered',
    trackingNotAvailable: 'Tracking is not currently available for this shipment',
    shipped: 'Shipped'
  },
  warranty: {
    activeWarrantyClaims: 'Active Warranty Claims',
    totalWarrantyClaims: 'Total Warranty Claims',
    autoApprovalViolation: {
      valueThreshold: 'Part value exceeds threshold',
      laborHoursThreshold: 'Labor hours exceeds threshold',
      obsoleteItem: 'Contains obsolete item',
      repairDurationThreshold: 'Repair duration exceeds threshold',
      partQuantityThreshold: 'Part quantity exceeds threshold',
      freightAllowanceThreshold: 'Freight allowance exceeds threshold',
      reviewSkuPrefix: 'Always review SKU prefix',
      reviewDefectivePartClaim: 'Always review defective part claim',
      initialViolation: 'Initial Auto Approve Violations',
      discontinuedPart: '{itemNumber} is discontinued',
      replacementPart: '{itemNumber} has been replaced with {replacementSku}',
      backOrderedPart: '{itemNumber} is backordered',
      backOrderedPartWithDate: '{itemNumber} is backordered til {date}'
    },
    claimStatus: {
      draft: 'Draft',
      pending: 'Pending',
      pendingInitialReview: 'Pending Initial Review',
      approved: 'Approved',
      approvedWithChanges: 'Approved With Changes',
      rejected: 'Rejected',
      pendingSubsequentReview: 'Pending Subsequent Review',
      denied: 'Denied',
      pendingAutoApproval: 'Pending Auto Approval'
    },
    partLineType: {
      warranty: 'Warranty',
      recall: 'Recall',
      goodwill: 'Goodwill'
    },
    shippedType: {
      notAvailable: 'Not Available',
      customerDropOffOrPickup: 'Customer Drop-Off/Pickup',
      companyTruck: 'Company Truck',
      carrierFreight: 'Common Carrier/Freight'
    },
    partsValidation: {
      notInCatalog: '{itemNumber} is not in our online catalog',
      invalidTool: '{itemNumber} is not valid as a tool on a warranty claim',
      invalidComponent: '{itemNumber} is not valid as a component on a warranty claim',
      unauthorizedRepair: 'Service Center is not authorized to repair {itemNumber}',
      flatRatePriceNotSet: 'There is a problem with the model number you entered. Please confirm the model number matches the tool you are repairing. Should you need additional assistance please email warrantyclaims@milwaukeetool.com include the model number you are trying to enter in your email.'
    },
    noClaimsMessage: 'No warranty claims found',
    accountSelection: 'Account Selection',
    eligibility: 'Eligibility',
    logistics: 'Logistics',
    repairDetails: 'Repair Details',
    customerDetails: 'Customer Details',
    claimReview: 'Claim Review',
    claimConfirmation: 'Claim Confirmation',
    commentsPlaceholder: 'Add additional comments',
    pricingMessage: 'Save changes to get pricing',
    claimInformation: 'Claim Information',
    expenseSummary: 'Expense Summary',
    repairNotes: 'Repair Notes',
    ascInformation: 'ASC Information',
    customerInformation: 'Customer Information',
    repairInformation: 'Repair Info',
    photos: 'Photos',
    productInformation: 'Product Information',
    reviewInformation: 'Review Information',
    shipping: {
      information: 'Shipping Information',
      batteryNotice: 'NOTICE: Any claims for Batteries require shipping the Batteries back to Milwaukee Tool for inspection.',
      batteryPrint: 'Please print out the documents below and follow the instructions to ship the Batteries to Milwaukee Tool.',
      labelPrint: 'Print Shipping Label and Instructions'
    },
    review: {
      errorTitle: 'Error submitting claim',
      requireComments: 'Please provide comments explaining your changes',
      invalidStatusChange: 'Status cannot change from {oldStatus} to {newStatus}'
    },
    submissionError: {
      dateReceivedOutOfRange: 'Date received must be within 1 year of today',
      dateRepairedOutOfRange: 'Date repaired must be within 1 year of today',
      dateReturnedOutOfRange: 'Date returned must be within 1 year of today',
      freightAllowanceRequired: 'Freight allowance required',
      primaryPartRequired: 'Primary part required',
      productSkuRequired: 'Catalog number required',
      receivedViaRequired: 'Received via required',
      returnedViaRequired: 'Returned via required',
      warrantyLaborRequired: 'Warranty labor required',
      warrantyLaborOutOfRange: 'Warranty labor must be between 0 and 100',
      notCertifiedAccurate: 'Please certify that the data is accurate',
      notCertifiedSafety: 'Please certify all test and checks have been completed',
      returnShippingIncomplete: 'Please include return shipping provider and tracking number',
      serialNumberRequired: 'Serial number is require if legible',
      duplicateParts: 'There are duplicate parts on your claim, please remove the duplicates to proceed',
      warrantyLaborPrecision: 'Warranty labor must be rounded to the nearest tenth',
      laborHoursExceededForToolReplacement: 'Labor hours exceed allotted for tool replacement. Only 0.3 is allowed for tool replacements.',
      onlyToolAllowedAsPart: 'Tool replacement claims can only have the tool listed as a part',
      receiptRequired: 'Receipt is required for claims with illegible serial numbers',
      purchaseDateRequired: 'Purchase date is required for claims with illegible serial numbers',
      noCostWarrantyClaim: 'Warranty Claim must be for a dollar amount greater than zero. Please verify and adjust your parts and values.',
      freightAllowanceGreaterThanDailyAmount: 'Maximum of {amountRemaining} remaining for Company Truck deliveries to this customer on the chosen day.',
      customerIncomplete: 'Customer information is incomplete',
      notAcceptingBatteryClaims: 'Battery Claims not allowed at this time',
      skuDoesNotExist: 'Sku {itemNumber} does not exist',
      skuIsNotEligible: 'Sku {itemNumber} is not eligible for defective part claim',
      partNotInCatalog: 'Some of the parts are not in the catalog',
      toolNotValidClaim: 'Invalid tool claim',
      componentNotValidClaim: 'Invalid claim component',
      accountNotAuthorized: 'Product line not authorized for this account',
      flatRatePriceNotSet: 'The flat rate price is not set for that item'
    },
    newComment: {
      errorTitle: 'An error occurred',
      errorMessage: 'An error occurred when submitting your comments, please try again later'
    },
    button: {
      saveChanges: 'Save Changes',
      addPart: 'Add Part',
      newClaim: 'New Claim',
      addComment: 'Add Comment',
      resubmit: 'Resubmit',
      deny: 'Deny',
      reject: 'Reject',
      approve: 'Approve',
      download: 'Download',
      confirm: 'Confirm'
    },
    archive: {
      archiveModalTitle: 'Archive Claim',
      archiveModalBody: 'Are you sure you want to archive this claim?',
      unarchiveModalTitle: 'Unarchive Claim',
      unarchiveModalBody: 'Are you sure you want to unarchive this claim?',
      archiveSuccessToastTitle: 'Claim Archived',
      archiveSuccessToastMessage: 'Claim # {claimNumber} has been archived',
      unarchiveSuccessToastTitle: 'Claim Unarchived',
      unarchiveSuccessToastMessage: 'Claim # {claimNumber} has been unarchived',
      errorToastTitle: 'An error occurred',
      archiveErrorToastMessage: 'An error occurred while archiving this claim, please try again later',
      unarchiveErrorToastMessage: 'An error occurred while unarchiving this claim, please try again later',
    }
  },
  warrantyAutoApproveFilters: {
    currentFilters: 'Active Auto Approve Filters',
    errorAddingFilterTitle: 'Error adding filter',
    errorAddingMessage: 'There was a problem while adding the filter. Please try again later.',
    errorSavingFilterTitle: 'Error save changes',
    errorSavingMessage: 'There was a problem while saving the filter. Please try again later.',
    errorDeletingFilterTitle: 'Error deleting filter',
    errorDeletingMessage: 'There was a problem while deleting the filter. Please try again later.',
    errorFilterTypeAndValueRequired: 'The type and value fields are required to save a filter',
    confirmDeleteModalTitle: 'Are you sure?',
    confirmDeleteModalBody: 'Are you sure you want to delete this filter? This action can\'t be undone.',
    confirmDeleteButton: 'I\'m Sure - Delete',
    actions: 'Actions',
    type: 'Type',
    value: 'Value'
  },
  contact: {
    title: 'Contact Us',
    yourInfo: 'Your Information',
    yourCompanyInfo: 'Your Company Information',
    custService: 'Customer Service',
    generalPhone: 'General Phone',
    fax: 'Fax',
    address: 'Address',
    emailForm: 'Email form',
    subject: 'Subject',
    selectSubject: 'Select a Subject',
    comments: 'Comments',
    userInstructions1: 'A Milwaukee Tool representative will respond to your request by the next business day.',
    userInstructions2: 'When ordering advertising materials or literature (catalogs, DPLs, etc.) please specify the quantity and items you wish to receive. Materials will be shipped to the account that is currently logged into, please change as needed. This will not change the information in our system, just the name and location for this request.',
    sentReqItemsTo: 'Send Requested Items to',
    custName: 'Customer Name',
    companyName: 'Company Name',
    zipCode: 'Zip/Postal Code',
    city: 'City',
    state: 'State/Provence',
    phone: 'Phone',
    email: 'Email',
    optional: 'Optional',
    changeShippingInfo: 'Change Shipping Info',
    thanksForYourRequst: 'Thank you for your request.',
    repWillRespond: 'A Milwaukee Tool representative will respond to your request by the next business day.',
    subjectInventory: 'Inventory',
    subjectOrderDpl: 'Order Dpl',
    subjectPricing: 'Pricing',
    subjectOrderStatus: 'Order Status',
    subjectInvoice: 'Invoice',
    subjectAccountBalance: 'Account Balance',
    subjectProdTech: 'Product/Technical',
    subjectProductService: 'Product Service',
    subjectAdvertLitReq: 'Advertising Literature Requests',
    subjectWarrantyClaims: 'Warranty Claims',
    errorSubmittingForm: 'Error submitting form.'
  },
  quickAdd: {
    title: 'Quick Add',
    invalidProduct: '{skuOrEachUpc} cannot be found or is not valid',
    invalidQuantity: 'Invalid quantity entered',
    invalidQuantityNextQuantityAllowed: '. Must be ordered in multiples of {nextQuantityAllowed}',
    invalidQuantityLowestQuantityAllowed: '. Must have minimum order of {lowestQuantityAllowed}.',
    validationMessage: 'Please enter a valid SKU or Each UPC, and Quantity',
    productPlaceholder: 'SKU or Each UPC',
    goToCart: 'Go to Cart',
    multipleSkusMessage: 'Multiple SKUs found for that product: {productSkus}',
    errorQuickAddToCart: 'An error occurred while adding a new product to the cart'
  },
  wishlists: {
    noWishlists: 'You have no shopping lists!',
    emptyWishlist: 'Your shopping list is empty!',
    addedToWishlistTitle: 'Added to Shopping List',
    addedToWishlist: '"{description}" has been added to your shopping list',
    errorAddingToWishlist: 'An error occurred adding {description} to your shopping list',
    wishlistLineAddedToCartTitle: 'Added to Cart',
    wishlistLineAddedToCart: '{description} was added to your cart!',
    errorAddingWishlistLineToCart: 'An error occurred adding {description} to your cart',
    removedFromWishlistTitle: 'Shopping List Updated',
    removedFromWishlist: '{description} was removed from your shopping list!',
    errorRemovingFromWishlist: 'An error occurred removing {description} from your shopping list',
    wishlistUpdatedTitle: 'Shopping List Updated',
    wishlistUpdated: 'Your shopping list was updated!',
    wishlistAlreadyExistsTitle: 'Shopping List Already Exists',
    wishlistAlreadyExists: 'A shopping list with that name already exists',
    errorUpdatingWishlist: 'An error occurred updating your shopping list',
    wishlistDeletedTitle: 'Shopping List Deleted',
    wishlistDeleted: 'Your shopping list was deleted!',
    errorDeletingWishlist: 'An error occurred deleting your shopping list',
    wishlistCreatedTitle: 'Shopping List Created',
    wishlistCreated: 'Your shopping list was created!',
    errorCreatingWishlist: 'An error occurred creating your shopping list',
    wishlistAddedToCartTitle: 'Added to Cart',
    wishlistAddedToCart: 'Your shopping list items were added to your cart!',
    errorAddingWishlistToCart: 'An error occurred adding your shopping list to your cart',
    wishlistSubtotal: 'Shopping List Subtotal',
    yourWishlists: 'Your Shopping Lists',
    wishlistDetails: 'Shopping List Details',
    editWishlist: 'Edit Shopping List',
    createWishlist: 'Create New',
    errorUpdatingQuantity: 'An error occurred updating the quantity',
    errorRemovingFromSavedForLater: 'An error occurred removing {description} from your saved for later',
  },
  restrictedItems: {
    warningForDeletingRestrictedItemsFromCart: 'You had restricted item(s) in your cart that were removed. Please review your cart before submitting.'
  },
  multishipment: {
    multishipmentWarningMessage: `
      This cart contains items that will result in multiple-site shipments.
      Items shipping from multiple sites need to be placed on separate orders.
      Use the 'Save Group For Later' button to remove Multi-Site Shipment items from your cart.
      To place orders for the removed 'Multi-Site Shipment' items: create a new cart, add the saved group back to the cart, and complete your order.
  `,
    multiSiteShipment: `Multi-Site Shipment Items`
  },
  creditCard: {
    confirmDeleteModalTitle: 'Delete Card',
    confirmDeleteModalBody: 'Are you sure you want to delete your card ending in {cardLast4}?',
    confirmDeleteButton: 'Delete Card',
  }
}