import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from '../../components';

const WarrantyClaimToggleArchivedModal = (props) => {
    const { isOpen, isArchived, onConfirm, close, isLoading } = props;
    const modalTitle = `warranty.archive.${isArchived ? 'unarchiveModalTitle' : 'archiveModalTitle'}`;
    const modalBody = `warranty.archive.${isArchived ? 'unarchiveModalBody' : 'archiveModalBody'}`;

    return (
        <Modal isOpen={isOpen} centered={true}>
            <ModalHeader>
                <FormattedMessage id={modalTitle} />
            </ModalHeader>
            <ModalBody>
                <div>
                    <FormattedMessage id={modalBody} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button isDisabled={isLoading} color='secondary' onClick={close}>
                    <FormattedMessage id='general.cancel' />
                </Button>{' '}
                <Button isLoading={isLoading} color='primary' onClick={onConfirm}>
                    <FormattedMessage id='warranty.button.confirm' />
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default WarrantyClaimToggleArchivedModal