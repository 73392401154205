import addressManagement from './addressManagement/es';
import checkout from './checkout/es';
import faq from './faq/es';
import square from './square/es';

export default {
  account: {
    accountNotFound: 'Cuenta no encontrada',
    accountLoadingError: 'Hubo problemas al recuperar los detalles de su cuenta. Puede experimentar algunas dificultades mientras usa Connect. Si el problema persiste, comuníquese con el Servicio al cliente global al 1-800-SAWDUST (1-800-729-3878).',
    accountImpersonationLoadingError: 'Hubo problemas al recuperar detalles para que la cuenta se suplante.'
  },
  addressManagement: addressManagement,
  announcements: {
    heading: 'Título',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    priority: 'Prioridad',
    actions: 'Comportamiento',
    dateRange: 'Rango de fechas',
    audience: 'Audiencia',
    isBanner: 'Alerta de página de inicio',
    noAnnouncementsMessage: 'No se encontraron anuncios.',
    currentAnnouncements: 'Anuncios actuales',
    addButton: '+ Añadir',
    confirmDeleteModalTitle: '¿Estás seguro?',
    confirmDeleteModalBody: '¿Seguro que quieres eliminar este anuncio? Esta acción no se puede deshacer.',
    confirmDeleteButton: 'Estoy seguro - Eliminar',
    newAnnouncementTitle: 'Nuevo anuncio',
    updateAnnouncementTitle: 'Anuncio de actualización',
    errorDeletingTitle: 'Error al eliminar el anuncio',
    errorDeletingMessage: 'Hubo un problema al eliminar el anuncio. Por favor, inténtelo de nuevo más tarde.',
    errorSavingTitle: 'Error al eliminar anuncio',
    errorSavingMessage: 'Hubo un problema al guardar el anuncio. Por favor, inténtelo de nuevo más tarde.',
    headingPlaceholder: 'Título del anuncio',
    headingValidationMessage: 'Se requiere un encabezado de anuncio',
    summaryPlaceholder: 'Un breve resumen del anuncio.',
    summaryValidationMessage: 'Un resumen debe tener entre 1 y 280 caracteres.',
    editorValidationMessage: 'Debes proporcionar detalles del anuncio',
    editorTitle: 'Detalles'
  },
  brandworks: {
    sproutloudFailureTitle: 'Error de inicio de sesión de Sproutloud',
    pinnacleFailureTitle: 'Error de inicio de sesión de SWAG de Milwaukee',
    flyerserviceFailureTitle: 'Falla de inicio de sesión del servicio de viajero',
    ssoFailure: 'Hubo un problema al acceder al proveedor externo. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, comuníquese con el Servicio al cliente global al 1-800-SAWDUST (1-800-729-3878).'
  },
  breadcrumbs: {
    dashboard: 'Tablero',
    yourOrders: 'Tus Pedidos',
    orderDetails: 'Detalles del Pedido',
    trackPackage: 'Paquete de Seguimiento'
  },
  cart: {
    addedToCart: 'Agregado al carrito',
    errorAddingToCart: 'Se produjo un error al agregar sus artículos a su carrito',
    emptyCartMessage: '¡Tu carrito esta vacío!',
    addToCartToastTitle: '{quantity, plural, one {Producto añadido a la cesta} other {Productos añadidos al carrito}}',
    addToCartToastMessage: '({quantity, number}) "{description}" {quantity, plural, one {se ha agregado a su carrito} other {se ha añadido a tu carrito}}',
    notAddedToCartToastTitle: '{quantity, plural, one {Producto no agregado al carrito} other {Productos no añadidos al carrito}}',
    notAddedToCartToastMessage: 'Hubo un problema al agregar {quantity, plural, one {el Product} other {los Productos}} a su carrito - inténtelo de nuevo más tarde.',
    errorRetreivingStatuses: 'Hubo un problema al recuperar la disponibilidad de artículos en el Carro.',
    cartExpiredTitle: 'Los precios del carrito son buenos por 30 días.',
    cartExpiredMessage: 'Algunos/Todos los artículos en su carrito han estado allí por más de 30 días. La cantidad se actualizará para reflejar el precio actual.',
    accountIssues: 'Hay un problema con su cuenta que le impide pagar. Comuníquese con Customer Experience al 800-729-3878 para resolver este problema.'
  },
  checkout: checkout,
  confirmation: {
    thankYou: 'Gracias por comprar con nosotros',
    explanations: {
      separateShipments: 'Le enviaremos una confirmación con información de seguimiento una vez que se envíe su pedido. Si ordenó varios artículos, pueden venir en envíos separados.',
      backordered: 'Si su pedido contiene artículos pendientes, una vez que el producto esté disponible, recibirá un correo electrónico de confirmación de envío.'
    },
    needHelp: {
      title: '¿Necesitas ayuda con tu pedido?',
      howToTrack: '¿Cómo hago el seguimiento de mi pedido?',
      canICancel: '¿Puedo cancelar o cambiar mi pedido?',
      orderArrive: '¿Cuándo llegará mi orden?'
    },
    talkToSomeone: 'Habla con alguien'
  },
  customLogo: {
    title: 'Personalizaciones',
    customizeProduct: 'Personaliza tu producto',
    customizeProductDescription: `Milwaukee Tool safety vests, hard hats, and helmets can be customized with images, logos, and text. Learn more about placing a custom product order {customizerFAQLink}.`,
    faqLinkText: 'aquí',
    startCustomizing: 'Ver o iniciar personalizacion',
    customize: 'Personalizar',
    goToCustomizer: 'Ir al personalizador',
    customizeMessage: 'Add your logo, name, or other artwork to item.',
    createStart: 'To view or create a new custom product design, start here',
  },
  faq: faq,
  footer: {
    copyright: '© {year} por Milwaukee Tool - Todos los derechos reservados',
    ccpa: 'Do Not Sell My Personal Information',
    privacy: 'Privacy'
  },
  general: {
    activate: 'Activar',
    actions: 'Comportamiento',
    amount: 'Cantidad',
    areYouSure: '¿Estás seguro?',
    cancel: 'Cancelar',
    cart: 'Carro',
    catalogNumber: 'Catalogar #',
    comments: 'Comentarios',
    customer: 'Cliente',
    date: 'Date',
    delete: 'Fecha',
    description: 'Descripción',
    edit: 'Editar',
    email: 'Email',
    endDate: 'Fecha final',
    errorToastTitle: 'Algo salió mal',
    exempt: 'Eximir',
    free: 'Free',
    id: 'ID',
    invoices: 'Facturas',
    item: 'ít',
    itemNumber: 'Número de artículo',
    linkCopiedToastTitle: 'Enlace copiado',
    linkCopied: 'Enlace copiado al portapapeles',
    orders: 'Pedidos',
    orderDate: 'Fecha de orden',
    orderDetails: 'Detalles del pedido',
    orderNumber: 'Número de orden',
    orderSummary: 'Resumen del pedido',
    pending: 'Pendiente',
    phone: 'Teléfono',
    purchaseDate: 'Fecha de compra',
    purchaseOrderNumber: 'Número de orden de compra',
    purchaseOrderNumberAbbrev: 'PO #',
    emailConfirmation: 'Confirmar correo electrónico',
    quantity: 'Cantidad',
    quantityAbbr: 'Qty',
    receipt: 'Recibo',
    refresh: 'Refrescar',
    total: 'Total',
    salesTax: 'Impuesto de venta',
    serialNumber: 'De serie #',
    share: 'Compartir',
    shipping: 'Envío',
    shippingCost: 'Costo de envío',
    shippingSpeed: 'Velocidad de envio',
    shipTo: 'Envie a',
    sku: 'SKU',
    startDate: 'Fecha de inicio',
    status: 'Estado',
    subtotal: 'Total parcial',
    successToastTitle: 'Éxito!',
    unknown: 'Desconocido',
    unauthorized: 'No autorizado',
    validationToastTitle: 'Por favor arregle los campos requeridos',
    viewOrder: 'Ver pedido',
    customerStatementErrorTitle: 'Error al obtener el extracto del cliente',
    customerStatementErrorBody: 'Hubo un problema al acceder a su estado de cuenta del cliente. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, comuníquese con el Servicio al cliente global al 1-800-SAWDUST (1-800-729-3878).'
  },
  home: {
    title: 'Encontrar un producto',
    orderInformationCardTitle: 'Información del Pedido',
    whatsNewCardTitle: 'Qué hay de nuevo',
    announcementsCardTitle: 'Anuncios',
    notificationsCardTitle: 'Notificaciones',
    brandworksCardTitle: 'Enlaces rápidos de marketing',
    enterAccountNumber: 'Ingrese el número de cuenta',
    accountActivationPending: 'Activación de cuenta pendiente',
    activateAccountDescription: 'Ingrese su número de cuenta aquí para la experiencia completa de Connect.',
    accountActivationRequestSent: 'Su solicitud de activación de cuenta se ha enviado a Atención al cliente. Recibirás un correo electrónico cuando tu cuenta haya sido activada.',
    activationErrorTitle: 'Activación de cuenta fallida',
    activationErrorDescription: 'Hubo un problema al intentar activar su cuenta. Si este problema persiste, comuníquese con Atención al cliente.',
    autoAccountActivationTitle: 'Activación de cuenta completada',
    autoAccountActivationDescription: 'La activación de su cuenta ha sido completada. En 5 segundos, el navegador se actualizará para actualizar su sesión.',
    autoAccountActivationFeedbackTitle: 'Activación de cuenta completada',
    autoAccountActivationFeedbackDescription: 'Su solicitud de activación de cuenta ha sido procesada. Cierre sesión y vuelva a iniciar sesión en unos minutos para que los cambios surtan efecto.'
  },
  invoices: {
    loadingInvoicesErrorMessage: 'Se produjo un error al recuperar sus facturas. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, comuníquese con el Servicio al cliente global al 1-800-SAWDUST (1-800-729-3878).',
    noInvoicesMessage: 'No se encontraron facturas.',
    downloadingInvoice: 'Descargando factura {invoiceId}...',
    downloadingInvoiceErrorMessage: 'Hubo un problema al acceder a su factura. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, comuníquese con el Servicio al cliente global al 1-800-SAWDUST (1-800-729-3878).'
  },
  orders: {
    noOrdersMessage: 'No se encontraron pedidos',
    ordersFilter: 'Todos',
    openOrders: 'Open',
    backordered: 'En espera',
    cancelled: 'Cancelado',
    invoicedFilter: 'Facturado',
    orderDateLabel: 'Fecha de orden',
    purchaseOrderLabel: 'Orden de compra',
    quoteNumberLabel: 'Número de cotización',
    orderNumberLabel: 'Orden #',
    shippingStatusLabel: 'Estado de envío',
    orderProcessingMessage: 'La orden se está procesando actualmente. Recibirá un número de pedido y una notificación por correo electrónico una vez que se haya completado el procesamiento.',
    skuColumn: 'Sku',
    quantityColumn: 'Qty',
    shipTo: 'Envie a',
    trackPackage: 'Paquete de seguimiento',
    packingSlip: 'Hoja de embalaje',
    downloadingPackingSlip: 'Descargando el albarán...',
    packingSlipDownloadErrorBody: 'Hubo un problema al obtener su recibo de embalaje. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, comuníquese con el Servicio al cliente global al 1-800-SAWDUST (1-800-729-3878).',
    excelDownloadErrorTitle: 'Problema al descargar Excel',
    excelDownloadErrorBody: 'Se produjo un problema al descargar el historial de pedidos de Excel. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, comuníquese con el Servicio al cliente global al 1-800-SAWDUST (1-800-729-3878).',
    reorder: 'Reordenar',
    reorderToastTitle: 'Productos por encargo {orderNumber} añadido al carrito',
    reorderErrorToastTitle: 'Hubo un problema al agregar los productos del pedido {orderNumber} a su carrito - por favor intente nuevamente más tarde.',
    downloadingWarrantyClaims: 'Downloading warranty claims...',
    warrantyClaimsExcelDownloadError: 'There was a problem downloading the order history excel. Please try again later. If this issue persists, please contact Global Customer Service at 1-800-SAWDUST (1-800-729-3878).',
    employeeOrdersDisabled: 'Debido a una demanda sin precedentes, no aceptamos pedidos de empleados en este momento.',
    orderLoadingError: 'Ocurrió un error al acceder al pedido. Por favor, inténtelo de nuevo más tarde.',
    unauthorizedToView: 'No está autorizado para ver este pedido.'
  },
  orderSummary: {
    backorderTooltip: 'No se le cobrarán estos artículos hasta que lleguen en stock y se envíen',
    itemsShipped: 'Artículos enviados',
    itemsToBeShipped: 'Artículos para ser enviados',
    itemsOnBackorder: 'Artículos en espera',
    shippingToBeCalculated: 'El envío se calcula cuando los artículos han sido enviados',
    freeShipping: 'This order qualifies for free shipping',
    taxExempt: 'Esta orden califica para estado exento de impuestos',
    taxToBeCalculated: 'El impuesto se calculará después de que se envíe el pedido'
  },
  pricing: {
    signInForPrice: 'Inicia sesión para ver tu precio',
    netUnitPrice: 'Precio Unitario Neto:',
    listPrice: 'Precio de lista:',
    unitPrice: 'Precio unitario',
    yourPrice: 'Tu precio:',
    quantity: 'Cantidad',
    extendedPrice: 'Precio Ampliado:',
    extendedPriceAbbr: 'Ext Price',
    reimbursementPrice: 'Precio de reembolso',
    accountNeedsAccess: 'Tu cuenta no tiene permitido realizar pedidos',
    selectOptions: 'Seleccione opciones'
  },
  products: {
    eachWeight: 'Peso',
    totalWeight: 'Peso total',
    backordered: 'En Espera',
    unavailable: 'Indisponible',
    discontinuedInStock: 'Descatalogado en Stock',
    discontinuedOutOfStock: 'Descontinuado Agotado',
    inStock: 'En Stock',
    other: 'Otra',
    quantityMultiple: 'Debe pedirse en múltiplos de {nextValidQuantityAllowed}',
    quantityMin: 'Debe tener un pedido mínimo de {lowestQuantityAllowed}',
    quantityMax: 'Debe tener un orden máximo de {highestQuantityAllowed}',
    LeadTime7Days: '7 días de tiempo de entrega',
    LeadTime7To14Days: 'Tiempo de entrega de 7 a 14 días',
    PreSale: 'Preventa'
  },
  productDetails: {
    keyFeatures: 'Características Importantes',
    includes: 'Incluye',
    youMightAlsoLike: 'También Podría Gustarte'
  },
  publications: {
    downloadingFile: 'Descargando {filename}...',
    effectiveDate: 'eficaz {date}',
    partsPricingTitle: 'Precios de piezas',
    partsPricingDescription: 'Los archivos de herramientas y accesorios contienen número de catálogo, descripción, lista y precios netos, cantidad de paquete maestro, código UPC, dimensiones del producto (LxWxH) y peso. Los archivos de piezas de servicio contienen el número de pieza, la descripción y el precio de lista.',
    toolAndAccessoryPrices: 'Precios de herramientas y accesorios',
    servicePartsPrices: 'Precios de piezas de servicio',
    iMap: 'IMAP',
    iMapPolicy: 'IMAP Policy',
    servicePublicationsTitle: 'Publicaciones de servicio',
    technicalPublicationsTitle: 'Publicaciones tecnicas',
    technicalPublicationsDescription: 'Los boletines de la lista de piezas de servicio, las instrucciones de cableado y los manuales de los operadores generalmente se pueden obtener aquí.',
    technicalPublicationsButton: 'Buscar publicaciones técnicas',
    lightningMaxRepairPriceSheetTitle: 'Hoja de precios de Lightning Max Repair',
    lightningMaxRepairPriceSheetDescription: 'Lista de precios del programa Lightning Max Repair para calibración, mantenimiento y órdenes de servicio sin garantía.',
    thirtyDayExclusionListTitle: 'Lista de exclusión de 30 días',
    thirtyDayExclusionListDescription: 'Lista de artículos que están excluidos de nuestra política de devolución de satisfacción de 30 días.',
    disclaimerTitle: 'Renuncia',
    disclaimerDescription: 'Los precios y la información contenida en estos archivos están sujetos a cambios sin previo aviso. Comuníquese con el Servicio al cliente global al 1-800-SAWDUST (1-800-729-3878) si tiene alguna pregunta relacionada con las piezas de servicio. Apreciamos su negocio y su asociación continua con nosotros para atender las necesidades de nuestros clientes.',
    documentSearchTitle: 'ONE KEY and MX FUEL Service Parts Publications and Videos',
    documentSearchDescription: 'Enter model number to search for SPLs and training material in the field below',
    documentTableName: 'Name',
    documentTableDate: 'Date Uploaded',
    documentTableDownload: 'Download',
    sales: 'Sales Policy',
    internet: 'Internet Distributor Policy',
    coOpPolicies: 'Co-Op Advertising Allowance Policy',
    termsOfSale: 'Terms of Sale',
    salesPolicies: 'Milwaukee Sales Policies'
  },
  search: {
    addToCart: 'Añadir a la Cesta',
    removeFromCart: 'Quitar',
    searchPlaceholder: 'Nombre del producto o #',
    sizes: 'Tamaños',
    colors: 'Colores',
    selectAColor: 'Por favor seleccione un color primero'
  },
  square: square,
  track: {
    carrier: 'Transportista',
    trackingNumber: 'El Número de Rastreo',
    eta: 'Fecha Estimada de Entrega',
    preTransit: 'Pre-Tránsito',
    inTransit: 'En Tránsito',
    outForDelivery: 'Fuera de Entrega',
    delivered: 'Entregado',
    trackingNotAvailable: 'El seguimiento no está disponible actualmente para este envío',
    shipped: 'Enviado'
  },
  warranty: {
    activeWarrantyClaims: 'Reclamos de garantía activa',
    totalWarrantyClaims: 'Reclamaciones de garantía total',
    autoApprovalViolation: {
      valueThreshold: 'El valor de la pieza excede el umbral',
      laborHoursThreshold: 'Las horas laborales exceden el umbral',
      obsoleteItem: 'Contiene artículo obsoleto',
      repairDurationThreshold: 'La duración de la reparación excede el umbral',
      partQuantityThreshold: 'La cantidad de parte excede el umbral',
      freightAllowanceThreshold: 'La franquicia excede el umbral',
      reviewSkuPrefix: 'Siempre revise el prefijo de SKU',
      reviewDefectivePartClaim: 'Siempre revise el reclamo de pieza defectuosa',
      initialViolation: 'Initial Auto Approve Violations',
      discontinuedPart: '{itemNumber} is discontinued',
      replacementPart: '{itemNumber} has been replaced with {replacementSku}',
      backOrderedPart: '{itemNumber} is backordered',
      backOrderedPartWithDate: '{itemNumber} is backordered til {date}'
    },
    claimStatus: {
      draft: 'Borrador',
      pending: 'Pendiente',
      pendingInitialReview: 'Revisión inicial pendiente',
      approved: 'Aprobada',
      approvedWithChanges: 'Aprobado con cambios',
      rejected: 'Rechazado',
      pendingSubsequentReview: 'Revisión posterior pendiente',
      denied: 'Negado',
      pendingAutoApproval: 'Pendiente de aprobación automática'
    },
    partLineType: {
      warranty: 'Garantía',
      recall: 'Recordar',
      goodwill: 'Buena voluntad'
    },
    shippedType: {
      notAvailable: 'No disponible',
      customerDropOffOrPickup: 'Entrega/recogida del cliente',
      companyTruck: 'Camión de la empresa',
      carrierFreight: 'Transportista Común/Flete'
    },
    partsValidation: {
      notInCatalog: '{itemNumber} no está en nuestro catálogo en línea',
      invalidTool: '{itemNumber} no es válido como herramienta en un reclamo de garantía',
      invalidComponent: '{itemNumber} no es válido como componente en un reclamo de garantía',
      unauthorizedRepair: 'El centro de servicio no está autorizado para reparar {itemNumber}',
      flatRatePriceNotSet: 'Hay un problema con el número de herramienta que selecciono. Por favor confirmar que el número de parte que puso es el mismo de la herramienta que está reparando. Si necesita apoyo adicional, por favor enviar un correo a Santiago.pava@milwaukeetool.com , incluyendo el número de parte en cuestión.'
    },
    noClaimsMessage: 'No se encontraron reclamos de garantía',
    accountSelection: 'Selección de cuenta',
    eligibility: 'Elegibilidad',
    logistics: 'Logística',
    repairDetails: 'Detalles de reparación',
    customerDetails: 'Detalles del cliente',
    claimReview: 'Revisión de reclamos',
    claimConfirmation: 'Confirmación de reclamo',
    commentsPlaceholder: 'Añadir comentarios adicionales',
    pricingMessage: 'Guardar cambios para obtener precios',
    claimInformation: 'Claim Information',
    expenseSummary: 'Expense Summary',
    repairNotes: 'Repair Notes',
    ascInformation: 'ASC Information',
    customerInformation: 'Customer Information',
    repairInformation: 'Repair Info',
    photos: 'Photos',
    productInformation: 'Product Information',
    reviewInformation: 'Review Information',
    shipping: {
      information: 'Shipping Information',
      batteryNotice: 'NOTICE: Any claims for Batteries require shipping the Batteries back to Milwaukee Tool for inspection.',
      batteryPrint: 'Please print out the documents below and follow the instructions to ship the Batteries to Milwaukee Tool.',
      labelPrint: 'Print Shipping Label and Instructions'
    },
    review: {
      errorTitle: 'Error al enviar el reclamo',
      requireComments: 'Proporcione comentarios que expliquen sus cambios.',
      invalidStatusChange: 'El estado no puede cambiar de {oldStatus} a {newStatus}'
    },
    newComment: {
      errorTitle: 'Ocurrió un error',
      errorMessage: 'Se produjo un error al enviar sus comentarios. Vuelva a intentarlo más tarde.'
    },
    submissionError: {
      dateReceivedOutOfRange: 'La fecha de recepción debe ser dentro de 1 año a partir de hoy',
      dateRepairedOutOfRange: 'La fecha de recepción debe ser dentro de 1 año a partir de hoy',
      dateReturnedOutOfRange: 'La fecha de recepción debe ser dentro de 1 año a partir de hoy',
      freightAllowanceRequired: 'Se requiere subsidio de flete',
      primaryPartRequired: 'Parte primaria requerida',
      productSkuRequired: 'Número de catálogo requerido',
      receivedViaRequired: 'Recibido vía requerido',
      returnedViaRequired: 'Devuelto a través de requerido',
      warrantyLaborRequired: 'Garantía de mano de obra requerida',
      warrantyLaborOutOfRange: 'El trabajo de garantía debe estar entre 0 y 100',
      notCertifiedAccurate: 'Por favor certifique que los datos son precisos',
      notCertifiedSafety: 'Por favor certifique que los datos son precisos',
      returnShippingIncomplete: 'Incluya el proveedor de envío de devolución y el número de seguimiento',
      serialNumberRequired: 'Se requiere un número de serie si es legible',
      duplicateParts: 'Hay partes duplicadas en su reclamo, elimínelas para continuar',
      warrantyLaborPrecision: 'La mano de obra de garantía debe redondearse a la décima más cercana',
      laborHoursExceededForToolReplacement: 'Las horas de trabajo exceden las asignadas para el reemplazo de herramientas. Solo se permite 0.3 para el reemplazo de herramientas.',
      onlyToolAllowedAsPart: 'Las reclamaciones de reemplazo de herramientas solo pueden tener la herramienta listada como parte',
      receiptRequired: 'Se requiere recibo para reclamos con números de serie ilegibles',
      purchaseDateRequired: 'Se requiere la fecha de compra para reclamos con números de serie ilegibles',
      noCostWarrantyClaim: 'El reclamo de garantía debe ser por un monto en dólares mayor a cero. Verifique y ajuste sus piezas y valores.',
      freightAllowanceGreaterThanDailyAmount: 'Máximo de {amountRemaining} restante para las entregas de la empresa de camiones a este cliente en el día elegido.',
      customerIncomplete: 'La información del cliente está incompleta',
      notAcceptingBatteryClaims: 'Battery Claims not allowed at this time',
      skuDoesNotExist: 'Sku {itemNumber} does not exist',
      skuIsNotEligible: 'Sku {itemNumber} no es elegible para reclamo de pieza defectuosa',
      partNotInCatalog: 'Algunas de las piezas no están en el catálogo.',
      toolNotValidClaim: 'Reclamo de herramienta no és válida',
      componentNotValidClaim: 'Componente de reclamo no és válido',
      accountNotAuthorized: 'Línea de producto no autorizada para esta cuenta',
      flatRatePriceNotSet: 'El precio de tarifa plana no está establecido para ese artículo'
    },
    button: {
      saveChanges: 'Save Changes',
      addPart: 'Add Part',
      newClaim: 'New Claim',
      addComment: 'Add Comment',
      resubmit: 'Resubmit',
      deny: 'Deny',
      reject: 'Reject',
      approve: 'Approve',
      download: 'Download',
      confirm: 'Confirmar'
    },
    archive: {
      archiveModalTitle: 'Archivar Reclamo de Garantía',
      archiveModalBody: '¿Seguro que quieres archivar este reclamo de garantía?',
      unarchiveModalTitle: 'Desarchivar Reclamo de Garantía',
      unarchiveModalBody: '¿Seguro que quieres desarchivar este reclamo de garantía?',
      archiveSuccessToastTitle: 'Reclamo de Garantía Archivado',
      archiveSuccessToastMessage: 'Reclamo de Garantía # {claimNumber} ha sido archivado',
      unarchiveSuccessToastTitle: 'Reclamo de Garantía Desarchivado',
      unarchiveSuccessToastMessage: 'Reclamo de Garantía # {claimNumber} ha sido desarchivado',
      errorToastTitle: 'Ocurrió un error',
      archiveErrorToastMessage: 'Ocurrió un error al archivar este reclamo de garantía, vuelva a intentarlo más tarde',
      unarchiveErrorToastMessage: 'Ocurrió un error al desarchivar este reclamo de garantía, vuelva a intentarlo más tarde',
    }
  },
  warrantyAutoApproveFilters: {
    currentFilters: 'Filtros de aprobación automática activa',
    errorAddingFilterTitle: 'Error al agregar filtro',
    errorAddingMessage: 'Hubo un problema al agregar el filtro. Por favor, inténtelo de nuevo más tarde.',
    errorSavingFilterTitle: 'Error al guardar cambios',
    errorSavingMessage: 'Hubo un problema al guardar el filtro. Por favor, inténtelo de nuevo más tarde.',
    errorDeletingFilterTitle: 'Error al eliminar el filtro',
    errorDeletingMessage: 'Hubo un problema al eliminar el filtro. Por favor, inténtelo de nuevo más tarde.',
    errorFilterTypeAndValueRequired: 'Los campos de tipo y valor son obligatorios para guardar un filtro.',
    confirmDeleteModalTitle: '¿Estás seguro?',
    confirmDeleteModalBody: '¿Seguro que quieres eliminar este filtro? Esta acción no se puede deshacer.',
    confirmDeleteButton: 'Estoy seguro - Eliminar',
    actions: 'Comportamiento',
    type: 'Tipo',
    value: 'Valor'
  },
  contact: {
    title: 'Contáctenos',
    yourInfo: 'Tu Información',
    yourCompanyInfo: 'La Información De Tu Compañia',
    custService: 'Servicio al cliente',
    generalPhone: 'Teléfono general',
    fax: 'Fax',
    address: 'Dirección',
    emailForm: 'Forma de correo electronico',
    subject: 'Tema',
    selectSubject: 'Seleccione un Tema',
    comments: 'Comentarios',
    userInstructions1: 'Un representante de Milwaukee Tool responderá a su solicitud el siguiente día hábil.',
    userInstructions2: 'Al pedir material publicitario o literatura (catálogos, DPL, etc.), especifique la cantidad y los artículos que desea recibir. Los materiales se enviarán a la cuenta en la que se haya iniciado sesión actualmente, cambie según sea necesario. Esto no cambiará la información en nuestro sistema, solo el nombre y la ubicación de esta solicitud.',
    sentReqItemsTo: 'Enviar artículos solicitados a',
    custName: 'Nombre del cliente',
    companyName: 'Nombre de empresa',
    zipCode: 'Código postal',
    city: 'Ciudad',
    state: 'Estado o Provincia',
    phone: 'Teléfono',
    email: 'Email',
    optional: 'Opcional',
    changeShippingInfo: 'Cambiar información de envío',
    thanksForYourRequst: 'Gracias por tu solicitud.',
    repWillRespond: 'Un representante de Milwaukee Tool responderá a su solicitud el siguiente día hábil.',
    subjectInventory: 'Inventario',
    subjectOrderDpl: 'Pedido Dpl',
    subjectPricing: 'Precios',
    subjectOrderStatus: 'Estado del pedido',
    subjectInvoice: 'Factura',
    subjectAccountBalance: 'Saldo de la cuenta',
    subjectProdTech: 'Producto/Técnico',
    subjectProductService: 'Servicio de producto',
    subjectAdvertLitReq: 'Solicitudes de literatura publicitaria',
    subjectWarrantyClaims: 'Reclamos de garantia',
    errorSubmittingForm: 'Error al enviar el formulario.'
  },
  quickAdd: {
    title: 'Agregar rápido',
    invalidProduct: '{skuOrEachUpc} no se puede encontrar o no es válido',
    invalidQuantity: 'Cantidad no válida ingresada',
    invalidQuantityNextQuantityAllowed: '. Debe pedirse en múltiplos de {nextQuantityAllowed}',
    invalidQuantityLowestQuantityAllowed: '. Debe tener un pedido mínimo de {lowestQuantityAllowed}.',
    validationMessage: 'Por favor ingrese un SKU o Each UPC válido',
    productPlaceholder: 'SKU o Each UPC',
    goToCart: 'Ir al carrito',
    multipleSkusMessage: 'Se encontraron múltiples SKU para ese producto: {productSkus}',
    errorQuickAddToCart: 'Se produjo un error al agregar un nuevo producto al carrito'
  },
  wishlists: {
    noWishlists: '¡No tienes listas de compras!',
    emptyWishlist: 'La lista está vacía!',
    addedToWishlistTitle: 'Agregado a la lista de compras',
    addedToWishlist: '"{description}" ha sido agregado a su lista de compras',
    errorAddingToWishlist: 'Se produjo un error al agregar {description} a su lista de compras',
    wishlistLineAddedToCartTitle: 'Agregado al carro',
    wishlistLineAddedToCart: '¡{description} fue agregado a su carrito!',
    errorAddingWishlistLineToCart: 'Se produjo un error al agregar {description} a su carrito',
    removedFromWishlistTitle: 'Lista de compras actualizada',
    removedFromWishlist: '¡Se eliminó {description} de su lista de compras!',
    errorRemovingFromWishlist: 'Se produjo un error al eliminar {description} de su lista de compras',
    wishlistUpdatedTitle: 'Lista de compras actualizada',
    wishlistUpdated: 'Su lista de compras fue actualizada!',
    wishlistAlreadyExistsTitle: 'La lista de compras ya existe',
    wishlistAlreadyExists: 'Ya existe una lista de compras con ese nombre',
    errorUpdatingWishlist: 'Se produjo un error al actualizar su lista de compras',
    wishlistDeletedTitle: 'Lista de compras eliminada',
    wishlistDeleted: 'Su lista de compras fue eliminada!',
    errorDeletingWishlist: 'Se produjo un error al eliminar tu lista de compras',
    wishlistCreatedTitle: 'Lista de compras creada',
    wishlistCreated: '¡Tu lista de compras fue creada!',
    errorCreatingWishlist: 'Se produjo un error al crear su lista de compras',
    wishlistAddedToCartTitle: 'Agregado al carro',
    wishlistAddedToCart: '¡Sus artículos de la lista de compras se agregaron a su carrito!',
    errorAddingWishlistToCart: 'Se produjo un error al agregar su lista de compras a su carrito',
    wishlistSubtotal: 'Subtotal de la lista de compras',
    yourWishlists: 'Tus listas de compras',
    wishlistDetails: 'Detalles de la lista de compras',
    editWishlist: 'Editar lista de compras',
    createWishlist: 'Crear nuevo',
    errorUpdatingQuantity: 'Se produjo un error al actualizar la cantidad',
    errorRemovingFromSavedForLater: 'Se produjo un error al eliminar {description} de tu guardado para más tarde'
  },
  restrictedItems: {
    warningForDeletingRestrictedItemsFromCart: 'Tenía artículos restringidos en su carrito que fueron eliminados. Revise su carrito antes de enviarlo.'
  },
  creditCard: {
    confirmDeleteModalTitle: 'Eliminar Tarjeta',
    confirmDeleteModalBody: '¿Seguro que quieres eliminar su tarjeta que termina en {cardLast4}?',
    confirmDeleteButton: 'Eliminar Tarjeta',
  }
}