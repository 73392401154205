import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Card, Col, Container, Row, Table} from 'reactstrap';

import {PageLoader} from '../../../components';
import {warrantyAutoApproveFiltersActions} from '../../../store';
import WarrantyAutoApproveRow from './WarrantyAutoApproveRow';

class WarrantyAutoApprove extends Component {
  componentDidMount() {
    this.props.getFilters();
  }
  
  handleDeleteClick = (id) => {
    const {intl} = this.props;
    this.props.deleteFilter(id)
      .then(() => {
        this.props.getFilters();
      })
      .catch(() => {
        toastr.error(intl.formatMessage({id: 'warrantyAutoApproveFilters.errorDeletingFilterTitle'}), intl.formatMessage({id: 'warrantyAutoApproveFilters.errorDeletingMessage'}))
      });
  };
  
  handleSaveClick = (filter) => {
    const {intl} = this.props;
    if (filter.id > 0) {
      this.props.updateFilter(filter)
      .then(() => {
        this.props.getFilters();
      })
      .catch(() => {
        toastr.error(intl.formatMessage({id: 'warrantyAutoApproveFilters.errorSavingFilterTitle'}), intl.formatMessage({id: 'warrantyAutoApproveFilters.errorDeletingMessage'}))
      });
    } else {
      this.props.addFilter(filter)
      .then(() => {
        this.props.getFilters();
      })
      .catch(() => {
        toastr.error(intl.formatMessage({id: 'warrantyAutoApproveFilters.errorAddingFilterTitle'}), intl.formatMessage({id: 'warrantyAutoApproveFilters.errorDeletingMessage'}))
      });
    }
  };

  render() {
    const {isLoading, filters} = this.props;
    
    let markup;
    if (isLoading) {
      markup = <PageLoader/>;
    }
    else if (!isLoading && filters.length > 0) {
      const rows = filters.map((filter, index) => {
        return (
          <WarrantyAutoApproveRow
            key={`filter=${index}`}
            filter={filter}
            onDelete={this.handleDeleteClick}
            onSave={this.handleSaveClick}
          />
        )
      });

      markup = (
        <Table striped borderless responsive className='order-lines-table'>
          <thead>
          <tr>
            <th><FormattedMessage id='warrantyAutoApproveFilters.type' /></th>
            <th><FormattedMessage id='warrantyAutoApproveFilters.value' /></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          <WarrantyAutoApproveRow
            key={`filter-new`}
            filter={{}}
            isNew={true}
            onDelete={this.handleDeleteClick}
            onSave={this.handleSaveClick}
          />
          </tbody>
        </Table>
      );
    }

    return (
      <div id='warrantyAutoApproveFilters'className='animate-bottom-fade-in content-wrapper'>
      <Container>
          <Row>
            <Col>
              <Card body>
                <Container>
                  <Row>
                    <Col>
                      <h4><FormattedMessage id='warrantyAutoApproveFilters.currentFilters' /></h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    {markup}
                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getFilters: () => dispatch(warrantyAutoApproveFiltersActions.getWarrantyAutoApproveFilters()),
  deleteFilter: (id) => dispatch(warrantyAutoApproveFiltersActions.deleteWarrantyAutoApproveFilter(id)),
  updateFilter: (filter) => dispatch(warrantyAutoApproveFiltersActions.updateWarrantyAutoApproveFilter(filter)),
  addFilter: (filter) => dispatch(warrantyAutoApproveFiltersActions.addWarrantyAutoApproveFilter(filter))
});

const mapStateToProps = state => {
  return {
    isLoading: state.warrantyAutoApproveFilters.getWarrantyAutoApproveFilters.isLoading,
    filters: state.warrantyAutoApproveFilters.getWarrantyAutoApproveFilters.filters
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WarrantyAutoApprove));