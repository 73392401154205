export const downloadUrl = (title, url) => {
  let a = document.createElement('a');
  a.href = url;
  a.setAttribute("type", "hidden");
  a.download = title;  
  document.body.appendChild(a); //this is also for firefox
  a.click();
  return a;
};

export const  downloadBlob = (title, blob) => {
  if(window.navigator && window.navigator.msSaveOrOpenBlob) {
    // This is for Internet Exploder
    window.navigator.msSaveOrOpenBlob(blob, title);    
  } else {
    let url = URL.createObjectURL(blob);
    let a = downloadUrl(title, url);
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(url);
      a.remove();
    }, 100);
  }
};