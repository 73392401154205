import React, {useEffect, useState} from 'react';
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Card, CardBody, Col, Container, ListGroup, ListGroupItem, Row, Table} from 'reactstrap';
import {bindActionCreators} from 'redux';
import {Button, CreateWishlistModal, Icon, PageLoader} from '../../components';
import history from '../../history';
import {wishlistsActions} from '../../store';

const Wishlists = (props) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  
  useEffect(() => {
    props.wishlistsActions.getWishlists();
  }, []);
  
  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };
  
  const handleCreateClick = (name) => {
    props.wishlistsActions.newWishlist(name)
      .then(() => {
        toastr.success(props.intl.formatMessage({id: 'wishlists.wishlistCreatedTitle'}), props.intl.formatMessage({id: 'wishlists.wishlistCreated'}));
        toggleCreateModal();
        props.wishlistsActions.getWishlists();
      })
      .catch((error) => {
        if (error.status === 409)
          toastr.error(props.intl.formatMessage({id: 'wishlists.wishlistAlreadyExistsTitle'}), props.intl.formatMessage({id: 'wishlists.wishlistAlreadyExists'}), {timeout: 0});
        else
          toastr.error(props.intl.formatMessage({id: 'general.errorToastTitle'}), props.intl.formatMessage({id: 'wishlists.errorCreatingWishlist'}), {timeout: 0});
      });
  };
  
  let wishlistsRows = props.wishlists.map((wishlist, index) => {
    return (
      <tr key={`wishlist-${index}`}>
        <th data-test-id={`wishlists-name-${wishlist.name}`} className='align-middle' data-label='Name' scope='row'>
          <span>{wishlist.name}</span>
        </th>
        <td data-test-id={`wishlists-created-at-${wishlist.createdAt}`} className='align-middle' data-label='Created At'>
          <FormattedDate value={wishlist.createdAt + 'Z'}/>&nbsp;<FormattedTime value={wishlist.createdAt + 'Z'} />
        </td>
        <td data-test-id={`wishlists-updated-at-${wishlist.updatedAt}`} className='align-middle' data-label='Updated At'>
          <FormattedDate value={wishlist.updatedAt + 'Z'}/>&nbsp;<FormattedTime value={wishlist.updatedAt + 'Z'} />
        </td>
        <td data-test-id={`wishlists-view-${wishlist.name}`} className='text-right align-middle'>
          <Button color='primary' onClick={() => history.push(`shoppingLists/${wishlist.id}`)}>View</Button>
        </td>
      </tr>
    );
  });
  
  let wishlistsMarkup = props.wishlists && props.wishlists.length > 0 ? (
    <Table striped borderless responsive className='wishlists-table'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Created</th>
          <th>Last Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {wishlistsRows}
      </tbody>
    </Table>
  ) : (
    <ListGroup>
      <ListGroupItem className='text-center'>
        <div className='my-4'>
          <h3><FormattedMessage id='wishlists.noWishlists'/></h3>
        </div>
      </ListGroupItem>
    </ListGroup>
  );
  
  let createWishlistModalMarkup = showCreateModal && (
    <CreateWishlistModal
      isOpen={showCreateModal}
      toggle={toggleCreateModal}
      handleCreateClick={handleCreateClick}
      isProcessing={props.createWishlistIsProcessing}
    />
  );
  
  return props.isLoading ? <PageLoader/> : (
    <div id='wishlists' className='content-wrapper animate-bottom-fade-in'>
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Container fluid>
                  <Row>
                    <Col>
                      {wishlistsMarkup}
                    </Col>
                  </Row>
                  <Row className='justify-content-end'>
                    <Col xs='auto' className='mt-3'>
                      <Button data-test-id='wishlists-add-new' block color='primary' onClick={() => toggleCreateModal()} className='d-print-none'>Add New <Icon type='plus' color='white' size='sm' className='ml-2'/></Button>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {createWishlistModalMarkup}
    </div>
  )
};

const mapStateToProps = state => {
  return {
    isLoading: state.wishlists.getWishlists.isLoading,
    isError: state.wishlists.getWishlists.isError,
    wishlists: state.wishlists.getWishlists.wishlists,
    createWishlistIsProcessing: state.wishlists.newWishlist.isProcessing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    wishlistsActions: bindActionCreators(wishlistsActions, dispatch)
  }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Wishlists));