import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardBody, CardHeader} from '@met/react-components';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Slider from 'react-slick';

import {PageLoader} from '../../components';
import history from '../../history';
import gtm from "../../helpers/googleTagManager";

class WhatsNew extends Component {
  
  handleClick = (sku)=>{
    gtm.event('Whats New', 'Click', sku);
    
    history.push(`/products/${sku}`);
  };
  
  render() {
    const { products, isLoading } = this.props;

    let sliderSettings = {
      dots: false,
      lazyLoad: 'progressive',
      infinite: products && products.length > 3,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    
    let markup;
    if (isLoading || !products) {
      markup = <PageLoader marginSize='sm' />;
    }
    else {
      let slides = products && products.map((product, index) => {
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div key={`product-${index}`} data-test-id={`whats-new-product-${product.sku}`} className='px-3 is-mock-link' onClick={()=>this.handleClick(product.skuAlias || product.sku)}>
            <img src={`/api/v1/images?url=${product.imageUrl}`} alt={product.title}/>
            <span className='mt-3 h6 pb-2' style={{ width: "200px", display: "block" }}>{product.title}</span>
          </div>
        )
      });
      
      markup = (
        <Slider {...sliderSettings} className='p-0'>
          {slides}
        </Slider>
      );
    }

    return (
      <Card className='info-card' id='whats-new'>
        <CardHeader className='header-secondary'>
          <FontAwesomeIcon icon='certificate' className='is-color-dark-gray mr-2'/>
          <FormattedMessage id='home.whatsNewCardTitle'/>
        </CardHeader>
        <CardBody>
          {markup}
        </CardBody>
      </Card>
    );
  }
}

WhatsNew.propTypes = {
  isLoading: PropTypes.bool,
  products: PropTypes.array
};

export default injectIntl(WhatsNew);