import {CardBody, Col, Placeholder, PlaceholderContent, Row} from '@met/react-components';
import React from 'react';

import user from '../../../helpers/user';
import AddressCard from './AddressCard';

const ShippingInfoLoader = () => {
  if (user.isMilwaukeeToolEmployee()){
    return (
      <Col xs={12} sm={6} className='d-flex'>
        <AddressCard>
          <CardBody>
            <Placeholder>
              <Row>
                <Col xs={6}><PlaceholderContent/></Col>
              </Row>
              <Row>
                <Col xs={5}><PlaceholderContent/></Col>
              </Row>
              <Row>
                <Col xs={5}><PlaceholderContent/></Col>
              </Row>
              <Row>
                <Col xs={3}><PlaceholderContent/></Col>
              </Row>
              <Row style={{marginTop: '3rem'}}>
                <Col xs={2}><PlaceholderContent/></Col>
                <Col xs={2}><PlaceholderContent/></Col>
              </Row>
            </Placeholder>
          </CardBody>
        </AddressCard>
      </Col>
    );
  }
  
  const emptyRow = <Row><Col><PlaceholderContent big empty/></Col></Row>;
  
  return (
    <Col>
      <Placeholder>
        <Row>
          <Col xs={2}><PlaceholderContent/></Col>
        </Row>
        <Row>
          <Col><PlaceholderContent big/></Col>
        </Row>
        {emptyRow}
        <Row>
          <Col xs={2}><PlaceholderContent/></Col>
        </Row>
        <Row>
          <Col><PlaceholderContent big/></Col>
        </Row>
        {emptyRow}
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={4}><PlaceholderContent/></Col>
            </Row>
            <Row>
              <Col><PlaceholderContent big/></Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={6}><PlaceholderContent/></Col>
            </Row>
            <Row>
              <Col><PlaceholderContent big/></Col>
            </Row>
          </Col>
          <Col xs={12} md={2}>
            <Row>
              <Col xs={6}><PlaceholderContent/></Col>
            </Row>
            <Row>
              <Col><PlaceholderContent big/></Col>
            </Row>
          </Col>
        </Row>
      </Placeholder>
    </Col>
  )
  
};

export default ShippingInfoLoader;