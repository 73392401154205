import { theme } from '@met/react-components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import styled, { css } from 'styled-components';

import OrderLineMessage from './OrderLineMessage';
import OrderLinePreview from './OrderLinePreview';
import OrderLinePreviewGrid from './OrderLinePreviewGrid';

const color = props => {
  if (props.group === 'inStock')
    return css`color: ${theme.colors.green};`;
  if (props.group === 'backordered')
    return css`color: ${theme.colors.yellow};`;
  if (props.group === 'other')
    return css`color: ${theme.colors.blue};`;
  return css`color: ${theme.colors.black};`;
};

const Title = styled.h5`
  ${(props) => color(props)}
`;

const OrderLineGroup = (props) => {
  let orderLineMarkup = props.orderLines && props.orderLines.map(orderLine => {
    return (
      <ListGroupItem key={`${props.group}-${orderLine.sku}`} className={props.useGridView ? 'cart-list-item-grid' : 'cart-list-item'}>
        {props.useGridView ? (
          <Fragment>
            <OrderLinePreviewGrid
              orderLine={orderLine}
              group={props.group}
              onQuantityChange={props.onQuantityChange}
              onRemoveFromCartClick={props.onRemoveFromCartClick}
              onSaveForLaterClick={props.onSaveForLaterClick}
            />
            <OrderLineMessage group={props.group} orderLine={orderLine} className='m-0' />
          </Fragment>
        ) : (
          <Fragment>
            <OrderLinePreview
              orderLine={orderLine}
              group={props.group}
              onQuantityChange={props.onQuantityChange}
              onRemoveFromCartClick={props.onRemoveFromCartClick}
              onSaveForLaterClick={props.onSaveForLaterClick}
            />
            <OrderLineMessage group={props.group} orderLine={orderLine} className='my-1' />
          </Fragment>
        )}

      </ListGroupItem>
    )
  });

  return (
     <div>
        <Title className='mb-3' group={props.group}>
          <FormattedMessage id={`products.${props.group}`} />
        </Title>
      <Card>
        <CardBody className='p-0'>
          <ListGroup className='cart-list'>
            {orderLineMarkup}
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  )
};

OrderLineGroup.propTypes = {
  orderLines: PropTypes.arrayOf(Object),
  group: PropTypes.string,
  useGridView: PropTypes.bool,
  onQuantityChange: PropTypes.func,
  onRemoveFromCartClick: PropTypes.func,
  onSaveForLaterClick: PropTypes.func
};

export default OrderLineGroup;