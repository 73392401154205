const common = {
  tryAgainLater: 'Por favor, inténtelo de nuevo más tarde. Si este problema persiste, comuníquese con el Servicio al Cliente Global al 1-800-SAWDUST (1-800-729-3878).'
};

const square = {
  inviteToPay: 'Invitar a Pagar',
  paymentMethod: 'Método de pago',
  paymentSummary: 'Resumen de Pago',
  submitPayment: 'Enviar Pago',
  referenceNumber: 'Número de Referencia',
  accountNumber: 'Número de Cuenta',
  email: 'Email',
  transactionNotValid: 'Transacción ya no es válida',
  transactionNotValidMessage: 'Esta transacción ya no es válida.',
  transactionComplete: 'Transacción Completa',
  transactionCompleteMessage: 'Esta transacción ya se ha completado.',
  inviteToPayErrorMessage: `Se produjo un error al recuperar los detalles de Invitar a pagar. ${common.tryAgainLater}`,
  getCreditCardsErrorMessage: `Ocurrió un error al recuperar sus tarjetas de crédito. ${common.tryAgainLater}`,
  submitPaymentErrorMessage: `Se produjo un error al enviar su pago. ${common.tryAgainLater}`,
  addCreditCardErrorMessage: `Se produjo un error al agregar su tarjeta de crédito. ${common.tryAgainLater}`,
  deleteCreditCardErrorMessage: `Se produjo un error al eliminar su tarjeta de crédito. ${common.tryAgainLater}`,
  creditCardSelectionRequired: 'Seleccione una tarjeta de crédito para realizar el pago.',
  errorCodes:{
    DEFAULT_ERROR: `Se produjo un error al procesar su pago. ${common.tryAgainLater}`,
    CARD_DENIED: 'Su método de pago fue rechazado. Agregue o seleccione un método de pago alternativo.',
    INVALID_CARD_ERROR: 'El método de pago no es válido. Agrega un nuevo método de pago.',
    UNSUPPORTED_CARD_ERROR: 'Su forma de pago no es compatible. Solo se aceptan VISA, MasterCard, American Express, Discover, JSC y UnionPay.'
  }
};

export default square;