import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Col, Container, Row} from 'reactstrap';

class CardBodyField extends Component {
  render() {
    const { label, value, md = 6, className } = this.props;
    
    return (
      <Container fluid>
        <Row className='my-1'>
          <Col>
            <span className='d-print-none has-font-size-2 font-weight-bold mr-2'>{label}</span>
          </Col>
          <Col className={`d-print-block d-none ${className}`}>
            <span className='font-weight-bold'>{label}</span>
          </Col>
          <Col md={md} data-test-id={this.props.claimDataTestId}>
            {value}
          </Col>
        </Row>
      </Container>
    )
  }
}

CardBodyField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  claimDataTestId: PropTypes.string,
  md: PropTypes.number,
  className: PropTypes.string
};

export default injectIntl(CardBodyField);