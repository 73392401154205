import React, {useState} from 'react';
import {Col, Container, FormGroup, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import {Button, Icon, Input} from '../../components';

const CreateWishlistModal = (props) => {
  const nameValidationRegex = /.+/; // Require at least one character
  
  const [name, setName] = useState({value: '', isValid: false});
  const [trySubmit, setTrySubmit] = useState(false);
  
  const handleNameChange = (obj) => {
    setName({
      value: obj.value,
      isValid: obj.isValid
    });
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCreateClick();
    }
  };
  
  const validateName = (value) => {
    return value ? new RegExp(nameValidationRegex).test(value) : false;
  };
  
  const handleCreateClick = () => {
    setTrySubmit(true);
    
    let nameIsValid = validateName(name.value);
    
    setName({
      value: name.value,
      isValid: nameIsValid
    });

    if (nameIsValid) {
      props.handleCreateClick(name.value);
    }
  };
  
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered id='edit-wishlist-modal'>
      <ModalHeader toggle={props.toggle}>
        <div className='d-flex flex-row align-items-center'>
          <Icon type='pencil-alt' color='dark-gray' size='sm'/>
          <div className='ml-2'>Create Shopping List</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row>
            <Col>
              <FormGroup data-test-id='create-wishlist-name-input'>
                <Label for='wishlist-name'>Name</Label>
                <Input
                  type='text'
                  value={name.value}
                  isValid={name.isValid}
                  onChange={handleNameChange}
                  onKeyPress={handleKeyPress}
                  validationMethod={validateName}
                  validationMessage='Name is required'
                  trySubmit={trySubmit}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className='justify-content-end'>
            <Col xs='auto'>
              <Button data-test-id='wishlist-create' onClick={handleCreateClick} color='primary' isLoading={props.isProcessing}>Create</Button>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default CreateWishlistModal;