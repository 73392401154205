import React from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink} from 'reactstrap';
import PropTypes from 'prop-types';

import {Icon, Link} from '../../components';
import user from "../../helpers/user";

export default class DesktopNavMenu extends React.Component {
  render() {
    let adminMenuItem = this.props.showAdminMenu && (
      <DropdownItem tag={Link} to='admin'>Admin</DropdownItem>
    );
    let downloadStatementMenuItem = this.props.showDownloadStatementMenuItem && (
      <DropdownItem onClick={this.props.getCustomerStatement}>
        <div className='d-flex flex-row align-items-center'>
          Download Statement
          <Icon type='file-alt' color='dark-gray' size='sm' className='ml-2'/>
        </div>
      </DropdownItem>
    );
    let wishlistsMenuItem = this.props.showWishlistsMenuItem && (
      <DropdownItem tag={Link} to='shoppingLists'>Shopping Lists</DropdownItem>
    );
    
    return (
      this.props.isAuthenticated ? (
        <Dropdown nav isOpen={this.props.isProfileDropdownOpen} toggle={this.props.toggleProfileDropdown}>
          <DropdownToggle nav caret>
            <Icon type='user-circle'/>
          </DropdownToggle>
          <DropdownMenu>
            {this.props.isConnectUser && 
              <DropdownItem tag='a' href={this.props.accountsUrl} target='_blank'>
                <div className='d-flex flex-row align-items-center'>
                  <div data-test-id='nav-account'>
                    <div>Account</div>
                    <div className='has-font-size-1'>{this.props.accountNumber}</div>
                  </div>
                  <Icon type='external-link-alt' color='dark-gray' size='sm' className='ml-2'/>
                </div>
              </DropdownItem>
            }
            {this.props.isConnectUser ?
              <DropdownItem tag={Link} to='profile'>Preferences</DropdownItem>
              : <DropdownItem tag={Link} to='profile/creditcards'>Manage Credit Cards</DropdownItem>
            }
            {
              user.isCompanyAdmin() && <DropdownItem tag={Link} to='companyadmin'>Company Admin</DropdownItem>
            }
            {wishlistsMenuItem}
            {adminMenuItem}
            {downloadStatementMenuItem}
            <DropdownItem divider />
            <DropdownItem onClick={this.props.logout}>Sign Out</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <NavItem>
          <NavLink onClick={this.props.login} data-test-id='nav-sign-in' className='has-pointer-icon'>Sign In</NavLink>
        </NavItem>
      )
    );
  }
}

DesktopNavMenu.propTypes = {
  isAuthenticated: PropTypes.bool,
  isProfileDropdownOpen: PropTypes.bool,
  toggleProfileDropdown: PropTypes.func,
  accountsUrl: PropTypes.string,
  accountNumber: PropTypes.string,
  showDownloadStatementMenuItem: PropTypes.bool,
  isConnectUser: PropTypes.bool,
  getCustomerStatement: PropTypes.func,
  showAdminMenu: PropTypes.bool,
  showWishlistsMenuItem: PropTypes.bool,
  logout: PropTypes.func,
  login: PropTypes.func
};