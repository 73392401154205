/* eslint-disable react/no-string-refs */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardHeader } from '@met/react-components';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { Col, Container, Row } from 'reactstrap';
import auth from '../../auth';
import { getSession } from '../../helpers/localStorage';

const SSOFailureMessage = () => {
  return <p>There was a problem accessing your account. Please reach out to the SproutLoud Customer Support team at <a id="ssofail-customer-support-phone" href="tel:+877-634-9260">877-634-9260</a> or <a id="ssofail-customer-support-email" href="mailto:customerservice@sproutloud.com">customerservice@sproutloud.com</a></p>;
};

class Brandworks extends Component {
  flyerServiceLoad = () => {
    let meself = this;
    this.props.getFlyerService()
      .then((response) => {
        meself.refs.flyerRedirectForm.action = response.data.targetUrl;
        meself.refs.flyerRedirectPassword.value = response.data.password;
        meself.refs.flyerRedirectToken.value = response.data.token;
        meself.refs.flyerRedirectRedirectUrl.value = response.data.redirectUrl;
        meself.refs.flyerRedirectForm.submit();
      }).catch(() => {
        toastr.error(this.props.intl.formatMessage({ id: 'brandworks.flyerserviceFailureTitle' }), this.props.intl.formatMessage({ id: 'brandworks.ssoFailure' }), { timeOut: 0 });
      });
  };

  pinnacleLoad = () => {
    let meself = this;
    this.props.getPinnacle()
      .then((response) => {
        meself.refs.pinnacleRedirectForm.action = response.data.targetUrl;
        meself.refs.pinnacleRedirectPassword.value = response.data.password;
        meself.refs.pinnacleRedirectToken.value = response.data.token;
        meself.refs.pinnacleRedirectRedirectUrl.value = response.data.redirectUrl;
        meself.refs.pinnacleRedirectForm.submit();
      }).catch(() => {
        toastr.error(this.props.intl.formatMessage({ id: 'brandworks.pinnacleFailureTitle' }), this.props.intl.formatMessage({ id: 'brandworks.ssoFailure' }), { timeOut: 0 });
      });
  };

  sproutLoudLoad = () => {
    let meself = this;
    this.props.getSproutLoud()
      .then((response) => {
        meself.refs.sproutLoudRedirectForm.action = response.data.targetUrl;
        meself.refs.sproutLoudRedirectlogin_data.value = response.data.body;
        meself.refs.sproutLoudRedirectForm.submit();
      }).catch(() => {
        toastr.error(this.props.intl.formatMessage({ id: 'brandworks.sproutloudFailureTitle' }), { component: <SSOFailureMessage />, timeout: 0 });
      });
  };

  render() {

    if (!auth.isAuthenticated()) {
      return ('');
    } else {
      let session = getSession();
      let hduUrl = session.heavyDutyUniversity ? session.heavyDutyUniversity.url : 'https://go.bluevolt.com/milwaukee/s/';
      let digitalLibraryUrl = session.digitalLibrary ? session.digitalLibrary.url : 'https://milwaukeetool.widencollective.com/dam';
      let catalogUrl = session.catalogUrl ? session.catalogUrl.url : 'https://www.milwaukeetool.com/catalog';
      return (
        <Card className='info-card'>
          <CardHeader className='header-secondary'>
            <FontAwesomeIcon icon='bullhorn' className='is-color-dark-gray mr-2' />
            <FormattedMessage id='home.brandworksCardTitle' />
          </CardHeader>
          <Container className='tiles'>
            <Row>
              <Col xs={12} lg className='tile is-mock-link' onClick={this.sproutLoudLoad} data-test-id='heavy-duty-marketing-center'>
                <FontAwesomeIcon className='icon-link' icon='external-link-alt' />
                <div className='icon'><FontAwesomeIcon icon='truck-monster' /></div>
                <div>Heavy Duty Marketing Center (Co-op)</div>
              </Col>
              <Col xs={6} lg className='tile is-mock-link' onClick={this.flyerServiceLoad} data-test-id='flyer-service'>
                <FontAwesomeIcon className='icon-link' icon='external-link-alt' />
                <div className='icon'><FontAwesomeIcon icon='file-invoice' /></div>
                <div>Flyer Service</div>
                <form ref='flyerRedirectForm' method='POST' target='_blank'>
                  <input name='password' type='hidden' ref='flyerRedirectPassword' />
                  <input name='token' type='hidden' ref='flyerRedirectToken' />
                  <input name='redirecturl' type='hidden' ref='flyerRedirectRedirectUrl' />
                </form>
              </Col>
              <Col xs={6} lg className='tile' data-test-id='digital-library'>
                <a href={digitalLibraryUrl} target='_blank' rel='noopener noreferrer'>
                  <FontAwesomeIcon className='icon-link' icon='external-link-alt' />
                  <div className='icon'><FontAwesomeIcon icon='book-reader' /></div>
                  <div>Digital Library</div>
                </a>
              </Col>
              <Col xs={6} lg className='tile is-mock-link' onClick={this.pinnacleLoad} data-test-id='milwaukee-swag'>
                <FontAwesomeIcon className='icon-link' icon='external-link-alt' />
                <div className='icon'><FontAwesomeIcon icon='tshirt' /></div>
                <div>Milwaukee SWAG</div>
                <form ref='pinnacleRedirectForm' method='POST' target='_blank'>
                  <input name='password' type='hidden' ref='pinnacleRedirectPassword' />
                  <input name='token' type='hidden' ref='pinnacleRedirectToken' />
                  <input name='redirecturl' type='hidden' ref='pinnacleRedirectRedirectUrl' />
                </form>
              </Col>
              <Col xs={6} lg className='tile is-mock-link' hidden={!this.props.isAuthenticatedAndHasAsc}>
                <a href={hduUrl} target='_blank' rel='noopener noreferrer'>
                  <FontAwesomeIcon className='icon-link' icon='external-link-alt' />
                  <div className='icon'><FontAwesomeIcon icon='graduation-cap' /></div>
                  <div>Heavy Duty University</div>
                </a>
              </Col>
              <Col xs={6} lg className='tile is-mock-link' hidden={!this.props.isEmployeeAccount} >
                <a href={catalogUrl} target='_blank' rel="noopener noreferrer">
                  <FontAwesomeIcon className='icon-link' icon='external-link-alt' />
                  <div className='icon'><FontAwesomeIcon icon='book-open' /></div>
                  <div>Milwaukee Catalog</div>
                </a>
              </Col>
              <form ref='sproutLoudRedirectForm' method='POST' target='_blank'>
                <input name='login_data' type='hidden' ref='sproutLoudRedirectlogin_data' />
              </form>
            </Row>
          </Container>
        </Card>
      );
    }
  }
}

export default injectIntl(Brandworks);