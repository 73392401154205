import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from 'reactstrap';

import { Link, PageLoader } from '../../components';
import { defectivePartsClaimActions } from '../../store';
import history from '../../history';
import Stepper from '../../components/Stepper/Stepper';
import DefectivePartClaim from './DefectivePartClaim';
import ReviewClaim from './ReviewClaim';

const partValidationErrorMap = {
  1: 'partNotInCatalog',
  2: 'toolNotValidClaim',
  3: 'componentNotValidClaim',
  4: 'accountNotAuthorized',
  5: 'flatRatePriceNotSet'
}

class DefectivePartWizard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentStep: 1,
      claim: {}
    }
  }
  next = (claim) => {
    const { intl } = this.props;
    this.setState({
      ...this.state,
      isLoading: true,
      claim
    }, () =>
      this.props.validateDefectClaim({ ...claim, accountNumber: this.props.accountNumber }).then(payload => {
        this.setState({
          ...this.state,
          currentStep: 2,
          isLoading: false
        });
      }).catch((json) => {
        const { errors, error } = json;
        if (errors) {
          Object.keys(errors).forEach(function (error) {
            errors[error].forEach(errorMessage => {
              toastr.error(intl.formatMessage({ id: 'warranty.submissionError.' + errorMessage }), { timeOut: 5000 });
            });
          });
        }
        if (error && error.details) {
          error.details.forEach(errorMessage => {
            if (errorMessage.ErrorCode) {
              toastr.error(intl.formatMessage({ id: `warranty.submissionError.${partValidationErrorMap[errorMessage.ErrorCode]}` }), { timeOut: 5000 });
            }
            else if (errorMessage.Name) {
              this.mapErrorMessage(errorMessage);
            }
            else {
              toastr.error(intl.formatMessage({ id: 'warranty.submissionError.' + errorMessage }), { timeOut: 5000 });
            }
          });
        }
        this.setState({
          ...this.state,
          isLoading: false
        });
      })
    );
  };

  back = () => {
    this.setState({
      ...this.state,
      currentStep: this.state.currentStep - 1
    });
  }

  submit = () => {
    const { claim } = this.state;
    const { intl } = this.props;
    this.setState({
      ...this.state,
      isLoading: true
    }, () => {
      this.props.createDefectClaim({ ...claim, accountNumber: this.props.accountNumber }).then(payload => {
        if (payload.claimId) {
          history.push(`/warranty/${payload.claimId}`);
        }
      }).catch((json) => {
        const { errors, error } = json;
        if (errors) {
          Object.keys(errors).forEach(function (error) {
            errors[error].forEach(errorMessage => {
              toastr.error(intl.formatMessage({ id: 'warranty.submissionError.' + errorMessage }));
            });
          });
        }
        if (error && error.details) {
          error.details.forEach(errorMessage => {
            if (errorMessage.ErrorCode) {
              this.mapErrorMessage(errorMessage);
            }
            else {
              toastr.error(intl.formatMessage({ id: 'warranty.submissionError.' + errorMessage }));
            }
          });
        }
        this.setState({
          ...this.state,
          isLoading: false
        });
      });
    });
  };


  mapErrorMessage = (errorMessage) => {
    const { intl } = this.props;
    toastr.error(intl.formatMessage({ id: 'warranty.submissionError.' + errorMessage.Name }, { itemNumber: errorMessage.Data }), { timeOut: 5000 });
  }

  render() {
    const { locale } = this.props.match.params;
    const { intl } = this.props;
    return (
      <div id="defective-claim-wizard" className="content-wrapper animate-bottom">
        <Container>
          <Row id="defective-claim-links">
            <Col>
              <Nav>
                <NavItem data-test-id='warranty-claim-search'>
                  <Link to='warranty' locale={locale} isActive={false} className='nav-link'><span>Search</span></Link>
                </NavItem>
                <NavItem data-test-id='warranty-claim-new-claim'>
                  <Link to='warranty/newClaim' locale={locale} isActive={false} className='nav-link'><span>New Claim</span></Link>
                </NavItem>
                <NavItem data-test-id='warranty-claim-new-defective-part-claim'>
                  <Link to='warranty/defectiveClaim' locale={locale} isActive={true} className='nav-link'><span>New Defective Part Claim</span></Link>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row id="defective-claim-stepper">
            <Col>
              <Card>
                <CardBody>
                  <Stepper steps={[
                    { label: intl.formatMessage({ id: 'warranty.repairDetails' }), icon: 'wrench', isComplete: this.state.currentStep > 1, isActive: this.state.currentStep === 1 },
                    { label: intl.formatMessage({ id: 'warranty.claimReview' }), icon: 'clipboard-check', isComplete: this.state.currentStep > 2, isActive: this.state.currentStep === 2 },
                    { label: intl.formatMessage({ id: 'warranty.claimConfirmation' }), icon: 'check', isComplete: this.state.currentStep > 3, isActive: this.state.currentStep === 3 }
                  ]} beforeToRender={1} afterToRender={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.isLoading && <PageLoader />}
          {!this.state.isLoading && this.state.currentStep === 1 &&
            <DefectivePartClaim
              next={this.next}
              claim={this.state.claim}
            />
          }
          {!this.state.isLoading && this.state.currentStep === 2 &&
            <ReviewClaim
              claim={this.props.validatedClaim}
              back={this.back}
              submit={this.submit}
            />
          }
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    validatedClaim: state.defectivePartsClaim && state.defectivePartsClaim.validateDefectClaim && state.defectivePartsClaim.validateDefectClaim.validatedClaim,
    newClaimid: state.defectivePartsClaim && state.defectivePartsClaim.createDefectClaim && state.defectivePartsClaim.createDefectClaim.claimId,
    accountNumber: state.account.currentAccount.accountDetails.accountNumber
  }
};

const mapDispatchToProps = dispatch => ({
  validateDefectClaim: (claim) => dispatch(defectivePartsClaimActions.validateClaim(claim)),
  createDefectClaim: (claim) => dispatch(defectivePartsClaimActions.createDefectClaim(claim))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DefectivePartWizard));