import createFetchAction from './createFetchAction';

export const LOADING_WARRANTY_SEARCH = 'LOADING_WARRANTY_SEARCH';
export const LOADING_WARRANTY_SEARCH_SUCCESS = 'LOADING_WARRANTY_SEARCH_SUCCESS';
export const LOADING_WARRANTY_SEARCH_ERROR = 'LOADING_WARRANTY_SEARCH_ERROR';

export const LOADING_SERVICE_MANAGERS = 'LOADING_SERVICE_MANAGERS';
export const LOADING_SERVICE_MANAGERS_SUCCESS = 'LOADING_SERVICE_MANAGERS_SUCCESS';
export const LOADING_SERVICE_MANAGERS_ERROR = 'LOADING_SERVICE_MANAGERS_ERROR';

export const CLEAR_WARRANTIES = 'CLEAR_WARRANTIES';

export const initialState = {
  warrantySearch: {
    warranties: [],
    isLoading: false,
    isError: false,
    totalPages: 0,
    totalCount: 0,
    count: 0,
  },
  serviceManagers: {
    serviceManagers: [],
    isLoading: false,
    isError: false
  }
};

export const actionCreators = {
  searchWarranties: (query, accountNumber) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/Search`,
      method: 'POST',
      startAction: LOADING_WARRANTY_SEARCH,
      body: {
        ...query,
        endDate: query.endDate && query.endDate.toISOString().split('T')[0],
        startDate: query.startDate && query.startDate.toISOString().split('T')[0],
        accountNumber
      }
    })
  },
  getServiceManagers: () => {
    return createFetchAction({
      url: `/warrantyapi/warranty/ServiceManagers`,
      startAction: LOADING_SERVICE_MANAGERS
    })
  },
  clearWarranties: () => {
    return {type: CLEAR_WARRANTIES};
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case LOADING_WARRANTY_SEARCH:
      return {
        ...state,
        warrantySearch: {
          ...state.warrantySearch,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_WARRANTY_SEARCH_SUCCESS:
      return {
        ...state,
        warrantySearch: {
          warranties: action.payload.data,
          totalPages: action.payload.totalPages || 0,
          totalCount: action.payload.totalCount,
          count: action.payload.count,
          isLoading: false,
          isError: false
        }
      };
    case LOADING_WARRANTY_SEARCH_ERROR:
      return {
        ...state,
        warrantySearch: {
          ...state.warrantySearch,
          isLoading: false,
          isError: true
        }
      };
    case LOADING_SERVICE_MANAGERS:
      return {
        ...state,
        serviceManagers: {
          ...state.serviceManagers,
          isLoading: true,
          isError: false
        }
      };
    case LOADING_SERVICE_MANAGERS_SUCCESS:
      return {
        ...state,
        serviceManagers: {
          serviceManagers: action.payload.data,
          isLoading: false,
          isError: false
        }
      };
    case CLEAR_WARRANTIES:
      return {
        ...state,
        warrantySearch: {
          warranties: []
        }
      };
    default:
      return state;
  }
};