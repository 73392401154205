import React from 'react';
import { Loader } from '@met/react-components';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const TilesLoader = () => {
  return (
    <Wrapper>
      <Loader type='tiles' />
    </Wrapper>
  );
};

export default TilesLoader;
