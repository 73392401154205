import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {Alert, Col, Collapse, Container, ListGroupItem, Row} from 'reactstrap';
import {LoadingDots, Circle} from '../../components';
import OrderLinesTable from '../../components/OrderLinesTable/OrderLinesTable';
import user from '../../helpers/user';

class PendingOrderPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
      toolTipText: <>Order failed. Please contact <a style={{ backgroundColor: 'white', borderRadius: '5px', border: '2px solid #DB011C' }} href={`mailto: CXHelp@milwaukeetool.com${(user.isMilwaukeeToolEmployee() ? `?subject=Urgent%20-%20Employee Order Needs Attention, ${this.props.order.quoteNumber}` : '')}`}>&nbsp;CXHelp@milwaukeetool.com&nbsp;</a><br />or call<br /><a style={{ backgroundColor: 'white', borderRadius: '5px', border: '2px solid #DB011C' }} href={'tel:1-800-729-3878'}>&nbsp; 1-800-SAWDUST(1-800-729-3878)&nbsp;</a></>
    };
  }

  toggle = () => {
    this.setState({
      ...this.state,
      collapse: !this.state.collapse
    });
  };

  render() {
    const {order} = this.props;

    let orderLinesTable = <OrderLinesTable locale={this.props.locale} orderLines={order.orderLines}/>;

    let orderFailedMarkup = order.orderStatusId === 8 && (
      <span style={{ position: 'absolute', paddingLeft: '35px', paddingTop: '10px' }} >
        <Circle radius={11} color={'red'} id={order.id} toolTipText={this.state.toolTipText} delay={2500} tooltipClass={'invalid-order-tooltip'} />
      </span>
    );

    let orderInfoMarkup = (<div className="order-info value">{order.orderStatusId === 8 ? 'N/A' : <LoadingDots/>}</div>);
    
    return (
      <ListGroupItem onClick={this.toggle} className='order-preview'>
        <Container fluid className='order-preview-header p-2'>
          <Row>
            <Col xs='auto' className='mr-2 d-flex align-items-md-center'>
              <span className='toggle'>
                <FontAwesomeIcon icon={this.state.collapse ? 'angle-down' : 'angle-right'}/>
              </span>
            </Col>
            <Col>
              <Container fluid>
                <Row>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='orders.purchaseOrderLabel'/></div>
                    <div data-test-id={`pending-order-purchase-order-${order.quoteNumber}`} className="order-info value">{order.purchaseOrderNumber || 'N/A'}</div>
                  </Col>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='orders.orderDateLabel'/></div>
                    <div data-test-id={`pending-order-date-${order.quoteNumber}`} className="order-info value">
                      <FormattedDate value={new Date(order.orderDate)}/>
                    </div>
                  </Col>
                  <Col xs={6} md className='mb-2 mb-md-0'>
                    <div className="order-info label"><FormattedMessage id='orders.quoteNumberLabel'/></div>
                    <div data-test-id={`pending-order-quote-number-${order.quoteNumber}`} className="order-info value">{order.quoteNumber}</div>
                  </Col>
                  <Col xs={6} md className='mb-2 mb-md-0' data-test-id={`pending-order-number-${order.quoteNumber}`} >
                    <div className="order-info label"><FormattedMessage id='orders.orderNumberLabel'/>
                      {orderFailedMarkup}
                    </div>
                    {orderInfoMarkup}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Collapse isOpen={this.state.collapse}>
          <hr/>
          <Container fluid className='p-2'>
            <Row>
              <Col>
                <Alert color='info'>
                  <FontAwesomeIcon icon='info-circle' className='mr-3'/>
                  <FormattedMessage id='orders.orderProcessingMessage'/>
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col>
                {orderLinesTable}
              </Col>
            </Row>
          </Container>
        </Collapse>
      </ListGroupItem>
    );
  }
}

export default injectIntl(PendingOrderPreview);
