import React, {Component} from 'react';
import ReactQuill from 'react-quill';
import className from 'classnames';
import PropTypes from 'prop-types';

class RichTextEditor extends Component {
  constructor(props){
    super(props);

    this.state = {
      editorHtml: this.props.html || '',
      theme: 'snow',
      isValid: true
    };
  }
  
  handleChange = (html, delta, source, editor) => {
    let text = editor.getText();
    let isValid = this.props.validationRegex ? new RegExp(this.props.validationRegex).test(text) : this.state.isValid;

    this.setState({
      ...this.state,
      editorHtml: html,
      isValid
    });
    
    if (this.props.onChange)
      this.props.onChange(html, isValid);
  };

  render() {
    const {validationMessage} = this.props;
    
    const quillClasses = className(
      !this.state.isValid && 'invalid'
    );
    
    return (
      <div className='rich-text-editor'>
        <ReactQuill
          theme={this.state.theme}
          onChange={this.handleChange}
          value={this.state.editorHtml}
          modules={RichTextEditor.modules}
          formats={RichTextEditor.formats}
          placeholder={''}
          className={quillClasses}
        />
        {!this.state.isValid && <div className='invalid-feedback d-block'>{validationMessage}</div>}
      </div>
    );
  }
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RichTextEditor.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    [{'color': []}, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'},
      {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichTextEditor.formats = [
  'header', 'font', 'size',
  'color', 'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

RichTextEditor.propTypes = {
  validationRegex: PropTypes.instanceOf(RegExp),
  validationMessage: PropTypes.string,
  onChange: PropTypes.func
};

export default RichTextEditor;