import createFetchAction from './createFetchAction';

export const LOADING_PRICES = 'LOADING_PRICES';
export const LOADING_PRICES_SUCCESS = 'LOADING_PRICES_SUCCESS';
export const LOADING_PRICES_ERROR = 'LOADING_PRICES_ERROR';

export const initialState = {
  priceMap: {},
  isLoading: false,
  isError: false
};

export const actionCreators = {
  getPrices: (skus, accountNumber) => {
    if (!skus || skus.length === 0) return { type: 'NOOP' };
    const skusString = skus.join ? skus.join(',') : skus;
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/prices`,
      body: {skus: skusString, accountNumber},
      startAction: LOADING_PRICES,
    });
  },
  clearPriceCache: () => {    
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === LOADING_PRICES) {
    return {
      ...state,
      isLoading: true,
      isError: false
    };
  }

  if (action.type === LOADING_PRICES_SUCCESS) {
    let priceMap = action.payload.data.reduce(function(map, obj) {
      map[obj.itemId] = obj;
      return map;
    }, {});
    
    let updatedMap = {...state.priceMap, ...priceMap};

    return {
      ...state,
      priceMap: updatedMap,
      isLoading: false,
      isError: false
    };
  }

  if (action.type === LOADING_PRICES_ERROR) {
    return {
      ...state,
      isLoading: false,
      isError: true
    };
  }

  if (action.type === "LOADING_ACCOUNT_SUCCESS") {
    return {
      ...state,
      priceMap: {},
      isLoading: false,
      isError: false
    };
  }

  return state;
};