import { getSession } from '../helpers/localStorage';
import history from '../history';
import auth from '../auth';

const createFetchAction = (config) => (dispatch, getState) => {
  dispatch({ ...config.actionPayload, type: config.startAction });
  let body = undefined;
  let headers = {
    Pragma: 'no-cache'
  };
  if (config.files) {
    let form = new FormData();
    for (var index = 0; index < config.files.length; index++) {
      var element = config.files[index];
      form.append(element.name, element.file);
    }
    form.append("model", JSON.stringify(config.body))
    body = form;
  } else {
    body = JSON.stringify(config.body);
    headers = {
      ...headers,
      'Content-Type': 'application/json'
    };
  }

  let session = getSession();
  

  // if the user is authenticated, automatically attach their jwt to the request
  if (session && session.accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${session.accessToken}`
    }
  }
  return fetch(config.url, {
    method: config.method || 'GET',
    headers,
    body
  })
    .then(response => {
      // handle specific errors
      switch (response.status) {
        case 500: console.error('Some server error'); break;
        case 404:
          if (!config.ignore404) {
            history.replace('/NotFound');
          }
          break;
        case 403:
          history.replace('/');
          break;
        case 401:
          auth.login();
          break;
        default: break;
      }

      // Only if the response is ok should we continue
      if (response.ok) {
        if (config.isBlob) {
          return response.blob();
        } else {
          return response.text().then(text => {
            return text ? JSON.parse(text) : {}
          });
        }
      } else {
        return response.text()
          .then((responseText) => {
            if (responseText.length) {
              try {
                return Promise.reject(JSON.parse(responseText));
              } catch {
                // not json, reject with raw text
                return Promise.reject(responseText);
              }
            }
            else {
              return Promise.reject(response);
            }
          });
      }
    })
    .then(payload => {
      dispatch({ ...config.actionPayload, type: config.startAction + '_SUCCESS', payload: payload });
      return payload;
    })
    .catch(error => {
      dispatch({ ...config.actionPayload, type: config.startAction + '_ERROR' });
      return Promise.reject(error);
    });
};

export default createFetchAction;