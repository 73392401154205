import React, {Component} from 'react';

import {PageLoader} from '../../components';
import user from '../../helpers/user';
import history from '../../history';

class Admin extends Component {
  componentDidMount() {
    if (user.isAnnouncementsAdmin())
      history.replace('/admin/announcements');
    else if (user.isProductAdmin() || user.isAdmin() || user.isSuperAdmin())
      history.replace('/admin/products');
    else if (user.canApproveWarrantyClaims())
      history.replace('/admin/warrantyFilters');
    else
      history.replace('/');
  }

  render() {
    return <PageLoader/>;
  }
}

export default Admin;