import React from 'react';
import {
  Col,
  Container,
  Row
} from 'reactstrap';

import BaseLayout from './BaseLayout';

class Layout extends React.Component {
  render() {
    let title = this.props.title && (
      <Row className='my-3'>
        <Col>
          <h2>{this.props.title}</h2>
        </Col>
      </Row>
    );
    
    return (
      <BaseLayout {...this.props}>
        <Container>
          {title}
          {this.props.children}
        </Container>
      </BaseLayout>
    );
  }
}

export default Layout;