import Enumerable from 'linq';

export const wishlistLineGrouper = (savedForLaterLines) =>{
  let lines = [];

  Enumerable.from(savedForLaterLines)
    .groupBy(x=>x.customLogoRefId)
    .forEach((group, index)=>{
      if(group.key() > ''){
        let primary = group.firstOrDefault(x=>x.sku.endsWith("C") || x.sku.endsWith("c"));
        if(!primary){
          primary = group.first();
          primary.children = group.skip(1).toArray();
        }else{
          primary.children = group.where(x => !(x.sku.endsWith("C") || x.sku.endsWith("c"))).toArray();
        }
        lines.push(primary);
      }else{
        group.forEach(line=>{
          lines.push(line)
        });
      }
    })
  
  return lines;  
 }