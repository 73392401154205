import { getProfile, updateProfile } from "../helpers/localStorage";
import createFetchAction from './createFetchAction';

export const LOADING_ACCOUNT = 'LOADING_ACCOUNT';
export const LOADING_ACCOUNT_SUCCESS = 'LOADING_ACCOUNT_SUCCESS';
export const LOADING_ACCOUNT_ERROR = 'LOADING_ACCOUNT_ERROR';

export const LOADING_CUSTOMERSTATEMENT = 'LOADING_CUSTOMERSTATEMENT';
export const LOADING_CUSTOMERSTATEMENT_SUCCESS = 'LOADING_CUSTOMERSTATEMENT_SUCCESS';
export const LOADING_CUSTOMERSTATEMENT_ERROR = 'LOADING_CUSTOMERSTATEMENT_ERROR';

export const LOADING_IMPERSONATED_ACCOUNT = 'LOADING_IMPERSONATED_ACCOUNT';
export const LOADING_IMPERSONATED_ACCOUNT_SUCCESS = 'LOADING_IMPERSONATED_ACCOUNT_SUCCESS';
export const LOADING_IMPERSONATED_ACCOUNT_ERROR = 'LOADING_IMPERSONATED_ACCOUNT_ERROR';
export const CLEAR_IMPERSONATED_ACCOUNT = 'CLEAR_IMPERSONATED_ACCOUNT';

export const LOADING_IMPERSONATED_CUSTOMERSTATEMENT = 'LOADING_IMPERSONATED_CUSTOMERSTATEMENT';
export const LOADING_IMPERSONATED_CUSTOMERSTATEMENT_SUCCESS = 'LOADING_IMPERSONATED_CUSTOMERSTATEMENT_SUCCESS';
export const LOADING_IMPERSONATED_CUSTOMERSTATEMENT_ERROR = 'LOADING_IMPERSONATED_CUSTOMERSTATEMENT_ERROR';

export const initialState = {
  loadingAccountNumber: '',
  isImpersonating: false,
  currentAccount: {
    isLoading: false,
    isError: false,
    canAccessCustomerStatement: false,
    customerStatement: {
      isLoading: false,
      isError: false,
    },
    accountDetails: {
      accountNumber: '',
      name: '',
      hasChildren: false,
      children: [],
      hasAsc: false,
      address: undefined,
      currencyCode: '',
      isEmployeeAccount: false,
      isCreditCardOnlyAccount: false,
      email: '',
      isFillOrKill: null
    }
  },
  impersonatedAccount: {
    isLoading: false,
    isError: false,
    canAccessCustomerStatement: false,
    customerStatement: {
      isLoading: false,
      isError: false,
    },
    accountDetails: {
      accountNumber: '',
      name: '',
      hasChildren: false,
      children: [],
      hasAsc: false,
      address: undefined,
      currencyCode: '',
      isEmployeeAccount: false,
      isCreditCardOnlyAccount: false
    }
  }
};

export const actionCreators = {
  loadAccount: (accountNumber) => {
    return createFetchAction({
      url: `/accountapi/account${(accountNumber ? `/${accountNumber}` : ``)}`,
      ignore404: true,
      startAction: LOADING_ACCOUNT,
      actionPayload: { loadingAccountNumber: accountNumber }
    });
  },
  getCustomerStatementForAccount: (accountNumber) => {
    return createFetchAction({
      url: `/accountapi/customerstatement${(accountNumber ? `/${accountNumber}` : ``)}`,
      startAction: LOADING_CUSTOMERSTATEMENT,
      isBlob: true
    });
  },
  loadImpersonatedAccount: (accountNumber) => {
    return createFetchAction({
      url: `/accountapi/account${(accountNumber ? `/${accountNumber}` : ``)}`,
      ignore404: true,
      startAction: LOADING_IMPERSONATED_ACCOUNT,
      actionPayload: { loadingAccountNumber: accountNumber }
    });
  },
  clearImpersonatedAccount: () => {
    return { type: CLEAR_IMPERSONATED_ACCOUNT };
  },
  getCustomerStatementForImpersonatedAccount: (accountNumber) => {
    return createFetchAction({
      url: `/accountapi/customerstatement${(accountNumber ? `/${accountNumber}` : ``)}`,
      startAction: LOADING_CUSTOMERSTATEMENT,
      isBlob: true
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case LOADING_ACCOUNT:
      return {
        ...state,
        loadingAccountNumber: action.loadingAccountNumber,
        currentAccount: {
          ...initialState.currentAccount,
          isLoading: true
        }
      };
    case LOADING_ACCOUNT_SUCCESS:{
      let profile = getProfile();

      if (profile.accountNumber === action.payload.accountNumber) {
        profile.cachedAccountDetails = action.payload;  // TODO: Make sure I didn't screw this up
        updateProfile(profile);
      }

      return {
        ...state,
        loadingAccountNumber: '',
        currentAccount: {
          isLoading: false,
          isError: false,
          canAccessCustomerStatement: action.payload.accountStatement !== 4,
          customerStatement: {
            ...state.currentAccount.customerStatement
          },
          accountDetails: {
            ...(action.payload || state.currentAccount.accountDetails),
            email: profile.email
          }
        }
      };
    }
    case LOADING_ACCOUNT_ERROR:{
      let profile2 = getProfile();

      if ((profile2.accountNumber === action.loadingAccountNumber || !action.loadingAccountNumber)
        && profile2.cachedAccountDetails
        && profile2.cachedAccountDetails.accountNumber === action.loadingAccountNumber) {
        // There is cached account data we can use
        return {
          ...state,
          loadingAccountNumber: '',
          currentAccount: {
            isLoading: false,
            isError: false,
            accountDetails: profile2.cachedAccountDetails,
            canAccessCustomerStatement: profile2.cachedAccountDetails.accountStatement !== 4
          }
        };
      } else {
        // There is no cached account data to use
        return {
          ...state,
          loadingAccountNumber: '',
          currentAccount: {
            ...state.currentAccount,
            isLoading: false,
            isError: true,
            canAccessCustomerStatement: false
          }
        };
      }
    }
    case LOADING_IMPERSONATED_ACCOUNT:
      return {
        ...state,
        loadingAccountNumber: action.loadingAccountNumber,
        impersonatedAccount: {
          ...initialState.impersonatedAccount,
          isLoading: true
        }
      };
    case LOADING_IMPERSONATED_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingAccountNumber: '',
        isImpersonating: true,
        impersonatedAccount: {
          isLoading: false,
          isError: false,
          canAccessCustomerStatement: action.payload.accountStatement !== 4,
          customerStatement: {
            ...state.currentAccount.customerStatement
          },
          accountDetails: {
            ...(action.payload || state.impersonatedAccount.accountDetails)
          }
        }
      };
    case LOADING_IMPERSONATED_ACCOUNT_ERROR:
      return {
        ...state,
        loadingAccountNumber: '',
        isImpersonating: false,
        impersonatedAccount: {
          ...state.impersonatedAccount,
          isLoading: false,
          isError: true,
          canAccessCustomerStatement: false
        }
      };
    case CLEAR_IMPERSONATED_ACCOUNT:
      return {
        ...state,
        isImpersonating: false,
        impersonatedAccount: {
          ...initialState.impersonatedAccount
        }
      };
    case LOADING_CUSTOMERSTATEMENT:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          customerStatement: {
            isLoading: true,
            isError: false
          }
        }
      };
    case LOADING_CUSTOMERSTATEMENT_SUCCESS:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          customerStatement: {
            isLoading: false,
            isError: false
          }
        }
      };
    case LOADING_CUSTOMERSTATEMENT_ERROR:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          customerStatement: {
            isLoading: false,
            isError: true
          }
        }
      };
    case LOADING_IMPERSONATED_CUSTOMERSTATEMENT:
      return {
        ...state,
        impersonatedAccount: {
          ...state.impersonatedAccount,
          customerStatement: {
            isLoading: true,
            isError: false
          }
        }
      };
    case LOADING_IMPERSONATED_CUSTOMERSTATEMENT_SUCCESS:
      return {
        ...state,
        impersonatedAccount: {
          ...state.impersonatedAccount,
          customerStatement: {
            isLoading: false,
            isError: false
          }
        }
      };
    case LOADING_IMPERSONATED_CUSTOMERSTATEMENT_ERROR:
      return {
        ...state,
        impersonatedAccount: {
          ...state.impersonatedAccount,
          customerStatement: {
            isLoading: false,
            isError: true
          }
        }
      };
    default:
      return state;
  }
};