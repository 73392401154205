import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Card, CardBody, Col, Container, ListGroup, Row } from 'reactstrap';
import history from '../../history';
import FAQItem from './FAQItem';

class FAQ extends Component {
  componentDidMount() {
    this.scrollFromHash();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;

    if (prevProps.location !== this.props.location) {
      this.scrollFromHash();
    }
  }

  scrollFromHash = () => {
    const anchor = this.props.location.hash.replace('#', '');

    if (!anchor)
      return false;

    let element = document.getElementById(anchor);

    if (!element)
      return false;

    // Need to calculate fixed header height to offset by
    let headerHeight = document.getElementById('navbar').clientHeight;

    window.scrollTo({
      top: element.offsetTop + headerHeight,
      behavior: 'smooth'
    });

    // Fallback for IE 11 being dumb
    setTimeout(() => {
      window.scrollTo(0, element.offsetTop + headerHeight);
    }, 300);
  };

  navigateToAccount = () => {
    history.push('#accounts');
  };

  navigateToOrdering = () => {
    history.push('#orderingOrders');
  };

  navigateToInvoices = () => {
    history.push('#invoicingStatements');
  };

  navigateToWarranty = () => {
    history.push('#warranty');
  };

  navigateToManual = () => {
    history.push('#manualsPartLists');
  };

  render() {
    return (
      <Container id='faq-container' className='animate-bottom-fade-in content-wrapper'>
        <Row className='my-3'>
          <Col>
            <h2>FAQs</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Container fluid>
                  <Row className='mb-3'>
                    <Col xs='6' sm='2' lg='1' className='text-center mb-3 mb-sm-0 has-pointer-icon' onClick={this.navigateToAccount}>
                      <FontAwesomeIcon icon='user-circle' size='2x' />
                      <div className='has-font-size-1'>Account</div>
                    </Col>
                    <Col xs='6' sm='2' lg='1' className='text-center mb-3 mb-sm-0 has-pointer-icon' onClick={this.navigateToOrdering}>
                      <FontAwesomeIcon icon='shopping-cart' size='2x' />
                      <div className='has-font-size-1'>Ordering & Orders</div>
                    </Col>
                    <Col xs='6' sm='2' lg='1' className='text-center mb-3 mb-sm-0 has-pointer-icon' onClick={this.navigateToInvoices}>
                      <FontAwesomeIcon icon='file-invoice-dollar' size='2x' />
                      <div className='has-font-size-1'>Invoices & Statements</div>
                    </Col>
                    <Col xs='6' sm='2' lg='1' className='text-center mb-3 mb-sm-0 has-pointer-icon' onClick={this.navigateToWarranty}>
                      <FontAwesomeIcon icon='award' size='2x' />
                      <div className='has-font-size-1'>Warranty</div>
                    </Col>
                    <Col xs='6' sm='2' lg='1' className='text-center mb-3 mb-sm-0 has-pointer-icon' onClick={this.navigateToManual}>
                      <FontAwesomeIcon icon='cloud-download-alt' size='2x' />
                      <div className='has-font-size-1'>Manual & Price Lists</div>
                    </Col>
                  </Row>
                  <Row className='ml-1 mb-3'>
                    <FontAwesomeIcon className='mt-1' icon='video' size='1x' />&nbsp;&nbsp;<a href='http://site-438789.bcvp0rtal.com/' target='_blank' rel='noopener noreferrer'>Watch key features and how-to videos</a>
                  </Row>
                  <Row id='accounts' className='mb-3'>
                    <Col>
                      <h5 className='mb-3'>Account</h5>
                      <ListGroup>
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.accounts.howToSeeAccounts.question' })} answer={this.props.intl.formatMessage({ id: 'faq.accounts.howToSeeAccounts.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.accounts.howToLocateAccount.question' })} answer={this.props.intl.formatMessage({ id: 'faq.accounts.howToLocateAccount.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.accounts.multipleContactNumbers.question' })} answer={this.props.intl.formatMessage({ id: 'faq.accounts.multipleContactNumbers.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.accounts.howToCreateAccount.question' })} answer={this.props.intl.formatMessage({ id: 'faq.accounts.howToCreateAccount.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.accounts.howToResetPassword.question' })} answer={this.props.intl.formatMessage({ id: 'faq.accounts.howToResetPassword.answer' })} />
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row id='orderingOrders' className='mb-3'>
                    <Col>
                      <h5 className='mb-3'>Ordering & Orders</h5>
                      <ListGroup>
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howToPriceAvailability.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howToPriceAvailability.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howToPricingSchedule.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howToPricingSchedule.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.applyPCE.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.applyPCE.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.employeePayment.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.employeePayment.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.locateTrackingNumber.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.locateTrackingNumber.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howToStatusUpdates.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howToStatusUpdates.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.canIPickup.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.canIPickup.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.canSalesRepsOrder.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.canSalesRepsOrder.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.receivedWrongProduct.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.receivedWrongProduct.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.whenWillOrderShip.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.whenWillOrderShip.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howCancelItemFromOrder.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howCancelItemFromOrder.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howToCreateACustomizedProduct.question' })} answer={this.props.intl.formatMessage({ id: 'faq.orderingOrders.howToCreateACustomizedProduct.answer' }, {
                          customizerFAQLink: (
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href={window.location.host.toLowerCase() === 'connect.milwaukeetool.com' ? 'https://custom.milwaukeetool.com/external/customer/FAQ' : 'https://custom-stage.milwaukeetool.com/external/customer/FAQ'}
                            >
                              {this.props.intl.formatMessage({
                                id: 'customLogo.faqLinkText',
                              })}
                            </a>
                          )
                        })} />
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row id='invoicingStatements' className='mb-3'>
                    <Col>
                      <h5 className='mb-3'>Invoicing & Statements</h5>
                      <ListGroup>
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.invoicingStatements.howToLocateInvoice.question' })} answer={this.props.intl.formatMessage({ id: 'faq.invoicingStatements.howToLocateInvoice.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.invoicingStatements.howToCustomerStatement.question' })} answer={this.props.intl.formatMessage({ id: 'faq.invoicingStatements.howToCustomerStatement.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.invoicingStatements.howToCreditMemo.question' })} answer={this.props.intl.formatMessage({ id: 'faq.invoicingStatements.howToCreditMemo.answer' })} />
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row id='warranty' className='mb-3'>
                    <Col>
                      <h5 className='mb-3'>Warranty & eService</h5>
                      <ListGroup>
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.warranty.noCatalogNumber.question' })} answer={this.props.intl.formatMessage({ id: 'faq.warranty.noCatalogNumber.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.warranty.noPartNumber.question' })} answer={this.props.intl.formatMessage({ id: 'faq.warranty.noPartNumber.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.warranty.rejectedClaim.question' })} answer={this.props.intl.formatMessage({ id: 'faq.warranty.rejectedClaim.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.warranty.pendingClaimStatus.question' })} answer={this.props.intl.formatMessage({ id: 'faq.warranty.pendingClaimStatus.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.warranty.defectiveParts.question' })} answer={this.props.intl.formatMessage({ id: 'faq.warranty.defectiveParts.answer' })} />
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row id='manualsPartLists' className='mb-3'>
                    <Col>
                      <h5 className='mb-3'>Manuals & Part Lists</h5>
                      <ListGroup>
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.manualsPartLists.howToLatestDPL.question' })} answer={this.props.intl.formatMessage({ id: 'faq.manualsPartLists.howToLatestDPL.answer' })} />
                        <FAQItem question={this.props.intl.formatMessage({ id: 'faq.manualsPartLists.howToPartsListManuals.question' })} answer={this.props.intl.formatMessage({ id: 'faq.manualsPartLists.howToPartsListManuals.answer' })} />
                      </ListGroup>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(FAQ);