import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import history from '../../history';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, } from 'reactstrap';

import { contactActions } from '../../store';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      canSubmit: false,
      subjectSelectedValue: '',
      subjectSelectedText: this.props.intl.formatMessage({ id: 'contact.selectSubject' }),
      emailBodyText: '',
      submitIsSuccess: false,
      selectOptions: [
        { value: 'ContactUs_Subject_Inventory', label: this.props.intl.formatMessage({ id: 'contact.subjectInventory' }) },
        { value: 'ContactUs_Subject_OrderDpl', label: this.props.intl.formatMessage({ id: 'contact.subjectOrderDpl' }) },
        { value: 'ContactUs_Subject_Pricing', label: this.props.intl.formatMessage({ id: 'contact.subjectPricing' }) },
        { value: 'ContactUs_Subject_OrderStatus', label: this.props.intl.formatMessage({ id: 'contact.subjectOrderStatus' }) },
        { value: 'ContactUs_Subject_Invoice', label: this.props.intl.formatMessage({ id: 'contact.subjectInvoice' }) },
        { value: 'ContactUs_Subject_AccountBalance', label: this.props.intl.formatMessage({ id: 'contact.subjectAccountBalance' }) },
        { value: 'ContactUs_Subject_ProdTech', label: this.props.intl.formatMessage({ id: 'contact.subjectProdTech' }) },
        { value: 'ContactUs_Subject_ProductService', label: this.props.intl.formatMessage({ id: 'contact.subjectProductService' }) },
        { value: 'ContactUs_Subject_AdvertLitReq', label: this.props.intl.formatMessage({ id: 'contact.subjectAdvertLitReq' }) },
        { value: 'ContactUs_Subject_WarrantyClaims', label: this.props.intl.formatMessage({ id: 'contact.subjectWarrantyClaims' }) }
      ]
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  subjectChangedValue = (value, action) => {
    let selectedValue = value.value;
    let selectedText = value.label;
    let userCanSubmitForm = (selectedValue !== 'Select a Subject' && this.state.emailBodyText.length > 0) ? true : false;
    this.setState({
      ...this.state,
      subjectSelectedValue: selectedValue,
      subjectSelectedText: selectedText,
      canSubmit: userCanSubmitForm
    });
  };

  emailBodyChanged = (e) => {
    let emailBodyText = e.currentTarget.value;
    let userCanSubmitForm = (this.state.subjectSelectedValue !== '' && emailBodyText.length > 0) ? true : false;
    this.setState({
      ...this.state,
      emailBodyText: emailBodyText,
      canSubmit: userCanSubmitForm
    });
  };

  handleSubmitContactForm = () => {
    let contactSubjectCode = this.state.subjectSelectedValue;
    let contactSubject = this.state.subjectSelectedText;
    let emailBody = this.state.emailBodyText;
    this.props.submitContactForm(contactSubjectCode, contactSubject, emailBody)
      .then(() => this.setState({
        ...this.state,
        submitIsSuccess: true
      }));
  };

  navigateToFaqs = () => {
    history.push('../FAQ');
  };

  render() {
    let companyContactInfo = '';
    let emailForm = '';
    let thankYouScreen = '';
    let currentForm = '';

    companyContactInfo = (
      <Card>
        <CardBody>
          <Row>
            <Col lg='3' sm='12'>
              <FormattedMessage id='contact.custService' /><br />
              <b data-test-id='usa-phone'>1-800-729-3878 (USA)</b><br />
              <b data-test-id='canada-phone'>1-800-268-4015 (CAN)</b>
            </Col>
            <Col lg='3' sm='12'>
              <FormattedMessage id='contact.generalPhone' /><br />
              <b data-test-id='general-phone'>1-800-729-3878</b>
            </Col>
            <Col lg='3' sm='12'>
              <FormattedMessage id='contact.fax' /><br />
              <b data-test-id='fax-phone'>1-800-638-9582</b>
            </Col>
            <Col lg='3' sm='12'>
              <FormattedMessage id='contact.address' /><br />
              <b data-test-id='contact-address'>13135 West Lisbon Road<br />
                Brookfield, WI 53005-2550</b>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
    emailForm = (
      <Card>
        <CardBody>
          <Row>
            <Col lg='6' sm='12'>
              <Row>
                <Col>
                  <h5><FormattedMessage id='contact.emailForm' /></h5>
                </Col>
                <Col className='text-right'>
                  <b><Link to='../FAQ'>FAQs</Link></b>
                </Col>
              </Row>
              <FormGroup>
                <Label for='emailSubject'><FormattedMessage id='contact.subject' /></Label>
                <div data-test-id='email-subject'>
                  <Select
                    className='react-select-container'
                    classNamePrefix='react-select'
                    onChange={this.subjectChangedValue}
                    options={this.state.selectOptions}
                  />
                </div>
              </FormGroup>
              <FormGroup data-test-id='email-comments'>
                <Label for='emailBody'><FormattedMessage id='contact.comments' /></Label>
                <Input type='textarea' name='text'
                  id='emailBody'
                  style={{ height: 200 }}
                  value={this.state.emailBodyText}
                  onChange={this.emailBodyChanged}
                />
              </FormGroup>
              <FormGroup>
                <Button data-test-id='email-submit' color='primary'
                  disabled={!this.state.canSubmit}
                  onClick={this.handleSubmitContactForm}>Submit</Button>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );


    thankYouScreen = (
      <Card>
        <CardBody>
          <Container style={{ height: 500 }}>
            <Row>
              <Col className='text-center'>
                <FontAwesomeIcon className='icon-link' size='3x' icon='envelope-open-text' />
              </Col>
            </Row>
            <Row>
              <Col data-test-id='email-thank-you' className='text-center'>
                <h3><FormattedMessage id='contact.thanksForYourRequst' /></h3>
              </Col>
            </Row>
            <Row>
              <Col className='text-center'>
                <FormattedMessage id='contact.repWillRespond' />
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    );

    if (this.state.submitIsSuccess === true) {
      currentForm = thankYouScreen;
    }
    else {
      currentForm = emailForm;
    }

    return (
      <div id='contactus' className='animate-bottom-fade-in content-wrapper'>
        <div className='container'>
          <Row>
            <Col lg='12' sm='12'>
              {companyContactInfo}
            </Col>
          </Row>
          <Row>
            <Col lg='12' sm='12'>
              {currentForm}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

// access to state
const mapStateToProps = state => {
  return {
    contact: state.contact
  }
};

// what actions are exposed on the component
// dispatch = allow you to fire off actions
const mapDispatchToProps = dispatch => ({
  submitContactForm: (subjectTypeCode, subjectType, emailBody) => dispatch(contactActions.submitContactForm(subjectTypeCode, subjectType, emailBody))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Contact));