import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { actions as toastrActions, toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import auth from '../../auth';
import Footer from '../../components/Footer';
import { Chat } from '../../containers/Chat';
import NavMenu from '../../components/NavMenu';
import { downloadBlob } from '../../helpers/downloadblob';
import { getProfile, getSession } from '../../helpers/localStorage';
import user from '../../helpers/user';
import { accountActions, configurationActions } from '../../store';
import { actionCreators as cartActions } from '../../store/Cart';
import { actionCreators as localesActions } from '../../store/Locales';

class BaseLayout extends React.Component {
  constructor(props){
    super(props);

    let userProfile = getProfile();
    
    this.state = {
      profile: {
        nickname: userProfile.nickname || 'My Account'
      },
      locale: this.props.match.params.locale || 'en',
      isFillOrKill: getProfile().cachedAccountDetails?.isFillOrKill || false
    }
  }

  componentDidMount() {
    this.props.getConfig();
    let userProfile = getProfile();
    let session = getSession();

    // switch to the correct locale
    this.props.changeLocale(this.state.locale);
    
    // If we already have profile information, use it.
    if (userProfile.nickname) {
      this.setState({
        ...this.state,
        profile: userProfile
      })
    }
    // otherwise, go fetch the user profile again
    else if (session.accessToken){
      auth.getProfile((err, profile) => {
        this.setState({
          ...this.state,
          profile
        })
      });
    }

    // load cart data
    if (user.canPlaceOrders() && this.props.cartQuantity === null)
      this.props.cartActions.getCartStatistics(this.props.accountNumber);
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined)
      return false;

    let userProfile = getProfile();

    // There's a few paths where we don't want to show the activation warning
    let isPathToNotShowActivationWarning = ['/invitetopay', '/profile/creditcards'].indexOf(window.location.pathname) > -1;

    // load the account if we are already authenticated and it isn't yet loaded
    if (auth.isAuthenticated() && userProfile && userProfile.accountNumber && !this.props.accountLoaded && !this.props.accountLoading) {
      this.loadAccount(userProfile.accountNumber);
    }
    
    if (auth.isAuthenticated() && this.props.accountLoaded && userProfile && !userProfile.accountNumber && !isPathToNotShowActivationWarning) {
      toastr.warning('Account Activation Incomplete', 'Please activate your account by sending an activation request from the home page.', {
        removeOnHover: false,
          showCloseButton: false,
          closeOnToastrClick: false,
          timeOut: 0
      });
    }

    
    if (this.props.match.params.locale && this.state.locale !== this.props.match.params.locale) {
      this.props.changeLocale(this.props.match.params.locale);
    }

    if (user.canPlaceOrders() && prevProps.accountNumber !== this.props.accountNumber) {
      this.props.cartActions.getCartStatistics(this.props.accountNumber);
    }

    if (prevProps.accountIsError !== this.props.accountIsError && this.props.accountIsError && this.props.accountLoaded) {
      this.props.showToast({
        id: 'accountErrorToast',
        type: 'error',
        title: this.props.intl.formatMessage({id: 'general.errorToastTitle'}),
        message: this.props.intl.formatMessage({id: 'account.accountLoadingError'}),
        options: {
          removeOnHover: false,
          showCloseButton: false,
          closeOnToastrClick: false,
          timeOut: 0
        }
      });
    }

    if (!this.props.accountIsError){
      toastr.remove('accountErrorToast');
    }
  }

  getCustStatement = () => {
    toastr.info(
      'Customer Statement Downloading...',
      '',
      {
        className: 'toastr-download',
        position: 'bottom-right',
        removeOnHover: false,
        showCloseButton: false,
        closeOnToastrClick: false,
        timeOut: 0,
        icon: <FontAwesomeIcon icon='spinner' size='2x' spin />
      }
    );
    
    if (this.props.isImpersonating) {
      this.props.getCustomerStatementForImpersonatedAccount(this.props.accountNumber)
        .then((blob) => {
          downloadBlob('Customer Statement.pdf', blob);
          toastr.removeByType('info');
        }).catch(() => {
        toastr.removeByType('info');
        toastr.error(this.props.intl.formatMessage({ id: 'general.customerStatementErrorTitle' }), this.props.intl.formatMessage({ id: 'general.customerStatementErrorBody' }), { timeOut: 0 });
      });
    }
    else {
      this.props.getCustomerStatementForAccount(this.props.accountNumber)
        .then((blob) => {
          downloadBlob('Customer Statement.pdf', blob);
          toastr.removeByType('info');
        }).catch(() => {
        toastr.removeByType('info');
        toastr.error(this.props.intl.formatMessage({ id: 'general.customerStatementErrorTitle' }), this.props.intl.formatMessage({ id: 'general.customerStatementErrorBody' }), { timeOut: 0 });
      });
    }
  };

  loadAccount = (accountNumber) => {
    this.props.loadAccount(accountNumber)
      .catch(() => {
        toastr.error(this.props.intl.formatMessage({ id: 'general.errorToastTitle' }), this.props.intl.formatMessage({ id: 'account.accountLoadingError' }));
      });
  };
  
  getCart = () => {
    this.props.cartActions.getCart(this.props.accountNumber, this.state.isFillOrKill);
  };

  render() {
    const classes = classNames(
      'main'
    );    
    const isAdmin = user.isAdmin();
    const showChat = this.props.featureToggles.ChatEnabled && auth.isAuthenticated() && user.isUS();
    const canViewInvoices = (this.props.topLevelAccount == this.props.accountNumber || user.isMilwaukeeToolEmployee()) ? true : (!this.props.restrictedInvoices?.includes(this.props.topLevelAccount))
    return (
      <>
        <NavMenu
          profile={this.state.profile}
          isAuthenticated={auth.isAuthenticated()}
          locale={this.props.match.params.locale}
          cartQuantity={this.props.cartQuantity}
          canAccessCustomerStatement={this.props.canAccessCustomerStatement}
          accountHasAsc={this.props.accountHasAsc}
          accountHasOneKey={this.props.accountHasOneKey}
          cart={this.props.cart}
          getCustomerStatement={this.getCustStatement}
          getCart={this.getCart}
          featureToggles={this.props.featureToggles}
          canViewInvoices={canViewInvoices}
        />
        <div className={classes} style={{backgroundColor: this.props.backgroundColor}}>{this.props.children}</div>
        {showChat && <Chat />}
        <Footer showCountries={isAdmin} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    locales: state.locales,
    cartQuantity: state.cart.totalQuantity,
    canAccessCustomerStatement: state.account.isImpersonating ? state.account.impersonatedAccount.canAccessCustomerStatement : state.account.currentAccount.canAccessCustomerStatement,
    accountLoaded: !state.account.currentAccount.isLoading && state.account.currentAccount.accountDetails.accountNumber !== '',
    accountLoading: state.account.currentAccount.isLoading,
    isImpersonating: state.account.isImpersonating,
    accountNumber: state.account && (state.account.isImpersonating ? state.account.impersonatedAccount.accountDetails.accountNumber : state.account.currentAccount.accountDetails.accountNumber),
    accountHasAsc: state.account.currentAccount.accountDetails.hasAsc,
    accountHasOneKey: state.account.currentAccount.accountDetails.hasOneKey,
    accountIsError: state.account.currentAccount.isError,
    featureToggles: state.configuration.featureToggles,
    topLevelAccount: state.account.currentAccount.accountDetails.topLevelAccount,
    restrictedInvoices: state.configuration.distributorAccountRestrictions.restrictedInvoices
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getConfig: () => dispatch(configurationActions.getConfig()),
    cartActions: bindActionCreators(cartActions, dispatch),
    changeLocale: (locale) => dispatch(localesActions.updateLocale(locale)),
    loadAccount: (account) => dispatch(accountActions.loadAccount(account)),
    getCustomerStatementForAccount: (account) => dispatch(accountActions.getCustomerStatementForAccount(account)),
    getCustomerStatementForImpersonatedAccount: (account) => dispatch(accountActions.getCustomerStatementForImpersonatedAccount(account)),
    showToast: (toastConfig) => dispatch(toastrActions.add(toastConfig))
  };
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(BaseLayout)));