import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import history from '../history';
import browser from './browser';
import oneTrust from './oneTrust';

let instrumentationKey = '8b33a8ef-d56c-4a95-b21e-2fb181262467';
let host = window.location.hostname;
let ignoreExceptions = browser.isSupportedBrowser();

if (host.indexOf('stage') >= 0)
  instrumentationKey = 'e76dc464-5518-474b-8fa8-809196df7ef3';
else if (host.indexOf('connect.milwaukeetool.com') >= 0)
  instrumentationKey = '539e9a37-e0f5-425e-8879-90010a4b289a';

let isEnabled = false;
let initializedInstance = null;

var filteringFunction = (envelope) => {
  if (envelope && envelope.data && envelope.data.message) {
    const { message } = envelope.data;

    // Per https://github.com/ant-design/ant-design/issues/26621
    if (message.toLowerCase().indexOf('resizeobserver') > -1) {
      return false;
    }

    if (message.indexOf('Synchronous XHR in page dismissal') > -1) {
      return false;
    }
  }
  return oneTrust.canLogAI();
};

let init = function () {
  if (oneTrust.canLogAI() && !isEnabled) {
    let reactPlugin = new ReactPlugin();
    let initializer = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            debug: false,
            history: history,
            disableExceptionTracking: ignoreExceptions
          }
        }
      }
    });
    initializedInstance = initializer.loadAppInsights();
    initializedInstance.addTelemetryInitializer(filteringFunction);
    initializedInstance.trackPageView();
    isEnabled = true;
  }
}
window.applicationInsightsHelper = {
  init
};

let appInsights = {
  init,
  setAuthenticatedUserContext: function (authenticatedUserId, accountId, storeInCookie) {
    if (oneTrust.canLogAI()) {
      if (!initializedInstance) {
        init();
      }
      initializedInstance.setAuthenticatedUserContext(
        authenticatedUserId,
        accountId,
        storeInCookie
      );
    }
  }
}

export default appInsights;