import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Enumerable from 'linq';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

class Stepper extends Component {
  render() {
    const { steps, beforeToRender, afterToRender } = this.props;
    
    let elements = [];
    
    if (steps.length > 0) {
      let activeStepIndex = Enumerable.from(steps).indexOf(x => x.isActive);
      let startIndex = activeStepIndex > 0 && activeStepIndex - beforeToRender > 0 ? activeStepIndex - beforeToRender : 0;
      let endIndex = (activeStepIndex + afterToRender) > steps.length - 1 ? steps.length - 1 : (activeStepIndex + afterToRender);
      
      if (startIndex !== 0) {
        const priorStepsComplete = Enumerable.from(steps).where(x => x.isComplete === true).count() <= activeStepIndex + 1;
        const priorStepsClasses = classNames(
          'step',
          'small',
          priorStepsComplete && 'complete'
        );
        
        elements.push(
          <div key={`step-start`} className={priorStepsClasses}>
            <div className='step-trigger'>
              <span className='stepper-circle'>
              </span>
            </div>
          </div>
        );
        elements.push(<div key={`line-start`} className='line'/>);
      }
      
      for (let i = startIndex; i <= endIndex; i++) {
        const step = steps[i];
        if (i !== startIndex) {
          elements.push(<div key={`line-${i + 1}`} className='line'/>);
        }
        
        const stepClasses = classNames(
          'step',
          step.isComplete && 'complete',
          step.isActive && 'active'
        );
        
        elements.push(
          <div key={`step-${i + 1}`} className={stepClasses}>
            <div className='step-trigger'>
              <span className='stepper-circle'>
                <FontAwesomeIcon icon={step.icon}/>
              </span>
              <span className='stepper-label mt-2'>{step.label}</span>
            </div>
          </div>
        );
      }


      if (endIndex !== steps.length - 1) {
        elements.push(<div key={`line-end`} className='line'/>);
        elements.push(
          <div key={`step-end`} className='step small'>
            <div className='step-trigger'>
              <span className='stepper-circle'>&nbsp;</span>
            </div>
          </div>
        );
      }
    }
    
    return (
      <div className='stepper'>
        <div className='stepper-header'>
          {elements}
        </div>
      </div>
    );
  }
}

Stepper.propTypes = {
  steps: PropTypes.array,
  beforeToRender: PropTypes.number,
  afterToRender: PropTypes.number
};

Stepper.defaultProps = {
  beforeToRender: 1,
  afterToRender: 1
};

export default Stepper;