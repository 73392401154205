export const getSession = () => {
  let session = JSON.parse(localStorage.getItem('customerportal:session') || '{}');
  
  return session || {};
};

export const getProfile = () => {
  let profile = JSON.parse(localStorage.getItem('customerportal:profile') || '{}');
  return profile || {};
};

export const getPreferences = () => {
  let preferences = JSON.parse(localStorage.getItem('customerportal:preferences') || '{}');
  return preferences || {};
};

export const updateSession = (session) => {
  let preexistingSession = JSON.parse(localStorage.getItem('customerportal:session') || '{}');
  localStorage.setItem('customerportal:session', JSON.stringify({...preexistingSession, ...session}));
};

export const updateProfile = (profile) => {
  let preexistingProfile = JSON.parse(localStorage.getItem('customerportal:profile') || '{}');
  localStorage.setItem('customerportal:profile', JSON.stringify({...preexistingProfile, ...profile}));
};

export const updatePreferences = (preferences) => {
  let preexistingPreferences = JSON.parse(localStorage.getItem('customerportal:preferences') || '{}');
  localStorage.setItem('customerportal:preferences', JSON.stringify({...preexistingPreferences, ...preferences}));
};

export const clearAll = () => {
  localStorage.removeItem('customerportal:session');
  localStorage.removeItem('customerportal:profile');
};