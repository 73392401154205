import { daysBetween } from '../helpers/date';
import queryString from '../helpers/queryString';
import createFetchAction from './createFetchAction';

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR';

export const GET_CART = 'GET_CART';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_ERROR = 'GET_CART_ERROR';

export const GET_CART_STATISTICS = 'GET_CART_STATISTICS';
export const GET_CART_STATISTICS_SUCCESS = 'GET_CART_STATISTICS_SUCCESS';

export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';

export const SUBMIT_CART = 'SUBMIT_CART';
export const SUBMIT_CART_SUCCESS = 'SUBMIT_CART_SUCCESS';
export const SUBMIT_CART_ERROR = 'SUBMIT_CART_ERROR';

export const REORDER = 'REORDER';
export const REFRESH_CART = 'REFRESH_CART';

export const MOVE_CART_SKU_TO_WISHLIST = 'MOVE_CART_SKU_TO_WISHLIST';
export const MOVE_CART_SKU_TO_WISHLIST_SUCCESS = 'MOVE_CART_SKU_TO_WISHLIST_SUCCESS';
export const MOVE_CART_SKU_TO_WISHLIST_ERROR = 'MOVE_CART_SKU_TO_WISHLIST_ERROR';

export const RESET_ON_NAVIGATION = 'RESET_ON_NAVIGATION';

export const initialState = {
  addToCart: {
    isProcessing: false,
    isError: false
  },
  getCart: {
    isLoading: false,
    isError: false
  },
  submitCart: {
    isProcessing: false,
    isError: false
  },
  totalQuantity: null,
  pendingOrder: null,
  isRefreshing: false,
  isGridView: false
};

export const actionCreators = {
  addToCart: (product, quantity, accountNumber, customLogoRefId, isEmployeeAccount) => {
    return createFetchAction({
      method: 'PATCH',
      url: `/api/v1/cart`,
      body: { ...product, quantity, accountNumber, customLogoRefId, isEmployeeAccount },
      startAction: ADD_TO_CART
    });
  },
  addBatchToCart: (orderLines, accountNumber) => {
    return createFetchAction({
      method: 'PATCH',
      url: `/api/v1/cart/batch`,
      body: {
        orderLines,
        accountNumber
      },
      startAction: ADD_TO_CART
    });
  },
  getCart: (accountNumber, isFillOrKill) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/cart?accountNumber=${accountNumber || ''}${(isFillOrKill ? `&isFillOrKill=${isFillOrKill}` : '')}`,
      startAction: GET_CART
    });
  },
  updateQuantity: (sku, quantity, accountNumber, customLogoRefId) => {
    return createFetchAction({
      method: 'PUT',
      url: `/api/v1/cart`,
      body: { sku, quantity, accountNumber, customLogoRefId },
      startAction: UPDATE_QUANTITY
    });
  },
  removeFromCart: (sku, accountNumber, customLogoRefId) => {
    return createFetchAction({
      method: 'DELETE',
      url: `/api/v1/cart`,
      body: { sku, accountNumber, customLogoRefId },
      startAction: REMOVE_FROM_CART
    });
  },
  moveToWishlist: (sku, accountNumber, customLogoRefId, orderLineIds) => {
    return createFetchAction({
      method: 'PUT',
      url: `/api/v1/cart/_actions/moveToWishlist`,
      body: { sku, accountNumber, customLogoRefId, orderLineIds },
      startAction: MOVE_CART_SKU_TO_WISHLIST
    });
  },
  getCartStatistics: (accountNumber) => {
    return createFetchAction({
      method: 'GET',
      url: `/api/v1/cart/statistics?accountNumber=${accountNumber || ''}`,
      startAction: GET_CART_STATISTICS
    });
  },
  submitCart: (shippingSpeed, requestedShipDate, purchaseOrderNumber, emailConfirmation, accountNumber, branch, creditCard, address, currencyCode) => {
    return createFetchAction({
      method: 'POST',
      url: '/api/v1/cart',
      startAction: SUBMIT_CART,
      body: {
        shippingSpeed,
        requestedShipDate,
        purchaseOrderNumber,
        email: emailConfirmation,
        accountNumber,
        branch: branch && branch.accountNumber,
        branchName: branch && branch.name,
        branchAddress: branch && branch.address,
        addressId: address && address.id,
        billingPostalCode: creditCard && creditCard.billingPostalCode,
        squareCardNonce: creditCard && creditCard.squareCardNonce,
        cardholderName: creditCard && creditCard.cardholderName,
        cardId: creditCard && creditCard.id,
        currencyCode
      }
    });
  },
  reorder: (orderId, accountNumber) => {
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/cart/_actions/reorder/${orderId}?accountNumber=${accountNumber || ''}`,
      startAction: REORDER
    });
  },
  refreshCart: (accountNumber) => {
    let query = queryString.objectToQuery({ accountNumber });
    return createFetchAction({
      method: 'POST',
      url: `/api/v1/cart/_actions/refreshcart?${query}`,
      startAction: REFRESH_CART
    });
  },
  resetOnNavigation: () => {
    return {
      type: RESET_ON_NAVIGATION
    };
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        addToCart: {
          isProcessing: true,
          isError: false
        },
        isRefreshing: true
      };

    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addToCart: {
          isProcessing: false,
          isError: false
        }
      };

    case ADD_TO_CART_ERROR:
      return {
        ...state,
        addToCart: {
          isProcessing: false,
          isError: true
        }
      };

    case GET_CART:
      return {
        ...state,
        getCart: {
          ...state.getCart,
          isLoading: true,
          isError: false
        }
      };

    case GET_CART_SUCCESS:
      return {
        ...state,
        getCart: {
          isLoading: false,
          isError: false
        },
        pendingOrder: action.payload.data || {},
        isExpired: action.payload.data && action.payload.data.totalQuantity > 0 ?
          (!(action.payload.data.quoteNumber && action.payload.data.quoteCreatedAt) || 
          (action.payload.data.quoteCreatedAt && daysBetween(new Date(), new Date(action.payload.data.quoteCreatedAt)) >= 30)) : false,
        isRefreshing: action.payload.data != null && action.payload.data.totalQuantity > 0 ? [3, 5].includes(action.payload.data.orderStatusId) : false
      };

    case GET_CART_ERROR:
      return {
        ...state,
        getCart: {
          ...state.getCart,
          isLoading: false,
          isError: true
        }
      };

    case GET_CART_STATISTICS_SUCCESS:
      return {
        ...state,
        totalQuantity: action.payload.data.totalQuantity
      };

    case UPDATE_QUANTITY:
    case REMOVE_FROM_CART:
      return {
        ...state,
        isRefreshing: true
      };

    case SUBMIT_CART:
      return {
        ...state,
        submitCart: {
          isProcessing: true,
          isError: false
        }
      };
    case SUBMIT_CART_SUCCESS:
      return {
        ...state,
        submitCart: {
          isProcessing: false,
          isError: false
        }
      };
    case SUBMIT_CART_ERROR:
      return {
        ...state,
        submitCart: {
          isProcessing: false,
          isError: true
        }
      };

    case RESET_ON_NAVIGATION:
      return {
        ...state,
        pendingOrder: null
      };
    default:
      break;
  }

  return state;
};