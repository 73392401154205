import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Address extends Component {
  render() {
    const { line1, line2, city, state, postalCode, countryCode } = this.props;
    let splitLine1 = line1?.split('\n');

    return (
      <div data-test-id={this.props.addressDataTestId} className='address'>
        {line1 && splitLine1?.length > 0 && line1.indexOf('Attn:') > -1 && <div>{splitLine1[0]?.replace('Attn: ', '')}</div>}
        {splitLine1?.length > 0 && <div>{splitLine1[splitLine1.length - 1]}</div>}
        <div>{line2}</div>
        <div>{city}, {state} {postalCode}</div>
        <div>{countryCode}</div>
      </div>
    )
  }
}

Address.propTypes = {
  addressDataTestId: PropTypes.string,
};

export default Address;