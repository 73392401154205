import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Col, Collapse, Container, ListGroupItem, Row} from 'reactstrap';

class FAQItem extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isOpen: this.props.isOpen || false
    };
  }
  
  toggle = () => {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen
    });
  };
  
  render() {
    const { question, answer } = this.props;
    
    let answerBefore;
    let answerList;
    let answerAfter;
    
    // NOTE: This supports either Ordered/Unordered list and Ordered list with unordered list per answer currently.
    if (answer.includes('[') && answer.includes(']')) {
      // Ordered list items with unordered list items in between
      if (answer.includes('<') && answer.includes('>')) {
        answerBefore = answer.substring(0, answer.indexOf('<'));
        answerAfter = answer.substring(answer.indexOf('>'), answer.length - 1);
        let listItems = answer
          .substring(answer.indexOf('<') + 2, answer.indexOf(']>'))
          .split('||')
          .map((item, index) => {
            // Unordered list item should be opened & closed in the same line.
            if (item.includes('[[') && item.includes(']]')) {
              const subItem = item.substring(item.indexOf('[[') + 2, item.length - 2);
              return (
                <ul className='answer-list' key={`sub-answer-${index}`}>
                  <li>{subItem}</li>
                </ul>
              );
            } else {
              return <li key={`answer-${index}`}>{item}</li>
            }
          });
        
        answerList = (
          <ol className='answer-list'>
            {listItems}
          </ol>
        );
      } else if (answer.includes('[[') && answer.includes(']]')) {
        // Unordered list present
        answerBefore = answer.substring(0, answer.indexOf('[['));
        answerAfter = answer.substring(answer.indexOf(']]') + 1, answer.length - 1);
        let listItems = answer
          .substring(answer.indexOf('[[') + 2, answer.indexOf(']]'))
          .split('||')
          .map((item, index) => {
            return (
              <li key={`answer-${index}`}>{item}</li>
            );
          });
        
        answerList = (
          <ul className='answer-list'>
            {listItems}
          </ul>
        );
      } else {
        // Ordered list present
        answerBefore = answer.substring(0, answer.indexOf('['));
        answerAfter = answer.substring(answer.indexOf(']'), answer.length - 1);
        let listItems = answer
          .substring(answer.indexOf('[') + 1, answer.indexOf(']'))
          .split('||')
          .map((item, index) => {
            return (
              <li key={`answer-${index}`}>{item}</li>
            );
          });

        answerList = (
          <ol className='answer-list'>
            {listItems}
          </ol>
        );
      }
    }
    
    let answerMarkup = answerList ? (
      <Fragment>
        {answerBefore}{answerList}{answerAfter}
      </Fragment>
    ) : answer;
    
    return (
      <ListGroupItem className='faq-item'>
        <Container onClick={this.toggle} fluid className='py-2 has-pointer-icon'>
          <Row>
            <Col xs='auto' className='faq-item-first text-center'>
              <FontAwesomeIcon icon={this.state.isOpen ? 'angle-down' : 'angle-right'}/>
            </Col>
            <Col>
              <h6 className='mb-0'>{question}</h6>
            </Col>
          </Row>
        </Container>
        <Collapse isOpen={this.state.isOpen}>
          <Container fluid>
            <Row>
              <Col xs='auto' className='faq-item-first'>
              </Col>
              <Col>
                {answerMarkup}
              </Col>
            </Row>
          </Container>
        </Collapse>
      </ListGroupItem>
    );
  }
}

FAQItem.propTypes = {
  isOpen: PropTypes.bool,
  question: PropTypes.string,
  answer: PropTypes.string
};

export default FAQItem;