import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Text extends Component {
  render() {
    const text = !this.props.isHidden && this.props.value;

    return (
      <span>
        {text}
      </span>
    );
  }
}

Text.propTypes = {
  isHidden: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Text;