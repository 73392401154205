import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@met/react-components';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const Message = styled.div`
  text-align: center;
  > span {
    font-weight: 600;
    padding-right: 1px;
    text-decoration: underline;
  }
`;

const DeleteAddressModal = ({ address, hidden, onDelete, toggle }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!hidden)
      setIsDeleting(false);
    
  }, [hidden]);

  const handleDelete = () => {
    setIsDeleting(true);
    onDelete(address.id).then(() => toggle());
  };

  return (
    <Modal hidden={hidden} toggle={toggle} centered>
      <ModalContent>
        <ModalHeader>
          Delete Address
        </ModalHeader>
        <ModalBody>
          <Message>Are you sure you want to delete your address at <span>{address && address.addressLine1}</span>?</Message>
        </ModalBody>
        <ModalFooter>
          <Button primary outline onClick={toggle}>Cancel</Button>
          <Button primary loading={isDeleting} onClick={handleDelete}>Delete Address</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

DeleteAddressModal.propTypes = {
  address: PropTypes.object,
  hidden: PropTypes.bool,
  onDelete: PropTypes.func,
  toggle: PropTypes.func
};

export default DeleteAddressModal;