import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal, ModalBody, ModalContent, ModalHeader} from '@met/react-components';
import PropTypes from 'prop-types';
import React from 'react';

import AddressForm from './AddressForm';

const AddressFormModal = ({ address, hidden, toggle, onSaveClick, states, countries, title, addAttentionTo }) => {
  const handleSaveClick = (addressFields) => {
    return onSaveClick(addressFields)
      .then(() => toggle());
  };
  
  return (
    <Modal centered hidden={hidden} toggle={toggle}>
      <ModalContent>
        <ModalHeader>
          <FontAwesomeIcon icon='address-card' className='mr-2' /> {title ? title : address ? 'Edit Address' : 'Add Address'}
        </ModalHeader>
        <ModalBody>
          <AddressForm
            address={address}
            states={states}
            countries={countries}
            toggle={toggle}
            onSaveClick={handleSaveClick}
            addAttentionTo={addAttentionTo}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

AddressFormModal.propTypes = {
  address: PropTypes.object,
  hidden: PropTypes.bool,
  toggle: PropTypes.func,
  states: PropTypes.array,
  countries: PropTypes.array,
  onSaveClick: PropTypes.func
};

export default AddressFormModal;