import React, {Component} from 'react';

import classNames from 'classnames';
import {
  Button as ReactstrapButton
} from 'reactstrap';
import PropTypes from 'prop-types';

class Button extends Component {
  render() {
    const { className, isDisabled, isLoading, isHidden, active, onClick, ...others } = this.props;
    
    if (isHidden)
      return null;
    
    const classes = classNames(
      className,
      isLoading && 'is-loading'
    );
    
    return (
      <ReactstrapButton className={classes} {...others} disabled={isDisabled} active={active} onClick={onClick}>
        {this.props.children}
      </ReactstrapButton>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;