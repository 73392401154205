import {CardBody, Col, useToggle} from '@met/react-components';
import React from 'react';

import {AddressFormModal, DeleteAddressModal} from "../../../components/AddressForm";
import AddressCard from './AddressCard';

const UserAddress = ({accountName, address, states, countries, onDeleteClick, onSaveClick}) => {
  const [addressModalHidden, toggleAddressModal] = useToggle(true);
  const [deleteModalHidden, toggleDeleteModal] = useToggle(true);
  
  return (
    <Col xs={12} sm={6} className='d-flex'>
      <AddressCard>
        <CardBody>
          <ul style={{listStyle: 'none', padding: 0}}>
            <li className='font-weight-bold'>{accountName}</li>
            <li>{address.addressLine1}</li>
            {address.addressLine2 && (
              <li>{address.addressLine2}</li>
            )}
            <li>{address.city}, {address.state} {address.postalCode}</li>
            <li>{address.countryCode}</li>
          </ul>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span className='is-mock-link' onClick={toggleAddressModal}>Edit</span>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span className='is-mock-link ml-3' onClick={toggleDeleteModal}>Delete</span>
        </CardBody>
      </AddressCard>
      <DeleteAddressModal
        address={address}
        hidden={deleteModalHidden}
        toggle={toggleDeleteModal}
        onDelete={onDeleteClick}
      />
      <AddressFormModal
        address={address}
        states={states}
        countries={countries}
        hidden={addressModalHidden}
        toggle={toggleAddressModal}
        onSaveClick={onSaveClick}
      />
    </Col>
  )
};

export default UserAddress;