import createFetchAction from './createFetchAction';

export const VALIDATE_DEFECT_CLAIM = 'VALIDATE_DEFECT_CLAIM';
export const VALIDATE_DEFECT_CLAIM_SUCCESS = 'VALIDATE_DEFECT_CLAIM_SUCCESS';
export const VALIDATE_DEFECT_CLAIM_ERROR = 'VALIDATE_DEFECT_CLAIM_ERROR';

export const CREATE_DEFECT_CLAIM = 'CREATE_DEFECT_CLAIM';
export const CREATE_DEFECT_CLAIM_SUCCESS = 'CREATE_DEFECT_CLAIM_SUCCESS';
export const CREATE_DEFECT_CLAIM_ERROR = 'CREATE_DEFECT_CLAIM_ERROR';

export const initialState = {
  validateDefectClaim: {
    isLoading: false,
    isError: false,
    validatedClaim: {}
  },
  createDefectClaim: {
    isLoading: false,
    isError: false,
    claimId: 0
  }
};

export const actionCreators = {
  validateClaim: (claim) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/ValidateDefectivePart`,
      method: 'POST',
      startAction: VALIDATE_DEFECT_CLAIM,
      body: claim
    });
  },
  createDefectClaim: (claim) => {
    return createFetchAction({
      url: `/warrantyapi/warranty/DefectivePart`,
      method: 'POST',
      startAction: CREATE_DEFECT_CLAIM,
      body: claim
    });
  }
};


export const reducer = (state, action) => {
  state = state || initialState;
  switch(action.type) {
    case VALIDATE_DEFECT_CLAIM:
      return {
        ...state,
        validateDefectClaim : {
          ...state.validateDefectClaim,
          isLoading: true,
          isError: false
        }
      };
    case VALIDATE_DEFECT_CLAIM_ERROR:
      return {
        ...state,
        validateDefectClaim : {
          isLoading: false,
          isError: true
        }
      };
    case VALIDATE_DEFECT_CLAIM_SUCCESS:
      return {
        ...state,
        validateDefectClaim : {
          isLoading: false,
          isError: false,
          validatedClaim: action.payload.data
        }
      };
    case CREATE_DEFECT_CLAIM:
      return {
        ...state,
        createDefectClaim : {
          isLoading: true,
          isError: false,
          claimId: 0
        }
      };
    case CREATE_DEFECT_CLAIM_ERROR:
      return {
        ...state,
        createDefectClaim : {
          isLoading: false,
          isError: true,
          claimId: 0
        }
      };
    case CREATE_DEFECT_CLAIM_SUCCESS:
      return {
        ...state,
        createDefectClaim : {
          isLoading: false,
          isError: true,
          claimId: action.payload.ClaimId
        }
      };
    default:
      return state;
  }
};

